import React from 'react';

const UnlockSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.997 299.997" {...props}>
        <path d="M134.437 161.482c-8.613 0-15.598 6.982-15.598 15.598 0 5.778 3.149 10.807 7.817 13.502v17.346h15.562v-17.346c4.668-2.695 7.817-7.726 7.817-13.502 0-8.616-6.985-15.598-15.598-15.598z"/>
        <path d="M149.996 0C67.157 0 .001 67.158.001 149.997c0 82.837 67.156 150 149.995 150s150-67.163 150-150C299.996 67.156 232.835 0 149.996 0zm102.947 134.436h-25.936v-27.915c0-13.108-10.665-23.775-23.773-23.775-13.111 0-23.775 10.665-23.775 23.775v25.321h1.063c9.734 0 17.626 7.892 17.626 17.629v56.921c0 9.736-7.892 17.629-17.626 17.629H88.349c-9.734 0-17.626-7.892-17.626-17.629v-56.924c0-9.736 7.892-17.629 17.626-17.629h65.174V106.52c0-27.409 22.302-49.711 49.711-49.711s49.709 22.3 49.709 49.711v27.916z"/>
    </svg>
);

export default UnlockSvg;
