import React from 'react';

const RoleBindingSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.03533 17.50038" {...props}>
        <path d="M8.95834.46317a1.13579 1.1265 0 0 0-.43526.11015L2.58354 3.41129a1.13579 1.1265 0 0 0-.61454.76419l-1.46533 6.3748a1.13579 1.1265 0 0 0 .1542.8637 1.13579 1.1265 0 0 0 .06458.08964l4.1111 5.11154a1.13579 1.1265 0 0 0 .88801.42387l6.59282-.00152a1.13579 1.1265 0 0 0 .888-.42311l4.1096-5.1123a1.13579 1.1265 0 0 0 .21953-.95333l-1.4676-6.37481a1.13579 1.1265 0 0 0-.61454-.76419L9.50907.57332a1.13579 1.1265 0 0 0-.55073-.11015z" />
        <path d="M8.95515.00157a1.19902 1.1892 0 0 0-.45881.11698L2.2256 3.11377a1.19902 1.1892 0 0 0-.64872.80673l-1.5466 6.72955a1.19902 1.1892 0 0 0 .16255.91231 1.19902 1.1892 0 0 0 .06837.0942l4.33976 5.3964a1.19902 1.1892 0 0 0 .93738.44742l6.95972-.00152a1.19902 1.1892 0 0 0 .93738-.44667l4.33824-5.39716a1.19902 1.1892 0 0 0 .23169-1.00575l-1.54888-6.7303a1.19902 1.1892 0 0 0-.64872-.80597L9.53703.11779a1.19902 1.1892 0 0 0-.58188-.11622zm.00304.46185a1.13579 1.1265 0 0 1 .55073.11015l5.9403 2.83645a1.13579 1.1265 0 0 1 .61454.7642l1.4676 6.3748a1.13579 1.1265 0 0 1-.21953.95333l-4.1096 5.1123a1.13579 1.1265 0 0 1-.888.42312l-6.59282.00152a1.13579 1.1265 0 0 1-.888-.42387L.7223 11.50388a1.13579 1.1265 0 0 1-.06457-.08964 1.13579 1.1265 0 0 1-.1542-.8637l1.46532-6.3748a1.13579 1.1265 0 0 1 .61454-.76419L8.52293.57358a1.13579 1.1265 0 0 1 .43526-.11015z" color="#000" fontWeight="400" fontFamily="Sans" overflow="visible" fill="#fff"/>
        <text y="16.91761" x="9.97446" fontWeight="400" fontSize="10.58333" fontFamily="Sans" letterSpacing="0" wordSpacing="0" fill="#fff" strokeWidth=".26458" transform="translate(-.99263 -1.17418)">
            <tspan y="16.91761" x="9.97446" fontSize="2.82222" fontFamily="Arial" textAnchor="middle">rb</tspan>
        </text>
        <path fill="none" stroke="#fff" strokeWidth=".4" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray=".8 .4" strokeDashoffset="4" d="M4.05273 3.75263h9.92986v8.65717H4.05273z"/>
        <path d="M5.8895 8.08121c0-.66039.53682-1.1972 1.1972-1.1972h1.54477v-.73376H7.0867c-1.0659 0-1.93095.86507-1.93095 1.93096s.86506 1.93096 1.93095 1.93096h1.54477V9.2784H7.0867c-.66038 0-1.1972-.5368-1.1972-1.19719zm1.5834.3862h3.08952v-.77239H7.4729zm3.47572-2.31716H9.40385v.73377h1.54477c.66038 0 1.19718.5368 1.19718 1.1972 0 .66038-.5368 1.19718-1.19718 1.19718H9.40385v.73377h1.54477c1.0659 0 1.93095-.86507 1.93095-1.93096s-.86506-1.93096-1.93095-1.93096zM7.50288 3.752v-.19403H8.27899v.38805H7.50288V3.752zM8.70232 3.752v-.19403H9.47843v.38805H8.70232V3.752zM9.90177 3.752v-.19403H10.67788v.38805H9.90177V3.752zM11.10121 3.752v-.19403H11.87732v.38805H11.10121V3.752zM12.30065 3.752v-.19403h.77612v.38805h-.77612V3.752z" fill="#fff"/>
    </svg>
);

export default RoleBindingSvg;
