import React from 'react';

const ServiceAccountSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.03533 17.50038" {...props}>
        <path d="M8.95834.46317a1.13579 1.1265 0 0 0-.43526.11015L2.58354 3.41129a1.13579 1.1265 0 0 0-.61454.76419l-1.46533 6.3748a1.13579 1.1265 0 0 0 .1542.8637 1.13579 1.1265 0 0 0 .06458.08964l4.1111 5.11154a1.13579 1.1265 0 0 0 .88801.42387l6.59282-.00152a1.13579 1.1265 0 0 0 .888-.42311l4.1096-5.1123a1.13579 1.1265 0 0 0 .21953-.95333l-1.4676-6.37481a1.13579 1.1265 0 0 0-.61454-.76419L9.50907.57332a1.13579 1.1265 0 0 0-.55073-.11015z"/>
        <path d="M8.95515.00157a1.19902 1.1892 0 0 0-.45881.11698L2.2256 3.11377a1.19902 1.1892 0 0 0-.64872.80673l-1.5466 6.72955a1.19902 1.1892 0 0 0 .16255.91231 1.19902 1.1892 0 0 0 .06837.0942l4.33976 5.3964a1.19902 1.1892 0 0 0 .93738.44742l6.95972-.00152a1.19902 1.1892 0 0 0 .93738-.44667l4.33824-5.39716a1.19902 1.1892 0 0 0 .23169-1.00575l-1.54888-6.7303a1.19902 1.1892 0 0 0-.64872-.80597L9.53703.11779a1.19902 1.1892 0 0 0-.58188-.11622zm.00304.46185a1.13579 1.1265 0 0 1 .55073.11015l5.9403 2.83645a1.13579 1.1265 0 0 1 .61454.7642l1.4676 6.3748a1.13579 1.1265 0 0 1-.21953.95333l-4.1096 5.1123a1.13579 1.1265 0 0 1-.888.42312l-6.59282.00152a1.13579 1.1265 0 0 1-.888-.42387L.7223 11.50388a1.13579 1.1265 0 0 1-.06457-.08964 1.13579 1.1265 0 0 1-.1542-.8637l1.46532-6.3748a1.13579 1.1265 0 0 1 .61454-.76419L8.52293.57358a1.13579 1.1265 0 0 1 .43526-.11015z" color="#000" fontWeight="400" fontFamily="Sans" overflow="visible" fill="#fff"/>
        <text y="16.81178" x="10.02476" fontWeight="400" fontSize="10.58333" fontFamily="Sans" letterSpacing="0" wordSpacing="0" fill="#fff" strokeWidth=".26458" transform="translate(-.99263 -1.17418)">
            <tspan y="16.81178" x="10.02476" fontSize="2.82222" fontFamily="Arial" textAnchor="middle">sa</tspan>
        </text>
        <path d="M9.01847 3.21212l-4.0416 1.7961v2.69493c0 2.49125 1.72496 4.82244 4.0416 5.38826 2.31665-.56582 4.03999-2.89701 4.03999-5.38826V5.00821zm0 1.9028c.52218 0 .99424.31524 1.19309.79861.20046.48338.09053 1.0395-.27968 1.40971-.37021.3686-.92634.48014-1.40971.27968-.48176-.20046-.797-.6709-.797-1.1947 0-.71455.57875-1.2933 1.2933-1.2933zm2.42335 5.34784c0 .03232-.0097.06305-.0275.09053-.02908.04363-.05817.08892-.09053.13094-.57067.7857-1.37899 1.36606-2.30533 1.65383-.92795-.28777-1.73627-.86814-2.30694-1.65383-.03232-.04204-.06143-.0873-.09053-.13094-.01778-.0275-.0275-.05817-.0275-.09053v-.7178c0-.99099 1.61664-1.49053 2.42496-1.49053.80832 0 2.42335.49954 2.42335 1.49054z" fill="#fff"/>
    </svg>
);

export default ServiceAccountSvg;
