import React from 'react';

const StatefulSetSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.035334 17.500378" {...props}>
        <path d="M8.99362041.46317128a1.13578782 1.12649307 0 0 0-.43526729.11014617L2.61881474 3.41129071a1.13578782 1.12649307 0 0 0-.61453947.76418673L.53895055 10.5502822a1.13578782 1.12649307 0 0 0 .15420523.8636987 1.13578782 1.12649307 0 0 0 .06456722.08963497l4.11111115 5.11154276a1.13578782 1.12649307 0 0 0 .8880063.42387233l6.5928196-.00152234a1.13578782 1.12649307 0 0 0 .8880061-.42311319l4.10959317-5.11229377a1.13578782 1.12649307 0 0 0 .219532-.95333468l-1.46760216-6.37480883a1.13578782 1.12649307 0 0 0-.61453999-.76418663L9.5443517.57331745a1.13578782 1.12649307 0 0 0-.55073128-.11014617z" />
        <path d="M8.99043163.00156985a1.19902036 1.18920801 0 0 0-.45881585.11698298L2.26087973 3.11376929a1.19902036 1.18920801 0 0 0-.64872294.8067259l-1.54660412 6.7295526a1.19902036 1.18920801 0 0 0 .1625598.9123139 1.19902036 1.18920801 0 0 0 .0683629.09419182l4.33975948 5.39640273a1.19902036 1.18920801 0 0 0 .93738165.4474218l6.95971983-.00152233a1.19902036 1.18920801 0 0 0 .93738246-.44666266l4.3382398-5.39716289a1.19902036 1.18920801 0 0 0 .2316869-1.0057476L16.4917662 3.9189759a1.19902036 1.18920801 0 0 0-.64872234-.80596625L9.57230772.11779318a1.19902036 1.18920801 0 0 0-.58187609-.11622333zm.00303452.46185444a1.13578782 1.12649307 0 0 1 .55073108.11014617l5.9402975 2.83645407a1.13578782 1.12649307 0 0 1 .61454376.76418674l1.46760303 6.37480537a1.13578782 1.12649307 0 0 1-.2195329.95333367l-4.10959207 5.1123029a1.13578782 1.12649307 0 0 1-.8880062.4231132l-6.59281909.00152233a1.13578782 1.12649307 0 0 1-.8880063-.4238703L.75757382 11.5038767a1.13578782 1.12649307 0 0 1-.06456722-.08963497 1.13578782 1.12649307 0 0 1-.15420422-.8636987l1.4653237-6.37480547a1.13578782 1.12649307 0 0 1 .61453947-.76418663L8.55820383.57357757a1.13578782 1.12649307 0 0 1 .4352674-.11014618z"
            color="#000" fontWeight="400" fontFamily="Sans" overflow="visible" fill="#fff"/>
        <text y="16.811775" x="10.056596"
            fontWeight="400" fontSize="10.58333302" fontFamily="Sans" letterSpacing="0" wordSpacing="0" fill="#fff" strokeWidth=".26458332" transform="translate(-.99262638 -1.174181)">
            <tspan
                y="16.811775" x="10.056596" fontSize="2.82222223" fontFamily="Arial" textAnchor="middle">sts</tspan>
        </text>
        <g>
            <path d="M7.32918454 3.9548946h6.5250067v4.583335h-6.5250067z" fill="none" stroke="#fff" strokeWidth=".52914584" strokeLinecap="square" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1.58743761 1.58743761" strokeDashoffset="3.66698074"/>
            <path d="M5.79043614 5.3662066h6.5250071V9.949542h-6.5250071z" fillRule="evenodd" stroke="#fff" strokeWidth=".52914584" strokeLinecap="square" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1.58743761 1.58743761" strokeDashoffset="3.87863898"/>
            <path d="M4.25170904 6.7775174h6.5249912v4.5833346h-6.5249912z" fill="#fff" fillRule="evenodd"/>
            <path d="M4.25170904 6.7775174h6.5249912v4.5833346h-6.5249912z" fill="none" stroke="#fff" strokeWidth=".5291667" strokeLinejoin="round" strokeMiterlimit="10"/>
            <path d="M4.78487104 8.0518672c0-.4294054 1.2218881-.7775054 2.7291615-.7775054 1.5072743 0 2.7291787.3481 2.7291787.7775054s-1.2219044.7775068-2.7291787.7775068c-1.5072734 0-2.7291615-.3481014-2.7291615-.7775068z" fill="#fff" fillRule="evenodd"/>
            <path d="M10.24321824 8.0518672c0 .4294054-1.2219064.7775068-2.7291777.7775068-1.5072734 0-2.7291614-.3481014-2.7291614-.7775068 0-.4294054 1.221888-.7775054 2.7291614-.7775054 1.5072733 0 2.7291777.3481 2.7291777.7775054V10.15519c0 .429405-1.2219064.777506-2.7291777.777506-1.5072734 0-2.7291614-.348101-2.7291614-.777506V8.0518672" fill="none"/>
            <path d="M10.24321824 8.0518672c0 .4294054-1.2219064.7775068-2.7291777.7775068-1.5072734 0-2.7291614-.3481014-2.7291614-.7775068 0-.4294054 1.221888-.7775054 2.7291614-.7775054 1.5072733 0 2.7291777.3481 2.7291777.7775054V10.15519c0 .429405-1.2219064.777506-2.7291777.777506-1.5072734 0-2.7291614-.348101-2.7291614-.777506V8.0518672" fill="none" stroke="#326ce5" strokeWidth=".5291667" strokeLinejoin="round" strokeMiterlimit="10"/>
        </g>
    </svg>
);

export default StatefulSetSvg;
