import React from 'react';

const CancelSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
        <path d="M112.78 205.8A66.43 66.43 0 0 0 150 217.16a66.89 66.89 0 0 0 66.8-66.8 66.43 66.43 0 0 0-11.35-37.23l-92.67 92.67zM150 83.54a66.88 66.88 0 0 0-66.8 66.81c0 15.6 5.38 29.95 14.37 41.33l93.75-93.76A66.43 66.43 0 0 0 150 83.54z"/>
        <path d="M150 0C67.16 0 0 67.16 0 150s67.16 150 150 150 150-67.16 150-150S232.84 0 150 0zm0 237.9c-48.28 0-87.56-39.27-87.56-87.55 0-48.28 39.28-87.56 87.56-87.56s87.56 39.28 87.56 87.56-39.28 87.56-87.56 87.56z"/>
    </svg>
);

export default CancelSvg;
