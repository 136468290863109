import React from 'react';

const DeleteSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.816 83.816" {...props}>
        <path d="M54.074 33.163H29.741c-.551 0-1 .449-1 1V58.83c0 .551.449 1 1 1h24.333c.551 0 1-.449 1-1V34.163c0-.552-.449-1-1-1zm-19.166 23c0 .552-.448 1-1 1s-1-.448-1-1v-19c0-.552.448-1 1-1s1 .448 1 1v19zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-19c0-.552.448-1 1-1s1 .448 1 1v19zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-19c0-.552.448-1 1-1s1 .448 1 1v19zm4 0c0 .552-.448 1-1 1-.553 0-1-.448-1-1v-19c0-.552.447-1 1-1 .552 0 1 .448 1 1v19zm4 0c0 .552-.448 1-1 1-.553 0-1-.448-1-1v-19c0-.552.447-1 1-1 .552 0 1 .448 1 1v19zM54.074 24.534h-7.167c-.552 0-1-.448-1-1v-.872c0-.551-.449-1-1-1h-6c-.551 0-1 .449-1 1v.872c0 .552-.448 1-1 1H29.74c-.551 0-1 .449-1 1v1.128c0 .551.449 1 1 1h24.333c.551 0 1-.449 1-1v-1.128c.001-.551-.448-1-.999-1z"/>
        <path d="M41.908 0C18.762 0 0 18.763 0 41.909c0 23.146 18.763 41.907 41.908 41.907s41.908-18.762 41.908-41.907C83.816 18.763 65.053 0 41.908 0zm15.166 58.829c0 1.653-1.346 3-3 3H29.741c-1.654 0-3-1.347-3-3V34.163c0-1.654 1.346-3 3-3h24.333c1.654 0 3 1.346 3 3v24.666zm0-32.166c0 1.654-1.346 3-3 3H29.741c-1.654 0-3-1.346-3-3v-1.128c0-1.654 1.346-3 3-3h6.169c.067-1.595 1.386-2.872 2.997-2.872h6c1.61 0 2.931 1.277 2.997 2.872h6.169c1.653 0 3 1.346 3 3l.001 1.128z"/>
    </svg>
);

export default DeleteSvg;
