import React from 'react';

const IngressSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.035334 17.500378" {...props}>
        <path d="M8.95834241.46317128a1.13578782 1.12649307 0 0 0-.43526729.11014617L2.58353674 3.41129071a1.13578782 1.12649307 0 0 0-.61453947.76418673L.50367255 10.5502822a1.13578782 1.12649307 0 0 0 .15420523.8636987 1.13578782 1.12649307 0 0 0 .06456722.08963497l4.11111115 5.11154276a1.13578782 1.12649307 0 0 0 .8880063.42387233l6.5928196-.00152234a1.13578782 1.12649307 0 0 0 .8880061-.42311319l4.10959317-5.11229377a1.13578782 1.12649307 0 0 0 .219532-.95333468l-1.46760216-6.37480883a1.13578782 1.12649307 0 0 0-.61453999-.76418663L9.5090737.57331745a1.13578782 1.12649307 0 0 0-.55073128-.11014617z"/>
        <path d="M8.95515363.00156985a1.19902036 1.18920801 0 0 0-.45881585.11698298L2.22560173 3.11376929a1.19902036 1.18920801 0 0 0-.64872294.8067259l-1.54660412 6.7295526a1.19902036 1.18920801 0 0 0 .1625598.9123139 1.19902036 1.18920801 0 0 0 .0683629.09419182l4.33975948 5.39640273a1.19902036 1.18920801 0 0 0 .93738165.4474218l6.95971983-.00152233a1.19902036 1.18920801 0 0 0 .93738246-.44666266l4.3382398-5.39716289a1.19902036 1.18920801 0 0 0 .2316869-1.0057476L16.4564882 3.9189759a1.19902036 1.18920801 0 0 0-.64872234-.80596625L9.53702972.11779318a1.19902036 1.18920801 0 0 0-.58187609-.11622333zm.00303452.46185444a1.13578782 1.12649307 0 0 1 .55073108.11014617l5.9402975 2.83645407a1.13578782 1.12649307 0 0 1 .61454376.76418674l1.46760303 6.37480537a1.13578782 1.12649307 0 0 1-.2195329.95333367l-4.10959207 5.1123029a1.13578782 1.12649307 0 0 1-.8880062.4231132l-6.59281909.00152233a1.13578782 1.12649307 0 0 1-.8880063-.4238703L.72229582 11.5038767a1.13578782 1.12649307 0 0 1-.06456722-.08963497 1.13578782 1.12649307 0 0 1-.15420422-.8636987l1.4653237-6.37480547a1.13578782 1.12649307 0 0 1 .61453947-.76418663L8.52292583.57357757a1.13578782 1.12649307 0 0 1 .4352674-.11014618z" color="#000" fontWeight="400" fontFamily="Sans" overflow="visible" fill="#fff"/>
        <text y="16.811775" x="10.008915" fontWeight="400" fontSize="10.58333302" fontFamily="Sans" letterSpacing="0" wordSpacing="0" fill="#fff" strokeWidth=".26458332" transform="translate(-.99262638 -1.174181)">
            <tspan y="16.811775" x="10.008915" fontSize="2.82222223" fontFamily="Arial" textAnchor="middle">ing</tspan>
        </text>
        <path d="M11.76536362 12.542075h-2.270701l-4.9209009-6.1558614h-1.42366V4.3713067h2.31473l4.9230119 6.1558533h1.37752V8.933686l3.119869 2.599882-3.119869 2.601983zm-2.47616-4.7552748l1.09864-1.3754256h1.37752v1.593475l3.119869-2.5998829-3.119869-2.601983v1.593483h-2.270701l-1.4571904 1.8241102zM5.69128172 9.151743l-1.11752 1.400578h-1.42366v2.014915h2.31473l1.4781699-1.849278z" fill="#fff"/>
    </svg>
);

export default IngressSvg;
