import React from 'react';

const SearchSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
        <path d="M139.415 96.195c-22.673 0-41.056 18.389-41.056 41.062 0 22.676 18.383 41.059 41.056 41.059 7.446 0 14.41-2.01 20.43-5.478 2.625-1.511 5.06-3.308 7.275-5.342.08-.073.163-.145.241-.218.705-.659 1.393-1.343 2.052-2.049.036-.039.07-.078.106-.117 2.754-2.977 5.073-6.367 6.86-10.068 2.596-5.387 4.095-11.404 4.095-17.787 0-22.673-18.381-41.062-41.059-41.062zm19.841 50.778h-39.684c-4.298 0-7.781-3.483-7.781-7.781s3.483-7.781 7.781-7.781h39.684c4.298 0 7.781 3.483 7.781 7.781s-3.483 7.781-7.781 7.781z"/>
        <path d="M149.995 0C67.156 0 0 67.158 0 149.995s67.156 150 149.995 150 150-67.163 150-150S232.834 0 149.995 0zm75.442 221.254c-2.371 2.376-5.48 3.561-8.59 3.561-3.11 0-6.217-1.185-8.593-3.561l-34.145-34.147c-9.837 6.863-21.791 10.896-34.697 10.896-33.548 0-60.742-27.196-60.742-60.744s27.194-60.742 60.742-60.742 60.744 27.194 60.744 60.742c0 11.855-3.408 22.909-9.28 32.259l34.56 34.56c4.747 4.739 4.747 12.434.001 17.176z"/>
    </svg>
);

export default SearchSvg;
