/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars, no-mixed-operators, strict*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.k8s = (function() {

    /**
     * Namespace k8s.
     * @exports k8s
     * @namespace
     */
    var k8s = {};

    k8s.io = (function() {

        /**
         * Namespace io.
         * @memberof k8s
         * @namespace
         */
        var io = {};

        io.metrics = (function() {

            /**
             * Namespace metrics.
             * @memberof k8s.io
             * @namespace
             */
            var metrics = {};

            metrics.pkg = (function() {

                /**
                 * Namespace pkg.
                 * @memberof k8s.io.metrics
                 * @namespace
                 */
                var pkg = {};

                pkg.apis = (function() {

                    /**
                     * Namespace apis.
                     * @memberof k8s.io.metrics.pkg
                     * @namespace
                     */
                    var apis = {};

                    apis.metrics = (function() {

                        /**
                         * Namespace metrics.
                         * @memberof k8s.io.metrics.pkg.apis
                         * @namespace
                         */
                        var metrics = {};

                        metrics.v1beta1 = (function() {

                            /**
                             * Namespace v1beta1.
                             * @memberof k8s.io.metrics.pkg.apis.metrics
                             * @namespace
                             */
                            var v1beta1 = {};

                            v1beta1.ContainerMetrics = (function() {

                                /**
                                 * Properties of a ContainerMetrics.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @interface IContainerMetrics
                                 * @property {string|null} [name] ContainerMetrics name
                                 * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [usage] ContainerMetrics usage
                                 */

                                /**
                                 * Constructs a new ContainerMetrics.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @classdesc Represents a ContainerMetrics.
                                 * @implements IContainerMetrics
                                 * @constructor
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IContainerMetrics=} [properties] Properties to set
                                 */
                                function ContainerMetrics(properties) {
                                    this.usage = {};
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ContainerMetrics name.
                                 * @member {string} name
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @instance
                                 */
                                ContainerMetrics.prototype.name = "";

                                /**
                                 * ContainerMetrics usage.
                                 * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} usage
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @instance
                                 */
                                ContainerMetrics.prototype.usage = $util.emptyObject;

                                /**
                                 * Creates a new ContainerMetrics instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IContainerMetrics=} [properties] Properties to set
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics} ContainerMetrics instance
                                 */
                                ContainerMetrics.create = function create(properties) {
                                    return new ContainerMetrics(properties);
                                };

                                /**
                                 * Encodes the specified ContainerMetrics message. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IContainerMetrics} message ContainerMetrics message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ContainerMetrics.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                                    if (message.usage != null && Object.hasOwnProperty.call(message, "usage"))
                                        for (var keys = Object.keys(message.usage), i = 0; i < keys.length; ++i) {
                                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                            $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.usage[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                        }
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ContainerMetrics message, length delimited. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IContainerMetrics} message ContainerMetrics message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ContainerMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a ContainerMetrics message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics} ContainerMetrics
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ContainerMetrics.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics(), key, value;
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.name = reader.string();
                                            break;
                                        case 2:
                                            if (message.usage === $util.emptyObject)
                                                message.usage = {};
                                            var end2 = reader.uint32() + reader.pos;
                                            key = "";
                                            value = null;
                                            while (reader.pos < end2) {
                                                var tag2 = reader.uint32();
                                                switch (tag2 >>> 3) {
                                                case 1:
                                                    key = reader.string();
                                                    break;
                                                case 2:
                                                    value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                                    break;
                                                default:
                                                    reader.skipType(tag2 & 7);
                                                    break;
                                                }
                                            }
                                            message.usage[key] = value;
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a ContainerMetrics message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics} ContainerMetrics
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ContainerMetrics.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a ContainerMetrics message.
                                 * @function verify
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ContainerMetrics.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        if (!$util.isString(message.name))
                                            return "name: string expected";
                                    if (message.usage != null && message.hasOwnProperty("usage")) {
                                        if (!$util.isObject(message.usage))
                                            return "usage: object expected";
                                        var key = Object.keys(message.usage);
                                        for (var i = 0; i < key.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.usage[key[i]]);
                                            if (error)
                                                return "usage." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a ContainerMetrics message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics} ContainerMetrics
                                 */
                                ContainerMetrics.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics)
                                        return object;
                                    var message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics();
                                    if (object.name != null)
                                        message.name = String(object.name);
                                    if (object.usage) {
                                        if (typeof object.usage !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.usage: object expected");
                                        message.usage = {};
                                        for (var keys = Object.keys(object.usage), i = 0; i < keys.length; ++i) {
                                            if (typeof object.usage[keys[i]] !== "object")
                                                throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.usage: object expected");
                                            message.usage[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.usage[keys[i]]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a ContainerMetrics message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics} message ContainerMetrics
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ContainerMetrics.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.objects || options.defaults)
                                        object.usage = {};
                                    if (options.defaults)
                                        object.name = "";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        object.name = message.name;
                                    var keys2;
                                    if (message.usage && (keys2 = Object.keys(message.usage)).length) {
                                        object.usage = {};
                                        for (var j = 0; j < keys2.length; ++j)
                                            object.usage[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.usage[keys2[j]], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this ContainerMetrics to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ContainerMetrics.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ContainerMetrics;
                            })();

                            v1beta1.NodeMetrics = (function() {

                                /**
                                 * Properties of a NodeMetrics.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @interface INodeMetrics
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] NodeMetrics metadata
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [timestamp] NodeMetrics timestamp
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration|null} [window] NodeMetrics window
                                 * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [usage] NodeMetrics usage
                                 */

                                /**
                                 * Constructs a new NodeMetrics.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @classdesc Represents a NodeMetrics.
                                 * @implements INodeMetrics
                                 * @constructor
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetrics=} [properties] Properties to set
                                 */
                                function NodeMetrics(properties) {
                                    this.usage = {};
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * NodeMetrics metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @instance
                                 */
                                NodeMetrics.prototype.metadata = null;

                                /**
                                 * NodeMetrics timestamp.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} timestamp
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @instance
                                 */
                                NodeMetrics.prototype.timestamp = null;

                                /**
                                 * NodeMetrics window.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration|null|undefined} window
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @instance
                                 */
                                NodeMetrics.prototype.window = null;

                                /**
                                 * NodeMetrics usage.
                                 * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} usage
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @instance
                                 */
                                NodeMetrics.prototype.usage = $util.emptyObject;

                                /**
                                 * Creates a new NodeMetrics instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetrics=} [properties] Properties to set
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics} NodeMetrics instance
                                 */
                                NodeMetrics.create = function create(properties) {
                                    return new NodeMetrics(properties);
                                };

                                /**
                                 * Encodes the specified NodeMetrics message. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetrics} message NodeMetrics message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                NodeMetrics.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.timestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    if (message.window != null && Object.hasOwnProperty.call(message, "window"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.encode(message.window, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                                    if (message.usage != null && Object.hasOwnProperty.call(message, "usage"))
                                        for (var keys = Object.keys(message.usage), i = 0; i < keys.length; ++i) {
                                            writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                            $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.usage[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                        }
                                    return writer;
                                };

                                /**
                                 * Encodes the specified NodeMetrics message, length delimited. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetrics} message NodeMetrics message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                NodeMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a NodeMetrics message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics} NodeMetrics
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                NodeMetrics.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics(), key, value;
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            message.timestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                            break;
                                        case 3:
                                            message.window = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.decode(reader, reader.uint32());
                                            break;
                                        case 4:
                                            if (message.usage === $util.emptyObject)
                                                message.usage = {};
                                            var end2 = reader.uint32() + reader.pos;
                                            key = "";
                                            value = null;
                                            while (reader.pos < end2) {
                                                var tag2 = reader.uint32();
                                                switch (tag2 >>> 3) {
                                                case 1:
                                                    key = reader.string();
                                                    break;
                                                case 2:
                                                    value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                                    break;
                                                default:
                                                    reader.skipType(tag2 & 7);
                                                    break;
                                                }
                                            }
                                            message.usage[key] = value;
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a NodeMetrics message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics} NodeMetrics
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                NodeMetrics.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a NodeMetrics message.
                                 * @function verify
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                NodeMetrics.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.timestamp);
                                        if (error)
                                            return "timestamp." + error;
                                    }
                                    if (message.window != null && message.hasOwnProperty("window")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.verify(message.window);
                                        if (error)
                                            return "window." + error;
                                    }
                                    if (message.usage != null && message.hasOwnProperty("usage")) {
                                        if (!$util.isObject(message.usage))
                                            return "usage: object expected";
                                        var key = Object.keys(message.usage);
                                        for (var i = 0; i < key.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.usage[key[i]]);
                                            if (error)
                                                return "usage." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a NodeMetrics message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics} NodeMetrics
                                 */
                                NodeMetrics.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics)
                                        return object;
                                    var message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                                    }
                                    if (object.timestamp != null) {
                                        if (typeof object.timestamp !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.timestamp: object expected");
                                        message.timestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.timestamp);
                                    }
                                    if (object.window != null) {
                                        if (typeof object.window !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.window: object expected");
                                        message.window = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.fromObject(object.window);
                                    }
                                    if (object.usage) {
                                        if (typeof object.usage !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.usage: object expected");
                                        message.usage = {};
                                        for (var keys = Object.keys(object.usage), i = 0; i < keys.length; ++i) {
                                            if (typeof object.usage[keys[i]] !== "object")
                                                throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.usage: object expected");
                                            message.usage[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.usage[keys[i]]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a NodeMetrics message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics} message NodeMetrics
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                NodeMetrics.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.objects || options.defaults)
                                        object.usage = {};
                                    if (options.defaults) {
                                        object.metadata = null;
                                        object.timestamp = null;
                                        object.window = null;
                                    }
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                                        object.timestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.timestamp, options);
                                    if (message.window != null && message.hasOwnProperty("window"))
                                        object.window = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.toObject(message.window, options);
                                    var keys2;
                                    if (message.usage && (keys2 = Object.keys(message.usage)).length) {
                                        object.usage = {};
                                        for (var j = 0; j < keys2.length; ++j)
                                            object.usage[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.usage[keys2[j]], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this NodeMetrics to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                NodeMetrics.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return NodeMetrics;
                            })();

                            v1beta1.NodeMetricsList = (function() {

                                /**
                                 * Properties of a NodeMetricsList.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @interface INodeMetricsList
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] NodeMetricsList metadata
                                 * @property {Array.<k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetrics>|null} [items] NodeMetricsList items
                                 */

                                /**
                                 * Constructs a new NodeMetricsList.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @classdesc Represents a NodeMetricsList.
                                 * @implements INodeMetricsList
                                 * @constructor
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetricsList=} [properties] Properties to set
                                 */
                                function NodeMetricsList(properties) {
                                    this.items = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * NodeMetricsList metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @instance
                                 */
                                NodeMetricsList.prototype.metadata = null;

                                /**
                                 * NodeMetricsList items.
                                 * @member {Array.<k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetrics>} items
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @instance
                                 */
                                NodeMetricsList.prototype.items = $util.emptyArray;

                                /**
                                 * Creates a new NodeMetricsList instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetricsList=} [properties] Properties to set
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList} NodeMetricsList instance
                                 */
                                NodeMetricsList.create = function create(properties) {
                                    return new NodeMetricsList(properties);
                                };

                                /**
                                 * Encodes the specified NodeMetricsList message. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetricsList} message NodeMetricsList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                NodeMetricsList.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.items != null && message.items.length)
                                        for (var i = 0; i < message.items.length; ++i)
                                            $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified NodeMetricsList message, length delimited. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.INodeMetricsList} message NodeMetricsList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                NodeMetricsList.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a NodeMetricsList message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList} NodeMetricsList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                NodeMetricsList.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            if (!(message.items && message.items.length))
                                                message.items = [];
                                            message.items.push($root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a NodeMetricsList message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList} NodeMetricsList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                NodeMetricsList.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a NodeMetricsList message.
                                 * @function verify
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                NodeMetricsList.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.items != null && message.hasOwnProperty("items")) {
                                        if (!Array.isArray(message.items))
                                            return "items: array expected";
                                        for (var i = 0; i < message.items.length; ++i) {
                                            var error = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.verify(message.items[i]);
                                            if (error)
                                                return "items." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a NodeMetricsList message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList} NodeMetricsList
                                 */
                                NodeMetricsList.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList)
                                        return object;
                                    var message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                                    }
                                    if (object.items) {
                                        if (!Array.isArray(object.items))
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList.items: array expected");
                                        message.items = [];
                                        for (var i = 0; i < object.items.length; ++i) {
                                            if (typeof object.items[i] !== "object")
                                                throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList.items: object expected");
                                            message.items[i] = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.fromObject(object.items[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a NodeMetricsList message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList} message NodeMetricsList
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                NodeMetricsList.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.items = [];
                                    if (options.defaults)
                                        object.metadata = null;
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                                    if (message.items && message.items.length) {
                                        object.items = [];
                                        for (var j = 0; j < message.items.length; ++j)
                                            object.items[j] = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetrics.toObject(message.items[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this NodeMetricsList to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.NodeMetricsList
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                NodeMetricsList.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return NodeMetricsList;
                            })();

                            v1beta1.PodMetrics = (function() {

                                /**
                                 * Properties of a PodMetrics.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @interface IPodMetrics
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PodMetrics metadata
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [timestamp] PodMetrics timestamp
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration|null} [window] PodMetrics window
                                 * @property {Array.<k8s.io.metrics.pkg.apis.metrics.v1beta1.IContainerMetrics>|null} [containers] PodMetrics containers
                                 */

                                /**
                                 * Constructs a new PodMetrics.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @classdesc Represents a PodMetrics.
                                 * @implements IPodMetrics
                                 * @constructor
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetrics=} [properties] Properties to set
                                 */
                                function PodMetrics(properties) {
                                    this.containers = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * PodMetrics metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @instance
                                 */
                                PodMetrics.prototype.metadata = null;

                                /**
                                 * PodMetrics timestamp.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} timestamp
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @instance
                                 */
                                PodMetrics.prototype.timestamp = null;

                                /**
                                 * PodMetrics window.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration|null|undefined} window
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @instance
                                 */
                                PodMetrics.prototype.window = null;

                                /**
                                 * PodMetrics containers.
                                 * @member {Array.<k8s.io.metrics.pkg.apis.metrics.v1beta1.IContainerMetrics>} containers
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @instance
                                 */
                                PodMetrics.prototype.containers = $util.emptyArray;

                                /**
                                 * Creates a new PodMetrics instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetrics=} [properties] Properties to set
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics} PodMetrics instance
                                 */
                                PodMetrics.create = function create(properties) {
                                    return new PodMetrics(properties);
                                };

                                /**
                                 * Encodes the specified PodMetrics message. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetrics} message PodMetrics message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PodMetrics.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.timestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    if (message.window != null && Object.hasOwnProperty.call(message, "window"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.encode(message.window, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                                    if (message.containers != null && message.containers.length)
                                        for (var i = 0; i < message.containers.length; ++i)
                                            $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.encode(message.containers[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified PodMetrics message, length delimited. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetrics} message PodMetrics message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PodMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a PodMetrics message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics} PodMetrics
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PodMetrics.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            message.timestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                            break;
                                        case 3:
                                            message.window = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.decode(reader, reader.uint32());
                                            break;
                                        case 4:
                                            if (!(message.containers && message.containers.length))
                                                message.containers = [];
                                            message.containers.push($root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a PodMetrics message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics} PodMetrics
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PodMetrics.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a PodMetrics message.
                                 * @function verify
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                PodMetrics.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.timestamp);
                                        if (error)
                                            return "timestamp." + error;
                                    }
                                    if (message.window != null && message.hasOwnProperty("window")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.verify(message.window);
                                        if (error)
                                            return "window." + error;
                                    }
                                    if (message.containers != null && message.hasOwnProperty("containers")) {
                                        if (!Array.isArray(message.containers))
                                            return "containers: array expected";
                                        for (var i = 0; i < message.containers.length; ++i) {
                                            var error = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.verify(message.containers[i]);
                                            if (error)
                                                return "containers." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a PodMetrics message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics} PodMetrics
                                 */
                                PodMetrics.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics)
                                        return object;
                                    var message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                                    }
                                    if (object.timestamp != null) {
                                        if (typeof object.timestamp !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.timestamp: object expected");
                                        message.timestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.timestamp);
                                    }
                                    if (object.window != null) {
                                        if (typeof object.window !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.window: object expected");
                                        message.window = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.fromObject(object.window);
                                    }
                                    if (object.containers) {
                                        if (!Array.isArray(object.containers))
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.containers: array expected");
                                        message.containers = [];
                                        for (var i = 0; i < object.containers.length; ++i) {
                                            if (typeof object.containers[i] !== "object")
                                                throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.containers: object expected");
                                            message.containers[i] = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.fromObject(object.containers[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a PodMetrics message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics} message PodMetrics
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                PodMetrics.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.containers = [];
                                    if (options.defaults) {
                                        object.metadata = null;
                                        object.timestamp = null;
                                        object.window = null;
                                    }
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                                        object.timestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.timestamp, options);
                                    if (message.window != null && message.hasOwnProperty("window"))
                                        object.window = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration.toObject(message.window, options);
                                    if (message.containers && message.containers.length) {
                                        object.containers = [];
                                        for (var j = 0; j < message.containers.length; ++j)
                                            object.containers[j] = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.ContainerMetrics.toObject(message.containers[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this PodMetrics to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                PodMetrics.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return PodMetrics;
                            })();

                            v1beta1.PodMetricsList = (function() {

                                /**
                                 * Properties of a PodMetricsList.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @interface IPodMetricsList
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] PodMetricsList metadata
                                 * @property {Array.<k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetrics>|null} [items] PodMetricsList items
                                 */

                                /**
                                 * Constructs a new PodMetricsList.
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1
                                 * @classdesc Represents a PodMetricsList.
                                 * @implements IPodMetricsList
                                 * @constructor
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetricsList=} [properties] Properties to set
                                 */
                                function PodMetricsList(properties) {
                                    this.items = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * PodMetricsList metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @instance
                                 */
                                PodMetricsList.prototype.metadata = null;

                                /**
                                 * PodMetricsList items.
                                 * @member {Array.<k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetrics>} items
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @instance
                                 */
                                PodMetricsList.prototype.items = $util.emptyArray;

                                /**
                                 * Creates a new PodMetricsList instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetricsList=} [properties] Properties to set
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList} PodMetricsList instance
                                 */
                                PodMetricsList.create = function create(properties) {
                                    return new PodMetricsList(properties);
                                };

                                /**
                                 * Encodes the specified PodMetricsList message. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetricsList} message PodMetricsList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PodMetricsList.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.items != null && message.items.length)
                                        for (var i = 0; i < message.items.length; ++i)
                                            $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified PodMetricsList message, length delimited. Does not implicitly {@link k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.IPodMetricsList} message PodMetricsList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PodMetricsList.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a PodMetricsList message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList} PodMetricsList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PodMetricsList.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            if (!(message.items && message.items.length))
                                                message.items = [];
                                            message.items.push($root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a PodMetricsList message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList} PodMetricsList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PodMetricsList.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a PodMetricsList message.
                                 * @function verify
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                PodMetricsList.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.items != null && message.hasOwnProperty("items")) {
                                        if (!Array.isArray(message.items))
                                            return "items: array expected";
                                        for (var i = 0; i < message.items.length; ++i) {
                                            var error = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.verify(message.items[i]);
                                            if (error)
                                                return "items." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a PodMetricsList message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList} PodMetricsList
                                 */
                                PodMetricsList.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList)
                                        return object;
                                    var message = new $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                                    }
                                    if (object.items) {
                                        if (!Array.isArray(object.items))
                                            throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList.items: array expected");
                                        message.items = [];
                                        for (var i = 0; i < object.items.length; ++i) {
                                            if (typeof object.items[i] !== "object")
                                                throw TypeError(".k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList.items: object expected");
                                            message.items[i] = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.fromObject(object.items[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a PodMetricsList message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @static
                                 * @param {k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList} message PodMetricsList
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                PodMetricsList.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.items = [];
                                    if (options.defaults)
                                        object.metadata = null;
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                                    if (message.items && message.items.length) {
                                        object.items = [];
                                        for (var j = 0; j < message.items.length; ++j)
                                            object.items[j] = $root.k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetrics.toObject(message.items[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this PodMetricsList to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.metrics.pkg.apis.metrics.v1beta1.PodMetricsList
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                PodMetricsList.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return PodMetricsList;
                            })();

                            return v1beta1;
                        })();

                        return metrics;
                    })();

                    return apis;
                })();

                return pkg;
            })();

            return metrics;
        })();

        io.api = (function() {

            /**
             * Namespace api.
             * @memberof k8s.io
             * @namespace
             */
            var api = {};

            api.core = (function() {

                /**
                 * Namespace core.
                 * @memberof k8s.io.api
                 * @namespace
                 */
                var core = {};

                core.v1 = (function() {

                    /**
                     * Namespace v1.
                     * @memberof k8s.io.api.core
                     * @namespace
                     */
                    var v1 = {};

                    v1.AWSElasticBlockStoreVolumeSource = (function() {

                        /**
                         * Properties of a AWSElasticBlockStoreVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAWSElasticBlockStoreVolumeSource
                         * @property {string|null} [volumeID] AWSElasticBlockStoreVolumeSource volumeID
                         * @property {string|null} [fsType] AWSElasticBlockStoreVolumeSource fsType
                         * @property {number|null} [partition] AWSElasticBlockStoreVolumeSource partition
                         * @property {boolean|null} [readOnly] AWSElasticBlockStoreVolumeSource readOnly
                         */

                        /**
                         * Constructs a new AWSElasticBlockStoreVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a AWSElasticBlockStoreVolumeSource.
                         * @implements IAWSElasticBlockStoreVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource=} [properties] Properties to set
                         */
                        function AWSElasticBlockStoreVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AWSElasticBlockStoreVolumeSource volumeID.
                         * @member {string} volumeID
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @instance
                         */
                        AWSElasticBlockStoreVolumeSource.prototype.volumeID = "";

                        /**
                         * AWSElasticBlockStoreVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @instance
                         */
                        AWSElasticBlockStoreVolumeSource.prototype.fsType = "";

                        /**
                         * AWSElasticBlockStoreVolumeSource partition.
                         * @member {number} partition
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @instance
                         */
                        AWSElasticBlockStoreVolumeSource.prototype.partition = 0;

                        /**
                         * AWSElasticBlockStoreVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @instance
                         */
                        AWSElasticBlockStoreVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new AWSElasticBlockStoreVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource} AWSElasticBlockStoreVolumeSource instance
                         */
                        AWSElasticBlockStoreVolumeSource.create = function create(properties) {
                            return new AWSElasticBlockStoreVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified AWSElasticBlockStoreVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource} message AWSElasticBlockStoreVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AWSElasticBlockStoreVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeID != null && Object.hasOwnProperty.call(message, "volumeID"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumeID);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.partition != null && Object.hasOwnProperty.call(message, "partition"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.partition);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified AWSElasticBlockStoreVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource} message AWSElasticBlockStoreVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AWSElasticBlockStoreVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a AWSElasticBlockStoreVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource} AWSElasticBlockStoreVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AWSElasticBlockStoreVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeID = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.partition = reader.int32();
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a AWSElasticBlockStoreVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource} AWSElasticBlockStoreVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AWSElasticBlockStoreVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a AWSElasticBlockStoreVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AWSElasticBlockStoreVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                if (!$util.isString(message.volumeID))
                                    return "volumeID: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.partition != null && message.hasOwnProperty("partition"))
                                if (!$util.isInteger(message.partition))
                                    return "partition: integer expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a AWSElasticBlockStoreVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource} AWSElasticBlockStoreVolumeSource
                         */
                        AWSElasticBlockStoreVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource();
                            if (object.volumeID != null)
                                message.volumeID = String(object.volumeID);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.partition != null)
                                message.partition = object.partition | 0;
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a AWSElasticBlockStoreVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource} message AWSElasticBlockStoreVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AWSElasticBlockStoreVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeID = "";
                                object.fsType = "";
                                object.partition = 0;
                                object.readOnly = false;
                            }
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                object.volumeID = message.volumeID;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.partition != null && message.hasOwnProperty("partition"))
                                object.partition = message.partition;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this AWSElasticBlockStoreVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AWSElasticBlockStoreVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AWSElasticBlockStoreVolumeSource;
                    })();

                    v1.Affinity = (function() {

                        /**
                         * Properties of an Affinity.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAffinity
                         * @property {k8s.io.api.core.v1.INodeAffinity|null} [nodeAffinity] Affinity nodeAffinity
                         * @property {k8s.io.api.core.v1.IPodAffinity|null} [podAffinity] Affinity podAffinity
                         * @property {k8s.io.api.core.v1.IPodAntiAffinity|null} [podAntiAffinity] Affinity podAntiAffinity
                         */

                        /**
                         * Constructs a new Affinity.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an Affinity.
                         * @implements IAffinity
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAffinity=} [properties] Properties to set
                         */
                        function Affinity(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Affinity nodeAffinity.
                         * @member {k8s.io.api.core.v1.INodeAffinity|null|undefined} nodeAffinity
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @instance
                         */
                        Affinity.prototype.nodeAffinity = null;

                        /**
                         * Affinity podAffinity.
                         * @member {k8s.io.api.core.v1.IPodAffinity|null|undefined} podAffinity
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @instance
                         */
                        Affinity.prototype.podAffinity = null;

                        /**
                         * Affinity podAntiAffinity.
                         * @member {k8s.io.api.core.v1.IPodAntiAffinity|null|undefined} podAntiAffinity
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @instance
                         */
                        Affinity.prototype.podAntiAffinity = null;

                        /**
                         * Creates a new Affinity instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {k8s.io.api.core.v1.IAffinity=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Affinity} Affinity instance
                         */
                        Affinity.create = function create(properties) {
                            return new Affinity(properties);
                        };

                        /**
                         * Encodes the specified Affinity message. Does not implicitly {@link k8s.io.api.core.v1.Affinity.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {k8s.io.api.core.v1.IAffinity} message Affinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Affinity.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.nodeAffinity != null && Object.hasOwnProperty.call(message, "nodeAffinity"))
                                $root.k8s.io.api.core.v1.NodeAffinity.encode(message.nodeAffinity, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.podAffinity != null && Object.hasOwnProperty.call(message, "podAffinity"))
                                $root.k8s.io.api.core.v1.PodAffinity.encode(message.podAffinity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.podAntiAffinity != null && Object.hasOwnProperty.call(message, "podAntiAffinity"))
                                $root.k8s.io.api.core.v1.PodAntiAffinity.encode(message.podAntiAffinity, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Affinity message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Affinity.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {k8s.io.api.core.v1.IAffinity} message Affinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Affinity.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Affinity message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Affinity} Affinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Affinity.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Affinity();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.nodeAffinity = $root.k8s.io.api.core.v1.NodeAffinity.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.podAffinity = $root.k8s.io.api.core.v1.PodAffinity.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.podAntiAffinity = $root.k8s.io.api.core.v1.PodAntiAffinity.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Affinity message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Affinity} Affinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Affinity.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Affinity message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Affinity.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.nodeAffinity != null && message.hasOwnProperty("nodeAffinity")) {
                                var error = $root.k8s.io.api.core.v1.NodeAffinity.verify(message.nodeAffinity);
                                if (error)
                                    return "nodeAffinity." + error;
                            }
                            if (message.podAffinity != null && message.hasOwnProperty("podAffinity")) {
                                var error = $root.k8s.io.api.core.v1.PodAffinity.verify(message.podAffinity);
                                if (error)
                                    return "podAffinity." + error;
                            }
                            if (message.podAntiAffinity != null && message.hasOwnProperty("podAntiAffinity")) {
                                var error = $root.k8s.io.api.core.v1.PodAntiAffinity.verify(message.podAntiAffinity);
                                if (error)
                                    return "podAntiAffinity." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an Affinity message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Affinity} Affinity
                         */
                        Affinity.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Affinity)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Affinity();
                            if (object.nodeAffinity != null) {
                                if (typeof object.nodeAffinity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Affinity.nodeAffinity: object expected");
                                message.nodeAffinity = $root.k8s.io.api.core.v1.NodeAffinity.fromObject(object.nodeAffinity);
                            }
                            if (object.podAffinity != null) {
                                if (typeof object.podAffinity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Affinity.podAffinity: object expected");
                                message.podAffinity = $root.k8s.io.api.core.v1.PodAffinity.fromObject(object.podAffinity);
                            }
                            if (object.podAntiAffinity != null) {
                                if (typeof object.podAntiAffinity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Affinity.podAntiAffinity: object expected");
                                message.podAntiAffinity = $root.k8s.io.api.core.v1.PodAntiAffinity.fromObject(object.podAntiAffinity);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an Affinity message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @static
                         * @param {k8s.io.api.core.v1.Affinity} message Affinity
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Affinity.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.nodeAffinity = null;
                                object.podAffinity = null;
                                object.podAntiAffinity = null;
                            }
                            if (message.nodeAffinity != null && message.hasOwnProperty("nodeAffinity"))
                                object.nodeAffinity = $root.k8s.io.api.core.v1.NodeAffinity.toObject(message.nodeAffinity, options);
                            if (message.podAffinity != null && message.hasOwnProperty("podAffinity"))
                                object.podAffinity = $root.k8s.io.api.core.v1.PodAffinity.toObject(message.podAffinity, options);
                            if (message.podAntiAffinity != null && message.hasOwnProperty("podAntiAffinity"))
                                object.podAntiAffinity = $root.k8s.io.api.core.v1.PodAntiAffinity.toObject(message.podAntiAffinity, options);
                            return object;
                        };

                        /**
                         * Converts this Affinity to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Affinity
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Affinity.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Affinity;
                    })();

                    v1.AttachedVolume = (function() {

                        /**
                         * Properties of an AttachedVolume.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAttachedVolume
                         * @property {string|null} [name] AttachedVolume name
                         * @property {string|null} [devicePath] AttachedVolume devicePath
                         */

                        /**
                         * Constructs a new AttachedVolume.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an AttachedVolume.
                         * @implements IAttachedVolume
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAttachedVolume=} [properties] Properties to set
                         */
                        function AttachedVolume(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AttachedVolume name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @instance
                         */
                        AttachedVolume.prototype.name = "";

                        /**
                         * AttachedVolume devicePath.
                         * @member {string} devicePath
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @instance
                         */
                        AttachedVolume.prototype.devicePath = "";

                        /**
                         * Creates a new AttachedVolume instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {k8s.io.api.core.v1.IAttachedVolume=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.AttachedVolume} AttachedVolume instance
                         */
                        AttachedVolume.create = function create(properties) {
                            return new AttachedVolume(properties);
                        };

                        /**
                         * Encodes the specified AttachedVolume message. Does not implicitly {@link k8s.io.api.core.v1.AttachedVolume.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {k8s.io.api.core.v1.IAttachedVolume} message AttachedVolume message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AttachedVolume.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.devicePath != null && Object.hasOwnProperty.call(message, "devicePath"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.devicePath);
                            return writer;
                        };

                        /**
                         * Encodes the specified AttachedVolume message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.AttachedVolume.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {k8s.io.api.core.v1.IAttachedVolume} message AttachedVolume message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AttachedVolume.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AttachedVolume message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.AttachedVolume} AttachedVolume
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AttachedVolume.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.AttachedVolume();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.devicePath = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AttachedVolume message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.AttachedVolume} AttachedVolume
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AttachedVolume.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AttachedVolume message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AttachedVolume.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.devicePath != null && message.hasOwnProperty("devicePath"))
                                if (!$util.isString(message.devicePath))
                                    return "devicePath: string expected";
                            return null;
                        };

                        /**
                         * Creates an AttachedVolume message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.AttachedVolume} AttachedVolume
                         */
                        AttachedVolume.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.AttachedVolume)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.AttachedVolume();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.devicePath != null)
                                message.devicePath = String(object.devicePath);
                            return message;
                        };

                        /**
                         * Creates a plain object from an AttachedVolume message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @static
                         * @param {k8s.io.api.core.v1.AttachedVolume} message AttachedVolume
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AttachedVolume.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.devicePath = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.devicePath != null && message.hasOwnProperty("devicePath"))
                                object.devicePath = message.devicePath;
                            return object;
                        };

                        /**
                         * Converts this AttachedVolume to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.AttachedVolume
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AttachedVolume.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AttachedVolume;
                    })();

                    v1.AvoidPods = (function() {

                        /**
                         * Properties of an AvoidPods.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAvoidPods
                         * @property {Array.<k8s.io.api.core.v1.IPreferAvoidPodsEntry>|null} [preferAvoidPods] AvoidPods preferAvoidPods
                         */

                        /**
                         * Constructs a new AvoidPods.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an AvoidPods.
                         * @implements IAvoidPods
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAvoidPods=} [properties] Properties to set
                         */
                        function AvoidPods(properties) {
                            this.preferAvoidPods = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AvoidPods preferAvoidPods.
                         * @member {Array.<k8s.io.api.core.v1.IPreferAvoidPodsEntry>} preferAvoidPods
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @instance
                         */
                        AvoidPods.prototype.preferAvoidPods = $util.emptyArray;

                        /**
                         * Creates a new AvoidPods instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {k8s.io.api.core.v1.IAvoidPods=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.AvoidPods} AvoidPods instance
                         */
                        AvoidPods.create = function create(properties) {
                            return new AvoidPods(properties);
                        };

                        /**
                         * Encodes the specified AvoidPods message. Does not implicitly {@link k8s.io.api.core.v1.AvoidPods.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {k8s.io.api.core.v1.IAvoidPods} message AvoidPods message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AvoidPods.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.preferAvoidPods != null && message.preferAvoidPods.length)
                                for (var i = 0; i < message.preferAvoidPods.length; ++i)
                                    $root.k8s.io.api.core.v1.PreferAvoidPodsEntry.encode(message.preferAvoidPods[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified AvoidPods message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.AvoidPods.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {k8s.io.api.core.v1.IAvoidPods} message AvoidPods message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AvoidPods.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AvoidPods message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.AvoidPods} AvoidPods
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AvoidPods.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.AvoidPods();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.preferAvoidPods && message.preferAvoidPods.length))
                                        message.preferAvoidPods = [];
                                    message.preferAvoidPods.push($root.k8s.io.api.core.v1.PreferAvoidPodsEntry.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AvoidPods message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.AvoidPods} AvoidPods
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AvoidPods.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AvoidPods message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AvoidPods.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.preferAvoidPods != null && message.hasOwnProperty("preferAvoidPods")) {
                                if (!Array.isArray(message.preferAvoidPods))
                                    return "preferAvoidPods: array expected";
                                for (var i = 0; i < message.preferAvoidPods.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PreferAvoidPodsEntry.verify(message.preferAvoidPods[i]);
                                    if (error)
                                        return "preferAvoidPods." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an AvoidPods message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.AvoidPods} AvoidPods
                         */
                        AvoidPods.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.AvoidPods)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.AvoidPods();
                            if (object.preferAvoidPods) {
                                if (!Array.isArray(object.preferAvoidPods))
                                    throw TypeError(".k8s.io.api.core.v1.AvoidPods.preferAvoidPods: array expected");
                                message.preferAvoidPods = [];
                                for (var i = 0; i < object.preferAvoidPods.length; ++i) {
                                    if (typeof object.preferAvoidPods[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.AvoidPods.preferAvoidPods: object expected");
                                    message.preferAvoidPods[i] = $root.k8s.io.api.core.v1.PreferAvoidPodsEntry.fromObject(object.preferAvoidPods[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an AvoidPods message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @static
                         * @param {k8s.io.api.core.v1.AvoidPods} message AvoidPods
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AvoidPods.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.preferAvoidPods = [];
                            if (message.preferAvoidPods && message.preferAvoidPods.length) {
                                object.preferAvoidPods = [];
                                for (var j = 0; j < message.preferAvoidPods.length; ++j)
                                    object.preferAvoidPods[j] = $root.k8s.io.api.core.v1.PreferAvoidPodsEntry.toObject(message.preferAvoidPods[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this AvoidPods to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.AvoidPods
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AvoidPods.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AvoidPods;
                    })();

                    v1.AzureDiskVolumeSource = (function() {

                        /**
                         * Properties of an AzureDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAzureDiskVolumeSource
                         * @property {string|null} [diskName] AzureDiskVolumeSource diskName
                         * @property {string|null} [diskURI] AzureDiskVolumeSource diskURI
                         * @property {string|null} [cachingMode] AzureDiskVolumeSource cachingMode
                         * @property {string|null} [fsType] AzureDiskVolumeSource fsType
                         * @property {boolean|null} [readOnly] AzureDiskVolumeSource readOnly
                         * @property {string|null} [kind] AzureDiskVolumeSource kind
                         */

                        /**
                         * Constructs a new AzureDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an AzureDiskVolumeSource.
                         * @implements IAzureDiskVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAzureDiskVolumeSource=} [properties] Properties to set
                         */
                        function AzureDiskVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AzureDiskVolumeSource diskName.
                         * @member {string} diskName
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         */
                        AzureDiskVolumeSource.prototype.diskName = "";

                        /**
                         * AzureDiskVolumeSource diskURI.
                         * @member {string} diskURI
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         */
                        AzureDiskVolumeSource.prototype.diskURI = "";

                        /**
                         * AzureDiskVolumeSource cachingMode.
                         * @member {string} cachingMode
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         */
                        AzureDiskVolumeSource.prototype.cachingMode = "";

                        /**
                         * AzureDiskVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         */
                        AzureDiskVolumeSource.prototype.fsType = "";

                        /**
                         * AzureDiskVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         */
                        AzureDiskVolumeSource.prototype.readOnly = false;

                        /**
                         * AzureDiskVolumeSource kind.
                         * @member {string} kind
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         */
                        AzureDiskVolumeSource.prototype.kind = "";

                        /**
                         * Creates a new AzureDiskVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureDiskVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.AzureDiskVolumeSource} AzureDiskVolumeSource instance
                         */
                        AzureDiskVolumeSource.create = function create(properties) {
                            return new AzureDiskVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified AzureDiskVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.AzureDiskVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureDiskVolumeSource} message AzureDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AzureDiskVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.diskName != null && Object.hasOwnProperty.call(message, "diskName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.diskName);
                            if (message.diskURI != null && Object.hasOwnProperty.call(message, "diskURI"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.diskURI);
                            if (message.cachingMode != null && Object.hasOwnProperty.call(message, "cachingMode"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cachingMode);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.readOnly);
                            if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.kind);
                            return writer;
                        };

                        /**
                         * Encodes the specified AzureDiskVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.AzureDiskVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureDiskVolumeSource} message AzureDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AzureDiskVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AzureDiskVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.AzureDiskVolumeSource} AzureDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AzureDiskVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.AzureDiskVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.diskName = reader.string();
                                    break;
                                case 2:
                                    message.diskURI = reader.string();
                                    break;
                                case 3:
                                    message.cachingMode = reader.string();
                                    break;
                                case 4:
                                    message.fsType = reader.string();
                                    break;
                                case 5:
                                    message.readOnly = reader.bool();
                                    break;
                                case 6:
                                    message.kind = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AzureDiskVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.AzureDiskVolumeSource} AzureDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AzureDiskVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AzureDiskVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AzureDiskVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.diskName != null && message.hasOwnProperty("diskName"))
                                if (!$util.isString(message.diskName))
                                    return "diskName: string expected";
                            if (message.diskURI != null && message.hasOwnProperty("diskURI"))
                                if (!$util.isString(message.diskURI))
                                    return "diskURI: string expected";
                            if (message.cachingMode != null && message.hasOwnProperty("cachingMode"))
                                if (!$util.isString(message.cachingMode))
                                    return "cachingMode: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                if (!$util.isString(message.kind))
                                    return "kind: string expected";
                            return null;
                        };

                        /**
                         * Creates an AzureDiskVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.AzureDiskVolumeSource} AzureDiskVolumeSource
                         */
                        AzureDiskVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.AzureDiskVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.AzureDiskVolumeSource();
                            if (object.diskName != null)
                                message.diskName = String(object.diskName);
                            if (object.diskURI != null)
                                message.diskURI = String(object.diskURI);
                            if (object.cachingMode != null)
                                message.cachingMode = String(object.cachingMode);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.kind != null)
                                message.kind = String(object.kind);
                            return message;
                        };

                        /**
                         * Creates a plain object from an AzureDiskVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.AzureDiskVolumeSource} message AzureDiskVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AzureDiskVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.diskName = "";
                                object.diskURI = "";
                                object.cachingMode = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.kind = "";
                            }
                            if (message.diskName != null && message.hasOwnProperty("diskName"))
                                object.diskName = message.diskName;
                            if (message.diskURI != null && message.hasOwnProperty("diskURI"))
                                object.diskURI = message.diskURI;
                            if (message.cachingMode != null && message.hasOwnProperty("cachingMode"))
                                object.cachingMode = message.cachingMode;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                object.kind = message.kind;
                            return object;
                        };

                        /**
                         * Converts this AzureDiskVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.AzureDiskVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AzureDiskVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AzureDiskVolumeSource;
                    })();

                    v1.AzureFilePersistentVolumeSource = (function() {

                        /**
                         * Properties of an AzureFilePersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAzureFilePersistentVolumeSource
                         * @property {string|null} [secretName] AzureFilePersistentVolumeSource secretName
                         * @property {string|null} [shareName] AzureFilePersistentVolumeSource shareName
                         * @property {boolean|null} [readOnly] AzureFilePersistentVolumeSource readOnly
                         * @property {string|null} [secretNamespace] AzureFilePersistentVolumeSource secretNamespace
                         */

                        /**
                         * Constructs a new AzureFilePersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an AzureFilePersistentVolumeSource.
                         * @implements IAzureFilePersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAzureFilePersistentVolumeSource=} [properties] Properties to set
                         */
                        function AzureFilePersistentVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AzureFilePersistentVolumeSource secretName.
                         * @member {string} secretName
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @instance
                         */
                        AzureFilePersistentVolumeSource.prototype.secretName = "";

                        /**
                         * AzureFilePersistentVolumeSource shareName.
                         * @member {string} shareName
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @instance
                         */
                        AzureFilePersistentVolumeSource.prototype.shareName = "";

                        /**
                         * AzureFilePersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @instance
                         */
                        AzureFilePersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * AzureFilePersistentVolumeSource secretNamespace.
                         * @member {string} secretNamespace
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @instance
                         */
                        AzureFilePersistentVolumeSource.prototype.secretNamespace = "";

                        /**
                         * Creates a new AzureFilePersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureFilePersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.AzureFilePersistentVolumeSource} AzureFilePersistentVolumeSource instance
                         */
                        AzureFilePersistentVolumeSource.create = function create(properties) {
                            return new AzureFilePersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified AzureFilePersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.AzureFilePersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureFilePersistentVolumeSource} message AzureFilePersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AzureFilePersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.secretName != null && Object.hasOwnProperty.call(message, "secretName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretName);
                            if (message.shareName != null && Object.hasOwnProperty.call(message, "shareName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.shareName);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            if (message.secretNamespace != null && Object.hasOwnProperty.call(message, "secretNamespace"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.secretNamespace);
                            return writer;
                        };

                        /**
                         * Encodes the specified AzureFilePersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.AzureFilePersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureFilePersistentVolumeSource} message AzureFilePersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AzureFilePersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AzureFilePersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.AzureFilePersistentVolumeSource} AzureFilePersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AzureFilePersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.secretName = reader.string();
                                    break;
                                case 2:
                                    message.shareName = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                case 4:
                                    message.secretNamespace = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AzureFilePersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.AzureFilePersistentVolumeSource} AzureFilePersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AzureFilePersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AzureFilePersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AzureFilePersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.secretName != null && message.hasOwnProperty("secretName"))
                                if (!$util.isString(message.secretName))
                                    return "secretName: string expected";
                            if (message.shareName != null && message.hasOwnProperty("shareName"))
                                if (!$util.isString(message.shareName))
                                    return "shareName: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.secretNamespace != null && message.hasOwnProperty("secretNamespace"))
                                if (!$util.isString(message.secretNamespace))
                                    return "secretNamespace: string expected";
                            return null;
                        };

                        /**
                         * Creates an AzureFilePersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.AzureFilePersistentVolumeSource} AzureFilePersistentVolumeSource
                         */
                        AzureFilePersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource();
                            if (object.secretName != null)
                                message.secretName = String(object.secretName);
                            if (object.shareName != null)
                                message.shareName = String(object.shareName);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.secretNamespace != null)
                                message.secretNamespace = String(object.secretNamespace);
                            return message;
                        };

                        /**
                         * Creates a plain object from an AzureFilePersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.AzureFilePersistentVolumeSource} message AzureFilePersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AzureFilePersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.secretName = "";
                                object.shareName = "";
                                object.readOnly = false;
                                object.secretNamespace = "";
                            }
                            if (message.secretName != null && message.hasOwnProperty("secretName"))
                                object.secretName = message.secretName;
                            if (message.shareName != null && message.hasOwnProperty("shareName"))
                                object.shareName = message.shareName;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.secretNamespace != null && message.hasOwnProperty("secretNamespace"))
                                object.secretNamespace = message.secretNamespace;
                            return object;
                        };

                        /**
                         * Converts this AzureFilePersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.AzureFilePersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AzureFilePersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AzureFilePersistentVolumeSource;
                    })();

                    v1.AzureFileVolumeSource = (function() {

                        /**
                         * Properties of an AzureFileVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IAzureFileVolumeSource
                         * @property {string|null} [secretName] AzureFileVolumeSource secretName
                         * @property {string|null} [shareName] AzureFileVolumeSource shareName
                         * @property {boolean|null} [readOnly] AzureFileVolumeSource readOnly
                         */

                        /**
                         * Constructs a new AzureFileVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an AzureFileVolumeSource.
                         * @implements IAzureFileVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IAzureFileVolumeSource=} [properties] Properties to set
                         */
                        function AzureFileVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AzureFileVolumeSource secretName.
                         * @member {string} secretName
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @instance
                         */
                        AzureFileVolumeSource.prototype.secretName = "";

                        /**
                         * AzureFileVolumeSource shareName.
                         * @member {string} shareName
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @instance
                         */
                        AzureFileVolumeSource.prototype.shareName = "";

                        /**
                         * AzureFileVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @instance
                         */
                        AzureFileVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new AzureFileVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureFileVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.AzureFileVolumeSource} AzureFileVolumeSource instance
                         */
                        AzureFileVolumeSource.create = function create(properties) {
                            return new AzureFileVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified AzureFileVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.AzureFileVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureFileVolumeSource} message AzureFileVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AzureFileVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.secretName != null && Object.hasOwnProperty.call(message, "secretName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretName);
                            if (message.shareName != null && Object.hasOwnProperty.call(message, "shareName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.shareName);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified AzureFileVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.AzureFileVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IAzureFileVolumeSource} message AzureFileVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AzureFileVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AzureFileVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.AzureFileVolumeSource} AzureFileVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AzureFileVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.AzureFileVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.secretName = reader.string();
                                    break;
                                case 2:
                                    message.shareName = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AzureFileVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.AzureFileVolumeSource} AzureFileVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AzureFileVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AzureFileVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AzureFileVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.secretName != null && message.hasOwnProperty("secretName"))
                                if (!$util.isString(message.secretName))
                                    return "secretName: string expected";
                            if (message.shareName != null && message.hasOwnProperty("shareName"))
                                if (!$util.isString(message.shareName))
                                    return "shareName: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates an AzureFileVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.AzureFileVolumeSource} AzureFileVolumeSource
                         */
                        AzureFileVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.AzureFileVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.AzureFileVolumeSource();
                            if (object.secretName != null)
                                message.secretName = String(object.secretName);
                            if (object.shareName != null)
                                message.shareName = String(object.shareName);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from an AzureFileVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.AzureFileVolumeSource} message AzureFileVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AzureFileVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.secretName = "";
                                object.shareName = "";
                                object.readOnly = false;
                            }
                            if (message.secretName != null && message.hasOwnProperty("secretName"))
                                object.secretName = message.secretName;
                            if (message.shareName != null && message.hasOwnProperty("shareName"))
                                object.shareName = message.shareName;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this AzureFileVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.AzureFileVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AzureFileVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AzureFileVolumeSource;
                    })();

                    v1.Binding = (function() {

                        /**
                         * Properties of a Binding.
                         * @memberof k8s.io.api.core.v1
                         * @interface IBinding
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Binding metadata
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [target] Binding target
                         */

                        /**
                         * Constructs a new Binding.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Binding.
                         * @implements IBinding
                         * @constructor
                         * @param {k8s.io.api.core.v1.IBinding=} [properties] Properties to set
                         */
                        function Binding(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Binding metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Binding
                         * @instance
                         */
                        Binding.prototype.metadata = null;

                        /**
                         * Binding target.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} target
                         * @memberof k8s.io.api.core.v1.Binding
                         * @instance
                         */
                        Binding.prototype.target = null;

                        /**
                         * Creates a new Binding instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {k8s.io.api.core.v1.IBinding=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Binding} Binding instance
                         */
                        Binding.create = function create(properties) {
                            return new Binding(properties);
                        };

                        /**
                         * Encodes the specified Binding message. Does not implicitly {@link k8s.io.api.core.v1.Binding.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {k8s.io.api.core.v1.IBinding} message Binding message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Binding.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.target, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Binding message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Binding.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {k8s.io.api.core.v1.IBinding} message Binding message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Binding.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Binding message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Binding} Binding
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Binding.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Binding();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.target = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Binding message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Binding} Binding
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Binding.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Binding message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Binding.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.target != null && message.hasOwnProperty("target")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.target);
                                if (error)
                                    return "target." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Binding message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Binding} Binding
                         */
                        Binding.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Binding)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Binding();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Binding.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.target != null) {
                                if (typeof object.target !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Binding.target: object expected");
                                message.target = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.target);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Binding message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Binding
                         * @static
                         * @param {k8s.io.api.core.v1.Binding} message Binding
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Binding.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.target = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.target != null && message.hasOwnProperty("target"))
                                object.target = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.target, options);
                            return object;
                        };

                        /**
                         * Converts this Binding to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Binding
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Binding.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Binding;
                    })();

                    v1.CSIPersistentVolumeSource = (function() {

                        /**
                         * Properties of a CSIPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICSIPersistentVolumeSource
                         * @property {string|null} [driver] CSIPersistentVolumeSource driver
                         * @property {string|null} [volumeHandle] CSIPersistentVolumeSource volumeHandle
                         * @property {boolean|null} [readOnly] CSIPersistentVolumeSource readOnly
                         * @property {string|null} [fsType] CSIPersistentVolumeSource fsType
                         * @property {Object.<string,string>|null} [volumeAttributes] CSIPersistentVolumeSource volumeAttributes
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [controllerPublishSecretRef] CSIPersistentVolumeSource controllerPublishSecretRef
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [nodeStageSecretRef] CSIPersistentVolumeSource nodeStageSecretRef
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [nodePublishSecretRef] CSIPersistentVolumeSource nodePublishSecretRef
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [controllerExpandSecretRef] CSIPersistentVolumeSource controllerExpandSecretRef
                         */

                        /**
                         * Constructs a new CSIPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a CSIPersistentVolumeSource.
                         * @implements ICSIPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICSIPersistentVolumeSource=} [properties] Properties to set
                         */
                        function CSIPersistentVolumeSource(properties) {
                            this.volumeAttributes = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CSIPersistentVolumeSource driver.
                         * @member {string} driver
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.driver = "";

                        /**
                         * CSIPersistentVolumeSource volumeHandle.
                         * @member {string} volumeHandle
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.volumeHandle = "";

                        /**
                         * CSIPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * CSIPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * CSIPersistentVolumeSource volumeAttributes.
                         * @member {Object.<string,string>} volumeAttributes
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.volumeAttributes = $util.emptyObject;

                        /**
                         * CSIPersistentVolumeSource controllerPublishSecretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} controllerPublishSecretRef
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.controllerPublishSecretRef = null;

                        /**
                         * CSIPersistentVolumeSource nodeStageSecretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} nodeStageSecretRef
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.nodeStageSecretRef = null;

                        /**
                         * CSIPersistentVolumeSource nodePublishSecretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} nodePublishSecretRef
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.nodePublishSecretRef = null;

                        /**
                         * CSIPersistentVolumeSource controllerExpandSecretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} controllerExpandSecretRef
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         */
                        CSIPersistentVolumeSource.prototype.controllerExpandSecretRef = null;

                        /**
                         * Creates a new CSIPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICSIPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.CSIPersistentVolumeSource} CSIPersistentVolumeSource instance
                         */
                        CSIPersistentVolumeSource.create = function create(properties) {
                            return new CSIPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified CSIPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.CSIPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICSIPersistentVolumeSource} message CSIPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CSIPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.driver != null && Object.hasOwnProperty.call(message, "driver"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.driver);
                            if (message.volumeHandle != null && Object.hasOwnProperty.call(message, "volumeHandle"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.volumeHandle);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.fsType);
                            if (message.volumeAttributes != null && Object.hasOwnProperty.call(message, "volumeAttributes"))
                                for (var keys = Object.keys(message.volumeAttributes), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.volumeAttributes[keys[i]]).ldelim();
                            if (message.controllerPublishSecretRef != null && Object.hasOwnProperty.call(message, "controllerPublishSecretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.controllerPublishSecretRef, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.nodeStageSecretRef != null && Object.hasOwnProperty.call(message, "nodeStageSecretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.nodeStageSecretRef, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.nodePublishSecretRef != null && Object.hasOwnProperty.call(message, "nodePublishSecretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.nodePublishSecretRef, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.controllerExpandSecretRef != null && Object.hasOwnProperty.call(message, "controllerExpandSecretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.controllerExpandSecretRef, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified CSIPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.CSIPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICSIPersistentVolumeSource} message CSIPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CSIPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CSIPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.CSIPersistentVolumeSource} CSIPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CSIPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.CSIPersistentVolumeSource(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.driver = reader.string();
                                    break;
                                case 2:
                                    message.volumeHandle = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                case 4:
                                    message.fsType = reader.string();
                                    break;
                                case 5:
                                    if (message.volumeAttributes === $util.emptyObject)
                                        message.volumeAttributes = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.volumeAttributes[key] = value;
                                    break;
                                case 6:
                                    message.controllerPublishSecretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.nodeStageSecretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.nodePublishSecretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.controllerExpandSecretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CSIPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.CSIPersistentVolumeSource} CSIPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CSIPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CSIPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CSIPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                if (!$util.isString(message.driver))
                                    return "driver: string expected";
                            if (message.volumeHandle != null && message.hasOwnProperty("volumeHandle"))
                                if (!$util.isString(message.volumeHandle))
                                    return "volumeHandle: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.volumeAttributes != null && message.hasOwnProperty("volumeAttributes")) {
                                if (!$util.isObject(message.volumeAttributes))
                                    return "volumeAttributes: object expected";
                                var key = Object.keys(message.volumeAttributes);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.volumeAttributes[key[i]]))
                                        return "volumeAttributes: string{k:string} expected";
                            }
                            if (message.controllerPublishSecretRef != null && message.hasOwnProperty("controllerPublishSecretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.controllerPublishSecretRef);
                                if (error)
                                    return "controllerPublishSecretRef." + error;
                            }
                            if (message.nodeStageSecretRef != null && message.hasOwnProperty("nodeStageSecretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.nodeStageSecretRef);
                                if (error)
                                    return "nodeStageSecretRef." + error;
                            }
                            if (message.nodePublishSecretRef != null && message.hasOwnProperty("nodePublishSecretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.nodePublishSecretRef);
                                if (error)
                                    return "nodePublishSecretRef." + error;
                            }
                            if (message.controllerExpandSecretRef != null && message.hasOwnProperty("controllerExpandSecretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.controllerExpandSecretRef);
                                if (error)
                                    return "controllerExpandSecretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a CSIPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.CSIPersistentVolumeSource} CSIPersistentVolumeSource
                         */
                        CSIPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.CSIPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.CSIPersistentVolumeSource();
                            if (object.driver != null)
                                message.driver = String(object.driver);
                            if (object.volumeHandle != null)
                                message.volumeHandle = String(object.volumeHandle);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.volumeAttributes) {
                                if (typeof object.volumeAttributes !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIPersistentVolumeSource.volumeAttributes: object expected");
                                message.volumeAttributes = {};
                                for (var keys = Object.keys(object.volumeAttributes), i = 0; i < keys.length; ++i)
                                    message.volumeAttributes[keys[i]] = String(object.volumeAttributes[keys[i]]);
                            }
                            if (object.controllerPublishSecretRef != null) {
                                if (typeof object.controllerPublishSecretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIPersistentVolumeSource.controllerPublishSecretRef: object expected");
                                message.controllerPublishSecretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.controllerPublishSecretRef);
                            }
                            if (object.nodeStageSecretRef != null) {
                                if (typeof object.nodeStageSecretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIPersistentVolumeSource.nodeStageSecretRef: object expected");
                                message.nodeStageSecretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.nodeStageSecretRef);
                            }
                            if (object.nodePublishSecretRef != null) {
                                if (typeof object.nodePublishSecretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIPersistentVolumeSource.nodePublishSecretRef: object expected");
                                message.nodePublishSecretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.nodePublishSecretRef);
                            }
                            if (object.controllerExpandSecretRef != null) {
                                if (typeof object.controllerExpandSecretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIPersistentVolumeSource.controllerExpandSecretRef: object expected");
                                message.controllerExpandSecretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.controllerExpandSecretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a CSIPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.CSIPersistentVolumeSource} message CSIPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CSIPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.volumeAttributes = {};
                            if (options.defaults) {
                                object.driver = "";
                                object.volumeHandle = "";
                                object.readOnly = false;
                                object.fsType = "";
                                object.controllerPublishSecretRef = null;
                                object.nodeStageSecretRef = null;
                                object.nodePublishSecretRef = null;
                                object.controllerExpandSecretRef = null;
                            }
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                object.driver = message.driver;
                            if (message.volumeHandle != null && message.hasOwnProperty("volumeHandle"))
                                object.volumeHandle = message.volumeHandle;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            var keys2;
                            if (message.volumeAttributes && (keys2 = Object.keys(message.volumeAttributes)).length) {
                                object.volumeAttributes = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.volumeAttributes[keys2[j]] = message.volumeAttributes[keys2[j]];
                            }
                            if (message.controllerPublishSecretRef != null && message.hasOwnProperty("controllerPublishSecretRef"))
                                object.controllerPublishSecretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.controllerPublishSecretRef, options);
                            if (message.nodeStageSecretRef != null && message.hasOwnProperty("nodeStageSecretRef"))
                                object.nodeStageSecretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.nodeStageSecretRef, options);
                            if (message.nodePublishSecretRef != null && message.hasOwnProperty("nodePublishSecretRef"))
                                object.nodePublishSecretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.nodePublishSecretRef, options);
                            if (message.controllerExpandSecretRef != null && message.hasOwnProperty("controllerExpandSecretRef"))
                                object.controllerExpandSecretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.controllerExpandSecretRef, options);
                            return object;
                        };

                        /**
                         * Converts this CSIPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.CSIPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CSIPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CSIPersistentVolumeSource;
                    })();

                    v1.CSIVolumeSource = (function() {

                        /**
                         * Properties of a CSIVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICSIVolumeSource
                         * @property {string|null} [driver] CSIVolumeSource driver
                         * @property {boolean|null} [readOnly] CSIVolumeSource readOnly
                         * @property {string|null} [fsType] CSIVolumeSource fsType
                         * @property {Object.<string,string>|null} [volumeAttributes] CSIVolumeSource volumeAttributes
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [nodePublishSecretRef] CSIVolumeSource nodePublishSecretRef
                         */

                        /**
                         * Constructs a new CSIVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a CSIVolumeSource.
                         * @implements ICSIVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICSIVolumeSource=} [properties] Properties to set
                         */
                        function CSIVolumeSource(properties) {
                            this.volumeAttributes = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CSIVolumeSource driver.
                         * @member {string} driver
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @instance
                         */
                        CSIVolumeSource.prototype.driver = "";

                        /**
                         * CSIVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @instance
                         */
                        CSIVolumeSource.prototype.readOnly = false;

                        /**
                         * CSIVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @instance
                         */
                        CSIVolumeSource.prototype.fsType = "";

                        /**
                         * CSIVolumeSource volumeAttributes.
                         * @member {Object.<string,string>} volumeAttributes
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @instance
                         */
                        CSIVolumeSource.prototype.volumeAttributes = $util.emptyObject;

                        /**
                         * CSIVolumeSource nodePublishSecretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} nodePublishSecretRef
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @instance
                         */
                        CSIVolumeSource.prototype.nodePublishSecretRef = null;

                        /**
                         * Creates a new CSIVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICSIVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.CSIVolumeSource} CSIVolumeSource instance
                         */
                        CSIVolumeSource.create = function create(properties) {
                            return new CSIVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified CSIVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.CSIVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICSIVolumeSource} message CSIVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CSIVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.driver != null && Object.hasOwnProperty.call(message, "driver"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.driver);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.readOnly);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fsType);
                            if (message.volumeAttributes != null && Object.hasOwnProperty.call(message, "volumeAttributes"))
                                for (var keys = Object.keys(message.volumeAttributes), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.volumeAttributes[keys[i]]).ldelim();
                            if (message.nodePublishSecretRef != null && Object.hasOwnProperty.call(message, "nodePublishSecretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.nodePublishSecretRef, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified CSIVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.CSIVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICSIVolumeSource} message CSIVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CSIVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CSIVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.CSIVolumeSource} CSIVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CSIVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.CSIVolumeSource(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.driver = reader.string();
                                    break;
                                case 2:
                                    message.readOnly = reader.bool();
                                    break;
                                case 3:
                                    message.fsType = reader.string();
                                    break;
                                case 4:
                                    if (message.volumeAttributes === $util.emptyObject)
                                        message.volumeAttributes = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.volumeAttributes[key] = value;
                                    break;
                                case 5:
                                    message.nodePublishSecretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CSIVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.CSIVolumeSource} CSIVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CSIVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CSIVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CSIVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                if (!$util.isString(message.driver))
                                    return "driver: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.volumeAttributes != null && message.hasOwnProperty("volumeAttributes")) {
                                if (!$util.isObject(message.volumeAttributes))
                                    return "volumeAttributes: object expected";
                                var key = Object.keys(message.volumeAttributes);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.volumeAttributes[key[i]]))
                                        return "volumeAttributes: string{k:string} expected";
                            }
                            if (message.nodePublishSecretRef != null && message.hasOwnProperty("nodePublishSecretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.nodePublishSecretRef);
                                if (error)
                                    return "nodePublishSecretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a CSIVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.CSIVolumeSource} CSIVolumeSource
                         */
                        CSIVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.CSIVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.CSIVolumeSource();
                            if (object.driver != null)
                                message.driver = String(object.driver);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.volumeAttributes) {
                                if (typeof object.volumeAttributes !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIVolumeSource.volumeAttributes: object expected");
                                message.volumeAttributes = {};
                                for (var keys = Object.keys(object.volumeAttributes), i = 0; i < keys.length; ++i)
                                    message.volumeAttributes[keys[i]] = String(object.volumeAttributes[keys[i]]);
                            }
                            if (object.nodePublishSecretRef != null) {
                                if (typeof object.nodePublishSecretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CSIVolumeSource.nodePublishSecretRef: object expected");
                                message.nodePublishSecretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.nodePublishSecretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a CSIVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.CSIVolumeSource} message CSIVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CSIVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.volumeAttributes = {};
                            if (options.defaults) {
                                object.driver = "";
                                object.readOnly = false;
                                object.fsType = "";
                                object.nodePublishSecretRef = null;
                            }
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                object.driver = message.driver;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            var keys2;
                            if (message.volumeAttributes && (keys2 = Object.keys(message.volumeAttributes)).length) {
                                object.volumeAttributes = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.volumeAttributes[keys2[j]] = message.volumeAttributes[keys2[j]];
                            }
                            if (message.nodePublishSecretRef != null && message.hasOwnProperty("nodePublishSecretRef"))
                                object.nodePublishSecretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.nodePublishSecretRef, options);
                            return object;
                        };

                        /**
                         * Converts this CSIVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.CSIVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CSIVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CSIVolumeSource;
                    })();

                    v1.Capabilities = (function() {

                        /**
                         * Properties of a Capabilities.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICapabilities
                         * @property {Array.<string>|null} [add] Capabilities add
                         * @property {Array.<string>|null} [drop] Capabilities drop
                         */

                        /**
                         * Constructs a new Capabilities.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Capabilities.
                         * @implements ICapabilities
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICapabilities=} [properties] Properties to set
                         */
                        function Capabilities(properties) {
                            this.add = [];
                            this.drop = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Capabilities add.
                         * @member {Array.<string>} add
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @instance
                         */
                        Capabilities.prototype.add = $util.emptyArray;

                        /**
                         * Capabilities drop.
                         * @member {Array.<string>} drop
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @instance
                         */
                        Capabilities.prototype.drop = $util.emptyArray;

                        /**
                         * Creates a new Capabilities instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {k8s.io.api.core.v1.ICapabilities=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Capabilities} Capabilities instance
                         */
                        Capabilities.create = function create(properties) {
                            return new Capabilities(properties);
                        };

                        /**
                         * Encodes the specified Capabilities message. Does not implicitly {@link k8s.io.api.core.v1.Capabilities.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {k8s.io.api.core.v1.ICapabilities} message Capabilities message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Capabilities.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.add != null && message.add.length)
                                for (var i = 0; i < message.add.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.add[i]);
                            if (message.drop != null && message.drop.length)
                                for (var i = 0; i < message.drop.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.drop[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified Capabilities message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Capabilities.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {k8s.io.api.core.v1.ICapabilities} message Capabilities message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Capabilities.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Capabilities message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Capabilities} Capabilities
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Capabilities.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Capabilities();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.add && message.add.length))
                                        message.add = [];
                                    message.add.push(reader.string());
                                    break;
                                case 2:
                                    if (!(message.drop && message.drop.length))
                                        message.drop = [];
                                    message.drop.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Capabilities message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Capabilities} Capabilities
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Capabilities.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Capabilities message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Capabilities.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.add != null && message.hasOwnProperty("add")) {
                                if (!Array.isArray(message.add))
                                    return "add: array expected";
                                for (var i = 0; i < message.add.length; ++i)
                                    if (!$util.isString(message.add[i]))
                                        return "add: string[] expected";
                            }
                            if (message.drop != null && message.hasOwnProperty("drop")) {
                                if (!Array.isArray(message.drop))
                                    return "drop: array expected";
                                for (var i = 0; i < message.drop.length; ++i)
                                    if (!$util.isString(message.drop[i]))
                                        return "drop: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a Capabilities message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Capabilities} Capabilities
                         */
                        Capabilities.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Capabilities)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Capabilities();
                            if (object.add) {
                                if (!Array.isArray(object.add))
                                    throw TypeError(".k8s.io.api.core.v1.Capabilities.add: array expected");
                                message.add = [];
                                for (var i = 0; i < object.add.length; ++i)
                                    message.add[i] = String(object.add[i]);
                            }
                            if (object.drop) {
                                if (!Array.isArray(object.drop))
                                    throw TypeError(".k8s.io.api.core.v1.Capabilities.drop: array expected");
                                message.drop = [];
                                for (var i = 0; i < object.drop.length; ++i)
                                    message.drop[i] = String(object.drop[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Capabilities message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @static
                         * @param {k8s.io.api.core.v1.Capabilities} message Capabilities
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Capabilities.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.add = [];
                                object.drop = [];
                            }
                            if (message.add && message.add.length) {
                                object.add = [];
                                for (var j = 0; j < message.add.length; ++j)
                                    object.add[j] = message.add[j];
                            }
                            if (message.drop && message.drop.length) {
                                object.drop = [];
                                for (var j = 0; j < message.drop.length; ++j)
                                    object.drop[j] = message.drop[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this Capabilities to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Capabilities
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Capabilities.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Capabilities;
                    })();

                    v1.CephFSPersistentVolumeSource = (function() {

                        /**
                         * Properties of a CephFSPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICephFSPersistentVolumeSource
                         * @property {Array.<string>|null} [monitors] CephFSPersistentVolumeSource monitors
                         * @property {string|null} [path] CephFSPersistentVolumeSource path
                         * @property {string|null} [user] CephFSPersistentVolumeSource user
                         * @property {string|null} [secretFile] CephFSPersistentVolumeSource secretFile
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [secretRef] CephFSPersistentVolumeSource secretRef
                         * @property {boolean|null} [readOnly] CephFSPersistentVolumeSource readOnly
                         */

                        /**
                         * Constructs a new CephFSPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a CephFSPersistentVolumeSource.
                         * @implements ICephFSPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICephFSPersistentVolumeSource=} [properties] Properties to set
                         */
                        function CephFSPersistentVolumeSource(properties) {
                            this.monitors = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CephFSPersistentVolumeSource monitors.
                         * @member {Array.<string>} monitors
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         */
                        CephFSPersistentVolumeSource.prototype.monitors = $util.emptyArray;

                        /**
                         * CephFSPersistentVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         */
                        CephFSPersistentVolumeSource.prototype.path = "";

                        /**
                         * CephFSPersistentVolumeSource user.
                         * @member {string} user
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         */
                        CephFSPersistentVolumeSource.prototype.user = "";

                        /**
                         * CephFSPersistentVolumeSource secretFile.
                         * @member {string} secretFile
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         */
                        CephFSPersistentVolumeSource.prototype.secretFile = "";

                        /**
                         * CephFSPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         */
                        CephFSPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * CephFSPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         */
                        CephFSPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new CephFSPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICephFSPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.CephFSPersistentVolumeSource} CephFSPersistentVolumeSource instance
                         */
                        CephFSPersistentVolumeSource.create = function create(properties) {
                            return new CephFSPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified CephFSPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.CephFSPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICephFSPersistentVolumeSource} message CephFSPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CephFSPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.monitors != null && message.monitors.length)
                                for (var i = 0; i < message.monitors.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.monitors[i]);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.user);
                            if (message.secretFile != null && Object.hasOwnProperty.call(message, "secretFile"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.secretFile);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.secretRef, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified CephFSPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.CephFSPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICephFSPersistentVolumeSource} message CephFSPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CephFSPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CephFSPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.CephFSPersistentVolumeSource} CephFSPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CephFSPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.monitors && message.monitors.length))
                                        message.monitors = [];
                                    message.monitors.push(reader.string());
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                case 3:
                                    message.user = reader.string();
                                    break;
                                case 4:
                                    message.secretFile = reader.string();
                                    break;
                                case 5:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CephFSPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.CephFSPersistentVolumeSource} CephFSPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CephFSPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CephFSPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CephFSPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.monitors != null && message.hasOwnProperty("monitors")) {
                                if (!Array.isArray(message.monitors))
                                    return "monitors: array expected";
                                for (var i = 0; i < message.monitors.length; ++i)
                                    if (!$util.isString(message.monitors[i]))
                                        return "monitors: string[] expected";
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.user != null && message.hasOwnProperty("user"))
                                if (!$util.isString(message.user))
                                    return "user: string expected";
                            if (message.secretFile != null && message.hasOwnProperty("secretFile"))
                                if (!$util.isString(message.secretFile))
                                    return "secretFile: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a CephFSPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.CephFSPersistentVolumeSource} CephFSPersistentVolumeSource
                         */
                        CephFSPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource();
                            if (object.monitors) {
                                if (!Array.isArray(object.monitors))
                                    throw TypeError(".k8s.io.api.core.v1.CephFSPersistentVolumeSource.monitors: array expected");
                                message.monitors = [];
                                for (var i = 0; i < object.monitors.length; ++i)
                                    message.monitors[i] = String(object.monitors[i]);
                            }
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.user != null)
                                message.user = String(object.user);
                            if (object.secretFile != null)
                                message.secretFile = String(object.secretFile);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CephFSPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.secretRef);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CephFSPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.CephFSPersistentVolumeSource} message CephFSPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CephFSPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.monitors = [];
                            if (options.defaults) {
                                object.path = "";
                                object.user = "";
                                object.secretFile = "";
                                object.secretRef = null;
                                object.readOnly = false;
                            }
                            if (message.monitors && message.monitors.length) {
                                object.monitors = [];
                                for (var j = 0; j < message.monitors.length; ++j)
                                    object.monitors[j] = message.monitors[j];
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.user != null && message.hasOwnProperty("user"))
                                object.user = message.user;
                            if (message.secretFile != null && message.hasOwnProperty("secretFile"))
                                object.secretFile = message.secretFile;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.secretRef, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this CephFSPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.CephFSPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CephFSPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CephFSPersistentVolumeSource;
                    })();

                    v1.CephFSVolumeSource = (function() {

                        /**
                         * Properties of a CephFSVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICephFSVolumeSource
                         * @property {Array.<string>|null} [monitors] CephFSVolumeSource monitors
                         * @property {string|null} [path] CephFSVolumeSource path
                         * @property {string|null} [user] CephFSVolumeSource user
                         * @property {string|null} [secretFile] CephFSVolumeSource secretFile
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] CephFSVolumeSource secretRef
                         * @property {boolean|null} [readOnly] CephFSVolumeSource readOnly
                         */

                        /**
                         * Constructs a new CephFSVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a CephFSVolumeSource.
                         * @implements ICephFSVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICephFSVolumeSource=} [properties] Properties to set
                         */
                        function CephFSVolumeSource(properties) {
                            this.monitors = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CephFSVolumeSource monitors.
                         * @member {Array.<string>} monitors
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         */
                        CephFSVolumeSource.prototype.monitors = $util.emptyArray;

                        /**
                         * CephFSVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         */
                        CephFSVolumeSource.prototype.path = "";

                        /**
                         * CephFSVolumeSource user.
                         * @member {string} user
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         */
                        CephFSVolumeSource.prototype.user = "";

                        /**
                         * CephFSVolumeSource secretFile.
                         * @member {string} secretFile
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         */
                        CephFSVolumeSource.prototype.secretFile = "";

                        /**
                         * CephFSVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         */
                        CephFSVolumeSource.prototype.secretRef = null;

                        /**
                         * CephFSVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         */
                        CephFSVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new CephFSVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICephFSVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.CephFSVolumeSource} CephFSVolumeSource instance
                         */
                        CephFSVolumeSource.create = function create(properties) {
                            return new CephFSVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified CephFSVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.CephFSVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICephFSVolumeSource} message CephFSVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CephFSVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.monitors != null && message.monitors.length)
                                for (var i = 0; i < message.monitors.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.monitors[i]);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.user);
                            if (message.secretFile != null && Object.hasOwnProperty.call(message, "secretFile"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.secretFile);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified CephFSVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.CephFSVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICephFSVolumeSource} message CephFSVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CephFSVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CephFSVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.CephFSVolumeSource} CephFSVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CephFSVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.CephFSVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.monitors && message.monitors.length))
                                        message.monitors = [];
                                    message.monitors.push(reader.string());
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                case 3:
                                    message.user = reader.string();
                                    break;
                                case 4:
                                    message.secretFile = reader.string();
                                    break;
                                case 5:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CephFSVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.CephFSVolumeSource} CephFSVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CephFSVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CephFSVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CephFSVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.monitors != null && message.hasOwnProperty("monitors")) {
                                if (!Array.isArray(message.monitors))
                                    return "monitors: array expected";
                                for (var i = 0; i < message.monitors.length; ++i)
                                    if (!$util.isString(message.monitors[i]))
                                        return "monitors: string[] expected";
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.user != null && message.hasOwnProperty("user"))
                                if (!$util.isString(message.user))
                                    return "user: string expected";
                            if (message.secretFile != null && message.hasOwnProperty("secretFile"))
                                if (!$util.isString(message.secretFile))
                                    return "secretFile: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a CephFSVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.CephFSVolumeSource} CephFSVolumeSource
                         */
                        CephFSVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.CephFSVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.CephFSVolumeSource();
                            if (object.monitors) {
                                if (!Array.isArray(object.monitors))
                                    throw TypeError(".k8s.io.api.core.v1.CephFSVolumeSource.monitors: array expected");
                                message.monitors = [];
                                for (var i = 0; i < object.monitors.length; ++i)
                                    message.monitors[i] = String(object.monitors[i]);
                            }
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.user != null)
                                message.user = String(object.user);
                            if (object.secretFile != null)
                                message.secretFile = String(object.secretFile);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CephFSVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a CephFSVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.CephFSVolumeSource} message CephFSVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CephFSVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.monitors = [];
                            if (options.defaults) {
                                object.path = "";
                                object.user = "";
                                object.secretFile = "";
                                object.secretRef = null;
                                object.readOnly = false;
                            }
                            if (message.monitors && message.monitors.length) {
                                object.monitors = [];
                                for (var j = 0; j < message.monitors.length; ++j)
                                    object.monitors[j] = message.monitors[j];
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.user != null && message.hasOwnProperty("user"))
                                object.user = message.user;
                            if (message.secretFile != null && message.hasOwnProperty("secretFile"))
                                object.secretFile = message.secretFile;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this CephFSVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.CephFSVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CephFSVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CephFSVolumeSource;
                    })();

                    v1.CinderPersistentVolumeSource = (function() {

                        /**
                         * Properties of a CinderPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICinderPersistentVolumeSource
                         * @property {string|null} [volumeID] CinderPersistentVolumeSource volumeID
                         * @property {string|null} [fsType] CinderPersistentVolumeSource fsType
                         * @property {boolean|null} [readOnly] CinderPersistentVolumeSource readOnly
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [secretRef] CinderPersistentVolumeSource secretRef
                         */

                        /**
                         * Constructs a new CinderPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a CinderPersistentVolumeSource.
                         * @implements ICinderPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICinderPersistentVolumeSource=} [properties] Properties to set
                         */
                        function CinderPersistentVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CinderPersistentVolumeSource volumeID.
                         * @member {string} volumeID
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @instance
                         */
                        CinderPersistentVolumeSource.prototype.volumeID = "";

                        /**
                         * CinderPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @instance
                         */
                        CinderPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * CinderPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @instance
                         */
                        CinderPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * CinderPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @instance
                         */
                        CinderPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * Creates a new CinderPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICinderPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.CinderPersistentVolumeSource} CinderPersistentVolumeSource instance
                         */
                        CinderPersistentVolumeSource.create = function create(properties) {
                            return new CinderPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified CinderPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.CinderPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICinderPersistentVolumeSource} message CinderPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CinderPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeID != null && Object.hasOwnProperty.call(message, "volumeID"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumeID);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.secretRef, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified CinderPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.CinderPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICinderPersistentVolumeSource} message CinderPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CinderPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CinderPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.CinderPersistentVolumeSource} CinderPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CinderPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.CinderPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeID = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                case 4:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CinderPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.CinderPersistentVolumeSource} CinderPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CinderPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CinderPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CinderPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                if (!$util.isString(message.volumeID))
                                    return "volumeID: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a CinderPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.CinderPersistentVolumeSource} CinderPersistentVolumeSource
                         */
                        CinderPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.CinderPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.CinderPersistentVolumeSource();
                            if (object.volumeID != null)
                                message.volumeID = String(object.volumeID);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CinderPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.secretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a CinderPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.CinderPersistentVolumeSource} message CinderPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CinderPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeID = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.secretRef = null;
                            }
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                object.volumeID = message.volumeID;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.secretRef, options);
                            return object;
                        };

                        /**
                         * Converts this CinderPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.CinderPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CinderPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CinderPersistentVolumeSource;
                    })();

                    v1.CinderVolumeSource = (function() {

                        /**
                         * Properties of a CinderVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ICinderVolumeSource
                         * @property {string|null} [volumeID] CinderVolumeSource volumeID
                         * @property {string|null} [fsType] CinderVolumeSource fsType
                         * @property {boolean|null} [readOnly] CinderVolumeSource readOnly
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] CinderVolumeSource secretRef
                         */

                        /**
                         * Constructs a new CinderVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a CinderVolumeSource.
                         * @implements ICinderVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ICinderVolumeSource=} [properties] Properties to set
                         */
                        function CinderVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CinderVolumeSource volumeID.
                         * @member {string} volumeID
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @instance
                         */
                        CinderVolumeSource.prototype.volumeID = "";

                        /**
                         * CinderVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @instance
                         */
                        CinderVolumeSource.prototype.fsType = "";

                        /**
                         * CinderVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @instance
                         */
                        CinderVolumeSource.prototype.readOnly = false;

                        /**
                         * CinderVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @instance
                         */
                        CinderVolumeSource.prototype.secretRef = null;

                        /**
                         * Creates a new CinderVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICinderVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.CinderVolumeSource} CinderVolumeSource instance
                         */
                        CinderVolumeSource.create = function create(properties) {
                            return new CinderVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified CinderVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.CinderVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICinderVolumeSource} message CinderVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CinderVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeID != null && Object.hasOwnProperty.call(message, "volumeID"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumeID);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified CinderVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.CinderVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ICinderVolumeSource} message CinderVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        CinderVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a CinderVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.CinderVolumeSource} CinderVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CinderVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.CinderVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeID = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                case 4:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a CinderVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.CinderVolumeSource} CinderVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        CinderVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a CinderVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CinderVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                if (!$util.isString(message.volumeID))
                                    return "volumeID: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a CinderVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.CinderVolumeSource} CinderVolumeSource
                         */
                        CinderVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.CinderVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.CinderVolumeSource();
                            if (object.volumeID != null)
                                message.volumeID = String(object.volumeID);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.CinderVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a CinderVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.CinderVolumeSource} message CinderVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CinderVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeID = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.secretRef = null;
                            }
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                object.volumeID = message.volumeID;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            return object;
                        };

                        /**
                         * Converts this CinderVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.CinderVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CinderVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CinderVolumeSource;
                    })();

                    v1.ClientIPConfig = (function() {

                        /**
                         * Properties of a ClientIPConfig.
                         * @memberof k8s.io.api.core.v1
                         * @interface IClientIPConfig
                         * @property {number|null} [timeoutSeconds] ClientIPConfig timeoutSeconds
                         */

                        /**
                         * Constructs a new ClientIPConfig.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ClientIPConfig.
                         * @implements IClientIPConfig
                         * @constructor
                         * @param {k8s.io.api.core.v1.IClientIPConfig=} [properties] Properties to set
                         */
                        function ClientIPConfig(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ClientIPConfig timeoutSeconds.
                         * @member {number} timeoutSeconds
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @instance
                         */
                        ClientIPConfig.prototype.timeoutSeconds = 0;

                        /**
                         * Creates a new ClientIPConfig instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {k8s.io.api.core.v1.IClientIPConfig=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ClientIPConfig} ClientIPConfig instance
                         */
                        ClientIPConfig.create = function create(properties) {
                            return new ClientIPConfig(properties);
                        };

                        /**
                         * Encodes the specified ClientIPConfig message. Does not implicitly {@link k8s.io.api.core.v1.ClientIPConfig.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {k8s.io.api.core.v1.IClientIPConfig} message ClientIPConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ClientIPConfig.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.timeoutSeconds != null && Object.hasOwnProperty.call(message, "timeoutSeconds"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.timeoutSeconds);
                            return writer;
                        };

                        /**
                         * Encodes the specified ClientIPConfig message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ClientIPConfig.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {k8s.io.api.core.v1.IClientIPConfig} message ClientIPConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ClientIPConfig.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ClientIPConfig message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ClientIPConfig} ClientIPConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ClientIPConfig.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ClientIPConfig();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.timeoutSeconds = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ClientIPConfig message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ClientIPConfig} ClientIPConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ClientIPConfig.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ClientIPConfig message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ClientIPConfig.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                                if (!$util.isInteger(message.timeoutSeconds))
                                    return "timeoutSeconds: integer expected";
                            return null;
                        };

                        /**
                         * Creates a ClientIPConfig message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ClientIPConfig} ClientIPConfig
                         */
                        ClientIPConfig.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ClientIPConfig)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ClientIPConfig();
                            if (object.timeoutSeconds != null)
                                message.timeoutSeconds = object.timeoutSeconds | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a ClientIPConfig message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @static
                         * @param {k8s.io.api.core.v1.ClientIPConfig} message ClientIPConfig
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ClientIPConfig.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.timeoutSeconds = 0;
                            if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                                object.timeoutSeconds = message.timeoutSeconds;
                            return object;
                        };

                        /**
                         * Converts this ClientIPConfig to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ClientIPConfig
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ClientIPConfig.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ClientIPConfig;
                    })();

                    v1.ComponentCondition = (function() {

                        /**
                         * Properties of a ComponentCondition.
                         * @memberof k8s.io.api.core.v1
                         * @interface IComponentCondition
                         * @property {string|null} [type] ComponentCondition type
                         * @property {string|null} [status] ComponentCondition status
                         * @property {string|null} [message] ComponentCondition message
                         * @property {string|null} [error] ComponentCondition error
                         */

                        /**
                         * Constructs a new ComponentCondition.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ComponentCondition.
                         * @implements IComponentCondition
                         * @constructor
                         * @param {k8s.io.api.core.v1.IComponentCondition=} [properties] Properties to set
                         */
                        function ComponentCondition(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ComponentCondition type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @instance
                         */
                        ComponentCondition.prototype.type = "";

                        /**
                         * ComponentCondition status.
                         * @member {string} status
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @instance
                         */
                        ComponentCondition.prototype.status = "";

                        /**
                         * ComponentCondition message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @instance
                         */
                        ComponentCondition.prototype.message = "";

                        /**
                         * ComponentCondition error.
                         * @member {string} error
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @instance
                         */
                        ComponentCondition.prototype.error = "";

                        /**
                         * Creates a new ComponentCondition instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentCondition=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ComponentCondition} ComponentCondition instance
                         */
                        ComponentCondition.create = function create(properties) {
                            return new ComponentCondition(properties);
                        };

                        /**
                         * Encodes the specified ComponentCondition message. Does not implicitly {@link k8s.io.api.core.v1.ComponentCondition.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentCondition} message ComponentCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ComponentCondition.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
                            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.error);
                            return writer;
                        };

                        /**
                         * Encodes the specified ComponentCondition message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ComponentCondition.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentCondition} message ComponentCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ComponentCondition.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ComponentCondition message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ComponentCondition} ComponentCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ComponentCondition.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ComponentCondition();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 3:
                                    message.message = reader.string();
                                    break;
                                case 4:
                                    message.error = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ComponentCondition message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ComponentCondition} ComponentCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ComponentCondition.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ComponentCondition message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ComponentCondition.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            if (message.error != null && message.hasOwnProperty("error"))
                                if (!$util.isString(message.error))
                                    return "error: string expected";
                            return null;
                        };

                        /**
                         * Creates a ComponentCondition message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ComponentCondition} ComponentCondition
                         */
                        ComponentCondition.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ComponentCondition)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ComponentCondition();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.message != null)
                                message.message = String(object.message);
                            if (object.error != null)
                                message.error = String(object.error);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ComponentCondition message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @static
                         * @param {k8s.io.api.core.v1.ComponentCondition} message ComponentCondition
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ComponentCondition.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.status = "";
                                object.message = "";
                                object.error = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            if (message.error != null && message.hasOwnProperty("error"))
                                object.error = message.error;
                            return object;
                        };

                        /**
                         * Converts this ComponentCondition to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ComponentCondition
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ComponentCondition.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ComponentCondition;
                    })();

                    v1.ComponentStatus = (function() {

                        /**
                         * Properties of a ComponentStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IComponentStatus
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] ComponentStatus metadata
                         * @property {Array.<k8s.io.api.core.v1.IComponentCondition>|null} [conditions] ComponentStatus conditions
                         */

                        /**
                         * Constructs a new ComponentStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ComponentStatus.
                         * @implements IComponentStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IComponentStatus=} [properties] Properties to set
                         */
                        function ComponentStatus(properties) {
                            this.conditions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ComponentStatus metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @instance
                         */
                        ComponentStatus.prototype.metadata = null;

                        /**
                         * ComponentStatus conditions.
                         * @member {Array.<k8s.io.api.core.v1.IComponentCondition>} conditions
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @instance
                         */
                        ComponentStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * Creates a new ComponentStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ComponentStatus} ComponentStatus instance
                         */
                        ComponentStatus.create = function create(properties) {
                            return new ComponentStatus(properties);
                        };

                        /**
                         * Encodes the specified ComponentStatus message. Does not implicitly {@link k8s.io.api.core.v1.ComponentStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentStatus} message ComponentStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ComponentStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.api.core.v1.ComponentCondition.encode(message.conditions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ComponentStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ComponentStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentStatus} message ComponentStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ComponentStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ComponentStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ComponentStatus} ComponentStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ComponentStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ComponentStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.api.core.v1.ComponentCondition.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ComponentStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ComponentStatus} ComponentStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ComponentStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ComponentStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ComponentStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ComponentCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ComponentStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ComponentStatus} ComponentStatus
                         */
                        ComponentStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ComponentStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ComponentStatus();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ComponentStatus.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.ComponentStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ComponentStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.api.core.v1.ComponentCondition.fromObject(object.conditions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ComponentStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ComponentStatus} message ComponentStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ComponentStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.conditions = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.api.core.v1.ComponentCondition.toObject(message.conditions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ComponentStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ComponentStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ComponentStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ComponentStatus;
                    })();

                    v1.ComponentStatusList = (function() {

                        /**
                         * Properties of a ComponentStatusList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IComponentStatusList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] ComponentStatusList metadata
                         * @property {Array.<k8s.io.api.core.v1.IComponentStatus>|null} [items] ComponentStatusList items
                         */

                        /**
                         * Constructs a new ComponentStatusList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ComponentStatusList.
                         * @implements IComponentStatusList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IComponentStatusList=} [properties] Properties to set
                         */
                        function ComponentStatusList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ComponentStatusList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @instance
                         */
                        ComponentStatusList.prototype.metadata = null;

                        /**
                         * ComponentStatusList items.
                         * @member {Array.<k8s.io.api.core.v1.IComponentStatus>} items
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @instance
                         */
                        ComponentStatusList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new ComponentStatusList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentStatusList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ComponentStatusList} ComponentStatusList instance
                         */
                        ComponentStatusList.create = function create(properties) {
                            return new ComponentStatusList(properties);
                        };

                        /**
                         * Encodes the specified ComponentStatusList message. Does not implicitly {@link k8s.io.api.core.v1.ComponentStatusList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentStatusList} message ComponentStatusList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ComponentStatusList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.ComponentStatus.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ComponentStatusList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ComponentStatusList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {k8s.io.api.core.v1.IComponentStatusList} message ComponentStatusList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ComponentStatusList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ComponentStatusList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ComponentStatusList} ComponentStatusList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ComponentStatusList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ComponentStatusList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.ComponentStatus.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ComponentStatusList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ComponentStatusList} ComponentStatusList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ComponentStatusList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ComponentStatusList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ComponentStatusList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ComponentStatus.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ComponentStatusList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ComponentStatusList} ComponentStatusList
                         */
                        ComponentStatusList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ComponentStatusList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ComponentStatusList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ComponentStatusList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ComponentStatusList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ComponentStatusList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.ComponentStatus.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ComponentStatusList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @static
                         * @param {k8s.io.api.core.v1.ComponentStatusList} message ComponentStatusList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ComponentStatusList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.ComponentStatus.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ComponentStatusList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ComponentStatusList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ComponentStatusList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ComponentStatusList;
                    })();

                    v1.ConfigMap = (function() {

                        /**
                         * Properties of a ConfigMap.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMap
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] ConfigMap metadata
                         * @property {boolean|null} [immutable] ConfigMap immutable
                         * @property {Object.<string,string>|null} [data] ConfigMap data
                         * @property {Object.<string,Uint8Array>|null} [binaryData] ConfigMap binaryData
                         */

                        /**
                         * Constructs a new ConfigMap.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMap.
                         * @implements IConfigMap
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMap=} [properties] Properties to set
                         */
                        function ConfigMap(properties) {
                            this.data = {};
                            this.binaryData = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMap metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @instance
                         */
                        ConfigMap.prototype.metadata = null;

                        /**
                         * ConfigMap immutable.
                         * @member {boolean} immutable
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @instance
                         */
                        ConfigMap.prototype.immutable = false;

                        /**
                         * ConfigMap data.
                         * @member {Object.<string,string>} data
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @instance
                         */
                        ConfigMap.prototype.data = $util.emptyObject;

                        /**
                         * ConfigMap binaryData.
                         * @member {Object.<string,Uint8Array>} binaryData
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @instance
                         */
                        ConfigMap.prototype.binaryData = $util.emptyObject;

                        /**
                         * Creates a new ConfigMap instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMap=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMap} ConfigMap instance
                         */
                        ConfigMap.create = function create(properties) {
                            return new ConfigMap(properties);
                        };

                        /**
                         * Encodes the specified ConfigMap message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMap.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMap} message ConfigMap message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMap.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                                for (var keys = Object.keys(message.data), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.data[keys[i]]).ldelim();
                            if (message.binaryData != null && Object.hasOwnProperty.call(message, "binaryData"))
                                for (var keys = Object.keys(message.binaryData), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.binaryData[keys[i]]).ldelim();
                            if (message.immutable != null && Object.hasOwnProperty.call(message, "immutable"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.immutable);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMap message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMap.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMap} message ConfigMap message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMap.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMap message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMap} ConfigMap
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMap.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMap(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.immutable = reader.bool();
                                    break;
                                case 2:
                                    if (message.data === $util.emptyObject)
                                        message.data = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.data[key] = value;
                                    break;
                                case 3:
                                    if (message.binaryData === $util.emptyObject)
                                        message.binaryData = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = [];
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.bytes();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.binaryData[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMap message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMap} ConfigMap
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMap.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMap message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMap.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.immutable != null && message.hasOwnProperty("immutable"))
                                if (typeof message.immutable !== "boolean")
                                    return "immutable: boolean expected";
                            if (message.data != null && message.hasOwnProperty("data")) {
                                if (!$util.isObject(message.data))
                                    return "data: object expected";
                                var key = Object.keys(message.data);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.data[key[i]]))
                                        return "data: string{k:string} expected";
                            }
                            if (message.binaryData != null && message.hasOwnProperty("binaryData")) {
                                if (!$util.isObject(message.binaryData))
                                    return "binaryData: object expected";
                                var key = Object.keys(message.binaryData);
                                for (var i = 0; i < key.length; ++i)
                                    if (!(message.binaryData[key[i]] && typeof message.binaryData[key[i]].length === "number" || $util.isString(message.binaryData[key[i]])))
                                        return "binaryData: buffer{k:string} expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a ConfigMap message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMap} ConfigMap
                         */
                        ConfigMap.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMap)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMap();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMap.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.immutable != null)
                                message.immutable = Boolean(object.immutable);
                            if (object.data) {
                                if (typeof object.data !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMap.data: object expected");
                                message.data = {};
                                for (var keys = Object.keys(object.data), i = 0; i < keys.length; ++i)
                                    message.data[keys[i]] = String(object.data[keys[i]]);
                            }
                            if (object.binaryData) {
                                if (typeof object.binaryData !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMap.binaryData: object expected");
                                message.binaryData = {};
                                for (var keys = Object.keys(object.binaryData), i = 0; i < keys.length; ++i)
                                    if (typeof object.binaryData[keys[i]] === "string")
                                        $util.base64.decode(object.binaryData[keys[i]], message.binaryData[keys[i]] = $util.newBuffer($util.base64.length(object.binaryData[keys[i]])), 0);
                                    else if (object.binaryData[keys[i]].length)
                                        message.binaryData[keys[i]] = object.binaryData[keys[i]];
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMap message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMap} message ConfigMap
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMap.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults) {
                                object.data = {};
                                object.binaryData = {};
                            }
                            if (options.defaults) {
                                object.metadata = null;
                                object.immutable = false;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            var keys2;
                            if (message.data && (keys2 = Object.keys(message.data)).length) {
                                object.data = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.data[keys2[j]] = message.data[keys2[j]];
                            }
                            if (message.binaryData && (keys2 = Object.keys(message.binaryData)).length) {
                                object.binaryData = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.binaryData[keys2[j]] = options.bytes === String ? $util.base64.encode(message.binaryData[keys2[j]], 0, message.binaryData[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.binaryData[keys2[j]]) : message.binaryData[keys2[j]];
                            }
                            if (message.immutable != null && message.hasOwnProperty("immutable"))
                                object.immutable = message.immutable;
                            return object;
                        };

                        /**
                         * Converts this ConfigMap to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMap
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMap.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMap;
                    })();

                    v1.ConfigMapEnvSource = (function() {

                        /**
                         * Properties of a ConfigMapEnvSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMapEnvSource
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] ConfigMapEnvSource localObjectReference
                         * @property {boolean|null} [optional] ConfigMapEnvSource optional
                         */

                        /**
                         * Constructs a new ConfigMapEnvSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMapEnvSource.
                         * @implements IConfigMapEnvSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMapEnvSource=} [properties] Properties to set
                         */
                        function ConfigMapEnvSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMapEnvSource localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @instance
                         */
                        ConfigMapEnvSource.prototype.localObjectReference = null;

                        /**
                         * ConfigMapEnvSource optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @instance
                         */
                        ConfigMapEnvSource.prototype.optional = false;

                        /**
                         * Creates a new ConfigMapEnvSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapEnvSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMapEnvSource} ConfigMapEnvSource instance
                         */
                        ConfigMapEnvSource.create = function create(properties) {
                            return new ConfigMapEnvSource(properties);
                        };

                        /**
                         * Encodes the specified ConfigMapEnvSource message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapEnvSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapEnvSource} message ConfigMapEnvSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapEnvSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMapEnvSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapEnvSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapEnvSource} message ConfigMapEnvSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapEnvSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMapEnvSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMapEnvSource} ConfigMapEnvSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapEnvSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMapEnvSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMapEnvSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMapEnvSource} ConfigMapEnvSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapEnvSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMapEnvSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMapEnvSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ConfigMapEnvSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMapEnvSource} ConfigMapEnvSource
                         */
                        ConfigMapEnvSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMapEnvSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMapEnvSource();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapEnvSource.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMapEnvSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMapEnvSource} message ConfigMapEnvSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMapEnvSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this ConfigMapEnvSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMapEnvSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMapEnvSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMapEnvSource;
                    })();

                    v1.ConfigMapKeySelector = (function() {

                        /**
                         * Properties of a ConfigMapKeySelector.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMapKeySelector
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] ConfigMapKeySelector localObjectReference
                         * @property {string|null} [key] ConfigMapKeySelector key
                         * @property {boolean|null} [optional] ConfigMapKeySelector optional
                         */

                        /**
                         * Constructs a new ConfigMapKeySelector.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMapKeySelector.
                         * @implements IConfigMapKeySelector
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMapKeySelector=} [properties] Properties to set
                         */
                        function ConfigMapKeySelector(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMapKeySelector localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @instance
                         */
                        ConfigMapKeySelector.prototype.localObjectReference = null;

                        /**
                         * ConfigMapKeySelector key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @instance
                         */
                        ConfigMapKeySelector.prototype.key = "";

                        /**
                         * ConfigMapKeySelector optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @instance
                         */
                        ConfigMapKeySelector.prototype.optional = false;

                        /**
                         * Creates a new ConfigMapKeySelector instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapKeySelector=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMapKeySelector} ConfigMapKeySelector instance
                         */
                        ConfigMapKeySelector.create = function create(properties) {
                            return new ConfigMapKeySelector(properties);
                        };

                        /**
                         * Encodes the specified ConfigMapKeySelector message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapKeySelector.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapKeySelector} message ConfigMapKeySelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapKeySelector.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMapKeySelector message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapKeySelector.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapKeySelector} message ConfigMapKeySelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapKeySelector.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMapKeySelector message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMapKeySelector} ConfigMapKeySelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapKeySelector.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMapKeySelector();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.key = reader.string();
                                    break;
                                case 3:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMapKeySelector message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMapKeySelector} ConfigMapKeySelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapKeySelector.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMapKeySelector message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMapKeySelector.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ConfigMapKeySelector message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMapKeySelector} ConfigMapKeySelector
                         */
                        ConfigMapKeySelector.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMapKeySelector)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMapKeySelector();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapKeySelector.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMapKeySelector message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMapKeySelector} message ConfigMapKeySelector
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMapKeySelector.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.key = "";
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this ConfigMapKeySelector to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMapKeySelector
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMapKeySelector.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMapKeySelector;
                    })();

                    v1.ConfigMapList = (function() {

                        /**
                         * Properties of a ConfigMapList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMapList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] ConfigMapList metadata
                         * @property {Array.<k8s.io.api.core.v1.IConfigMap>|null} [items] ConfigMapList items
                         */

                        /**
                         * Constructs a new ConfigMapList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMapList.
                         * @implements IConfigMapList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMapList=} [properties] Properties to set
                         */
                        function ConfigMapList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMapList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @instance
                         */
                        ConfigMapList.prototype.metadata = null;

                        /**
                         * ConfigMapList items.
                         * @member {Array.<k8s.io.api.core.v1.IConfigMap>} items
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @instance
                         */
                        ConfigMapList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new ConfigMapList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMapList} ConfigMapList instance
                         */
                        ConfigMapList.create = function create(properties) {
                            return new ConfigMapList(properties);
                        };

                        /**
                         * Encodes the specified ConfigMapList message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapList} message ConfigMapList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.ConfigMap.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMapList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapList} message ConfigMapList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMapList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMapList} ConfigMapList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMapList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.ConfigMap.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMapList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMapList} ConfigMapList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMapList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMapList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ConfigMap.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ConfigMapList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMapList} ConfigMapList
                         */
                        ConfigMapList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMapList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMapList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ConfigMapList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.ConfigMap.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMapList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMapList} message ConfigMapList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMapList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.ConfigMap.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ConfigMapList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMapList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMapList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMapList;
                    })();

                    v1.ConfigMapNodeConfigSource = (function() {

                        /**
                         * Properties of a ConfigMapNodeConfigSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMapNodeConfigSource
                         * @property {string|null} [namespace] ConfigMapNodeConfigSource namespace
                         * @property {string|null} [name] ConfigMapNodeConfigSource name
                         * @property {string|null} [uid] ConfigMapNodeConfigSource uid
                         * @property {string|null} [resourceVersion] ConfigMapNodeConfigSource resourceVersion
                         * @property {string|null} [kubeletConfigKey] ConfigMapNodeConfigSource kubeletConfigKey
                         */

                        /**
                         * Constructs a new ConfigMapNodeConfigSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMapNodeConfigSource.
                         * @implements IConfigMapNodeConfigSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMapNodeConfigSource=} [properties] Properties to set
                         */
                        function ConfigMapNodeConfigSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMapNodeConfigSource namespace.
                         * @member {string} namespace
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @instance
                         */
                        ConfigMapNodeConfigSource.prototype.namespace = "";

                        /**
                         * ConfigMapNodeConfigSource name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @instance
                         */
                        ConfigMapNodeConfigSource.prototype.name = "";

                        /**
                         * ConfigMapNodeConfigSource uid.
                         * @member {string} uid
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @instance
                         */
                        ConfigMapNodeConfigSource.prototype.uid = "";

                        /**
                         * ConfigMapNodeConfigSource resourceVersion.
                         * @member {string} resourceVersion
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @instance
                         */
                        ConfigMapNodeConfigSource.prototype.resourceVersion = "";

                        /**
                         * ConfigMapNodeConfigSource kubeletConfigKey.
                         * @member {string} kubeletConfigKey
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @instance
                         */
                        ConfigMapNodeConfigSource.prototype.kubeletConfigKey = "";

                        /**
                         * Creates a new ConfigMapNodeConfigSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapNodeConfigSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMapNodeConfigSource} ConfigMapNodeConfigSource instance
                         */
                        ConfigMapNodeConfigSource.create = function create(properties) {
                            return new ConfigMapNodeConfigSource(properties);
                        };

                        /**
                         * Encodes the specified ConfigMapNodeConfigSource message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapNodeConfigSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapNodeConfigSource} message ConfigMapNodeConfigSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapNodeConfigSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.namespace != null && Object.hasOwnProperty.call(message, "namespace"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.namespace);
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.uid);
                            if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.resourceVersion);
                            if (message.kubeletConfigKey != null && Object.hasOwnProperty.call(message, "kubeletConfigKey"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.kubeletConfigKey);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMapNodeConfigSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapNodeConfigSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapNodeConfigSource} message ConfigMapNodeConfigSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapNodeConfigSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMapNodeConfigSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMapNodeConfigSource} ConfigMapNodeConfigSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapNodeConfigSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.namespace = reader.string();
                                    break;
                                case 2:
                                    message.name = reader.string();
                                    break;
                                case 3:
                                    message.uid = reader.string();
                                    break;
                                case 4:
                                    message.resourceVersion = reader.string();
                                    break;
                                case 5:
                                    message.kubeletConfigKey = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMapNodeConfigSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMapNodeConfigSource} ConfigMapNodeConfigSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapNodeConfigSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMapNodeConfigSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMapNodeConfigSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.namespace != null && message.hasOwnProperty("namespace"))
                                if (!$util.isString(message.namespace))
                                    return "namespace: string expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.uid != null && message.hasOwnProperty("uid"))
                                if (!$util.isString(message.uid))
                                    return "uid: string expected";
                            if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                if (!$util.isString(message.resourceVersion))
                                    return "resourceVersion: string expected";
                            if (message.kubeletConfigKey != null && message.hasOwnProperty("kubeletConfigKey"))
                                if (!$util.isString(message.kubeletConfigKey))
                                    return "kubeletConfigKey: string expected";
                            return null;
                        };

                        /**
                         * Creates a ConfigMapNodeConfigSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMapNodeConfigSource} ConfigMapNodeConfigSource
                         */
                        ConfigMapNodeConfigSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource();
                            if (object.namespace != null)
                                message.namespace = String(object.namespace);
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.uid != null)
                                message.uid = String(object.uid);
                            if (object.resourceVersion != null)
                                message.resourceVersion = String(object.resourceVersion);
                            if (object.kubeletConfigKey != null)
                                message.kubeletConfigKey = String(object.kubeletConfigKey);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMapNodeConfigSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMapNodeConfigSource} message ConfigMapNodeConfigSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMapNodeConfigSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.namespace = "";
                                object.name = "";
                                object.uid = "";
                                object.resourceVersion = "";
                                object.kubeletConfigKey = "";
                            }
                            if (message.namespace != null && message.hasOwnProperty("namespace"))
                                object.namespace = message.namespace;
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.uid != null && message.hasOwnProperty("uid"))
                                object.uid = message.uid;
                            if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                object.resourceVersion = message.resourceVersion;
                            if (message.kubeletConfigKey != null && message.hasOwnProperty("kubeletConfigKey"))
                                object.kubeletConfigKey = message.kubeletConfigKey;
                            return object;
                        };

                        /**
                         * Converts this ConfigMapNodeConfigSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMapNodeConfigSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMapNodeConfigSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMapNodeConfigSource;
                    })();

                    v1.ConfigMapProjection = (function() {

                        /**
                         * Properties of a ConfigMapProjection.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMapProjection
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] ConfigMapProjection localObjectReference
                         * @property {Array.<k8s.io.api.core.v1.IKeyToPath>|null} [items] ConfigMapProjection items
                         * @property {boolean|null} [optional] ConfigMapProjection optional
                         */

                        /**
                         * Constructs a new ConfigMapProjection.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMapProjection.
                         * @implements IConfigMapProjection
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMapProjection=} [properties] Properties to set
                         */
                        function ConfigMapProjection(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMapProjection localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @instance
                         */
                        ConfigMapProjection.prototype.localObjectReference = null;

                        /**
                         * ConfigMapProjection items.
                         * @member {Array.<k8s.io.api.core.v1.IKeyToPath>} items
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @instance
                         */
                        ConfigMapProjection.prototype.items = $util.emptyArray;

                        /**
                         * ConfigMapProjection optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @instance
                         */
                        ConfigMapProjection.prototype.optional = false;

                        /**
                         * Creates a new ConfigMapProjection instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapProjection=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMapProjection} ConfigMapProjection instance
                         */
                        ConfigMapProjection.create = function create(properties) {
                            return new ConfigMapProjection(properties);
                        };

                        /**
                         * Encodes the specified ConfigMapProjection message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapProjection.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapProjection} message ConfigMapProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapProjection.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.KeyToPath.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMapProjection message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapProjection.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapProjection} message ConfigMapProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapProjection.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMapProjection message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMapProjection} ConfigMapProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapProjection.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMapProjection();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.KeyToPath.decode(reader, reader.uint32()));
                                    break;
                                case 4:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMapProjection message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMapProjection} ConfigMapProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapProjection.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMapProjection message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMapProjection.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.KeyToPath.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ConfigMapProjection message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMapProjection} ConfigMapProjection
                         */
                        ConfigMapProjection.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMapProjection)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMapProjection();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapProjection.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapProjection.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ConfigMapProjection.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.KeyToPath.fromObject(object.items[i]);
                                }
                            }
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMapProjection message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMapProjection} message ConfigMapProjection
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMapProjection.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.KeyToPath.toObject(message.items[j], options);
                            }
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this ConfigMapProjection to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMapProjection
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMapProjection.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMapProjection;
                    })();

                    v1.ConfigMapVolumeSource = (function() {

                        /**
                         * Properties of a ConfigMapVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IConfigMapVolumeSource
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] ConfigMapVolumeSource localObjectReference
                         * @property {Array.<k8s.io.api.core.v1.IKeyToPath>|null} [items] ConfigMapVolumeSource items
                         * @property {number|null} [defaultMode] ConfigMapVolumeSource defaultMode
                         * @property {boolean|null} [optional] ConfigMapVolumeSource optional
                         */

                        /**
                         * Constructs a new ConfigMapVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ConfigMapVolumeSource.
                         * @implements IConfigMapVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IConfigMapVolumeSource=} [properties] Properties to set
                         */
                        function ConfigMapVolumeSource(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ConfigMapVolumeSource localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @instance
                         */
                        ConfigMapVolumeSource.prototype.localObjectReference = null;

                        /**
                         * ConfigMapVolumeSource items.
                         * @member {Array.<k8s.io.api.core.v1.IKeyToPath>} items
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @instance
                         */
                        ConfigMapVolumeSource.prototype.items = $util.emptyArray;

                        /**
                         * ConfigMapVolumeSource defaultMode.
                         * @member {number} defaultMode
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @instance
                         */
                        ConfigMapVolumeSource.prototype.defaultMode = 0;

                        /**
                         * ConfigMapVolumeSource optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @instance
                         */
                        ConfigMapVolumeSource.prototype.optional = false;

                        /**
                         * Creates a new ConfigMapVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ConfigMapVolumeSource} ConfigMapVolumeSource instance
                         */
                        ConfigMapVolumeSource.create = function create(properties) {
                            return new ConfigMapVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified ConfigMapVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapVolumeSource} message ConfigMapVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.KeyToPath.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.defaultMode != null && Object.hasOwnProperty.call(message, "defaultMode"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.defaultMode);
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified ConfigMapVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ConfigMapVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IConfigMapVolumeSource} message ConfigMapVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ConfigMapVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ConfigMapVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ConfigMapVolumeSource} ConfigMapVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ConfigMapVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.KeyToPath.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    message.defaultMode = reader.int32();
                                    break;
                                case 4:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ConfigMapVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ConfigMapVolumeSource} ConfigMapVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ConfigMapVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ConfigMapVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ConfigMapVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.KeyToPath.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                if (!$util.isInteger(message.defaultMode))
                                    return "defaultMode: integer expected";
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ConfigMapVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ConfigMapVolumeSource} ConfigMapVolumeSource
                         */
                        ConfigMapVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ConfigMapVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ConfigMapVolumeSource();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapVolumeSource.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ConfigMapVolumeSource.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ConfigMapVolumeSource.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.KeyToPath.fromObject(object.items[i]);
                                }
                            }
                            if (object.defaultMode != null)
                                message.defaultMode = object.defaultMode | 0;
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ConfigMapVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ConfigMapVolumeSource} message ConfigMapVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ConfigMapVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.defaultMode = 0;
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.KeyToPath.toObject(message.items[j], options);
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                object.defaultMode = message.defaultMode;
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this ConfigMapVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ConfigMapVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ConfigMapVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ConfigMapVolumeSource;
                    })();

                    v1.Container = (function() {

                        /**
                         * Properties of a Container.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainer
                         * @property {string|null} [name] Container name
                         * @property {string|null} [image] Container image
                         * @property {Array.<string>|null} [command] Container command
                         * @property {Array.<string>|null} [args] Container args
                         * @property {string|null} [workingDir] Container workingDir
                         * @property {Array.<k8s.io.api.core.v1.IContainerPort>|null} [ports] Container ports
                         * @property {Array.<k8s.io.api.core.v1.IEnvFromSource>|null} [envFrom] Container envFrom
                         * @property {Array.<k8s.io.api.core.v1.IEnvVar>|null} [env] Container env
                         * @property {k8s.io.api.core.v1.IResourceRequirements|null} [resources] Container resources
                         * @property {Array.<k8s.io.api.core.v1.IVolumeMount>|null} [volumeMounts] Container volumeMounts
                         * @property {Array.<k8s.io.api.core.v1.IVolumeDevice>|null} [volumeDevices] Container volumeDevices
                         * @property {k8s.io.api.core.v1.IProbe|null} [livenessProbe] Container livenessProbe
                         * @property {k8s.io.api.core.v1.IProbe|null} [readinessProbe] Container readinessProbe
                         * @property {k8s.io.api.core.v1.IProbe|null} [startupProbe] Container startupProbe
                         * @property {k8s.io.api.core.v1.ILifecycle|null} [lifecycle] Container lifecycle
                         * @property {string|null} [terminationMessagePath] Container terminationMessagePath
                         * @property {string|null} [terminationMessagePolicy] Container terminationMessagePolicy
                         * @property {string|null} [imagePullPolicy] Container imagePullPolicy
                         * @property {k8s.io.api.core.v1.ISecurityContext|null} [securityContext] Container securityContext
                         * @property {boolean|null} [stdin] Container stdin
                         * @property {boolean|null} [stdinOnce] Container stdinOnce
                         * @property {boolean|null} [tty] Container tty
                         */

                        /**
                         * Constructs a new Container.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Container.
                         * @implements IContainer
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainer=} [properties] Properties to set
                         */
                        function Container(properties) {
                            this.command = [];
                            this.args = [];
                            this.ports = [];
                            this.envFrom = [];
                            this.env = [];
                            this.volumeMounts = [];
                            this.volumeDevices = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Container name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.name = "";

                        /**
                         * Container image.
                         * @member {string} image
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.image = "";

                        /**
                         * Container command.
                         * @member {Array.<string>} command
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.command = $util.emptyArray;

                        /**
                         * Container args.
                         * @member {Array.<string>} args
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.args = $util.emptyArray;

                        /**
                         * Container workingDir.
                         * @member {string} workingDir
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.workingDir = "";

                        /**
                         * Container ports.
                         * @member {Array.<k8s.io.api.core.v1.IContainerPort>} ports
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.ports = $util.emptyArray;

                        /**
                         * Container envFrom.
                         * @member {Array.<k8s.io.api.core.v1.IEnvFromSource>} envFrom
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.envFrom = $util.emptyArray;

                        /**
                         * Container env.
                         * @member {Array.<k8s.io.api.core.v1.IEnvVar>} env
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.env = $util.emptyArray;

                        /**
                         * Container resources.
                         * @member {k8s.io.api.core.v1.IResourceRequirements|null|undefined} resources
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.resources = null;

                        /**
                         * Container volumeMounts.
                         * @member {Array.<k8s.io.api.core.v1.IVolumeMount>} volumeMounts
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.volumeMounts = $util.emptyArray;

                        /**
                         * Container volumeDevices.
                         * @member {Array.<k8s.io.api.core.v1.IVolumeDevice>} volumeDevices
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.volumeDevices = $util.emptyArray;

                        /**
                         * Container livenessProbe.
                         * @member {k8s.io.api.core.v1.IProbe|null|undefined} livenessProbe
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.livenessProbe = null;

                        /**
                         * Container readinessProbe.
                         * @member {k8s.io.api.core.v1.IProbe|null|undefined} readinessProbe
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.readinessProbe = null;

                        /**
                         * Container startupProbe.
                         * @member {k8s.io.api.core.v1.IProbe|null|undefined} startupProbe
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.startupProbe = null;

                        /**
                         * Container lifecycle.
                         * @member {k8s.io.api.core.v1.ILifecycle|null|undefined} lifecycle
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.lifecycle = null;

                        /**
                         * Container terminationMessagePath.
                         * @member {string} terminationMessagePath
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.terminationMessagePath = "";

                        /**
                         * Container terminationMessagePolicy.
                         * @member {string} terminationMessagePolicy
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.terminationMessagePolicy = "";

                        /**
                         * Container imagePullPolicy.
                         * @member {string} imagePullPolicy
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.imagePullPolicy = "";

                        /**
                         * Container securityContext.
                         * @member {k8s.io.api.core.v1.ISecurityContext|null|undefined} securityContext
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.securityContext = null;

                        /**
                         * Container stdin.
                         * @member {boolean} stdin
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.stdin = false;

                        /**
                         * Container stdinOnce.
                         * @member {boolean} stdinOnce
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.stdinOnce = false;

                        /**
                         * Container tty.
                         * @member {boolean} tty
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         */
                        Container.prototype.tty = false;

                        /**
                         * Creates a new Container instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {k8s.io.api.core.v1.IContainer=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Container} Container instance
                         */
                        Container.create = function create(properties) {
                            return new Container(properties);
                        };

                        /**
                         * Encodes the specified Container message. Does not implicitly {@link k8s.io.api.core.v1.Container.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {k8s.io.api.core.v1.IContainer} message Container message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Container.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.image);
                            if (message.command != null && message.command.length)
                                for (var i = 0; i < message.command.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.command[i]);
                            if (message.args != null && message.args.length)
                                for (var i = 0; i < message.args.length; ++i)
                                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.args[i]);
                            if (message.workingDir != null && Object.hasOwnProperty.call(message, "workingDir"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.workingDir);
                            if (message.ports != null && message.ports.length)
                                for (var i = 0; i < message.ports.length; ++i)
                                    $root.k8s.io.api.core.v1.ContainerPort.encode(message.ports[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.env != null && message.env.length)
                                for (var i = 0; i < message.env.length; ++i)
                                    $root.k8s.io.api.core.v1.EnvVar.encode(message.env[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.resources != null && Object.hasOwnProperty.call(message, "resources"))
                                $root.k8s.io.api.core.v1.ResourceRequirements.encode(message.resources, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.volumeMounts != null && message.volumeMounts.length)
                                for (var i = 0; i < message.volumeMounts.length; ++i)
                                    $root.k8s.io.api.core.v1.VolumeMount.encode(message.volumeMounts[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.livenessProbe != null && Object.hasOwnProperty.call(message, "livenessProbe"))
                                $root.k8s.io.api.core.v1.Probe.encode(message.livenessProbe, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.readinessProbe != null && Object.hasOwnProperty.call(message, "readinessProbe"))
                                $root.k8s.io.api.core.v1.Probe.encode(message.readinessProbe, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.lifecycle != null && Object.hasOwnProperty.call(message, "lifecycle"))
                                $root.k8s.io.api.core.v1.Lifecycle.encode(message.lifecycle, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.terminationMessagePath != null && Object.hasOwnProperty.call(message, "terminationMessagePath"))
                                writer.uint32(/* id 13, wireType 2 =*/106).string(message.terminationMessagePath);
                            if (message.imagePullPolicy != null && Object.hasOwnProperty.call(message, "imagePullPolicy"))
                                writer.uint32(/* id 14, wireType 2 =*/114).string(message.imagePullPolicy);
                            if (message.securityContext != null && Object.hasOwnProperty.call(message, "securityContext"))
                                $root.k8s.io.api.core.v1.SecurityContext.encode(message.securityContext, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.stdin != null && Object.hasOwnProperty.call(message, "stdin"))
                                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.stdin);
                            if (message.stdinOnce != null && Object.hasOwnProperty.call(message, "stdinOnce"))
                                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.stdinOnce);
                            if (message.tty != null && Object.hasOwnProperty.call(message, "tty"))
                                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.tty);
                            if (message.envFrom != null && message.envFrom.length)
                                for (var i = 0; i < message.envFrom.length; ++i)
                                    $root.k8s.io.api.core.v1.EnvFromSource.encode(message.envFrom[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.terminationMessagePolicy != null && Object.hasOwnProperty.call(message, "terminationMessagePolicy"))
                                writer.uint32(/* id 20, wireType 2 =*/162).string(message.terminationMessagePolicy);
                            if (message.volumeDevices != null && message.volumeDevices.length)
                                for (var i = 0; i < message.volumeDevices.length; ++i)
                                    $root.k8s.io.api.core.v1.VolumeDevice.encode(message.volumeDevices[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.startupProbe != null && Object.hasOwnProperty.call(message, "startupProbe"))
                                $root.k8s.io.api.core.v1.Probe.encode(message.startupProbe, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Container message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Container.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {k8s.io.api.core.v1.IContainer} message Container message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Container.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Container message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Container} Container
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Container.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Container();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.image = reader.string();
                                    break;
                                case 3:
                                    if (!(message.command && message.command.length))
                                        message.command = [];
                                    message.command.push(reader.string());
                                    break;
                                case 4:
                                    if (!(message.args && message.args.length))
                                        message.args = [];
                                    message.args.push(reader.string());
                                    break;
                                case 5:
                                    message.workingDir = reader.string();
                                    break;
                                case 6:
                                    if (!(message.ports && message.ports.length))
                                        message.ports = [];
                                    message.ports.push($root.k8s.io.api.core.v1.ContainerPort.decode(reader, reader.uint32()));
                                    break;
                                case 19:
                                    if (!(message.envFrom && message.envFrom.length))
                                        message.envFrom = [];
                                    message.envFrom.push($root.k8s.io.api.core.v1.EnvFromSource.decode(reader, reader.uint32()));
                                    break;
                                case 7:
                                    if (!(message.env && message.env.length))
                                        message.env = [];
                                    message.env.push($root.k8s.io.api.core.v1.EnvVar.decode(reader, reader.uint32()));
                                    break;
                                case 8:
                                    message.resources = $root.k8s.io.api.core.v1.ResourceRequirements.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    if (!(message.volumeMounts && message.volumeMounts.length))
                                        message.volumeMounts = [];
                                    message.volumeMounts.push($root.k8s.io.api.core.v1.VolumeMount.decode(reader, reader.uint32()));
                                    break;
                                case 21:
                                    if (!(message.volumeDevices && message.volumeDevices.length))
                                        message.volumeDevices = [];
                                    message.volumeDevices.push($root.k8s.io.api.core.v1.VolumeDevice.decode(reader, reader.uint32()));
                                    break;
                                case 10:
                                    message.livenessProbe = $root.k8s.io.api.core.v1.Probe.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.readinessProbe = $root.k8s.io.api.core.v1.Probe.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.startupProbe = $root.k8s.io.api.core.v1.Probe.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.lifecycle = $root.k8s.io.api.core.v1.Lifecycle.decode(reader, reader.uint32());
                                    break;
                                case 13:
                                    message.terminationMessagePath = reader.string();
                                    break;
                                case 20:
                                    message.terminationMessagePolicy = reader.string();
                                    break;
                                case 14:
                                    message.imagePullPolicy = reader.string();
                                    break;
                                case 15:
                                    message.securityContext = $root.k8s.io.api.core.v1.SecurityContext.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.stdin = reader.bool();
                                    break;
                                case 17:
                                    message.stdinOnce = reader.bool();
                                    break;
                                case 18:
                                    message.tty = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Container message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Container} Container
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Container.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Container message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Container.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.image != null && message.hasOwnProperty("image"))
                                if (!$util.isString(message.image))
                                    return "image: string expected";
                            if (message.command != null && message.hasOwnProperty("command")) {
                                if (!Array.isArray(message.command))
                                    return "command: array expected";
                                for (var i = 0; i < message.command.length; ++i)
                                    if (!$util.isString(message.command[i]))
                                        return "command: string[] expected";
                            }
                            if (message.args != null && message.hasOwnProperty("args")) {
                                if (!Array.isArray(message.args))
                                    return "args: array expected";
                                for (var i = 0; i < message.args.length; ++i)
                                    if (!$util.isString(message.args[i]))
                                        return "args: string[] expected";
                            }
                            if (message.workingDir != null && message.hasOwnProperty("workingDir"))
                                if (!$util.isString(message.workingDir))
                                    return "workingDir: string expected";
                            if (message.ports != null && message.hasOwnProperty("ports")) {
                                if (!Array.isArray(message.ports))
                                    return "ports: array expected";
                                for (var i = 0; i < message.ports.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ContainerPort.verify(message.ports[i]);
                                    if (error)
                                        return "ports." + error;
                                }
                            }
                            if (message.envFrom != null && message.hasOwnProperty("envFrom")) {
                                if (!Array.isArray(message.envFrom))
                                    return "envFrom: array expected";
                                for (var i = 0; i < message.envFrom.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EnvFromSource.verify(message.envFrom[i]);
                                    if (error)
                                        return "envFrom." + error;
                                }
                            }
                            if (message.env != null && message.hasOwnProperty("env")) {
                                if (!Array.isArray(message.env))
                                    return "env: array expected";
                                for (var i = 0; i < message.env.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EnvVar.verify(message.env[i]);
                                    if (error)
                                        return "env." + error;
                                }
                            }
                            if (message.resources != null && message.hasOwnProperty("resources")) {
                                var error = $root.k8s.io.api.core.v1.ResourceRequirements.verify(message.resources);
                                if (error)
                                    return "resources." + error;
                            }
                            if (message.volumeMounts != null && message.hasOwnProperty("volumeMounts")) {
                                if (!Array.isArray(message.volumeMounts))
                                    return "volumeMounts: array expected";
                                for (var i = 0; i < message.volumeMounts.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.VolumeMount.verify(message.volumeMounts[i]);
                                    if (error)
                                        return "volumeMounts." + error;
                                }
                            }
                            if (message.volumeDevices != null && message.hasOwnProperty("volumeDevices")) {
                                if (!Array.isArray(message.volumeDevices))
                                    return "volumeDevices: array expected";
                                for (var i = 0; i < message.volumeDevices.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.VolumeDevice.verify(message.volumeDevices[i]);
                                    if (error)
                                        return "volumeDevices." + error;
                                }
                            }
                            if (message.livenessProbe != null && message.hasOwnProperty("livenessProbe")) {
                                var error = $root.k8s.io.api.core.v1.Probe.verify(message.livenessProbe);
                                if (error)
                                    return "livenessProbe." + error;
                            }
                            if (message.readinessProbe != null && message.hasOwnProperty("readinessProbe")) {
                                var error = $root.k8s.io.api.core.v1.Probe.verify(message.readinessProbe);
                                if (error)
                                    return "readinessProbe." + error;
                            }
                            if (message.startupProbe != null && message.hasOwnProperty("startupProbe")) {
                                var error = $root.k8s.io.api.core.v1.Probe.verify(message.startupProbe);
                                if (error)
                                    return "startupProbe." + error;
                            }
                            if (message.lifecycle != null && message.hasOwnProperty("lifecycle")) {
                                var error = $root.k8s.io.api.core.v1.Lifecycle.verify(message.lifecycle);
                                if (error)
                                    return "lifecycle." + error;
                            }
                            if (message.terminationMessagePath != null && message.hasOwnProperty("terminationMessagePath"))
                                if (!$util.isString(message.terminationMessagePath))
                                    return "terminationMessagePath: string expected";
                            if (message.terminationMessagePolicy != null && message.hasOwnProperty("terminationMessagePolicy"))
                                if (!$util.isString(message.terminationMessagePolicy))
                                    return "terminationMessagePolicy: string expected";
                            if (message.imagePullPolicy != null && message.hasOwnProperty("imagePullPolicy"))
                                if (!$util.isString(message.imagePullPolicy))
                                    return "imagePullPolicy: string expected";
                            if (message.securityContext != null && message.hasOwnProperty("securityContext")) {
                                var error = $root.k8s.io.api.core.v1.SecurityContext.verify(message.securityContext);
                                if (error)
                                    return "securityContext." + error;
                            }
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                if (typeof message.stdin !== "boolean")
                                    return "stdin: boolean expected";
                            if (message.stdinOnce != null && message.hasOwnProperty("stdinOnce"))
                                if (typeof message.stdinOnce !== "boolean")
                                    return "stdinOnce: boolean expected";
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                if (typeof message.tty !== "boolean")
                                    return "tty: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a Container message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Container} Container
                         */
                        Container.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Container)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Container();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.image != null)
                                message.image = String(object.image);
                            if (object.command) {
                                if (!Array.isArray(object.command))
                                    throw TypeError(".k8s.io.api.core.v1.Container.command: array expected");
                                message.command = [];
                                for (var i = 0; i < object.command.length; ++i)
                                    message.command[i] = String(object.command[i]);
                            }
                            if (object.args) {
                                if (!Array.isArray(object.args))
                                    throw TypeError(".k8s.io.api.core.v1.Container.args: array expected");
                                message.args = [];
                                for (var i = 0; i < object.args.length; ++i)
                                    message.args[i] = String(object.args[i]);
                            }
                            if (object.workingDir != null)
                                message.workingDir = String(object.workingDir);
                            if (object.ports) {
                                if (!Array.isArray(object.ports))
                                    throw TypeError(".k8s.io.api.core.v1.Container.ports: array expected");
                                message.ports = [];
                                for (var i = 0; i < object.ports.length; ++i) {
                                    if (typeof object.ports[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.Container.ports: object expected");
                                    message.ports[i] = $root.k8s.io.api.core.v1.ContainerPort.fromObject(object.ports[i]);
                                }
                            }
                            if (object.envFrom) {
                                if (!Array.isArray(object.envFrom))
                                    throw TypeError(".k8s.io.api.core.v1.Container.envFrom: array expected");
                                message.envFrom = [];
                                for (var i = 0; i < object.envFrom.length; ++i) {
                                    if (typeof object.envFrom[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.Container.envFrom: object expected");
                                    message.envFrom[i] = $root.k8s.io.api.core.v1.EnvFromSource.fromObject(object.envFrom[i]);
                                }
                            }
                            if (object.env) {
                                if (!Array.isArray(object.env))
                                    throw TypeError(".k8s.io.api.core.v1.Container.env: array expected");
                                message.env = [];
                                for (var i = 0; i < object.env.length; ++i) {
                                    if (typeof object.env[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.Container.env: object expected");
                                    message.env[i] = $root.k8s.io.api.core.v1.EnvVar.fromObject(object.env[i]);
                                }
                            }
                            if (object.resources != null) {
                                if (typeof object.resources !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Container.resources: object expected");
                                message.resources = $root.k8s.io.api.core.v1.ResourceRequirements.fromObject(object.resources);
                            }
                            if (object.volumeMounts) {
                                if (!Array.isArray(object.volumeMounts))
                                    throw TypeError(".k8s.io.api.core.v1.Container.volumeMounts: array expected");
                                message.volumeMounts = [];
                                for (var i = 0; i < object.volumeMounts.length; ++i) {
                                    if (typeof object.volumeMounts[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.Container.volumeMounts: object expected");
                                    message.volumeMounts[i] = $root.k8s.io.api.core.v1.VolumeMount.fromObject(object.volumeMounts[i]);
                                }
                            }
                            if (object.volumeDevices) {
                                if (!Array.isArray(object.volumeDevices))
                                    throw TypeError(".k8s.io.api.core.v1.Container.volumeDevices: array expected");
                                message.volumeDevices = [];
                                for (var i = 0; i < object.volumeDevices.length; ++i) {
                                    if (typeof object.volumeDevices[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.Container.volumeDevices: object expected");
                                    message.volumeDevices[i] = $root.k8s.io.api.core.v1.VolumeDevice.fromObject(object.volumeDevices[i]);
                                }
                            }
                            if (object.livenessProbe != null) {
                                if (typeof object.livenessProbe !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Container.livenessProbe: object expected");
                                message.livenessProbe = $root.k8s.io.api.core.v1.Probe.fromObject(object.livenessProbe);
                            }
                            if (object.readinessProbe != null) {
                                if (typeof object.readinessProbe !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Container.readinessProbe: object expected");
                                message.readinessProbe = $root.k8s.io.api.core.v1.Probe.fromObject(object.readinessProbe);
                            }
                            if (object.startupProbe != null) {
                                if (typeof object.startupProbe !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Container.startupProbe: object expected");
                                message.startupProbe = $root.k8s.io.api.core.v1.Probe.fromObject(object.startupProbe);
                            }
                            if (object.lifecycle != null) {
                                if (typeof object.lifecycle !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Container.lifecycle: object expected");
                                message.lifecycle = $root.k8s.io.api.core.v1.Lifecycle.fromObject(object.lifecycle);
                            }
                            if (object.terminationMessagePath != null)
                                message.terminationMessagePath = String(object.terminationMessagePath);
                            if (object.terminationMessagePolicy != null)
                                message.terminationMessagePolicy = String(object.terminationMessagePolicy);
                            if (object.imagePullPolicy != null)
                                message.imagePullPolicy = String(object.imagePullPolicy);
                            if (object.securityContext != null) {
                                if (typeof object.securityContext !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Container.securityContext: object expected");
                                message.securityContext = $root.k8s.io.api.core.v1.SecurityContext.fromObject(object.securityContext);
                            }
                            if (object.stdin != null)
                                message.stdin = Boolean(object.stdin);
                            if (object.stdinOnce != null)
                                message.stdinOnce = Boolean(object.stdinOnce);
                            if (object.tty != null)
                                message.tty = Boolean(object.tty);
                            return message;
                        };

                        /**
                         * Creates a plain object from a Container message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Container
                         * @static
                         * @param {k8s.io.api.core.v1.Container} message Container
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Container.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.command = [];
                                object.args = [];
                                object.ports = [];
                                object.env = [];
                                object.volumeMounts = [];
                                object.envFrom = [];
                                object.volumeDevices = [];
                            }
                            if (options.defaults) {
                                object.name = "";
                                object.image = "";
                                object.workingDir = "";
                                object.resources = null;
                                object.livenessProbe = null;
                                object.readinessProbe = null;
                                object.lifecycle = null;
                                object.terminationMessagePath = "";
                                object.imagePullPolicy = "";
                                object.securityContext = null;
                                object.stdin = false;
                                object.stdinOnce = false;
                                object.tty = false;
                                object.terminationMessagePolicy = "";
                                object.startupProbe = null;
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.image != null && message.hasOwnProperty("image"))
                                object.image = message.image;
                            if (message.command && message.command.length) {
                                object.command = [];
                                for (var j = 0; j < message.command.length; ++j)
                                    object.command[j] = message.command[j];
                            }
                            if (message.args && message.args.length) {
                                object.args = [];
                                for (var j = 0; j < message.args.length; ++j)
                                    object.args[j] = message.args[j];
                            }
                            if (message.workingDir != null && message.hasOwnProperty("workingDir"))
                                object.workingDir = message.workingDir;
                            if (message.ports && message.ports.length) {
                                object.ports = [];
                                for (var j = 0; j < message.ports.length; ++j)
                                    object.ports[j] = $root.k8s.io.api.core.v1.ContainerPort.toObject(message.ports[j], options);
                            }
                            if (message.env && message.env.length) {
                                object.env = [];
                                for (var j = 0; j < message.env.length; ++j)
                                    object.env[j] = $root.k8s.io.api.core.v1.EnvVar.toObject(message.env[j], options);
                            }
                            if (message.resources != null && message.hasOwnProperty("resources"))
                                object.resources = $root.k8s.io.api.core.v1.ResourceRequirements.toObject(message.resources, options);
                            if (message.volumeMounts && message.volumeMounts.length) {
                                object.volumeMounts = [];
                                for (var j = 0; j < message.volumeMounts.length; ++j)
                                    object.volumeMounts[j] = $root.k8s.io.api.core.v1.VolumeMount.toObject(message.volumeMounts[j], options);
                            }
                            if (message.livenessProbe != null && message.hasOwnProperty("livenessProbe"))
                                object.livenessProbe = $root.k8s.io.api.core.v1.Probe.toObject(message.livenessProbe, options);
                            if (message.readinessProbe != null && message.hasOwnProperty("readinessProbe"))
                                object.readinessProbe = $root.k8s.io.api.core.v1.Probe.toObject(message.readinessProbe, options);
                            if (message.lifecycle != null && message.hasOwnProperty("lifecycle"))
                                object.lifecycle = $root.k8s.io.api.core.v1.Lifecycle.toObject(message.lifecycle, options);
                            if (message.terminationMessagePath != null && message.hasOwnProperty("terminationMessagePath"))
                                object.terminationMessagePath = message.terminationMessagePath;
                            if (message.imagePullPolicy != null && message.hasOwnProperty("imagePullPolicy"))
                                object.imagePullPolicy = message.imagePullPolicy;
                            if (message.securityContext != null && message.hasOwnProperty("securityContext"))
                                object.securityContext = $root.k8s.io.api.core.v1.SecurityContext.toObject(message.securityContext, options);
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                object.stdin = message.stdin;
                            if (message.stdinOnce != null && message.hasOwnProperty("stdinOnce"))
                                object.stdinOnce = message.stdinOnce;
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                object.tty = message.tty;
                            if (message.envFrom && message.envFrom.length) {
                                object.envFrom = [];
                                for (var j = 0; j < message.envFrom.length; ++j)
                                    object.envFrom[j] = $root.k8s.io.api.core.v1.EnvFromSource.toObject(message.envFrom[j], options);
                            }
                            if (message.terminationMessagePolicy != null && message.hasOwnProperty("terminationMessagePolicy"))
                                object.terminationMessagePolicy = message.terminationMessagePolicy;
                            if (message.volumeDevices && message.volumeDevices.length) {
                                object.volumeDevices = [];
                                for (var j = 0; j < message.volumeDevices.length; ++j)
                                    object.volumeDevices[j] = $root.k8s.io.api.core.v1.VolumeDevice.toObject(message.volumeDevices[j], options);
                            }
                            if (message.startupProbe != null && message.hasOwnProperty("startupProbe"))
                                object.startupProbe = $root.k8s.io.api.core.v1.Probe.toObject(message.startupProbe, options);
                            return object;
                        };

                        /**
                         * Converts this Container to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Container
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Container.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Container;
                    })();

                    v1.ContainerImage = (function() {

                        /**
                         * Properties of a ContainerImage.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerImage
                         * @property {Array.<string>|null} [names] ContainerImage names
                         * @property {number|Long|null} [sizeBytes] ContainerImage sizeBytes
                         */

                        /**
                         * Constructs a new ContainerImage.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerImage.
                         * @implements IContainerImage
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerImage=} [properties] Properties to set
                         */
                        function ContainerImage(properties) {
                            this.names = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerImage names.
                         * @member {Array.<string>} names
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @instance
                         */
                        ContainerImage.prototype.names = $util.emptyArray;

                        /**
                         * ContainerImage sizeBytes.
                         * @member {number|Long} sizeBytes
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @instance
                         */
                        ContainerImage.prototype.sizeBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new ContainerImage instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerImage=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerImage} ContainerImage instance
                         */
                        ContainerImage.create = function create(properties) {
                            return new ContainerImage(properties);
                        };

                        /**
                         * Encodes the specified ContainerImage message. Does not implicitly {@link k8s.io.api.core.v1.ContainerImage.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerImage} message ContainerImage message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerImage.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.names != null && message.names.length)
                                for (var i = 0; i < message.names.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.names[i]);
                            if (message.sizeBytes != null && Object.hasOwnProperty.call(message, "sizeBytes"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sizeBytes);
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerImage message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerImage.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerImage} message ContainerImage message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerImage.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerImage message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerImage} ContainerImage
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerImage.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerImage();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.names && message.names.length))
                                        message.names = [];
                                    message.names.push(reader.string());
                                    break;
                                case 2:
                                    message.sizeBytes = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerImage message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerImage} ContainerImage
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerImage.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerImage message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerImage.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.names != null && message.hasOwnProperty("names")) {
                                if (!Array.isArray(message.names))
                                    return "names: array expected";
                                for (var i = 0; i < message.names.length; ++i)
                                    if (!$util.isString(message.names[i]))
                                        return "names: string[] expected";
                            }
                            if (message.sizeBytes != null && message.hasOwnProperty("sizeBytes"))
                                if (!$util.isInteger(message.sizeBytes) && !(message.sizeBytes && $util.isInteger(message.sizeBytes.low) && $util.isInteger(message.sizeBytes.high)))
                                    return "sizeBytes: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a ContainerImage message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerImage} ContainerImage
                         */
                        ContainerImage.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerImage)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerImage();
                            if (object.names) {
                                if (!Array.isArray(object.names))
                                    throw TypeError(".k8s.io.api.core.v1.ContainerImage.names: array expected");
                                message.names = [];
                                for (var i = 0; i < object.names.length; ++i)
                                    message.names[i] = String(object.names[i]);
                            }
                            if (object.sizeBytes != null)
                                if ($util.Long)
                                    (message.sizeBytes = $util.Long.fromValue(object.sizeBytes)).unsigned = false;
                                else if (typeof object.sizeBytes === "string")
                                    message.sizeBytes = parseInt(object.sizeBytes, 10);
                                else if (typeof object.sizeBytes === "number")
                                    message.sizeBytes = object.sizeBytes;
                                else if (typeof object.sizeBytes === "object")
                                    message.sizeBytes = new $util.LongBits(object.sizeBytes.low >>> 0, object.sizeBytes.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerImage message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerImage} message ContainerImage
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerImage.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.names = [];
                            if (options.defaults)
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.sizeBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.sizeBytes = options.longs === String ? "0" : 0;
                            if (message.names && message.names.length) {
                                object.names = [];
                                for (var j = 0; j < message.names.length; ++j)
                                    object.names[j] = message.names[j];
                            }
                            if (message.sizeBytes != null && message.hasOwnProperty("sizeBytes"))
                                if (typeof message.sizeBytes === "number")
                                    object.sizeBytes = options.longs === String ? String(message.sizeBytes) : message.sizeBytes;
                                else
                                    object.sizeBytes = options.longs === String ? $util.Long.prototype.toString.call(message.sizeBytes) : options.longs === Number ? new $util.LongBits(message.sizeBytes.low >>> 0, message.sizeBytes.high >>> 0).toNumber() : message.sizeBytes;
                            return object;
                        };

                        /**
                         * Converts this ContainerImage to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerImage
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerImage.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerImage;
                    })();

                    v1.ContainerPort = (function() {

                        /**
                         * Properties of a ContainerPort.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerPort
                         * @property {string|null} [name] ContainerPort name
                         * @property {number|null} [hostPort] ContainerPort hostPort
                         * @property {number|null} [containerPort] ContainerPort containerPort
                         * @property {string|null} [protocol] ContainerPort protocol
                         * @property {string|null} [hostIP] ContainerPort hostIP
                         */

                        /**
                         * Constructs a new ContainerPort.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerPort.
                         * @implements IContainerPort
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerPort=} [properties] Properties to set
                         */
                        function ContainerPort(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerPort name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @instance
                         */
                        ContainerPort.prototype.name = "";

                        /**
                         * ContainerPort hostPort.
                         * @member {number} hostPort
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @instance
                         */
                        ContainerPort.prototype.hostPort = 0;

                        /**
                         * ContainerPort containerPort.
                         * @member {number} containerPort
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @instance
                         */
                        ContainerPort.prototype.containerPort = 0;

                        /**
                         * ContainerPort protocol.
                         * @member {string} protocol
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @instance
                         */
                        ContainerPort.prototype.protocol = "";

                        /**
                         * ContainerPort hostIP.
                         * @member {string} hostIP
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @instance
                         */
                        ContainerPort.prototype.hostIP = "";

                        /**
                         * Creates a new ContainerPort instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerPort=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerPort} ContainerPort instance
                         */
                        ContainerPort.create = function create(properties) {
                            return new ContainerPort(properties);
                        };

                        /**
                         * Encodes the specified ContainerPort message. Does not implicitly {@link k8s.io.api.core.v1.ContainerPort.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerPort} message ContainerPort message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerPort.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.hostPort != null && Object.hasOwnProperty.call(message, "hostPort"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.hostPort);
                            if (message.containerPort != null && Object.hasOwnProperty.call(message, "containerPort"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.containerPort);
                            if (message.protocol != null && Object.hasOwnProperty.call(message, "protocol"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.protocol);
                            if (message.hostIP != null && Object.hasOwnProperty.call(message, "hostIP"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.hostIP);
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerPort message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerPort.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerPort} message ContainerPort message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerPort.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerPort message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerPort} ContainerPort
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerPort.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerPort();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.hostPort = reader.int32();
                                    break;
                                case 3:
                                    message.containerPort = reader.int32();
                                    break;
                                case 4:
                                    message.protocol = reader.string();
                                    break;
                                case 5:
                                    message.hostIP = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerPort message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerPort} ContainerPort
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerPort.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerPort message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerPort.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.hostPort != null && message.hasOwnProperty("hostPort"))
                                if (!$util.isInteger(message.hostPort))
                                    return "hostPort: integer expected";
                            if (message.containerPort != null && message.hasOwnProperty("containerPort"))
                                if (!$util.isInteger(message.containerPort))
                                    return "containerPort: integer expected";
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                if (!$util.isString(message.protocol))
                                    return "protocol: string expected";
                            if (message.hostIP != null && message.hasOwnProperty("hostIP"))
                                if (!$util.isString(message.hostIP))
                                    return "hostIP: string expected";
                            return null;
                        };

                        /**
                         * Creates a ContainerPort message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerPort} ContainerPort
                         */
                        ContainerPort.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerPort)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerPort();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.hostPort != null)
                                message.hostPort = object.hostPort | 0;
                            if (object.containerPort != null)
                                message.containerPort = object.containerPort | 0;
                            if (object.protocol != null)
                                message.protocol = String(object.protocol);
                            if (object.hostIP != null)
                                message.hostIP = String(object.hostIP);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerPort message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerPort} message ContainerPort
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerPort.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.hostPort = 0;
                                object.containerPort = 0;
                                object.protocol = "";
                                object.hostIP = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.hostPort != null && message.hasOwnProperty("hostPort"))
                                object.hostPort = message.hostPort;
                            if (message.containerPort != null && message.hasOwnProperty("containerPort"))
                                object.containerPort = message.containerPort;
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                object.protocol = message.protocol;
                            if (message.hostIP != null && message.hasOwnProperty("hostIP"))
                                object.hostIP = message.hostIP;
                            return object;
                        };

                        /**
                         * Converts this ContainerPort to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerPort
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerPort.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerPort;
                    })();

                    v1.ContainerState = (function() {

                        /**
                         * Properties of a ContainerState.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerState
                         * @property {k8s.io.api.core.v1.IContainerStateWaiting|null} [waiting] ContainerState waiting
                         * @property {k8s.io.api.core.v1.IContainerStateRunning|null} [running] ContainerState running
                         * @property {k8s.io.api.core.v1.IContainerStateTerminated|null} [terminated] ContainerState terminated
                         */

                        /**
                         * Constructs a new ContainerState.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerState.
                         * @implements IContainerState
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerState=} [properties] Properties to set
                         */
                        function ContainerState(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerState waiting.
                         * @member {k8s.io.api.core.v1.IContainerStateWaiting|null|undefined} waiting
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @instance
                         */
                        ContainerState.prototype.waiting = null;

                        /**
                         * ContainerState running.
                         * @member {k8s.io.api.core.v1.IContainerStateRunning|null|undefined} running
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @instance
                         */
                        ContainerState.prototype.running = null;

                        /**
                         * ContainerState terminated.
                         * @member {k8s.io.api.core.v1.IContainerStateTerminated|null|undefined} terminated
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @instance
                         */
                        ContainerState.prototype.terminated = null;

                        /**
                         * Creates a new ContainerState instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerState=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerState} ContainerState instance
                         */
                        ContainerState.create = function create(properties) {
                            return new ContainerState(properties);
                        };

                        /**
                         * Encodes the specified ContainerState message. Does not implicitly {@link k8s.io.api.core.v1.ContainerState.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerState} message ContainerState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerState.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.waiting != null && Object.hasOwnProperty.call(message, "waiting"))
                                $root.k8s.io.api.core.v1.ContainerStateWaiting.encode(message.waiting, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.running != null && Object.hasOwnProperty.call(message, "running"))
                                $root.k8s.io.api.core.v1.ContainerStateRunning.encode(message.running, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.terminated != null && Object.hasOwnProperty.call(message, "terminated"))
                                $root.k8s.io.api.core.v1.ContainerStateTerminated.encode(message.terminated, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerState message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerState.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerState} message ContainerState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerState.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerState message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerState} ContainerState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerState.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerState();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.waiting = $root.k8s.io.api.core.v1.ContainerStateWaiting.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.running = $root.k8s.io.api.core.v1.ContainerStateRunning.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.terminated = $root.k8s.io.api.core.v1.ContainerStateTerminated.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerState message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerState} ContainerState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerState.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerState message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerState.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.waiting != null && message.hasOwnProperty("waiting")) {
                                var error = $root.k8s.io.api.core.v1.ContainerStateWaiting.verify(message.waiting);
                                if (error)
                                    return "waiting." + error;
                            }
                            if (message.running != null && message.hasOwnProperty("running")) {
                                var error = $root.k8s.io.api.core.v1.ContainerStateRunning.verify(message.running);
                                if (error)
                                    return "running." + error;
                            }
                            if (message.terminated != null && message.hasOwnProperty("terminated")) {
                                var error = $root.k8s.io.api.core.v1.ContainerStateTerminated.verify(message.terminated);
                                if (error)
                                    return "terminated." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ContainerState message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerState} ContainerState
                         */
                        ContainerState.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerState)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerState();
                            if (object.waiting != null) {
                                if (typeof object.waiting !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerState.waiting: object expected");
                                message.waiting = $root.k8s.io.api.core.v1.ContainerStateWaiting.fromObject(object.waiting);
                            }
                            if (object.running != null) {
                                if (typeof object.running !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerState.running: object expected");
                                message.running = $root.k8s.io.api.core.v1.ContainerStateRunning.fromObject(object.running);
                            }
                            if (object.terminated != null) {
                                if (typeof object.terminated !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerState.terminated: object expected");
                                message.terminated = $root.k8s.io.api.core.v1.ContainerStateTerminated.fromObject(object.terminated);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerState message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerState} message ContainerState
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerState.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.waiting = null;
                                object.running = null;
                                object.terminated = null;
                            }
                            if (message.waiting != null && message.hasOwnProperty("waiting"))
                                object.waiting = $root.k8s.io.api.core.v1.ContainerStateWaiting.toObject(message.waiting, options);
                            if (message.running != null && message.hasOwnProperty("running"))
                                object.running = $root.k8s.io.api.core.v1.ContainerStateRunning.toObject(message.running, options);
                            if (message.terminated != null && message.hasOwnProperty("terminated"))
                                object.terminated = $root.k8s.io.api.core.v1.ContainerStateTerminated.toObject(message.terminated, options);
                            return object;
                        };

                        /**
                         * Converts this ContainerState to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerState
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerState.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerState;
                    })();

                    v1.ContainerStateRunning = (function() {

                        /**
                         * Properties of a ContainerStateRunning.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerStateRunning
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [startedAt] ContainerStateRunning startedAt
                         */

                        /**
                         * Constructs a new ContainerStateRunning.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerStateRunning.
                         * @implements IContainerStateRunning
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerStateRunning=} [properties] Properties to set
                         */
                        function ContainerStateRunning(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerStateRunning startedAt.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} startedAt
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @instance
                         */
                        ContainerStateRunning.prototype.startedAt = null;

                        /**
                         * Creates a new ContainerStateRunning instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateRunning=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerStateRunning} ContainerStateRunning instance
                         */
                        ContainerStateRunning.create = function create(properties) {
                            return new ContainerStateRunning(properties);
                        };

                        /**
                         * Encodes the specified ContainerStateRunning message. Does not implicitly {@link k8s.io.api.core.v1.ContainerStateRunning.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateRunning} message ContainerStateRunning message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStateRunning.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.startedAt != null && Object.hasOwnProperty.call(message, "startedAt"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.startedAt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerStateRunning message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerStateRunning.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateRunning} message ContainerStateRunning message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStateRunning.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerStateRunning message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerStateRunning} ContainerStateRunning
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStateRunning.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerStateRunning();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.startedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerStateRunning message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerStateRunning} ContainerStateRunning
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStateRunning.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerStateRunning message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerStateRunning.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ContainerStateRunning message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerStateRunning} ContainerStateRunning
                         */
                        ContainerStateRunning.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerStateRunning)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerStateRunning();
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerStateRunning.startedAt: object expected");
                                message.startedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.startedAt);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerStateRunning message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerStateRunning} message ContainerStateRunning
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerStateRunning.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.startedAt = null;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.startedAt, options);
                            return object;
                        };

                        /**
                         * Converts this ContainerStateRunning to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerStateRunning
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerStateRunning.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerStateRunning;
                    })();

                    v1.ContainerStateTerminated = (function() {

                        /**
                         * Properties of a ContainerStateTerminated.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerStateTerminated
                         * @property {number|null} [exitCode] ContainerStateTerminated exitCode
                         * @property {number|null} [signal] ContainerStateTerminated signal
                         * @property {string|null} [reason] ContainerStateTerminated reason
                         * @property {string|null} [message] ContainerStateTerminated message
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [startedAt] ContainerStateTerminated startedAt
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [finishedAt] ContainerStateTerminated finishedAt
                         * @property {string|null} [containerID] ContainerStateTerminated containerID
                         */

                        /**
                         * Constructs a new ContainerStateTerminated.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerStateTerminated.
                         * @implements IContainerStateTerminated
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerStateTerminated=} [properties] Properties to set
                         */
                        function ContainerStateTerminated(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerStateTerminated exitCode.
                         * @member {number} exitCode
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.exitCode = 0;

                        /**
                         * ContainerStateTerminated signal.
                         * @member {number} signal
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.signal = 0;

                        /**
                         * ContainerStateTerminated reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.reason = "";

                        /**
                         * ContainerStateTerminated message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.message = "";

                        /**
                         * ContainerStateTerminated startedAt.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} startedAt
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.startedAt = null;

                        /**
                         * ContainerStateTerminated finishedAt.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} finishedAt
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.finishedAt = null;

                        /**
                         * ContainerStateTerminated containerID.
                         * @member {string} containerID
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         */
                        ContainerStateTerminated.prototype.containerID = "";

                        /**
                         * Creates a new ContainerStateTerminated instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateTerminated=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerStateTerminated} ContainerStateTerminated instance
                         */
                        ContainerStateTerminated.create = function create(properties) {
                            return new ContainerStateTerminated(properties);
                        };

                        /**
                         * Encodes the specified ContainerStateTerminated message. Does not implicitly {@link k8s.io.api.core.v1.ContainerStateTerminated.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateTerminated} message ContainerStateTerminated message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStateTerminated.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.exitCode != null && Object.hasOwnProperty.call(message, "exitCode"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.exitCode);
                            if (message.signal != null && Object.hasOwnProperty.call(message, "signal"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.signal);
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
                            if (message.startedAt != null && Object.hasOwnProperty.call(message, "startedAt"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.startedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.finishedAt != null && Object.hasOwnProperty.call(message, "finishedAt"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.finishedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.containerID != null && Object.hasOwnProperty.call(message, "containerID"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.containerID);
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerStateTerminated message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerStateTerminated.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateTerminated} message ContainerStateTerminated message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStateTerminated.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerStateTerminated message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerStateTerminated} ContainerStateTerminated
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStateTerminated.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerStateTerminated();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.exitCode = reader.int32();
                                    break;
                                case 2:
                                    message.signal = reader.int32();
                                    break;
                                case 3:
                                    message.reason = reader.string();
                                    break;
                                case 4:
                                    message.message = reader.string();
                                    break;
                                case 5:
                                    message.startedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.finishedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.containerID = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerStateTerminated message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerStateTerminated} ContainerStateTerminated
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStateTerminated.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerStateTerminated message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerStateTerminated.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                                if (!$util.isInteger(message.exitCode))
                                    return "exitCode: integer expected";
                            if (message.signal != null && message.hasOwnProperty("signal"))
                                if (!$util.isInteger(message.signal))
                                    return "signal: integer expected";
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            if (message.startedAt != null && message.hasOwnProperty("startedAt")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.startedAt);
                                if (error)
                                    return "startedAt." + error;
                            }
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.finishedAt);
                                if (error)
                                    return "finishedAt." + error;
                            }
                            if (message.containerID != null && message.hasOwnProperty("containerID"))
                                if (!$util.isString(message.containerID))
                                    return "containerID: string expected";
                            return null;
                        };

                        /**
                         * Creates a ContainerStateTerminated message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerStateTerminated} ContainerStateTerminated
                         */
                        ContainerStateTerminated.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerStateTerminated)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerStateTerminated();
                            if (object.exitCode != null)
                                message.exitCode = object.exitCode | 0;
                            if (object.signal != null)
                                message.signal = object.signal | 0;
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            if (object.startedAt != null) {
                                if (typeof object.startedAt !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerStateTerminated.startedAt: object expected");
                                message.startedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.startedAt);
                            }
                            if (object.finishedAt != null) {
                                if (typeof object.finishedAt !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerStateTerminated.finishedAt: object expected");
                                message.finishedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.finishedAt);
                            }
                            if (object.containerID != null)
                                message.containerID = String(object.containerID);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerStateTerminated message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerStateTerminated} message ContainerStateTerminated
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerStateTerminated.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.exitCode = 0;
                                object.signal = 0;
                                object.reason = "";
                                object.message = "";
                                object.startedAt = null;
                                object.finishedAt = null;
                                object.containerID = "";
                            }
                            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                                object.exitCode = message.exitCode;
                            if (message.signal != null && message.hasOwnProperty("signal"))
                                object.signal = message.signal;
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            if (message.startedAt != null && message.hasOwnProperty("startedAt"))
                                object.startedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.startedAt, options);
                            if (message.finishedAt != null && message.hasOwnProperty("finishedAt"))
                                object.finishedAt = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.finishedAt, options);
                            if (message.containerID != null && message.hasOwnProperty("containerID"))
                                object.containerID = message.containerID;
                            return object;
                        };

                        /**
                         * Converts this ContainerStateTerminated to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerStateTerminated
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerStateTerminated.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerStateTerminated;
                    })();

                    v1.ContainerStateWaiting = (function() {

                        /**
                         * Properties of a ContainerStateWaiting.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerStateWaiting
                         * @property {string|null} [reason] ContainerStateWaiting reason
                         * @property {string|null} [message] ContainerStateWaiting message
                         */

                        /**
                         * Constructs a new ContainerStateWaiting.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerStateWaiting.
                         * @implements IContainerStateWaiting
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerStateWaiting=} [properties] Properties to set
                         */
                        function ContainerStateWaiting(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerStateWaiting reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @instance
                         */
                        ContainerStateWaiting.prototype.reason = "";

                        /**
                         * ContainerStateWaiting message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @instance
                         */
                        ContainerStateWaiting.prototype.message = "";

                        /**
                         * Creates a new ContainerStateWaiting instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateWaiting=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerStateWaiting} ContainerStateWaiting instance
                         */
                        ContainerStateWaiting.create = function create(properties) {
                            return new ContainerStateWaiting(properties);
                        };

                        /**
                         * Encodes the specified ContainerStateWaiting message. Does not implicitly {@link k8s.io.api.core.v1.ContainerStateWaiting.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateWaiting} message ContainerStateWaiting message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStateWaiting.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerStateWaiting message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerStateWaiting.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStateWaiting} message ContainerStateWaiting message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStateWaiting.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerStateWaiting message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerStateWaiting} ContainerStateWaiting
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStateWaiting.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerStateWaiting();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.reason = reader.string();
                                    break;
                                case 2:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerStateWaiting message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerStateWaiting} ContainerStateWaiting
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStateWaiting.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerStateWaiting message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerStateWaiting.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a ContainerStateWaiting message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerStateWaiting} ContainerStateWaiting
                         */
                        ContainerStateWaiting.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerStateWaiting)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerStateWaiting();
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerStateWaiting message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerStateWaiting} message ContainerStateWaiting
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerStateWaiting.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this ContainerStateWaiting to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerStateWaiting
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerStateWaiting.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerStateWaiting;
                    })();

                    v1.ContainerStatus = (function() {

                        /**
                         * Properties of a ContainerStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IContainerStatus
                         * @property {string|null} [name] ContainerStatus name
                         * @property {k8s.io.api.core.v1.IContainerState|null} [state] ContainerStatus state
                         * @property {k8s.io.api.core.v1.IContainerState|null} [lastState] ContainerStatus lastState
                         * @property {boolean|null} [ready] ContainerStatus ready
                         * @property {number|null} [restartCount] ContainerStatus restartCount
                         * @property {string|null} [image] ContainerStatus image
                         * @property {string|null} [imageID] ContainerStatus imageID
                         * @property {string|null} [containerID] ContainerStatus containerID
                         * @property {boolean|null} [started] ContainerStatus started
                         */

                        /**
                         * Constructs a new ContainerStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ContainerStatus.
                         * @implements IContainerStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IContainerStatus=} [properties] Properties to set
                         */
                        function ContainerStatus(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ContainerStatus name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.name = "";

                        /**
                         * ContainerStatus state.
                         * @member {k8s.io.api.core.v1.IContainerState|null|undefined} state
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.state = null;

                        /**
                         * ContainerStatus lastState.
                         * @member {k8s.io.api.core.v1.IContainerState|null|undefined} lastState
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.lastState = null;

                        /**
                         * ContainerStatus ready.
                         * @member {boolean} ready
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.ready = false;

                        /**
                         * ContainerStatus restartCount.
                         * @member {number} restartCount
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.restartCount = 0;

                        /**
                         * ContainerStatus image.
                         * @member {string} image
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.image = "";

                        /**
                         * ContainerStatus imageID.
                         * @member {string} imageID
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.imageID = "";

                        /**
                         * ContainerStatus containerID.
                         * @member {string} containerID
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.containerID = "";

                        /**
                         * ContainerStatus started.
                         * @member {boolean} started
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         */
                        ContainerStatus.prototype.started = false;

                        /**
                         * Creates a new ContainerStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ContainerStatus} ContainerStatus instance
                         */
                        ContainerStatus.create = function create(properties) {
                            return new ContainerStatus(properties);
                        };

                        /**
                         * Encodes the specified ContainerStatus message. Does not implicitly {@link k8s.io.api.core.v1.ContainerStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStatus} message ContainerStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                                $root.k8s.io.api.core.v1.ContainerState.encode(message.state, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.lastState != null && Object.hasOwnProperty.call(message, "lastState"))
                                $root.k8s.io.api.core.v1.ContainerState.encode(message.lastState, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.ready != null && Object.hasOwnProperty.call(message, "ready"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.ready);
                            if (message.restartCount != null && Object.hasOwnProperty.call(message, "restartCount"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.restartCount);
                            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.image);
                            if (message.imageID != null && Object.hasOwnProperty.call(message, "imageID"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.imageID);
                            if (message.containerID != null && Object.hasOwnProperty.call(message, "containerID"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.containerID);
                            if (message.started != null && Object.hasOwnProperty.call(message, "started"))
                                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.started);
                            return writer;
                        };

                        /**
                         * Encodes the specified ContainerStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ContainerStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IContainerStatus} message ContainerStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ContainerStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ContainerStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ContainerStatus} ContainerStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ContainerStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.state = $root.k8s.io.api.core.v1.ContainerState.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.lastState = $root.k8s.io.api.core.v1.ContainerState.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.ready = reader.bool();
                                    break;
                                case 5:
                                    message.restartCount = reader.int32();
                                    break;
                                case 6:
                                    message.image = reader.string();
                                    break;
                                case 7:
                                    message.imageID = reader.string();
                                    break;
                                case 8:
                                    message.containerID = reader.string();
                                    break;
                                case 9:
                                    message.started = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ContainerStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ContainerStatus} ContainerStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ContainerStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ContainerStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ContainerStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.state != null && message.hasOwnProperty("state")) {
                                var error = $root.k8s.io.api.core.v1.ContainerState.verify(message.state);
                                if (error)
                                    return "state." + error;
                            }
                            if (message.lastState != null && message.hasOwnProperty("lastState")) {
                                var error = $root.k8s.io.api.core.v1.ContainerState.verify(message.lastState);
                                if (error)
                                    return "lastState." + error;
                            }
                            if (message.ready != null && message.hasOwnProperty("ready"))
                                if (typeof message.ready !== "boolean")
                                    return "ready: boolean expected";
                            if (message.restartCount != null && message.hasOwnProperty("restartCount"))
                                if (!$util.isInteger(message.restartCount))
                                    return "restartCount: integer expected";
                            if (message.image != null && message.hasOwnProperty("image"))
                                if (!$util.isString(message.image))
                                    return "image: string expected";
                            if (message.imageID != null && message.hasOwnProperty("imageID"))
                                if (!$util.isString(message.imageID))
                                    return "imageID: string expected";
                            if (message.containerID != null && message.hasOwnProperty("containerID"))
                                if (!$util.isString(message.containerID))
                                    return "containerID: string expected";
                            if (message.started != null && message.hasOwnProperty("started"))
                                if (typeof message.started !== "boolean")
                                    return "started: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ContainerStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ContainerStatus} ContainerStatus
                         */
                        ContainerStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ContainerStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ContainerStatus();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.state != null) {
                                if (typeof object.state !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerStatus.state: object expected");
                                message.state = $root.k8s.io.api.core.v1.ContainerState.fromObject(object.state);
                            }
                            if (object.lastState != null) {
                                if (typeof object.lastState !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ContainerStatus.lastState: object expected");
                                message.lastState = $root.k8s.io.api.core.v1.ContainerState.fromObject(object.lastState);
                            }
                            if (object.ready != null)
                                message.ready = Boolean(object.ready);
                            if (object.restartCount != null)
                                message.restartCount = object.restartCount | 0;
                            if (object.image != null)
                                message.image = String(object.image);
                            if (object.imageID != null)
                                message.imageID = String(object.imageID);
                            if (object.containerID != null)
                                message.containerID = String(object.containerID);
                            if (object.started != null)
                                message.started = Boolean(object.started);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ContainerStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ContainerStatus} message ContainerStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ContainerStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.state = null;
                                object.lastState = null;
                                object.ready = false;
                                object.restartCount = 0;
                                object.image = "";
                                object.imageID = "";
                                object.containerID = "";
                                object.started = false;
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.state != null && message.hasOwnProperty("state"))
                                object.state = $root.k8s.io.api.core.v1.ContainerState.toObject(message.state, options);
                            if (message.lastState != null && message.hasOwnProperty("lastState"))
                                object.lastState = $root.k8s.io.api.core.v1.ContainerState.toObject(message.lastState, options);
                            if (message.ready != null && message.hasOwnProperty("ready"))
                                object.ready = message.ready;
                            if (message.restartCount != null && message.hasOwnProperty("restartCount"))
                                object.restartCount = message.restartCount;
                            if (message.image != null && message.hasOwnProperty("image"))
                                object.image = message.image;
                            if (message.imageID != null && message.hasOwnProperty("imageID"))
                                object.imageID = message.imageID;
                            if (message.containerID != null && message.hasOwnProperty("containerID"))
                                object.containerID = message.containerID;
                            if (message.started != null && message.hasOwnProperty("started"))
                                object.started = message.started;
                            return object;
                        };

                        /**
                         * Converts this ContainerStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ContainerStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ContainerStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ContainerStatus;
                    })();

                    v1.DaemonEndpoint = (function() {

                        /**
                         * Properties of a DaemonEndpoint.
                         * @memberof k8s.io.api.core.v1
                         * @interface IDaemonEndpoint
                         * @property {number|null} [Port] DaemonEndpoint Port
                         */

                        /**
                         * Constructs a new DaemonEndpoint.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a DaemonEndpoint.
                         * @implements IDaemonEndpoint
                         * @constructor
                         * @param {k8s.io.api.core.v1.IDaemonEndpoint=} [properties] Properties to set
                         */
                        function DaemonEndpoint(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DaemonEndpoint Port.
                         * @member {number} Port
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @instance
                         */
                        DaemonEndpoint.prototype.Port = 0;

                        /**
                         * Creates a new DaemonEndpoint instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {k8s.io.api.core.v1.IDaemonEndpoint=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.DaemonEndpoint} DaemonEndpoint instance
                         */
                        DaemonEndpoint.create = function create(properties) {
                            return new DaemonEndpoint(properties);
                        };

                        /**
                         * Encodes the specified DaemonEndpoint message. Does not implicitly {@link k8s.io.api.core.v1.DaemonEndpoint.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {k8s.io.api.core.v1.IDaemonEndpoint} message DaemonEndpoint message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DaemonEndpoint.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.Port != null && Object.hasOwnProperty.call(message, "Port"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Port);
                            return writer;
                        };

                        /**
                         * Encodes the specified DaemonEndpoint message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.DaemonEndpoint.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {k8s.io.api.core.v1.IDaemonEndpoint} message DaemonEndpoint message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DaemonEndpoint.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DaemonEndpoint message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.DaemonEndpoint} DaemonEndpoint
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DaemonEndpoint.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.DaemonEndpoint();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.Port = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DaemonEndpoint message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.DaemonEndpoint} DaemonEndpoint
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DaemonEndpoint.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DaemonEndpoint message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DaemonEndpoint.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.Port != null && message.hasOwnProperty("Port"))
                                if (!$util.isInteger(message.Port))
                                    return "Port: integer expected";
                            return null;
                        };

                        /**
                         * Creates a DaemonEndpoint message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.DaemonEndpoint} DaemonEndpoint
                         */
                        DaemonEndpoint.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.DaemonEndpoint)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.DaemonEndpoint();
                            if (object.Port != null)
                                message.Port = object.Port | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a DaemonEndpoint message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @static
                         * @param {k8s.io.api.core.v1.DaemonEndpoint} message DaemonEndpoint
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DaemonEndpoint.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.Port = 0;
                            if (message.Port != null && message.hasOwnProperty("Port"))
                                object.Port = message.Port;
                            return object;
                        };

                        /**
                         * Converts this DaemonEndpoint to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.DaemonEndpoint
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DaemonEndpoint.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DaemonEndpoint;
                    })();

                    v1.DownwardAPIProjection = (function() {

                        /**
                         * Properties of a DownwardAPIProjection.
                         * @memberof k8s.io.api.core.v1
                         * @interface IDownwardAPIProjection
                         * @property {Array.<k8s.io.api.core.v1.IDownwardAPIVolumeFile>|null} [items] DownwardAPIProjection items
                         */

                        /**
                         * Constructs a new DownwardAPIProjection.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a DownwardAPIProjection.
                         * @implements IDownwardAPIProjection
                         * @constructor
                         * @param {k8s.io.api.core.v1.IDownwardAPIProjection=} [properties] Properties to set
                         */
                        function DownwardAPIProjection(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DownwardAPIProjection items.
                         * @member {Array.<k8s.io.api.core.v1.IDownwardAPIVolumeFile>} items
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @instance
                         */
                        DownwardAPIProjection.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new DownwardAPIProjection instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIProjection=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.DownwardAPIProjection} DownwardAPIProjection instance
                         */
                        DownwardAPIProjection.create = function create(properties) {
                            return new DownwardAPIProjection(properties);
                        };

                        /**
                         * Encodes the specified DownwardAPIProjection message. Does not implicitly {@link k8s.io.api.core.v1.DownwardAPIProjection.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIProjection} message DownwardAPIProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DownwardAPIProjection.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DownwardAPIProjection message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.DownwardAPIProjection.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIProjection} message DownwardAPIProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DownwardAPIProjection.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DownwardAPIProjection message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.DownwardAPIProjection} DownwardAPIProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DownwardAPIProjection.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.DownwardAPIProjection();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.DownwardAPIVolumeFile.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DownwardAPIProjection message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.DownwardAPIProjection} DownwardAPIProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DownwardAPIProjection.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DownwardAPIProjection message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DownwardAPIProjection.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a DownwardAPIProjection message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.DownwardAPIProjection} DownwardAPIProjection
                         */
                        DownwardAPIProjection.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.DownwardAPIProjection)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.DownwardAPIProjection();
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.DownwardAPIProjection.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.DownwardAPIProjection.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DownwardAPIProjection message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @static
                         * @param {k8s.io.api.core.v1.DownwardAPIProjection} message DownwardAPIProjection
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DownwardAPIProjection.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this DownwardAPIProjection to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.DownwardAPIProjection
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DownwardAPIProjection.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DownwardAPIProjection;
                    })();

                    v1.DownwardAPIVolumeFile = (function() {

                        /**
                         * Properties of a DownwardAPIVolumeFile.
                         * @memberof k8s.io.api.core.v1
                         * @interface IDownwardAPIVolumeFile
                         * @property {string|null} [path] DownwardAPIVolumeFile path
                         * @property {k8s.io.api.core.v1.IObjectFieldSelector|null} [fieldRef] DownwardAPIVolumeFile fieldRef
                         * @property {k8s.io.api.core.v1.IResourceFieldSelector|null} [resourceFieldRef] DownwardAPIVolumeFile resourceFieldRef
                         * @property {number|null} [mode] DownwardAPIVolumeFile mode
                         */

                        /**
                         * Constructs a new DownwardAPIVolumeFile.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a DownwardAPIVolumeFile.
                         * @implements IDownwardAPIVolumeFile
                         * @constructor
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeFile=} [properties] Properties to set
                         */
                        function DownwardAPIVolumeFile(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DownwardAPIVolumeFile path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @instance
                         */
                        DownwardAPIVolumeFile.prototype.path = "";

                        /**
                         * DownwardAPIVolumeFile fieldRef.
                         * @member {k8s.io.api.core.v1.IObjectFieldSelector|null|undefined} fieldRef
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @instance
                         */
                        DownwardAPIVolumeFile.prototype.fieldRef = null;

                        /**
                         * DownwardAPIVolumeFile resourceFieldRef.
                         * @member {k8s.io.api.core.v1.IResourceFieldSelector|null|undefined} resourceFieldRef
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @instance
                         */
                        DownwardAPIVolumeFile.prototype.resourceFieldRef = null;

                        /**
                         * DownwardAPIVolumeFile mode.
                         * @member {number} mode
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @instance
                         */
                        DownwardAPIVolumeFile.prototype.mode = 0;

                        /**
                         * Creates a new DownwardAPIVolumeFile instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeFile=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeFile} DownwardAPIVolumeFile instance
                         */
                        DownwardAPIVolumeFile.create = function create(properties) {
                            return new DownwardAPIVolumeFile(properties);
                        };

                        /**
                         * Encodes the specified DownwardAPIVolumeFile message. Does not implicitly {@link k8s.io.api.core.v1.DownwardAPIVolumeFile.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeFile} message DownwardAPIVolumeFile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DownwardAPIVolumeFile.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            if (message.fieldRef != null && Object.hasOwnProperty.call(message, "fieldRef"))
                                $root.k8s.io.api.core.v1.ObjectFieldSelector.encode(message.fieldRef, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.resourceFieldRef != null && Object.hasOwnProperty.call(message, "resourceFieldRef"))
                                $root.k8s.io.api.core.v1.ResourceFieldSelector.encode(message.resourceFieldRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.mode);
                            return writer;
                        };

                        /**
                         * Encodes the specified DownwardAPIVolumeFile message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.DownwardAPIVolumeFile.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeFile} message DownwardAPIVolumeFile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DownwardAPIVolumeFile.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DownwardAPIVolumeFile message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeFile} DownwardAPIVolumeFile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DownwardAPIVolumeFile.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.DownwardAPIVolumeFile();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                case 2:
                                    message.fieldRef = $root.k8s.io.api.core.v1.ObjectFieldSelector.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.resourceFieldRef = $root.k8s.io.api.core.v1.ResourceFieldSelector.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.mode = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DownwardAPIVolumeFile message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeFile} DownwardAPIVolumeFile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DownwardAPIVolumeFile.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DownwardAPIVolumeFile message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DownwardAPIVolumeFile.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.fieldRef != null && message.hasOwnProperty("fieldRef")) {
                                var error = $root.k8s.io.api.core.v1.ObjectFieldSelector.verify(message.fieldRef);
                                if (error)
                                    return "fieldRef." + error;
                            }
                            if (message.resourceFieldRef != null && message.hasOwnProperty("resourceFieldRef")) {
                                var error = $root.k8s.io.api.core.v1.ResourceFieldSelector.verify(message.resourceFieldRef);
                                if (error)
                                    return "resourceFieldRef." + error;
                            }
                            if (message.mode != null && message.hasOwnProperty("mode"))
                                if (!$util.isInteger(message.mode))
                                    return "mode: integer expected";
                            return null;
                        };

                        /**
                         * Creates a DownwardAPIVolumeFile message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeFile} DownwardAPIVolumeFile
                         */
                        DownwardAPIVolumeFile.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.DownwardAPIVolumeFile)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.DownwardAPIVolumeFile();
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.fieldRef != null) {
                                if (typeof object.fieldRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.DownwardAPIVolumeFile.fieldRef: object expected");
                                message.fieldRef = $root.k8s.io.api.core.v1.ObjectFieldSelector.fromObject(object.fieldRef);
                            }
                            if (object.resourceFieldRef != null) {
                                if (typeof object.resourceFieldRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.DownwardAPIVolumeFile.resourceFieldRef: object expected");
                                message.resourceFieldRef = $root.k8s.io.api.core.v1.ResourceFieldSelector.fromObject(object.resourceFieldRef);
                            }
                            if (object.mode != null)
                                message.mode = object.mode | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a DownwardAPIVolumeFile message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @static
                         * @param {k8s.io.api.core.v1.DownwardAPIVolumeFile} message DownwardAPIVolumeFile
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DownwardAPIVolumeFile.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.path = "";
                                object.fieldRef = null;
                                object.resourceFieldRef = null;
                                object.mode = 0;
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.fieldRef != null && message.hasOwnProperty("fieldRef"))
                                object.fieldRef = $root.k8s.io.api.core.v1.ObjectFieldSelector.toObject(message.fieldRef, options);
                            if (message.resourceFieldRef != null && message.hasOwnProperty("resourceFieldRef"))
                                object.resourceFieldRef = $root.k8s.io.api.core.v1.ResourceFieldSelector.toObject(message.resourceFieldRef, options);
                            if (message.mode != null && message.hasOwnProperty("mode"))
                                object.mode = message.mode;
                            return object;
                        };

                        /**
                         * Converts this DownwardAPIVolumeFile to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeFile
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DownwardAPIVolumeFile.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DownwardAPIVolumeFile;
                    })();

                    v1.DownwardAPIVolumeSource = (function() {

                        /**
                         * Properties of a DownwardAPIVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IDownwardAPIVolumeSource
                         * @property {Array.<k8s.io.api.core.v1.IDownwardAPIVolumeFile>|null} [items] DownwardAPIVolumeSource items
                         * @property {number|null} [defaultMode] DownwardAPIVolumeSource defaultMode
                         */

                        /**
                         * Constructs a new DownwardAPIVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a DownwardAPIVolumeSource.
                         * @implements IDownwardAPIVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeSource=} [properties] Properties to set
                         */
                        function DownwardAPIVolumeSource(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DownwardAPIVolumeSource items.
                         * @member {Array.<k8s.io.api.core.v1.IDownwardAPIVolumeFile>} items
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @instance
                         */
                        DownwardAPIVolumeSource.prototype.items = $util.emptyArray;

                        /**
                         * DownwardAPIVolumeSource defaultMode.
                         * @member {number} defaultMode
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @instance
                         */
                        DownwardAPIVolumeSource.prototype.defaultMode = 0;

                        /**
                         * Creates a new DownwardAPIVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeSource} DownwardAPIVolumeSource instance
                         */
                        DownwardAPIVolumeSource.create = function create(properties) {
                            return new DownwardAPIVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified DownwardAPIVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.DownwardAPIVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeSource} message DownwardAPIVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DownwardAPIVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.defaultMode != null && Object.hasOwnProperty.call(message, "defaultMode"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.defaultMode);
                            return writer;
                        };

                        /**
                         * Encodes the specified DownwardAPIVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.DownwardAPIVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IDownwardAPIVolumeSource} message DownwardAPIVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DownwardAPIVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DownwardAPIVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeSource} DownwardAPIVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DownwardAPIVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.DownwardAPIVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.DownwardAPIVolumeFile.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    message.defaultMode = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DownwardAPIVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeSource} DownwardAPIVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DownwardAPIVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DownwardAPIVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DownwardAPIVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                if (!$util.isInteger(message.defaultMode))
                                    return "defaultMode: integer expected";
                            return null;
                        };

                        /**
                         * Creates a DownwardAPIVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.DownwardAPIVolumeSource} DownwardAPIVolumeSource
                         */
                        DownwardAPIVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.DownwardAPIVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.DownwardAPIVolumeSource();
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.DownwardAPIVolumeSource.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.DownwardAPIVolumeSource.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.fromObject(object.items[i]);
                                }
                            }
                            if (object.defaultMode != null)
                                message.defaultMode = object.defaultMode | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a DownwardAPIVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.DownwardAPIVolumeSource} message DownwardAPIVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DownwardAPIVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.defaultMode = 0;
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.DownwardAPIVolumeFile.toObject(message.items[j], options);
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                object.defaultMode = message.defaultMode;
                            return object;
                        };

                        /**
                         * Converts this DownwardAPIVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.DownwardAPIVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DownwardAPIVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DownwardAPIVolumeSource;
                    })();

                    v1.EmptyDirVolumeSource = (function() {

                        /**
                         * Properties of an EmptyDirVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEmptyDirVolumeSource
                         * @property {string|null} [medium] EmptyDirVolumeSource medium
                         * @property {k8s.io.apimachinery.pkg.api.resource.IQuantity|null} [sizeLimit] EmptyDirVolumeSource sizeLimit
                         */

                        /**
                         * Constructs a new EmptyDirVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EmptyDirVolumeSource.
                         * @implements IEmptyDirVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEmptyDirVolumeSource=} [properties] Properties to set
                         */
                        function EmptyDirVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EmptyDirVolumeSource medium.
                         * @member {string} medium
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @instance
                         */
                        EmptyDirVolumeSource.prototype.medium = "";

                        /**
                         * EmptyDirVolumeSource sizeLimit.
                         * @member {k8s.io.apimachinery.pkg.api.resource.IQuantity|null|undefined} sizeLimit
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @instance
                         */
                        EmptyDirVolumeSource.prototype.sizeLimit = null;

                        /**
                         * Creates a new EmptyDirVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEmptyDirVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EmptyDirVolumeSource} EmptyDirVolumeSource instance
                         */
                        EmptyDirVolumeSource.create = function create(properties) {
                            return new EmptyDirVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified EmptyDirVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.EmptyDirVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEmptyDirVolumeSource} message EmptyDirVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EmptyDirVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.medium != null && Object.hasOwnProperty.call(message, "medium"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.medium);
                            if (message.sizeLimit != null && Object.hasOwnProperty.call(message, "sizeLimit"))
                                $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.sizeLimit, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EmptyDirVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EmptyDirVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEmptyDirVolumeSource} message EmptyDirVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EmptyDirVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EmptyDirVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EmptyDirVolumeSource} EmptyDirVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EmptyDirVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EmptyDirVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.medium = reader.string();
                                    break;
                                case 2:
                                    message.sizeLimit = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EmptyDirVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EmptyDirVolumeSource} EmptyDirVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EmptyDirVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EmptyDirVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EmptyDirVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.medium != null && message.hasOwnProperty("medium"))
                                if (!$util.isString(message.medium))
                                    return "medium: string expected";
                            if (message.sizeLimit != null && message.hasOwnProperty("sizeLimit")) {
                                var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.sizeLimit);
                                if (error)
                                    return "sizeLimit." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an EmptyDirVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EmptyDirVolumeSource} EmptyDirVolumeSource
                         */
                        EmptyDirVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EmptyDirVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EmptyDirVolumeSource();
                            if (object.medium != null)
                                message.medium = String(object.medium);
                            if (object.sizeLimit != null) {
                                if (typeof object.sizeLimit !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EmptyDirVolumeSource.sizeLimit: object expected");
                                message.sizeLimit = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.sizeLimit);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EmptyDirVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.EmptyDirVolumeSource} message EmptyDirVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EmptyDirVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.medium = "";
                                object.sizeLimit = null;
                            }
                            if (message.medium != null && message.hasOwnProperty("medium"))
                                object.medium = message.medium;
                            if (message.sizeLimit != null && message.hasOwnProperty("sizeLimit"))
                                object.sizeLimit = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.sizeLimit, options);
                            return object;
                        };

                        /**
                         * Converts this EmptyDirVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EmptyDirVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EmptyDirVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EmptyDirVolumeSource;
                    })();

                    v1.EndpointAddress = (function() {

                        /**
                         * Properties of an EndpointAddress.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEndpointAddress
                         * @property {string|null} [ip] EndpointAddress ip
                         * @property {string|null} [hostname] EndpointAddress hostname
                         * @property {string|null} [nodeName] EndpointAddress nodeName
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [targetRef] EndpointAddress targetRef
                         */

                        /**
                         * Constructs a new EndpointAddress.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EndpointAddress.
                         * @implements IEndpointAddress
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEndpointAddress=} [properties] Properties to set
                         */
                        function EndpointAddress(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EndpointAddress ip.
                         * @member {string} ip
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @instance
                         */
                        EndpointAddress.prototype.ip = "";

                        /**
                         * EndpointAddress hostname.
                         * @member {string} hostname
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @instance
                         */
                        EndpointAddress.prototype.hostname = "";

                        /**
                         * EndpointAddress nodeName.
                         * @member {string} nodeName
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @instance
                         */
                        EndpointAddress.prototype.nodeName = "";

                        /**
                         * EndpointAddress targetRef.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} targetRef
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @instance
                         */
                        EndpointAddress.prototype.targetRef = null;

                        /**
                         * Creates a new EndpointAddress instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointAddress=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EndpointAddress} EndpointAddress instance
                         */
                        EndpointAddress.create = function create(properties) {
                            return new EndpointAddress(properties);
                        };

                        /**
                         * Encodes the specified EndpointAddress message. Does not implicitly {@link k8s.io.api.core.v1.EndpointAddress.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointAddress} message EndpointAddress message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointAddress.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ip);
                            if (message.targetRef != null && Object.hasOwnProperty.call(message, "targetRef"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.targetRef, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.hostname != null && Object.hasOwnProperty.call(message, "hostname"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.hostname);
                            if (message.nodeName != null && Object.hasOwnProperty.call(message, "nodeName"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.nodeName);
                            return writer;
                        };

                        /**
                         * Encodes the specified EndpointAddress message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EndpointAddress.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointAddress} message EndpointAddress message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointAddress.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EndpointAddress message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EndpointAddress} EndpointAddress
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointAddress.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EndpointAddress();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.ip = reader.string();
                                    break;
                                case 3:
                                    message.hostname = reader.string();
                                    break;
                                case 4:
                                    message.nodeName = reader.string();
                                    break;
                                case 2:
                                    message.targetRef = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EndpointAddress message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EndpointAddress} EndpointAddress
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointAddress.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EndpointAddress message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EndpointAddress.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                if (!$util.isString(message.ip))
                                    return "ip: string expected";
                            if (message.hostname != null && message.hasOwnProperty("hostname"))
                                if (!$util.isString(message.hostname))
                                    return "hostname: string expected";
                            if (message.nodeName != null && message.hasOwnProperty("nodeName"))
                                if (!$util.isString(message.nodeName))
                                    return "nodeName: string expected";
                            if (message.targetRef != null && message.hasOwnProperty("targetRef")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.targetRef);
                                if (error)
                                    return "targetRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an EndpointAddress message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EndpointAddress} EndpointAddress
                         */
                        EndpointAddress.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EndpointAddress)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EndpointAddress();
                            if (object.ip != null)
                                message.ip = String(object.ip);
                            if (object.hostname != null)
                                message.hostname = String(object.hostname);
                            if (object.nodeName != null)
                                message.nodeName = String(object.nodeName);
                            if (object.targetRef != null) {
                                if (typeof object.targetRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EndpointAddress.targetRef: object expected");
                                message.targetRef = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.targetRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EndpointAddress message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @static
                         * @param {k8s.io.api.core.v1.EndpointAddress} message EndpointAddress
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EndpointAddress.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.ip = "";
                                object.targetRef = null;
                                object.hostname = "";
                                object.nodeName = "";
                            }
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                object.ip = message.ip;
                            if (message.targetRef != null && message.hasOwnProperty("targetRef"))
                                object.targetRef = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.targetRef, options);
                            if (message.hostname != null && message.hasOwnProperty("hostname"))
                                object.hostname = message.hostname;
                            if (message.nodeName != null && message.hasOwnProperty("nodeName"))
                                object.nodeName = message.nodeName;
                            return object;
                        };

                        /**
                         * Converts this EndpointAddress to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EndpointAddress
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EndpointAddress.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EndpointAddress;
                    })();

                    v1.EndpointPort = (function() {

                        /**
                         * Properties of an EndpointPort.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEndpointPort
                         * @property {string|null} [name] EndpointPort name
                         * @property {number|null} [port] EndpointPort port
                         * @property {string|null} [protocol] EndpointPort protocol
                         * @property {string|null} [appProtocol] EndpointPort appProtocol
                         */

                        /**
                         * Constructs a new EndpointPort.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EndpointPort.
                         * @implements IEndpointPort
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEndpointPort=} [properties] Properties to set
                         */
                        function EndpointPort(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EndpointPort name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @instance
                         */
                        EndpointPort.prototype.name = "";

                        /**
                         * EndpointPort port.
                         * @member {number} port
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @instance
                         */
                        EndpointPort.prototype.port = 0;

                        /**
                         * EndpointPort protocol.
                         * @member {string} protocol
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @instance
                         */
                        EndpointPort.prototype.protocol = "";

                        /**
                         * EndpointPort appProtocol.
                         * @member {string} appProtocol
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @instance
                         */
                        EndpointPort.prototype.appProtocol = "";

                        /**
                         * Creates a new EndpointPort instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointPort=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EndpointPort} EndpointPort instance
                         */
                        EndpointPort.create = function create(properties) {
                            return new EndpointPort(properties);
                        };

                        /**
                         * Encodes the specified EndpointPort message. Does not implicitly {@link k8s.io.api.core.v1.EndpointPort.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointPort} message EndpointPort message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointPort.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.port);
                            if (message.protocol != null && Object.hasOwnProperty.call(message, "protocol"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.protocol);
                            if (message.appProtocol != null && Object.hasOwnProperty.call(message, "appProtocol"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.appProtocol);
                            return writer;
                        };

                        /**
                         * Encodes the specified EndpointPort message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EndpointPort.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointPort} message EndpointPort message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointPort.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EndpointPort message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EndpointPort} EndpointPort
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointPort.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EndpointPort();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.port = reader.int32();
                                    break;
                                case 3:
                                    message.protocol = reader.string();
                                    break;
                                case 4:
                                    message.appProtocol = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EndpointPort message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EndpointPort} EndpointPort
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointPort.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EndpointPort message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EndpointPort.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.port != null && message.hasOwnProperty("port"))
                                if (!$util.isInteger(message.port))
                                    return "port: integer expected";
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                if (!$util.isString(message.protocol))
                                    return "protocol: string expected";
                            if (message.appProtocol != null && message.hasOwnProperty("appProtocol"))
                                if (!$util.isString(message.appProtocol))
                                    return "appProtocol: string expected";
                            return null;
                        };

                        /**
                         * Creates an EndpointPort message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EndpointPort} EndpointPort
                         */
                        EndpointPort.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EndpointPort)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EndpointPort();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.port != null)
                                message.port = object.port | 0;
                            if (object.protocol != null)
                                message.protocol = String(object.protocol);
                            if (object.appProtocol != null)
                                message.appProtocol = String(object.appProtocol);
                            return message;
                        };

                        /**
                         * Creates a plain object from an EndpointPort message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @static
                         * @param {k8s.io.api.core.v1.EndpointPort} message EndpointPort
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EndpointPort.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.port = 0;
                                object.protocol = "";
                                object.appProtocol = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.port != null && message.hasOwnProperty("port"))
                                object.port = message.port;
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                object.protocol = message.protocol;
                            if (message.appProtocol != null && message.hasOwnProperty("appProtocol"))
                                object.appProtocol = message.appProtocol;
                            return object;
                        };

                        /**
                         * Converts this EndpointPort to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EndpointPort
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EndpointPort.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EndpointPort;
                    })();

                    v1.EndpointSubset = (function() {

                        /**
                         * Properties of an EndpointSubset.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEndpointSubset
                         * @property {Array.<k8s.io.api.core.v1.IEndpointAddress>|null} [addresses] EndpointSubset addresses
                         * @property {Array.<k8s.io.api.core.v1.IEndpointAddress>|null} [notReadyAddresses] EndpointSubset notReadyAddresses
                         * @property {Array.<k8s.io.api.core.v1.IEndpointPort>|null} [ports] EndpointSubset ports
                         */

                        /**
                         * Constructs a new EndpointSubset.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EndpointSubset.
                         * @implements IEndpointSubset
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEndpointSubset=} [properties] Properties to set
                         */
                        function EndpointSubset(properties) {
                            this.addresses = [];
                            this.notReadyAddresses = [];
                            this.ports = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EndpointSubset addresses.
                         * @member {Array.<k8s.io.api.core.v1.IEndpointAddress>} addresses
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @instance
                         */
                        EndpointSubset.prototype.addresses = $util.emptyArray;

                        /**
                         * EndpointSubset notReadyAddresses.
                         * @member {Array.<k8s.io.api.core.v1.IEndpointAddress>} notReadyAddresses
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @instance
                         */
                        EndpointSubset.prototype.notReadyAddresses = $util.emptyArray;

                        /**
                         * EndpointSubset ports.
                         * @member {Array.<k8s.io.api.core.v1.IEndpointPort>} ports
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @instance
                         */
                        EndpointSubset.prototype.ports = $util.emptyArray;

                        /**
                         * Creates a new EndpointSubset instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointSubset=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EndpointSubset} EndpointSubset instance
                         */
                        EndpointSubset.create = function create(properties) {
                            return new EndpointSubset(properties);
                        };

                        /**
                         * Encodes the specified EndpointSubset message. Does not implicitly {@link k8s.io.api.core.v1.EndpointSubset.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointSubset} message EndpointSubset message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointSubset.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.addresses != null && message.addresses.length)
                                for (var i = 0; i < message.addresses.length; ++i)
                                    $root.k8s.io.api.core.v1.EndpointAddress.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.notReadyAddresses != null && message.notReadyAddresses.length)
                                for (var i = 0; i < message.notReadyAddresses.length; ++i)
                                    $root.k8s.io.api.core.v1.EndpointAddress.encode(message.notReadyAddresses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.ports != null && message.ports.length)
                                for (var i = 0; i < message.ports.length; ++i)
                                    $root.k8s.io.api.core.v1.EndpointPort.encode(message.ports[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EndpointSubset message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EndpointSubset.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointSubset} message EndpointSubset message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointSubset.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EndpointSubset message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EndpointSubset} EndpointSubset
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointSubset.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EndpointSubset();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.addresses && message.addresses.length))
                                        message.addresses = [];
                                    message.addresses.push($root.k8s.io.api.core.v1.EndpointAddress.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (!(message.notReadyAddresses && message.notReadyAddresses.length))
                                        message.notReadyAddresses = [];
                                    message.notReadyAddresses.push($root.k8s.io.api.core.v1.EndpointAddress.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    if (!(message.ports && message.ports.length))
                                        message.ports = [];
                                    message.ports.push($root.k8s.io.api.core.v1.EndpointPort.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EndpointSubset message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EndpointSubset} EndpointSubset
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointSubset.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EndpointSubset message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EndpointSubset.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.addresses != null && message.hasOwnProperty("addresses")) {
                                if (!Array.isArray(message.addresses))
                                    return "addresses: array expected";
                                for (var i = 0; i < message.addresses.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EndpointAddress.verify(message.addresses[i]);
                                    if (error)
                                        return "addresses." + error;
                                }
                            }
                            if (message.notReadyAddresses != null && message.hasOwnProperty("notReadyAddresses")) {
                                if (!Array.isArray(message.notReadyAddresses))
                                    return "notReadyAddresses: array expected";
                                for (var i = 0; i < message.notReadyAddresses.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EndpointAddress.verify(message.notReadyAddresses[i]);
                                    if (error)
                                        return "notReadyAddresses." + error;
                                }
                            }
                            if (message.ports != null && message.hasOwnProperty("ports")) {
                                if (!Array.isArray(message.ports))
                                    return "ports: array expected";
                                for (var i = 0; i < message.ports.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EndpointPort.verify(message.ports[i]);
                                    if (error)
                                        return "ports." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an EndpointSubset message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EndpointSubset} EndpointSubset
                         */
                        EndpointSubset.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EndpointSubset)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EndpointSubset();
                            if (object.addresses) {
                                if (!Array.isArray(object.addresses))
                                    throw TypeError(".k8s.io.api.core.v1.EndpointSubset.addresses: array expected");
                                message.addresses = [];
                                for (var i = 0; i < object.addresses.length; ++i) {
                                    if (typeof object.addresses[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EndpointSubset.addresses: object expected");
                                    message.addresses[i] = $root.k8s.io.api.core.v1.EndpointAddress.fromObject(object.addresses[i]);
                                }
                            }
                            if (object.notReadyAddresses) {
                                if (!Array.isArray(object.notReadyAddresses))
                                    throw TypeError(".k8s.io.api.core.v1.EndpointSubset.notReadyAddresses: array expected");
                                message.notReadyAddresses = [];
                                for (var i = 0; i < object.notReadyAddresses.length; ++i) {
                                    if (typeof object.notReadyAddresses[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EndpointSubset.notReadyAddresses: object expected");
                                    message.notReadyAddresses[i] = $root.k8s.io.api.core.v1.EndpointAddress.fromObject(object.notReadyAddresses[i]);
                                }
                            }
                            if (object.ports) {
                                if (!Array.isArray(object.ports))
                                    throw TypeError(".k8s.io.api.core.v1.EndpointSubset.ports: array expected");
                                message.ports = [];
                                for (var i = 0; i < object.ports.length; ++i) {
                                    if (typeof object.ports[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EndpointSubset.ports: object expected");
                                    message.ports[i] = $root.k8s.io.api.core.v1.EndpointPort.fromObject(object.ports[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EndpointSubset message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @static
                         * @param {k8s.io.api.core.v1.EndpointSubset} message EndpointSubset
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EndpointSubset.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.addresses = [];
                                object.notReadyAddresses = [];
                                object.ports = [];
                            }
                            if (message.addresses && message.addresses.length) {
                                object.addresses = [];
                                for (var j = 0; j < message.addresses.length; ++j)
                                    object.addresses[j] = $root.k8s.io.api.core.v1.EndpointAddress.toObject(message.addresses[j], options);
                            }
                            if (message.notReadyAddresses && message.notReadyAddresses.length) {
                                object.notReadyAddresses = [];
                                for (var j = 0; j < message.notReadyAddresses.length; ++j)
                                    object.notReadyAddresses[j] = $root.k8s.io.api.core.v1.EndpointAddress.toObject(message.notReadyAddresses[j], options);
                            }
                            if (message.ports && message.ports.length) {
                                object.ports = [];
                                for (var j = 0; j < message.ports.length; ++j)
                                    object.ports[j] = $root.k8s.io.api.core.v1.EndpointPort.toObject(message.ports[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this EndpointSubset to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EndpointSubset
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EndpointSubset.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EndpointSubset;
                    })();

                    v1.Endpoints = (function() {

                        /**
                         * Properties of an Endpoints.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEndpoints
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Endpoints metadata
                         * @property {Array.<k8s.io.api.core.v1.IEndpointSubset>|null} [subsets] Endpoints subsets
                         */

                        /**
                         * Constructs a new Endpoints.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an Endpoints.
                         * @implements IEndpoints
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEndpoints=} [properties] Properties to set
                         */
                        function Endpoints(properties) {
                            this.subsets = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Endpoints metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @instance
                         */
                        Endpoints.prototype.metadata = null;

                        /**
                         * Endpoints subsets.
                         * @member {Array.<k8s.io.api.core.v1.IEndpointSubset>} subsets
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @instance
                         */
                        Endpoints.prototype.subsets = $util.emptyArray;

                        /**
                         * Creates a new Endpoints instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpoints=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Endpoints} Endpoints instance
                         */
                        Endpoints.create = function create(properties) {
                            return new Endpoints(properties);
                        };

                        /**
                         * Encodes the specified Endpoints message. Does not implicitly {@link k8s.io.api.core.v1.Endpoints.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpoints} message Endpoints message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Endpoints.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.subsets != null && message.subsets.length)
                                for (var i = 0; i < message.subsets.length; ++i)
                                    $root.k8s.io.api.core.v1.EndpointSubset.encode(message.subsets[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Endpoints message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Endpoints.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpoints} message Endpoints message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Endpoints.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Endpoints message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Endpoints} Endpoints
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Endpoints.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Endpoints();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.subsets && message.subsets.length))
                                        message.subsets = [];
                                    message.subsets.push($root.k8s.io.api.core.v1.EndpointSubset.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Endpoints message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Endpoints} Endpoints
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Endpoints.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Endpoints message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Endpoints.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.subsets != null && message.hasOwnProperty("subsets")) {
                                if (!Array.isArray(message.subsets))
                                    return "subsets: array expected";
                                for (var i = 0; i < message.subsets.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EndpointSubset.verify(message.subsets[i]);
                                    if (error)
                                        return "subsets." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an Endpoints message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Endpoints} Endpoints
                         */
                        Endpoints.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Endpoints)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Endpoints();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Endpoints.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.subsets) {
                                if (!Array.isArray(object.subsets))
                                    throw TypeError(".k8s.io.api.core.v1.Endpoints.subsets: array expected");
                                message.subsets = [];
                                for (var i = 0; i < object.subsets.length; ++i) {
                                    if (typeof object.subsets[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.Endpoints.subsets: object expected");
                                    message.subsets[i] = $root.k8s.io.api.core.v1.EndpointSubset.fromObject(object.subsets[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an Endpoints message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @static
                         * @param {k8s.io.api.core.v1.Endpoints} message Endpoints
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Endpoints.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.subsets = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.subsets && message.subsets.length) {
                                object.subsets = [];
                                for (var j = 0; j < message.subsets.length; ++j)
                                    object.subsets[j] = $root.k8s.io.api.core.v1.EndpointSubset.toObject(message.subsets[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this Endpoints to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Endpoints
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Endpoints.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Endpoints;
                    })();

                    v1.EndpointsList = (function() {

                        /**
                         * Properties of an EndpointsList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEndpointsList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] EndpointsList metadata
                         * @property {Array.<k8s.io.api.core.v1.IEndpoints>|null} [items] EndpointsList items
                         */

                        /**
                         * Constructs a new EndpointsList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EndpointsList.
                         * @implements IEndpointsList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEndpointsList=} [properties] Properties to set
                         */
                        function EndpointsList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EndpointsList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @instance
                         */
                        EndpointsList.prototype.metadata = null;

                        /**
                         * EndpointsList items.
                         * @member {Array.<k8s.io.api.core.v1.IEndpoints>} items
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @instance
                         */
                        EndpointsList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new EndpointsList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointsList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EndpointsList} EndpointsList instance
                         */
                        EndpointsList.create = function create(properties) {
                            return new EndpointsList(properties);
                        };

                        /**
                         * Encodes the specified EndpointsList message. Does not implicitly {@link k8s.io.api.core.v1.EndpointsList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointsList} message EndpointsList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointsList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Endpoints.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EndpointsList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EndpointsList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {k8s.io.api.core.v1.IEndpointsList} message EndpointsList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EndpointsList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EndpointsList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EndpointsList} EndpointsList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointsList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EndpointsList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Endpoints.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EndpointsList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EndpointsList} EndpointsList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EndpointsList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EndpointsList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EndpointsList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Endpoints.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an EndpointsList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EndpointsList} EndpointsList
                         */
                        EndpointsList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EndpointsList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EndpointsList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EndpointsList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.EndpointsList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EndpointsList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Endpoints.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EndpointsList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @static
                         * @param {k8s.io.api.core.v1.EndpointsList} message EndpointsList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EndpointsList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Endpoints.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this EndpointsList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EndpointsList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EndpointsList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EndpointsList;
                    })();

                    v1.EnvFromSource = (function() {

                        /**
                         * Properties of an EnvFromSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEnvFromSource
                         * @property {string|null} [prefix] EnvFromSource prefix
                         * @property {k8s.io.api.core.v1.IConfigMapEnvSource|null} [configMapRef] EnvFromSource configMapRef
                         * @property {k8s.io.api.core.v1.ISecretEnvSource|null} [secretRef] EnvFromSource secretRef
                         */

                        /**
                         * Constructs a new EnvFromSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EnvFromSource.
                         * @implements IEnvFromSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEnvFromSource=} [properties] Properties to set
                         */
                        function EnvFromSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EnvFromSource prefix.
                         * @member {string} prefix
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @instance
                         */
                        EnvFromSource.prototype.prefix = "";

                        /**
                         * EnvFromSource configMapRef.
                         * @member {k8s.io.api.core.v1.IConfigMapEnvSource|null|undefined} configMapRef
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @instance
                         */
                        EnvFromSource.prototype.configMapRef = null;

                        /**
                         * EnvFromSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretEnvSource|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @instance
                         */
                        EnvFromSource.prototype.secretRef = null;

                        /**
                         * Creates a new EnvFromSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvFromSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EnvFromSource} EnvFromSource instance
                         */
                        EnvFromSource.create = function create(properties) {
                            return new EnvFromSource(properties);
                        };

                        /**
                         * Encodes the specified EnvFromSource message. Does not implicitly {@link k8s.io.api.core.v1.EnvFromSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvFromSource} message EnvFromSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnvFromSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.prefix);
                            if (message.configMapRef != null && Object.hasOwnProperty.call(message, "configMapRef"))
                                $root.k8s.io.api.core.v1.ConfigMapEnvSource.encode(message.configMapRef, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretEnvSource.encode(message.secretRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EnvFromSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EnvFromSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvFromSource} message EnvFromSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnvFromSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EnvFromSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EnvFromSource} EnvFromSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnvFromSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EnvFromSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.prefix = reader.string();
                                    break;
                                case 2:
                                    message.configMapRef = $root.k8s.io.api.core.v1.ConfigMapEnvSource.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretEnvSource.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EnvFromSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EnvFromSource} EnvFromSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnvFromSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EnvFromSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EnvFromSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.prefix != null && message.hasOwnProperty("prefix"))
                                if (!$util.isString(message.prefix))
                                    return "prefix: string expected";
                            if (message.configMapRef != null && message.hasOwnProperty("configMapRef")) {
                                var error = $root.k8s.io.api.core.v1.ConfigMapEnvSource.verify(message.configMapRef);
                                if (error)
                                    return "configMapRef." + error;
                            }
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretEnvSource.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an EnvFromSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EnvFromSource} EnvFromSource
                         */
                        EnvFromSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EnvFromSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EnvFromSource();
                            if (object.prefix != null)
                                message.prefix = String(object.prefix);
                            if (object.configMapRef != null) {
                                if (typeof object.configMapRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvFromSource.configMapRef: object expected");
                                message.configMapRef = $root.k8s.io.api.core.v1.ConfigMapEnvSource.fromObject(object.configMapRef);
                            }
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvFromSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretEnvSource.fromObject(object.secretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EnvFromSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @static
                         * @param {k8s.io.api.core.v1.EnvFromSource} message EnvFromSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EnvFromSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.prefix = "";
                                object.configMapRef = null;
                                object.secretRef = null;
                            }
                            if (message.prefix != null && message.hasOwnProperty("prefix"))
                                object.prefix = message.prefix;
                            if (message.configMapRef != null && message.hasOwnProperty("configMapRef"))
                                object.configMapRef = $root.k8s.io.api.core.v1.ConfigMapEnvSource.toObject(message.configMapRef, options);
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretEnvSource.toObject(message.secretRef, options);
                            return object;
                        };

                        /**
                         * Converts this EnvFromSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EnvFromSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EnvFromSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EnvFromSource;
                    })();

                    v1.EnvVar = (function() {

                        /**
                         * Properties of an EnvVar.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEnvVar
                         * @property {string|null} [name] EnvVar name
                         * @property {string|null} [value] EnvVar value
                         * @property {k8s.io.api.core.v1.IEnvVarSource|null} [valueFrom] EnvVar valueFrom
                         */

                        /**
                         * Constructs a new EnvVar.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EnvVar.
                         * @implements IEnvVar
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEnvVar=} [properties] Properties to set
                         */
                        function EnvVar(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EnvVar name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @instance
                         */
                        EnvVar.prototype.name = "";

                        /**
                         * EnvVar value.
                         * @member {string} value
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @instance
                         */
                        EnvVar.prototype.value = "";

                        /**
                         * EnvVar valueFrom.
                         * @member {k8s.io.api.core.v1.IEnvVarSource|null|undefined} valueFrom
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @instance
                         */
                        EnvVar.prototype.valueFrom = null;

                        /**
                         * Creates a new EnvVar instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvVar=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EnvVar} EnvVar instance
                         */
                        EnvVar.create = function create(properties) {
                            return new EnvVar(properties);
                        };

                        /**
                         * Encodes the specified EnvVar message. Does not implicitly {@link k8s.io.api.core.v1.EnvVar.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvVar} message EnvVar message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnvVar.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                            if (message.valueFrom != null && Object.hasOwnProperty.call(message, "valueFrom"))
                                $root.k8s.io.api.core.v1.EnvVarSource.encode(message.valueFrom, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EnvVar message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EnvVar.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvVar} message EnvVar message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnvVar.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EnvVar message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EnvVar} EnvVar
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnvVar.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EnvVar();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.value = reader.string();
                                    break;
                                case 3:
                                    message.valueFrom = $root.k8s.io.api.core.v1.EnvVarSource.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EnvVar message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EnvVar} EnvVar
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnvVar.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EnvVar message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EnvVar.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isString(message.value))
                                    return "value: string expected";
                            if (message.valueFrom != null && message.hasOwnProperty("valueFrom")) {
                                var error = $root.k8s.io.api.core.v1.EnvVarSource.verify(message.valueFrom);
                                if (error)
                                    return "valueFrom." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an EnvVar message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EnvVar} EnvVar
                         */
                        EnvVar.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EnvVar)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EnvVar();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.value != null)
                                message.value = String(object.value);
                            if (object.valueFrom != null) {
                                if (typeof object.valueFrom !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvVar.valueFrom: object expected");
                                message.valueFrom = $root.k8s.io.api.core.v1.EnvVarSource.fromObject(object.valueFrom);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EnvVar message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @static
                         * @param {k8s.io.api.core.v1.EnvVar} message EnvVar
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EnvVar.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.value = "";
                                object.valueFrom = null;
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            if (message.valueFrom != null && message.hasOwnProperty("valueFrom"))
                                object.valueFrom = $root.k8s.io.api.core.v1.EnvVarSource.toObject(message.valueFrom, options);
                            return object;
                        };

                        /**
                         * Converts this EnvVar to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EnvVar
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EnvVar.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EnvVar;
                    })();

                    v1.EnvVarSource = (function() {

                        /**
                         * Properties of an EnvVarSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEnvVarSource
                         * @property {k8s.io.api.core.v1.IObjectFieldSelector|null} [fieldRef] EnvVarSource fieldRef
                         * @property {k8s.io.api.core.v1.IResourceFieldSelector|null} [resourceFieldRef] EnvVarSource resourceFieldRef
                         * @property {k8s.io.api.core.v1.IConfigMapKeySelector|null} [configMapKeyRef] EnvVarSource configMapKeyRef
                         * @property {k8s.io.api.core.v1.ISecretKeySelector|null} [secretKeyRef] EnvVarSource secretKeyRef
                         */

                        /**
                         * Constructs a new EnvVarSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EnvVarSource.
                         * @implements IEnvVarSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEnvVarSource=} [properties] Properties to set
                         */
                        function EnvVarSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EnvVarSource fieldRef.
                         * @member {k8s.io.api.core.v1.IObjectFieldSelector|null|undefined} fieldRef
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @instance
                         */
                        EnvVarSource.prototype.fieldRef = null;

                        /**
                         * EnvVarSource resourceFieldRef.
                         * @member {k8s.io.api.core.v1.IResourceFieldSelector|null|undefined} resourceFieldRef
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @instance
                         */
                        EnvVarSource.prototype.resourceFieldRef = null;

                        /**
                         * EnvVarSource configMapKeyRef.
                         * @member {k8s.io.api.core.v1.IConfigMapKeySelector|null|undefined} configMapKeyRef
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @instance
                         */
                        EnvVarSource.prototype.configMapKeyRef = null;

                        /**
                         * EnvVarSource secretKeyRef.
                         * @member {k8s.io.api.core.v1.ISecretKeySelector|null|undefined} secretKeyRef
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @instance
                         */
                        EnvVarSource.prototype.secretKeyRef = null;

                        /**
                         * Creates a new EnvVarSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvVarSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EnvVarSource} EnvVarSource instance
                         */
                        EnvVarSource.create = function create(properties) {
                            return new EnvVarSource(properties);
                        };

                        /**
                         * Encodes the specified EnvVarSource message. Does not implicitly {@link k8s.io.api.core.v1.EnvVarSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvVarSource} message EnvVarSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnvVarSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.fieldRef != null && Object.hasOwnProperty.call(message, "fieldRef"))
                                $root.k8s.io.api.core.v1.ObjectFieldSelector.encode(message.fieldRef, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.resourceFieldRef != null && Object.hasOwnProperty.call(message, "resourceFieldRef"))
                                $root.k8s.io.api.core.v1.ResourceFieldSelector.encode(message.resourceFieldRef, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.configMapKeyRef != null && Object.hasOwnProperty.call(message, "configMapKeyRef"))
                                $root.k8s.io.api.core.v1.ConfigMapKeySelector.encode(message.configMapKeyRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.secretKeyRef != null && Object.hasOwnProperty.call(message, "secretKeyRef"))
                                $root.k8s.io.api.core.v1.SecretKeySelector.encode(message.secretKeyRef, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EnvVarSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EnvVarSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEnvVarSource} message EnvVarSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnvVarSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EnvVarSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EnvVarSource} EnvVarSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnvVarSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EnvVarSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.fieldRef = $root.k8s.io.api.core.v1.ObjectFieldSelector.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.resourceFieldRef = $root.k8s.io.api.core.v1.ResourceFieldSelector.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.configMapKeyRef = $root.k8s.io.api.core.v1.ConfigMapKeySelector.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.secretKeyRef = $root.k8s.io.api.core.v1.SecretKeySelector.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EnvVarSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EnvVarSource} EnvVarSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnvVarSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EnvVarSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EnvVarSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.fieldRef != null && message.hasOwnProperty("fieldRef")) {
                                var error = $root.k8s.io.api.core.v1.ObjectFieldSelector.verify(message.fieldRef);
                                if (error)
                                    return "fieldRef." + error;
                            }
                            if (message.resourceFieldRef != null && message.hasOwnProperty("resourceFieldRef")) {
                                var error = $root.k8s.io.api.core.v1.ResourceFieldSelector.verify(message.resourceFieldRef);
                                if (error)
                                    return "resourceFieldRef." + error;
                            }
                            if (message.configMapKeyRef != null && message.hasOwnProperty("configMapKeyRef")) {
                                var error = $root.k8s.io.api.core.v1.ConfigMapKeySelector.verify(message.configMapKeyRef);
                                if (error)
                                    return "configMapKeyRef." + error;
                            }
                            if (message.secretKeyRef != null && message.hasOwnProperty("secretKeyRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretKeySelector.verify(message.secretKeyRef);
                                if (error)
                                    return "secretKeyRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an EnvVarSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EnvVarSource} EnvVarSource
                         */
                        EnvVarSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EnvVarSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EnvVarSource();
                            if (object.fieldRef != null) {
                                if (typeof object.fieldRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvVarSource.fieldRef: object expected");
                                message.fieldRef = $root.k8s.io.api.core.v1.ObjectFieldSelector.fromObject(object.fieldRef);
                            }
                            if (object.resourceFieldRef != null) {
                                if (typeof object.resourceFieldRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvVarSource.resourceFieldRef: object expected");
                                message.resourceFieldRef = $root.k8s.io.api.core.v1.ResourceFieldSelector.fromObject(object.resourceFieldRef);
                            }
                            if (object.configMapKeyRef != null) {
                                if (typeof object.configMapKeyRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvVarSource.configMapKeyRef: object expected");
                                message.configMapKeyRef = $root.k8s.io.api.core.v1.ConfigMapKeySelector.fromObject(object.configMapKeyRef);
                            }
                            if (object.secretKeyRef != null) {
                                if (typeof object.secretKeyRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EnvVarSource.secretKeyRef: object expected");
                                message.secretKeyRef = $root.k8s.io.api.core.v1.SecretKeySelector.fromObject(object.secretKeyRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EnvVarSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @static
                         * @param {k8s.io.api.core.v1.EnvVarSource} message EnvVarSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EnvVarSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.fieldRef = null;
                                object.resourceFieldRef = null;
                                object.configMapKeyRef = null;
                                object.secretKeyRef = null;
                            }
                            if (message.fieldRef != null && message.hasOwnProperty("fieldRef"))
                                object.fieldRef = $root.k8s.io.api.core.v1.ObjectFieldSelector.toObject(message.fieldRef, options);
                            if (message.resourceFieldRef != null && message.hasOwnProperty("resourceFieldRef"))
                                object.resourceFieldRef = $root.k8s.io.api.core.v1.ResourceFieldSelector.toObject(message.resourceFieldRef, options);
                            if (message.configMapKeyRef != null && message.hasOwnProperty("configMapKeyRef"))
                                object.configMapKeyRef = $root.k8s.io.api.core.v1.ConfigMapKeySelector.toObject(message.configMapKeyRef, options);
                            if (message.secretKeyRef != null && message.hasOwnProperty("secretKeyRef"))
                                object.secretKeyRef = $root.k8s.io.api.core.v1.SecretKeySelector.toObject(message.secretKeyRef, options);
                            return object;
                        };

                        /**
                         * Converts this EnvVarSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EnvVarSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EnvVarSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EnvVarSource;
                    })();

                    v1.EphemeralContainer = (function() {

                        /**
                         * Properties of an EphemeralContainer.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEphemeralContainer
                         * @property {k8s.io.api.core.v1.IEphemeralContainerCommon|null} [ephemeralContainerCommon] EphemeralContainer ephemeralContainerCommon
                         * @property {string|null} [targetContainerName] EphemeralContainer targetContainerName
                         */

                        /**
                         * Constructs a new EphemeralContainer.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EphemeralContainer.
                         * @implements IEphemeralContainer
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEphemeralContainer=} [properties] Properties to set
                         */
                        function EphemeralContainer(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EphemeralContainer ephemeralContainerCommon.
                         * @member {k8s.io.api.core.v1.IEphemeralContainerCommon|null|undefined} ephemeralContainerCommon
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @instance
                         */
                        EphemeralContainer.prototype.ephemeralContainerCommon = null;

                        /**
                         * EphemeralContainer targetContainerName.
                         * @member {string} targetContainerName
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @instance
                         */
                        EphemeralContainer.prototype.targetContainerName = "";

                        /**
                         * Creates a new EphemeralContainer instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainer=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EphemeralContainer} EphemeralContainer instance
                         */
                        EphemeralContainer.create = function create(properties) {
                            return new EphemeralContainer(properties);
                        };

                        /**
                         * Encodes the specified EphemeralContainer message. Does not implicitly {@link k8s.io.api.core.v1.EphemeralContainer.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainer} message EphemeralContainer message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralContainer.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ephemeralContainerCommon != null && Object.hasOwnProperty.call(message, "ephemeralContainerCommon"))
                                $root.k8s.io.api.core.v1.EphemeralContainerCommon.encode(message.ephemeralContainerCommon, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.targetContainerName != null && Object.hasOwnProperty.call(message, "targetContainerName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.targetContainerName);
                            return writer;
                        };

                        /**
                         * Encodes the specified EphemeralContainer message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EphemeralContainer.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainer} message EphemeralContainer message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralContainer.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EphemeralContainer message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EphemeralContainer} EphemeralContainer
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralContainer.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EphemeralContainer();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.ephemeralContainerCommon = $root.k8s.io.api.core.v1.EphemeralContainerCommon.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.targetContainerName = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EphemeralContainer message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EphemeralContainer} EphemeralContainer
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralContainer.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EphemeralContainer message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EphemeralContainer.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ephemeralContainerCommon != null && message.hasOwnProperty("ephemeralContainerCommon")) {
                                var error = $root.k8s.io.api.core.v1.EphemeralContainerCommon.verify(message.ephemeralContainerCommon);
                                if (error)
                                    return "ephemeralContainerCommon." + error;
                            }
                            if (message.targetContainerName != null && message.hasOwnProperty("targetContainerName"))
                                if (!$util.isString(message.targetContainerName))
                                    return "targetContainerName: string expected";
                            return null;
                        };

                        /**
                         * Creates an EphemeralContainer message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EphemeralContainer} EphemeralContainer
                         */
                        EphemeralContainer.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EphemeralContainer)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EphemeralContainer();
                            if (object.ephemeralContainerCommon != null) {
                                if (typeof object.ephemeralContainerCommon !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainer.ephemeralContainerCommon: object expected");
                                message.ephemeralContainerCommon = $root.k8s.io.api.core.v1.EphemeralContainerCommon.fromObject(object.ephemeralContainerCommon);
                            }
                            if (object.targetContainerName != null)
                                message.targetContainerName = String(object.targetContainerName);
                            return message;
                        };

                        /**
                         * Creates a plain object from an EphemeralContainer message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @static
                         * @param {k8s.io.api.core.v1.EphemeralContainer} message EphemeralContainer
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EphemeralContainer.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.ephemeralContainerCommon = null;
                                object.targetContainerName = "";
                            }
                            if (message.ephemeralContainerCommon != null && message.hasOwnProperty("ephemeralContainerCommon"))
                                object.ephemeralContainerCommon = $root.k8s.io.api.core.v1.EphemeralContainerCommon.toObject(message.ephemeralContainerCommon, options);
                            if (message.targetContainerName != null && message.hasOwnProperty("targetContainerName"))
                                object.targetContainerName = message.targetContainerName;
                            return object;
                        };

                        /**
                         * Converts this EphemeralContainer to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EphemeralContainer
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EphemeralContainer.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EphemeralContainer;
                    })();

                    v1.EphemeralContainerCommon = (function() {

                        /**
                         * Properties of an EphemeralContainerCommon.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEphemeralContainerCommon
                         * @property {string|null} [name] EphemeralContainerCommon name
                         * @property {string|null} [image] EphemeralContainerCommon image
                         * @property {Array.<string>|null} [command] EphemeralContainerCommon command
                         * @property {Array.<string>|null} [args] EphemeralContainerCommon args
                         * @property {string|null} [workingDir] EphemeralContainerCommon workingDir
                         * @property {Array.<k8s.io.api.core.v1.IContainerPort>|null} [ports] EphemeralContainerCommon ports
                         * @property {Array.<k8s.io.api.core.v1.IEnvFromSource>|null} [envFrom] EphemeralContainerCommon envFrom
                         * @property {Array.<k8s.io.api.core.v1.IEnvVar>|null} [env] EphemeralContainerCommon env
                         * @property {k8s.io.api.core.v1.IResourceRequirements|null} [resources] EphemeralContainerCommon resources
                         * @property {Array.<k8s.io.api.core.v1.IVolumeMount>|null} [volumeMounts] EphemeralContainerCommon volumeMounts
                         * @property {Array.<k8s.io.api.core.v1.IVolumeDevice>|null} [volumeDevices] EphemeralContainerCommon volumeDevices
                         * @property {k8s.io.api.core.v1.IProbe|null} [livenessProbe] EphemeralContainerCommon livenessProbe
                         * @property {k8s.io.api.core.v1.IProbe|null} [readinessProbe] EphemeralContainerCommon readinessProbe
                         * @property {k8s.io.api.core.v1.IProbe|null} [startupProbe] EphemeralContainerCommon startupProbe
                         * @property {k8s.io.api.core.v1.ILifecycle|null} [lifecycle] EphemeralContainerCommon lifecycle
                         * @property {string|null} [terminationMessagePath] EphemeralContainerCommon terminationMessagePath
                         * @property {string|null} [terminationMessagePolicy] EphemeralContainerCommon terminationMessagePolicy
                         * @property {string|null} [imagePullPolicy] EphemeralContainerCommon imagePullPolicy
                         * @property {k8s.io.api.core.v1.ISecurityContext|null} [securityContext] EphemeralContainerCommon securityContext
                         * @property {boolean|null} [stdin] EphemeralContainerCommon stdin
                         * @property {boolean|null} [stdinOnce] EphemeralContainerCommon stdinOnce
                         * @property {boolean|null} [tty] EphemeralContainerCommon tty
                         */

                        /**
                         * Constructs a new EphemeralContainerCommon.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EphemeralContainerCommon.
                         * @implements IEphemeralContainerCommon
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEphemeralContainerCommon=} [properties] Properties to set
                         */
                        function EphemeralContainerCommon(properties) {
                            this.command = [];
                            this.args = [];
                            this.ports = [];
                            this.envFrom = [];
                            this.env = [];
                            this.volumeMounts = [];
                            this.volumeDevices = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EphemeralContainerCommon name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.name = "";

                        /**
                         * EphemeralContainerCommon image.
                         * @member {string} image
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.image = "";

                        /**
                         * EphemeralContainerCommon command.
                         * @member {Array.<string>} command
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.command = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon args.
                         * @member {Array.<string>} args
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.args = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon workingDir.
                         * @member {string} workingDir
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.workingDir = "";

                        /**
                         * EphemeralContainerCommon ports.
                         * @member {Array.<k8s.io.api.core.v1.IContainerPort>} ports
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.ports = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon envFrom.
                         * @member {Array.<k8s.io.api.core.v1.IEnvFromSource>} envFrom
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.envFrom = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon env.
                         * @member {Array.<k8s.io.api.core.v1.IEnvVar>} env
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.env = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon resources.
                         * @member {k8s.io.api.core.v1.IResourceRequirements|null|undefined} resources
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.resources = null;

                        /**
                         * EphemeralContainerCommon volumeMounts.
                         * @member {Array.<k8s.io.api.core.v1.IVolumeMount>} volumeMounts
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.volumeMounts = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon volumeDevices.
                         * @member {Array.<k8s.io.api.core.v1.IVolumeDevice>} volumeDevices
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.volumeDevices = $util.emptyArray;

                        /**
                         * EphemeralContainerCommon livenessProbe.
                         * @member {k8s.io.api.core.v1.IProbe|null|undefined} livenessProbe
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.livenessProbe = null;

                        /**
                         * EphemeralContainerCommon readinessProbe.
                         * @member {k8s.io.api.core.v1.IProbe|null|undefined} readinessProbe
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.readinessProbe = null;

                        /**
                         * EphemeralContainerCommon startupProbe.
                         * @member {k8s.io.api.core.v1.IProbe|null|undefined} startupProbe
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.startupProbe = null;

                        /**
                         * EphemeralContainerCommon lifecycle.
                         * @member {k8s.io.api.core.v1.ILifecycle|null|undefined} lifecycle
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.lifecycle = null;

                        /**
                         * EphemeralContainerCommon terminationMessagePath.
                         * @member {string} terminationMessagePath
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.terminationMessagePath = "";

                        /**
                         * EphemeralContainerCommon terminationMessagePolicy.
                         * @member {string} terminationMessagePolicy
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.terminationMessagePolicy = "";

                        /**
                         * EphemeralContainerCommon imagePullPolicy.
                         * @member {string} imagePullPolicy
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.imagePullPolicy = "";

                        /**
                         * EphemeralContainerCommon securityContext.
                         * @member {k8s.io.api.core.v1.ISecurityContext|null|undefined} securityContext
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.securityContext = null;

                        /**
                         * EphemeralContainerCommon stdin.
                         * @member {boolean} stdin
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.stdin = false;

                        /**
                         * EphemeralContainerCommon stdinOnce.
                         * @member {boolean} stdinOnce
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.stdinOnce = false;

                        /**
                         * EphemeralContainerCommon tty.
                         * @member {boolean} tty
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         */
                        EphemeralContainerCommon.prototype.tty = false;

                        /**
                         * Creates a new EphemeralContainerCommon instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainerCommon=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EphemeralContainerCommon} EphemeralContainerCommon instance
                         */
                        EphemeralContainerCommon.create = function create(properties) {
                            return new EphemeralContainerCommon(properties);
                        };

                        /**
                         * Encodes the specified EphemeralContainerCommon message. Does not implicitly {@link k8s.io.api.core.v1.EphemeralContainerCommon.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainerCommon} message EphemeralContainerCommon message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralContainerCommon.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.image);
                            if (message.command != null && message.command.length)
                                for (var i = 0; i < message.command.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.command[i]);
                            if (message.args != null && message.args.length)
                                for (var i = 0; i < message.args.length; ++i)
                                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.args[i]);
                            if (message.workingDir != null && Object.hasOwnProperty.call(message, "workingDir"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.workingDir);
                            if (message.ports != null && message.ports.length)
                                for (var i = 0; i < message.ports.length; ++i)
                                    $root.k8s.io.api.core.v1.ContainerPort.encode(message.ports[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.env != null && message.env.length)
                                for (var i = 0; i < message.env.length; ++i)
                                    $root.k8s.io.api.core.v1.EnvVar.encode(message.env[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.resources != null && Object.hasOwnProperty.call(message, "resources"))
                                $root.k8s.io.api.core.v1.ResourceRequirements.encode(message.resources, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.volumeMounts != null && message.volumeMounts.length)
                                for (var i = 0; i < message.volumeMounts.length; ++i)
                                    $root.k8s.io.api.core.v1.VolumeMount.encode(message.volumeMounts[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.livenessProbe != null && Object.hasOwnProperty.call(message, "livenessProbe"))
                                $root.k8s.io.api.core.v1.Probe.encode(message.livenessProbe, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.readinessProbe != null && Object.hasOwnProperty.call(message, "readinessProbe"))
                                $root.k8s.io.api.core.v1.Probe.encode(message.readinessProbe, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.lifecycle != null && Object.hasOwnProperty.call(message, "lifecycle"))
                                $root.k8s.io.api.core.v1.Lifecycle.encode(message.lifecycle, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.terminationMessagePath != null && Object.hasOwnProperty.call(message, "terminationMessagePath"))
                                writer.uint32(/* id 13, wireType 2 =*/106).string(message.terminationMessagePath);
                            if (message.imagePullPolicy != null && Object.hasOwnProperty.call(message, "imagePullPolicy"))
                                writer.uint32(/* id 14, wireType 2 =*/114).string(message.imagePullPolicy);
                            if (message.securityContext != null && Object.hasOwnProperty.call(message, "securityContext"))
                                $root.k8s.io.api.core.v1.SecurityContext.encode(message.securityContext, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.stdin != null && Object.hasOwnProperty.call(message, "stdin"))
                                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.stdin);
                            if (message.stdinOnce != null && Object.hasOwnProperty.call(message, "stdinOnce"))
                                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.stdinOnce);
                            if (message.tty != null && Object.hasOwnProperty.call(message, "tty"))
                                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.tty);
                            if (message.envFrom != null && message.envFrom.length)
                                for (var i = 0; i < message.envFrom.length; ++i)
                                    $root.k8s.io.api.core.v1.EnvFromSource.encode(message.envFrom[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.terminationMessagePolicy != null && Object.hasOwnProperty.call(message, "terminationMessagePolicy"))
                                writer.uint32(/* id 20, wireType 2 =*/162).string(message.terminationMessagePolicy);
                            if (message.volumeDevices != null && message.volumeDevices.length)
                                for (var i = 0; i < message.volumeDevices.length; ++i)
                                    $root.k8s.io.api.core.v1.VolumeDevice.encode(message.volumeDevices[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.startupProbe != null && Object.hasOwnProperty.call(message, "startupProbe"))
                                $root.k8s.io.api.core.v1.Probe.encode(message.startupProbe, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EphemeralContainerCommon message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EphemeralContainerCommon.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainerCommon} message EphemeralContainerCommon message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralContainerCommon.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EphemeralContainerCommon message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EphemeralContainerCommon} EphemeralContainerCommon
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralContainerCommon.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EphemeralContainerCommon();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.image = reader.string();
                                    break;
                                case 3:
                                    if (!(message.command && message.command.length))
                                        message.command = [];
                                    message.command.push(reader.string());
                                    break;
                                case 4:
                                    if (!(message.args && message.args.length))
                                        message.args = [];
                                    message.args.push(reader.string());
                                    break;
                                case 5:
                                    message.workingDir = reader.string();
                                    break;
                                case 6:
                                    if (!(message.ports && message.ports.length))
                                        message.ports = [];
                                    message.ports.push($root.k8s.io.api.core.v1.ContainerPort.decode(reader, reader.uint32()));
                                    break;
                                case 19:
                                    if (!(message.envFrom && message.envFrom.length))
                                        message.envFrom = [];
                                    message.envFrom.push($root.k8s.io.api.core.v1.EnvFromSource.decode(reader, reader.uint32()));
                                    break;
                                case 7:
                                    if (!(message.env && message.env.length))
                                        message.env = [];
                                    message.env.push($root.k8s.io.api.core.v1.EnvVar.decode(reader, reader.uint32()));
                                    break;
                                case 8:
                                    message.resources = $root.k8s.io.api.core.v1.ResourceRequirements.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    if (!(message.volumeMounts && message.volumeMounts.length))
                                        message.volumeMounts = [];
                                    message.volumeMounts.push($root.k8s.io.api.core.v1.VolumeMount.decode(reader, reader.uint32()));
                                    break;
                                case 21:
                                    if (!(message.volumeDevices && message.volumeDevices.length))
                                        message.volumeDevices = [];
                                    message.volumeDevices.push($root.k8s.io.api.core.v1.VolumeDevice.decode(reader, reader.uint32()));
                                    break;
                                case 10:
                                    message.livenessProbe = $root.k8s.io.api.core.v1.Probe.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.readinessProbe = $root.k8s.io.api.core.v1.Probe.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.startupProbe = $root.k8s.io.api.core.v1.Probe.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.lifecycle = $root.k8s.io.api.core.v1.Lifecycle.decode(reader, reader.uint32());
                                    break;
                                case 13:
                                    message.terminationMessagePath = reader.string();
                                    break;
                                case 20:
                                    message.terminationMessagePolicy = reader.string();
                                    break;
                                case 14:
                                    message.imagePullPolicy = reader.string();
                                    break;
                                case 15:
                                    message.securityContext = $root.k8s.io.api.core.v1.SecurityContext.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.stdin = reader.bool();
                                    break;
                                case 17:
                                    message.stdinOnce = reader.bool();
                                    break;
                                case 18:
                                    message.tty = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EphemeralContainerCommon message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EphemeralContainerCommon} EphemeralContainerCommon
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralContainerCommon.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EphemeralContainerCommon message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EphemeralContainerCommon.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.image != null && message.hasOwnProperty("image"))
                                if (!$util.isString(message.image))
                                    return "image: string expected";
                            if (message.command != null && message.hasOwnProperty("command")) {
                                if (!Array.isArray(message.command))
                                    return "command: array expected";
                                for (var i = 0; i < message.command.length; ++i)
                                    if (!$util.isString(message.command[i]))
                                        return "command: string[] expected";
                            }
                            if (message.args != null && message.hasOwnProperty("args")) {
                                if (!Array.isArray(message.args))
                                    return "args: array expected";
                                for (var i = 0; i < message.args.length; ++i)
                                    if (!$util.isString(message.args[i]))
                                        return "args: string[] expected";
                            }
                            if (message.workingDir != null && message.hasOwnProperty("workingDir"))
                                if (!$util.isString(message.workingDir))
                                    return "workingDir: string expected";
                            if (message.ports != null && message.hasOwnProperty("ports")) {
                                if (!Array.isArray(message.ports))
                                    return "ports: array expected";
                                for (var i = 0; i < message.ports.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ContainerPort.verify(message.ports[i]);
                                    if (error)
                                        return "ports." + error;
                                }
                            }
                            if (message.envFrom != null && message.hasOwnProperty("envFrom")) {
                                if (!Array.isArray(message.envFrom))
                                    return "envFrom: array expected";
                                for (var i = 0; i < message.envFrom.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EnvFromSource.verify(message.envFrom[i]);
                                    if (error)
                                        return "envFrom." + error;
                                }
                            }
                            if (message.env != null && message.hasOwnProperty("env")) {
                                if (!Array.isArray(message.env))
                                    return "env: array expected";
                                for (var i = 0; i < message.env.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EnvVar.verify(message.env[i]);
                                    if (error)
                                        return "env." + error;
                                }
                            }
                            if (message.resources != null && message.hasOwnProperty("resources")) {
                                var error = $root.k8s.io.api.core.v1.ResourceRequirements.verify(message.resources);
                                if (error)
                                    return "resources." + error;
                            }
                            if (message.volumeMounts != null && message.hasOwnProperty("volumeMounts")) {
                                if (!Array.isArray(message.volumeMounts))
                                    return "volumeMounts: array expected";
                                for (var i = 0; i < message.volumeMounts.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.VolumeMount.verify(message.volumeMounts[i]);
                                    if (error)
                                        return "volumeMounts." + error;
                                }
                            }
                            if (message.volumeDevices != null && message.hasOwnProperty("volumeDevices")) {
                                if (!Array.isArray(message.volumeDevices))
                                    return "volumeDevices: array expected";
                                for (var i = 0; i < message.volumeDevices.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.VolumeDevice.verify(message.volumeDevices[i]);
                                    if (error)
                                        return "volumeDevices." + error;
                                }
                            }
                            if (message.livenessProbe != null && message.hasOwnProperty("livenessProbe")) {
                                var error = $root.k8s.io.api.core.v1.Probe.verify(message.livenessProbe);
                                if (error)
                                    return "livenessProbe." + error;
                            }
                            if (message.readinessProbe != null && message.hasOwnProperty("readinessProbe")) {
                                var error = $root.k8s.io.api.core.v1.Probe.verify(message.readinessProbe);
                                if (error)
                                    return "readinessProbe." + error;
                            }
                            if (message.startupProbe != null && message.hasOwnProperty("startupProbe")) {
                                var error = $root.k8s.io.api.core.v1.Probe.verify(message.startupProbe);
                                if (error)
                                    return "startupProbe." + error;
                            }
                            if (message.lifecycle != null && message.hasOwnProperty("lifecycle")) {
                                var error = $root.k8s.io.api.core.v1.Lifecycle.verify(message.lifecycle);
                                if (error)
                                    return "lifecycle." + error;
                            }
                            if (message.terminationMessagePath != null && message.hasOwnProperty("terminationMessagePath"))
                                if (!$util.isString(message.terminationMessagePath))
                                    return "terminationMessagePath: string expected";
                            if (message.terminationMessagePolicy != null && message.hasOwnProperty("terminationMessagePolicy"))
                                if (!$util.isString(message.terminationMessagePolicy))
                                    return "terminationMessagePolicy: string expected";
                            if (message.imagePullPolicy != null && message.hasOwnProperty("imagePullPolicy"))
                                if (!$util.isString(message.imagePullPolicy))
                                    return "imagePullPolicy: string expected";
                            if (message.securityContext != null && message.hasOwnProperty("securityContext")) {
                                var error = $root.k8s.io.api.core.v1.SecurityContext.verify(message.securityContext);
                                if (error)
                                    return "securityContext." + error;
                            }
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                if (typeof message.stdin !== "boolean")
                                    return "stdin: boolean expected";
                            if (message.stdinOnce != null && message.hasOwnProperty("stdinOnce"))
                                if (typeof message.stdinOnce !== "boolean")
                                    return "stdinOnce: boolean expected";
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                if (typeof message.tty !== "boolean")
                                    return "tty: boolean expected";
                            return null;
                        };

                        /**
                         * Creates an EphemeralContainerCommon message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EphemeralContainerCommon} EphemeralContainerCommon
                         */
                        EphemeralContainerCommon.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EphemeralContainerCommon)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EphemeralContainerCommon();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.image != null)
                                message.image = String(object.image);
                            if (object.command) {
                                if (!Array.isArray(object.command))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.command: array expected");
                                message.command = [];
                                for (var i = 0; i < object.command.length; ++i)
                                    message.command[i] = String(object.command[i]);
                            }
                            if (object.args) {
                                if (!Array.isArray(object.args))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.args: array expected");
                                message.args = [];
                                for (var i = 0; i < object.args.length; ++i)
                                    message.args[i] = String(object.args[i]);
                            }
                            if (object.workingDir != null)
                                message.workingDir = String(object.workingDir);
                            if (object.ports) {
                                if (!Array.isArray(object.ports))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.ports: array expected");
                                message.ports = [];
                                for (var i = 0; i < object.ports.length; ++i) {
                                    if (typeof object.ports[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.ports: object expected");
                                    message.ports[i] = $root.k8s.io.api.core.v1.ContainerPort.fromObject(object.ports[i]);
                                }
                            }
                            if (object.envFrom) {
                                if (!Array.isArray(object.envFrom))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.envFrom: array expected");
                                message.envFrom = [];
                                for (var i = 0; i < object.envFrom.length; ++i) {
                                    if (typeof object.envFrom[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.envFrom: object expected");
                                    message.envFrom[i] = $root.k8s.io.api.core.v1.EnvFromSource.fromObject(object.envFrom[i]);
                                }
                            }
                            if (object.env) {
                                if (!Array.isArray(object.env))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.env: array expected");
                                message.env = [];
                                for (var i = 0; i < object.env.length; ++i) {
                                    if (typeof object.env[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.env: object expected");
                                    message.env[i] = $root.k8s.io.api.core.v1.EnvVar.fromObject(object.env[i]);
                                }
                            }
                            if (object.resources != null) {
                                if (typeof object.resources !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.resources: object expected");
                                message.resources = $root.k8s.io.api.core.v1.ResourceRequirements.fromObject(object.resources);
                            }
                            if (object.volumeMounts) {
                                if (!Array.isArray(object.volumeMounts))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.volumeMounts: array expected");
                                message.volumeMounts = [];
                                for (var i = 0; i < object.volumeMounts.length; ++i) {
                                    if (typeof object.volumeMounts[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.volumeMounts: object expected");
                                    message.volumeMounts[i] = $root.k8s.io.api.core.v1.VolumeMount.fromObject(object.volumeMounts[i]);
                                }
                            }
                            if (object.volumeDevices) {
                                if (!Array.isArray(object.volumeDevices))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.volumeDevices: array expected");
                                message.volumeDevices = [];
                                for (var i = 0; i < object.volumeDevices.length; ++i) {
                                    if (typeof object.volumeDevices[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.volumeDevices: object expected");
                                    message.volumeDevices[i] = $root.k8s.io.api.core.v1.VolumeDevice.fromObject(object.volumeDevices[i]);
                                }
                            }
                            if (object.livenessProbe != null) {
                                if (typeof object.livenessProbe !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.livenessProbe: object expected");
                                message.livenessProbe = $root.k8s.io.api.core.v1.Probe.fromObject(object.livenessProbe);
                            }
                            if (object.readinessProbe != null) {
                                if (typeof object.readinessProbe !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.readinessProbe: object expected");
                                message.readinessProbe = $root.k8s.io.api.core.v1.Probe.fromObject(object.readinessProbe);
                            }
                            if (object.startupProbe != null) {
                                if (typeof object.startupProbe !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.startupProbe: object expected");
                                message.startupProbe = $root.k8s.io.api.core.v1.Probe.fromObject(object.startupProbe);
                            }
                            if (object.lifecycle != null) {
                                if (typeof object.lifecycle !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.lifecycle: object expected");
                                message.lifecycle = $root.k8s.io.api.core.v1.Lifecycle.fromObject(object.lifecycle);
                            }
                            if (object.terminationMessagePath != null)
                                message.terminationMessagePath = String(object.terminationMessagePath);
                            if (object.terminationMessagePolicy != null)
                                message.terminationMessagePolicy = String(object.terminationMessagePolicy);
                            if (object.imagePullPolicy != null)
                                message.imagePullPolicy = String(object.imagePullPolicy);
                            if (object.securityContext != null) {
                                if (typeof object.securityContext !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainerCommon.securityContext: object expected");
                                message.securityContext = $root.k8s.io.api.core.v1.SecurityContext.fromObject(object.securityContext);
                            }
                            if (object.stdin != null)
                                message.stdin = Boolean(object.stdin);
                            if (object.stdinOnce != null)
                                message.stdinOnce = Boolean(object.stdinOnce);
                            if (object.tty != null)
                                message.tty = Boolean(object.tty);
                            return message;
                        };

                        /**
                         * Creates a plain object from an EphemeralContainerCommon message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @static
                         * @param {k8s.io.api.core.v1.EphemeralContainerCommon} message EphemeralContainerCommon
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EphemeralContainerCommon.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.command = [];
                                object.args = [];
                                object.ports = [];
                                object.env = [];
                                object.volumeMounts = [];
                                object.envFrom = [];
                                object.volumeDevices = [];
                            }
                            if (options.defaults) {
                                object.name = "";
                                object.image = "";
                                object.workingDir = "";
                                object.resources = null;
                                object.livenessProbe = null;
                                object.readinessProbe = null;
                                object.lifecycle = null;
                                object.terminationMessagePath = "";
                                object.imagePullPolicy = "";
                                object.securityContext = null;
                                object.stdin = false;
                                object.stdinOnce = false;
                                object.tty = false;
                                object.terminationMessagePolicy = "";
                                object.startupProbe = null;
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.image != null && message.hasOwnProperty("image"))
                                object.image = message.image;
                            if (message.command && message.command.length) {
                                object.command = [];
                                for (var j = 0; j < message.command.length; ++j)
                                    object.command[j] = message.command[j];
                            }
                            if (message.args && message.args.length) {
                                object.args = [];
                                for (var j = 0; j < message.args.length; ++j)
                                    object.args[j] = message.args[j];
                            }
                            if (message.workingDir != null && message.hasOwnProperty("workingDir"))
                                object.workingDir = message.workingDir;
                            if (message.ports && message.ports.length) {
                                object.ports = [];
                                for (var j = 0; j < message.ports.length; ++j)
                                    object.ports[j] = $root.k8s.io.api.core.v1.ContainerPort.toObject(message.ports[j], options);
                            }
                            if (message.env && message.env.length) {
                                object.env = [];
                                for (var j = 0; j < message.env.length; ++j)
                                    object.env[j] = $root.k8s.io.api.core.v1.EnvVar.toObject(message.env[j], options);
                            }
                            if (message.resources != null && message.hasOwnProperty("resources"))
                                object.resources = $root.k8s.io.api.core.v1.ResourceRequirements.toObject(message.resources, options);
                            if (message.volumeMounts && message.volumeMounts.length) {
                                object.volumeMounts = [];
                                for (var j = 0; j < message.volumeMounts.length; ++j)
                                    object.volumeMounts[j] = $root.k8s.io.api.core.v1.VolumeMount.toObject(message.volumeMounts[j], options);
                            }
                            if (message.livenessProbe != null && message.hasOwnProperty("livenessProbe"))
                                object.livenessProbe = $root.k8s.io.api.core.v1.Probe.toObject(message.livenessProbe, options);
                            if (message.readinessProbe != null && message.hasOwnProperty("readinessProbe"))
                                object.readinessProbe = $root.k8s.io.api.core.v1.Probe.toObject(message.readinessProbe, options);
                            if (message.lifecycle != null && message.hasOwnProperty("lifecycle"))
                                object.lifecycle = $root.k8s.io.api.core.v1.Lifecycle.toObject(message.lifecycle, options);
                            if (message.terminationMessagePath != null && message.hasOwnProperty("terminationMessagePath"))
                                object.terminationMessagePath = message.terminationMessagePath;
                            if (message.imagePullPolicy != null && message.hasOwnProperty("imagePullPolicy"))
                                object.imagePullPolicy = message.imagePullPolicy;
                            if (message.securityContext != null && message.hasOwnProperty("securityContext"))
                                object.securityContext = $root.k8s.io.api.core.v1.SecurityContext.toObject(message.securityContext, options);
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                object.stdin = message.stdin;
                            if (message.stdinOnce != null && message.hasOwnProperty("stdinOnce"))
                                object.stdinOnce = message.stdinOnce;
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                object.tty = message.tty;
                            if (message.envFrom && message.envFrom.length) {
                                object.envFrom = [];
                                for (var j = 0; j < message.envFrom.length; ++j)
                                    object.envFrom[j] = $root.k8s.io.api.core.v1.EnvFromSource.toObject(message.envFrom[j], options);
                            }
                            if (message.terminationMessagePolicy != null && message.hasOwnProperty("terminationMessagePolicy"))
                                object.terminationMessagePolicy = message.terminationMessagePolicy;
                            if (message.volumeDevices && message.volumeDevices.length) {
                                object.volumeDevices = [];
                                for (var j = 0; j < message.volumeDevices.length; ++j)
                                    object.volumeDevices[j] = $root.k8s.io.api.core.v1.VolumeDevice.toObject(message.volumeDevices[j], options);
                            }
                            if (message.startupProbe != null && message.hasOwnProperty("startupProbe"))
                                object.startupProbe = $root.k8s.io.api.core.v1.Probe.toObject(message.startupProbe, options);
                            return object;
                        };

                        /**
                         * Converts this EphemeralContainerCommon to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EphemeralContainerCommon
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EphemeralContainerCommon.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EphemeralContainerCommon;
                    })();

                    v1.EphemeralContainers = (function() {

                        /**
                         * Properties of an EphemeralContainers.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEphemeralContainers
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] EphemeralContainers metadata
                         * @property {Array.<k8s.io.api.core.v1.IEphemeralContainer>|null} [ephemeralContainers] EphemeralContainers ephemeralContainers
                         */

                        /**
                         * Constructs a new EphemeralContainers.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EphemeralContainers.
                         * @implements IEphemeralContainers
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEphemeralContainers=} [properties] Properties to set
                         */
                        function EphemeralContainers(properties) {
                            this.ephemeralContainers = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EphemeralContainers metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @instance
                         */
                        EphemeralContainers.prototype.metadata = null;

                        /**
                         * EphemeralContainers ephemeralContainers.
                         * @member {Array.<k8s.io.api.core.v1.IEphemeralContainer>} ephemeralContainers
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @instance
                         */
                        EphemeralContainers.prototype.ephemeralContainers = $util.emptyArray;

                        /**
                         * Creates a new EphemeralContainers instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainers=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EphemeralContainers} EphemeralContainers instance
                         */
                        EphemeralContainers.create = function create(properties) {
                            return new EphemeralContainers(properties);
                        };

                        /**
                         * Encodes the specified EphemeralContainers message. Does not implicitly {@link k8s.io.api.core.v1.EphemeralContainers.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainers} message EphemeralContainers message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralContainers.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.ephemeralContainers != null && message.ephemeralContainers.length)
                                for (var i = 0; i < message.ephemeralContainers.length; ++i)
                                    $root.k8s.io.api.core.v1.EphemeralContainer.encode(message.ephemeralContainers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EphemeralContainers message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EphemeralContainers.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralContainers} message EphemeralContainers message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralContainers.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EphemeralContainers message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EphemeralContainers} EphemeralContainers
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralContainers.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EphemeralContainers();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.ephemeralContainers && message.ephemeralContainers.length))
                                        message.ephemeralContainers = [];
                                    message.ephemeralContainers.push($root.k8s.io.api.core.v1.EphemeralContainer.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EphemeralContainers message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EphemeralContainers} EphemeralContainers
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralContainers.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EphemeralContainers message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EphemeralContainers.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.ephemeralContainers != null && message.hasOwnProperty("ephemeralContainers")) {
                                if (!Array.isArray(message.ephemeralContainers))
                                    return "ephemeralContainers: array expected";
                                for (var i = 0; i < message.ephemeralContainers.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EphemeralContainer.verify(message.ephemeralContainers[i]);
                                    if (error)
                                        return "ephemeralContainers." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an EphemeralContainers message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EphemeralContainers} EphemeralContainers
                         */
                        EphemeralContainers.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EphemeralContainers)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EphemeralContainers();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainers.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.ephemeralContainers) {
                                if (!Array.isArray(object.ephemeralContainers))
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralContainers.ephemeralContainers: array expected");
                                message.ephemeralContainers = [];
                                for (var i = 0; i < object.ephemeralContainers.length; ++i) {
                                    if (typeof object.ephemeralContainers[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EphemeralContainers.ephemeralContainers: object expected");
                                    message.ephemeralContainers[i] = $root.k8s.io.api.core.v1.EphemeralContainer.fromObject(object.ephemeralContainers[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EphemeralContainers message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @static
                         * @param {k8s.io.api.core.v1.EphemeralContainers} message EphemeralContainers
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EphemeralContainers.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.ephemeralContainers = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.ephemeralContainers && message.ephemeralContainers.length) {
                                object.ephemeralContainers = [];
                                for (var j = 0; j < message.ephemeralContainers.length; ++j)
                                    object.ephemeralContainers[j] = $root.k8s.io.api.core.v1.EphemeralContainer.toObject(message.ephemeralContainers[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this EphemeralContainers to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EphemeralContainers
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EphemeralContainers.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EphemeralContainers;
                    })();

                    v1.EphemeralVolumeSource = (function() {

                        /**
                         * Properties of an EphemeralVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEphemeralVolumeSource
                         * @property {k8s.io.api.core.v1.IPersistentVolumeClaimTemplate|null} [volumeClaimTemplate] EphemeralVolumeSource volumeClaimTemplate
                         * @property {boolean|null} [readOnly] EphemeralVolumeSource readOnly
                         */

                        /**
                         * Constructs a new EphemeralVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EphemeralVolumeSource.
                         * @implements IEphemeralVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEphemeralVolumeSource=} [properties] Properties to set
                         */
                        function EphemeralVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EphemeralVolumeSource volumeClaimTemplate.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeClaimTemplate|null|undefined} volumeClaimTemplate
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @instance
                         */
                        EphemeralVolumeSource.prototype.volumeClaimTemplate = null;

                        /**
                         * EphemeralVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @instance
                         */
                        EphemeralVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new EphemeralVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EphemeralVolumeSource} EphemeralVolumeSource instance
                         */
                        EphemeralVolumeSource.create = function create(properties) {
                            return new EphemeralVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified EphemeralVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.EphemeralVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralVolumeSource} message EphemeralVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeClaimTemplate != null && Object.hasOwnProperty.call(message, "volumeClaimTemplate"))
                                $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate.encode(message.volumeClaimTemplate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified EphemeralVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EphemeralVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEphemeralVolumeSource} message EphemeralVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EphemeralVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EphemeralVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EphemeralVolumeSource} EphemeralVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EphemeralVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeClaimTemplate = $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EphemeralVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EphemeralVolumeSource} EphemeralVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EphemeralVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EphemeralVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EphemeralVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeClaimTemplate != null && message.hasOwnProperty("volumeClaimTemplate")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate.verify(message.volumeClaimTemplate);
                                if (error)
                                    return "volumeClaimTemplate." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates an EphemeralVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EphemeralVolumeSource} EphemeralVolumeSource
                         */
                        EphemeralVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EphemeralVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EphemeralVolumeSource();
                            if (object.volumeClaimTemplate != null) {
                                if (typeof object.volumeClaimTemplate !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EphemeralVolumeSource.volumeClaimTemplate: object expected");
                                message.volumeClaimTemplate = $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate.fromObject(object.volumeClaimTemplate);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from an EphemeralVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.EphemeralVolumeSource} message EphemeralVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EphemeralVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeClaimTemplate = null;
                                object.readOnly = false;
                            }
                            if (message.volumeClaimTemplate != null && message.hasOwnProperty("volumeClaimTemplate"))
                                object.volumeClaimTemplate = $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate.toObject(message.volumeClaimTemplate, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this EphemeralVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EphemeralVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EphemeralVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EphemeralVolumeSource;
                    })();

                    v1.Event = (function() {

                        /**
                         * Properties of an Event.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEvent
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Event metadata
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [involvedObject] Event involvedObject
                         * @property {string|null} [reason] Event reason
                         * @property {string|null} [message] Event message
                         * @property {k8s.io.api.core.v1.IEventSource|null} [source] Event source
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [firstTimestamp] Event firstTimestamp
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTimestamp] Event lastTimestamp
                         * @property {number|null} [count] Event count
                         * @property {string|null} [type] Event type
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime|null} [eventTime] Event eventTime
                         * @property {k8s.io.api.core.v1.IEventSeries|null} [series] Event series
                         * @property {string|null} [action] Event action
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [related] Event related
                         * @property {string|null} [reportingComponent] Event reportingComponent
                         * @property {string|null} [reportingInstance] Event reportingInstance
                         */

                        /**
                         * Constructs a new Event.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an Event.
                         * @implements IEvent
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEvent=} [properties] Properties to set
                         */
                        function Event(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Event metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.metadata = null;

                        /**
                         * Event involvedObject.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} involvedObject
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.involvedObject = null;

                        /**
                         * Event reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.reason = "";

                        /**
                         * Event message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.message = "";

                        /**
                         * Event source.
                         * @member {k8s.io.api.core.v1.IEventSource|null|undefined} source
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.source = null;

                        /**
                         * Event firstTimestamp.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} firstTimestamp
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.firstTimestamp = null;

                        /**
                         * Event lastTimestamp.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTimestamp
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.lastTimestamp = null;

                        /**
                         * Event count.
                         * @member {number} count
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.count = 0;

                        /**
                         * Event type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.type = "";

                        /**
                         * Event eventTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime|null|undefined} eventTime
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.eventTime = null;

                        /**
                         * Event series.
                         * @member {k8s.io.api.core.v1.IEventSeries|null|undefined} series
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.series = null;

                        /**
                         * Event action.
                         * @member {string} action
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.action = "";

                        /**
                         * Event related.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} related
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.related = null;

                        /**
                         * Event reportingComponent.
                         * @member {string} reportingComponent
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.reportingComponent = "";

                        /**
                         * Event reportingInstance.
                         * @member {string} reportingInstance
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         */
                        Event.prototype.reportingInstance = "";

                        /**
                         * Creates a new Event instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {k8s.io.api.core.v1.IEvent=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Event} Event instance
                         */
                        Event.create = function create(properties) {
                            return new Event(properties);
                        };

                        /**
                         * Encodes the specified Event message. Does not implicitly {@link k8s.io.api.core.v1.Event.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {k8s.io.api.core.v1.IEvent} message Event message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Event.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.involvedObject != null && Object.hasOwnProperty.call(message, "involvedObject"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.involvedObject, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
                            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                                $root.k8s.io.api.core.v1.EventSource.encode(message.source, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.firstTimestamp != null && Object.hasOwnProperty.call(message, "firstTimestamp"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.firstTimestamp, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.lastTimestamp != null && Object.hasOwnProperty.call(message, "lastTimestamp"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTimestamp, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.count);
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
                            if (message.eventTime != null && Object.hasOwnProperty.call(message, "eventTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.encode(message.eventTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.series != null && Object.hasOwnProperty.call(message, "series"))
                                $root.k8s.io.api.core.v1.EventSeries.encode(message.series, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                                writer.uint32(/* id 12, wireType 2 =*/98).string(message.action);
                            if (message.related != null && Object.hasOwnProperty.call(message, "related"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.related, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.reportingComponent != null && Object.hasOwnProperty.call(message, "reportingComponent"))
                                writer.uint32(/* id 14, wireType 2 =*/114).string(message.reportingComponent);
                            if (message.reportingInstance != null && Object.hasOwnProperty.call(message, "reportingInstance"))
                                writer.uint32(/* id 15, wireType 2 =*/122).string(message.reportingInstance);
                            return writer;
                        };

                        /**
                         * Encodes the specified Event message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Event.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {k8s.io.api.core.v1.IEvent} message Event message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Event.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Event message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Event} Event
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Event.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Event();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.involvedObject = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.reason = reader.string();
                                    break;
                                case 4:
                                    message.message = reader.string();
                                    break;
                                case 5:
                                    message.source = $root.k8s.io.api.core.v1.EventSource.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.firstTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.lastTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.count = reader.int32();
                                    break;
                                case 9:
                                    message.type = reader.string();
                                    break;
                                case 10:
                                    message.eventTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.series = $root.k8s.io.api.core.v1.EventSeries.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.action = reader.string();
                                    break;
                                case 13:
                                    message.related = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.reportingComponent = reader.string();
                                    break;
                                case 15:
                                    message.reportingInstance = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Event message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Event} Event
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Event.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Event message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Event.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.involvedObject != null && message.hasOwnProperty("involvedObject")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.involvedObject);
                                if (error)
                                    return "involvedObject." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            if (message.source != null && message.hasOwnProperty("source")) {
                                var error = $root.k8s.io.api.core.v1.EventSource.verify(message.source);
                                if (error)
                                    return "source." + error;
                            }
                            if (message.firstTimestamp != null && message.hasOwnProperty("firstTimestamp")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.firstTimestamp);
                                if (error)
                                    return "firstTimestamp." + error;
                            }
                            if (message.lastTimestamp != null && message.hasOwnProperty("lastTimestamp")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTimestamp);
                                if (error)
                                    return "lastTimestamp." + error;
                            }
                            if (message.count != null && message.hasOwnProperty("count"))
                                if (!$util.isInteger(message.count))
                                    return "count: integer expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.eventTime != null && message.hasOwnProperty("eventTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.verify(message.eventTime);
                                if (error)
                                    return "eventTime." + error;
                            }
                            if (message.series != null && message.hasOwnProperty("series")) {
                                var error = $root.k8s.io.api.core.v1.EventSeries.verify(message.series);
                                if (error)
                                    return "series." + error;
                            }
                            if (message.action != null && message.hasOwnProperty("action"))
                                if (!$util.isString(message.action))
                                    return "action: string expected";
                            if (message.related != null && message.hasOwnProperty("related")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.related);
                                if (error)
                                    return "related." + error;
                            }
                            if (message.reportingComponent != null && message.hasOwnProperty("reportingComponent"))
                                if (!$util.isString(message.reportingComponent))
                                    return "reportingComponent: string expected";
                            if (message.reportingInstance != null && message.hasOwnProperty("reportingInstance"))
                                if (!$util.isString(message.reportingInstance))
                                    return "reportingInstance: string expected";
                            return null;
                        };

                        /**
                         * Creates an Event message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Event} Event
                         */
                        Event.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Event)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Event();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.involvedObject != null) {
                                if (typeof object.involvedObject !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.involvedObject: object expected");
                                message.involvedObject = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.involvedObject);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            if (object.source != null) {
                                if (typeof object.source !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.source: object expected");
                                message.source = $root.k8s.io.api.core.v1.EventSource.fromObject(object.source);
                            }
                            if (object.firstTimestamp != null) {
                                if (typeof object.firstTimestamp !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.firstTimestamp: object expected");
                                message.firstTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.firstTimestamp);
                            }
                            if (object.lastTimestamp != null) {
                                if (typeof object.lastTimestamp !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.lastTimestamp: object expected");
                                message.lastTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTimestamp);
                            }
                            if (object.count != null)
                                message.count = object.count | 0;
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.eventTime != null) {
                                if (typeof object.eventTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.eventTime: object expected");
                                message.eventTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.fromObject(object.eventTime);
                            }
                            if (object.series != null) {
                                if (typeof object.series !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.series: object expected");
                                message.series = $root.k8s.io.api.core.v1.EventSeries.fromObject(object.series);
                            }
                            if (object.action != null)
                                message.action = String(object.action);
                            if (object.related != null) {
                                if (typeof object.related !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Event.related: object expected");
                                message.related = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.related);
                            }
                            if (object.reportingComponent != null)
                                message.reportingComponent = String(object.reportingComponent);
                            if (object.reportingInstance != null)
                                message.reportingInstance = String(object.reportingInstance);
                            return message;
                        };

                        /**
                         * Creates a plain object from an Event message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Event
                         * @static
                         * @param {k8s.io.api.core.v1.Event} message Event
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Event.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.involvedObject = null;
                                object.reason = "";
                                object.message = "";
                                object.source = null;
                                object.firstTimestamp = null;
                                object.lastTimestamp = null;
                                object.count = 0;
                                object.type = "";
                                object.eventTime = null;
                                object.series = null;
                                object.action = "";
                                object.related = null;
                                object.reportingComponent = "";
                                object.reportingInstance = "";
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.involvedObject != null && message.hasOwnProperty("involvedObject"))
                                object.involvedObject = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.involvedObject, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            if (message.source != null && message.hasOwnProperty("source"))
                                object.source = $root.k8s.io.api.core.v1.EventSource.toObject(message.source, options);
                            if (message.firstTimestamp != null && message.hasOwnProperty("firstTimestamp"))
                                object.firstTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.firstTimestamp, options);
                            if (message.lastTimestamp != null && message.hasOwnProperty("lastTimestamp"))
                                object.lastTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTimestamp, options);
                            if (message.count != null && message.hasOwnProperty("count"))
                                object.count = message.count;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.eventTime != null && message.hasOwnProperty("eventTime"))
                                object.eventTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.toObject(message.eventTime, options);
                            if (message.series != null && message.hasOwnProperty("series"))
                                object.series = $root.k8s.io.api.core.v1.EventSeries.toObject(message.series, options);
                            if (message.action != null && message.hasOwnProperty("action"))
                                object.action = message.action;
                            if (message.related != null && message.hasOwnProperty("related"))
                                object.related = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.related, options);
                            if (message.reportingComponent != null && message.hasOwnProperty("reportingComponent"))
                                object.reportingComponent = message.reportingComponent;
                            if (message.reportingInstance != null && message.hasOwnProperty("reportingInstance"))
                                object.reportingInstance = message.reportingInstance;
                            return object;
                        };

                        /**
                         * Converts this Event to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Event
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Event.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Event;
                    })();

                    v1.EventList = (function() {

                        /**
                         * Properties of an EventList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEventList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] EventList metadata
                         * @property {Array.<k8s.io.api.core.v1.IEvent>|null} [items] EventList items
                         */

                        /**
                         * Constructs a new EventList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EventList.
                         * @implements IEventList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEventList=} [properties] Properties to set
                         */
                        function EventList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EventList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.EventList
                         * @instance
                         */
                        EventList.prototype.metadata = null;

                        /**
                         * EventList items.
                         * @member {Array.<k8s.io.api.core.v1.IEvent>} items
                         * @memberof k8s.io.api.core.v1.EventList
                         * @instance
                         */
                        EventList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new EventList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {k8s.io.api.core.v1.IEventList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EventList} EventList instance
                         */
                        EventList.create = function create(properties) {
                            return new EventList(properties);
                        };

                        /**
                         * Encodes the specified EventList message. Does not implicitly {@link k8s.io.api.core.v1.EventList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {k8s.io.api.core.v1.IEventList} message EventList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EventList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Event.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EventList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EventList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {k8s.io.api.core.v1.IEventList} message EventList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EventList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EventList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EventList} EventList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EventList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EventList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Event.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EventList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EventList} EventList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EventList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EventList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EventList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Event.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an EventList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EventList} EventList
                         */
                        EventList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EventList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EventList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EventList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.EventList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.EventList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Event.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EventList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EventList
                         * @static
                         * @param {k8s.io.api.core.v1.EventList} message EventList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EventList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Event.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this EventList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EventList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EventList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EventList;
                    })();

                    v1.EventSeries = (function() {

                        /**
                         * Properties of an EventSeries.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEventSeries
                         * @property {number|null} [count] EventSeries count
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime|null} [lastObservedTime] EventSeries lastObservedTime
                         */

                        /**
                         * Constructs a new EventSeries.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EventSeries.
                         * @implements IEventSeries
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEventSeries=} [properties] Properties to set
                         */
                        function EventSeries(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EventSeries count.
                         * @member {number} count
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @instance
                         */
                        EventSeries.prototype.count = 0;

                        /**
                         * EventSeries lastObservedTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime|null|undefined} lastObservedTime
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @instance
                         */
                        EventSeries.prototype.lastObservedTime = null;

                        /**
                         * Creates a new EventSeries instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {k8s.io.api.core.v1.IEventSeries=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EventSeries} EventSeries instance
                         */
                        EventSeries.create = function create(properties) {
                            return new EventSeries(properties);
                        };

                        /**
                         * Encodes the specified EventSeries message. Does not implicitly {@link k8s.io.api.core.v1.EventSeries.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {k8s.io.api.core.v1.IEventSeries} message EventSeries message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EventSeries.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
                            if (message.lastObservedTime != null && Object.hasOwnProperty.call(message, "lastObservedTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.encode(message.lastObservedTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified EventSeries message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EventSeries.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {k8s.io.api.core.v1.IEventSeries} message EventSeries message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EventSeries.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EventSeries message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EventSeries} EventSeries
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EventSeries.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EventSeries();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.count = reader.int32();
                                    break;
                                case 2:
                                    message.lastObservedTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EventSeries message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EventSeries} EventSeries
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EventSeries.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EventSeries message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EventSeries.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.count != null && message.hasOwnProperty("count"))
                                if (!$util.isInteger(message.count))
                                    return "count: integer expected";
                            if (message.lastObservedTime != null && message.hasOwnProperty("lastObservedTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.verify(message.lastObservedTime);
                                if (error)
                                    return "lastObservedTime." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an EventSeries message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EventSeries} EventSeries
                         */
                        EventSeries.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EventSeries)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EventSeries();
                            if (object.count != null)
                                message.count = object.count | 0;
                            if (object.lastObservedTime != null) {
                                if (typeof object.lastObservedTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.EventSeries.lastObservedTime: object expected");
                                message.lastObservedTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.fromObject(object.lastObservedTime);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an EventSeries message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @static
                         * @param {k8s.io.api.core.v1.EventSeries} message EventSeries
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EventSeries.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.count = 0;
                                object.lastObservedTime = null;
                            }
                            if (message.count != null && message.hasOwnProperty("count"))
                                object.count = message.count;
                            if (message.lastObservedTime != null && message.hasOwnProperty("lastObservedTime"))
                                object.lastObservedTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.toObject(message.lastObservedTime, options);
                            return object;
                        };

                        /**
                         * Converts this EventSeries to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EventSeries
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EventSeries.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EventSeries;
                    })();

                    v1.EventSource = (function() {

                        /**
                         * Properties of an EventSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IEventSource
                         * @property {string|null} [component] EventSource component
                         * @property {string|null} [host] EventSource host
                         */

                        /**
                         * Constructs a new EventSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an EventSource.
                         * @implements IEventSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IEventSource=} [properties] Properties to set
                         */
                        function EventSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EventSource component.
                         * @member {string} component
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @instance
                         */
                        EventSource.prototype.component = "";

                        /**
                         * EventSource host.
                         * @member {string} host
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @instance
                         */
                        EventSource.prototype.host = "";

                        /**
                         * Creates a new EventSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEventSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.EventSource} EventSource instance
                         */
                        EventSource.create = function create(properties) {
                            return new EventSource(properties);
                        };

                        /**
                         * Encodes the specified EventSource message. Does not implicitly {@link k8s.io.api.core.v1.EventSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEventSource} message EventSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EventSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.component != null && Object.hasOwnProperty.call(message, "component"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.component);
                            if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.host);
                            return writer;
                        };

                        /**
                         * Encodes the specified EventSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.EventSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {k8s.io.api.core.v1.IEventSource} message EventSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EventSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EventSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.EventSource} EventSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EventSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.EventSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.component = reader.string();
                                    break;
                                case 2:
                                    message.host = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EventSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.EventSource} EventSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EventSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EventSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EventSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.component != null && message.hasOwnProperty("component"))
                                if (!$util.isString(message.component))
                                    return "component: string expected";
                            if (message.host != null && message.hasOwnProperty("host"))
                                if (!$util.isString(message.host))
                                    return "host: string expected";
                            return null;
                        };

                        /**
                         * Creates an EventSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.EventSource} EventSource
                         */
                        EventSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.EventSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.EventSource();
                            if (object.component != null)
                                message.component = String(object.component);
                            if (object.host != null)
                                message.host = String(object.host);
                            return message;
                        };

                        /**
                         * Creates a plain object from an EventSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @static
                         * @param {k8s.io.api.core.v1.EventSource} message EventSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EventSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.component = "";
                                object.host = "";
                            }
                            if (message.component != null && message.hasOwnProperty("component"))
                                object.component = message.component;
                            if (message.host != null && message.hasOwnProperty("host"))
                                object.host = message.host;
                            return object;
                        };

                        /**
                         * Converts this EventSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.EventSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EventSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return EventSource;
                    })();

                    v1.ExecAction = (function() {

                        /**
                         * Properties of an ExecAction.
                         * @memberof k8s.io.api.core.v1
                         * @interface IExecAction
                         * @property {Array.<string>|null} [command] ExecAction command
                         */

                        /**
                         * Constructs a new ExecAction.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an ExecAction.
                         * @implements IExecAction
                         * @constructor
                         * @param {k8s.io.api.core.v1.IExecAction=} [properties] Properties to set
                         */
                        function ExecAction(properties) {
                            this.command = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ExecAction command.
                         * @member {Array.<string>} command
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @instance
                         */
                        ExecAction.prototype.command = $util.emptyArray;

                        /**
                         * Creates a new ExecAction instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {k8s.io.api.core.v1.IExecAction=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ExecAction} ExecAction instance
                         */
                        ExecAction.create = function create(properties) {
                            return new ExecAction(properties);
                        };

                        /**
                         * Encodes the specified ExecAction message. Does not implicitly {@link k8s.io.api.core.v1.ExecAction.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {k8s.io.api.core.v1.IExecAction} message ExecAction message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExecAction.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.command != null && message.command.length)
                                for (var i = 0; i < message.command.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.command[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified ExecAction message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ExecAction.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {k8s.io.api.core.v1.IExecAction} message ExecAction message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExecAction.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ExecAction message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ExecAction} ExecAction
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExecAction.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ExecAction();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.command && message.command.length))
                                        message.command = [];
                                    message.command.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ExecAction message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ExecAction} ExecAction
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExecAction.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ExecAction message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ExecAction.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.command != null && message.hasOwnProperty("command")) {
                                if (!Array.isArray(message.command))
                                    return "command: array expected";
                                for (var i = 0; i < message.command.length; ++i)
                                    if (!$util.isString(message.command[i]))
                                        return "command: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates an ExecAction message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ExecAction} ExecAction
                         */
                        ExecAction.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ExecAction)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ExecAction();
                            if (object.command) {
                                if (!Array.isArray(object.command))
                                    throw TypeError(".k8s.io.api.core.v1.ExecAction.command: array expected");
                                message.command = [];
                                for (var i = 0; i < object.command.length; ++i)
                                    message.command[i] = String(object.command[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ExecAction message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @static
                         * @param {k8s.io.api.core.v1.ExecAction} message ExecAction
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ExecAction.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.command = [];
                            if (message.command && message.command.length) {
                                object.command = [];
                                for (var j = 0; j < message.command.length; ++j)
                                    object.command[j] = message.command[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this ExecAction to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ExecAction
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ExecAction.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ExecAction;
                    })();

                    v1.FCVolumeSource = (function() {

                        /**
                         * Properties of a FCVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IFCVolumeSource
                         * @property {Array.<string>|null} [targetWWNs] FCVolumeSource targetWWNs
                         * @property {number|null} [lun] FCVolumeSource lun
                         * @property {string|null} [fsType] FCVolumeSource fsType
                         * @property {boolean|null} [readOnly] FCVolumeSource readOnly
                         * @property {Array.<string>|null} [wwids] FCVolumeSource wwids
                         */

                        /**
                         * Constructs a new FCVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a FCVolumeSource.
                         * @implements IFCVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IFCVolumeSource=} [properties] Properties to set
                         */
                        function FCVolumeSource(properties) {
                            this.targetWWNs = [];
                            this.wwids = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FCVolumeSource targetWWNs.
                         * @member {Array.<string>} targetWWNs
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @instance
                         */
                        FCVolumeSource.prototype.targetWWNs = $util.emptyArray;

                        /**
                         * FCVolumeSource lun.
                         * @member {number} lun
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @instance
                         */
                        FCVolumeSource.prototype.lun = 0;

                        /**
                         * FCVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @instance
                         */
                        FCVolumeSource.prototype.fsType = "";

                        /**
                         * FCVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @instance
                         */
                        FCVolumeSource.prototype.readOnly = false;

                        /**
                         * FCVolumeSource wwids.
                         * @member {Array.<string>} wwids
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @instance
                         */
                        FCVolumeSource.prototype.wwids = $util.emptyArray;

                        /**
                         * Creates a new FCVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFCVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.FCVolumeSource} FCVolumeSource instance
                         */
                        FCVolumeSource.create = function create(properties) {
                            return new FCVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified FCVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.FCVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFCVolumeSource} message FCVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FCVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.targetWWNs != null && message.targetWWNs.length)
                                for (var i = 0; i < message.targetWWNs.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.targetWWNs[i]);
                            if (message.lun != null && Object.hasOwnProperty.call(message, "lun"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.lun);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            if (message.wwids != null && message.wwids.length)
                                for (var i = 0; i < message.wwids.length; ++i)
                                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.wwids[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified FCVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.FCVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFCVolumeSource} message FCVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FCVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FCVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.FCVolumeSource} FCVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FCVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.FCVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.targetWWNs && message.targetWWNs.length))
                                        message.targetWWNs = [];
                                    message.targetWWNs.push(reader.string());
                                    break;
                                case 2:
                                    message.lun = reader.int32();
                                    break;
                                case 3:
                                    message.fsType = reader.string();
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                case 5:
                                    if (!(message.wwids && message.wwids.length))
                                        message.wwids = [];
                                    message.wwids.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FCVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.FCVolumeSource} FCVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FCVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FCVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FCVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.targetWWNs != null && message.hasOwnProperty("targetWWNs")) {
                                if (!Array.isArray(message.targetWWNs))
                                    return "targetWWNs: array expected";
                                for (var i = 0; i < message.targetWWNs.length; ++i)
                                    if (!$util.isString(message.targetWWNs[i]))
                                        return "targetWWNs: string[] expected";
                            }
                            if (message.lun != null && message.hasOwnProperty("lun"))
                                if (!$util.isInteger(message.lun))
                                    return "lun: integer expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.wwids != null && message.hasOwnProperty("wwids")) {
                                if (!Array.isArray(message.wwids))
                                    return "wwids: array expected";
                                for (var i = 0; i < message.wwids.length; ++i)
                                    if (!$util.isString(message.wwids[i]))
                                        return "wwids: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a FCVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.FCVolumeSource} FCVolumeSource
                         */
                        FCVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.FCVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.FCVolumeSource();
                            if (object.targetWWNs) {
                                if (!Array.isArray(object.targetWWNs))
                                    throw TypeError(".k8s.io.api.core.v1.FCVolumeSource.targetWWNs: array expected");
                                message.targetWWNs = [];
                                for (var i = 0; i < object.targetWWNs.length; ++i)
                                    message.targetWWNs[i] = String(object.targetWWNs[i]);
                            }
                            if (object.lun != null)
                                message.lun = object.lun | 0;
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.wwids) {
                                if (!Array.isArray(object.wwids))
                                    throw TypeError(".k8s.io.api.core.v1.FCVolumeSource.wwids: array expected");
                                message.wwids = [];
                                for (var i = 0; i < object.wwids.length; ++i)
                                    message.wwids[i] = String(object.wwids[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FCVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.FCVolumeSource} message FCVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FCVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.targetWWNs = [];
                                object.wwids = [];
                            }
                            if (options.defaults) {
                                object.lun = 0;
                                object.fsType = "";
                                object.readOnly = false;
                            }
                            if (message.targetWWNs && message.targetWWNs.length) {
                                object.targetWWNs = [];
                                for (var j = 0; j < message.targetWWNs.length; ++j)
                                    object.targetWWNs[j] = message.targetWWNs[j];
                            }
                            if (message.lun != null && message.hasOwnProperty("lun"))
                                object.lun = message.lun;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.wwids && message.wwids.length) {
                                object.wwids = [];
                                for (var j = 0; j < message.wwids.length; ++j)
                                    object.wwids[j] = message.wwids[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this FCVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.FCVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FCVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FCVolumeSource;
                    })();

                    v1.FlexPersistentVolumeSource = (function() {

                        /**
                         * Properties of a FlexPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IFlexPersistentVolumeSource
                         * @property {string|null} [driver] FlexPersistentVolumeSource driver
                         * @property {string|null} [fsType] FlexPersistentVolumeSource fsType
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [secretRef] FlexPersistentVolumeSource secretRef
                         * @property {boolean|null} [readOnly] FlexPersistentVolumeSource readOnly
                         * @property {Object.<string,string>|null} [options] FlexPersistentVolumeSource options
                         */

                        /**
                         * Constructs a new FlexPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a FlexPersistentVolumeSource.
                         * @implements IFlexPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IFlexPersistentVolumeSource=} [properties] Properties to set
                         */
                        function FlexPersistentVolumeSource(properties) {
                            this.options = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FlexPersistentVolumeSource driver.
                         * @member {string} driver
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @instance
                         */
                        FlexPersistentVolumeSource.prototype.driver = "";

                        /**
                         * FlexPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @instance
                         */
                        FlexPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * FlexPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @instance
                         */
                        FlexPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * FlexPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @instance
                         */
                        FlexPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * FlexPersistentVolumeSource options.
                         * @member {Object.<string,string>} options
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @instance
                         */
                        FlexPersistentVolumeSource.prototype.options = $util.emptyObject;

                        /**
                         * Creates a new FlexPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlexPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.FlexPersistentVolumeSource} FlexPersistentVolumeSource instance
                         */
                        FlexPersistentVolumeSource.create = function create(properties) {
                            return new FlexPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified FlexPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.FlexPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlexPersistentVolumeSource} message FlexPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FlexPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.driver != null && Object.hasOwnProperty.call(message, "driver"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.driver);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.secretRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                                for (var keys = Object.keys(message.options), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.options[keys[i]]).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FlexPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.FlexPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlexPersistentVolumeSource} message FlexPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FlexPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FlexPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.FlexPersistentVolumeSource} FlexPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FlexPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.FlexPersistentVolumeSource(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.driver = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                case 5:
                                    if (message.options === $util.emptyObject)
                                        message.options = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.options[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FlexPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.FlexPersistentVolumeSource} FlexPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FlexPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FlexPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FlexPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                if (!$util.isString(message.driver))
                                    return "driver: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.options != null && message.hasOwnProperty("options")) {
                                if (!$util.isObject(message.options))
                                    return "options: object expected";
                                var key = Object.keys(message.options);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.options[key[i]]))
                                        return "options: string{k:string} expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a FlexPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.FlexPersistentVolumeSource} FlexPersistentVolumeSource
                         */
                        FlexPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.FlexPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.FlexPersistentVolumeSource();
                            if (object.driver != null)
                                message.driver = String(object.driver);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.FlexPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.secretRef);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.options) {
                                if (typeof object.options !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.FlexPersistentVolumeSource.options: object expected");
                                message.options = {};
                                for (var keys = Object.keys(object.options), i = 0; i < keys.length; ++i)
                                    message.options[keys[i]] = String(object.options[keys[i]]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FlexPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.FlexPersistentVolumeSource} message FlexPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FlexPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.options = {};
                            if (options.defaults) {
                                object.driver = "";
                                object.fsType = "";
                                object.secretRef = null;
                                object.readOnly = false;
                            }
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                object.driver = message.driver;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.secretRef, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            var keys2;
                            if (message.options && (keys2 = Object.keys(message.options)).length) {
                                object.options = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.options[keys2[j]] = message.options[keys2[j]];
                            }
                            return object;
                        };

                        /**
                         * Converts this FlexPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.FlexPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FlexPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FlexPersistentVolumeSource;
                    })();

                    v1.FlexVolumeSource = (function() {

                        /**
                         * Properties of a FlexVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IFlexVolumeSource
                         * @property {string|null} [driver] FlexVolumeSource driver
                         * @property {string|null} [fsType] FlexVolumeSource fsType
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] FlexVolumeSource secretRef
                         * @property {boolean|null} [readOnly] FlexVolumeSource readOnly
                         * @property {Object.<string,string>|null} [options] FlexVolumeSource options
                         */

                        /**
                         * Constructs a new FlexVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a FlexVolumeSource.
                         * @implements IFlexVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IFlexVolumeSource=} [properties] Properties to set
                         */
                        function FlexVolumeSource(properties) {
                            this.options = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FlexVolumeSource driver.
                         * @member {string} driver
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @instance
                         */
                        FlexVolumeSource.prototype.driver = "";

                        /**
                         * FlexVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @instance
                         */
                        FlexVolumeSource.prototype.fsType = "";

                        /**
                         * FlexVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @instance
                         */
                        FlexVolumeSource.prototype.secretRef = null;

                        /**
                         * FlexVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @instance
                         */
                        FlexVolumeSource.prototype.readOnly = false;

                        /**
                         * FlexVolumeSource options.
                         * @member {Object.<string,string>} options
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @instance
                         */
                        FlexVolumeSource.prototype.options = $util.emptyObject;

                        /**
                         * Creates a new FlexVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlexVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.FlexVolumeSource} FlexVolumeSource instance
                         */
                        FlexVolumeSource.create = function create(properties) {
                            return new FlexVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified FlexVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.FlexVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlexVolumeSource} message FlexVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FlexVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.driver != null && Object.hasOwnProperty.call(message, "driver"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.driver);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                                for (var keys = Object.keys(message.options), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.options[keys[i]]).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FlexVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.FlexVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlexVolumeSource} message FlexVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FlexVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FlexVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.FlexVolumeSource} FlexVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FlexVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.FlexVolumeSource(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.driver = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                case 5:
                                    if (message.options === $util.emptyObject)
                                        message.options = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.options[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FlexVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.FlexVolumeSource} FlexVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FlexVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FlexVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FlexVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                if (!$util.isString(message.driver))
                                    return "driver: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.options != null && message.hasOwnProperty("options")) {
                                if (!$util.isObject(message.options))
                                    return "options: object expected";
                                var key = Object.keys(message.options);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.options[key[i]]))
                                        return "options: string{k:string} expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a FlexVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.FlexVolumeSource} FlexVolumeSource
                         */
                        FlexVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.FlexVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.FlexVolumeSource();
                            if (object.driver != null)
                                message.driver = String(object.driver);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.FlexVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.options) {
                                if (typeof object.options !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.FlexVolumeSource.options: object expected");
                                message.options = {};
                                for (var keys = Object.keys(object.options), i = 0; i < keys.length; ++i)
                                    message.options[keys[i]] = String(object.options[keys[i]]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FlexVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.FlexVolumeSource} message FlexVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FlexVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.options = {};
                            if (options.defaults) {
                                object.driver = "";
                                object.fsType = "";
                                object.secretRef = null;
                                object.readOnly = false;
                            }
                            if (message.driver != null && message.hasOwnProperty("driver"))
                                object.driver = message.driver;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            var keys2;
                            if (message.options && (keys2 = Object.keys(message.options)).length) {
                                object.options = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.options[keys2[j]] = message.options[keys2[j]];
                            }
                            return object;
                        };

                        /**
                         * Converts this FlexVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.FlexVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FlexVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FlexVolumeSource;
                    })();

                    v1.FlockerVolumeSource = (function() {

                        /**
                         * Properties of a FlockerVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IFlockerVolumeSource
                         * @property {string|null} [datasetName] FlockerVolumeSource datasetName
                         * @property {string|null} [datasetUUID] FlockerVolumeSource datasetUUID
                         */

                        /**
                         * Constructs a new FlockerVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a FlockerVolumeSource.
                         * @implements IFlockerVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IFlockerVolumeSource=} [properties] Properties to set
                         */
                        function FlockerVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FlockerVolumeSource datasetName.
                         * @member {string} datasetName
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @instance
                         */
                        FlockerVolumeSource.prototype.datasetName = "";

                        /**
                         * FlockerVolumeSource datasetUUID.
                         * @member {string} datasetUUID
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @instance
                         */
                        FlockerVolumeSource.prototype.datasetUUID = "";

                        /**
                         * Creates a new FlockerVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlockerVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.FlockerVolumeSource} FlockerVolumeSource instance
                         */
                        FlockerVolumeSource.create = function create(properties) {
                            return new FlockerVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified FlockerVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.FlockerVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlockerVolumeSource} message FlockerVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FlockerVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.datasetName != null && Object.hasOwnProperty.call(message, "datasetName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.datasetName);
                            if (message.datasetUUID != null && Object.hasOwnProperty.call(message, "datasetUUID"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.datasetUUID);
                            return writer;
                        };

                        /**
                         * Encodes the specified FlockerVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.FlockerVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IFlockerVolumeSource} message FlockerVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FlockerVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FlockerVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.FlockerVolumeSource} FlockerVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FlockerVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.FlockerVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.datasetName = reader.string();
                                    break;
                                case 2:
                                    message.datasetUUID = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FlockerVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.FlockerVolumeSource} FlockerVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FlockerVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FlockerVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FlockerVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.datasetName != null && message.hasOwnProperty("datasetName"))
                                if (!$util.isString(message.datasetName))
                                    return "datasetName: string expected";
                            if (message.datasetUUID != null && message.hasOwnProperty("datasetUUID"))
                                if (!$util.isString(message.datasetUUID))
                                    return "datasetUUID: string expected";
                            return null;
                        };

                        /**
                         * Creates a FlockerVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.FlockerVolumeSource} FlockerVolumeSource
                         */
                        FlockerVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.FlockerVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.FlockerVolumeSource();
                            if (object.datasetName != null)
                                message.datasetName = String(object.datasetName);
                            if (object.datasetUUID != null)
                                message.datasetUUID = String(object.datasetUUID);
                            return message;
                        };

                        /**
                         * Creates a plain object from a FlockerVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.FlockerVolumeSource} message FlockerVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FlockerVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.datasetName = "";
                                object.datasetUUID = "";
                            }
                            if (message.datasetName != null && message.hasOwnProperty("datasetName"))
                                object.datasetName = message.datasetName;
                            if (message.datasetUUID != null && message.hasOwnProperty("datasetUUID"))
                                object.datasetUUID = message.datasetUUID;
                            return object;
                        };

                        /**
                         * Converts this FlockerVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.FlockerVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FlockerVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FlockerVolumeSource;
                    })();

                    v1.GCEPersistentDiskVolumeSource = (function() {

                        /**
                         * Properties of a GCEPersistentDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IGCEPersistentDiskVolumeSource
                         * @property {string|null} [pdName] GCEPersistentDiskVolumeSource pdName
                         * @property {string|null} [fsType] GCEPersistentDiskVolumeSource fsType
                         * @property {number|null} [partition] GCEPersistentDiskVolumeSource partition
                         * @property {boolean|null} [readOnly] GCEPersistentDiskVolumeSource readOnly
                         */

                        /**
                         * Constructs a new GCEPersistentDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a GCEPersistentDiskVolumeSource.
                         * @implements IGCEPersistentDiskVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource=} [properties] Properties to set
                         */
                        function GCEPersistentDiskVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GCEPersistentDiskVolumeSource pdName.
                         * @member {string} pdName
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @instance
                         */
                        GCEPersistentDiskVolumeSource.prototype.pdName = "";

                        /**
                         * GCEPersistentDiskVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @instance
                         */
                        GCEPersistentDiskVolumeSource.prototype.fsType = "";

                        /**
                         * GCEPersistentDiskVolumeSource partition.
                         * @member {number} partition
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @instance
                         */
                        GCEPersistentDiskVolumeSource.prototype.partition = 0;

                        /**
                         * GCEPersistentDiskVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @instance
                         */
                        GCEPersistentDiskVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new GCEPersistentDiskVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.GCEPersistentDiskVolumeSource} GCEPersistentDiskVolumeSource instance
                         */
                        GCEPersistentDiskVolumeSource.create = function create(properties) {
                            return new GCEPersistentDiskVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified GCEPersistentDiskVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource} message GCEPersistentDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GCEPersistentDiskVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.pdName != null && Object.hasOwnProperty.call(message, "pdName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pdName);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.partition != null && Object.hasOwnProperty.call(message, "partition"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.partition);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified GCEPersistentDiskVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource} message GCEPersistentDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GCEPersistentDiskVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GCEPersistentDiskVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.GCEPersistentDiskVolumeSource} GCEPersistentDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GCEPersistentDiskVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.pdName = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.partition = reader.int32();
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GCEPersistentDiskVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.GCEPersistentDiskVolumeSource} GCEPersistentDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GCEPersistentDiskVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GCEPersistentDiskVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GCEPersistentDiskVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.pdName != null && message.hasOwnProperty("pdName"))
                                if (!$util.isString(message.pdName))
                                    return "pdName: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.partition != null && message.hasOwnProperty("partition"))
                                if (!$util.isInteger(message.partition))
                                    return "partition: integer expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a GCEPersistentDiskVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.GCEPersistentDiskVolumeSource} GCEPersistentDiskVolumeSource
                         */
                        GCEPersistentDiskVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource();
                            if (object.pdName != null)
                                message.pdName = String(object.pdName);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.partition != null)
                                message.partition = object.partition | 0;
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a GCEPersistentDiskVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.GCEPersistentDiskVolumeSource} message GCEPersistentDiskVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GCEPersistentDiskVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.pdName = "";
                                object.fsType = "";
                                object.partition = 0;
                                object.readOnly = false;
                            }
                            if (message.pdName != null && message.hasOwnProperty("pdName"))
                                object.pdName = message.pdName;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.partition != null && message.hasOwnProperty("partition"))
                                object.partition = message.partition;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this GCEPersistentDiskVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.GCEPersistentDiskVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GCEPersistentDiskVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GCEPersistentDiskVolumeSource;
                    })();

                    v1.GitRepoVolumeSource = (function() {

                        /**
                         * Properties of a GitRepoVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IGitRepoVolumeSource
                         * @property {string|null} [repository] GitRepoVolumeSource repository
                         * @property {string|null} [revision] GitRepoVolumeSource revision
                         * @property {string|null} [directory] GitRepoVolumeSource directory
                         */

                        /**
                         * Constructs a new GitRepoVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a GitRepoVolumeSource.
                         * @implements IGitRepoVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IGitRepoVolumeSource=} [properties] Properties to set
                         */
                        function GitRepoVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GitRepoVolumeSource repository.
                         * @member {string} repository
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @instance
                         */
                        GitRepoVolumeSource.prototype.repository = "";

                        /**
                         * GitRepoVolumeSource revision.
                         * @member {string} revision
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @instance
                         */
                        GitRepoVolumeSource.prototype.revision = "";

                        /**
                         * GitRepoVolumeSource directory.
                         * @member {string} directory
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @instance
                         */
                        GitRepoVolumeSource.prototype.directory = "";

                        /**
                         * Creates a new GitRepoVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGitRepoVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.GitRepoVolumeSource} GitRepoVolumeSource instance
                         */
                        GitRepoVolumeSource.create = function create(properties) {
                            return new GitRepoVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified GitRepoVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.GitRepoVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGitRepoVolumeSource} message GitRepoVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GitRepoVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.repository != null && Object.hasOwnProperty.call(message, "repository"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.repository);
                            if (message.revision != null && Object.hasOwnProperty.call(message, "revision"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.revision);
                            if (message.directory != null && Object.hasOwnProperty.call(message, "directory"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.directory);
                            return writer;
                        };

                        /**
                         * Encodes the specified GitRepoVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.GitRepoVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGitRepoVolumeSource} message GitRepoVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GitRepoVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GitRepoVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.GitRepoVolumeSource} GitRepoVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GitRepoVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.GitRepoVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.repository = reader.string();
                                    break;
                                case 2:
                                    message.revision = reader.string();
                                    break;
                                case 3:
                                    message.directory = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GitRepoVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.GitRepoVolumeSource} GitRepoVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GitRepoVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GitRepoVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GitRepoVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.repository != null && message.hasOwnProperty("repository"))
                                if (!$util.isString(message.repository))
                                    return "repository: string expected";
                            if (message.revision != null && message.hasOwnProperty("revision"))
                                if (!$util.isString(message.revision))
                                    return "revision: string expected";
                            if (message.directory != null && message.hasOwnProperty("directory"))
                                if (!$util.isString(message.directory))
                                    return "directory: string expected";
                            return null;
                        };

                        /**
                         * Creates a GitRepoVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.GitRepoVolumeSource} GitRepoVolumeSource
                         */
                        GitRepoVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.GitRepoVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.GitRepoVolumeSource();
                            if (object.repository != null)
                                message.repository = String(object.repository);
                            if (object.revision != null)
                                message.revision = String(object.revision);
                            if (object.directory != null)
                                message.directory = String(object.directory);
                            return message;
                        };

                        /**
                         * Creates a plain object from a GitRepoVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.GitRepoVolumeSource} message GitRepoVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GitRepoVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.repository = "";
                                object.revision = "";
                                object.directory = "";
                            }
                            if (message.repository != null && message.hasOwnProperty("repository"))
                                object.repository = message.repository;
                            if (message.revision != null && message.hasOwnProperty("revision"))
                                object.revision = message.revision;
                            if (message.directory != null && message.hasOwnProperty("directory"))
                                object.directory = message.directory;
                            return object;
                        };

                        /**
                         * Converts this GitRepoVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.GitRepoVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GitRepoVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GitRepoVolumeSource;
                    })();

                    v1.GlusterfsPersistentVolumeSource = (function() {

                        /**
                         * Properties of a GlusterfsPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IGlusterfsPersistentVolumeSource
                         * @property {string|null} [endpoints] GlusterfsPersistentVolumeSource endpoints
                         * @property {string|null} [path] GlusterfsPersistentVolumeSource path
                         * @property {boolean|null} [readOnly] GlusterfsPersistentVolumeSource readOnly
                         * @property {string|null} [endpointsNamespace] GlusterfsPersistentVolumeSource endpointsNamespace
                         */

                        /**
                         * Constructs a new GlusterfsPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a GlusterfsPersistentVolumeSource.
                         * @implements IGlusterfsPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IGlusterfsPersistentVolumeSource=} [properties] Properties to set
                         */
                        function GlusterfsPersistentVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GlusterfsPersistentVolumeSource endpoints.
                         * @member {string} endpoints
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @instance
                         */
                        GlusterfsPersistentVolumeSource.prototype.endpoints = "";

                        /**
                         * GlusterfsPersistentVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @instance
                         */
                        GlusterfsPersistentVolumeSource.prototype.path = "";

                        /**
                         * GlusterfsPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @instance
                         */
                        GlusterfsPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * GlusterfsPersistentVolumeSource endpointsNamespace.
                         * @member {string} endpointsNamespace
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @instance
                         */
                        GlusterfsPersistentVolumeSource.prototype.endpointsNamespace = "";

                        /**
                         * Creates a new GlusterfsPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGlusterfsPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.GlusterfsPersistentVolumeSource} GlusterfsPersistentVolumeSource instance
                         */
                        GlusterfsPersistentVolumeSource.create = function create(properties) {
                            return new GlusterfsPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified GlusterfsPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGlusterfsPersistentVolumeSource} message GlusterfsPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GlusterfsPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.endpoints != null && Object.hasOwnProperty.call(message, "endpoints"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.endpoints);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            if (message.endpointsNamespace != null && Object.hasOwnProperty.call(message, "endpointsNamespace"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.endpointsNamespace);
                            return writer;
                        };

                        /**
                         * Encodes the specified GlusterfsPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGlusterfsPersistentVolumeSource} message GlusterfsPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GlusterfsPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GlusterfsPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.GlusterfsPersistentVolumeSource} GlusterfsPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GlusterfsPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.endpoints = reader.string();
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                case 4:
                                    message.endpointsNamespace = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GlusterfsPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.GlusterfsPersistentVolumeSource} GlusterfsPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GlusterfsPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GlusterfsPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GlusterfsPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.endpoints != null && message.hasOwnProperty("endpoints"))
                                if (!$util.isString(message.endpoints))
                                    return "endpoints: string expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.endpointsNamespace != null && message.hasOwnProperty("endpointsNamespace"))
                                if (!$util.isString(message.endpointsNamespace))
                                    return "endpointsNamespace: string expected";
                            return null;
                        };

                        /**
                         * Creates a GlusterfsPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.GlusterfsPersistentVolumeSource} GlusterfsPersistentVolumeSource
                         */
                        GlusterfsPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource();
                            if (object.endpoints != null)
                                message.endpoints = String(object.endpoints);
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.endpointsNamespace != null)
                                message.endpointsNamespace = String(object.endpointsNamespace);
                            return message;
                        };

                        /**
                         * Creates a plain object from a GlusterfsPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.GlusterfsPersistentVolumeSource} message GlusterfsPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GlusterfsPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.endpoints = "";
                                object.path = "";
                                object.readOnly = false;
                                object.endpointsNamespace = "";
                            }
                            if (message.endpoints != null && message.hasOwnProperty("endpoints"))
                                object.endpoints = message.endpoints;
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.endpointsNamespace != null && message.hasOwnProperty("endpointsNamespace"))
                                object.endpointsNamespace = message.endpointsNamespace;
                            return object;
                        };

                        /**
                         * Converts this GlusterfsPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.GlusterfsPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GlusterfsPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GlusterfsPersistentVolumeSource;
                    })();

                    v1.GlusterfsVolumeSource = (function() {

                        /**
                         * Properties of a GlusterfsVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IGlusterfsVolumeSource
                         * @property {string|null} [endpoints] GlusterfsVolumeSource endpoints
                         * @property {string|null} [path] GlusterfsVolumeSource path
                         * @property {boolean|null} [readOnly] GlusterfsVolumeSource readOnly
                         */

                        /**
                         * Constructs a new GlusterfsVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a GlusterfsVolumeSource.
                         * @implements IGlusterfsVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IGlusterfsVolumeSource=} [properties] Properties to set
                         */
                        function GlusterfsVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GlusterfsVolumeSource endpoints.
                         * @member {string} endpoints
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @instance
                         */
                        GlusterfsVolumeSource.prototype.endpoints = "";

                        /**
                         * GlusterfsVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @instance
                         */
                        GlusterfsVolumeSource.prototype.path = "";

                        /**
                         * GlusterfsVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @instance
                         */
                        GlusterfsVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new GlusterfsVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGlusterfsVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.GlusterfsVolumeSource} GlusterfsVolumeSource instance
                         */
                        GlusterfsVolumeSource.create = function create(properties) {
                            return new GlusterfsVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified GlusterfsVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.GlusterfsVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGlusterfsVolumeSource} message GlusterfsVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GlusterfsVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.endpoints != null && Object.hasOwnProperty.call(message, "endpoints"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.endpoints);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified GlusterfsVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.GlusterfsVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IGlusterfsVolumeSource} message GlusterfsVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        GlusterfsVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a GlusterfsVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.GlusterfsVolumeSource} GlusterfsVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GlusterfsVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.GlusterfsVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.endpoints = reader.string();
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a GlusterfsVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.GlusterfsVolumeSource} GlusterfsVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        GlusterfsVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a GlusterfsVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        GlusterfsVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.endpoints != null && message.hasOwnProperty("endpoints"))
                                if (!$util.isString(message.endpoints))
                                    return "endpoints: string expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a GlusterfsVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.GlusterfsVolumeSource} GlusterfsVolumeSource
                         */
                        GlusterfsVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.GlusterfsVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.GlusterfsVolumeSource();
                            if (object.endpoints != null)
                                message.endpoints = String(object.endpoints);
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a GlusterfsVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.GlusterfsVolumeSource} message GlusterfsVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        GlusterfsVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.endpoints = "";
                                object.path = "";
                                object.readOnly = false;
                            }
                            if (message.endpoints != null && message.hasOwnProperty("endpoints"))
                                object.endpoints = message.endpoints;
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this GlusterfsVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.GlusterfsVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        GlusterfsVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return GlusterfsVolumeSource;
                    })();

                    v1.HTTPGetAction = (function() {

                        /**
                         * Properties of a HTTPGetAction.
                         * @memberof k8s.io.api.core.v1
                         * @interface IHTTPGetAction
                         * @property {string|null} [path] HTTPGetAction path
                         * @property {k8s.io.apimachinery.pkg.util.intstr.IIntOrString|null} [port] HTTPGetAction port
                         * @property {string|null} [host] HTTPGetAction host
                         * @property {string|null} [scheme] HTTPGetAction scheme
                         * @property {Array.<k8s.io.api.core.v1.IHTTPHeader>|null} [httpHeaders] HTTPGetAction httpHeaders
                         */

                        /**
                         * Constructs a new HTTPGetAction.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a HTTPGetAction.
                         * @implements IHTTPGetAction
                         * @constructor
                         * @param {k8s.io.api.core.v1.IHTTPGetAction=} [properties] Properties to set
                         */
                        function HTTPGetAction(properties) {
                            this.httpHeaders = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * HTTPGetAction path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @instance
                         */
                        HTTPGetAction.prototype.path = "";

                        /**
                         * HTTPGetAction port.
                         * @member {k8s.io.apimachinery.pkg.util.intstr.IIntOrString|null|undefined} port
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @instance
                         */
                        HTTPGetAction.prototype.port = null;

                        /**
                         * HTTPGetAction host.
                         * @member {string} host
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @instance
                         */
                        HTTPGetAction.prototype.host = "";

                        /**
                         * HTTPGetAction scheme.
                         * @member {string} scheme
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @instance
                         */
                        HTTPGetAction.prototype.scheme = "";

                        /**
                         * HTTPGetAction httpHeaders.
                         * @member {Array.<k8s.io.api.core.v1.IHTTPHeader>} httpHeaders
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @instance
                         */
                        HTTPGetAction.prototype.httpHeaders = $util.emptyArray;

                        /**
                         * Creates a new HTTPGetAction instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {k8s.io.api.core.v1.IHTTPGetAction=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.HTTPGetAction} HTTPGetAction instance
                         */
                        HTTPGetAction.create = function create(properties) {
                            return new HTTPGetAction(properties);
                        };

                        /**
                         * Encodes the specified HTTPGetAction message. Does not implicitly {@link k8s.io.api.core.v1.HTTPGetAction.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {k8s.io.api.core.v1.IHTTPGetAction} message HTTPGetAction message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HTTPGetAction.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                                $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.encode(message.port, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.host);
                            if (message.scheme != null && Object.hasOwnProperty.call(message, "scheme"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.scheme);
                            if (message.httpHeaders != null && message.httpHeaders.length)
                                for (var i = 0; i < message.httpHeaders.length; ++i)
                                    $root.k8s.io.api.core.v1.HTTPHeader.encode(message.httpHeaders[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified HTTPGetAction message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.HTTPGetAction.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {k8s.io.api.core.v1.IHTTPGetAction} message HTTPGetAction message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HTTPGetAction.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a HTTPGetAction message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.HTTPGetAction} HTTPGetAction
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HTTPGetAction.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.HTTPGetAction();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                case 2:
                                    message.port = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.host = reader.string();
                                    break;
                                case 4:
                                    message.scheme = reader.string();
                                    break;
                                case 5:
                                    if (!(message.httpHeaders && message.httpHeaders.length))
                                        message.httpHeaders = [];
                                    message.httpHeaders.push($root.k8s.io.api.core.v1.HTTPHeader.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a HTTPGetAction message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.HTTPGetAction} HTTPGetAction
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HTTPGetAction.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a HTTPGetAction message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        HTTPGetAction.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.port != null && message.hasOwnProperty("port")) {
                                var error = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.verify(message.port);
                                if (error)
                                    return "port." + error;
                            }
                            if (message.host != null && message.hasOwnProperty("host"))
                                if (!$util.isString(message.host))
                                    return "host: string expected";
                            if (message.scheme != null && message.hasOwnProperty("scheme"))
                                if (!$util.isString(message.scheme))
                                    return "scheme: string expected";
                            if (message.httpHeaders != null && message.hasOwnProperty("httpHeaders")) {
                                if (!Array.isArray(message.httpHeaders))
                                    return "httpHeaders: array expected";
                                for (var i = 0; i < message.httpHeaders.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.HTTPHeader.verify(message.httpHeaders[i]);
                                    if (error)
                                        return "httpHeaders." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a HTTPGetAction message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.HTTPGetAction} HTTPGetAction
                         */
                        HTTPGetAction.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.HTTPGetAction)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.HTTPGetAction();
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.port != null) {
                                if (typeof object.port !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.HTTPGetAction.port: object expected");
                                message.port = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.fromObject(object.port);
                            }
                            if (object.host != null)
                                message.host = String(object.host);
                            if (object.scheme != null)
                                message.scheme = String(object.scheme);
                            if (object.httpHeaders) {
                                if (!Array.isArray(object.httpHeaders))
                                    throw TypeError(".k8s.io.api.core.v1.HTTPGetAction.httpHeaders: array expected");
                                message.httpHeaders = [];
                                for (var i = 0; i < object.httpHeaders.length; ++i) {
                                    if (typeof object.httpHeaders[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.HTTPGetAction.httpHeaders: object expected");
                                    message.httpHeaders[i] = $root.k8s.io.api.core.v1.HTTPHeader.fromObject(object.httpHeaders[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a HTTPGetAction message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @static
                         * @param {k8s.io.api.core.v1.HTTPGetAction} message HTTPGetAction
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        HTTPGetAction.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.httpHeaders = [];
                            if (options.defaults) {
                                object.path = "";
                                object.port = null;
                                object.host = "";
                                object.scheme = "";
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.port != null && message.hasOwnProperty("port"))
                                object.port = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.toObject(message.port, options);
                            if (message.host != null && message.hasOwnProperty("host"))
                                object.host = message.host;
                            if (message.scheme != null && message.hasOwnProperty("scheme"))
                                object.scheme = message.scheme;
                            if (message.httpHeaders && message.httpHeaders.length) {
                                object.httpHeaders = [];
                                for (var j = 0; j < message.httpHeaders.length; ++j)
                                    object.httpHeaders[j] = $root.k8s.io.api.core.v1.HTTPHeader.toObject(message.httpHeaders[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this HTTPGetAction to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.HTTPGetAction
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        HTTPGetAction.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return HTTPGetAction;
                    })();

                    v1.HTTPHeader = (function() {

                        /**
                         * Properties of a HTTPHeader.
                         * @memberof k8s.io.api.core.v1
                         * @interface IHTTPHeader
                         * @property {string|null} [name] HTTPHeader name
                         * @property {string|null} [value] HTTPHeader value
                         */

                        /**
                         * Constructs a new HTTPHeader.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a HTTPHeader.
                         * @implements IHTTPHeader
                         * @constructor
                         * @param {k8s.io.api.core.v1.IHTTPHeader=} [properties] Properties to set
                         */
                        function HTTPHeader(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * HTTPHeader name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @instance
                         */
                        HTTPHeader.prototype.name = "";

                        /**
                         * HTTPHeader value.
                         * @member {string} value
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @instance
                         */
                        HTTPHeader.prototype.value = "";

                        /**
                         * Creates a new HTTPHeader instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {k8s.io.api.core.v1.IHTTPHeader=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.HTTPHeader} HTTPHeader instance
                         */
                        HTTPHeader.create = function create(properties) {
                            return new HTTPHeader(properties);
                        };

                        /**
                         * Encodes the specified HTTPHeader message. Does not implicitly {@link k8s.io.api.core.v1.HTTPHeader.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {k8s.io.api.core.v1.IHTTPHeader} message HTTPHeader message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HTTPHeader.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                            return writer;
                        };

                        /**
                         * Encodes the specified HTTPHeader message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.HTTPHeader.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {k8s.io.api.core.v1.IHTTPHeader} message HTTPHeader message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HTTPHeader.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a HTTPHeader message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.HTTPHeader} HTTPHeader
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HTTPHeader.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.HTTPHeader();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a HTTPHeader message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.HTTPHeader} HTTPHeader
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HTTPHeader.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a HTTPHeader message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        HTTPHeader.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isString(message.value))
                                    return "value: string expected";
                            return null;
                        };

                        /**
                         * Creates a HTTPHeader message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.HTTPHeader} HTTPHeader
                         */
                        HTTPHeader.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.HTTPHeader)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.HTTPHeader();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.value != null)
                                message.value = String(object.value);
                            return message;
                        };

                        /**
                         * Creates a plain object from a HTTPHeader message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @static
                         * @param {k8s.io.api.core.v1.HTTPHeader} message HTTPHeader
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        HTTPHeader.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.value = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            return object;
                        };

                        /**
                         * Converts this HTTPHeader to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.HTTPHeader
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        HTTPHeader.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return HTTPHeader;
                    })();

                    v1.Handler = (function() {

                        /**
                         * Properties of a Handler.
                         * @memberof k8s.io.api.core.v1
                         * @interface IHandler
                         * @property {k8s.io.api.core.v1.IExecAction|null} [exec] Handler exec
                         * @property {k8s.io.api.core.v1.IHTTPGetAction|null} [httpGet] Handler httpGet
                         * @property {k8s.io.api.core.v1.ITCPSocketAction|null} [tcpSocket] Handler tcpSocket
                         */

                        /**
                         * Constructs a new Handler.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Handler.
                         * @implements IHandler
                         * @constructor
                         * @param {k8s.io.api.core.v1.IHandler=} [properties] Properties to set
                         */
                        function Handler(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Handler exec.
                         * @member {k8s.io.api.core.v1.IExecAction|null|undefined} exec
                         * @memberof k8s.io.api.core.v1.Handler
                         * @instance
                         */
                        Handler.prototype.exec = null;

                        /**
                         * Handler httpGet.
                         * @member {k8s.io.api.core.v1.IHTTPGetAction|null|undefined} httpGet
                         * @memberof k8s.io.api.core.v1.Handler
                         * @instance
                         */
                        Handler.prototype.httpGet = null;

                        /**
                         * Handler tcpSocket.
                         * @member {k8s.io.api.core.v1.ITCPSocketAction|null|undefined} tcpSocket
                         * @memberof k8s.io.api.core.v1.Handler
                         * @instance
                         */
                        Handler.prototype.tcpSocket = null;

                        /**
                         * Creates a new Handler instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {k8s.io.api.core.v1.IHandler=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Handler} Handler instance
                         */
                        Handler.create = function create(properties) {
                            return new Handler(properties);
                        };

                        /**
                         * Encodes the specified Handler message. Does not implicitly {@link k8s.io.api.core.v1.Handler.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {k8s.io.api.core.v1.IHandler} message Handler message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Handler.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.exec != null && Object.hasOwnProperty.call(message, "exec"))
                                $root.k8s.io.api.core.v1.ExecAction.encode(message.exec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.httpGet != null && Object.hasOwnProperty.call(message, "httpGet"))
                                $root.k8s.io.api.core.v1.HTTPGetAction.encode(message.httpGet, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.tcpSocket != null && Object.hasOwnProperty.call(message, "tcpSocket"))
                                $root.k8s.io.api.core.v1.TCPSocketAction.encode(message.tcpSocket, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Handler message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Handler.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {k8s.io.api.core.v1.IHandler} message Handler message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Handler.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Handler message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Handler} Handler
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Handler.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Handler();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.exec = $root.k8s.io.api.core.v1.ExecAction.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.httpGet = $root.k8s.io.api.core.v1.HTTPGetAction.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.tcpSocket = $root.k8s.io.api.core.v1.TCPSocketAction.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Handler message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Handler} Handler
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Handler.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Handler message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Handler.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.exec != null && message.hasOwnProperty("exec")) {
                                var error = $root.k8s.io.api.core.v1.ExecAction.verify(message.exec);
                                if (error)
                                    return "exec." + error;
                            }
                            if (message.httpGet != null && message.hasOwnProperty("httpGet")) {
                                var error = $root.k8s.io.api.core.v1.HTTPGetAction.verify(message.httpGet);
                                if (error)
                                    return "httpGet." + error;
                            }
                            if (message.tcpSocket != null && message.hasOwnProperty("tcpSocket")) {
                                var error = $root.k8s.io.api.core.v1.TCPSocketAction.verify(message.tcpSocket);
                                if (error)
                                    return "tcpSocket." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Handler message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Handler} Handler
                         */
                        Handler.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Handler)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Handler();
                            if (object.exec != null) {
                                if (typeof object.exec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Handler.exec: object expected");
                                message.exec = $root.k8s.io.api.core.v1.ExecAction.fromObject(object.exec);
                            }
                            if (object.httpGet != null) {
                                if (typeof object.httpGet !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Handler.httpGet: object expected");
                                message.httpGet = $root.k8s.io.api.core.v1.HTTPGetAction.fromObject(object.httpGet);
                            }
                            if (object.tcpSocket != null) {
                                if (typeof object.tcpSocket !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Handler.tcpSocket: object expected");
                                message.tcpSocket = $root.k8s.io.api.core.v1.TCPSocketAction.fromObject(object.tcpSocket);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Handler message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Handler
                         * @static
                         * @param {k8s.io.api.core.v1.Handler} message Handler
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Handler.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.exec = null;
                                object.httpGet = null;
                                object.tcpSocket = null;
                            }
                            if (message.exec != null && message.hasOwnProperty("exec"))
                                object.exec = $root.k8s.io.api.core.v1.ExecAction.toObject(message.exec, options);
                            if (message.httpGet != null && message.hasOwnProperty("httpGet"))
                                object.httpGet = $root.k8s.io.api.core.v1.HTTPGetAction.toObject(message.httpGet, options);
                            if (message.tcpSocket != null && message.hasOwnProperty("tcpSocket"))
                                object.tcpSocket = $root.k8s.io.api.core.v1.TCPSocketAction.toObject(message.tcpSocket, options);
                            return object;
                        };

                        /**
                         * Converts this Handler to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Handler
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Handler.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Handler;
                    })();

                    v1.HostAlias = (function() {

                        /**
                         * Properties of a HostAlias.
                         * @memberof k8s.io.api.core.v1
                         * @interface IHostAlias
                         * @property {string|null} [ip] HostAlias ip
                         * @property {Array.<string>|null} [hostnames] HostAlias hostnames
                         */

                        /**
                         * Constructs a new HostAlias.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a HostAlias.
                         * @implements IHostAlias
                         * @constructor
                         * @param {k8s.io.api.core.v1.IHostAlias=} [properties] Properties to set
                         */
                        function HostAlias(properties) {
                            this.hostnames = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * HostAlias ip.
                         * @member {string} ip
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @instance
                         */
                        HostAlias.prototype.ip = "";

                        /**
                         * HostAlias hostnames.
                         * @member {Array.<string>} hostnames
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @instance
                         */
                        HostAlias.prototype.hostnames = $util.emptyArray;

                        /**
                         * Creates a new HostAlias instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {k8s.io.api.core.v1.IHostAlias=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.HostAlias} HostAlias instance
                         */
                        HostAlias.create = function create(properties) {
                            return new HostAlias(properties);
                        };

                        /**
                         * Encodes the specified HostAlias message. Does not implicitly {@link k8s.io.api.core.v1.HostAlias.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {k8s.io.api.core.v1.IHostAlias} message HostAlias message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HostAlias.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ip);
                            if (message.hostnames != null && message.hostnames.length)
                                for (var i = 0; i < message.hostnames.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.hostnames[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified HostAlias message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.HostAlias.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {k8s.io.api.core.v1.IHostAlias} message HostAlias message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HostAlias.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a HostAlias message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.HostAlias} HostAlias
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HostAlias.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.HostAlias();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.ip = reader.string();
                                    break;
                                case 2:
                                    if (!(message.hostnames && message.hostnames.length))
                                        message.hostnames = [];
                                    message.hostnames.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a HostAlias message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.HostAlias} HostAlias
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HostAlias.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a HostAlias message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        HostAlias.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                if (!$util.isString(message.ip))
                                    return "ip: string expected";
                            if (message.hostnames != null && message.hasOwnProperty("hostnames")) {
                                if (!Array.isArray(message.hostnames))
                                    return "hostnames: array expected";
                                for (var i = 0; i < message.hostnames.length; ++i)
                                    if (!$util.isString(message.hostnames[i]))
                                        return "hostnames: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a HostAlias message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.HostAlias} HostAlias
                         */
                        HostAlias.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.HostAlias)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.HostAlias();
                            if (object.ip != null)
                                message.ip = String(object.ip);
                            if (object.hostnames) {
                                if (!Array.isArray(object.hostnames))
                                    throw TypeError(".k8s.io.api.core.v1.HostAlias.hostnames: array expected");
                                message.hostnames = [];
                                for (var i = 0; i < object.hostnames.length; ++i)
                                    message.hostnames[i] = String(object.hostnames[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a HostAlias message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @static
                         * @param {k8s.io.api.core.v1.HostAlias} message HostAlias
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        HostAlias.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.hostnames = [];
                            if (options.defaults)
                                object.ip = "";
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                object.ip = message.ip;
                            if (message.hostnames && message.hostnames.length) {
                                object.hostnames = [];
                                for (var j = 0; j < message.hostnames.length; ++j)
                                    object.hostnames[j] = message.hostnames[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this HostAlias to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.HostAlias
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        HostAlias.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return HostAlias;
                    })();

                    v1.HostPathVolumeSource = (function() {

                        /**
                         * Properties of a HostPathVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IHostPathVolumeSource
                         * @property {string|null} [path] HostPathVolumeSource path
                         * @property {string|null} [type] HostPathVolumeSource type
                         */

                        /**
                         * Constructs a new HostPathVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a HostPathVolumeSource.
                         * @implements IHostPathVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IHostPathVolumeSource=} [properties] Properties to set
                         */
                        function HostPathVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * HostPathVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @instance
                         */
                        HostPathVolumeSource.prototype.path = "";

                        /**
                         * HostPathVolumeSource type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @instance
                         */
                        HostPathVolumeSource.prototype.type = "";

                        /**
                         * Creates a new HostPathVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IHostPathVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.HostPathVolumeSource} HostPathVolumeSource instance
                         */
                        HostPathVolumeSource.create = function create(properties) {
                            return new HostPathVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified HostPathVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.HostPathVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IHostPathVolumeSource} message HostPathVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HostPathVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                            return writer;
                        };

                        /**
                         * Encodes the specified HostPathVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.HostPathVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IHostPathVolumeSource} message HostPathVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HostPathVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a HostPathVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.HostPathVolumeSource} HostPathVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HostPathVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.HostPathVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                case 2:
                                    message.type = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a HostPathVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.HostPathVolumeSource} HostPathVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HostPathVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a HostPathVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        HostPathVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            return null;
                        };

                        /**
                         * Creates a HostPathVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.HostPathVolumeSource} HostPathVolumeSource
                         */
                        HostPathVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.HostPathVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.HostPathVolumeSource();
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.type != null)
                                message.type = String(object.type);
                            return message;
                        };

                        /**
                         * Creates a plain object from a HostPathVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.HostPathVolumeSource} message HostPathVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        HostPathVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.path = "";
                                object.type = "";
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            return object;
                        };

                        /**
                         * Converts this HostPathVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.HostPathVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        HostPathVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return HostPathVolumeSource;
                    })();

                    v1.ISCSIPersistentVolumeSource = (function() {

                        /**
                         * Properties of a ISCSIPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IISCSIPersistentVolumeSource
                         * @property {string|null} [targetPortal] ISCSIPersistentVolumeSource targetPortal
                         * @property {string|null} [iqn] ISCSIPersistentVolumeSource iqn
                         * @property {number|null} [lun] ISCSIPersistentVolumeSource lun
                         * @property {string|null} [iscsiInterface] ISCSIPersistentVolumeSource iscsiInterface
                         * @property {string|null} [fsType] ISCSIPersistentVolumeSource fsType
                         * @property {boolean|null} [readOnly] ISCSIPersistentVolumeSource readOnly
                         * @property {Array.<string>|null} [portals] ISCSIPersistentVolumeSource portals
                         * @property {boolean|null} [chapAuthDiscovery] ISCSIPersistentVolumeSource chapAuthDiscovery
                         * @property {boolean|null} [chapAuthSession] ISCSIPersistentVolumeSource chapAuthSession
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [secretRef] ISCSIPersistentVolumeSource secretRef
                         * @property {string|null} [initiatorName] ISCSIPersistentVolumeSource initiatorName
                         */

                        /**
                         * Constructs a new ISCSIPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ISCSIPersistentVolumeSource.
                         * @implements IISCSIPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IISCSIPersistentVolumeSource=} [properties] Properties to set
                         */
                        function ISCSIPersistentVolumeSource(properties) {
                            this.portals = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ISCSIPersistentVolumeSource targetPortal.
                         * @member {string} targetPortal
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.targetPortal = "";

                        /**
                         * ISCSIPersistentVolumeSource iqn.
                         * @member {string} iqn
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.iqn = "";

                        /**
                         * ISCSIPersistentVolumeSource lun.
                         * @member {number} lun
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.lun = 0;

                        /**
                         * ISCSIPersistentVolumeSource iscsiInterface.
                         * @member {string} iscsiInterface
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.iscsiInterface = "";

                        /**
                         * ISCSIPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * ISCSIPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * ISCSIPersistentVolumeSource portals.
                         * @member {Array.<string>} portals
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.portals = $util.emptyArray;

                        /**
                         * ISCSIPersistentVolumeSource chapAuthDiscovery.
                         * @member {boolean} chapAuthDiscovery
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.chapAuthDiscovery = false;

                        /**
                         * ISCSIPersistentVolumeSource chapAuthSession.
                         * @member {boolean} chapAuthSession
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.chapAuthSession = false;

                        /**
                         * ISCSIPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * ISCSIPersistentVolumeSource initiatorName.
                         * @member {string} initiatorName
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         */
                        ISCSIPersistentVolumeSource.prototype.initiatorName = "";

                        /**
                         * Creates a new ISCSIPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IISCSIPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ISCSIPersistentVolumeSource} ISCSIPersistentVolumeSource instance
                         */
                        ISCSIPersistentVolumeSource.create = function create(properties) {
                            return new ISCSIPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified ISCSIPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.ISCSIPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IISCSIPersistentVolumeSource} message ISCSIPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ISCSIPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.targetPortal != null && Object.hasOwnProperty.call(message, "targetPortal"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.targetPortal);
                            if (message.iqn != null && Object.hasOwnProperty.call(message, "iqn"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.iqn);
                            if (message.lun != null && Object.hasOwnProperty.call(message, "lun"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.lun);
                            if (message.iscsiInterface != null && Object.hasOwnProperty.call(message, "iscsiInterface"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.iscsiInterface);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readOnly);
                            if (message.portals != null && message.portals.length)
                                for (var i = 0; i < message.portals.length; ++i)
                                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.portals[i]);
                            if (message.chapAuthDiscovery != null && Object.hasOwnProperty.call(message, "chapAuthDiscovery"))
                                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.chapAuthDiscovery);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.secretRef, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.chapAuthSession != null && Object.hasOwnProperty.call(message, "chapAuthSession"))
                                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.chapAuthSession);
                            if (message.initiatorName != null && Object.hasOwnProperty.call(message, "initiatorName"))
                                writer.uint32(/* id 12, wireType 2 =*/98).string(message.initiatorName);
                            return writer;
                        };

                        /**
                         * Encodes the specified ISCSIPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ISCSIPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IISCSIPersistentVolumeSource} message ISCSIPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ISCSIPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ISCSIPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ISCSIPersistentVolumeSource} ISCSIPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ISCSIPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.targetPortal = reader.string();
                                    break;
                                case 2:
                                    message.iqn = reader.string();
                                    break;
                                case 3:
                                    message.lun = reader.int32();
                                    break;
                                case 4:
                                    message.iscsiInterface = reader.string();
                                    break;
                                case 5:
                                    message.fsType = reader.string();
                                    break;
                                case 6:
                                    message.readOnly = reader.bool();
                                    break;
                                case 7:
                                    if (!(message.portals && message.portals.length))
                                        message.portals = [];
                                    message.portals.push(reader.string());
                                    break;
                                case 8:
                                    message.chapAuthDiscovery = reader.bool();
                                    break;
                                case 11:
                                    message.chapAuthSession = reader.bool();
                                    break;
                                case 10:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.initiatorName = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ISCSIPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ISCSIPersistentVolumeSource} ISCSIPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ISCSIPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ISCSIPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ISCSIPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.targetPortal != null && message.hasOwnProperty("targetPortal"))
                                if (!$util.isString(message.targetPortal))
                                    return "targetPortal: string expected";
                            if (message.iqn != null && message.hasOwnProperty("iqn"))
                                if (!$util.isString(message.iqn))
                                    return "iqn: string expected";
                            if (message.lun != null && message.hasOwnProperty("lun"))
                                if (!$util.isInteger(message.lun))
                                    return "lun: integer expected";
                            if (message.iscsiInterface != null && message.hasOwnProperty("iscsiInterface"))
                                if (!$util.isString(message.iscsiInterface))
                                    return "iscsiInterface: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.portals != null && message.hasOwnProperty("portals")) {
                                if (!Array.isArray(message.portals))
                                    return "portals: array expected";
                                for (var i = 0; i < message.portals.length; ++i)
                                    if (!$util.isString(message.portals[i]))
                                        return "portals: string[] expected";
                            }
                            if (message.chapAuthDiscovery != null && message.hasOwnProperty("chapAuthDiscovery"))
                                if (typeof message.chapAuthDiscovery !== "boolean")
                                    return "chapAuthDiscovery: boolean expected";
                            if (message.chapAuthSession != null && message.hasOwnProperty("chapAuthSession"))
                                if (typeof message.chapAuthSession !== "boolean")
                                    return "chapAuthSession: boolean expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.initiatorName != null && message.hasOwnProperty("initiatorName"))
                                if (!$util.isString(message.initiatorName))
                                    return "initiatorName: string expected";
                            return null;
                        };

                        /**
                         * Creates a ISCSIPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ISCSIPersistentVolumeSource} ISCSIPersistentVolumeSource
                         */
                        ISCSIPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource();
                            if (object.targetPortal != null)
                                message.targetPortal = String(object.targetPortal);
                            if (object.iqn != null)
                                message.iqn = String(object.iqn);
                            if (object.lun != null)
                                message.lun = object.lun | 0;
                            if (object.iscsiInterface != null)
                                message.iscsiInterface = String(object.iscsiInterface);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.portals) {
                                if (!Array.isArray(object.portals))
                                    throw TypeError(".k8s.io.api.core.v1.ISCSIPersistentVolumeSource.portals: array expected");
                                message.portals = [];
                                for (var i = 0; i < object.portals.length; ++i)
                                    message.portals[i] = String(object.portals[i]);
                            }
                            if (object.chapAuthDiscovery != null)
                                message.chapAuthDiscovery = Boolean(object.chapAuthDiscovery);
                            if (object.chapAuthSession != null)
                                message.chapAuthSession = Boolean(object.chapAuthSession);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ISCSIPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.secretRef);
                            }
                            if (object.initiatorName != null)
                                message.initiatorName = String(object.initiatorName);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ISCSIPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISCSIPersistentVolumeSource} message ISCSIPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ISCSIPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.portals = [];
                            if (options.defaults) {
                                object.targetPortal = "";
                                object.iqn = "";
                                object.lun = 0;
                                object.iscsiInterface = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.chapAuthDiscovery = false;
                                object.secretRef = null;
                                object.chapAuthSession = false;
                                object.initiatorName = "";
                            }
                            if (message.targetPortal != null && message.hasOwnProperty("targetPortal"))
                                object.targetPortal = message.targetPortal;
                            if (message.iqn != null && message.hasOwnProperty("iqn"))
                                object.iqn = message.iqn;
                            if (message.lun != null && message.hasOwnProperty("lun"))
                                object.lun = message.lun;
                            if (message.iscsiInterface != null && message.hasOwnProperty("iscsiInterface"))
                                object.iscsiInterface = message.iscsiInterface;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.portals && message.portals.length) {
                                object.portals = [];
                                for (var j = 0; j < message.portals.length; ++j)
                                    object.portals[j] = message.portals[j];
                            }
                            if (message.chapAuthDiscovery != null && message.hasOwnProperty("chapAuthDiscovery"))
                                object.chapAuthDiscovery = message.chapAuthDiscovery;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.secretRef, options);
                            if (message.chapAuthSession != null && message.hasOwnProperty("chapAuthSession"))
                                object.chapAuthSession = message.chapAuthSession;
                            if (message.initiatorName != null && message.hasOwnProperty("initiatorName"))
                                object.initiatorName = message.initiatorName;
                            return object;
                        };

                        /**
                         * Converts this ISCSIPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ISCSIPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ISCSIPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ISCSIPersistentVolumeSource;
                    })();

                    v1.ISCSIVolumeSource = (function() {

                        /**
                         * Properties of a ISCSIVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IISCSIVolumeSource
                         * @property {string|null} [targetPortal] ISCSIVolumeSource targetPortal
                         * @property {string|null} [iqn] ISCSIVolumeSource iqn
                         * @property {number|null} [lun] ISCSIVolumeSource lun
                         * @property {string|null} [iscsiInterface] ISCSIVolumeSource iscsiInterface
                         * @property {string|null} [fsType] ISCSIVolumeSource fsType
                         * @property {boolean|null} [readOnly] ISCSIVolumeSource readOnly
                         * @property {Array.<string>|null} [portals] ISCSIVolumeSource portals
                         * @property {boolean|null} [chapAuthDiscovery] ISCSIVolumeSource chapAuthDiscovery
                         * @property {boolean|null} [chapAuthSession] ISCSIVolumeSource chapAuthSession
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] ISCSIVolumeSource secretRef
                         * @property {string|null} [initiatorName] ISCSIVolumeSource initiatorName
                         */

                        /**
                         * Constructs a new ISCSIVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ISCSIVolumeSource.
                         * @implements IISCSIVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IISCSIVolumeSource=} [properties] Properties to set
                         */
                        function ISCSIVolumeSource(properties) {
                            this.portals = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ISCSIVolumeSource targetPortal.
                         * @member {string} targetPortal
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.targetPortal = "";

                        /**
                         * ISCSIVolumeSource iqn.
                         * @member {string} iqn
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.iqn = "";

                        /**
                         * ISCSIVolumeSource lun.
                         * @member {number} lun
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.lun = 0;

                        /**
                         * ISCSIVolumeSource iscsiInterface.
                         * @member {string} iscsiInterface
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.iscsiInterface = "";

                        /**
                         * ISCSIVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.fsType = "";

                        /**
                         * ISCSIVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.readOnly = false;

                        /**
                         * ISCSIVolumeSource portals.
                         * @member {Array.<string>} portals
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.portals = $util.emptyArray;

                        /**
                         * ISCSIVolumeSource chapAuthDiscovery.
                         * @member {boolean} chapAuthDiscovery
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.chapAuthDiscovery = false;

                        /**
                         * ISCSIVolumeSource chapAuthSession.
                         * @member {boolean} chapAuthSession
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.chapAuthSession = false;

                        /**
                         * ISCSIVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.secretRef = null;

                        /**
                         * ISCSIVolumeSource initiatorName.
                         * @member {string} initiatorName
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         */
                        ISCSIVolumeSource.prototype.initiatorName = "";

                        /**
                         * Creates a new ISCSIVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IISCSIVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ISCSIVolumeSource} ISCSIVolumeSource instance
                         */
                        ISCSIVolumeSource.create = function create(properties) {
                            return new ISCSIVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified ISCSIVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.ISCSIVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IISCSIVolumeSource} message ISCSIVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ISCSIVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.targetPortal != null && Object.hasOwnProperty.call(message, "targetPortal"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.targetPortal);
                            if (message.iqn != null && Object.hasOwnProperty.call(message, "iqn"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.iqn);
                            if (message.lun != null && Object.hasOwnProperty.call(message, "lun"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.lun);
                            if (message.iscsiInterface != null && Object.hasOwnProperty.call(message, "iscsiInterface"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.iscsiInterface);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readOnly);
                            if (message.portals != null && message.portals.length)
                                for (var i = 0; i < message.portals.length; ++i)
                                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.portals[i]);
                            if (message.chapAuthDiscovery != null && Object.hasOwnProperty.call(message, "chapAuthDiscovery"))
                                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.chapAuthDiscovery);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.chapAuthSession != null && Object.hasOwnProperty.call(message, "chapAuthSession"))
                                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.chapAuthSession);
                            if (message.initiatorName != null && Object.hasOwnProperty.call(message, "initiatorName"))
                                writer.uint32(/* id 12, wireType 2 =*/98).string(message.initiatorName);
                            return writer;
                        };

                        /**
                         * Encodes the specified ISCSIVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ISCSIVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IISCSIVolumeSource} message ISCSIVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ISCSIVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ISCSIVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ISCSIVolumeSource} ISCSIVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ISCSIVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ISCSIVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.targetPortal = reader.string();
                                    break;
                                case 2:
                                    message.iqn = reader.string();
                                    break;
                                case 3:
                                    message.lun = reader.int32();
                                    break;
                                case 4:
                                    message.iscsiInterface = reader.string();
                                    break;
                                case 5:
                                    message.fsType = reader.string();
                                    break;
                                case 6:
                                    message.readOnly = reader.bool();
                                    break;
                                case 7:
                                    if (!(message.portals && message.portals.length))
                                        message.portals = [];
                                    message.portals.push(reader.string());
                                    break;
                                case 8:
                                    message.chapAuthDiscovery = reader.bool();
                                    break;
                                case 11:
                                    message.chapAuthSession = reader.bool();
                                    break;
                                case 10:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.initiatorName = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ISCSIVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ISCSIVolumeSource} ISCSIVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ISCSIVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ISCSIVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ISCSIVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.targetPortal != null && message.hasOwnProperty("targetPortal"))
                                if (!$util.isString(message.targetPortal))
                                    return "targetPortal: string expected";
                            if (message.iqn != null && message.hasOwnProperty("iqn"))
                                if (!$util.isString(message.iqn))
                                    return "iqn: string expected";
                            if (message.lun != null && message.hasOwnProperty("lun"))
                                if (!$util.isInteger(message.lun))
                                    return "lun: integer expected";
                            if (message.iscsiInterface != null && message.hasOwnProperty("iscsiInterface"))
                                if (!$util.isString(message.iscsiInterface))
                                    return "iscsiInterface: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.portals != null && message.hasOwnProperty("portals")) {
                                if (!Array.isArray(message.portals))
                                    return "portals: array expected";
                                for (var i = 0; i < message.portals.length; ++i)
                                    if (!$util.isString(message.portals[i]))
                                        return "portals: string[] expected";
                            }
                            if (message.chapAuthDiscovery != null && message.hasOwnProperty("chapAuthDiscovery"))
                                if (typeof message.chapAuthDiscovery !== "boolean")
                                    return "chapAuthDiscovery: boolean expected";
                            if (message.chapAuthSession != null && message.hasOwnProperty("chapAuthSession"))
                                if (typeof message.chapAuthSession !== "boolean")
                                    return "chapAuthSession: boolean expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.initiatorName != null && message.hasOwnProperty("initiatorName"))
                                if (!$util.isString(message.initiatorName))
                                    return "initiatorName: string expected";
                            return null;
                        };

                        /**
                         * Creates a ISCSIVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ISCSIVolumeSource} ISCSIVolumeSource
                         */
                        ISCSIVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ISCSIVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ISCSIVolumeSource();
                            if (object.targetPortal != null)
                                message.targetPortal = String(object.targetPortal);
                            if (object.iqn != null)
                                message.iqn = String(object.iqn);
                            if (object.lun != null)
                                message.lun = object.lun | 0;
                            if (object.iscsiInterface != null)
                                message.iscsiInterface = String(object.iscsiInterface);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.portals) {
                                if (!Array.isArray(object.portals))
                                    throw TypeError(".k8s.io.api.core.v1.ISCSIVolumeSource.portals: array expected");
                                message.portals = [];
                                for (var i = 0; i < object.portals.length; ++i)
                                    message.portals[i] = String(object.portals[i]);
                            }
                            if (object.chapAuthDiscovery != null)
                                message.chapAuthDiscovery = Boolean(object.chapAuthDiscovery);
                            if (object.chapAuthSession != null)
                                message.chapAuthSession = Boolean(object.chapAuthSession);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ISCSIVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            if (object.initiatorName != null)
                                message.initiatorName = String(object.initiatorName);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ISCSIVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISCSIVolumeSource} message ISCSIVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ISCSIVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.portals = [];
                            if (options.defaults) {
                                object.targetPortal = "";
                                object.iqn = "";
                                object.lun = 0;
                                object.iscsiInterface = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.chapAuthDiscovery = false;
                                object.secretRef = null;
                                object.chapAuthSession = false;
                                object.initiatorName = "";
                            }
                            if (message.targetPortal != null && message.hasOwnProperty("targetPortal"))
                                object.targetPortal = message.targetPortal;
                            if (message.iqn != null && message.hasOwnProperty("iqn"))
                                object.iqn = message.iqn;
                            if (message.lun != null && message.hasOwnProperty("lun"))
                                object.lun = message.lun;
                            if (message.iscsiInterface != null && message.hasOwnProperty("iscsiInterface"))
                                object.iscsiInterface = message.iscsiInterface;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.portals && message.portals.length) {
                                object.portals = [];
                                for (var j = 0; j < message.portals.length; ++j)
                                    object.portals[j] = message.portals[j];
                            }
                            if (message.chapAuthDiscovery != null && message.hasOwnProperty("chapAuthDiscovery"))
                                object.chapAuthDiscovery = message.chapAuthDiscovery;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            if (message.chapAuthSession != null && message.hasOwnProperty("chapAuthSession"))
                                object.chapAuthSession = message.chapAuthSession;
                            if (message.initiatorName != null && message.hasOwnProperty("initiatorName"))
                                object.initiatorName = message.initiatorName;
                            return object;
                        };

                        /**
                         * Converts this ISCSIVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ISCSIVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ISCSIVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ISCSIVolumeSource;
                    })();

                    v1.KeyToPath = (function() {

                        /**
                         * Properties of a KeyToPath.
                         * @memberof k8s.io.api.core.v1
                         * @interface IKeyToPath
                         * @property {string|null} [key] KeyToPath key
                         * @property {string|null} [path] KeyToPath path
                         * @property {number|null} [mode] KeyToPath mode
                         */

                        /**
                         * Constructs a new KeyToPath.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a KeyToPath.
                         * @implements IKeyToPath
                         * @constructor
                         * @param {k8s.io.api.core.v1.IKeyToPath=} [properties] Properties to set
                         */
                        function KeyToPath(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * KeyToPath key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @instance
                         */
                        KeyToPath.prototype.key = "";

                        /**
                         * KeyToPath path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @instance
                         */
                        KeyToPath.prototype.path = "";

                        /**
                         * KeyToPath mode.
                         * @member {number} mode
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @instance
                         */
                        KeyToPath.prototype.mode = 0;

                        /**
                         * Creates a new KeyToPath instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {k8s.io.api.core.v1.IKeyToPath=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.KeyToPath} KeyToPath instance
                         */
                        KeyToPath.create = function create(properties) {
                            return new KeyToPath(properties);
                        };

                        /**
                         * Encodes the specified KeyToPath message. Does not implicitly {@link k8s.io.api.core.v1.KeyToPath.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {k8s.io.api.core.v1.IKeyToPath} message KeyToPath message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        KeyToPath.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.mode);
                            return writer;
                        };

                        /**
                         * Encodes the specified KeyToPath message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.KeyToPath.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {k8s.io.api.core.v1.IKeyToPath} message KeyToPath message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        KeyToPath.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a KeyToPath message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.KeyToPath} KeyToPath
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        KeyToPath.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.KeyToPath();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.key = reader.string();
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                case 3:
                                    message.mode = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a KeyToPath message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.KeyToPath} KeyToPath
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        KeyToPath.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a KeyToPath message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        KeyToPath.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.mode != null && message.hasOwnProperty("mode"))
                                if (!$util.isInteger(message.mode))
                                    return "mode: integer expected";
                            return null;
                        };

                        /**
                         * Creates a KeyToPath message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.KeyToPath} KeyToPath
                         */
                        KeyToPath.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.KeyToPath)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.KeyToPath();
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.mode != null)
                                message.mode = object.mode | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a KeyToPath message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @static
                         * @param {k8s.io.api.core.v1.KeyToPath} message KeyToPath
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        KeyToPath.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.key = "";
                                object.path = "";
                                object.mode = 0;
                            }
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.mode != null && message.hasOwnProperty("mode"))
                                object.mode = message.mode;
                            return object;
                        };

                        /**
                         * Converts this KeyToPath to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.KeyToPath
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        KeyToPath.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return KeyToPath;
                    })();

                    v1.Lifecycle = (function() {

                        /**
                         * Properties of a Lifecycle.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILifecycle
                         * @property {k8s.io.api.core.v1.IHandler|null} [postStart] Lifecycle postStart
                         * @property {k8s.io.api.core.v1.IHandler|null} [preStop] Lifecycle preStop
                         */

                        /**
                         * Constructs a new Lifecycle.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Lifecycle.
                         * @implements ILifecycle
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILifecycle=} [properties] Properties to set
                         */
                        function Lifecycle(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Lifecycle postStart.
                         * @member {k8s.io.api.core.v1.IHandler|null|undefined} postStart
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @instance
                         */
                        Lifecycle.prototype.postStart = null;

                        /**
                         * Lifecycle preStop.
                         * @member {k8s.io.api.core.v1.IHandler|null|undefined} preStop
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @instance
                         */
                        Lifecycle.prototype.preStop = null;

                        /**
                         * Creates a new Lifecycle instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {k8s.io.api.core.v1.ILifecycle=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Lifecycle} Lifecycle instance
                         */
                        Lifecycle.create = function create(properties) {
                            return new Lifecycle(properties);
                        };

                        /**
                         * Encodes the specified Lifecycle message. Does not implicitly {@link k8s.io.api.core.v1.Lifecycle.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {k8s.io.api.core.v1.ILifecycle} message Lifecycle message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Lifecycle.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.postStart != null && Object.hasOwnProperty.call(message, "postStart"))
                                $root.k8s.io.api.core.v1.Handler.encode(message.postStart, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.preStop != null && Object.hasOwnProperty.call(message, "preStop"))
                                $root.k8s.io.api.core.v1.Handler.encode(message.preStop, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Lifecycle message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Lifecycle.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {k8s.io.api.core.v1.ILifecycle} message Lifecycle message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Lifecycle.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Lifecycle message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Lifecycle} Lifecycle
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Lifecycle.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Lifecycle();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.postStart = $root.k8s.io.api.core.v1.Handler.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.preStop = $root.k8s.io.api.core.v1.Handler.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Lifecycle message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Lifecycle} Lifecycle
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Lifecycle.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Lifecycle message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Lifecycle.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.postStart != null && message.hasOwnProperty("postStart")) {
                                var error = $root.k8s.io.api.core.v1.Handler.verify(message.postStart);
                                if (error)
                                    return "postStart." + error;
                            }
                            if (message.preStop != null && message.hasOwnProperty("preStop")) {
                                var error = $root.k8s.io.api.core.v1.Handler.verify(message.preStop);
                                if (error)
                                    return "preStop." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Lifecycle message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Lifecycle} Lifecycle
                         */
                        Lifecycle.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Lifecycle)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Lifecycle();
                            if (object.postStart != null) {
                                if (typeof object.postStart !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Lifecycle.postStart: object expected");
                                message.postStart = $root.k8s.io.api.core.v1.Handler.fromObject(object.postStart);
                            }
                            if (object.preStop != null) {
                                if (typeof object.preStop !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Lifecycle.preStop: object expected");
                                message.preStop = $root.k8s.io.api.core.v1.Handler.fromObject(object.preStop);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Lifecycle message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @static
                         * @param {k8s.io.api.core.v1.Lifecycle} message Lifecycle
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Lifecycle.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.postStart = null;
                                object.preStop = null;
                            }
                            if (message.postStart != null && message.hasOwnProperty("postStart"))
                                object.postStart = $root.k8s.io.api.core.v1.Handler.toObject(message.postStart, options);
                            if (message.preStop != null && message.hasOwnProperty("preStop"))
                                object.preStop = $root.k8s.io.api.core.v1.Handler.toObject(message.preStop, options);
                            return object;
                        };

                        /**
                         * Converts this Lifecycle to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Lifecycle
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Lifecycle.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Lifecycle;
                    })();

                    v1.LimitRange = (function() {

                        /**
                         * Properties of a LimitRange.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILimitRange
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] LimitRange metadata
                         * @property {k8s.io.api.core.v1.ILimitRangeSpec|null} [spec] LimitRange spec
                         */

                        /**
                         * Constructs a new LimitRange.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LimitRange.
                         * @implements ILimitRange
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILimitRange=} [properties] Properties to set
                         */
                        function LimitRange(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LimitRange metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @instance
                         */
                        LimitRange.prototype.metadata = null;

                        /**
                         * LimitRange spec.
                         * @member {k8s.io.api.core.v1.ILimitRangeSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @instance
                         */
                        LimitRange.prototype.spec = null;

                        /**
                         * Creates a new LimitRange instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRange=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LimitRange} LimitRange instance
                         */
                        LimitRange.create = function create(properties) {
                            return new LimitRange(properties);
                        };

                        /**
                         * Encodes the specified LimitRange message. Does not implicitly {@link k8s.io.api.core.v1.LimitRange.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRange} message LimitRange message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRange.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.LimitRangeSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified LimitRange message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LimitRange.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRange} message LimitRange message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRange.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LimitRange message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LimitRange} LimitRange
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRange.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LimitRange();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.LimitRangeSpec.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LimitRange message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LimitRange} LimitRange
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRange.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LimitRange message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LimitRange.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.LimitRangeSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a LimitRange message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LimitRange} LimitRange
                         */
                        LimitRange.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LimitRange)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LimitRange();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRange.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRange.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.LimitRangeSpec.fromObject(object.spec);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a LimitRange message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @static
                         * @param {k8s.io.api.core.v1.LimitRange} message LimitRange
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LimitRange.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.LimitRangeSpec.toObject(message.spec, options);
                            return object;
                        };

                        /**
                         * Converts this LimitRange to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LimitRange
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LimitRange.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LimitRange;
                    })();

                    v1.LimitRangeItem = (function() {

                        /**
                         * Properties of a LimitRangeItem.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILimitRangeItem
                         * @property {string|null} [type] LimitRangeItem type
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [max] LimitRangeItem max
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [min] LimitRangeItem min
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} ["default"] LimitRangeItem default
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [defaultRequest] LimitRangeItem defaultRequest
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [maxLimitRequestRatio] LimitRangeItem maxLimitRequestRatio
                         */

                        /**
                         * Constructs a new LimitRangeItem.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LimitRangeItem.
                         * @implements ILimitRangeItem
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILimitRangeItem=} [properties] Properties to set
                         */
                        function LimitRangeItem(properties) {
                            this.max = {};
                            this.min = {};
                            this["default"] = {};
                            this.defaultRequest = {};
                            this.maxLimitRequestRatio = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LimitRangeItem type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         */
                        LimitRangeItem.prototype.type = "";

                        /**
                         * LimitRangeItem max.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} max
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         */
                        LimitRangeItem.prototype.max = $util.emptyObject;

                        /**
                         * LimitRangeItem min.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} min
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         */
                        LimitRangeItem.prototype.min = $util.emptyObject;

                        /**
                         * LimitRangeItem default.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} default
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         */
                        LimitRangeItem.prototype["default"] = $util.emptyObject;

                        /**
                         * LimitRangeItem defaultRequest.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} defaultRequest
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         */
                        LimitRangeItem.prototype.defaultRequest = $util.emptyObject;

                        /**
                         * LimitRangeItem maxLimitRequestRatio.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} maxLimitRequestRatio
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         */
                        LimitRangeItem.prototype.maxLimitRequestRatio = $util.emptyObject;

                        /**
                         * Creates a new LimitRangeItem instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeItem=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LimitRangeItem} LimitRangeItem instance
                         */
                        LimitRangeItem.create = function create(properties) {
                            return new LimitRangeItem(properties);
                        };

                        /**
                         * Encodes the specified LimitRangeItem message. Does not implicitly {@link k8s.io.api.core.v1.LimitRangeItem.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeItem} message LimitRangeItem message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRangeItem.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                                for (var keys = Object.keys(message.max), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.max[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                                for (var keys = Object.keys(message.min), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.min[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
                                for (var keys = Object.keys(message["default"]), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message["default"][keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.defaultRequest != null && Object.hasOwnProperty.call(message, "defaultRequest"))
                                for (var keys = Object.keys(message.defaultRequest), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 5, wireType 2 =*/42).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.defaultRequest[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.maxLimitRequestRatio != null && Object.hasOwnProperty.call(message, "maxLimitRequestRatio"))
                                for (var keys = Object.keys(message.maxLimitRequestRatio), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.maxLimitRequestRatio[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            return writer;
                        };

                        /**
                         * Encodes the specified LimitRangeItem message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LimitRangeItem.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeItem} message LimitRangeItem message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRangeItem.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LimitRangeItem message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LimitRangeItem} LimitRangeItem
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRangeItem.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LimitRangeItem(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    if (message.max === $util.emptyObject)
                                        message.max = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.max[key] = value;
                                    break;
                                case 3:
                                    if (message.min === $util.emptyObject)
                                        message.min = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.min[key] = value;
                                    break;
                                case 4:
                                    if (message["default"] === $util.emptyObject)
                                        message["default"] = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message["default"][key] = value;
                                    break;
                                case 5:
                                    if (message.defaultRequest === $util.emptyObject)
                                        message.defaultRequest = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.defaultRequest[key] = value;
                                    break;
                                case 6:
                                    if (message.maxLimitRequestRatio === $util.emptyObject)
                                        message.maxLimitRequestRatio = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.maxLimitRequestRatio[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LimitRangeItem message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LimitRangeItem} LimitRangeItem
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRangeItem.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LimitRangeItem message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LimitRangeItem.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.max != null && message.hasOwnProperty("max")) {
                                if (!$util.isObject(message.max))
                                    return "max: object expected";
                                var key = Object.keys(message.max);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.max[key[i]]);
                                    if (error)
                                        return "max." + error;
                                }
                            }
                            if (message.min != null && message.hasOwnProperty("min")) {
                                if (!$util.isObject(message.min))
                                    return "min: object expected";
                                var key = Object.keys(message.min);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.min[key[i]]);
                                    if (error)
                                        return "min." + error;
                                }
                            }
                            if (message["default"] != null && message.hasOwnProperty("default")) {
                                if (!$util.isObject(message["default"]))
                                    return "default: object expected";
                                var key = Object.keys(message["default"]);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message["default"][key[i]]);
                                    if (error)
                                        return "default." + error;
                                }
                            }
                            if (message.defaultRequest != null && message.hasOwnProperty("defaultRequest")) {
                                if (!$util.isObject(message.defaultRequest))
                                    return "defaultRequest: object expected";
                                var key = Object.keys(message.defaultRequest);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.defaultRequest[key[i]]);
                                    if (error)
                                        return "defaultRequest." + error;
                                }
                            }
                            if (message.maxLimitRequestRatio != null && message.hasOwnProperty("maxLimitRequestRatio")) {
                                if (!$util.isObject(message.maxLimitRequestRatio))
                                    return "maxLimitRequestRatio: object expected";
                                var key = Object.keys(message.maxLimitRequestRatio);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.maxLimitRequestRatio[key[i]]);
                                    if (error)
                                        return "maxLimitRequestRatio." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a LimitRangeItem message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LimitRangeItem} LimitRangeItem
                         */
                        LimitRangeItem.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LimitRangeItem)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LimitRangeItem();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.max) {
                                if (typeof object.max !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.max: object expected");
                                message.max = {};
                                for (var keys = Object.keys(object.max), i = 0; i < keys.length; ++i) {
                                    if (typeof object.max[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.max: object expected");
                                    message.max[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.max[keys[i]]);
                                }
                            }
                            if (object.min) {
                                if (typeof object.min !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.min: object expected");
                                message.min = {};
                                for (var keys = Object.keys(object.min), i = 0; i < keys.length; ++i) {
                                    if (typeof object.min[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.min: object expected");
                                    message.min[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.min[keys[i]]);
                                }
                            }
                            if (object["default"]) {
                                if (typeof object["default"] !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.default: object expected");
                                message["default"] = {};
                                for (var keys = Object.keys(object["default"]), i = 0; i < keys.length; ++i) {
                                    if (typeof object["default"][keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.default: object expected");
                                    message["default"][keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object["default"][keys[i]]);
                                }
                            }
                            if (object.defaultRequest) {
                                if (typeof object.defaultRequest !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.defaultRequest: object expected");
                                message.defaultRequest = {};
                                for (var keys = Object.keys(object.defaultRequest), i = 0; i < keys.length; ++i) {
                                    if (typeof object.defaultRequest[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.defaultRequest: object expected");
                                    message.defaultRequest[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.defaultRequest[keys[i]]);
                                }
                            }
                            if (object.maxLimitRequestRatio) {
                                if (typeof object.maxLimitRequestRatio !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.maxLimitRequestRatio: object expected");
                                message.maxLimitRequestRatio = {};
                                for (var keys = Object.keys(object.maxLimitRequestRatio), i = 0; i < keys.length; ++i) {
                                    if (typeof object.maxLimitRequestRatio[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeItem.maxLimitRequestRatio: object expected");
                                    message.maxLimitRequestRatio[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.maxLimitRequestRatio[keys[i]]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a LimitRangeItem message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @static
                         * @param {k8s.io.api.core.v1.LimitRangeItem} message LimitRangeItem
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LimitRangeItem.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults) {
                                object.max = {};
                                object.min = {};
                                object["default"] = {};
                                object.defaultRequest = {};
                                object.maxLimitRequestRatio = {};
                            }
                            if (options.defaults)
                                object.type = "";
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            var keys2;
                            if (message.max && (keys2 = Object.keys(message.max)).length) {
                                object.max = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.max[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.max[keys2[j]], options);
                            }
                            if (message.min && (keys2 = Object.keys(message.min)).length) {
                                object.min = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.min[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.min[keys2[j]], options);
                            }
                            if (message["default"] && (keys2 = Object.keys(message["default"])).length) {
                                object["default"] = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object["default"][keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message["default"][keys2[j]], options);
                            }
                            if (message.defaultRequest && (keys2 = Object.keys(message.defaultRequest)).length) {
                                object.defaultRequest = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.defaultRequest[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.defaultRequest[keys2[j]], options);
                            }
                            if (message.maxLimitRequestRatio && (keys2 = Object.keys(message.maxLimitRequestRatio)).length) {
                                object.maxLimitRequestRatio = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.maxLimitRequestRatio[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.maxLimitRequestRatio[keys2[j]], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this LimitRangeItem to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LimitRangeItem
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LimitRangeItem.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LimitRangeItem;
                    })();

                    v1.LimitRangeList = (function() {

                        /**
                         * Properties of a LimitRangeList.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILimitRangeList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] LimitRangeList metadata
                         * @property {Array.<k8s.io.api.core.v1.ILimitRange>|null} [items] LimitRangeList items
                         */

                        /**
                         * Constructs a new LimitRangeList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LimitRangeList.
                         * @implements ILimitRangeList
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILimitRangeList=} [properties] Properties to set
                         */
                        function LimitRangeList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LimitRangeList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @instance
                         */
                        LimitRangeList.prototype.metadata = null;

                        /**
                         * LimitRangeList items.
                         * @member {Array.<k8s.io.api.core.v1.ILimitRange>} items
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @instance
                         */
                        LimitRangeList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new LimitRangeList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LimitRangeList} LimitRangeList instance
                         */
                        LimitRangeList.create = function create(properties) {
                            return new LimitRangeList(properties);
                        };

                        /**
                         * Encodes the specified LimitRangeList message. Does not implicitly {@link k8s.io.api.core.v1.LimitRangeList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeList} message LimitRangeList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRangeList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.LimitRange.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified LimitRangeList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LimitRangeList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeList} message LimitRangeList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRangeList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LimitRangeList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LimitRangeList} LimitRangeList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRangeList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LimitRangeList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.LimitRange.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LimitRangeList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LimitRangeList} LimitRangeList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRangeList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LimitRangeList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LimitRangeList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.LimitRange.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a LimitRangeList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LimitRangeList} LimitRangeList
                         */
                        LimitRangeList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LimitRangeList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LimitRangeList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.LimitRange.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a LimitRangeList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @static
                         * @param {k8s.io.api.core.v1.LimitRangeList} message LimitRangeList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LimitRangeList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.LimitRange.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this LimitRangeList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LimitRangeList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LimitRangeList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LimitRangeList;
                    })();

                    v1.LimitRangeSpec = (function() {

                        /**
                         * Properties of a LimitRangeSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILimitRangeSpec
                         * @property {Array.<k8s.io.api.core.v1.ILimitRangeItem>|null} [limits] LimitRangeSpec limits
                         */

                        /**
                         * Constructs a new LimitRangeSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LimitRangeSpec.
                         * @implements ILimitRangeSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILimitRangeSpec=} [properties] Properties to set
                         */
                        function LimitRangeSpec(properties) {
                            this.limits = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LimitRangeSpec limits.
                         * @member {Array.<k8s.io.api.core.v1.ILimitRangeItem>} limits
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @instance
                         */
                        LimitRangeSpec.prototype.limits = $util.emptyArray;

                        /**
                         * Creates a new LimitRangeSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LimitRangeSpec} LimitRangeSpec instance
                         */
                        LimitRangeSpec.create = function create(properties) {
                            return new LimitRangeSpec(properties);
                        };

                        /**
                         * Encodes the specified LimitRangeSpec message. Does not implicitly {@link k8s.io.api.core.v1.LimitRangeSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeSpec} message LimitRangeSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRangeSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.limits != null && message.limits.length)
                                for (var i = 0; i < message.limits.length; ++i)
                                    $root.k8s.io.api.core.v1.LimitRangeItem.encode(message.limits[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified LimitRangeSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LimitRangeSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.ILimitRangeSpec} message LimitRangeSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LimitRangeSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LimitRangeSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LimitRangeSpec} LimitRangeSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRangeSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LimitRangeSpec();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.limits && message.limits.length))
                                        message.limits = [];
                                    message.limits.push($root.k8s.io.api.core.v1.LimitRangeItem.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LimitRangeSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LimitRangeSpec} LimitRangeSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LimitRangeSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LimitRangeSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LimitRangeSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.limits != null && message.hasOwnProperty("limits")) {
                                if (!Array.isArray(message.limits))
                                    return "limits: array expected";
                                for (var i = 0; i < message.limits.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.LimitRangeItem.verify(message.limits[i]);
                                    if (error)
                                        return "limits." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a LimitRangeSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LimitRangeSpec} LimitRangeSpec
                         */
                        LimitRangeSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LimitRangeSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LimitRangeSpec();
                            if (object.limits) {
                                if (!Array.isArray(object.limits))
                                    throw TypeError(".k8s.io.api.core.v1.LimitRangeSpec.limits: array expected");
                                message.limits = [];
                                for (var i = 0; i < object.limits.length; ++i) {
                                    if (typeof object.limits[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LimitRangeSpec.limits: object expected");
                                    message.limits[i] = $root.k8s.io.api.core.v1.LimitRangeItem.fromObject(object.limits[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a LimitRangeSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.LimitRangeSpec} message LimitRangeSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LimitRangeSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.limits = [];
                            if (message.limits && message.limits.length) {
                                object.limits = [];
                                for (var j = 0; j < message.limits.length; ++j)
                                    object.limits[j] = $root.k8s.io.api.core.v1.LimitRangeItem.toObject(message.limits[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this LimitRangeSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LimitRangeSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LimitRangeSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LimitRangeSpec;
                    })();

                    v1.List = (function() {

                        /**
                         * Properties of a List.
                         * @memberof k8s.io.api.core.v1
                         * @interface IList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] List metadata
                         * @property {Array.<k8s.io.apimachinery.pkg.runtime.IRawExtension>|null} [items] List items
                         */

                        /**
                         * Constructs a new List.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a List.
                         * @implements IList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IList=} [properties] Properties to set
                         */
                        function List(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * List metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.List
                         * @instance
                         */
                        List.prototype.metadata = null;

                        /**
                         * List items.
                         * @member {Array.<k8s.io.apimachinery.pkg.runtime.IRawExtension>} items
                         * @memberof k8s.io.api.core.v1.List
                         * @instance
                         */
                        List.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new List instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {k8s.io.api.core.v1.IList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.List} List instance
                         */
                        List.create = function create(properties) {
                            return new List(properties);
                        };

                        /**
                         * Encodes the specified List message. Does not implicitly {@link k8s.io.api.core.v1.List.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {k8s.io.api.core.v1.IList} message List message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        List.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.apimachinery.pkg.runtime.RawExtension.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified List message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.List.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {k8s.io.api.core.v1.IList} message List message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        List.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a List message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.List} List
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        List.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.List();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.apimachinery.pkg.runtime.RawExtension.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a List message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.List} List
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        List.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a List message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        List.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a List message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.List} List
                         */
                        List.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.List)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.List();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.List.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.List.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.List.items: object expected");
                                    message.items[i] = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a List message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.List
                         * @static
                         * @param {k8s.io.api.core.v1.List} message List
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        List.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this List to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.List
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        List.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return List;
                    })();

                    v1.LoadBalancerIngress = (function() {

                        /**
                         * Properties of a LoadBalancerIngress.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILoadBalancerIngress
                         * @property {string|null} [ip] LoadBalancerIngress ip
                         * @property {string|null} [hostname] LoadBalancerIngress hostname
                         * @property {Array.<k8s.io.api.core.v1.IPortStatus>|null} [ports] LoadBalancerIngress ports
                         */

                        /**
                         * Constructs a new LoadBalancerIngress.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LoadBalancerIngress.
                         * @implements ILoadBalancerIngress
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILoadBalancerIngress=} [properties] Properties to set
                         */
                        function LoadBalancerIngress(properties) {
                            this.ports = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LoadBalancerIngress ip.
                         * @member {string} ip
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @instance
                         */
                        LoadBalancerIngress.prototype.ip = "";

                        /**
                         * LoadBalancerIngress hostname.
                         * @member {string} hostname
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @instance
                         */
                        LoadBalancerIngress.prototype.hostname = "";

                        /**
                         * LoadBalancerIngress ports.
                         * @member {Array.<k8s.io.api.core.v1.IPortStatus>} ports
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @instance
                         */
                        LoadBalancerIngress.prototype.ports = $util.emptyArray;

                        /**
                         * Creates a new LoadBalancerIngress instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {k8s.io.api.core.v1.ILoadBalancerIngress=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LoadBalancerIngress} LoadBalancerIngress instance
                         */
                        LoadBalancerIngress.create = function create(properties) {
                            return new LoadBalancerIngress(properties);
                        };

                        /**
                         * Encodes the specified LoadBalancerIngress message. Does not implicitly {@link k8s.io.api.core.v1.LoadBalancerIngress.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {k8s.io.api.core.v1.ILoadBalancerIngress} message LoadBalancerIngress message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LoadBalancerIngress.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ip);
                            if (message.hostname != null && Object.hasOwnProperty.call(message, "hostname"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.hostname);
                            if (message.ports != null && message.ports.length)
                                for (var i = 0; i < message.ports.length; ++i)
                                    $root.k8s.io.api.core.v1.PortStatus.encode(message.ports[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified LoadBalancerIngress message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LoadBalancerIngress.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {k8s.io.api.core.v1.ILoadBalancerIngress} message LoadBalancerIngress message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LoadBalancerIngress.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LoadBalancerIngress message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LoadBalancerIngress} LoadBalancerIngress
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LoadBalancerIngress.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LoadBalancerIngress();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.ip = reader.string();
                                    break;
                                case 2:
                                    message.hostname = reader.string();
                                    break;
                                case 4:
                                    if (!(message.ports && message.ports.length))
                                        message.ports = [];
                                    message.ports.push($root.k8s.io.api.core.v1.PortStatus.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LoadBalancerIngress message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LoadBalancerIngress} LoadBalancerIngress
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LoadBalancerIngress.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LoadBalancerIngress message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LoadBalancerIngress.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                if (!$util.isString(message.ip))
                                    return "ip: string expected";
                            if (message.hostname != null && message.hasOwnProperty("hostname"))
                                if (!$util.isString(message.hostname))
                                    return "hostname: string expected";
                            if (message.ports != null && message.hasOwnProperty("ports")) {
                                if (!Array.isArray(message.ports))
                                    return "ports: array expected";
                                for (var i = 0; i < message.ports.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PortStatus.verify(message.ports[i]);
                                    if (error)
                                        return "ports." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a LoadBalancerIngress message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LoadBalancerIngress} LoadBalancerIngress
                         */
                        LoadBalancerIngress.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LoadBalancerIngress)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LoadBalancerIngress();
                            if (object.ip != null)
                                message.ip = String(object.ip);
                            if (object.hostname != null)
                                message.hostname = String(object.hostname);
                            if (object.ports) {
                                if (!Array.isArray(object.ports))
                                    throw TypeError(".k8s.io.api.core.v1.LoadBalancerIngress.ports: array expected");
                                message.ports = [];
                                for (var i = 0; i < object.ports.length; ++i) {
                                    if (typeof object.ports[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LoadBalancerIngress.ports: object expected");
                                    message.ports[i] = $root.k8s.io.api.core.v1.PortStatus.fromObject(object.ports[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a LoadBalancerIngress message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @static
                         * @param {k8s.io.api.core.v1.LoadBalancerIngress} message LoadBalancerIngress
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LoadBalancerIngress.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.ports = [];
                            if (options.defaults) {
                                object.ip = "";
                                object.hostname = "";
                            }
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                object.ip = message.ip;
                            if (message.hostname != null && message.hasOwnProperty("hostname"))
                                object.hostname = message.hostname;
                            if (message.ports && message.ports.length) {
                                object.ports = [];
                                for (var j = 0; j < message.ports.length; ++j)
                                    object.ports[j] = $root.k8s.io.api.core.v1.PortStatus.toObject(message.ports[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this LoadBalancerIngress to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LoadBalancerIngress
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LoadBalancerIngress.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LoadBalancerIngress;
                    })();

                    v1.LoadBalancerStatus = (function() {

                        /**
                         * Properties of a LoadBalancerStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILoadBalancerStatus
                         * @property {Array.<k8s.io.api.core.v1.ILoadBalancerIngress>|null} [ingress] LoadBalancerStatus ingress
                         */

                        /**
                         * Constructs a new LoadBalancerStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LoadBalancerStatus.
                         * @implements ILoadBalancerStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILoadBalancerStatus=} [properties] Properties to set
                         */
                        function LoadBalancerStatus(properties) {
                            this.ingress = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LoadBalancerStatus ingress.
                         * @member {Array.<k8s.io.api.core.v1.ILoadBalancerIngress>} ingress
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @instance
                         */
                        LoadBalancerStatus.prototype.ingress = $util.emptyArray;

                        /**
                         * Creates a new LoadBalancerStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ILoadBalancerStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LoadBalancerStatus} LoadBalancerStatus instance
                         */
                        LoadBalancerStatus.create = function create(properties) {
                            return new LoadBalancerStatus(properties);
                        };

                        /**
                         * Encodes the specified LoadBalancerStatus message. Does not implicitly {@link k8s.io.api.core.v1.LoadBalancerStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ILoadBalancerStatus} message LoadBalancerStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LoadBalancerStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ingress != null && message.ingress.length)
                                for (var i = 0; i < message.ingress.length; ++i)
                                    $root.k8s.io.api.core.v1.LoadBalancerIngress.encode(message.ingress[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified LoadBalancerStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LoadBalancerStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ILoadBalancerStatus} message LoadBalancerStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LoadBalancerStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LoadBalancerStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LoadBalancerStatus} LoadBalancerStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LoadBalancerStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LoadBalancerStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.ingress && message.ingress.length))
                                        message.ingress = [];
                                    message.ingress.push($root.k8s.io.api.core.v1.LoadBalancerIngress.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LoadBalancerStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LoadBalancerStatus} LoadBalancerStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LoadBalancerStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LoadBalancerStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LoadBalancerStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ingress != null && message.hasOwnProperty("ingress")) {
                                if (!Array.isArray(message.ingress))
                                    return "ingress: array expected";
                                for (var i = 0; i < message.ingress.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.LoadBalancerIngress.verify(message.ingress[i]);
                                    if (error)
                                        return "ingress." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a LoadBalancerStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LoadBalancerStatus} LoadBalancerStatus
                         */
                        LoadBalancerStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LoadBalancerStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LoadBalancerStatus();
                            if (object.ingress) {
                                if (!Array.isArray(object.ingress))
                                    throw TypeError(".k8s.io.api.core.v1.LoadBalancerStatus.ingress: array expected");
                                message.ingress = [];
                                for (var i = 0; i < object.ingress.length; ++i) {
                                    if (typeof object.ingress[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.LoadBalancerStatus.ingress: object expected");
                                    message.ingress[i] = $root.k8s.io.api.core.v1.LoadBalancerIngress.fromObject(object.ingress[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a LoadBalancerStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.LoadBalancerStatus} message LoadBalancerStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LoadBalancerStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.ingress = [];
                            if (message.ingress && message.ingress.length) {
                                object.ingress = [];
                                for (var j = 0; j < message.ingress.length; ++j)
                                    object.ingress[j] = $root.k8s.io.api.core.v1.LoadBalancerIngress.toObject(message.ingress[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this LoadBalancerStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LoadBalancerStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LoadBalancerStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LoadBalancerStatus;
                    })();

                    v1.LocalObjectReference = (function() {

                        /**
                         * Properties of a LocalObjectReference.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILocalObjectReference
                         * @property {string|null} [name] LocalObjectReference name
                         */

                        /**
                         * Constructs a new LocalObjectReference.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LocalObjectReference.
                         * @implements ILocalObjectReference
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILocalObjectReference=} [properties] Properties to set
                         */
                        function LocalObjectReference(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LocalObjectReference name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @instance
                         */
                        LocalObjectReference.prototype.name = "";

                        /**
                         * Creates a new LocalObjectReference instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ILocalObjectReference=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LocalObjectReference} LocalObjectReference instance
                         */
                        LocalObjectReference.create = function create(properties) {
                            return new LocalObjectReference(properties);
                        };

                        /**
                         * Encodes the specified LocalObjectReference message. Does not implicitly {@link k8s.io.api.core.v1.LocalObjectReference.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ILocalObjectReference} message LocalObjectReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LocalObjectReference.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            return writer;
                        };

                        /**
                         * Encodes the specified LocalObjectReference message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LocalObjectReference.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ILocalObjectReference} message LocalObjectReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LocalObjectReference.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LocalObjectReference message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LocalObjectReference} LocalObjectReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LocalObjectReference.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LocalObjectReference();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LocalObjectReference message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LocalObjectReference} LocalObjectReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LocalObjectReference.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LocalObjectReference message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LocalObjectReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            return null;
                        };

                        /**
                         * Creates a LocalObjectReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LocalObjectReference} LocalObjectReference
                         */
                        LocalObjectReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LocalObjectReference)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LocalObjectReference();
                            if (object.name != null)
                                message.name = String(object.name);
                            return message;
                        };

                        /**
                         * Creates a plain object from a LocalObjectReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.LocalObjectReference} message LocalObjectReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LocalObjectReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.name = "";
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            return object;
                        };

                        /**
                         * Converts this LocalObjectReference to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LocalObjectReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LocalObjectReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LocalObjectReference;
                    })();

                    v1.LocalVolumeSource = (function() {

                        /**
                         * Properties of a LocalVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ILocalVolumeSource
                         * @property {string|null} [path] LocalVolumeSource path
                         * @property {string|null} [fsType] LocalVolumeSource fsType
                         */

                        /**
                         * Constructs a new LocalVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a LocalVolumeSource.
                         * @implements ILocalVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ILocalVolumeSource=} [properties] Properties to set
                         */
                        function LocalVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * LocalVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @instance
                         */
                        LocalVolumeSource.prototype.path = "";

                        /**
                         * LocalVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @instance
                         */
                        LocalVolumeSource.prototype.fsType = "";

                        /**
                         * Creates a new LocalVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ILocalVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.LocalVolumeSource} LocalVolumeSource instance
                         */
                        LocalVolumeSource.create = function create(properties) {
                            return new LocalVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified LocalVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.LocalVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ILocalVolumeSource} message LocalVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LocalVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            return writer;
                        };

                        /**
                         * Encodes the specified LocalVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.LocalVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ILocalVolumeSource} message LocalVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        LocalVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a LocalVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.LocalVolumeSource} LocalVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LocalVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.LocalVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a LocalVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.LocalVolumeSource} LocalVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        LocalVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a LocalVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        LocalVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            return null;
                        };

                        /**
                         * Creates a LocalVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.LocalVolumeSource} LocalVolumeSource
                         */
                        LocalVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.LocalVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.LocalVolumeSource();
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            return message;
                        };

                        /**
                         * Creates a plain object from a LocalVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.LocalVolumeSource} message LocalVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        LocalVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.path = "";
                                object.fsType = "";
                            }
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            return object;
                        };

                        /**
                         * Converts this LocalVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.LocalVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        LocalVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return LocalVolumeSource;
                    })();

                    v1.NFSVolumeSource = (function() {

                        /**
                         * Properties of a NFSVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface INFSVolumeSource
                         * @property {string|null} [server] NFSVolumeSource server
                         * @property {string|null} [path] NFSVolumeSource path
                         * @property {boolean|null} [readOnly] NFSVolumeSource readOnly
                         */

                        /**
                         * Constructs a new NFSVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NFSVolumeSource.
                         * @implements INFSVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.INFSVolumeSource=} [properties] Properties to set
                         */
                        function NFSVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NFSVolumeSource server.
                         * @member {string} server
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @instance
                         */
                        NFSVolumeSource.prototype.server = "";

                        /**
                         * NFSVolumeSource path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @instance
                         */
                        NFSVolumeSource.prototype.path = "";

                        /**
                         * NFSVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @instance
                         */
                        NFSVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new NFSVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.INFSVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NFSVolumeSource} NFSVolumeSource instance
                         */
                        NFSVolumeSource.create = function create(properties) {
                            return new NFSVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified NFSVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.NFSVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.INFSVolumeSource} message NFSVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NFSVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.server != null && Object.hasOwnProperty.call(message, "server"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.server);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified NFSVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NFSVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.INFSVolumeSource} message NFSVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NFSVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NFSVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NFSVolumeSource} NFSVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NFSVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NFSVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.server = reader.string();
                                    break;
                                case 2:
                                    message.path = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NFSVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NFSVolumeSource} NFSVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NFSVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NFSVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NFSVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.server != null && message.hasOwnProperty("server"))
                                if (!$util.isString(message.server))
                                    return "server: string expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a NFSVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NFSVolumeSource} NFSVolumeSource
                         */
                        NFSVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NFSVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NFSVolumeSource();
                            if (object.server != null)
                                message.server = String(object.server);
                            if (object.path != null)
                                message.path = String(object.path);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NFSVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.NFSVolumeSource} message NFSVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NFSVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.server = "";
                                object.path = "";
                                object.readOnly = false;
                            }
                            if (message.server != null && message.hasOwnProperty("server"))
                                object.server = message.server;
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this NFSVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NFSVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NFSVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NFSVolumeSource;
                    })();

                    v1.Namespace = (function() {

                        /**
                         * Properties of a Namespace.
                         * @memberof k8s.io.api.core.v1
                         * @interface INamespace
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Namespace metadata
                         * @property {k8s.io.api.core.v1.INamespaceSpec|null} [spec] Namespace spec
                         * @property {k8s.io.api.core.v1.INamespaceStatus|null} [status] Namespace status
                         */

                        /**
                         * Constructs a new Namespace.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Namespace.
                         * @implements INamespace
                         * @constructor
                         * @param {k8s.io.api.core.v1.INamespace=} [properties] Properties to set
                         */
                        function Namespace(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Namespace metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @instance
                         */
                        Namespace.prototype.metadata = null;

                        /**
                         * Namespace spec.
                         * @member {k8s.io.api.core.v1.INamespaceSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @instance
                         */
                        Namespace.prototype.spec = null;

                        /**
                         * Namespace status.
                         * @member {k8s.io.api.core.v1.INamespaceStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @instance
                         */
                        Namespace.prototype.status = null;

                        /**
                         * Creates a new Namespace instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {k8s.io.api.core.v1.INamespace=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Namespace} Namespace instance
                         */
                        Namespace.create = function create(properties) {
                            return new Namespace(properties);
                        };

                        /**
                         * Encodes the specified Namespace message. Does not implicitly {@link k8s.io.api.core.v1.Namespace.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {k8s.io.api.core.v1.INamespace} message Namespace message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Namespace.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.NamespaceSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.NamespaceStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Namespace message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Namespace.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {k8s.io.api.core.v1.INamespace} message Namespace message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Namespace.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Namespace message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Namespace} Namespace
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Namespace.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Namespace();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.NamespaceSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.NamespaceStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Namespace message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Namespace} Namespace
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Namespace.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Namespace message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Namespace.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.NamespaceSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.NamespaceStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Namespace message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Namespace} Namespace
                         */
                        Namespace.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Namespace)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Namespace();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Namespace.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Namespace.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.NamespaceSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Namespace.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.NamespaceStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Namespace message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @static
                         * @param {k8s.io.api.core.v1.Namespace} message Namespace
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Namespace.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.NamespaceSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.NamespaceStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this Namespace to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Namespace
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Namespace.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Namespace;
                    })();

                    v1.NamespaceCondition = (function() {

                        /**
                         * Properties of a NamespaceCondition.
                         * @memberof k8s.io.api.core.v1
                         * @interface INamespaceCondition
                         * @property {string|null} [type] NamespaceCondition type
                         * @property {string|null} [status] NamespaceCondition status
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTransitionTime] NamespaceCondition lastTransitionTime
                         * @property {string|null} [reason] NamespaceCondition reason
                         * @property {string|null} [message] NamespaceCondition message
                         */

                        /**
                         * Constructs a new NamespaceCondition.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NamespaceCondition.
                         * @implements INamespaceCondition
                         * @constructor
                         * @param {k8s.io.api.core.v1.INamespaceCondition=} [properties] Properties to set
                         */
                        function NamespaceCondition(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NamespaceCondition type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @instance
                         */
                        NamespaceCondition.prototype.type = "";

                        /**
                         * NamespaceCondition status.
                         * @member {string} status
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @instance
                         */
                        NamespaceCondition.prototype.status = "";

                        /**
                         * NamespaceCondition lastTransitionTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTransitionTime
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @instance
                         */
                        NamespaceCondition.prototype.lastTransitionTime = null;

                        /**
                         * NamespaceCondition reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @instance
                         */
                        NamespaceCondition.prototype.reason = "";

                        /**
                         * NamespaceCondition message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @instance
                         */
                        NamespaceCondition.prototype.message = "";

                        /**
                         * Creates a new NamespaceCondition instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceCondition=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NamespaceCondition} NamespaceCondition instance
                         */
                        NamespaceCondition.create = function create(properties) {
                            return new NamespaceCondition(properties);
                        };

                        /**
                         * Encodes the specified NamespaceCondition message. Does not implicitly {@link k8s.io.api.core.v1.NamespaceCondition.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceCondition} message NamespaceCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceCondition.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.lastTransitionTime != null && Object.hasOwnProperty.call(message, "lastTransitionTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTransitionTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified NamespaceCondition message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NamespaceCondition.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceCondition} message NamespaceCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceCondition.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NamespaceCondition message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NamespaceCondition} NamespaceCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceCondition.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NamespaceCondition();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 4:
                                    message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.reason = reader.string();
                                    break;
                                case 6:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NamespaceCondition message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NamespaceCondition} NamespaceCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceCondition.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NamespaceCondition message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NamespaceCondition.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTransitionTime);
                                if (error)
                                    return "lastTransitionTime." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a NamespaceCondition message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NamespaceCondition} NamespaceCondition
                         */
                        NamespaceCondition.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NamespaceCondition)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NamespaceCondition();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.lastTransitionTime != null) {
                                if (typeof object.lastTransitionTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NamespaceCondition.lastTransitionTime: object expected");
                                message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTransitionTime);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NamespaceCondition message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @static
                         * @param {k8s.io.api.core.v1.NamespaceCondition} message NamespaceCondition
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NamespaceCondition.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.status = "";
                                object.lastTransitionTime = null;
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime"))
                                object.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTransitionTime, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this NamespaceCondition to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NamespaceCondition
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NamespaceCondition.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NamespaceCondition;
                    })();

                    v1.NamespaceList = (function() {

                        /**
                         * Properties of a NamespaceList.
                         * @memberof k8s.io.api.core.v1
                         * @interface INamespaceList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] NamespaceList metadata
                         * @property {Array.<k8s.io.api.core.v1.INamespace>|null} [items] NamespaceList items
                         */

                        /**
                         * Constructs a new NamespaceList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NamespaceList.
                         * @implements INamespaceList
                         * @constructor
                         * @param {k8s.io.api.core.v1.INamespaceList=} [properties] Properties to set
                         */
                        function NamespaceList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NamespaceList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @instance
                         */
                        NamespaceList.prototype.metadata = null;

                        /**
                         * NamespaceList items.
                         * @member {Array.<k8s.io.api.core.v1.INamespace>} items
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @instance
                         */
                        NamespaceList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new NamespaceList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NamespaceList} NamespaceList instance
                         */
                        NamespaceList.create = function create(properties) {
                            return new NamespaceList(properties);
                        };

                        /**
                         * Encodes the specified NamespaceList message. Does not implicitly {@link k8s.io.api.core.v1.NamespaceList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceList} message NamespaceList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Namespace.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NamespaceList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NamespaceList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceList} message NamespaceList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NamespaceList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NamespaceList} NamespaceList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NamespaceList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Namespace.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NamespaceList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NamespaceList} NamespaceList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NamespaceList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NamespaceList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Namespace.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NamespaceList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NamespaceList} NamespaceList
                         */
                        NamespaceList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NamespaceList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NamespaceList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NamespaceList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.NamespaceList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NamespaceList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Namespace.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NamespaceList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @static
                         * @param {k8s.io.api.core.v1.NamespaceList} message NamespaceList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NamespaceList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Namespace.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NamespaceList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NamespaceList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NamespaceList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NamespaceList;
                    })();

                    v1.NamespaceSpec = (function() {

                        /**
                         * Properties of a NamespaceSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface INamespaceSpec
                         * @property {Array.<string>|null} [finalizers] NamespaceSpec finalizers
                         */

                        /**
                         * Constructs a new NamespaceSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NamespaceSpec.
                         * @implements INamespaceSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.INamespaceSpec=} [properties] Properties to set
                         */
                        function NamespaceSpec(properties) {
                            this.finalizers = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NamespaceSpec finalizers.
                         * @member {Array.<string>} finalizers
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @instance
                         */
                        NamespaceSpec.prototype.finalizers = $util.emptyArray;

                        /**
                         * Creates a new NamespaceSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NamespaceSpec} NamespaceSpec instance
                         */
                        NamespaceSpec.create = function create(properties) {
                            return new NamespaceSpec(properties);
                        };

                        /**
                         * Encodes the specified NamespaceSpec message. Does not implicitly {@link k8s.io.api.core.v1.NamespaceSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceSpec} message NamespaceSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.finalizers != null && message.finalizers.length)
                                for (var i = 0; i < message.finalizers.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.finalizers[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified NamespaceSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NamespaceSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceSpec} message NamespaceSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NamespaceSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NamespaceSpec} NamespaceSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NamespaceSpec();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.finalizers && message.finalizers.length))
                                        message.finalizers = [];
                                    message.finalizers.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NamespaceSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NamespaceSpec} NamespaceSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NamespaceSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NamespaceSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.finalizers != null && message.hasOwnProperty("finalizers")) {
                                if (!Array.isArray(message.finalizers))
                                    return "finalizers: array expected";
                                for (var i = 0; i < message.finalizers.length; ++i)
                                    if (!$util.isString(message.finalizers[i]))
                                        return "finalizers: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a NamespaceSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NamespaceSpec} NamespaceSpec
                         */
                        NamespaceSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NamespaceSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NamespaceSpec();
                            if (object.finalizers) {
                                if (!Array.isArray(object.finalizers))
                                    throw TypeError(".k8s.io.api.core.v1.NamespaceSpec.finalizers: array expected");
                                message.finalizers = [];
                                for (var i = 0; i < object.finalizers.length; ++i)
                                    message.finalizers[i] = String(object.finalizers[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NamespaceSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.NamespaceSpec} message NamespaceSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NamespaceSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.finalizers = [];
                            if (message.finalizers && message.finalizers.length) {
                                object.finalizers = [];
                                for (var j = 0; j < message.finalizers.length; ++j)
                                    object.finalizers[j] = message.finalizers[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this NamespaceSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NamespaceSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NamespaceSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NamespaceSpec;
                    })();

                    v1.NamespaceStatus = (function() {

                        /**
                         * Properties of a NamespaceStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface INamespaceStatus
                         * @property {string|null} [phase] NamespaceStatus phase
                         * @property {Array.<k8s.io.api.core.v1.INamespaceCondition>|null} [conditions] NamespaceStatus conditions
                         */

                        /**
                         * Constructs a new NamespaceStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NamespaceStatus.
                         * @implements INamespaceStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.INamespaceStatus=} [properties] Properties to set
                         */
                        function NamespaceStatus(properties) {
                            this.conditions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NamespaceStatus phase.
                         * @member {string} phase
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @instance
                         */
                        NamespaceStatus.prototype.phase = "";

                        /**
                         * NamespaceStatus conditions.
                         * @member {Array.<k8s.io.api.core.v1.INamespaceCondition>} conditions
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @instance
                         */
                        NamespaceStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * Creates a new NamespaceStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NamespaceStatus} NamespaceStatus instance
                         */
                        NamespaceStatus.create = function create(properties) {
                            return new NamespaceStatus(properties);
                        };

                        /**
                         * Encodes the specified NamespaceStatus message. Does not implicitly {@link k8s.io.api.core.v1.NamespaceStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceStatus} message NamespaceStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phase);
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.api.core.v1.NamespaceCondition.encode(message.conditions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NamespaceStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NamespaceStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INamespaceStatus} message NamespaceStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NamespaceStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NamespaceStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NamespaceStatus} NamespaceStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NamespaceStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.phase = reader.string();
                                    break;
                                case 2:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.api.core.v1.NamespaceCondition.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NamespaceStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NamespaceStatus} NamespaceStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NamespaceStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NamespaceStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NamespaceStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                if (!$util.isString(message.phase))
                                    return "phase: string expected";
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.NamespaceCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NamespaceStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NamespaceStatus} NamespaceStatus
                         */
                        NamespaceStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NamespaceStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NamespaceStatus();
                            if (object.phase != null)
                                message.phase = String(object.phase);
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.NamespaceStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NamespaceStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.api.core.v1.NamespaceCondition.fromObject(object.conditions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NamespaceStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.NamespaceStatus} message NamespaceStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NamespaceStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.conditions = [];
                            if (options.defaults)
                                object.phase = "";
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                object.phase = message.phase;
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.api.core.v1.NamespaceCondition.toObject(message.conditions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NamespaceStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NamespaceStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NamespaceStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NamespaceStatus;
                    })();

                    v1.Node = (function() {

                        /**
                         * Properties of a Node.
                         * @memberof k8s.io.api.core.v1
                         * @interface INode
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Node metadata
                         * @property {k8s.io.api.core.v1.INodeSpec|null} [spec] Node spec
                         * @property {k8s.io.api.core.v1.INodeStatus|null} [status] Node status
                         */

                        /**
                         * Constructs a new Node.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Node.
                         * @implements INode
                         * @constructor
                         * @param {k8s.io.api.core.v1.INode=} [properties] Properties to set
                         */
                        function Node(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Node metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Node
                         * @instance
                         */
                        Node.prototype.metadata = null;

                        /**
                         * Node spec.
                         * @member {k8s.io.api.core.v1.INodeSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.Node
                         * @instance
                         */
                        Node.prototype.spec = null;

                        /**
                         * Node status.
                         * @member {k8s.io.api.core.v1.INodeStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.Node
                         * @instance
                         */
                        Node.prototype.status = null;

                        /**
                         * Creates a new Node instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {k8s.io.api.core.v1.INode=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Node} Node instance
                         */
                        Node.create = function create(properties) {
                            return new Node(properties);
                        };

                        /**
                         * Encodes the specified Node message. Does not implicitly {@link k8s.io.api.core.v1.Node.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {k8s.io.api.core.v1.INode} message Node message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Node.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.NodeSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.NodeStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Node message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Node.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {k8s.io.api.core.v1.INode} message Node message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Node.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Node message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Node} Node
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Node.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Node();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.NodeSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.NodeStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Node message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Node} Node
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Node.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Node message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Node.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.NodeSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.NodeStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Node message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Node} Node
                         */
                        Node.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Node)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Node();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Node.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Node.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.NodeSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Node.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.NodeStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Node message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Node
                         * @static
                         * @param {k8s.io.api.core.v1.Node} message Node
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Node.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.NodeSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.NodeStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this Node to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Node
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Node.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Node;
                    })();

                    v1.NodeAddress = (function() {

                        /**
                         * Properties of a NodeAddress.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeAddress
                         * @property {string|null} [type] NodeAddress type
                         * @property {string|null} [address] NodeAddress address
                         */

                        /**
                         * Constructs a new NodeAddress.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeAddress.
                         * @implements INodeAddress
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeAddress=} [properties] Properties to set
                         */
                        function NodeAddress(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeAddress type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @instance
                         */
                        NodeAddress.prototype.type = "";

                        /**
                         * NodeAddress address.
                         * @member {string} address
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @instance
                         */
                        NodeAddress.prototype.address = "";

                        /**
                         * Creates a new NodeAddress instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {k8s.io.api.core.v1.INodeAddress=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeAddress} NodeAddress instance
                         */
                        NodeAddress.create = function create(properties) {
                            return new NodeAddress(properties);
                        };

                        /**
                         * Encodes the specified NodeAddress message. Does not implicitly {@link k8s.io.api.core.v1.NodeAddress.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {k8s.io.api.core.v1.INodeAddress} message NodeAddress message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeAddress.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeAddress message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeAddress.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {k8s.io.api.core.v1.INodeAddress} message NodeAddress message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeAddress.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeAddress message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeAddress} NodeAddress
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeAddress.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeAddress();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.address = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeAddress message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeAddress} NodeAddress
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeAddress.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeAddress message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeAddress.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.address != null && message.hasOwnProperty("address"))
                                if (!$util.isString(message.address))
                                    return "address: string expected";
                            return null;
                        };

                        /**
                         * Creates a NodeAddress message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeAddress} NodeAddress
                         */
                        NodeAddress.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeAddress)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeAddress();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.address != null)
                                message.address = String(object.address);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeAddress message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @static
                         * @param {k8s.io.api.core.v1.NodeAddress} message NodeAddress
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeAddress.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.address = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.address != null && message.hasOwnProperty("address"))
                                object.address = message.address;
                            return object;
                        };

                        /**
                         * Converts this NodeAddress to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeAddress
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeAddress.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeAddress;
                    })();

                    v1.NodeAffinity = (function() {

                        /**
                         * Properties of a NodeAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeAffinity
                         * @property {k8s.io.api.core.v1.INodeSelector|null} [requiredDuringSchedulingIgnoredDuringExecution] NodeAffinity requiredDuringSchedulingIgnoredDuringExecution
                         * @property {Array.<k8s.io.api.core.v1.IPreferredSchedulingTerm>|null} [preferredDuringSchedulingIgnoredDuringExecution] NodeAffinity preferredDuringSchedulingIgnoredDuringExecution
                         */

                        /**
                         * Constructs a new NodeAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeAffinity.
                         * @implements INodeAffinity
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeAffinity=} [properties] Properties to set
                         */
                        function NodeAffinity(properties) {
                            this.preferredDuringSchedulingIgnoredDuringExecution = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeAffinity requiredDuringSchedulingIgnoredDuringExecution.
                         * @member {k8s.io.api.core.v1.INodeSelector|null|undefined} requiredDuringSchedulingIgnoredDuringExecution
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @instance
                         */
                        NodeAffinity.prototype.requiredDuringSchedulingIgnoredDuringExecution = null;

                        /**
                         * NodeAffinity preferredDuringSchedulingIgnoredDuringExecution.
                         * @member {Array.<k8s.io.api.core.v1.IPreferredSchedulingTerm>} preferredDuringSchedulingIgnoredDuringExecution
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @instance
                         */
                        NodeAffinity.prototype.preferredDuringSchedulingIgnoredDuringExecution = $util.emptyArray;

                        /**
                         * Creates a new NodeAffinity instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.INodeAffinity=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeAffinity} NodeAffinity instance
                         */
                        NodeAffinity.create = function create(properties) {
                            return new NodeAffinity(properties);
                        };

                        /**
                         * Encodes the specified NodeAffinity message. Does not implicitly {@link k8s.io.api.core.v1.NodeAffinity.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.INodeAffinity} message NodeAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeAffinity.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && Object.hasOwnProperty.call(message, "requiredDuringSchedulingIgnoredDuringExecution"))
                                $root.k8s.io.api.core.v1.NodeSelector.encode(message.requiredDuringSchedulingIgnoredDuringExecution, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.preferredDuringSchedulingIgnoredDuringExecution != null && message.preferredDuringSchedulingIgnoredDuringExecution.length)
                                for (var i = 0; i < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++i)
                                    $root.k8s.io.api.core.v1.PreferredSchedulingTerm.encode(message.preferredDuringSchedulingIgnoredDuringExecution[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeAffinity message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeAffinity.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.INodeAffinity} message NodeAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeAffinity.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeAffinity message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeAffinity} NodeAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeAffinity.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeAffinity();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.requiredDuringSchedulingIgnoredDuringExecution = $root.k8s.io.api.core.v1.NodeSelector.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.preferredDuringSchedulingIgnoredDuringExecution && message.preferredDuringSchedulingIgnoredDuringExecution.length))
                                        message.preferredDuringSchedulingIgnoredDuringExecution = [];
                                    message.preferredDuringSchedulingIgnoredDuringExecution.push($root.k8s.io.api.core.v1.PreferredSchedulingTerm.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeAffinity message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeAffinity} NodeAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeAffinity.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeAffinity message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeAffinity.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("requiredDuringSchedulingIgnoredDuringExecution")) {
                                var error = $root.k8s.io.api.core.v1.NodeSelector.verify(message.requiredDuringSchedulingIgnoredDuringExecution);
                                if (error)
                                    return "requiredDuringSchedulingIgnoredDuringExecution." + error;
                            }
                            if (message.preferredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("preferredDuringSchedulingIgnoredDuringExecution")) {
                                if (!Array.isArray(message.preferredDuringSchedulingIgnoredDuringExecution))
                                    return "preferredDuringSchedulingIgnoredDuringExecution: array expected";
                                for (var i = 0; i < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PreferredSchedulingTerm.verify(message.preferredDuringSchedulingIgnoredDuringExecution[i]);
                                    if (error)
                                        return "preferredDuringSchedulingIgnoredDuringExecution." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeAffinity message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeAffinity} NodeAffinity
                         */
                        NodeAffinity.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeAffinity)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeAffinity();
                            if (object.requiredDuringSchedulingIgnoredDuringExecution != null) {
                                if (typeof object.requiredDuringSchedulingIgnoredDuringExecution !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeAffinity.requiredDuringSchedulingIgnoredDuringExecution: object expected");
                                message.requiredDuringSchedulingIgnoredDuringExecution = $root.k8s.io.api.core.v1.NodeSelector.fromObject(object.requiredDuringSchedulingIgnoredDuringExecution);
                            }
                            if (object.preferredDuringSchedulingIgnoredDuringExecution) {
                                if (!Array.isArray(object.preferredDuringSchedulingIgnoredDuringExecution))
                                    throw TypeError(".k8s.io.api.core.v1.NodeAffinity.preferredDuringSchedulingIgnoredDuringExecution: array expected");
                                message.preferredDuringSchedulingIgnoredDuringExecution = [];
                                for (var i = 0; i < object.preferredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    if (typeof object.preferredDuringSchedulingIgnoredDuringExecution[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeAffinity.preferredDuringSchedulingIgnoredDuringExecution: object expected");
                                    message.preferredDuringSchedulingIgnoredDuringExecution[i] = $root.k8s.io.api.core.v1.PreferredSchedulingTerm.fromObject(object.preferredDuringSchedulingIgnoredDuringExecution[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeAffinity message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.NodeAffinity} message NodeAffinity
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeAffinity.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.preferredDuringSchedulingIgnoredDuringExecution = [];
                            if (options.defaults)
                                object.requiredDuringSchedulingIgnoredDuringExecution = null;
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("requiredDuringSchedulingIgnoredDuringExecution"))
                                object.requiredDuringSchedulingIgnoredDuringExecution = $root.k8s.io.api.core.v1.NodeSelector.toObject(message.requiredDuringSchedulingIgnoredDuringExecution, options);
                            if (message.preferredDuringSchedulingIgnoredDuringExecution && message.preferredDuringSchedulingIgnoredDuringExecution.length) {
                                object.preferredDuringSchedulingIgnoredDuringExecution = [];
                                for (var j = 0; j < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++j)
                                    object.preferredDuringSchedulingIgnoredDuringExecution[j] = $root.k8s.io.api.core.v1.PreferredSchedulingTerm.toObject(message.preferredDuringSchedulingIgnoredDuringExecution[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeAffinity to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeAffinity
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeAffinity.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeAffinity;
                    })();

                    v1.NodeCondition = (function() {

                        /**
                         * Properties of a NodeCondition.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeCondition
                         * @property {string|null} [type] NodeCondition type
                         * @property {string|null} [status] NodeCondition status
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastHeartbeatTime] NodeCondition lastHeartbeatTime
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTransitionTime] NodeCondition lastTransitionTime
                         * @property {string|null} [reason] NodeCondition reason
                         * @property {string|null} [message] NodeCondition message
                         */

                        /**
                         * Constructs a new NodeCondition.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeCondition.
                         * @implements INodeCondition
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeCondition=} [properties] Properties to set
                         */
                        function NodeCondition(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeCondition type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         */
                        NodeCondition.prototype.type = "";

                        /**
                         * NodeCondition status.
                         * @member {string} status
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         */
                        NodeCondition.prototype.status = "";

                        /**
                         * NodeCondition lastHeartbeatTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastHeartbeatTime
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         */
                        NodeCondition.prototype.lastHeartbeatTime = null;

                        /**
                         * NodeCondition lastTransitionTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTransitionTime
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         */
                        NodeCondition.prototype.lastTransitionTime = null;

                        /**
                         * NodeCondition reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         */
                        NodeCondition.prototype.reason = "";

                        /**
                         * NodeCondition message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         */
                        NodeCondition.prototype.message = "";

                        /**
                         * Creates a new NodeCondition instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {k8s.io.api.core.v1.INodeCondition=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeCondition} NodeCondition instance
                         */
                        NodeCondition.create = function create(properties) {
                            return new NodeCondition(properties);
                        };

                        /**
                         * Encodes the specified NodeCondition message. Does not implicitly {@link k8s.io.api.core.v1.NodeCondition.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {k8s.io.api.core.v1.INodeCondition} message NodeCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeCondition.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.lastHeartbeatTime != null && Object.hasOwnProperty.call(message, "lastHeartbeatTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastHeartbeatTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.lastTransitionTime != null && Object.hasOwnProperty.call(message, "lastTransitionTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTransitionTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeCondition message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeCondition.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {k8s.io.api.core.v1.INodeCondition} message NodeCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeCondition.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeCondition message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeCondition} NodeCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeCondition.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeCondition();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 3:
                                    message.lastHeartbeatTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.reason = reader.string();
                                    break;
                                case 6:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeCondition message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeCondition} NodeCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeCondition.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeCondition message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeCondition.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.lastHeartbeatTime != null && message.hasOwnProperty("lastHeartbeatTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastHeartbeatTime);
                                if (error)
                                    return "lastHeartbeatTime." + error;
                            }
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTransitionTime);
                                if (error)
                                    return "lastTransitionTime." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a NodeCondition message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeCondition} NodeCondition
                         */
                        NodeCondition.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeCondition)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeCondition();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.lastHeartbeatTime != null) {
                                if (typeof object.lastHeartbeatTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeCondition.lastHeartbeatTime: object expected");
                                message.lastHeartbeatTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastHeartbeatTime);
                            }
                            if (object.lastTransitionTime != null) {
                                if (typeof object.lastTransitionTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeCondition.lastTransitionTime: object expected");
                                message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTransitionTime);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeCondition message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @static
                         * @param {k8s.io.api.core.v1.NodeCondition} message NodeCondition
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeCondition.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.status = "";
                                object.lastHeartbeatTime = null;
                                object.lastTransitionTime = null;
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.lastHeartbeatTime != null && message.hasOwnProperty("lastHeartbeatTime"))
                                object.lastHeartbeatTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastHeartbeatTime, options);
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime"))
                                object.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTransitionTime, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this NodeCondition to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeCondition
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeCondition.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeCondition;
                    })();

                    v1.NodeConfigSource = (function() {

                        /**
                         * Properties of a NodeConfigSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeConfigSource
                         * @property {k8s.io.api.core.v1.IConfigMapNodeConfigSource|null} [configMap] NodeConfigSource configMap
                         */

                        /**
                         * Constructs a new NodeConfigSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeConfigSource.
                         * @implements INodeConfigSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeConfigSource=} [properties] Properties to set
                         */
                        function NodeConfigSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeConfigSource configMap.
                         * @member {k8s.io.api.core.v1.IConfigMapNodeConfigSource|null|undefined} configMap
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @instance
                         */
                        NodeConfigSource.prototype.configMap = null;

                        /**
                         * Creates a new NodeConfigSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.INodeConfigSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeConfigSource} NodeConfigSource instance
                         */
                        NodeConfigSource.create = function create(properties) {
                            return new NodeConfigSource(properties);
                        };

                        /**
                         * Encodes the specified NodeConfigSource message. Does not implicitly {@link k8s.io.api.core.v1.NodeConfigSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.INodeConfigSource} message NodeConfigSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeConfigSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.configMap != null && Object.hasOwnProperty.call(message, "configMap"))
                                $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource.encode(message.configMap, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeConfigSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeConfigSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.INodeConfigSource} message NodeConfigSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeConfigSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeConfigSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeConfigSource} NodeConfigSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeConfigSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeConfigSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 2:
                                    message.configMap = $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeConfigSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeConfigSource} NodeConfigSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeConfigSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeConfigSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeConfigSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.configMap != null && message.hasOwnProperty("configMap")) {
                                var error = $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource.verify(message.configMap);
                                if (error)
                                    return "configMap." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeConfigSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeConfigSource} NodeConfigSource
                         */
                        NodeConfigSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeConfigSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeConfigSource();
                            if (object.configMap != null) {
                                if (typeof object.configMap !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeConfigSource.configMap: object expected");
                                message.configMap = $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource.fromObject(object.configMap);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeConfigSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @static
                         * @param {k8s.io.api.core.v1.NodeConfigSource} message NodeConfigSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeConfigSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.configMap = null;
                            if (message.configMap != null && message.hasOwnProperty("configMap"))
                                object.configMap = $root.k8s.io.api.core.v1.ConfigMapNodeConfigSource.toObject(message.configMap, options);
                            return object;
                        };

                        /**
                         * Converts this NodeConfigSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeConfigSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeConfigSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeConfigSource;
                    })();

                    v1.NodeConfigStatus = (function() {

                        /**
                         * Properties of a NodeConfigStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeConfigStatus
                         * @property {k8s.io.api.core.v1.INodeConfigSource|null} [assigned] NodeConfigStatus assigned
                         * @property {k8s.io.api.core.v1.INodeConfigSource|null} [active] NodeConfigStatus active
                         * @property {k8s.io.api.core.v1.INodeConfigSource|null} [lastKnownGood] NodeConfigStatus lastKnownGood
                         * @property {string|null} [error] NodeConfigStatus error
                         */

                        /**
                         * Constructs a new NodeConfigStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeConfigStatus.
                         * @implements INodeConfigStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeConfigStatus=} [properties] Properties to set
                         */
                        function NodeConfigStatus(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeConfigStatus assigned.
                         * @member {k8s.io.api.core.v1.INodeConfigSource|null|undefined} assigned
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @instance
                         */
                        NodeConfigStatus.prototype.assigned = null;

                        /**
                         * NodeConfigStatus active.
                         * @member {k8s.io.api.core.v1.INodeConfigSource|null|undefined} active
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @instance
                         */
                        NodeConfigStatus.prototype.active = null;

                        /**
                         * NodeConfigStatus lastKnownGood.
                         * @member {k8s.io.api.core.v1.INodeConfigSource|null|undefined} lastKnownGood
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @instance
                         */
                        NodeConfigStatus.prototype.lastKnownGood = null;

                        /**
                         * NodeConfigStatus error.
                         * @member {string} error
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @instance
                         */
                        NodeConfigStatus.prototype.error = "";

                        /**
                         * Creates a new NodeConfigStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INodeConfigStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeConfigStatus} NodeConfigStatus instance
                         */
                        NodeConfigStatus.create = function create(properties) {
                            return new NodeConfigStatus(properties);
                        };

                        /**
                         * Encodes the specified NodeConfigStatus message. Does not implicitly {@link k8s.io.api.core.v1.NodeConfigStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INodeConfigStatus} message NodeConfigStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeConfigStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.assigned != null && Object.hasOwnProperty.call(message, "assigned"))
                                $root.k8s.io.api.core.v1.NodeConfigSource.encode(message.assigned, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                                $root.k8s.io.api.core.v1.NodeConfigSource.encode(message.active, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.lastKnownGood != null && Object.hasOwnProperty.call(message, "lastKnownGood"))
                                $root.k8s.io.api.core.v1.NodeConfigSource.encode(message.lastKnownGood, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.error);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeConfigStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeConfigStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INodeConfigStatus} message NodeConfigStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeConfigStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeConfigStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeConfigStatus} NodeConfigStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeConfigStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeConfigStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.assigned = $root.k8s.io.api.core.v1.NodeConfigSource.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.active = $root.k8s.io.api.core.v1.NodeConfigSource.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.lastKnownGood = $root.k8s.io.api.core.v1.NodeConfigSource.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.error = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeConfigStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeConfigStatus} NodeConfigStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeConfigStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeConfigStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeConfigStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.assigned != null && message.hasOwnProperty("assigned")) {
                                var error = $root.k8s.io.api.core.v1.NodeConfigSource.verify(message.assigned);
                                if (error)
                                    return "assigned." + error;
                            }
                            if (message.active != null && message.hasOwnProperty("active")) {
                                var error = $root.k8s.io.api.core.v1.NodeConfigSource.verify(message.active);
                                if (error)
                                    return "active." + error;
                            }
                            if (message.lastKnownGood != null && message.hasOwnProperty("lastKnownGood")) {
                                var error = $root.k8s.io.api.core.v1.NodeConfigSource.verify(message.lastKnownGood);
                                if (error)
                                    return "lastKnownGood." + error;
                            }
                            if (message.error != null && message.hasOwnProperty("error"))
                                if (!$util.isString(message.error))
                                    return "error: string expected";
                            return null;
                        };

                        /**
                         * Creates a NodeConfigStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeConfigStatus} NodeConfigStatus
                         */
                        NodeConfigStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeConfigStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeConfigStatus();
                            if (object.assigned != null) {
                                if (typeof object.assigned !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeConfigStatus.assigned: object expected");
                                message.assigned = $root.k8s.io.api.core.v1.NodeConfigSource.fromObject(object.assigned);
                            }
                            if (object.active != null) {
                                if (typeof object.active !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeConfigStatus.active: object expected");
                                message.active = $root.k8s.io.api.core.v1.NodeConfigSource.fromObject(object.active);
                            }
                            if (object.lastKnownGood != null) {
                                if (typeof object.lastKnownGood !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeConfigStatus.lastKnownGood: object expected");
                                message.lastKnownGood = $root.k8s.io.api.core.v1.NodeConfigSource.fromObject(object.lastKnownGood);
                            }
                            if (object.error != null)
                                message.error = String(object.error);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeConfigStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @static
                         * @param {k8s.io.api.core.v1.NodeConfigStatus} message NodeConfigStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeConfigStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.assigned = null;
                                object.active = null;
                                object.lastKnownGood = null;
                                object.error = "";
                            }
                            if (message.assigned != null && message.hasOwnProperty("assigned"))
                                object.assigned = $root.k8s.io.api.core.v1.NodeConfigSource.toObject(message.assigned, options);
                            if (message.active != null && message.hasOwnProperty("active"))
                                object.active = $root.k8s.io.api.core.v1.NodeConfigSource.toObject(message.active, options);
                            if (message.lastKnownGood != null && message.hasOwnProperty("lastKnownGood"))
                                object.lastKnownGood = $root.k8s.io.api.core.v1.NodeConfigSource.toObject(message.lastKnownGood, options);
                            if (message.error != null && message.hasOwnProperty("error"))
                                object.error = message.error;
                            return object;
                        };

                        /**
                         * Converts this NodeConfigStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeConfigStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeConfigStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeConfigStatus;
                    })();

                    v1.NodeDaemonEndpoints = (function() {

                        /**
                         * Properties of a NodeDaemonEndpoints.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeDaemonEndpoints
                         * @property {k8s.io.api.core.v1.IDaemonEndpoint|null} [kubeletEndpoint] NodeDaemonEndpoints kubeletEndpoint
                         */

                        /**
                         * Constructs a new NodeDaemonEndpoints.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeDaemonEndpoints.
                         * @implements INodeDaemonEndpoints
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeDaemonEndpoints=} [properties] Properties to set
                         */
                        function NodeDaemonEndpoints(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeDaemonEndpoints kubeletEndpoint.
                         * @member {k8s.io.api.core.v1.IDaemonEndpoint|null|undefined} kubeletEndpoint
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @instance
                         */
                        NodeDaemonEndpoints.prototype.kubeletEndpoint = null;

                        /**
                         * Creates a new NodeDaemonEndpoints instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {k8s.io.api.core.v1.INodeDaemonEndpoints=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeDaemonEndpoints} NodeDaemonEndpoints instance
                         */
                        NodeDaemonEndpoints.create = function create(properties) {
                            return new NodeDaemonEndpoints(properties);
                        };

                        /**
                         * Encodes the specified NodeDaemonEndpoints message. Does not implicitly {@link k8s.io.api.core.v1.NodeDaemonEndpoints.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {k8s.io.api.core.v1.INodeDaemonEndpoints} message NodeDaemonEndpoints message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeDaemonEndpoints.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.kubeletEndpoint != null && Object.hasOwnProperty.call(message, "kubeletEndpoint"))
                                $root.k8s.io.api.core.v1.DaemonEndpoint.encode(message.kubeletEndpoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeDaemonEndpoints message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeDaemonEndpoints.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {k8s.io.api.core.v1.INodeDaemonEndpoints} message NodeDaemonEndpoints message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeDaemonEndpoints.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeDaemonEndpoints message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeDaemonEndpoints} NodeDaemonEndpoints
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeDaemonEndpoints.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeDaemonEndpoints();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.kubeletEndpoint = $root.k8s.io.api.core.v1.DaemonEndpoint.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeDaemonEndpoints message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeDaemonEndpoints} NodeDaemonEndpoints
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeDaemonEndpoints.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeDaemonEndpoints message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeDaemonEndpoints.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.kubeletEndpoint != null && message.hasOwnProperty("kubeletEndpoint")) {
                                var error = $root.k8s.io.api.core.v1.DaemonEndpoint.verify(message.kubeletEndpoint);
                                if (error)
                                    return "kubeletEndpoint." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeDaemonEndpoints message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeDaemonEndpoints} NodeDaemonEndpoints
                         */
                        NodeDaemonEndpoints.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeDaemonEndpoints)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeDaemonEndpoints();
                            if (object.kubeletEndpoint != null) {
                                if (typeof object.kubeletEndpoint !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeDaemonEndpoints.kubeletEndpoint: object expected");
                                message.kubeletEndpoint = $root.k8s.io.api.core.v1.DaemonEndpoint.fromObject(object.kubeletEndpoint);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeDaemonEndpoints message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @static
                         * @param {k8s.io.api.core.v1.NodeDaemonEndpoints} message NodeDaemonEndpoints
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeDaemonEndpoints.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.kubeletEndpoint = null;
                            if (message.kubeletEndpoint != null && message.hasOwnProperty("kubeletEndpoint"))
                                object.kubeletEndpoint = $root.k8s.io.api.core.v1.DaemonEndpoint.toObject(message.kubeletEndpoint, options);
                            return object;
                        };

                        /**
                         * Converts this NodeDaemonEndpoints to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeDaemonEndpoints
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeDaemonEndpoints.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeDaemonEndpoints;
                    })();

                    v1.NodeList = (function() {

                        /**
                         * Properties of a NodeList.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] NodeList metadata
                         * @property {Array.<k8s.io.api.core.v1.INode>|null} [items] NodeList items
                         */

                        /**
                         * Constructs a new NodeList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeList.
                         * @implements INodeList
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeList=} [properties] Properties to set
                         */
                        function NodeList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @instance
                         */
                        NodeList.prototype.metadata = null;

                        /**
                         * NodeList items.
                         * @member {Array.<k8s.io.api.core.v1.INode>} items
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @instance
                         */
                        NodeList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new NodeList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {k8s.io.api.core.v1.INodeList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeList} NodeList instance
                         */
                        NodeList.create = function create(properties) {
                            return new NodeList(properties);
                        };

                        /**
                         * Encodes the specified NodeList message. Does not implicitly {@link k8s.io.api.core.v1.NodeList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {k8s.io.api.core.v1.INodeList} message NodeList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Node.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {k8s.io.api.core.v1.INodeList} message NodeList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeList} NodeList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Node.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeList} NodeList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Node.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeList} NodeList
                         */
                        NodeList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.NodeList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Node.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @static
                         * @param {k8s.io.api.core.v1.NodeList} message NodeList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Node.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeList;
                    })();

                    v1.NodeProxyOptions = (function() {

                        /**
                         * Properties of a NodeProxyOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeProxyOptions
                         * @property {string|null} [path] NodeProxyOptions path
                         */

                        /**
                         * Constructs a new NodeProxyOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeProxyOptions.
                         * @implements INodeProxyOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeProxyOptions=} [properties] Properties to set
                         */
                        function NodeProxyOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeProxyOptions path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @instance
                         */
                        NodeProxyOptions.prototype.path = "";

                        /**
                         * Creates a new NodeProxyOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.INodeProxyOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeProxyOptions} NodeProxyOptions instance
                         */
                        NodeProxyOptions.create = function create(properties) {
                            return new NodeProxyOptions(properties);
                        };

                        /**
                         * Encodes the specified NodeProxyOptions message. Does not implicitly {@link k8s.io.api.core.v1.NodeProxyOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.INodeProxyOptions} message NodeProxyOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeProxyOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeProxyOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeProxyOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.INodeProxyOptions} message NodeProxyOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeProxyOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeProxyOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeProxyOptions} NodeProxyOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeProxyOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeProxyOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeProxyOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeProxyOptions} NodeProxyOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeProxyOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeProxyOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeProxyOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            return null;
                        };

                        /**
                         * Creates a NodeProxyOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeProxyOptions} NodeProxyOptions
                         */
                        NodeProxyOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeProxyOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeProxyOptions();
                            if (object.path != null)
                                message.path = String(object.path);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeProxyOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.NodeProxyOptions} message NodeProxyOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeProxyOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.path = "";
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            return object;
                        };

                        /**
                         * Converts this NodeProxyOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeProxyOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeProxyOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeProxyOptions;
                    })();

                    v1.NodeResources = (function() {

                        /**
                         * Properties of a NodeResources.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeResources
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [capacity] NodeResources capacity
                         */

                        /**
                         * Constructs a new NodeResources.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeResources.
                         * @implements INodeResources
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeResources=} [properties] Properties to set
                         */
                        function NodeResources(properties) {
                            this.capacity = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeResources capacity.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} capacity
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @instance
                         */
                        NodeResources.prototype.capacity = $util.emptyObject;

                        /**
                         * Creates a new NodeResources instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {k8s.io.api.core.v1.INodeResources=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeResources} NodeResources instance
                         */
                        NodeResources.create = function create(properties) {
                            return new NodeResources(properties);
                        };

                        /**
                         * Encodes the specified NodeResources message. Does not implicitly {@link k8s.io.api.core.v1.NodeResources.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {k8s.io.api.core.v1.INodeResources} message NodeResources message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeResources.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                                for (var keys = Object.keys(message.capacity), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.capacity[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeResources message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeResources.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {k8s.io.api.core.v1.INodeResources} message NodeResources message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeResources.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeResources message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeResources} NodeResources
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeResources.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeResources(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (message.capacity === $util.emptyObject)
                                        message.capacity = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.capacity[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeResources message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeResources} NodeResources
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeResources.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeResources message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeResources.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.capacity != null && message.hasOwnProperty("capacity")) {
                                if (!$util.isObject(message.capacity))
                                    return "capacity: object expected";
                                var key = Object.keys(message.capacity);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.capacity[key[i]]);
                                    if (error)
                                        return "capacity." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeResources message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeResources} NodeResources
                         */
                        NodeResources.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeResources)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeResources();
                            if (object.capacity) {
                                if (typeof object.capacity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeResources.capacity: object expected");
                                message.capacity = {};
                                for (var keys = Object.keys(object.capacity), i = 0; i < keys.length; ++i) {
                                    if (typeof object.capacity[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeResources.capacity: object expected");
                                    message.capacity[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.capacity[keys[i]]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeResources message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @static
                         * @param {k8s.io.api.core.v1.NodeResources} message NodeResources
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeResources.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.capacity = {};
                            var keys2;
                            if (message.capacity && (keys2 = Object.keys(message.capacity)).length) {
                                object.capacity = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.capacity[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.capacity[keys2[j]], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeResources to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeResources
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeResources.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeResources;
                    })();

                    v1.NodeSelector = (function() {

                        /**
                         * Properties of a NodeSelector.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeSelector
                         * @property {Array.<k8s.io.api.core.v1.INodeSelectorTerm>|null} [nodeSelectorTerms] NodeSelector nodeSelectorTerms
                         */

                        /**
                         * Constructs a new NodeSelector.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeSelector.
                         * @implements INodeSelector
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeSelector=} [properties] Properties to set
                         */
                        function NodeSelector(properties) {
                            this.nodeSelectorTerms = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeSelector nodeSelectorTerms.
                         * @member {Array.<k8s.io.api.core.v1.INodeSelectorTerm>} nodeSelectorTerms
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @instance
                         */
                        NodeSelector.prototype.nodeSelectorTerms = $util.emptyArray;

                        /**
                         * Creates a new NodeSelector instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelector=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeSelector} NodeSelector instance
                         */
                        NodeSelector.create = function create(properties) {
                            return new NodeSelector(properties);
                        };

                        /**
                         * Encodes the specified NodeSelector message. Does not implicitly {@link k8s.io.api.core.v1.NodeSelector.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelector} message NodeSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSelector.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.nodeSelectorTerms != null && message.nodeSelectorTerms.length)
                                for (var i = 0; i < message.nodeSelectorTerms.length; ++i)
                                    $root.k8s.io.api.core.v1.NodeSelectorTerm.encode(message.nodeSelectorTerms[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeSelector message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeSelector.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelector} message NodeSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSelector.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeSelector message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeSelector} NodeSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSelector.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeSelector();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.nodeSelectorTerms && message.nodeSelectorTerms.length))
                                        message.nodeSelectorTerms = [];
                                    message.nodeSelectorTerms.push($root.k8s.io.api.core.v1.NodeSelectorTerm.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeSelector message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeSelector} NodeSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSelector.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeSelector message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeSelector.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.nodeSelectorTerms != null && message.hasOwnProperty("nodeSelectorTerms")) {
                                if (!Array.isArray(message.nodeSelectorTerms))
                                    return "nodeSelectorTerms: array expected";
                                for (var i = 0; i < message.nodeSelectorTerms.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.NodeSelectorTerm.verify(message.nodeSelectorTerms[i]);
                                    if (error)
                                        return "nodeSelectorTerms." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeSelector message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeSelector} NodeSelector
                         */
                        NodeSelector.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeSelector)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeSelector();
                            if (object.nodeSelectorTerms) {
                                if (!Array.isArray(object.nodeSelectorTerms))
                                    throw TypeError(".k8s.io.api.core.v1.NodeSelector.nodeSelectorTerms: array expected");
                                message.nodeSelectorTerms = [];
                                for (var i = 0; i < object.nodeSelectorTerms.length; ++i) {
                                    if (typeof object.nodeSelectorTerms[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeSelector.nodeSelectorTerms: object expected");
                                    message.nodeSelectorTerms[i] = $root.k8s.io.api.core.v1.NodeSelectorTerm.fromObject(object.nodeSelectorTerms[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeSelector message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.NodeSelector} message NodeSelector
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeSelector.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.nodeSelectorTerms = [];
                            if (message.nodeSelectorTerms && message.nodeSelectorTerms.length) {
                                object.nodeSelectorTerms = [];
                                for (var j = 0; j < message.nodeSelectorTerms.length; ++j)
                                    object.nodeSelectorTerms[j] = $root.k8s.io.api.core.v1.NodeSelectorTerm.toObject(message.nodeSelectorTerms[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeSelector to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeSelector
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeSelector.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeSelector;
                    })();

                    v1.NodeSelectorRequirement = (function() {

                        /**
                         * Properties of a NodeSelectorRequirement.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeSelectorRequirement
                         * @property {string|null} [key] NodeSelectorRequirement key
                         * @property {string|null} [operator] NodeSelectorRequirement operator
                         * @property {Array.<string>|null} [values] NodeSelectorRequirement values
                         */

                        /**
                         * Constructs a new NodeSelectorRequirement.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeSelectorRequirement.
                         * @implements INodeSelectorRequirement
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeSelectorRequirement=} [properties] Properties to set
                         */
                        function NodeSelectorRequirement(properties) {
                            this.values = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeSelectorRequirement key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @instance
                         */
                        NodeSelectorRequirement.prototype.key = "";

                        /**
                         * NodeSelectorRequirement operator.
                         * @member {string} operator
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @instance
                         */
                        NodeSelectorRequirement.prototype.operator = "";

                        /**
                         * NodeSelectorRequirement values.
                         * @member {Array.<string>} values
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @instance
                         */
                        NodeSelectorRequirement.prototype.values = $util.emptyArray;

                        /**
                         * Creates a new NodeSelectorRequirement instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelectorRequirement=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeSelectorRequirement} NodeSelectorRequirement instance
                         */
                        NodeSelectorRequirement.create = function create(properties) {
                            return new NodeSelectorRequirement(properties);
                        };

                        /**
                         * Encodes the specified NodeSelectorRequirement message. Does not implicitly {@link k8s.io.api.core.v1.NodeSelectorRequirement.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelectorRequirement} message NodeSelectorRequirement message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSelectorRequirement.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                            if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operator);
                            if (message.values != null && message.values.length)
                                for (var i = 0; i < message.values.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.values[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeSelectorRequirement message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeSelectorRequirement.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelectorRequirement} message NodeSelectorRequirement message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSelectorRequirement.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeSelectorRequirement message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeSelectorRequirement} NodeSelectorRequirement
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSelectorRequirement.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeSelectorRequirement();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.key = reader.string();
                                    break;
                                case 2:
                                    message.operator = reader.string();
                                    break;
                                case 3:
                                    if (!(message.values && message.values.length))
                                        message.values = [];
                                    message.values.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeSelectorRequirement message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeSelectorRequirement} NodeSelectorRequirement
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSelectorRequirement.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeSelectorRequirement message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeSelectorRequirement.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.operator != null && message.hasOwnProperty("operator"))
                                if (!$util.isString(message.operator))
                                    return "operator: string expected";
                            if (message.values != null && message.hasOwnProperty("values")) {
                                if (!Array.isArray(message.values))
                                    return "values: array expected";
                                for (var i = 0; i < message.values.length; ++i)
                                    if (!$util.isString(message.values[i]))
                                        return "values: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeSelectorRequirement message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeSelectorRequirement} NodeSelectorRequirement
                         */
                        NodeSelectorRequirement.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeSelectorRequirement)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeSelectorRequirement();
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.operator != null)
                                message.operator = String(object.operator);
                            if (object.values) {
                                if (!Array.isArray(object.values))
                                    throw TypeError(".k8s.io.api.core.v1.NodeSelectorRequirement.values: array expected");
                                message.values = [];
                                for (var i = 0; i < object.values.length; ++i)
                                    message.values[i] = String(object.values[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeSelectorRequirement message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.NodeSelectorRequirement} message NodeSelectorRequirement
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeSelectorRequirement.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.values = [];
                            if (options.defaults) {
                                object.key = "";
                                object.operator = "";
                            }
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.operator != null && message.hasOwnProperty("operator"))
                                object.operator = message.operator;
                            if (message.values && message.values.length) {
                                object.values = [];
                                for (var j = 0; j < message.values.length; ++j)
                                    object.values[j] = message.values[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeSelectorRequirement to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeSelectorRequirement
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeSelectorRequirement.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeSelectorRequirement;
                    })();

                    v1.NodeSelectorTerm = (function() {

                        /**
                         * Properties of a NodeSelectorTerm.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeSelectorTerm
                         * @property {Array.<k8s.io.api.core.v1.INodeSelectorRequirement>|null} [matchExpressions] NodeSelectorTerm matchExpressions
                         * @property {Array.<k8s.io.api.core.v1.INodeSelectorRequirement>|null} [matchFields] NodeSelectorTerm matchFields
                         */

                        /**
                         * Constructs a new NodeSelectorTerm.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeSelectorTerm.
                         * @implements INodeSelectorTerm
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeSelectorTerm=} [properties] Properties to set
                         */
                        function NodeSelectorTerm(properties) {
                            this.matchExpressions = [];
                            this.matchFields = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeSelectorTerm matchExpressions.
                         * @member {Array.<k8s.io.api.core.v1.INodeSelectorRequirement>} matchExpressions
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @instance
                         */
                        NodeSelectorTerm.prototype.matchExpressions = $util.emptyArray;

                        /**
                         * NodeSelectorTerm matchFields.
                         * @member {Array.<k8s.io.api.core.v1.INodeSelectorRequirement>} matchFields
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @instance
                         */
                        NodeSelectorTerm.prototype.matchFields = $util.emptyArray;

                        /**
                         * Creates a new NodeSelectorTerm instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelectorTerm=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeSelectorTerm} NodeSelectorTerm instance
                         */
                        NodeSelectorTerm.create = function create(properties) {
                            return new NodeSelectorTerm(properties);
                        };

                        /**
                         * Encodes the specified NodeSelectorTerm message. Does not implicitly {@link k8s.io.api.core.v1.NodeSelectorTerm.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelectorTerm} message NodeSelectorTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSelectorTerm.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.matchExpressions != null && message.matchExpressions.length)
                                for (var i = 0; i < message.matchExpressions.length; ++i)
                                    $root.k8s.io.api.core.v1.NodeSelectorRequirement.encode(message.matchExpressions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.matchFields != null && message.matchFields.length)
                                for (var i = 0; i < message.matchFields.length; ++i)
                                    $root.k8s.io.api.core.v1.NodeSelectorRequirement.encode(message.matchFields[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeSelectorTerm message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeSelectorTerm.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSelectorTerm} message NodeSelectorTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSelectorTerm.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeSelectorTerm message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeSelectorTerm} NodeSelectorTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSelectorTerm.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeSelectorTerm();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.matchExpressions && message.matchExpressions.length))
                                        message.matchExpressions = [];
                                    message.matchExpressions.push($root.k8s.io.api.core.v1.NodeSelectorRequirement.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (!(message.matchFields && message.matchFields.length))
                                        message.matchFields = [];
                                    message.matchFields.push($root.k8s.io.api.core.v1.NodeSelectorRequirement.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeSelectorTerm message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeSelectorTerm} NodeSelectorTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSelectorTerm.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeSelectorTerm message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeSelectorTerm.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.matchExpressions != null && message.hasOwnProperty("matchExpressions")) {
                                if (!Array.isArray(message.matchExpressions))
                                    return "matchExpressions: array expected";
                                for (var i = 0; i < message.matchExpressions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.NodeSelectorRequirement.verify(message.matchExpressions[i]);
                                    if (error)
                                        return "matchExpressions." + error;
                                }
                            }
                            if (message.matchFields != null && message.hasOwnProperty("matchFields")) {
                                if (!Array.isArray(message.matchFields))
                                    return "matchFields: array expected";
                                for (var i = 0; i < message.matchFields.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.NodeSelectorRequirement.verify(message.matchFields[i]);
                                    if (error)
                                        return "matchFields." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeSelectorTerm message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeSelectorTerm} NodeSelectorTerm
                         */
                        NodeSelectorTerm.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeSelectorTerm)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeSelectorTerm();
                            if (object.matchExpressions) {
                                if (!Array.isArray(object.matchExpressions))
                                    throw TypeError(".k8s.io.api.core.v1.NodeSelectorTerm.matchExpressions: array expected");
                                message.matchExpressions = [];
                                for (var i = 0; i < object.matchExpressions.length; ++i) {
                                    if (typeof object.matchExpressions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeSelectorTerm.matchExpressions: object expected");
                                    message.matchExpressions[i] = $root.k8s.io.api.core.v1.NodeSelectorRequirement.fromObject(object.matchExpressions[i]);
                                }
                            }
                            if (object.matchFields) {
                                if (!Array.isArray(object.matchFields))
                                    throw TypeError(".k8s.io.api.core.v1.NodeSelectorTerm.matchFields: array expected");
                                message.matchFields = [];
                                for (var i = 0; i < object.matchFields.length; ++i) {
                                    if (typeof object.matchFields[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeSelectorTerm.matchFields: object expected");
                                    message.matchFields[i] = $root.k8s.io.api.core.v1.NodeSelectorRequirement.fromObject(object.matchFields[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeSelectorTerm message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.NodeSelectorTerm} message NodeSelectorTerm
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeSelectorTerm.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.matchExpressions = [];
                                object.matchFields = [];
                            }
                            if (message.matchExpressions && message.matchExpressions.length) {
                                object.matchExpressions = [];
                                for (var j = 0; j < message.matchExpressions.length; ++j)
                                    object.matchExpressions[j] = $root.k8s.io.api.core.v1.NodeSelectorRequirement.toObject(message.matchExpressions[j], options);
                            }
                            if (message.matchFields && message.matchFields.length) {
                                object.matchFields = [];
                                for (var j = 0; j < message.matchFields.length; ++j)
                                    object.matchFields[j] = $root.k8s.io.api.core.v1.NodeSelectorRequirement.toObject(message.matchFields[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeSelectorTerm to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeSelectorTerm
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeSelectorTerm.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeSelectorTerm;
                    })();

                    v1.NodeSpec = (function() {

                        /**
                         * Properties of a NodeSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeSpec
                         * @property {string|null} [podCIDR] NodeSpec podCIDR
                         * @property {Array.<string>|null} [podCIDRs] NodeSpec podCIDRs
                         * @property {string|null} [providerID] NodeSpec providerID
                         * @property {boolean|null} [unschedulable] NodeSpec unschedulable
                         * @property {Array.<k8s.io.api.core.v1.ITaint>|null} [taints] NodeSpec taints
                         * @property {k8s.io.api.core.v1.INodeConfigSource|null} [configSource] NodeSpec configSource
                         * @property {string|null} [externalID] NodeSpec externalID
                         */

                        /**
                         * Constructs a new NodeSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeSpec.
                         * @implements INodeSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeSpec=} [properties] Properties to set
                         */
                        function NodeSpec(properties) {
                            this.podCIDRs = [];
                            this.taints = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeSpec podCIDR.
                         * @member {string} podCIDR
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.podCIDR = "";

                        /**
                         * NodeSpec podCIDRs.
                         * @member {Array.<string>} podCIDRs
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.podCIDRs = $util.emptyArray;

                        /**
                         * NodeSpec providerID.
                         * @member {string} providerID
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.providerID = "";

                        /**
                         * NodeSpec unschedulable.
                         * @member {boolean} unschedulable
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.unschedulable = false;

                        /**
                         * NodeSpec taints.
                         * @member {Array.<k8s.io.api.core.v1.ITaint>} taints
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.taints = $util.emptyArray;

                        /**
                         * NodeSpec configSource.
                         * @member {k8s.io.api.core.v1.INodeConfigSource|null|undefined} configSource
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.configSource = null;

                        /**
                         * NodeSpec externalID.
                         * @member {string} externalID
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         */
                        NodeSpec.prototype.externalID = "";

                        /**
                         * Creates a new NodeSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeSpec} NodeSpec instance
                         */
                        NodeSpec.create = function create(properties) {
                            return new NodeSpec(properties);
                        };

                        /**
                         * Encodes the specified NodeSpec message. Does not implicitly {@link k8s.io.api.core.v1.NodeSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSpec} message NodeSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.podCIDR != null && Object.hasOwnProperty.call(message, "podCIDR"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.podCIDR);
                            if (message.externalID != null && Object.hasOwnProperty.call(message, "externalID"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalID);
                            if (message.providerID != null && Object.hasOwnProperty.call(message, "providerID"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.providerID);
                            if (message.unschedulable != null && Object.hasOwnProperty.call(message, "unschedulable"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.unschedulable);
                            if (message.taints != null && message.taints.length)
                                for (var i = 0; i < message.taints.length; ++i)
                                    $root.k8s.io.api.core.v1.Taint.encode(message.taints[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.configSource != null && Object.hasOwnProperty.call(message, "configSource"))
                                $root.k8s.io.api.core.v1.NodeConfigSource.encode(message.configSource, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.podCIDRs != null && message.podCIDRs.length)
                                for (var i = 0; i < message.podCIDRs.length; ++i)
                                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.podCIDRs[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSpec} message NodeSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeSpec} NodeSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeSpec();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.podCIDR = reader.string();
                                    break;
                                case 7:
                                    if (!(message.podCIDRs && message.podCIDRs.length))
                                        message.podCIDRs = [];
                                    message.podCIDRs.push(reader.string());
                                    break;
                                case 3:
                                    message.providerID = reader.string();
                                    break;
                                case 4:
                                    message.unschedulable = reader.bool();
                                    break;
                                case 5:
                                    if (!(message.taints && message.taints.length))
                                        message.taints = [];
                                    message.taints.push($root.k8s.io.api.core.v1.Taint.decode(reader, reader.uint32()));
                                    break;
                                case 6:
                                    message.configSource = $root.k8s.io.api.core.v1.NodeConfigSource.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.externalID = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeSpec} NodeSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.podCIDR != null && message.hasOwnProperty("podCIDR"))
                                if (!$util.isString(message.podCIDR))
                                    return "podCIDR: string expected";
                            if (message.podCIDRs != null && message.hasOwnProperty("podCIDRs")) {
                                if (!Array.isArray(message.podCIDRs))
                                    return "podCIDRs: array expected";
                                for (var i = 0; i < message.podCIDRs.length; ++i)
                                    if (!$util.isString(message.podCIDRs[i]))
                                        return "podCIDRs: string[] expected";
                            }
                            if (message.providerID != null && message.hasOwnProperty("providerID"))
                                if (!$util.isString(message.providerID))
                                    return "providerID: string expected";
                            if (message.unschedulable != null && message.hasOwnProperty("unschedulable"))
                                if (typeof message.unschedulable !== "boolean")
                                    return "unschedulable: boolean expected";
                            if (message.taints != null && message.hasOwnProperty("taints")) {
                                if (!Array.isArray(message.taints))
                                    return "taints: array expected";
                                for (var i = 0; i < message.taints.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Taint.verify(message.taints[i]);
                                    if (error)
                                        return "taints." + error;
                                }
                            }
                            if (message.configSource != null && message.hasOwnProperty("configSource")) {
                                var error = $root.k8s.io.api.core.v1.NodeConfigSource.verify(message.configSource);
                                if (error)
                                    return "configSource." + error;
                            }
                            if (message.externalID != null && message.hasOwnProperty("externalID"))
                                if (!$util.isString(message.externalID))
                                    return "externalID: string expected";
                            return null;
                        };

                        /**
                         * Creates a NodeSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeSpec} NodeSpec
                         */
                        NodeSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeSpec();
                            if (object.podCIDR != null)
                                message.podCIDR = String(object.podCIDR);
                            if (object.podCIDRs) {
                                if (!Array.isArray(object.podCIDRs))
                                    throw TypeError(".k8s.io.api.core.v1.NodeSpec.podCIDRs: array expected");
                                message.podCIDRs = [];
                                for (var i = 0; i < object.podCIDRs.length; ++i)
                                    message.podCIDRs[i] = String(object.podCIDRs[i]);
                            }
                            if (object.providerID != null)
                                message.providerID = String(object.providerID);
                            if (object.unschedulable != null)
                                message.unschedulable = Boolean(object.unschedulable);
                            if (object.taints) {
                                if (!Array.isArray(object.taints))
                                    throw TypeError(".k8s.io.api.core.v1.NodeSpec.taints: array expected");
                                message.taints = [];
                                for (var i = 0; i < object.taints.length; ++i) {
                                    if (typeof object.taints[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeSpec.taints: object expected");
                                    message.taints[i] = $root.k8s.io.api.core.v1.Taint.fromObject(object.taints[i]);
                                }
                            }
                            if (object.configSource != null) {
                                if (typeof object.configSource !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeSpec.configSource: object expected");
                                message.configSource = $root.k8s.io.api.core.v1.NodeConfigSource.fromObject(object.configSource);
                            }
                            if (object.externalID != null)
                                message.externalID = String(object.externalID);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.NodeSpec} message NodeSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.taints = [];
                                object.podCIDRs = [];
                            }
                            if (options.defaults) {
                                object.podCIDR = "";
                                object.externalID = "";
                                object.providerID = "";
                                object.unschedulable = false;
                                object.configSource = null;
                            }
                            if (message.podCIDR != null && message.hasOwnProperty("podCIDR"))
                                object.podCIDR = message.podCIDR;
                            if (message.externalID != null && message.hasOwnProperty("externalID"))
                                object.externalID = message.externalID;
                            if (message.providerID != null && message.hasOwnProperty("providerID"))
                                object.providerID = message.providerID;
                            if (message.unschedulable != null && message.hasOwnProperty("unschedulable"))
                                object.unschedulable = message.unschedulable;
                            if (message.taints && message.taints.length) {
                                object.taints = [];
                                for (var j = 0; j < message.taints.length; ++j)
                                    object.taints[j] = $root.k8s.io.api.core.v1.Taint.toObject(message.taints[j], options);
                            }
                            if (message.configSource != null && message.hasOwnProperty("configSource"))
                                object.configSource = $root.k8s.io.api.core.v1.NodeConfigSource.toObject(message.configSource, options);
                            if (message.podCIDRs && message.podCIDRs.length) {
                                object.podCIDRs = [];
                                for (var j = 0; j < message.podCIDRs.length; ++j)
                                    object.podCIDRs[j] = message.podCIDRs[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this NodeSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeSpec;
                    })();

                    v1.NodeStatus = (function() {

                        /**
                         * Properties of a NodeStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeStatus
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [capacity] NodeStatus capacity
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [allocatable] NodeStatus allocatable
                         * @property {string|null} [phase] NodeStatus phase
                         * @property {Array.<k8s.io.api.core.v1.INodeCondition>|null} [conditions] NodeStatus conditions
                         * @property {Array.<k8s.io.api.core.v1.INodeAddress>|null} [addresses] NodeStatus addresses
                         * @property {k8s.io.api.core.v1.INodeDaemonEndpoints|null} [daemonEndpoints] NodeStatus daemonEndpoints
                         * @property {k8s.io.api.core.v1.INodeSystemInfo|null} [nodeInfo] NodeStatus nodeInfo
                         * @property {Array.<k8s.io.api.core.v1.IContainerImage>|null} [images] NodeStatus images
                         * @property {Array.<string>|null} [volumesInUse] NodeStatus volumesInUse
                         * @property {Array.<k8s.io.api.core.v1.IAttachedVolume>|null} [volumesAttached] NodeStatus volumesAttached
                         * @property {k8s.io.api.core.v1.INodeConfigStatus|null} [config] NodeStatus config
                         */

                        /**
                         * Constructs a new NodeStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeStatus.
                         * @implements INodeStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeStatus=} [properties] Properties to set
                         */
                        function NodeStatus(properties) {
                            this.capacity = {};
                            this.allocatable = {};
                            this.conditions = [];
                            this.addresses = [];
                            this.images = [];
                            this.volumesInUse = [];
                            this.volumesAttached = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeStatus capacity.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} capacity
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.capacity = $util.emptyObject;

                        /**
                         * NodeStatus allocatable.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} allocatable
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.allocatable = $util.emptyObject;

                        /**
                         * NodeStatus phase.
                         * @member {string} phase
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.phase = "";

                        /**
                         * NodeStatus conditions.
                         * @member {Array.<k8s.io.api.core.v1.INodeCondition>} conditions
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * NodeStatus addresses.
                         * @member {Array.<k8s.io.api.core.v1.INodeAddress>} addresses
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.addresses = $util.emptyArray;

                        /**
                         * NodeStatus daemonEndpoints.
                         * @member {k8s.io.api.core.v1.INodeDaemonEndpoints|null|undefined} daemonEndpoints
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.daemonEndpoints = null;

                        /**
                         * NodeStatus nodeInfo.
                         * @member {k8s.io.api.core.v1.INodeSystemInfo|null|undefined} nodeInfo
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.nodeInfo = null;

                        /**
                         * NodeStatus images.
                         * @member {Array.<k8s.io.api.core.v1.IContainerImage>} images
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.images = $util.emptyArray;

                        /**
                         * NodeStatus volumesInUse.
                         * @member {Array.<string>} volumesInUse
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.volumesInUse = $util.emptyArray;

                        /**
                         * NodeStatus volumesAttached.
                         * @member {Array.<k8s.io.api.core.v1.IAttachedVolume>} volumesAttached
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.volumesAttached = $util.emptyArray;

                        /**
                         * NodeStatus config.
                         * @member {k8s.io.api.core.v1.INodeConfigStatus|null|undefined} config
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         */
                        NodeStatus.prototype.config = null;

                        /**
                         * Creates a new NodeStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INodeStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeStatus} NodeStatus instance
                         */
                        NodeStatus.create = function create(properties) {
                            return new NodeStatus(properties);
                        };

                        /**
                         * Encodes the specified NodeStatus message. Does not implicitly {@link k8s.io.api.core.v1.NodeStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INodeStatus} message NodeStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                                for (var keys = Object.keys(message.capacity), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.capacity[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.allocatable != null && Object.hasOwnProperty.call(message, "allocatable"))
                                for (var keys = Object.keys(message.allocatable), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.allocatable[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phase);
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.api.core.v1.NodeCondition.encode(message.conditions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.addresses != null && message.addresses.length)
                                for (var i = 0; i < message.addresses.length; ++i)
                                    $root.k8s.io.api.core.v1.NodeAddress.encode(message.addresses[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.daemonEndpoints != null && Object.hasOwnProperty.call(message, "daemonEndpoints"))
                                $root.k8s.io.api.core.v1.NodeDaemonEndpoints.encode(message.daemonEndpoints, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.nodeInfo != null && Object.hasOwnProperty.call(message, "nodeInfo"))
                                $root.k8s.io.api.core.v1.NodeSystemInfo.encode(message.nodeInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.images != null && message.images.length)
                                for (var i = 0; i < message.images.length; ++i)
                                    $root.k8s.io.api.core.v1.ContainerImage.encode(message.images[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.volumesInUse != null && message.volumesInUse.length)
                                for (var i = 0; i < message.volumesInUse.length; ++i)
                                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.volumesInUse[i]);
                            if (message.volumesAttached != null && message.volumesAttached.length)
                                for (var i = 0; i < message.volumesAttached.length; ++i)
                                    $root.k8s.io.api.core.v1.AttachedVolume.encode(message.volumesAttached[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                                $root.k8s.io.api.core.v1.NodeConfigStatus.encode(message.config, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.INodeStatus} message NodeStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeStatus} NodeStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeStatus(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (message.capacity === $util.emptyObject)
                                        message.capacity = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.capacity[key] = value;
                                    break;
                                case 2:
                                    if (message.allocatable === $util.emptyObject)
                                        message.allocatable = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.allocatable[key] = value;
                                    break;
                                case 3:
                                    message.phase = reader.string();
                                    break;
                                case 4:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.api.core.v1.NodeCondition.decode(reader, reader.uint32()));
                                    break;
                                case 5:
                                    if (!(message.addresses && message.addresses.length))
                                        message.addresses = [];
                                    message.addresses.push($root.k8s.io.api.core.v1.NodeAddress.decode(reader, reader.uint32()));
                                    break;
                                case 6:
                                    message.daemonEndpoints = $root.k8s.io.api.core.v1.NodeDaemonEndpoints.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.nodeInfo = $root.k8s.io.api.core.v1.NodeSystemInfo.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    if (!(message.images && message.images.length))
                                        message.images = [];
                                    message.images.push($root.k8s.io.api.core.v1.ContainerImage.decode(reader, reader.uint32()));
                                    break;
                                case 9:
                                    if (!(message.volumesInUse && message.volumesInUse.length))
                                        message.volumesInUse = [];
                                    message.volumesInUse.push(reader.string());
                                    break;
                                case 10:
                                    if (!(message.volumesAttached && message.volumesAttached.length))
                                        message.volumesAttached = [];
                                    message.volumesAttached.push($root.k8s.io.api.core.v1.AttachedVolume.decode(reader, reader.uint32()));
                                    break;
                                case 11:
                                    message.config = $root.k8s.io.api.core.v1.NodeConfigStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeStatus} NodeStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.capacity != null && message.hasOwnProperty("capacity")) {
                                if (!$util.isObject(message.capacity))
                                    return "capacity: object expected";
                                var key = Object.keys(message.capacity);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.capacity[key[i]]);
                                    if (error)
                                        return "capacity." + error;
                                }
                            }
                            if (message.allocatable != null && message.hasOwnProperty("allocatable")) {
                                if (!$util.isObject(message.allocatable))
                                    return "allocatable: object expected";
                                var key = Object.keys(message.allocatable);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.allocatable[key[i]]);
                                    if (error)
                                        return "allocatable." + error;
                                }
                            }
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                if (!$util.isString(message.phase))
                                    return "phase: string expected";
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.NodeCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            if (message.addresses != null && message.hasOwnProperty("addresses")) {
                                if (!Array.isArray(message.addresses))
                                    return "addresses: array expected";
                                for (var i = 0; i < message.addresses.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.NodeAddress.verify(message.addresses[i]);
                                    if (error)
                                        return "addresses." + error;
                                }
                            }
                            if (message.daemonEndpoints != null && message.hasOwnProperty("daemonEndpoints")) {
                                var error = $root.k8s.io.api.core.v1.NodeDaemonEndpoints.verify(message.daemonEndpoints);
                                if (error)
                                    return "daemonEndpoints." + error;
                            }
                            if (message.nodeInfo != null && message.hasOwnProperty("nodeInfo")) {
                                var error = $root.k8s.io.api.core.v1.NodeSystemInfo.verify(message.nodeInfo);
                                if (error)
                                    return "nodeInfo." + error;
                            }
                            if (message.images != null && message.hasOwnProperty("images")) {
                                if (!Array.isArray(message.images))
                                    return "images: array expected";
                                for (var i = 0; i < message.images.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ContainerImage.verify(message.images[i]);
                                    if (error)
                                        return "images." + error;
                                }
                            }
                            if (message.volumesInUse != null && message.hasOwnProperty("volumesInUse")) {
                                if (!Array.isArray(message.volumesInUse))
                                    return "volumesInUse: array expected";
                                for (var i = 0; i < message.volumesInUse.length; ++i)
                                    if (!$util.isString(message.volumesInUse[i]))
                                        return "volumesInUse: string[] expected";
                            }
                            if (message.volumesAttached != null && message.hasOwnProperty("volumesAttached")) {
                                if (!Array.isArray(message.volumesAttached))
                                    return "volumesAttached: array expected";
                                for (var i = 0; i < message.volumesAttached.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.AttachedVolume.verify(message.volumesAttached[i]);
                                    if (error)
                                        return "volumesAttached." + error;
                                }
                            }
                            if (message.config != null && message.hasOwnProperty("config")) {
                                var error = $root.k8s.io.api.core.v1.NodeConfigStatus.verify(message.config);
                                if (error)
                                    return "config." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a NodeStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeStatus} NodeStatus
                         */
                        NodeStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeStatus();
                            if (object.capacity) {
                                if (typeof object.capacity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.capacity: object expected");
                                message.capacity = {};
                                for (var keys = Object.keys(object.capacity), i = 0; i < keys.length; ++i) {
                                    if (typeof object.capacity[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeStatus.capacity: object expected");
                                    message.capacity[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.capacity[keys[i]]);
                                }
                            }
                            if (object.allocatable) {
                                if (typeof object.allocatable !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.allocatable: object expected");
                                message.allocatable = {};
                                for (var keys = Object.keys(object.allocatable), i = 0; i < keys.length; ++i) {
                                    if (typeof object.allocatable[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeStatus.allocatable: object expected");
                                    message.allocatable[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.allocatable[keys[i]]);
                                }
                            }
                            if (object.phase != null)
                                message.phase = String(object.phase);
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.api.core.v1.NodeCondition.fromObject(object.conditions[i]);
                                }
                            }
                            if (object.addresses) {
                                if (!Array.isArray(object.addresses))
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.addresses: array expected");
                                message.addresses = [];
                                for (var i = 0; i < object.addresses.length; ++i) {
                                    if (typeof object.addresses[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeStatus.addresses: object expected");
                                    message.addresses[i] = $root.k8s.io.api.core.v1.NodeAddress.fromObject(object.addresses[i]);
                                }
                            }
                            if (object.daemonEndpoints != null) {
                                if (typeof object.daemonEndpoints !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.daemonEndpoints: object expected");
                                message.daemonEndpoints = $root.k8s.io.api.core.v1.NodeDaemonEndpoints.fromObject(object.daemonEndpoints);
                            }
                            if (object.nodeInfo != null) {
                                if (typeof object.nodeInfo !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.nodeInfo: object expected");
                                message.nodeInfo = $root.k8s.io.api.core.v1.NodeSystemInfo.fromObject(object.nodeInfo);
                            }
                            if (object.images) {
                                if (!Array.isArray(object.images))
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.images: array expected");
                                message.images = [];
                                for (var i = 0; i < object.images.length; ++i) {
                                    if (typeof object.images[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeStatus.images: object expected");
                                    message.images[i] = $root.k8s.io.api.core.v1.ContainerImage.fromObject(object.images[i]);
                                }
                            }
                            if (object.volumesInUse) {
                                if (!Array.isArray(object.volumesInUse))
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.volumesInUse: array expected");
                                message.volumesInUse = [];
                                for (var i = 0; i < object.volumesInUse.length; ++i)
                                    message.volumesInUse[i] = String(object.volumesInUse[i]);
                            }
                            if (object.volumesAttached) {
                                if (!Array.isArray(object.volumesAttached))
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.volumesAttached: array expected");
                                message.volumesAttached = [];
                                for (var i = 0; i < object.volumesAttached.length; ++i) {
                                    if (typeof object.volumesAttached[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.NodeStatus.volumesAttached: object expected");
                                    message.volumesAttached[i] = $root.k8s.io.api.core.v1.AttachedVolume.fromObject(object.volumesAttached[i]);
                                }
                            }
                            if (object.config != null) {
                                if (typeof object.config !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.NodeStatus.config: object expected");
                                message.config = $root.k8s.io.api.core.v1.NodeConfigStatus.fromObject(object.config);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.NodeStatus} message NodeStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.conditions = [];
                                object.addresses = [];
                                object.images = [];
                                object.volumesInUse = [];
                                object.volumesAttached = [];
                            }
                            if (options.objects || options.defaults) {
                                object.capacity = {};
                                object.allocatable = {};
                            }
                            if (options.defaults) {
                                object.phase = "";
                                object.daemonEndpoints = null;
                                object.nodeInfo = null;
                                object.config = null;
                            }
                            var keys2;
                            if (message.capacity && (keys2 = Object.keys(message.capacity)).length) {
                                object.capacity = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.capacity[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.capacity[keys2[j]], options);
                            }
                            if (message.allocatable && (keys2 = Object.keys(message.allocatable)).length) {
                                object.allocatable = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.allocatable[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.allocatable[keys2[j]], options);
                            }
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                object.phase = message.phase;
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.api.core.v1.NodeCondition.toObject(message.conditions[j], options);
                            }
                            if (message.addresses && message.addresses.length) {
                                object.addresses = [];
                                for (var j = 0; j < message.addresses.length; ++j)
                                    object.addresses[j] = $root.k8s.io.api.core.v1.NodeAddress.toObject(message.addresses[j], options);
                            }
                            if (message.daemonEndpoints != null && message.hasOwnProperty("daemonEndpoints"))
                                object.daemonEndpoints = $root.k8s.io.api.core.v1.NodeDaemonEndpoints.toObject(message.daemonEndpoints, options);
                            if (message.nodeInfo != null && message.hasOwnProperty("nodeInfo"))
                                object.nodeInfo = $root.k8s.io.api.core.v1.NodeSystemInfo.toObject(message.nodeInfo, options);
                            if (message.images && message.images.length) {
                                object.images = [];
                                for (var j = 0; j < message.images.length; ++j)
                                    object.images[j] = $root.k8s.io.api.core.v1.ContainerImage.toObject(message.images[j], options);
                            }
                            if (message.volumesInUse && message.volumesInUse.length) {
                                object.volumesInUse = [];
                                for (var j = 0; j < message.volumesInUse.length; ++j)
                                    object.volumesInUse[j] = message.volumesInUse[j];
                            }
                            if (message.volumesAttached && message.volumesAttached.length) {
                                object.volumesAttached = [];
                                for (var j = 0; j < message.volumesAttached.length; ++j)
                                    object.volumesAttached[j] = $root.k8s.io.api.core.v1.AttachedVolume.toObject(message.volumesAttached[j], options);
                            }
                            if (message.config != null && message.hasOwnProperty("config"))
                                object.config = $root.k8s.io.api.core.v1.NodeConfigStatus.toObject(message.config, options);
                            return object;
                        };

                        /**
                         * Converts this NodeStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeStatus;
                    })();

                    v1.NodeSystemInfo = (function() {

                        /**
                         * Properties of a NodeSystemInfo.
                         * @memberof k8s.io.api.core.v1
                         * @interface INodeSystemInfo
                         * @property {string|null} [machineID] NodeSystemInfo machineID
                         * @property {string|null} [systemUUID] NodeSystemInfo systemUUID
                         * @property {string|null} [bootID] NodeSystemInfo bootID
                         * @property {string|null} [kernelVersion] NodeSystemInfo kernelVersion
                         * @property {string|null} [osImage] NodeSystemInfo osImage
                         * @property {string|null} [containerRuntimeVersion] NodeSystemInfo containerRuntimeVersion
                         * @property {string|null} [kubeletVersion] NodeSystemInfo kubeletVersion
                         * @property {string|null} [kubeProxyVersion] NodeSystemInfo kubeProxyVersion
                         * @property {string|null} [operatingSystem] NodeSystemInfo operatingSystem
                         * @property {string|null} [architecture] NodeSystemInfo architecture
                         */

                        /**
                         * Constructs a new NodeSystemInfo.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a NodeSystemInfo.
                         * @implements INodeSystemInfo
                         * @constructor
                         * @param {k8s.io.api.core.v1.INodeSystemInfo=} [properties] Properties to set
                         */
                        function NodeSystemInfo(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NodeSystemInfo machineID.
                         * @member {string} machineID
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.machineID = "";

                        /**
                         * NodeSystemInfo systemUUID.
                         * @member {string} systemUUID
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.systemUUID = "";

                        /**
                         * NodeSystemInfo bootID.
                         * @member {string} bootID
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.bootID = "";

                        /**
                         * NodeSystemInfo kernelVersion.
                         * @member {string} kernelVersion
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.kernelVersion = "";

                        /**
                         * NodeSystemInfo osImage.
                         * @member {string} osImage
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.osImage = "";

                        /**
                         * NodeSystemInfo containerRuntimeVersion.
                         * @member {string} containerRuntimeVersion
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.containerRuntimeVersion = "";

                        /**
                         * NodeSystemInfo kubeletVersion.
                         * @member {string} kubeletVersion
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.kubeletVersion = "";

                        /**
                         * NodeSystemInfo kubeProxyVersion.
                         * @member {string} kubeProxyVersion
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.kubeProxyVersion = "";

                        /**
                         * NodeSystemInfo operatingSystem.
                         * @member {string} operatingSystem
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.operatingSystem = "";

                        /**
                         * NodeSystemInfo architecture.
                         * @member {string} architecture
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         */
                        NodeSystemInfo.prototype.architecture = "";

                        /**
                         * Creates a new NodeSystemInfo instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSystemInfo=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.NodeSystemInfo} NodeSystemInfo instance
                         */
                        NodeSystemInfo.create = function create(properties) {
                            return new NodeSystemInfo(properties);
                        };

                        /**
                         * Encodes the specified NodeSystemInfo message. Does not implicitly {@link k8s.io.api.core.v1.NodeSystemInfo.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSystemInfo} message NodeSystemInfo message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSystemInfo.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.machineID != null && Object.hasOwnProperty.call(message, "machineID"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.machineID);
                            if (message.systemUUID != null && Object.hasOwnProperty.call(message, "systemUUID"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.systemUUID);
                            if (message.bootID != null && Object.hasOwnProperty.call(message, "bootID"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.bootID);
                            if (message.kernelVersion != null && Object.hasOwnProperty.call(message, "kernelVersion"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.kernelVersion);
                            if (message.osImage != null && Object.hasOwnProperty.call(message, "osImage"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.osImage);
                            if (message.containerRuntimeVersion != null && Object.hasOwnProperty.call(message, "containerRuntimeVersion"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.containerRuntimeVersion);
                            if (message.kubeletVersion != null && Object.hasOwnProperty.call(message, "kubeletVersion"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.kubeletVersion);
                            if (message.kubeProxyVersion != null && Object.hasOwnProperty.call(message, "kubeProxyVersion"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.kubeProxyVersion);
                            if (message.operatingSystem != null && Object.hasOwnProperty.call(message, "operatingSystem"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.operatingSystem);
                            if (message.architecture != null && Object.hasOwnProperty.call(message, "architecture"))
                                writer.uint32(/* id 10, wireType 2 =*/82).string(message.architecture);
                            return writer;
                        };

                        /**
                         * Encodes the specified NodeSystemInfo message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.NodeSystemInfo.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {k8s.io.api.core.v1.INodeSystemInfo} message NodeSystemInfo message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NodeSystemInfo.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NodeSystemInfo message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.NodeSystemInfo} NodeSystemInfo
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSystemInfo.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.NodeSystemInfo();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.machineID = reader.string();
                                    break;
                                case 2:
                                    message.systemUUID = reader.string();
                                    break;
                                case 3:
                                    message.bootID = reader.string();
                                    break;
                                case 4:
                                    message.kernelVersion = reader.string();
                                    break;
                                case 5:
                                    message.osImage = reader.string();
                                    break;
                                case 6:
                                    message.containerRuntimeVersion = reader.string();
                                    break;
                                case 7:
                                    message.kubeletVersion = reader.string();
                                    break;
                                case 8:
                                    message.kubeProxyVersion = reader.string();
                                    break;
                                case 9:
                                    message.operatingSystem = reader.string();
                                    break;
                                case 10:
                                    message.architecture = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NodeSystemInfo message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.NodeSystemInfo} NodeSystemInfo
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NodeSystemInfo.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NodeSystemInfo message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NodeSystemInfo.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.machineID != null && message.hasOwnProperty("machineID"))
                                if (!$util.isString(message.machineID))
                                    return "machineID: string expected";
                            if (message.systemUUID != null && message.hasOwnProperty("systemUUID"))
                                if (!$util.isString(message.systemUUID))
                                    return "systemUUID: string expected";
                            if (message.bootID != null && message.hasOwnProperty("bootID"))
                                if (!$util.isString(message.bootID))
                                    return "bootID: string expected";
                            if (message.kernelVersion != null && message.hasOwnProperty("kernelVersion"))
                                if (!$util.isString(message.kernelVersion))
                                    return "kernelVersion: string expected";
                            if (message.osImage != null && message.hasOwnProperty("osImage"))
                                if (!$util.isString(message.osImage))
                                    return "osImage: string expected";
                            if (message.containerRuntimeVersion != null && message.hasOwnProperty("containerRuntimeVersion"))
                                if (!$util.isString(message.containerRuntimeVersion))
                                    return "containerRuntimeVersion: string expected";
                            if (message.kubeletVersion != null && message.hasOwnProperty("kubeletVersion"))
                                if (!$util.isString(message.kubeletVersion))
                                    return "kubeletVersion: string expected";
                            if (message.kubeProxyVersion != null && message.hasOwnProperty("kubeProxyVersion"))
                                if (!$util.isString(message.kubeProxyVersion))
                                    return "kubeProxyVersion: string expected";
                            if (message.operatingSystem != null && message.hasOwnProperty("operatingSystem"))
                                if (!$util.isString(message.operatingSystem))
                                    return "operatingSystem: string expected";
                            if (message.architecture != null && message.hasOwnProperty("architecture"))
                                if (!$util.isString(message.architecture))
                                    return "architecture: string expected";
                            return null;
                        };

                        /**
                         * Creates a NodeSystemInfo message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.NodeSystemInfo} NodeSystemInfo
                         */
                        NodeSystemInfo.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.NodeSystemInfo)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.NodeSystemInfo();
                            if (object.machineID != null)
                                message.machineID = String(object.machineID);
                            if (object.systemUUID != null)
                                message.systemUUID = String(object.systemUUID);
                            if (object.bootID != null)
                                message.bootID = String(object.bootID);
                            if (object.kernelVersion != null)
                                message.kernelVersion = String(object.kernelVersion);
                            if (object.osImage != null)
                                message.osImage = String(object.osImage);
                            if (object.containerRuntimeVersion != null)
                                message.containerRuntimeVersion = String(object.containerRuntimeVersion);
                            if (object.kubeletVersion != null)
                                message.kubeletVersion = String(object.kubeletVersion);
                            if (object.kubeProxyVersion != null)
                                message.kubeProxyVersion = String(object.kubeProxyVersion);
                            if (object.operatingSystem != null)
                                message.operatingSystem = String(object.operatingSystem);
                            if (object.architecture != null)
                                message.architecture = String(object.architecture);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NodeSystemInfo message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @static
                         * @param {k8s.io.api.core.v1.NodeSystemInfo} message NodeSystemInfo
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NodeSystemInfo.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.machineID = "";
                                object.systemUUID = "";
                                object.bootID = "";
                                object.kernelVersion = "";
                                object.osImage = "";
                                object.containerRuntimeVersion = "";
                                object.kubeletVersion = "";
                                object.kubeProxyVersion = "";
                                object.operatingSystem = "";
                                object.architecture = "";
                            }
                            if (message.machineID != null && message.hasOwnProperty("machineID"))
                                object.machineID = message.machineID;
                            if (message.systemUUID != null && message.hasOwnProperty("systemUUID"))
                                object.systemUUID = message.systemUUID;
                            if (message.bootID != null && message.hasOwnProperty("bootID"))
                                object.bootID = message.bootID;
                            if (message.kernelVersion != null && message.hasOwnProperty("kernelVersion"))
                                object.kernelVersion = message.kernelVersion;
                            if (message.osImage != null && message.hasOwnProperty("osImage"))
                                object.osImage = message.osImage;
                            if (message.containerRuntimeVersion != null && message.hasOwnProperty("containerRuntimeVersion"))
                                object.containerRuntimeVersion = message.containerRuntimeVersion;
                            if (message.kubeletVersion != null && message.hasOwnProperty("kubeletVersion"))
                                object.kubeletVersion = message.kubeletVersion;
                            if (message.kubeProxyVersion != null && message.hasOwnProperty("kubeProxyVersion"))
                                object.kubeProxyVersion = message.kubeProxyVersion;
                            if (message.operatingSystem != null && message.hasOwnProperty("operatingSystem"))
                                object.operatingSystem = message.operatingSystem;
                            if (message.architecture != null && message.hasOwnProperty("architecture"))
                                object.architecture = message.architecture;
                            return object;
                        };

                        /**
                         * Converts this NodeSystemInfo to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.NodeSystemInfo
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NodeSystemInfo.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NodeSystemInfo;
                    })();

                    v1.ObjectFieldSelector = (function() {

                        /**
                         * Properties of an ObjectFieldSelector.
                         * @memberof k8s.io.api.core.v1
                         * @interface IObjectFieldSelector
                         * @property {string|null} [apiVersion] ObjectFieldSelector apiVersion
                         * @property {string|null} [fieldPath] ObjectFieldSelector fieldPath
                         */

                        /**
                         * Constructs a new ObjectFieldSelector.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an ObjectFieldSelector.
                         * @implements IObjectFieldSelector
                         * @constructor
                         * @param {k8s.io.api.core.v1.IObjectFieldSelector=} [properties] Properties to set
                         */
                        function ObjectFieldSelector(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ObjectFieldSelector apiVersion.
                         * @member {string} apiVersion
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @instance
                         */
                        ObjectFieldSelector.prototype.apiVersion = "";

                        /**
                         * ObjectFieldSelector fieldPath.
                         * @member {string} fieldPath
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @instance
                         */
                        ObjectFieldSelector.prototype.fieldPath = "";

                        /**
                         * Creates a new ObjectFieldSelector instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IObjectFieldSelector=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ObjectFieldSelector} ObjectFieldSelector instance
                         */
                        ObjectFieldSelector.create = function create(properties) {
                            return new ObjectFieldSelector(properties);
                        };

                        /**
                         * Encodes the specified ObjectFieldSelector message. Does not implicitly {@link k8s.io.api.core.v1.ObjectFieldSelector.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IObjectFieldSelector} message ObjectFieldSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ObjectFieldSelector.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.apiVersion);
                            if (message.fieldPath != null && Object.hasOwnProperty.call(message, "fieldPath"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fieldPath);
                            return writer;
                        };

                        /**
                         * Encodes the specified ObjectFieldSelector message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ObjectFieldSelector.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IObjectFieldSelector} message ObjectFieldSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ObjectFieldSelector.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ObjectFieldSelector message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ObjectFieldSelector} ObjectFieldSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ObjectFieldSelector.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ObjectFieldSelector();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.apiVersion = reader.string();
                                    break;
                                case 2:
                                    message.fieldPath = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ObjectFieldSelector message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ObjectFieldSelector} ObjectFieldSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ObjectFieldSelector.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ObjectFieldSelector message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ObjectFieldSelector.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                if (!$util.isString(message.apiVersion))
                                    return "apiVersion: string expected";
                            if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                                if (!$util.isString(message.fieldPath))
                                    return "fieldPath: string expected";
                            return null;
                        };

                        /**
                         * Creates an ObjectFieldSelector message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ObjectFieldSelector} ObjectFieldSelector
                         */
                        ObjectFieldSelector.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ObjectFieldSelector)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ObjectFieldSelector();
                            if (object.apiVersion != null)
                                message.apiVersion = String(object.apiVersion);
                            if (object.fieldPath != null)
                                message.fieldPath = String(object.fieldPath);
                            return message;
                        };

                        /**
                         * Creates a plain object from an ObjectFieldSelector message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.ObjectFieldSelector} message ObjectFieldSelector
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ObjectFieldSelector.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.apiVersion = "";
                                object.fieldPath = "";
                            }
                            if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                object.apiVersion = message.apiVersion;
                            if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                                object.fieldPath = message.fieldPath;
                            return object;
                        };

                        /**
                         * Converts this ObjectFieldSelector to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ObjectFieldSelector
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ObjectFieldSelector.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ObjectFieldSelector;
                    })();

                    v1.ObjectReference = (function() {

                        /**
                         * Properties of an ObjectReference.
                         * @memberof k8s.io.api.core.v1
                         * @interface IObjectReference
                         * @property {string|null} [kind] ObjectReference kind
                         * @property {string|null} [namespace] ObjectReference namespace
                         * @property {string|null} [name] ObjectReference name
                         * @property {string|null} [uid] ObjectReference uid
                         * @property {string|null} [apiVersion] ObjectReference apiVersion
                         * @property {string|null} [resourceVersion] ObjectReference resourceVersion
                         * @property {string|null} [fieldPath] ObjectReference fieldPath
                         */

                        /**
                         * Constructs a new ObjectReference.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents an ObjectReference.
                         * @implements IObjectReference
                         * @constructor
                         * @param {k8s.io.api.core.v1.IObjectReference=} [properties] Properties to set
                         */
                        function ObjectReference(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ObjectReference kind.
                         * @member {string} kind
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.kind = "";

                        /**
                         * ObjectReference namespace.
                         * @member {string} namespace
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.namespace = "";

                        /**
                         * ObjectReference name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.name = "";

                        /**
                         * ObjectReference uid.
                         * @member {string} uid
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.uid = "";

                        /**
                         * ObjectReference apiVersion.
                         * @member {string} apiVersion
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.apiVersion = "";

                        /**
                         * ObjectReference resourceVersion.
                         * @member {string} resourceVersion
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.resourceVersion = "";

                        /**
                         * ObjectReference fieldPath.
                         * @member {string} fieldPath
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         */
                        ObjectReference.prototype.fieldPath = "";

                        /**
                         * Creates a new ObjectReference instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.IObjectReference=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ObjectReference} ObjectReference instance
                         */
                        ObjectReference.create = function create(properties) {
                            return new ObjectReference(properties);
                        };

                        /**
                         * Encodes the specified ObjectReference message. Does not implicitly {@link k8s.io.api.core.v1.ObjectReference.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.IObjectReference} message ObjectReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ObjectReference.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.kind);
                            if (message.namespace != null && Object.hasOwnProperty.call(message, "namespace"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.namespace);
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.uid);
                            if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiVersion);
                            if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.resourceVersion);
                            if (message.fieldPath != null && Object.hasOwnProperty.call(message, "fieldPath"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.fieldPath);
                            return writer;
                        };

                        /**
                         * Encodes the specified ObjectReference message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ObjectReference.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.IObjectReference} message ObjectReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ObjectReference.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ObjectReference message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ObjectReference} ObjectReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ObjectReference.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ObjectReference();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.kind = reader.string();
                                    break;
                                case 2:
                                    message.namespace = reader.string();
                                    break;
                                case 3:
                                    message.name = reader.string();
                                    break;
                                case 4:
                                    message.uid = reader.string();
                                    break;
                                case 5:
                                    message.apiVersion = reader.string();
                                    break;
                                case 6:
                                    message.resourceVersion = reader.string();
                                    break;
                                case 7:
                                    message.fieldPath = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ObjectReference message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ObjectReference} ObjectReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ObjectReference.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ObjectReference message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ObjectReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                if (!$util.isString(message.kind))
                                    return "kind: string expected";
                            if (message.namespace != null && message.hasOwnProperty("namespace"))
                                if (!$util.isString(message.namespace))
                                    return "namespace: string expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.uid != null && message.hasOwnProperty("uid"))
                                if (!$util.isString(message.uid))
                                    return "uid: string expected";
                            if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                if (!$util.isString(message.apiVersion))
                                    return "apiVersion: string expected";
                            if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                if (!$util.isString(message.resourceVersion))
                                    return "resourceVersion: string expected";
                            if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                                if (!$util.isString(message.fieldPath))
                                    return "fieldPath: string expected";
                            return null;
                        };

                        /**
                         * Creates an ObjectReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ObjectReference} ObjectReference
                         */
                        ObjectReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ObjectReference)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ObjectReference();
                            if (object.kind != null)
                                message.kind = String(object.kind);
                            if (object.namespace != null)
                                message.namespace = String(object.namespace);
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.uid != null)
                                message.uid = String(object.uid);
                            if (object.apiVersion != null)
                                message.apiVersion = String(object.apiVersion);
                            if (object.resourceVersion != null)
                                message.resourceVersion = String(object.resourceVersion);
                            if (object.fieldPath != null)
                                message.fieldPath = String(object.fieldPath);
                            return message;
                        };

                        /**
                         * Creates a plain object from an ObjectReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ObjectReference} message ObjectReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ObjectReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.kind = "";
                                object.namespace = "";
                                object.name = "";
                                object.uid = "";
                                object.apiVersion = "";
                                object.resourceVersion = "";
                                object.fieldPath = "";
                            }
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                object.kind = message.kind;
                            if (message.namespace != null && message.hasOwnProperty("namespace"))
                                object.namespace = message.namespace;
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.uid != null && message.hasOwnProperty("uid"))
                                object.uid = message.uid;
                            if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                object.apiVersion = message.apiVersion;
                            if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                object.resourceVersion = message.resourceVersion;
                            if (message.fieldPath != null && message.hasOwnProperty("fieldPath"))
                                object.fieldPath = message.fieldPath;
                            return object;
                        };

                        /**
                         * Converts this ObjectReference to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ObjectReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ObjectReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ObjectReference;
                    })();

                    v1.PersistentVolume = (function() {

                        /**
                         * Properties of a PersistentVolume.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolume
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PersistentVolume metadata
                         * @property {k8s.io.api.core.v1.IPersistentVolumeSpec|null} [spec] PersistentVolume spec
                         * @property {k8s.io.api.core.v1.IPersistentVolumeStatus|null} [status] PersistentVolume status
                         */

                        /**
                         * Constructs a new PersistentVolume.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolume.
                         * @implements IPersistentVolume
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolume=} [properties] Properties to set
                         */
                        function PersistentVolume(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolume metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @instance
                         */
                        PersistentVolume.prototype.metadata = null;

                        /**
                         * PersistentVolume spec.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @instance
                         */
                        PersistentVolume.prototype.spec = null;

                        /**
                         * PersistentVolume status.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @instance
                         */
                        PersistentVolume.prototype.status = null;

                        /**
                         * Creates a new PersistentVolume instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolume=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolume} PersistentVolume instance
                         */
                        PersistentVolume.create = function create(properties) {
                            return new PersistentVolume(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolume message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolume.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolume} message PersistentVolume message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolume.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.PersistentVolumeSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.PersistentVolumeStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolume message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolume.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolume} message PersistentVolume message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolume.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolume message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolume} PersistentVolume
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolume.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolume();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.PersistentVolumeSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.PersistentVolumeStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolume message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolume} PersistentVolume
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolume.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolume message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolume.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolume message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolume} PersistentVolume
                         */
                        PersistentVolume.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolume)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolume();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolume.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolume.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.PersistentVolumeSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolume.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.PersistentVolumeStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolume message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolume} message PersistentVolume
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolume.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.PersistentVolumeSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.PersistentVolumeStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this PersistentVolume to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolume
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolume.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolume;
                    })();

                    v1.PersistentVolumeClaim = (function() {

                        /**
                         * Properties of a PersistentVolumeClaim.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaim
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PersistentVolumeClaim metadata
                         * @property {k8s.io.api.core.v1.IPersistentVolumeClaimSpec|null} [spec] PersistentVolumeClaim spec
                         * @property {k8s.io.api.core.v1.IPersistentVolumeClaimStatus|null} [status] PersistentVolumeClaim status
                         */

                        /**
                         * Constructs a new PersistentVolumeClaim.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaim.
                         * @implements IPersistentVolumeClaim
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaim=} [properties] Properties to set
                         */
                        function PersistentVolumeClaim(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaim metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @instance
                         */
                        PersistentVolumeClaim.prototype.metadata = null;

                        /**
                         * PersistentVolumeClaim spec.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeClaimSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @instance
                         */
                        PersistentVolumeClaim.prototype.spec = null;

                        /**
                         * PersistentVolumeClaim status.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeClaimStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @instance
                         */
                        PersistentVolumeClaim.prototype.status = null;

                        /**
                         * Creates a new PersistentVolumeClaim instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaim=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaim} PersistentVolumeClaim instance
                         */
                        PersistentVolumeClaim.create = function create(properties) {
                            return new PersistentVolumeClaim(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaim message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaim.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaim} message PersistentVolumeClaim message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaim.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaim message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaim.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaim} message PersistentVolumeClaim message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaim.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaim message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaim} PersistentVolumeClaim
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaim.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaim();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaim message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaim} PersistentVolumeClaim
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaim.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaim message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaim.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaim message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaim} PersistentVolumeClaim
                         */
                        PersistentVolumeClaim.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaim)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaim();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaim.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaim.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaim.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaim message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaim} message PersistentVolumeClaim
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaim.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaim to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaim
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaim.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaim;
                    })();

                    v1.PersistentVolumeClaimCondition = (function() {

                        /**
                         * Properties of a PersistentVolumeClaimCondition.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaimCondition
                         * @property {string|null} [type] PersistentVolumeClaimCondition type
                         * @property {string|null} [status] PersistentVolumeClaimCondition status
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastProbeTime] PersistentVolumeClaimCondition lastProbeTime
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTransitionTime] PersistentVolumeClaimCondition lastTransitionTime
                         * @property {string|null} [reason] PersistentVolumeClaimCondition reason
                         * @property {string|null} [message] PersistentVolumeClaimCondition message
                         */

                        /**
                         * Constructs a new PersistentVolumeClaimCondition.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaimCondition.
                         * @implements IPersistentVolumeClaimCondition
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimCondition=} [properties] Properties to set
                         */
                        function PersistentVolumeClaimCondition(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaimCondition type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         */
                        PersistentVolumeClaimCondition.prototype.type = "";

                        /**
                         * PersistentVolumeClaimCondition status.
                         * @member {string} status
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         */
                        PersistentVolumeClaimCondition.prototype.status = "";

                        /**
                         * PersistentVolumeClaimCondition lastProbeTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastProbeTime
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         */
                        PersistentVolumeClaimCondition.prototype.lastProbeTime = null;

                        /**
                         * PersistentVolumeClaimCondition lastTransitionTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTransitionTime
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         */
                        PersistentVolumeClaimCondition.prototype.lastTransitionTime = null;

                        /**
                         * PersistentVolumeClaimCondition reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         */
                        PersistentVolumeClaimCondition.prototype.reason = "";

                        /**
                         * PersistentVolumeClaimCondition message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         */
                        PersistentVolumeClaimCondition.prototype.message = "";

                        /**
                         * Creates a new PersistentVolumeClaimCondition instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimCondition=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimCondition} PersistentVolumeClaimCondition instance
                         */
                        PersistentVolumeClaimCondition.create = function create(properties) {
                            return new PersistentVolumeClaimCondition(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimCondition message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimCondition.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimCondition} message PersistentVolumeClaimCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimCondition.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.lastProbeTime != null && Object.hasOwnProperty.call(message, "lastProbeTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastProbeTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.lastTransitionTime != null && Object.hasOwnProperty.call(message, "lastTransitionTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTransitionTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimCondition message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimCondition.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimCondition} message PersistentVolumeClaimCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimCondition.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaimCondition message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimCondition} PersistentVolumeClaimCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimCondition.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 3:
                                    message.lastProbeTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.reason = reader.string();
                                    break;
                                case 6:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaimCondition message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimCondition} PersistentVolumeClaimCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimCondition.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaimCondition message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaimCondition.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.lastProbeTime != null && message.hasOwnProperty("lastProbeTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastProbeTime);
                                if (error)
                                    return "lastProbeTime." + error;
                            }
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTransitionTime);
                                if (error)
                                    return "lastTransitionTime." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaimCondition message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimCondition} PersistentVolumeClaimCondition
                         */
                        PersistentVolumeClaimCondition.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.lastProbeTime != null) {
                                if (typeof object.lastProbeTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimCondition.lastProbeTime: object expected");
                                message.lastProbeTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastProbeTime);
                            }
                            if (object.lastTransitionTime != null) {
                                if (typeof object.lastTransitionTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimCondition.lastTransitionTime: object expected");
                                message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTransitionTime);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaimCondition message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaimCondition} message PersistentVolumeClaimCondition
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaimCondition.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.status = "";
                                object.lastProbeTime = null;
                                object.lastTransitionTime = null;
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.lastProbeTime != null && message.hasOwnProperty("lastProbeTime"))
                                object.lastProbeTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastProbeTime, options);
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime"))
                                object.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTransitionTime, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaimCondition to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimCondition
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaimCondition.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaimCondition;
                    })();

                    v1.PersistentVolumeClaimList = (function() {

                        /**
                         * Properties of a PersistentVolumeClaimList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaimList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] PersistentVolumeClaimList metadata
                         * @property {Array.<k8s.io.api.core.v1.IPersistentVolumeClaim>|null} [items] PersistentVolumeClaimList items
                         */

                        /**
                         * Constructs a new PersistentVolumeClaimList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaimList.
                         * @implements IPersistentVolumeClaimList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimList=} [properties] Properties to set
                         */
                        function PersistentVolumeClaimList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaimList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @instance
                         */
                        PersistentVolumeClaimList.prototype.metadata = null;

                        /**
                         * PersistentVolumeClaimList items.
                         * @member {Array.<k8s.io.api.core.v1.IPersistentVolumeClaim>} items
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @instance
                         */
                        PersistentVolumeClaimList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new PersistentVolumeClaimList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimList} PersistentVolumeClaimList instance
                         */
                        PersistentVolumeClaimList.create = function create(properties) {
                            return new PersistentVolumeClaimList(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimList message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimList} message PersistentVolumeClaimList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.PersistentVolumeClaim.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimList} message PersistentVolumeClaimList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaimList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimList} PersistentVolumeClaimList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.PersistentVolumeClaim.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaimList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimList} PersistentVolumeClaimList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaimList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaimList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PersistentVolumeClaim.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaimList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimList} PersistentVolumeClaimList
                         */
                        PersistentVolumeClaimList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaimList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.PersistentVolumeClaim.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaimList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaimList} message PersistentVolumeClaimList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaimList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.PersistentVolumeClaim.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaimList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaimList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaimList;
                    })();

                    v1.PersistentVolumeClaimSpec = (function() {

                        /**
                         * Properties of a PersistentVolumeClaimSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaimSpec
                         * @property {Array.<string>|null} [accessModes] PersistentVolumeClaimSpec accessModes
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector|null} [selector] PersistentVolumeClaimSpec selector
                         * @property {k8s.io.api.core.v1.IResourceRequirements|null} [resources] PersistentVolumeClaimSpec resources
                         * @property {string|null} [volumeName] PersistentVolumeClaimSpec volumeName
                         * @property {string|null} [storageClassName] PersistentVolumeClaimSpec storageClassName
                         * @property {string|null} [volumeMode] PersistentVolumeClaimSpec volumeMode
                         * @property {k8s.io.api.core.v1.ITypedLocalObjectReference|null} [dataSource] PersistentVolumeClaimSpec dataSource
                         */

                        /**
                         * Constructs a new PersistentVolumeClaimSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaimSpec.
                         * @implements IPersistentVolumeClaimSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimSpec=} [properties] Properties to set
                         */
                        function PersistentVolumeClaimSpec(properties) {
                            this.accessModes = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaimSpec accessModes.
                         * @member {Array.<string>} accessModes
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.accessModes = $util.emptyArray;

                        /**
                         * PersistentVolumeClaimSpec selector.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector|null|undefined} selector
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.selector = null;

                        /**
                         * PersistentVolumeClaimSpec resources.
                         * @member {k8s.io.api.core.v1.IResourceRequirements|null|undefined} resources
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.resources = null;

                        /**
                         * PersistentVolumeClaimSpec volumeName.
                         * @member {string} volumeName
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.volumeName = "";

                        /**
                         * PersistentVolumeClaimSpec storageClassName.
                         * @member {string} storageClassName
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.storageClassName = "";

                        /**
                         * PersistentVolumeClaimSpec volumeMode.
                         * @member {string} volumeMode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.volumeMode = "";

                        /**
                         * PersistentVolumeClaimSpec dataSource.
                         * @member {k8s.io.api.core.v1.ITypedLocalObjectReference|null|undefined} dataSource
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         */
                        PersistentVolumeClaimSpec.prototype.dataSource = null;

                        /**
                         * Creates a new PersistentVolumeClaimSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimSpec} PersistentVolumeClaimSpec instance
                         */
                        PersistentVolumeClaimSpec.create = function create(properties) {
                            return new PersistentVolumeClaimSpec(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimSpec message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimSpec} message PersistentVolumeClaimSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.accessModes != null && message.accessModes.length)
                                for (var i = 0; i < message.accessModes.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessModes[i]);
                            if (message.resources != null && Object.hasOwnProperty.call(message, "resources"))
                                $root.k8s.io.api.core.v1.ResourceRequirements.encode(message.resources, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.volumeName != null && Object.hasOwnProperty.call(message, "volumeName"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.volumeName);
                            if (message.selector != null && Object.hasOwnProperty.call(message, "selector"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.encode(message.selector, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.storageClassName != null && Object.hasOwnProperty.call(message, "storageClassName"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.storageClassName);
                            if (message.volumeMode != null && Object.hasOwnProperty.call(message, "volumeMode"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.volumeMode);
                            if (message.dataSource != null && Object.hasOwnProperty.call(message, "dataSource"))
                                $root.k8s.io.api.core.v1.TypedLocalObjectReference.encode(message.dataSource, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimSpec} message PersistentVolumeClaimSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaimSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimSpec} PersistentVolumeClaimSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.accessModes && message.accessModes.length))
                                        message.accessModes = [];
                                    message.accessModes.push(reader.string());
                                    break;
                                case 4:
                                    message.selector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.resources = $root.k8s.io.api.core.v1.ResourceRequirements.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.volumeName = reader.string();
                                    break;
                                case 5:
                                    message.storageClassName = reader.string();
                                    break;
                                case 6:
                                    message.volumeMode = reader.string();
                                    break;
                                case 7:
                                    message.dataSource = $root.k8s.io.api.core.v1.TypedLocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaimSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimSpec} PersistentVolumeClaimSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaimSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaimSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.accessModes != null && message.hasOwnProperty("accessModes")) {
                                if (!Array.isArray(message.accessModes))
                                    return "accessModes: array expected";
                                for (var i = 0; i < message.accessModes.length; ++i)
                                    if (!$util.isString(message.accessModes[i]))
                                        return "accessModes: string[] expected";
                            }
                            if (message.selector != null && message.hasOwnProperty("selector")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.verify(message.selector);
                                if (error)
                                    return "selector." + error;
                            }
                            if (message.resources != null && message.hasOwnProperty("resources")) {
                                var error = $root.k8s.io.api.core.v1.ResourceRequirements.verify(message.resources);
                                if (error)
                                    return "resources." + error;
                            }
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                if (!$util.isString(message.volumeName))
                                    return "volumeName: string expected";
                            if (message.storageClassName != null && message.hasOwnProperty("storageClassName"))
                                if (!$util.isString(message.storageClassName))
                                    return "storageClassName: string expected";
                            if (message.volumeMode != null && message.hasOwnProperty("volumeMode"))
                                if (!$util.isString(message.volumeMode))
                                    return "volumeMode: string expected";
                            if (message.dataSource != null && message.hasOwnProperty("dataSource")) {
                                var error = $root.k8s.io.api.core.v1.TypedLocalObjectReference.verify(message.dataSource);
                                if (error)
                                    return "dataSource." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaimSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimSpec} PersistentVolumeClaimSpec
                         */
                        PersistentVolumeClaimSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec();
                            if (object.accessModes) {
                                if (!Array.isArray(object.accessModes))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimSpec.accessModes: array expected");
                                message.accessModes = [];
                                for (var i = 0; i < object.accessModes.length; ++i)
                                    message.accessModes[i] = String(object.accessModes[i]);
                            }
                            if (object.selector != null) {
                                if (typeof object.selector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimSpec.selector: object expected");
                                message.selector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.fromObject(object.selector);
                            }
                            if (object.resources != null) {
                                if (typeof object.resources !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimSpec.resources: object expected");
                                message.resources = $root.k8s.io.api.core.v1.ResourceRequirements.fromObject(object.resources);
                            }
                            if (object.volumeName != null)
                                message.volumeName = String(object.volumeName);
                            if (object.storageClassName != null)
                                message.storageClassName = String(object.storageClassName);
                            if (object.volumeMode != null)
                                message.volumeMode = String(object.volumeMode);
                            if (object.dataSource != null) {
                                if (typeof object.dataSource !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimSpec.dataSource: object expected");
                                message.dataSource = $root.k8s.io.api.core.v1.TypedLocalObjectReference.fromObject(object.dataSource);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaimSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaimSpec} message PersistentVolumeClaimSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaimSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.accessModes = [];
                            if (options.defaults) {
                                object.resources = null;
                                object.volumeName = "";
                                object.selector = null;
                                object.storageClassName = "";
                                object.volumeMode = "";
                                object.dataSource = null;
                            }
                            if (message.accessModes && message.accessModes.length) {
                                object.accessModes = [];
                                for (var j = 0; j < message.accessModes.length; ++j)
                                    object.accessModes[j] = message.accessModes[j];
                            }
                            if (message.resources != null && message.hasOwnProperty("resources"))
                                object.resources = $root.k8s.io.api.core.v1.ResourceRequirements.toObject(message.resources, options);
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                object.volumeName = message.volumeName;
                            if (message.selector != null && message.hasOwnProperty("selector"))
                                object.selector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.toObject(message.selector, options);
                            if (message.storageClassName != null && message.hasOwnProperty("storageClassName"))
                                object.storageClassName = message.storageClassName;
                            if (message.volumeMode != null && message.hasOwnProperty("volumeMode"))
                                object.volumeMode = message.volumeMode;
                            if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                                object.dataSource = $root.k8s.io.api.core.v1.TypedLocalObjectReference.toObject(message.dataSource, options);
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaimSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaimSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaimSpec;
                    })();

                    v1.PersistentVolumeClaimStatus = (function() {

                        /**
                         * Properties of a PersistentVolumeClaimStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaimStatus
                         * @property {string|null} [phase] PersistentVolumeClaimStatus phase
                         * @property {Array.<string>|null} [accessModes] PersistentVolumeClaimStatus accessModes
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [capacity] PersistentVolumeClaimStatus capacity
                         * @property {Array.<k8s.io.api.core.v1.IPersistentVolumeClaimCondition>|null} [conditions] PersistentVolumeClaimStatus conditions
                         */

                        /**
                         * Constructs a new PersistentVolumeClaimStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaimStatus.
                         * @implements IPersistentVolumeClaimStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimStatus=} [properties] Properties to set
                         */
                        function PersistentVolumeClaimStatus(properties) {
                            this.accessModes = [];
                            this.capacity = {};
                            this.conditions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaimStatus phase.
                         * @member {string} phase
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @instance
                         */
                        PersistentVolumeClaimStatus.prototype.phase = "";

                        /**
                         * PersistentVolumeClaimStatus accessModes.
                         * @member {Array.<string>} accessModes
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @instance
                         */
                        PersistentVolumeClaimStatus.prototype.accessModes = $util.emptyArray;

                        /**
                         * PersistentVolumeClaimStatus capacity.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} capacity
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @instance
                         */
                        PersistentVolumeClaimStatus.prototype.capacity = $util.emptyObject;

                        /**
                         * PersistentVolumeClaimStatus conditions.
                         * @member {Array.<k8s.io.api.core.v1.IPersistentVolumeClaimCondition>} conditions
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @instance
                         */
                        PersistentVolumeClaimStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * Creates a new PersistentVolumeClaimStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimStatus} PersistentVolumeClaimStatus instance
                         */
                        PersistentVolumeClaimStatus.create = function create(properties) {
                            return new PersistentVolumeClaimStatus(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimStatus message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimStatus} message PersistentVolumeClaimStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phase);
                            if (message.accessModes != null && message.accessModes.length)
                                for (var i = 0; i < message.accessModes.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.accessModes[i]);
                            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                                for (var keys = Object.keys(message.capacity), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.capacity[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition.encode(message.conditions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimStatus} message PersistentVolumeClaimStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaimStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimStatus} PersistentVolumeClaimStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.phase = reader.string();
                                    break;
                                case 2:
                                    if (!(message.accessModes && message.accessModes.length))
                                        message.accessModes = [];
                                    message.accessModes.push(reader.string());
                                    break;
                                case 3:
                                    if (message.capacity === $util.emptyObject)
                                        message.capacity = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.capacity[key] = value;
                                    break;
                                case 4:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.api.core.v1.PersistentVolumeClaimCondition.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaimStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimStatus} PersistentVolumeClaimStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaimStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaimStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                if (!$util.isString(message.phase))
                                    return "phase: string expected";
                            if (message.accessModes != null && message.hasOwnProperty("accessModes")) {
                                if (!Array.isArray(message.accessModes))
                                    return "accessModes: array expected";
                                for (var i = 0; i < message.accessModes.length; ++i)
                                    if (!$util.isString(message.accessModes[i]))
                                        return "accessModes: string[] expected";
                            }
                            if (message.capacity != null && message.hasOwnProperty("capacity")) {
                                if (!$util.isObject(message.capacity))
                                    return "capacity: object expected";
                                var key = Object.keys(message.capacity);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.capacity[key[i]]);
                                    if (error)
                                        return "capacity." + error;
                                }
                            }
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaimStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimStatus} PersistentVolumeClaimStatus
                         */
                        PersistentVolumeClaimStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimStatus();
                            if (object.phase != null)
                                message.phase = String(object.phase);
                            if (object.accessModes) {
                                if (!Array.isArray(object.accessModes))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimStatus.accessModes: array expected");
                                message.accessModes = [];
                                for (var i = 0; i < object.accessModes.length; ++i)
                                    message.accessModes[i] = String(object.accessModes[i]);
                            }
                            if (object.capacity) {
                                if (typeof object.capacity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimStatus.capacity: object expected");
                                message.capacity = {};
                                for (var keys = Object.keys(object.capacity), i = 0; i < keys.length; ++i) {
                                    if (typeof object.capacity[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimStatus.capacity: object expected");
                                    message.capacity[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.capacity[keys[i]]);
                                }
                            }
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition.fromObject(object.conditions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaimStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaimStatus} message PersistentVolumeClaimStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaimStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.accessModes = [];
                                object.conditions = [];
                            }
                            if (options.objects || options.defaults)
                                object.capacity = {};
                            if (options.defaults)
                                object.phase = "";
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                object.phase = message.phase;
                            if (message.accessModes && message.accessModes.length) {
                                object.accessModes = [];
                                for (var j = 0; j < message.accessModes.length; ++j)
                                    object.accessModes[j] = message.accessModes[j];
                            }
                            var keys2;
                            if (message.capacity && (keys2 = Object.keys(message.capacity)).length) {
                                object.capacity = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.capacity[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.capacity[keys2[j]], options);
                            }
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.api.core.v1.PersistentVolumeClaimCondition.toObject(message.conditions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaimStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaimStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaimStatus;
                    })();

                    v1.PersistentVolumeClaimTemplate = (function() {

                        /**
                         * Properties of a PersistentVolumeClaimTemplate.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaimTemplate
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PersistentVolumeClaimTemplate metadata
                         * @property {k8s.io.api.core.v1.IPersistentVolumeClaimSpec|null} [spec] PersistentVolumeClaimTemplate spec
                         */

                        /**
                         * Constructs a new PersistentVolumeClaimTemplate.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaimTemplate.
                         * @implements IPersistentVolumeClaimTemplate
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimTemplate=} [properties] Properties to set
                         */
                        function PersistentVolumeClaimTemplate(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaimTemplate metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @instance
                         */
                        PersistentVolumeClaimTemplate.prototype.metadata = null;

                        /**
                         * PersistentVolumeClaimTemplate spec.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeClaimSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @instance
                         */
                        PersistentVolumeClaimTemplate.prototype.spec = null;

                        /**
                         * Creates a new PersistentVolumeClaimTemplate instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimTemplate=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimTemplate} PersistentVolumeClaimTemplate instance
                         */
                        PersistentVolumeClaimTemplate.create = function create(properties) {
                            return new PersistentVolumeClaimTemplate(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimTemplate message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimTemplate.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimTemplate} message PersistentVolumeClaimTemplate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimTemplate.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimTemplate message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimTemplate.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimTemplate} message PersistentVolumeClaimTemplate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimTemplate.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaimTemplate message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimTemplate} PersistentVolumeClaimTemplate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimTemplate.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaimTemplate message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimTemplate} PersistentVolumeClaimTemplate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimTemplate.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaimTemplate message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaimTemplate.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaimTemplate message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimTemplate} PersistentVolumeClaimTemplate
                         */
                        PersistentVolumeClaimTemplate.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimTemplate();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimTemplate.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeClaimTemplate.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.fromObject(object.spec);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaimTemplate message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaimTemplate} message PersistentVolumeClaimTemplate
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaimTemplate.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.PersistentVolumeClaimSpec.toObject(message.spec, options);
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaimTemplate to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimTemplate
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaimTemplate.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaimTemplate;
                    })();

                    v1.PersistentVolumeClaimVolumeSource = (function() {

                        /**
                         * Properties of a PersistentVolumeClaimVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeClaimVolumeSource
                         * @property {string|null} [claimName] PersistentVolumeClaimVolumeSource claimName
                         * @property {boolean|null} [readOnly] PersistentVolumeClaimVolumeSource readOnly
                         */

                        /**
                         * Constructs a new PersistentVolumeClaimVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeClaimVolumeSource.
                         * @implements IPersistentVolumeClaimVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimVolumeSource=} [properties] Properties to set
                         */
                        function PersistentVolumeClaimVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeClaimVolumeSource claimName.
                         * @member {string} claimName
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @instance
                         */
                        PersistentVolumeClaimVolumeSource.prototype.claimName = "";

                        /**
                         * PersistentVolumeClaimVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @instance
                         */
                        PersistentVolumeClaimVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new PersistentVolumeClaimVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource} PersistentVolumeClaimVolumeSource instance
                         */
                        PersistentVolumeClaimVolumeSource.create = function create(properties) {
                            return new PersistentVolumeClaimVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimVolumeSource} message PersistentVolumeClaimVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.claimName != null && Object.hasOwnProperty.call(message, "claimName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.claimName);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeClaimVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeClaimVolumeSource} message PersistentVolumeClaimVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeClaimVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeClaimVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource} PersistentVolumeClaimVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.claimName = reader.string();
                                    break;
                                case 2:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeClaimVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource} PersistentVolumeClaimVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeClaimVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeClaimVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeClaimVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.claimName != null && message.hasOwnProperty("claimName"))
                                if (!$util.isString(message.claimName))
                                    return "claimName: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeClaimVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource} PersistentVolumeClaimVolumeSource
                         */
                        PersistentVolumeClaimVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource();
                            if (object.claimName != null)
                                message.claimName = String(object.claimName);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeClaimVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource} message PersistentVolumeClaimVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeClaimVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.claimName = "";
                                object.readOnly = false;
                            }
                            if (message.claimName != null && message.hasOwnProperty("claimName"))
                                object.claimName = message.claimName;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeClaimVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeClaimVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeClaimVolumeSource;
                    })();

                    v1.PersistentVolumeList = (function() {

                        /**
                         * Properties of a PersistentVolumeList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] PersistentVolumeList metadata
                         * @property {Array.<k8s.io.api.core.v1.IPersistentVolume>|null} [items] PersistentVolumeList items
                         */

                        /**
                         * Constructs a new PersistentVolumeList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeList.
                         * @implements IPersistentVolumeList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeList=} [properties] Properties to set
                         */
                        function PersistentVolumeList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @instance
                         */
                        PersistentVolumeList.prototype.metadata = null;

                        /**
                         * PersistentVolumeList items.
                         * @member {Array.<k8s.io.api.core.v1.IPersistentVolume>} items
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @instance
                         */
                        PersistentVolumeList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new PersistentVolumeList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeList} PersistentVolumeList instance
                         */
                        PersistentVolumeList.create = function create(properties) {
                            return new PersistentVolumeList(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeList message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeList} message PersistentVolumeList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.PersistentVolume.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeList} message PersistentVolumeList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeList} PersistentVolumeList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.PersistentVolume.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeList} PersistentVolumeList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PersistentVolume.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeList} PersistentVolumeList
                         */
                        PersistentVolumeList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PersistentVolumeList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.PersistentVolume.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeList} message PersistentVolumeList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.PersistentVolume.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeList;
                    })();

                    v1.PersistentVolumeSource = (function() {

                        /**
                         * Properties of a PersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeSource
                         * @property {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource|null} [gcePersistentDisk] PersistentVolumeSource gcePersistentDisk
                         * @property {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource|null} [awsElasticBlockStore] PersistentVolumeSource awsElasticBlockStore
                         * @property {k8s.io.api.core.v1.IHostPathVolumeSource|null} [hostPath] PersistentVolumeSource hostPath
                         * @property {k8s.io.api.core.v1.IGlusterfsPersistentVolumeSource|null} [glusterfs] PersistentVolumeSource glusterfs
                         * @property {k8s.io.api.core.v1.INFSVolumeSource|null} [nfs] PersistentVolumeSource nfs
                         * @property {k8s.io.api.core.v1.IRBDPersistentVolumeSource|null} [rbd] PersistentVolumeSource rbd
                         * @property {k8s.io.api.core.v1.IISCSIPersistentVolumeSource|null} [iscsi] PersistentVolumeSource iscsi
                         * @property {k8s.io.api.core.v1.ICinderPersistentVolumeSource|null} [cinder] PersistentVolumeSource cinder
                         * @property {k8s.io.api.core.v1.ICephFSPersistentVolumeSource|null} [cephfs] PersistentVolumeSource cephfs
                         * @property {k8s.io.api.core.v1.IFCVolumeSource|null} [fc] PersistentVolumeSource fc
                         * @property {k8s.io.api.core.v1.IFlockerVolumeSource|null} [flocker] PersistentVolumeSource flocker
                         * @property {k8s.io.api.core.v1.IFlexPersistentVolumeSource|null} [flexVolume] PersistentVolumeSource flexVolume
                         * @property {k8s.io.api.core.v1.IAzureFilePersistentVolumeSource|null} [azureFile] PersistentVolumeSource azureFile
                         * @property {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource|null} [vsphereVolume] PersistentVolumeSource vsphereVolume
                         * @property {k8s.io.api.core.v1.IQuobyteVolumeSource|null} [quobyte] PersistentVolumeSource quobyte
                         * @property {k8s.io.api.core.v1.IAzureDiskVolumeSource|null} [azureDisk] PersistentVolumeSource azureDisk
                         * @property {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource|null} [photonPersistentDisk] PersistentVolumeSource photonPersistentDisk
                         * @property {k8s.io.api.core.v1.IPortworxVolumeSource|null} [portworxVolume] PersistentVolumeSource portworxVolume
                         * @property {k8s.io.api.core.v1.IScaleIOPersistentVolumeSource|null} [scaleIO] PersistentVolumeSource scaleIO
                         * @property {k8s.io.api.core.v1.ILocalVolumeSource|null} [local] PersistentVolumeSource local
                         * @property {k8s.io.api.core.v1.IStorageOSPersistentVolumeSource|null} [storageos] PersistentVolumeSource storageos
                         * @property {k8s.io.api.core.v1.ICSIPersistentVolumeSource|null} [csi] PersistentVolumeSource csi
                         */

                        /**
                         * Constructs a new PersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeSource.
                         * @implements IPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSource=} [properties] Properties to set
                         */
                        function PersistentVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeSource gcePersistentDisk.
                         * @member {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource|null|undefined} gcePersistentDisk
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.gcePersistentDisk = null;

                        /**
                         * PersistentVolumeSource awsElasticBlockStore.
                         * @member {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource|null|undefined} awsElasticBlockStore
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.awsElasticBlockStore = null;

                        /**
                         * PersistentVolumeSource hostPath.
                         * @member {k8s.io.api.core.v1.IHostPathVolumeSource|null|undefined} hostPath
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.hostPath = null;

                        /**
                         * PersistentVolumeSource glusterfs.
                         * @member {k8s.io.api.core.v1.IGlusterfsPersistentVolumeSource|null|undefined} glusterfs
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.glusterfs = null;

                        /**
                         * PersistentVolumeSource nfs.
                         * @member {k8s.io.api.core.v1.INFSVolumeSource|null|undefined} nfs
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.nfs = null;

                        /**
                         * PersistentVolumeSource rbd.
                         * @member {k8s.io.api.core.v1.IRBDPersistentVolumeSource|null|undefined} rbd
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.rbd = null;

                        /**
                         * PersistentVolumeSource iscsi.
                         * @member {k8s.io.api.core.v1.IISCSIPersistentVolumeSource|null|undefined} iscsi
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.iscsi = null;

                        /**
                         * PersistentVolumeSource cinder.
                         * @member {k8s.io.api.core.v1.ICinderPersistentVolumeSource|null|undefined} cinder
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.cinder = null;

                        /**
                         * PersistentVolumeSource cephfs.
                         * @member {k8s.io.api.core.v1.ICephFSPersistentVolumeSource|null|undefined} cephfs
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.cephfs = null;

                        /**
                         * PersistentVolumeSource fc.
                         * @member {k8s.io.api.core.v1.IFCVolumeSource|null|undefined} fc
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.fc = null;

                        /**
                         * PersistentVolumeSource flocker.
                         * @member {k8s.io.api.core.v1.IFlockerVolumeSource|null|undefined} flocker
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.flocker = null;

                        /**
                         * PersistentVolumeSource flexVolume.
                         * @member {k8s.io.api.core.v1.IFlexPersistentVolumeSource|null|undefined} flexVolume
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.flexVolume = null;

                        /**
                         * PersistentVolumeSource azureFile.
                         * @member {k8s.io.api.core.v1.IAzureFilePersistentVolumeSource|null|undefined} azureFile
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.azureFile = null;

                        /**
                         * PersistentVolumeSource vsphereVolume.
                         * @member {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource|null|undefined} vsphereVolume
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.vsphereVolume = null;

                        /**
                         * PersistentVolumeSource quobyte.
                         * @member {k8s.io.api.core.v1.IQuobyteVolumeSource|null|undefined} quobyte
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.quobyte = null;

                        /**
                         * PersistentVolumeSource azureDisk.
                         * @member {k8s.io.api.core.v1.IAzureDiskVolumeSource|null|undefined} azureDisk
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.azureDisk = null;

                        /**
                         * PersistentVolumeSource photonPersistentDisk.
                         * @member {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource|null|undefined} photonPersistentDisk
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.photonPersistentDisk = null;

                        /**
                         * PersistentVolumeSource portworxVolume.
                         * @member {k8s.io.api.core.v1.IPortworxVolumeSource|null|undefined} portworxVolume
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.portworxVolume = null;

                        /**
                         * PersistentVolumeSource scaleIO.
                         * @member {k8s.io.api.core.v1.IScaleIOPersistentVolumeSource|null|undefined} scaleIO
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.scaleIO = null;

                        /**
                         * PersistentVolumeSource local.
                         * @member {k8s.io.api.core.v1.ILocalVolumeSource|null|undefined} local
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.local = null;

                        /**
                         * PersistentVolumeSource storageos.
                         * @member {k8s.io.api.core.v1.IStorageOSPersistentVolumeSource|null|undefined} storageos
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.storageos = null;

                        /**
                         * PersistentVolumeSource csi.
                         * @member {k8s.io.api.core.v1.ICSIPersistentVolumeSource|null|undefined} csi
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         */
                        PersistentVolumeSource.prototype.csi = null;

                        /**
                         * Creates a new PersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSource} PersistentVolumeSource instance
                         */
                        PersistentVolumeSource.create = function create(properties) {
                            return new PersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSource} message PersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.gcePersistentDisk != null && Object.hasOwnProperty.call(message, "gcePersistentDisk"))
                                $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.encode(message.gcePersistentDisk, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.awsElasticBlockStore != null && Object.hasOwnProperty.call(message, "awsElasticBlockStore"))
                                $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.encode(message.awsElasticBlockStore, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.hostPath != null && Object.hasOwnProperty.call(message, "hostPath"))
                                $root.k8s.io.api.core.v1.HostPathVolumeSource.encode(message.hostPath, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.glusterfs != null && Object.hasOwnProperty.call(message, "glusterfs"))
                                $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.encode(message.glusterfs, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.nfs != null && Object.hasOwnProperty.call(message, "nfs"))
                                $root.k8s.io.api.core.v1.NFSVolumeSource.encode(message.nfs, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.rbd != null && Object.hasOwnProperty.call(message, "rbd"))
                                $root.k8s.io.api.core.v1.RBDPersistentVolumeSource.encode(message.rbd, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.iscsi != null && Object.hasOwnProperty.call(message, "iscsi"))
                                $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource.encode(message.iscsi, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.cinder != null && Object.hasOwnProperty.call(message, "cinder"))
                                $root.k8s.io.api.core.v1.CinderPersistentVolumeSource.encode(message.cinder, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.cephfs != null && Object.hasOwnProperty.call(message, "cephfs"))
                                $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource.encode(message.cephfs, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.fc != null && Object.hasOwnProperty.call(message, "fc"))
                                $root.k8s.io.api.core.v1.FCVolumeSource.encode(message.fc, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.flocker != null && Object.hasOwnProperty.call(message, "flocker"))
                                $root.k8s.io.api.core.v1.FlockerVolumeSource.encode(message.flocker, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.flexVolume != null && Object.hasOwnProperty.call(message, "flexVolume"))
                                $root.k8s.io.api.core.v1.FlexPersistentVolumeSource.encode(message.flexVolume, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.azureFile != null && Object.hasOwnProperty.call(message, "azureFile"))
                                $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource.encode(message.azureFile, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.vsphereVolume != null && Object.hasOwnProperty.call(message, "vsphereVolume"))
                                $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.encode(message.vsphereVolume, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.quobyte != null && Object.hasOwnProperty.call(message, "quobyte"))
                                $root.k8s.io.api.core.v1.QuobyteVolumeSource.encode(message.quobyte, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.azureDisk != null && Object.hasOwnProperty.call(message, "azureDisk"))
                                $root.k8s.io.api.core.v1.AzureDiskVolumeSource.encode(message.azureDisk, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                            if (message.photonPersistentDisk != null && Object.hasOwnProperty.call(message, "photonPersistentDisk"))
                                $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.encode(message.photonPersistentDisk, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                            if (message.portworxVolume != null && Object.hasOwnProperty.call(message, "portworxVolume"))
                                $root.k8s.io.api.core.v1.PortworxVolumeSource.encode(message.portworxVolume, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.scaleIO != null && Object.hasOwnProperty.call(message, "scaleIO"))
                                $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.encode(message.scaleIO, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.local != null && Object.hasOwnProperty.call(message, "local"))
                                $root.k8s.io.api.core.v1.LocalVolumeSource.encode(message.local, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.storageos != null && Object.hasOwnProperty.call(message, "storageos"))
                                $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource.encode(message.storageos, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.csi != null && Object.hasOwnProperty.call(message, "csi"))
                                $root.k8s.io.api.core.v1.CSIPersistentVolumeSource.encode(message.csi, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSource} message PersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSource} PersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.gcePersistentDisk = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.awsElasticBlockStore = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.hostPath = $root.k8s.io.api.core.v1.HostPathVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.glusterfs = $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.nfs = $root.k8s.io.api.core.v1.NFSVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.rbd = $root.k8s.io.api.core.v1.RBDPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.iscsi = $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.cinder = $root.k8s.io.api.core.v1.CinderPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.cephfs = $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.fc = $root.k8s.io.api.core.v1.FCVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.flocker = $root.k8s.io.api.core.v1.FlockerVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.flexVolume = $root.k8s.io.api.core.v1.FlexPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 13:
                                    message.azureFile = $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.vsphereVolume = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    message.quobyte = $root.k8s.io.api.core.v1.QuobyteVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.azureDisk = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 17:
                                    message.photonPersistentDisk = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 18:
                                    message.portworxVolume = $root.k8s.io.api.core.v1.PortworxVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.scaleIO = $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 20:
                                    message.local = $root.k8s.io.api.core.v1.LocalVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 21:
                                    message.storageos = $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.csi = $root.k8s.io.api.core.v1.CSIPersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSource} PersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.gcePersistentDisk != null && message.hasOwnProperty("gcePersistentDisk")) {
                                var error = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.verify(message.gcePersistentDisk);
                                if (error)
                                    return "gcePersistentDisk." + error;
                            }
                            if (message.awsElasticBlockStore != null && message.hasOwnProperty("awsElasticBlockStore")) {
                                var error = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.verify(message.awsElasticBlockStore);
                                if (error)
                                    return "awsElasticBlockStore." + error;
                            }
                            if (message.hostPath != null && message.hasOwnProperty("hostPath")) {
                                var error = $root.k8s.io.api.core.v1.HostPathVolumeSource.verify(message.hostPath);
                                if (error)
                                    return "hostPath." + error;
                            }
                            if (message.glusterfs != null && message.hasOwnProperty("glusterfs")) {
                                var error = $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.verify(message.glusterfs);
                                if (error)
                                    return "glusterfs." + error;
                            }
                            if (message.nfs != null && message.hasOwnProperty("nfs")) {
                                var error = $root.k8s.io.api.core.v1.NFSVolumeSource.verify(message.nfs);
                                if (error)
                                    return "nfs." + error;
                            }
                            if (message.rbd != null && message.hasOwnProperty("rbd")) {
                                var error = $root.k8s.io.api.core.v1.RBDPersistentVolumeSource.verify(message.rbd);
                                if (error)
                                    return "rbd." + error;
                            }
                            if (message.iscsi != null && message.hasOwnProperty("iscsi")) {
                                var error = $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource.verify(message.iscsi);
                                if (error)
                                    return "iscsi." + error;
                            }
                            if (message.cinder != null && message.hasOwnProperty("cinder")) {
                                var error = $root.k8s.io.api.core.v1.CinderPersistentVolumeSource.verify(message.cinder);
                                if (error)
                                    return "cinder." + error;
                            }
                            if (message.cephfs != null && message.hasOwnProperty("cephfs")) {
                                var error = $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource.verify(message.cephfs);
                                if (error)
                                    return "cephfs." + error;
                            }
                            if (message.fc != null && message.hasOwnProperty("fc")) {
                                var error = $root.k8s.io.api.core.v1.FCVolumeSource.verify(message.fc);
                                if (error)
                                    return "fc." + error;
                            }
                            if (message.flocker != null && message.hasOwnProperty("flocker")) {
                                var error = $root.k8s.io.api.core.v1.FlockerVolumeSource.verify(message.flocker);
                                if (error)
                                    return "flocker." + error;
                            }
                            if (message.flexVolume != null && message.hasOwnProperty("flexVolume")) {
                                var error = $root.k8s.io.api.core.v1.FlexPersistentVolumeSource.verify(message.flexVolume);
                                if (error)
                                    return "flexVolume." + error;
                            }
                            if (message.azureFile != null && message.hasOwnProperty("azureFile")) {
                                var error = $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource.verify(message.azureFile);
                                if (error)
                                    return "azureFile." + error;
                            }
                            if (message.vsphereVolume != null && message.hasOwnProperty("vsphereVolume")) {
                                var error = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.verify(message.vsphereVolume);
                                if (error)
                                    return "vsphereVolume." + error;
                            }
                            if (message.quobyte != null && message.hasOwnProperty("quobyte")) {
                                var error = $root.k8s.io.api.core.v1.QuobyteVolumeSource.verify(message.quobyte);
                                if (error)
                                    return "quobyte." + error;
                            }
                            if (message.azureDisk != null && message.hasOwnProperty("azureDisk")) {
                                var error = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.verify(message.azureDisk);
                                if (error)
                                    return "azureDisk." + error;
                            }
                            if (message.photonPersistentDisk != null && message.hasOwnProperty("photonPersistentDisk")) {
                                var error = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.verify(message.photonPersistentDisk);
                                if (error)
                                    return "photonPersistentDisk." + error;
                            }
                            if (message.portworxVolume != null && message.hasOwnProperty("portworxVolume")) {
                                var error = $root.k8s.io.api.core.v1.PortworxVolumeSource.verify(message.portworxVolume);
                                if (error)
                                    return "portworxVolume." + error;
                            }
                            if (message.scaleIO != null && message.hasOwnProperty("scaleIO")) {
                                var error = $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.verify(message.scaleIO);
                                if (error)
                                    return "scaleIO." + error;
                            }
                            if (message.local != null && message.hasOwnProperty("local")) {
                                var error = $root.k8s.io.api.core.v1.LocalVolumeSource.verify(message.local);
                                if (error)
                                    return "local." + error;
                            }
                            if (message.storageos != null && message.hasOwnProperty("storageos")) {
                                var error = $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource.verify(message.storageos);
                                if (error)
                                    return "storageos." + error;
                            }
                            if (message.csi != null && message.hasOwnProperty("csi")) {
                                var error = $root.k8s.io.api.core.v1.CSIPersistentVolumeSource.verify(message.csi);
                                if (error)
                                    return "csi." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSource} PersistentVolumeSource
                         */
                        PersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeSource();
                            if (object.gcePersistentDisk != null) {
                                if (typeof object.gcePersistentDisk !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.gcePersistentDisk: object expected");
                                message.gcePersistentDisk = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.fromObject(object.gcePersistentDisk);
                            }
                            if (object.awsElasticBlockStore != null) {
                                if (typeof object.awsElasticBlockStore !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.awsElasticBlockStore: object expected");
                                message.awsElasticBlockStore = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.fromObject(object.awsElasticBlockStore);
                            }
                            if (object.hostPath != null) {
                                if (typeof object.hostPath !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.hostPath: object expected");
                                message.hostPath = $root.k8s.io.api.core.v1.HostPathVolumeSource.fromObject(object.hostPath);
                            }
                            if (object.glusterfs != null) {
                                if (typeof object.glusterfs !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.glusterfs: object expected");
                                message.glusterfs = $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.fromObject(object.glusterfs);
                            }
                            if (object.nfs != null) {
                                if (typeof object.nfs !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.nfs: object expected");
                                message.nfs = $root.k8s.io.api.core.v1.NFSVolumeSource.fromObject(object.nfs);
                            }
                            if (object.rbd != null) {
                                if (typeof object.rbd !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.rbd: object expected");
                                message.rbd = $root.k8s.io.api.core.v1.RBDPersistentVolumeSource.fromObject(object.rbd);
                            }
                            if (object.iscsi != null) {
                                if (typeof object.iscsi !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.iscsi: object expected");
                                message.iscsi = $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource.fromObject(object.iscsi);
                            }
                            if (object.cinder != null) {
                                if (typeof object.cinder !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.cinder: object expected");
                                message.cinder = $root.k8s.io.api.core.v1.CinderPersistentVolumeSource.fromObject(object.cinder);
                            }
                            if (object.cephfs != null) {
                                if (typeof object.cephfs !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.cephfs: object expected");
                                message.cephfs = $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource.fromObject(object.cephfs);
                            }
                            if (object.fc != null) {
                                if (typeof object.fc !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.fc: object expected");
                                message.fc = $root.k8s.io.api.core.v1.FCVolumeSource.fromObject(object.fc);
                            }
                            if (object.flocker != null) {
                                if (typeof object.flocker !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.flocker: object expected");
                                message.flocker = $root.k8s.io.api.core.v1.FlockerVolumeSource.fromObject(object.flocker);
                            }
                            if (object.flexVolume != null) {
                                if (typeof object.flexVolume !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.flexVolume: object expected");
                                message.flexVolume = $root.k8s.io.api.core.v1.FlexPersistentVolumeSource.fromObject(object.flexVolume);
                            }
                            if (object.azureFile != null) {
                                if (typeof object.azureFile !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.azureFile: object expected");
                                message.azureFile = $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource.fromObject(object.azureFile);
                            }
                            if (object.vsphereVolume != null) {
                                if (typeof object.vsphereVolume !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.vsphereVolume: object expected");
                                message.vsphereVolume = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.fromObject(object.vsphereVolume);
                            }
                            if (object.quobyte != null) {
                                if (typeof object.quobyte !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.quobyte: object expected");
                                message.quobyte = $root.k8s.io.api.core.v1.QuobyteVolumeSource.fromObject(object.quobyte);
                            }
                            if (object.azureDisk != null) {
                                if (typeof object.azureDisk !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.azureDisk: object expected");
                                message.azureDisk = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.fromObject(object.azureDisk);
                            }
                            if (object.photonPersistentDisk != null) {
                                if (typeof object.photonPersistentDisk !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.photonPersistentDisk: object expected");
                                message.photonPersistentDisk = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.fromObject(object.photonPersistentDisk);
                            }
                            if (object.portworxVolume != null) {
                                if (typeof object.portworxVolume !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.portworxVolume: object expected");
                                message.portworxVolume = $root.k8s.io.api.core.v1.PortworxVolumeSource.fromObject(object.portworxVolume);
                            }
                            if (object.scaleIO != null) {
                                if (typeof object.scaleIO !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.scaleIO: object expected");
                                message.scaleIO = $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.fromObject(object.scaleIO);
                            }
                            if (object.local != null) {
                                if (typeof object.local !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.local: object expected");
                                message.local = $root.k8s.io.api.core.v1.LocalVolumeSource.fromObject(object.local);
                            }
                            if (object.storageos != null) {
                                if (typeof object.storageos !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.storageos: object expected");
                                message.storageos = $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource.fromObject(object.storageos);
                            }
                            if (object.csi != null) {
                                if (typeof object.csi !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSource.csi: object expected");
                                message.csi = $root.k8s.io.api.core.v1.CSIPersistentVolumeSource.fromObject(object.csi);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeSource} message PersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.gcePersistentDisk = null;
                                object.awsElasticBlockStore = null;
                                object.hostPath = null;
                                object.glusterfs = null;
                                object.nfs = null;
                                object.rbd = null;
                                object.iscsi = null;
                                object.cinder = null;
                                object.cephfs = null;
                                object.fc = null;
                                object.flocker = null;
                                object.flexVolume = null;
                                object.azureFile = null;
                                object.vsphereVolume = null;
                                object.quobyte = null;
                                object.azureDisk = null;
                                object.photonPersistentDisk = null;
                                object.portworxVolume = null;
                                object.scaleIO = null;
                                object.local = null;
                                object.storageos = null;
                                object.csi = null;
                            }
                            if (message.gcePersistentDisk != null && message.hasOwnProperty("gcePersistentDisk"))
                                object.gcePersistentDisk = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.toObject(message.gcePersistentDisk, options);
                            if (message.awsElasticBlockStore != null && message.hasOwnProperty("awsElasticBlockStore"))
                                object.awsElasticBlockStore = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.toObject(message.awsElasticBlockStore, options);
                            if (message.hostPath != null && message.hasOwnProperty("hostPath"))
                                object.hostPath = $root.k8s.io.api.core.v1.HostPathVolumeSource.toObject(message.hostPath, options);
                            if (message.glusterfs != null && message.hasOwnProperty("glusterfs"))
                                object.glusterfs = $root.k8s.io.api.core.v1.GlusterfsPersistentVolumeSource.toObject(message.glusterfs, options);
                            if (message.nfs != null && message.hasOwnProperty("nfs"))
                                object.nfs = $root.k8s.io.api.core.v1.NFSVolumeSource.toObject(message.nfs, options);
                            if (message.rbd != null && message.hasOwnProperty("rbd"))
                                object.rbd = $root.k8s.io.api.core.v1.RBDPersistentVolumeSource.toObject(message.rbd, options);
                            if (message.iscsi != null && message.hasOwnProperty("iscsi"))
                                object.iscsi = $root.k8s.io.api.core.v1.ISCSIPersistentVolumeSource.toObject(message.iscsi, options);
                            if (message.cinder != null && message.hasOwnProperty("cinder"))
                                object.cinder = $root.k8s.io.api.core.v1.CinderPersistentVolumeSource.toObject(message.cinder, options);
                            if (message.cephfs != null && message.hasOwnProperty("cephfs"))
                                object.cephfs = $root.k8s.io.api.core.v1.CephFSPersistentVolumeSource.toObject(message.cephfs, options);
                            if (message.fc != null && message.hasOwnProperty("fc"))
                                object.fc = $root.k8s.io.api.core.v1.FCVolumeSource.toObject(message.fc, options);
                            if (message.flocker != null && message.hasOwnProperty("flocker"))
                                object.flocker = $root.k8s.io.api.core.v1.FlockerVolumeSource.toObject(message.flocker, options);
                            if (message.flexVolume != null && message.hasOwnProperty("flexVolume"))
                                object.flexVolume = $root.k8s.io.api.core.v1.FlexPersistentVolumeSource.toObject(message.flexVolume, options);
                            if (message.azureFile != null && message.hasOwnProperty("azureFile"))
                                object.azureFile = $root.k8s.io.api.core.v1.AzureFilePersistentVolumeSource.toObject(message.azureFile, options);
                            if (message.vsphereVolume != null && message.hasOwnProperty("vsphereVolume"))
                                object.vsphereVolume = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.toObject(message.vsphereVolume, options);
                            if (message.quobyte != null && message.hasOwnProperty("quobyte"))
                                object.quobyte = $root.k8s.io.api.core.v1.QuobyteVolumeSource.toObject(message.quobyte, options);
                            if (message.azureDisk != null && message.hasOwnProperty("azureDisk"))
                                object.azureDisk = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.toObject(message.azureDisk, options);
                            if (message.photonPersistentDisk != null && message.hasOwnProperty("photonPersistentDisk"))
                                object.photonPersistentDisk = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.toObject(message.photonPersistentDisk, options);
                            if (message.portworxVolume != null && message.hasOwnProperty("portworxVolume"))
                                object.portworxVolume = $root.k8s.io.api.core.v1.PortworxVolumeSource.toObject(message.portworxVolume, options);
                            if (message.scaleIO != null && message.hasOwnProperty("scaleIO"))
                                object.scaleIO = $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.toObject(message.scaleIO, options);
                            if (message.local != null && message.hasOwnProperty("local"))
                                object.local = $root.k8s.io.api.core.v1.LocalVolumeSource.toObject(message.local, options);
                            if (message.storageos != null && message.hasOwnProperty("storageos"))
                                object.storageos = $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource.toObject(message.storageos, options);
                            if (message.csi != null && message.hasOwnProperty("csi"))
                                object.csi = $root.k8s.io.api.core.v1.CSIPersistentVolumeSource.toObject(message.csi, options);
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeSource;
                    })();

                    v1.PersistentVolumeSpec = (function() {

                        /**
                         * Properties of a PersistentVolumeSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeSpec
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [capacity] PersistentVolumeSpec capacity
                         * @property {k8s.io.api.core.v1.IPersistentVolumeSource|null} [persistentVolumeSource] PersistentVolumeSpec persistentVolumeSource
                         * @property {Array.<string>|null} [accessModes] PersistentVolumeSpec accessModes
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [claimRef] PersistentVolumeSpec claimRef
                         * @property {string|null} [persistentVolumeReclaimPolicy] PersistentVolumeSpec persistentVolumeReclaimPolicy
                         * @property {string|null} [storageClassName] PersistentVolumeSpec storageClassName
                         * @property {Array.<string>|null} [mountOptions] PersistentVolumeSpec mountOptions
                         * @property {string|null} [volumeMode] PersistentVolumeSpec volumeMode
                         * @property {k8s.io.api.core.v1.IVolumeNodeAffinity|null} [nodeAffinity] PersistentVolumeSpec nodeAffinity
                         */

                        /**
                         * Constructs a new PersistentVolumeSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeSpec.
                         * @implements IPersistentVolumeSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSpec=} [properties] Properties to set
                         */
                        function PersistentVolumeSpec(properties) {
                            this.capacity = {};
                            this.accessModes = [];
                            this.mountOptions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeSpec capacity.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} capacity
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.capacity = $util.emptyObject;

                        /**
                         * PersistentVolumeSpec persistentVolumeSource.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeSource|null|undefined} persistentVolumeSource
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.persistentVolumeSource = null;

                        /**
                         * PersistentVolumeSpec accessModes.
                         * @member {Array.<string>} accessModes
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.accessModes = $util.emptyArray;

                        /**
                         * PersistentVolumeSpec claimRef.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} claimRef
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.claimRef = null;

                        /**
                         * PersistentVolumeSpec persistentVolumeReclaimPolicy.
                         * @member {string} persistentVolumeReclaimPolicy
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.persistentVolumeReclaimPolicy = "";

                        /**
                         * PersistentVolumeSpec storageClassName.
                         * @member {string} storageClassName
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.storageClassName = "";

                        /**
                         * PersistentVolumeSpec mountOptions.
                         * @member {Array.<string>} mountOptions
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.mountOptions = $util.emptyArray;

                        /**
                         * PersistentVolumeSpec volumeMode.
                         * @member {string} volumeMode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.volumeMode = "";

                        /**
                         * PersistentVolumeSpec nodeAffinity.
                         * @member {k8s.io.api.core.v1.IVolumeNodeAffinity|null|undefined} nodeAffinity
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         */
                        PersistentVolumeSpec.prototype.nodeAffinity = null;

                        /**
                         * Creates a new PersistentVolumeSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSpec} PersistentVolumeSpec instance
                         */
                        PersistentVolumeSpec.create = function create(properties) {
                            return new PersistentVolumeSpec(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeSpec message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSpec} message PersistentVolumeSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                                for (var keys = Object.keys(message.capacity), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.capacity[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.persistentVolumeSource != null && Object.hasOwnProperty.call(message, "persistentVolumeSource"))
                                $root.k8s.io.api.core.v1.PersistentVolumeSource.encode(message.persistentVolumeSource, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.accessModes != null && message.accessModes.length)
                                for (var i = 0; i < message.accessModes.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.accessModes[i]);
                            if (message.claimRef != null && Object.hasOwnProperty.call(message, "claimRef"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.claimRef, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.persistentVolumeReclaimPolicy != null && Object.hasOwnProperty.call(message, "persistentVolumeReclaimPolicy"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.persistentVolumeReclaimPolicy);
                            if (message.storageClassName != null && Object.hasOwnProperty.call(message, "storageClassName"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.storageClassName);
                            if (message.mountOptions != null && message.mountOptions.length)
                                for (var i = 0; i < message.mountOptions.length; ++i)
                                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.mountOptions[i]);
                            if (message.volumeMode != null && Object.hasOwnProperty.call(message, "volumeMode"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.volumeMode);
                            if (message.nodeAffinity != null && Object.hasOwnProperty.call(message, "nodeAffinity"))
                                $root.k8s.io.api.core.v1.VolumeNodeAffinity.encode(message.nodeAffinity, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeSpec} message PersistentVolumeSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSpec} PersistentVolumeSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeSpec(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (message.capacity === $util.emptyObject)
                                        message.capacity = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.capacity[key] = value;
                                    break;
                                case 2:
                                    message.persistentVolumeSource = $root.k8s.io.api.core.v1.PersistentVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    if (!(message.accessModes && message.accessModes.length))
                                        message.accessModes = [];
                                    message.accessModes.push(reader.string());
                                    break;
                                case 4:
                                    message.claimRef = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.persistentVolumeReclaimPolicy = reader.string();
                                    break;
                                case 6:
                                    message.storageClassName = reader.string();
                                    break;
                                case 7:
                                    if (!(message.mountOptions && message.mountOptions.length))
                                        message.mountOptions = [];
                                    message.mountOptions.push(reader.string());
                                    break;
                                case 8:
                                    message.volumeMode = reader.string();
                                    break;
                                case 9:
                                    message.nodeAffinity = $root.k8s.io.api.core.v1.VolumeNodeAffinity.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSpec} PersistentVolumeSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.capacity != null && message.hasOwnProperty("capacity")) {
                                if (!$util.isObject(message.capacity))
                                    return "capacity: object expected";
                                var key = Object.keys(message.capacity);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.capacity[key[i]]);
                                    if (error)
                                        return "capacity." + error;
                                }
                            }
                            if (message.persistentVolumeSource != null && message.hasOwnProperty("persistentVolumeSource")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeSource.verify(message.persistentVolumeSource);
                                if (error)
                                    return "persistentVolumeSource." + error;
                            }
                            if (message.accessModes != null && message.hasOwnProperty("accessModes")) {
                                if (!Array.isArray(message.accessModes))
                                    return "accessModes: array expected";
                                for (var i = 0; i < message.accessModes.length; ++i)
                                    if (!$util.isString(message.accessModes[i]))
                                        return "accessModes: string[] expected";
                            }
                            if (message.claimRef != null && message.hasOwnProperty("claimRef")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.claimRef);
                                if (error)
                                    return "claimRef." + error;
                            }
                            if (message.persistentVolumeReclaimPolicy != null && message.hasOwnProperty("persistentVolumeReclaimPolicy"))
                                if (!$util.isString(message.persistentVolumeReclaimPolicy))
                                    return "persistentVolumeReclaimPolicy: string expected";
                            if (message.storageClassName != null && message.hasOwnProperty("storageClassName"))
                                if (!$util.isString(message.storageClassName))
                                    return "storageClassName: string expected";
                            if (message.mountOptions != null && message.hasOwnProperty("mountOptions")) {
                                if (!Array.isArray(message.mountOptions))
                                    return "mountOptions: array expected";
                                for (var i = 0; i < message.mountOptions.length; ++i)
                                    if (!$util.isString(message.mountOptions[i]))
                                        return "mountOptions: string[] expected";
                            }
                            if (message.volumeMode != null && message.hasOwnProperty("volumeMode"))
                                if (!$util.isString(message.volumeMode))
                                    return "volumeMode: string expected";
                            if (message.nodeAffinity != null && message.hasOwnProperty("nodeAffinity")) {
                                var error = $root.k8s.io.api.core.v1.VolumeNodeAffinity.verify(message.nodeAffinity);
                                if (error)
                                    return "nodeAffinity." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeSpec} PersistentVolumeSpec
                         */
                        PersistentVolumeSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeSpec();
                            if (object.capacity) {
                                if (typeof object.capacity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.capacity: object expected");
                                message.capacity = {};
                                for (var keys = Object.keys(object.capacity), i = 0; i < keys.length; ++i) {
                                    if (typeof object.capacity[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.capacity: object expected");
                                    message.capacity[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.capacity[keys[i]]);
                                }
                            }
                            if (object.persistentVolumeSource != null) {
                                if (typeof object.persistentVolumeSource !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.persistentVolumeSource: object expected");
                                message.persistentVolumeSource = $root.k8s.io.api.core.v1.PersistentVolumeSource.fromObject(object.persistentVolumeSource);
                            }
                            if (object.accessModes) {
                                if (!Array.isArray(object.accessModes))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.accessModes: array expected");
                                message.accessModes = [];
                                for (var i = 0; i < object.accessModes.length; ++i)
                                    message.accessModes[i] = String(object.accessModes[i]);
                            }
                            if (object.claimRef != null) {
                                if (typeof object.claimRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.claimRef: object expected");
                                message.claimRef = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.claimRef);
                            }
                            if (object.persistentVolumeReclaimPolicy != null)
                                message.persistentVolumeReclaimPolicy = String(object.persistentVolumeReclaimPolicy);
                            if (object.storageClassName != null)
                                message.storageClassName = String(object.storageClassName);
                            if (object.mountOptions) {
                                if (!Array.isArray(object.mountOptions))
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.mountOptions: array expected");
                                message.mountOptions = [];
                                for (var i = 0; i < object.mountOptions.length; ++i)
                                    message.mountOptions[i] = String(object.mountOptions[i]);
                            }
                            if (object.volumeMode != null)
                                message.volumeMode = String(object.volumeMode);
                            if (object.nodeAffinity != null) {
                                if (typeof object.nodeAffinity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PersistentVolumeSpec.nodeAffinity: object expected");
                                message.nodeAffinity = $root.k8s.io.api.core.v1.VolumeNodeAffinity.fromObject(object.nodeAffinity);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeSpec} message PersistentVolumeSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.accessModes = [];
                                object.mountOptions = [];
                            }
                            if (options.objects || options.defaults)
                                object.capacity = {};
                            if (options.defaults) {
                                object.persistentVolumeSource = null;
                                object.claimRef = null;
                                object.persistentVolumeReclaimPolicy = "";
                                object.storageClassName = "";
                                object.volumeMode = "";
                                object.nodeAffinity = null;
                            }
                            var keys2;
                            if (message.capacity && (keys2 = Object.keys(message.capacity)).length) {
                                object.capacity = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.capacity[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.capacity[keys2[j]], options);
                            }
                            if (message.persistentVolumeSource != null && message.hasOwnProperty("persistentVolumeSource"))
                                object.persistentVolumeSource = $root.k8s.io.api.core.v1.PersistentVolumeSource.toObject(message.persistentVolumeSource, options);
                            if (message.accessModes && message.accessModes.length) {
                                object.accessModes = [];
                                for (var j = 0; j < message.accessModes.length; ++j)
                                    object.accessModes[j] = message.accessModes[j];
                            }
                            if (message.claimRef != null && message.hasOwnProperty("claimRef"))
                                object.claimRef = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.claimRef, options);
                            if (message.persistentVolumeReclaimPolicy != null && message.hasOwnProperty("persistentVolumeReclaimPolicy"))
                                object.persistentVolumeReclaimPolicy = message.persistentVolumeReclaimPolicy;
                            if (message.storageClassName != null && message.hasOwnProperty("storageClassName"))
                                object.storageClassName = message.storageClassName;
                            if (message.mountOptions && message.mountOptions.length) {
                                object.mountOptions = [];
                                for (var j = 0; j < message.mountOptions.length; ++j)
                                    object.mountOptions[j] = message.mountOptions[j];
                            }
                            if (message.volumeMode != null && message.hasOwnProperty("volumeMode"))
                                object.volumeMode = message.volumeMode;
                            if (message.nodeAffinity != null && message.hasOwnProperty("nodeAffinity"))
                                object.nodeAffinity = $root.k8s.io.api.core.v1.VolumeNodeAffinity.toObject(message.nodeAffinity, options);
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeSpec;
                    })();

                    v1.PersistentVolumeStatus = (function() {

                        /**
                         * Properties of a PersistentVolumeStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPersistentVolumeStatus
                         * @property {string|null} [phase] PersistentVolumeStatus phase
                         * @property {string|null} [message] PersistentVolumeStatus message
                         * @property {string|null} [reason] PersistentVolumeStatus reason
                         */

                        /**
                         * Constructs a new PersistentVolumeStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PersistentVolumeStatus.
                         * @implements IPersistentVolumeStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPersistentVolumeStatus=} [properties] Properties to set
                         */
                        function PersistentVolumeStatus(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PersistentVolumeStatus phase.
                         * @member {string} phase
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @instance
                         */
                        PersistentVolumeStatus.prototype.phase = "";

                        /**
                         * PersistentVolumeStatus message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @instance
                         */
                        PersistentVolumeStatus.prototype.message = "";

                        /**
                         * PersistentVolumeStatus reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @instance
                         */
                        PersistentVolumeStatus.prototype.reason = "";

                        /**
                         * Creates a new PersistentVolumeStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PersistentVolumeStatus} PersistentVolumeStatus instance
                         */
                        PersistentVolumeStatus.create = function create(properties) {
                            return new PersistentVolumeStatus(properties);
                        };

                        /**
                         * Encodes the specified PersistentVolumeStatus message. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeStatus} message PersistentVolumeStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phase);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
                            return writer;
                        };

                        /**
                         * Encodes the specified PersistentVolumeStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PersistentVolumeStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPersistentVolumeStatus} message PersistentVolumeStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PersistentVolumeStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PersistentVolumeStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PersistentVolumeStatus} PersistentVolumeStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PersistentVolumeStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.phase = reader.string();
                                    break;
                                case 2:
                                    message.message = reader.string();
                                    break;
                                case 3:
                                    message.reason = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PersistentVolumeStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PersistentVolumeStatus} PersistentVolumeStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PersistentVolumeStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PersistentVolumeStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PersistentVolumeStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                if (!$util.isString(message.phase))
                                    return "phase: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            return null;
                        };

                        /**
                         * Creates a PersistentVolumeStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PersistentVolumeStatus} PersistentVolumeStatus
                         */
                        PersistentVolumeStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PersistentVolumeStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PersistentVolumeStatus();
                            if (object.phase != null)
                                message.phase = String(object.phase);
                            if (object.message != null)
                                message.message = String(object.message);
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PersistentVolumeStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @static
                         * @param {k8s.io.api.core.v1.PersistentVolumeStatus} message PersistentVolumeStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PersistentVolumeStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.phase = "";
                                object.message = "";
                                object.reason = "";
                            }
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                object.phase = message.phase;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            return object;
                        };

                        /**
                         * Converts this PersistentVolumeStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PersistentVolumeStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PersistentVolumeStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PersistentVolumeStatus;
                    })();

                    v1.PhotonPersistentDiskVolumeSource = (function() {

                        /**
                         * Properties of a PhotonPersistentDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPhotonPersistentDiskVolumeSource
                         * @property {string|null} [pdID] PhotonPersistentDiskVolumeSource pdID
                         * @property {string|null} [fsType] PhotonPersistentDiskVolumeSource fsType
                         */

                        /**
                         * Constructs a new PhotonPersistentDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PhotonPersistentDiskVolumeSource.
                         * @implements IPhotonPersistentDiskVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource=} [properties] Properties to set
                         */
                        function PhotonPersistentDiskVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PhotonPersistentDiskVolumeSource pdID.
                         * @member {string} pdID
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @instance
                         */
                        PhotonPersistentDiskVolumeSource.prototype.pdID = "";

                        /**
                         * PhotonPersistentDiskVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @instance
                         */
                        PhotonPersistentDiskVolumeSource.prototype.fsType = "";

                        /**
                         * Creates a new PhotonPersistentDiskVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource} PhotonPersistentDiskVolumeSource instance
                         */
                        PhotonPersistentDiskVolumeSource.create = function create(properties) {
                            return new PhotonPersistentDiskVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified PhotonPersistentDiskVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource} message PhotonPersistentDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PhotonPersistentDiskVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.pdID != null && Object.hasOwnProperty.call(message, "pdID"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pdID);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            return writer;
                        };

                        /**
                         * Encodes the specified PhotonPersistentDiskVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource} message PhotonPersistentDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PhotonPersistentDiskVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PhotonPersistentDiskVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource} PhotonPersistentDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PhotonPersistentDiskVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.pdID = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PhotonPersistentDiskVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource} PhotonPersistentDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PhotonPersistentDiskVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PhotonPersistentDiskVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PhotonPersistentDiskVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.pdID != null && message.hasOwnProperty("pdID"))
                                if (!$util.isString(message.pdID))
                                    return "pdID: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            return null;
                        };

                        /**
                         * Creates a PhotonPersistentDiskVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource} PhotonPersistentDiskVolumeSource
                         */
                        PhotonPersistentDiskVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource();
                            if (object.pdID != null)
                                message.pdID = String(object.pdID);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PhotonPersistentDiskVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource} message PhotonPersistentDiskVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PhotonPersistentDiskVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.pdID = "";
                                object.fsType = "";
                            }
                            if (message.pdID != null && message.hasOwnProperty("pdID"))
                                object.pdID = message.pdID;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            return object;
                        };

                        /**
                         * Converts this PhotonPersistentDiskVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PhotonPersistentDiskVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PhotonPersistentDiskVolumeSource;
                    })();

                    v1.Pod = (function() {

                        /**
                         * Properties of a Pod.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPod
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Pod metadata
                         * @property {k8s.io.api.core.v1.IPodSpec|null} [spec] Pod spec
                         * @property {k8s.io.api.core.v1.IPodStatus|null} [status] Pod status
                         */

                        /**
                         * Constructs a new Pod.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Pod.
                         * @implements IPod
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPod=} [properties] Properties to set
                         */
                        function Pod(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Pod metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Pod
                         * @instance
                         */
                        Pod.prototype.metadata = null;

                        /**
                         * Pod spec.
                         * @member {k8s.io.api.core.v1.IPodSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.Pod
                         * @instance
                         */
                        Pod.prototype.spec = null;

                        /**
                         * Pod status.
                         * @member {k8s.io.api.core.v1.IPodStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.Pod
                         * @instance
                         */
                        Pod.prototype.status = null;

                        /**
                         * Creates a new Pod instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {k8s.io.api.core.v1.IPod=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Pod} Pod instance
                         */
                        Pod.create = function create(properties) {
                            return new Pod(properties);
                        };

                        /**
                         * Encodes the specified Pod message. Does not implicitly {@link k8s.io.api.core.v1.Pod.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {k8s.io.api.core.v1.IPod} message Pod message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Pod.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.PodSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.PodStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Pod message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Pod.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {k8s.io.api.core.v1.IPod} message Pod message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Pod.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Pod message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Pod} Pod
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Pod.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Pod();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.PodSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.PodStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Pod message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Pod} Pod
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Pod.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Pod message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Pod.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.PodSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.PodStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Pod message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Pod} Pod
                         */
                        Pod.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Pod)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Pod();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Pod.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Pod.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.PodSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Pod.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.PodStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Pod message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Pod
                         * @static
                         * @param {k8s.io.api.core.v1.Pod} message Pod
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Pod.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.PodSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.PodStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this Pod to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Pod
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Pod.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Pod;
                    })();

                    v1.PodAffinity = (function() {

                        /**
                         * Properties of a PodAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodAffinity
                         * @property {Array.<k8s.io.api.core.v1.IPodAffinityTerm>|null} [requiredDuringSchedulingIgnoredDuringExecution] PodAffinity requiredDuringSchedulingIgnoredDuringExecution
                         * @property {Array.<k8s.io.api.core.v1.IWeightedPodAffinityTerm>|null} [preferredDuringSchedulingIgnoredDuringExecution] PodAffinity preferredDuringSchedulingIgnoredDuringExecution
                         */

                        /**
                         * Constructs a new PodAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodAffinity.
                         * @implements IPodAffinity
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodAffinity=} [properties] Properties to set
                         */
                        function PodAffinity(properties) {
                            this.requiredDuringSchedulingIgnoredDuringExecution = [];
                            this.preferredDuringSchedulingIgnoredDuringExecution = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodAffinity requiredDuringSchedulingIgnoredDuringExecution.
                         * @member {Array.<k8s.io.api.core.v1.IPodAffinityTerm>} requiredDuringSchedulingIgnoredDuringExecution
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @instance
                         */
                        PodAffinity.prototype.requiredDuringSchedulingIgnoredDuringExecution = $util.emptyArray;

                        /**
                         * PodAffinity preferredDuringSchedulingIgnoredDuringExecution.
                         * @member {Array.<k8s.io.api.core.v1.IWeightedPodAffinityTerm>} preferredDuringSchedulingIgnoredDuringExecution
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @instance
                         */
                        PodAffinity.prototype.preferredDuringSchedulingIgnoredDuringExecution = $util.emptyArray;

                        /**
                         * Creates a new PodAffinity instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAffinity=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodAffinity} PodAffinity instance
                         */
                        PodAffinity.create = function create(properties) {
                            return new PodAffinity(properties);
                        };

                        /**
                         * Encodes the specified PodAffinity message. Does not implicitly {@link k8s.io.api.core.v1.PodAffinity.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAffinity} message PodAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAffinity.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && message.requiredDuringSchedulingIgnoredDuringExecution.length)
                                for (var i = 0; i < message.requiredDuringSchedulingIgnoredDuringExecution.length; ++i)
                                    $root.k8s.io.api.core.v1.PodAffinityTerm.encode(message.requiredDuringSchedulingIgnoredDuringExecution[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.preferredDuringSchedulingIgnoredDuringExecution != null && message.preferredDuringSchedulingIgnoredDuringExecution.length)
                                for (var i = 0; i < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++i)
                                    $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.encode(message.preferredDuringSchedulingIgnoredDuringExecution[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodAffinity message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodAffinity.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAffinity} message PodAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAffinity.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodAffinity message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodAffinity} PodAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAffinity.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodAffinity();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.requiredDuringSchedulingIgnoredDuringExecution && message.requiredDuringSchedulingIgnoredDuringExecution.length))
                                        message.requiredDuringSchedulingIgnoredDuringExecution = [];
                                    message.requiredDuringSchedulingIgnoredDuringExecution.push($root.k8s.io.api.core.v1.PodAffinityTerm.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (!(message.preferredDuringSchedulingIgnoredDuringExecution && message.preferredDuringSchedulingIgnoredDuringExecution.length))
                                        message.preferredDuringSchedulingIgnoredDuringExecution = [];
                                    message.preferredDuringSchedulingIgnoredDuringExecution.push($root.k8s.io.api.core.v1.WeightedPodAffinityTerm.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodAffinity message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodAffinity} PodAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAffinity.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodAffinity message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodAffinity.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("requiredDuringSchedulingIgnoredDuringExecution")) {
                                if (!Array.isArray(message.requiredDuringSchedulingIgnoredDuringExecution))
                                    return "requiredDuringSchedulingIgnoredDuringExecution: array expected";
                                for (var i = 0; i < message.requiredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodAffinityTerm.verify(message.requiredDuringSchedulingIgnoredDuringExecution[i]);
                                    if (error)
                                        return "requiredDuringSchedulingIgnoredDuringExecution." + error;
                                }
                            }
                            if (message.preferredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("preferredDuringSchedulingIgnoredDuringExecution")) {
                                if (!Array.isArray(message.preferredDuringSchedulingIgnoredDuringExecution))
                                    return "preferredDuringSchedulingIgnoredDuringExecution: array expected";
                                for (var i = 0; i < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.verify(message.preferredDuringSchedulingIgnoredDuringExecution[i]);
                                    if (error)
                                        return "preferredDuringSchedulingIgnoredDuringExecution." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PodAffinity message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodAffinity} PodAffinity
                         */
                        PodAffinity.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodAffinity)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodAffinity();
                            if (object.requiredDuringSchedulingIgnoredDuringExecution) {
                                if (!Array.isArray(object.requiredDuringSchedulingIgnoredDuringExecution))
                                    throw TypeError(".k8s.io.api.core.v1.PodAffinity.requiredDuringSchedulingIgnoredDuringExecution: array expected");
                                message.requiredDuringSchedulingIgnoredDuringExecution = [];
                                for (var i = 0; i < object.requiredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    if (typeof object.requiredDuringSchedulingIgnoredDuringExecution[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodAffinity.requiredDuringSchedulingIgnoredDuringExecution: object expected");
                                    message.requiredDuringSchedulingIgnoredDuringExecution[i] = $root.k8s.io.api.core.v1.PodAffinityTerm.fromObject(object.requiredDuringSchedulingIgnoredDuringExecution[i]);
                                }
                            }
                            if (object.preferredDuringSchedulingIgnoredDuringExecution) {
                                if (!Array.isArray(object.preferredDuringSchedulingIgnoredDuringExecution))
                                    throw TypeError(".k8s.io.api.core.v1.PodAffinity.preferredDuringSchedulingIgnoredDuringExecution: array expected");
                                message.preferredDuringSchedulingIgnoredDuringExecution = [];
                                for (var i = 0; i < object.preferredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    if (typeof object.preferredDuringSchedulingIgnoredDuringExecution[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodAffinity.preferredDuringSchedulingIgnoredDuringExecution: object expected");
                                    message.preferredDuringSchedulingIgnoredDuringExecution[i] = $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.fromObject(object.preferredDuringSchedulingIgnoredDuringExecution[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodAffinity message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.PodAffinity} message PodAffinity
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodAffinity.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.requiredDuringSchedulingIgnoredDuringExecution = [];
                                object.preferredDuringSchedulingIgnoredDuringExecution = [];
                            }
                            if (message.requiredDuringSchedulingIgnoredDuringExecution && message.requiredDuringSchedulingIgnoredDuringExecution.length) {
                                object.requiredDuringSchedulingIgnoredDuringExecution = [];
                                for (var j = 0; j < message.requiredDuringSchedulingIgnoredDuringExecution.length; ++j)
                                    object.requiredDuringSchedulingIgnoredDuringExecution[j] = $root.k8s.io.api.core.v1.PodAffinityTerm.toObject(message.requiredDuringSchedulingIgnoredDuringExecution[j], options);
                            }
                            if (message.preferredDuringSchedulingIgnoredDuringExecution && message.preferredDuringSchedulingIgnoredDuringExecution.length) {
                                object.preferredDuringSchedulingIgnoredDuringExecution = [];
                                for (var j = 0; j < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++j)
                                    object.preferredDuringSchedulingIgnoredDuringExecution[j] = $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.toObject(message.preferredDuringSchedulingIgnoredDuringExecution[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PodAffinity to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodAffinity
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodAffinity.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodAffinity;
                    })();

                    v1.PodAffinityTerm = (function() {

                        /**
                         * Properties of a PodAffinityTerm.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodAffinityTerm
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector|null} [labelSelector] PodAffinityTerm labelSelector
                         * @property {Array.<string>|null} [namespaces] PodAffinityTerm namespaces
                         * @property {string|null} [topologyKey] PodAffinityTerm topologyKey
                         */

                        /**
                         * Constructs a new PodAffinityTerm.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodAffinityTerm.
                         * @implements IPodAffinityTerm
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodAffinityTerm=} [properties] Properties to set
                         */
                        function PodAffinityTerm(properties) {
                            this.namespaces = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodAffinityTerm labelSelector.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector|null|undefined} labelSelector
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @instance
                         */
                        PodAffinityTerm.prototype.labelSelector = null;

                        /**
                         * PodAffinityTerm namespaces.
                         * @member {Array.<string>} namespaces
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @instance
                         */
                        PodAffinityTerm.prototype.namespaces = $util.emptyArray;

                        /**
                         * PodAffinityTerm topologyKey.
                         * @member {string} topologyKey
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @instance
                         */
                        PodAffinityTerm.prototype.topologyKey = "";

                        /**
                         * Creates a new PodAffinityTerm instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAffinityTerm=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodAffinityTerm} PodAffinityTerm instance
                         */
                        PodAffinityTerm.create = function create(properties) {
                            return new PodAffinityTerm(properties);
                        };

                        /**
                         * Encodes the specified PodAffinityTerm message. Does not implicitly {@link k8s.io.api.core.v1.PodAffinityTerm.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAffinityTerm} message PodAffinityTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAffinityTerm.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.labelSelector != null && Object.hasOwnProperty.call(message, "labelSelector"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.encode(message.labelSelector, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.namespaces != null && message.namespaces.length)
                                for (var i = 0; i < message.namespaces.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.namespaces[i]);
                            if (message.topologyKey != null && Object.hasOwnProperty.call(message, "topologyKey"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.topologyKey);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodAffinityTerm message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodAffinityTerm.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAffinityTerm} message PodAffinityTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAffinityTerm.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodAffinityTerm message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodAffinityTerm} PodAffinityTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAffinityTerm.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodAffinityTerm();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.labelSelector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.namespaces && message.namespaces.length))
                                        message.namespaces = [];
                                    message.namespaces.push(reader.string());
                                    break;
                                case 3:
                                    message.topologyKey = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodAffinityTerm message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodAffinityTerm} PodAffinityTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAffinityTerm.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodAffinityTerm message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodAffinityTerm.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.labelSelector != null && message.hasOwnProperty("labelSelector")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.verify(message.labelSelector);
                                if (error)
                                    return "labelSelector." + error;
                            }
                            if (message.namespaces != null && message.hasOwnProperty("namespaces")) {
                                if (!Array.isArray(message.namespaces))
                                    return "namespaces: array expected";
                                for (var i = 0; i < message.namespaces.length; ++i)
                                    if (!$util.isString(message.namespaces[i]))
                                        return "namespaces: string[] expected";
                            }
                            if (message.topologyKey != null && message.hasOwnProperty("topologyKey"))
                                if (!$util.isString(message.topologyKey))
                                    return "topologyKey: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodAffinityTerm message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodAffinityTerm} PodAffinityTerm
                         */
                        PodAffinityTerm.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodAffinityTerm)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodAffinityTerm();
                            if (object.labelSelector != null) {
                                if (typeof object.labelSelector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodAffinityTerm.labelSelector: object expected");
                                message.labelSelector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.fromObject(object.labelSelector);
                            }
                            if (object.namespaces) {
                                if (!Array.isArray(object.namespaces))
                                    throw TypeError(".k8s.io.api.core.v1.PodAffinityTerm.namespaces: array expected");
                                message.namespaces = [];
                                for (var i = 0; i < object.namespaces.length; ++i)
                                    message.namespaces[i] = String(object.namespaces[i]);
                            }
                            if (object.topologyKey != null)
                                message.topologyKey = String(object.topologyKey);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodAffinityTerm message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.PodAffinityTerm} message PodAffinityTerm
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodAffinityTerm.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.namespaces = [];
                            if (options.defaults) {
                                object.labelSelector = null;
                                object.topologyKey = "";
                            }
                            if (message.labelSelector != null && message.hasOwnProperty("labelSelector"))
                                object.labelSelector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.toObject(message.labelSelector, options);
                            if (message.namespaces && message.namespaces.length) {
                                object.namespaces = [];
                                for (var j = 0; j < message.namespaces.length; ++j)
                                    object.namespaces[j] = message.namespaces[j];
                            }
                            if (message.topologyKey != null && message.hasOwnProperty("topologyKey"))
                                object.topologyKey = message.topologyKey;
                            return object;
                        };

                        /**
                         * Converts this PodAffinityTerm to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodAffinityTerm
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodAffinityTerm.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodAffinityTerm;
                    })();

                    v1.PodAntiAffinity = (function() {

                        /**
                         * Properties of a PodAntiAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodAntiAffinity
                         * @property {Array.<k8s.io.api.core.v1.IPodAffinityTerm>|null} [requiredDuringSchedulingIgnoredDuringExecution] PodAntiAffinity requiredDuringSchedulingIgnoredDuringExecution
                         * @property {Array.<k8s.io.api.core.v1.IWeightedPodAffinityTerm>|null} [preferredDuringSchedulingIgnoredDuringExecution] PodAntiAffinity preferredDuringSchedulingIgnoredDuringExecution
                         */

                        /**
                         * Constructs a new PodAntiAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodAntiAffinity.
                         * @implements IPodAntiAffinity
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodAntiAffinity=} [properties] Properties to set
                         */
                        function PodAntiAffinity(properties) {
                            this.requiredDuringSchedulingIgnoredDuringExecution = [];
                            this.preferredDuringSchedulingIgnoredDuringExecution = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodAntiAffinity requiredDuringSchedulingIgnoredDuringExecution.
                         * @member {Array.<k8s.io.api.core.v1.IPodAffinityTerm>} requiredDuringSchedulingIgnoredDuringExecution
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @instance
                         */
                        PodAntiAffinity.prototype.requiredDuringSchedulingIgnoredDuringExecution = $util.emptyArray;

                        /**
                         * PodAntiAffinity preferredDuringSchedulingIgnoredDuringExecution.
                         * @member {Array.<k8s.io.api.core.v1.IWeightedPodAffinityTerm>} preferredDuringSchedulingIgnoredDuringExecution
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @instance
                         */
                        PodAntiAffinity.prototype.preferredDuringSchedulingIgnoredDuringExecution = $util.emptyArray;

                        /**
                         * Creates a new PodAntiAffinity instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAntiAffinity=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodAntiAffinity} PodAntiAffinity instance
                         */
                        PodAntiAffinity.create = function create(properties) {
                            return new PodAntiAffinity(properties);
                        };

                        /**
                         * Encodes the specified PodAntiAffinity message. Does not implicitly {@link k8s.io.api.core.v1.PodAntiAffinity.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAntiAffinity} message PodAntiAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAntiAffinity.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && message.requiredDuringSchedulingIgnoredDuringExecution.length)
                                for (var i = 0; i < message.requiredDuringSchedulingIgnoredDuringExecution.length; ++i)
                                    $root.k8s.io.api.core.v1.PodAffinityTerm.encode(message.requiredDuringSchedulingIgnoredDuringExecution[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.preferredDuringSchedulingIgnoredDuringExecution != null && message.preferredDuringSchedulingIgnoredDuringExecution.length)
                                for (var i = 0; i < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++i)
                                    $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.encode(message.preferredDuringSchedulingIgnoredDuringExecution[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodAntiAffinity message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodAntiAffinity.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAntiAffinity} message PodAntiAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAntiAffinity.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodAntiAffinity message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodAntiAffinity} PodAntiAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAntiAffinity.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodAntiAffinity();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.requiredDuringSchedulingIgnoredDuringExecution && message.requiredDuringSchedulingIgnoredDuringExecution.length))
                                        message.requiredDuringSchedulingIgnoredDuringExecution = [];
                                    message.requiredDuringSchedulingIgnoredDuringExecution.push($root.k8s.io.api.core.v1.PodAffinityTerm.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (!(message.preferredDuringSchedulingIgnoredDuringExecution && message.preferredDuringSchedulingIgnoredDuringExecution.length))
                                        message.preferredDuringSchedulingIgnoredDuringExecution = [];
                                    message.preferredDuringSchedulingIgnoredDuringExecution.push($root.k8s.io.api.core.v1.WeightedPodAffinityTerm.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodAntiAffinity message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodAntiAffinity} PodAntiAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAntiAffinity.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodAntiAffinity message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodAntiAffinity.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.requiredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("requiredDuringSchedulingIgnoredDuringExecution")) {
                                if (!Array.isArray(message.requiredDuringSchedulingIgnoredDuringExecution))
                                    return "requiredDuringSchedulingIgnoredDuringExecution: array expected";
                                for (var i = 0; i < message.requiredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodAffinityTerm.verify(message.requiredDuringSchedulingIgnoredDuringExecution[i]);
                                    if (error)
                                        return "requiredDuringSchedulingIgnoredDuringExecution." + error;
                                }
                            }
                            if (message.preferredDuringSchedulingIgnoredDuringExecution != null && message.hasOwnProperty("preferredDuringSchedulingIgnoredDuringExecution")) {
                                if (!Array.isArray(message.preferredDuringSchedulingIgnoredDuringExecution))
                                    return "preferredDuringSchedulingIgnoredDuringExecution: array expected";
                                for (var i = 0; i < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.verify(message.preferredDuringSchedulingIgnoredDuringExecution[i]);
                                    if (error)
                                        return "preferredDuringSchedulingIgnoredDuringExecution." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PodAntiAffinity message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodAntiAffinity} PodAntiAffinity
                         */
                        PodAntiAffinity.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodAntiAffinity)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodAntiAffinity();
                            if (object.requiredDuringSchedulingIgnoredDuringExecution) {
                                if (!Array.isArray(object.requiredDuringSchedulingIgnoredDuringExecution))
                                    throw TypeError(".k8s.io.api.core.v1.PodAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution: array expected");
                                message.requiredDuringSchedulingIgnoredDuringExecution = [];
                                for (var i = 0; i < object.requiredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    if (typeof object.requiredDuringSchedulingIgnoredDuringExecution[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution: object expected");
                                    message.requiredDuringSchedulingIgnoredDuringExecution[i] = $root.k8s.io.api.core.v1.PodAffinityTerm.fromObject(object.requiredDuringSchedulingIgnoredDuringExecution[i]);
                                }
                            }
                            if (object.preferredDuringSchedulingIgnoredDuringExecution) {
                                if (!Array.isArray(object.preferredDuringSchedulingIgnoredDuringExecution))
                                    throw TypeError(".k8s.io.api.core.v1.PodAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution: array expected");
                                message.preferredDuringSchedulingIgnoredDuringExecution = [];
                                for (var i = 0; i < object.preferredDuringSchedulingIgnoredDuringExecution.length; ++i) {
                                    if (typeof object.preferredDuringSchedulingIgnoredDuringExecution[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution: object expected");
                                    message.preferredDuringSchedulingIgnoredDuringExecution[i] = $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.fromObject(object.preferredDuringSchedulingIgnoredDuringExecution[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodAntiAffinity message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.PodAntiAffinity} message PodAntiAffinity
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodAntiAffinity.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.requiredDuringSchedulingIgnoredDuringExecution = [];
                                object.preferredDuringSchedulingIgnoredDuringExecution = [];
                            }
                            if (message.requiredDuringSchedulingIgnoredDuringExecution && message.requiredDuringSchedulingIgnoredDuringExecution.length) {
                                object.requiredDuringSchedulingIgnoredDuringExecution = [];
                                for (var j = 0; j < message.requiredDuringSchedulingIgnoredDuringExecution.length; ++j)
                                    object.requiredDuringSchedulingIgnoredDuringExecution[j] = $root.k8s.io.api.core.v1.PodAffinityTerm.toObject(message.requiredDuringSchedulingIgnoredDuringExecution[j], options);
                            }
                            if (message.preferredDuringSchedulingIgnoredDuringExecution && message.preferredDuringSchedulingIgnoredDuringExecution.length) {
                                object.preferredDuringSchedulingIgnoredDuringExecution = [];
                                for (var j = 0; j < message.preferredDuringSchedulingIgnoredDuringExecution.length; ++j)
                                    object.preferredDuringSchedulingIgnoredDuringExecution[j] = $root.k8s.io.api.core.v1.WeightedPodAffinityTerm.toObject(message.preferredDuringSchedulingIgnoredDuringExecution[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PodAntiAffinity to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodAntiAffinity
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodAntiAffinity.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodAntiAffinity;
                    })();

                    v1.PodAttachOptions = (function() {

                        /**
                         * Properties of a PodAttachOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodAttachOptions
                         * @property {boolean|null} [stdin] PodAttachOptions stdin
                         * @property {boolean|null} [stdout] PodAttachOptions stdout
                         * @property {boolean|null} [stderr] PodAttachOptions stderr
                         * @property {boolean|null} [tty] PodAttachOptions tty
                         * @property {string|null} [container] PodAttachOptions container
                         */

                        /**
                         * Constructs a new PodAttachOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodAttachOptions.
                         * @implements IPodAttachOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodAttachOptions=} [properties] Properties to set
                         */
                        function PodAttachOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodAttachOptions stdin.
                         * @member {boolean} stdin
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @instance
                         */
                        PodAttachOptions.prototype.stdin = false;

                        /**
                         * PodAttachOptions stdout.
                         * @member {boolean} stdout
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @instance
                         */
                        PodAttachOptions.prototype.stdout = false;

                        /**
                         * PodAttachOptions stderr.
                         * @member {boolean} stderr
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @instance
                         */
                        PodAttachOptions.prototype.stderr = false;

                        /**
                         * PodAttachOptions tty.
                         * @member {boolean} tty
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @instance
                         */
                        PodAttachOptions.prototype.tty = false;

                        /**
                         * PodAttachOptions container.
                         * @member {string} container
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @instance
                         */
                        PodAttachOptions.prototype.container = "";

                        /**
                         * Creates a new PodAttachOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAttachOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodAttachOptions} PodAttachOptions instance
                         */
                        PodAttachOptions.create = function create(properties) {
                            return new PodAttachOptions(properties);
                        };

                        /**
                         * Encodes the specified PodAttachOptions message. Does not implicitly {@link k8s.io.api.core.v1.PodAttachOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAttachOptions} message PodAttachOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAttachOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.stdin != null && Object.hasOwnProperty.call(message, "stdin"))
                                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.stdin);
                            if (message.stdout != null && Object.hasOwnProperty.call(message, "stdout"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.stdout);
                            if (message.stderr != null && Object.hasOwnProperty.call(message, "stderr"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.stderr);
                            if (message.tty != null && Object.hasOwnProperty.call(message, "tty"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.tty);
                            if (message.container != null && Object.hasOwnProperty.call(message, "container"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.container);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodAttachOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodAttachOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodAttachOptions} message PodAttachOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodAttachOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodAttachOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodAttachOptions} PodAttachOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAttachOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodAttachOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.stdin = reader.bool();
                                    break;
                                case 2:
                                    message.stdout = reader.bool();
                                    break;
                                case 3:
                                    message.stderr = reader.bool();
                                    break;
                                case 4:
                                    message.tty = reader.bool();
                                    break;
                                case 5:
                                    message.container = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodAttachOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodAttachOptions} PodAttachOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodAttachOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodAttachOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodAttachOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                if (typeof message.stdin !== "boolean")
                                    return "stdin: boolean expected";
                            if (message.stdout != null && message.hasOwnProperty("stdout"))
                                if (typeof message.stdout !== "boolean")
                                    return "stdout: boolean expected";
                            if (message.stderr != null && message.hasOwnProperty("stderr"))
                                if (typeof message.stderr !== "boolean")
                                    return "stderr: boolean expected";
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                if (typeof message.tty !== "boolean")
                                    return "tty: boolean expected";
                            if (message.container != null && message.hasOwnProperty("container"))
                                if (!$util.isString(message.container))
                                    return "container: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodAttachOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodAttachOptions} PodAttachOptions
                         */
                        PodAttachOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodAttachOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodAttachOptions();
                            if (object.stdin != null)
                                message.stdin = Boolean(object.stdin);
                            if (object.stdout != null)
                                message.stdout = Boolean(object.stdout);
                            if (object.stderr != null)
                                message.stderr = Boolean(object.stderr);
                            if (object.tty != null)
                                message.tty = Boolean(object.tty);
                            if (object.container != null)
                                message.container = String(object.container);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodAttachOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @static
                         * @param {k8s.io.api.core.v1.PodAttachOptions} message PodAttachOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodAttachOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.stdin = false;
                                object.stdout = false;
                                object.stderr = false;
                                object.tty = false;
                                object.container = "";
                            }
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                object.stdin = message.stdin;
                            if (message.stdout != null && message.hasOwnProperty("stdout"))
                                object.stdout = message.stdout;
                            if (message.stderr != null && message.hasOwnProperty("stderr"))
                                object.stderr = message.stderr;
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                object.tty = message.tty;
                            if (message.container != null && message.hasOwnProperty("container"))
                                object.container = message.container;
                            return object;
                        };

                        /**
                         * Converts this PodAttachOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodAttachOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodAttachOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodAttachOptions;
                    })();

                    v1.PodCondition = (function() {

                        /**
                         * Properties of a PodCondition.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodCondition
                         * @property {string|null} [type] PodCondition type
                         * @property {string|null} [status] PodCondition status
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastProbeTime] PodCondition lastProbeTime
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTransitionTime] PodCondition lastTransitionTime
                         * @property {string|null} [reason] PodCondition reason
                         * @property {string|null} [message] PodCondition message
                         */

                        /**
                         * Constructs a new PodCondition.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodCondition.
                         * @implements IPodCondition
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodCondition=} [properties] Properties to set
                         */
                        function PodCondition(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodCondition type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         */
                        PodCondition.prototype.type = "";

                        /**
                         * PodCondition status.
                         * @member {string} status
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         */
                        PodCondition.prototype.status = "";

                        /**
                         * PodCondition lastProbeTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastProbeTime
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         */
                        PodCondition.prototype.lastProbeTime = null;

                        /**
                         * PodCondition lastTransitionTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTransitionTime
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         */
                        PodCondition.prototype.lastTransitionTime = null;

                        /**
                         * PodCondition reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         */
                        PodCondition.prototype.reason = "";

                        /**
                         * PodCondition message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         */
                        PodCondition.prototype.message = "";

                        /**
                         * Creates a new PodCondition instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IPodCondition=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodCondition} PodCondition instance
                         */
                        PodCondition.create = function create(properties) {
                            return new PodCondition(properties);
                        };

                        /**
                         * Encodes the specified PodCondition message. Does not implicitly {@link k8s.io.api.core.v1.PodCondition.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IPodCondition} message PodCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodCondition.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.lastProbeTime != null && Object.hasOwnProperty.call(message, "lastProbeTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastProbeTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.lastTransitionTime != null && Object.hasOwnProperty.call(message, "lastTransitionTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTransitionTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodCondition message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodCondition.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IPodCondition} message PodCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodCondition.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodCondition message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodCondition} PodCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodCondition.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodCondition();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 3:
                                    message.lastProbeTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.reason = reader.string();
                                    break;
                                case 6:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodCondition message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodCondition} PodCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodCondition.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodCondition message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodCondition.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.lastProbeTime != null && message.hasOwnProperty("lastProbeTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastProbeTime);
                                if (error)
                                    return "lastProbeTime." + error;
                            }
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTransitionTime);
                                if (error)
                                    return "lastTransitionTime." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodCondition message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodCondition} PodCondition
                         */
                        PodCondition.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodCondition)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodCondition();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.lastProbeTime != null) {
                                if (typeof object.lastProbeTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodCondition.lastProbeTime: object expected");
                                message.lastProbeTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastProbeTime);
                            }
                            if (object.lastTransitionTime != null) {
                                if (typeof object.lastTransitionTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodCondition.lastTransitionTime: object expected");
                                message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTransitionTime);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodCondition message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @static
                         * @param {k8s.io.api.core.v1.PodCondition} message PodCondition
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodCondition.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.status = "";
                                object.lastProbeTime = null;
                                object.lastTransitionTime = null;
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.lastProbeTime != null && message.hasOwnProperty("lastProbeTime"))
                                object.lastProbeTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastProbeTime, options);
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime"))
                                object.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTransitionTime, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this PodCondition to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodCondition
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodCondition.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodCondition;
                    })();

                    v1.PodDNSConfig = (function() {

                        /**
                         * Properties of a PodDNSConfig.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodDNSConfig
                         * @property {Array.<string>|null} [nameservers] PodDNSConfig nameservers
                         * @property {Array.<string>|null} [searches] PodDNSConfig searches
                         * @property {Array.<k8s.io.api.core.v1.IPodDNSConfigOption>|null} [options] PodDNSConfig options
                         */

                        /**
                         * Constructs a new PodDNSConfig.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodDNSConfig.
                         * @implements IPodDNSConfig
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodDNSConfig=} [properties] Properties to set
                         */
                        function PodDNSConfig(properties) {
                            this.nameservers = [];
                            this.searches = [];
                            this.options = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodDNSConfig nameservers.
                         * @member {Array.<string>} nameservers
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @instance
                         */
                        PodDNSConfig.prototype.nameservers = $util.emptyArray;

                        /**
                         * PodDNSConfig searches.
                         * @member {Array.<string>} searches
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @instance
                         */
                        PodDNSConfig.prototype.searches = $util.emptyArray;

                        /**
                         * PodDNSConfig options.
                         * @member {Array.<k8s.io.api.core.v1.IPodDNSConfigOption>} options
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @instance
                         */
                        PodDNSConfig.prototype.options = $util.emptyArray;

                        /**
                         * Creates a new PodDNSConfig instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {k8s.io.api.core.v1.IPodDNSConfig=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodDNSConfig} PodDNSConfig instance
                         */
                        PodDNSConfig.create = function create(properties) {
                            return new PodDNSConfig(properties);
                        };

                        /**
                         * Encodes the specified PodDNSConfig message. Does not implicitly {@link k8s.io.api.core.v1.PodDNSConfig.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {k8s.io.api.core.v1.IPodDNSConfig} message PodDNSConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodDNSConfig.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.nameservers != null && message.nameservers.length)
                                for (var i = 0; i < message.nameservers.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.nameservers[i]);
                            if (message.searches != null && message.searches.length)
                                for (var i = 0; i < message.searches.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.searches[i]);
                            if (message.options != null && message.options.length)
                                for (var i = 0; i < message.options.length; ++i)
                                    $root.k8s.io.api.core.v1.PodDNSConfigOption.encode(message.options[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodDNSConfig message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodDNSConfig.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {k8s.io.api.core.v1.IPodDNSConfig} message PodDNSConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodDNSConfig.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodDNSConfig message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodDNSConfig} PodDNSConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodDNSConfig.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodDNSConfig();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.nameservers && message.nameservers.length))
                                        message.nameservers = [];
                                    message.nameservers.push(reader.string());
                                    break;
                                case 2:
                                    if (!(message.searches && message.searches.length))
                                        message.searches = [];
                                    message.searches.push(reader.string());
                                    break;
                                case 3:
                                    if (!(message.options && message.options.length))
                                        message.options = [];
                                    message.options.push($root.k8s.io.api.core.v1.PodDNSConfigOption.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodDNSConfig message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodDNSConfig} PodDNSConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodDNSConfig.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodDNSConfig message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodDNSConfig.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.nameservers != null && message.hasOwnProperty("nameservers")) {
                                if (!Array.isArray(message.nameservers))
                                    return "nameservers: array expected";
                                for (var i = 0; i < message.nameservers.length; ++i)
                                    if (!$util.isString(message.nameservers[i]))
                                        return "nameservers: string[] expected";
                            }
                            if (message.searches != null && message.hasOwnProperty("searches")) {
                                if (!Array.isArray(message.searches))
                                    return "searches: array expected";
                                for (var i = 0; i < message.searches.length; ++i)
                                    if (!$util.isString(message.searches[i]))
                                        return "searches: string[] expected";
                            }
                            if (message.options != null && message.hasOwnProperty("options")) {
                                if (!Array.isArray(message.options))
                                    return "options: array expected";
                                for (var i = 0; i < message.options.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodDNSConfigOption.verify(message.options[i]);
                                    if (error)
                                        return "options." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PodDNSConfig message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodDNSConfig} PodDNSConfig
                         */
                        PodDNSConfig.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodDNSConfig)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodDNSConfig();
                            if (object.nameservers) {
                                if (!Array.isArray(object.nameservers))
                                    throw TypeError(".k8s.io.api.core.v1.PodDNSConfig.nameservers: array expected");
                                message.nameservers = [];
                                for (var i = 0; i < object.nameservers.length; ++i)
                                    message.nameservers[i] = String(object.nameservers[i]);
                            }
                            if (object.searches) {
                                if (!Array.isArray(object.searches))
                                    throw TypeError(".k8s.io.api.core.v1.PodDNSConfig.searches: array expected");
                                message.searches = [];
                                for (var i = 0; i < object.searches.length; ++i)
                                    message.searches[i] = String(object.searches[i]);
                            }
                            if (object.options) {
                                if (!Array.isArray(object.options))
                                    throw TypeError(".k8s.io.api.core.v1.PodDNSConfig.options: array expected");
                                message.options = [];
                                for (var i = 0; i < object.options.length; ++i) {
                                    if (typeof object.options[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodDNSConfig.options: object expected");
                                    message.options[i] = $root.k8s.io.api.core.v1.PodDNSConfigOption.fromObject(object.options[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodDNSConfig message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @static
                         * @param {k8s.io.api.core.v1.PodDNSConfig} message PodDNSConfig
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodDNSConfig.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.nameservers = [];
                                object.searches = [];
                                object.options = [];
                            }
                            if (message.nameservers && message.nameservers.length) {
                                object.nameservers = [];
                                for (var j = 0; j < message.nameservers.length; ++j)
                                    object.nameservers[j] = message.nameservers[j];
                            }
                            if (message.searches && message.searches.length) {
                                object.searches = [];
                                for (var j = 0; j < message.searches.length; ++j)
                                    object.searches[j] = message.searches[j];
                            }
                            if (message.options && message.options.length) {
                                object.options = [];
                                for (var j = 0; j < message.options.length; ++j)
                                    object.options[j] = $root.k8s.io.api.core.v1.PodDNSConfigOption.toObject(message.options[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PodDNSConfig to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodDNSConfig
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodDNSConfig.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodDNSConfig;
                    })();

                    v1.PodDNSConfigOption = (function() {

                        /**
                         * Properties of a PodDNSConfigOption.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodDNSConfigOption
                         * @property {string|null} [name] PodDNSConfigOption name
                         * @property {string|null} [value] PodDNSConfigOption value
                         */

                        /**
                         * Constructs a new PodDNSConfigOption.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodDNSConfigOption.
                         * @implements IPodDNSConfigOption
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodDNSConfigOption=} [properties] Properties to set
                         */
                        function PodDNSConfigOption(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodDNSConfigOption name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @instance
                         */
                        PodDNSConfigOption.prototype.name = "";

                        /**
                         * PodDNSConfigOption value.
                         * @member {string} value
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @instance
                         */
                        PodDNSConfigOption.prototype.value = "";

                        /**
                         * Creates a new PodDNSConfigOption instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {k8s.io.api.core.v1.IPodDNSConfigOption=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodDNSConfigOption} PodDNSConfigOption instance
                         */
                        PodDNSConfigOption.create = function create(properties) {
                            return new PodDNSConfigOption(properties);
                        };

                        /**
                         * Encodes the specified PodDNSConfigOption message. Does not implicitly {@link k8s.io.api.core.v1.PodDNSConfigOption.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {k8s.io.api.core.v1.IPodDNSConfigOption} message PodDNSConfigOption message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodDNSConfigOption.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodDNSConfigOption message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodDNSConfigOption.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {k8s.io.api.core.v1.IPodDNSConfigOption} message PodDNSConfigOption message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodDNSConfigOption.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodDNSConfigOption message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodDNSConfigOption} PodDNSConfigOption
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodDNSConfigOption.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodDNSConfigOption();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodDNSConfigOption message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodDNSConfigOption} PodDNSConfigOption
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodDNSConfigOption.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodDNSConfigOption message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodDNSConfigOption.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isString(message.value))
                                    return "value: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodDNSConfigOption message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodDNSConfigOption} PodDNSConfigOption
                         */
                        PodDNSConfigOption.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodDNSConfigOption)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodDNSConfigOption();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.value != null)
                                message.value = String(object.value);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodDNSConfigOption message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @static
                         * @param {k8s.io.api.core.v1.PodDNSConfigOption} message PodDNSConfigOption
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodDNSConfigOption.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.value = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            return object;
                        };

                        /**
                         * Converts this PodDNSConfigOption to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodDNSConfigOption
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodDNSConfigOption.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodDNSConfigOption;
                    })();

                    v1.PodExecOptions = (function() {

                        /**
                         * Properties of a PodExecOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodExecOptions
                         * @property {boolean|null} [stdin] PodExecOptions stdin
                         * @property {boolean|null} [stdout] PodExecOptions stdout
                         * @property {boolean|null} [stderr] PodExecOptions stderr
                         * @property {boolean|null} [tty] PodExecOptions tty
                         * @property {string|null} [container] PodExecOptions container
                         * @property {Array.<string>|null} [command] PodExecOptions command
                         */

                        /**
                         * Constructs a new PodExecOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodExecOptions.
                         * @implements IPodExecOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodExecOptions=} [properties] Properties to set
                         */
                        function PodExecOptions(properties) {
                            this.command = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodExecOptions stdin.
                         * @member {boolean} stdin
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         */
                        PodExecOptions.prototype.stdin = false;

                        /**
                         * PodExecOptions stdout.
                         * @member {boolean} stdout
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         */
                        PodExecOptions.prototype.stdout = false;

                        /**
                         * PodExecOptions stderr.
                         * @member {boolean} stderr
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         */
                        PodExecOptions.prototype.stderr = false;

                        /**
                         * PodExecOptions tty.
                         * @member {boolean} tty
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         */
                        PodExecOptions.prototype.tty = false;

                        /**
                         * PodExecOptions container.
                         * @member {string} container
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         */
                        PodExecOptions.prototype.container = "";

                        /**
                         * PodExecOptions command.
                         * @member {Array.<string>} command
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         */
                        PodExecOptions.prototype.command = $util.emptyArray;

                        /**
                         * Creates a new PodExecOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodExecOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodExecOptions} PodExecOptions instance
                         */
                        PodExecOptions.create = function create(properties) {
                            return new PodExecOptions(properties);
                        };

                        /**
                         * Encodes the specified PodExecOptions message. Does not implicitly {@link k8s.io.api.core.v1.PodExecOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodExecOptions} message PodExecOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodExecOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.stdin != null && Object.hasOwnProperty.call(message, "stdin"))
                                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.stdin);
                            if (message.stdout != null && Object.hasOwnProperty.call(message, "stdout"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.stdout);
                            if (message.stderr != null && Object.hasOwnProperty.call(message, "stderr"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.stderr);
                            if (message.tty != null && Object.hasOwnProperty.call(message, "tty"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.tty);
                            if (message.container != null && Object.hasOwnProperty.call(message, "container"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.container);
                            if (message.command != null && message.command.length)
                                for (var i = 0; i < message.command.length; ++i)
                                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.command[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodExecOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodExecOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodExecOptions} message PodExecOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodExecOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodExecOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodExecOptions} PodExecOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodExecOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodExecOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.stdin = reader.bool();
                                    break;
                                case 2:
                                    message.stdout = reader.bool();
                                    break;
                                case 3:
                                    message.stderr = reader.bool();
                                    break;
                                case 4:
                                    message.tty = reader.bool();
                                    break;
                                case 5:
                                    message.container = reader.string();
                                    break;
                                case 6:
                                    if (!(message.command && message.command.length))
                                        message.command = [];
                                    message.command.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodExecOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodExecOptions} PodExecOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodExecOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodExecOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodExecOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                if (typeof message.stdin !== "boolean")
                                    return "stdin: boolean expected";
                            if (message.stdout != null && message.hasOwnProperty("stdout"))
                                if (typeof message.stdout !== "boolean")
                                    return "stdout: boolean expected";
                            if (message.stderr != null && message.hasOwnProperty("stderr"))
                                if (typeof message.stderr !== "boolean")
                                    return "stderr: boolean expected";
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                if (typeof message.tty !== "boolean")
                                    return "tty: boolean expected";
                            if (message.container != null && message.hasOwnProperty("container"))
                                if (!$util.isString(message.container))
                                    return "container: string expected";
                            if (message.command != null && message.hasOwnProperty("command")) {
                                if (!Array.isArray(message.command))
                                    return "command: array expected";
                                for (var i = 0; i < message.command.length; ++i)
                                    if (!$util.isString(message.command[i]))
                                        return "command: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a PodExecOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodExecOptions} PodExecOptions
                         */
                        PodExecOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodExecOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodExecOptions();
                            if (object.stdin != null)
                                message.stdin = Boolean(object.stdin);
                            if (object.stdout != null)
                                message.stdout = Boolean(object.stdout);
                            if (object.stderr != null)
                                message.stderr = Boolean(object.stderr);
                            if (object.tty != null)
                                message.tty = Boolean(object.tty);
                            if (object.container != null)
                                message.container = String(object.container);
                            if (object.command) {
                                if (!Array.isArray(object.command))
                                    throw TypeError(".k8s.io.api.core.v1.PodExecOptions.command: array expected");
                                message.command = [];
                                for (var i = 0; i < object.command.length; ++i)
                                    message.command[i] = String(object.command[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodExecOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @static
                         * @param {k8s.io.api.core.v1.PodExecOptions} message PodExecOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodExecOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.command = [];
                            if (options.defaults) {
                                object.stdin = false;
                                object.stdout = false;
                                object.stderr = false;
                                object.tty = false;
                                object.container = "";
                            }
                            if (message.stdin != null && message.hasOwnProperty("stdin"))
                                object.stdin = message.stdin;
                            if (message.stdout != null && message.hasOwnProperty("stdout"))
                                object.stdout = message.stdout;
                            if (message.stderr != null && message.hasOwnProperty("stderr"))
                                object.stderr = message.stderr;
                            if (message.tty != null && message.hasOwnProperty("tty"))
                                object.tty = message.tty;
                            if (message.container != null && message.hasOwnProperty("container"))
                                object.container = message.container;
                            if (message.command && message.command.length) {
                                object.command = [];
                                for (var j = 0; j < message.command.length; ++j)
                                    object.command[j] = message.command[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this PodExecOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodExecOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodExecOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodExecOptions;
                    })();

                    v1.PodIP = (function() {

                        /**
                         * Properties of a PodIP.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodIP
                         * @property {string|null} [ip] PodIP ip
                         */

                        /**
                         * Constructs a new PodIP.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodIP.
                         * @implements IPodIP
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodIP=} [properties] Properties to set
                         */
                        function PodIP(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodIP ip.
                         * @member {string} ip
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @instance
                         */
                        PodIP.prototype.ip = "";

                        /**
                         * Creates a new PodIP instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {k8s.io.api.core.v1.IPodIP=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodIP} PodIP instance
                         */
                        PodIP.create = function create(properties) {
                            return new PodIP(properties);
                        };

                        /**
                         * Encodes the specified PodIP message. Does not implicitly {@link k8s.io.api.core.v1.PodIP.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {k8s.io.api.core.v1.IPodIP} message PodIP message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodIP.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ip);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodIP message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodIP.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {k8s.io.api.core.v1.IPodIP} message PodIP message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodIP.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodIP message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodIP} PodIP
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodIP.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodIP();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.ip = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodIP message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodIP} PodIP
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodIP.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodIP message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodIP.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                if (!$util.isString(message.ip))
                                    return "ip: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodIP message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodIP} PodIP
                         */
                        PodIP.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodIP)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodIP();
                            if (object.ip != null)
                                message.ip = String(object.ip);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodIP message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @static
                         * @param {k8s.io.api.core.v1.PodIP} message PodIP
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodIP.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.ip = "";
                            if (message.ip != null && message.hasOwnProperty("ip"))
                                object.ip = message.ip;
                            return object;
                        };

                        /**
                         * Converts this PodIP to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodIP
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodIP.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodIP;
                    })();

                    v1.PodList = (function() {

                        /**
                         * Properties of a PodList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] PodList metadata
                         * @property {Array.<k8s.io.api.core.v1.IPod>|null} [items] PodList items
                         */

                        /**
                         * Constructs a new PodList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodList.
                         * @implements IPodList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodList=} [properties] Properties to set
                         */
                        function PodList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PodList
                         * @instance
                         */
                        PodList.prototype.metadata = null;

                        /**
                         * PodList items.
                         * @member {Array.<k8s.io.api.core.v1.IPod>} items
                         * @memberof k8s.io.api.core.v1.PodList
                         * @instance
                         */
                        PodList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new PodList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {k8s.io.api.core.v1.IPodList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodList} PodList instance
                         */
                        PodList.create = function create(properties) {
                            return new PodList(properties);
                        };

                        /**
                         * Encodes the specified PodList message. Does not implicitly {@link k8s.io.api.core.v1.PodList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {k8s.io.api.core.v1.IPodList} message PodList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Pod.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {k8s.io.api.core.v1.IPodList} message PodList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodList} PodList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Pod.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodList} PodList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Pod.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PodList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodList} PodList
                         */
                        PodList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.PodList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Pod.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodList
                         * @static
                         * @param {k8s.io.api.core.v1.PodList} message PodList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Pod.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PodList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodList;
                    })();

                    v1.PodLogOptions = (function() {

                        /**
                         * Properties of a PodLogOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodLogOptions
                         * @property {string|null} [container] PodLogOptions container
                         * @property {boolean|null} [follow] PodLogOptions follow
                         * @property {boolean|null} [previous] PodLogOptions previous
                         * @property {number|Long|null} [sinceSeconds] PodLogOptions sinceSeconds
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [sinceTime] PodLogOptions sinceTime
                         * @property {boolean|null} [timestamps] PodLogOptions timestamps
                         * @property {number|Long|null} [tailLines] PodLogOptions tailLines
                         * @property {number|Long|null} [limitBytes] PodLogOptions limitBytes
                         * @property {boolean|null} [insecureSkipTLSVerifyBackend] PodLogOptions insecureSkipTLSVerifyBackend
                         */

                        /**
                         * Constructs a new PodLogOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodLogOptions.
                         * @implements IPodLogOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodLogOptions=} [properties] Properties to set
                         */
                        function PodLogOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodLogOptions container.
                         * @member {string} container
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.container = "";

                        /**
                         * PodLogOptions follow.
                         * @member {boolean} follow
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.follow = false;

                        /**
                         * PodLogOptions previous.
                         * @member {boolean} previous
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.previous = false;

                        /**
                         * PodLogOptions sinceSeconds.
                         * @member {number|Long} sinceSeconds
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.sinceSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodLogOptions sinceTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} sinceTime
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.sinceTime = null;

                        /**
                         * PodLogOptions timestamps.
                         * @member {boolean} timestamps
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.timestamps = false;

                        /**
                         * PodLogOptions tailLines.
                         * @member {number|Long} tailLines
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.tailLines = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodLogOptions limitBytes.
                         * @member {number|Long} limitBytes
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.limitBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodLogOptions insecureSkipTLSVerifyBackend.
                         * @member {boolean} insecureSkipTLSVerifyBackend
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         */
                        PodLogOptions.prototype.insecureSkipTLSVerifyBackend = false;

                        /**
                         * Creates a new PodLogOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodLogOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodLogOptions} PodLogOptions instance
                         */
                        PodLogOptions.create = function create(properties) {
                            return new PodLogOptions(properties);
                        };

                        /**
                         * Encodes the specified PodLogOptions message. Does not implicitly {@link k8s.io.api.core.v1.PodLogOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodLogOptions} message PodLogOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodLogOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.container != null && Object.hasOwnProperty.call(message, "container"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.container);
                            if (message.follow != null && Object.hasOwnProperty.call(message, "follow"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.follow);
                            if (message.previous != null && Object.hasOwnProperty.call(message, "previous"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.previous);
                            if (message.sinceSeconds != null && Object.hasOwnProperty.call(message, "sinceSeconds"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.sinceSeconds);
                            if (message.sinceTime != null && Object.hasOwnProperty.call(message, "sinceTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.sinceTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.timestamps != null && Object.hasOwnProperty.call(message, "timestamps"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.timestamps);
                            if (message.tailLines != null && Object.hasOwnProperty.call(message, "tailLines"))
                                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.tailLines);
                            if (message.limitBytes != null && Object.hasOwnProperty.call(message, "limitBytes"))
                                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.limitBytes);
                            if (message.insecureSkipTLSVerifyBackend != null && Object.hasOwnProperty.call(message, "insecureSkipTLSVerifyBackend"))
                                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.insecureSkipTLSVerifyBackend);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodLogOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodLogOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodLogOptions} message PodLogOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodLogOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodLogOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodLogOptions} PodLogOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodLogOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodLogOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.container = reader.string();
                                    break;
                                case 2:
                                    message.follow = reader.bool();
                                    break;
                                case 3:
                                    message.previous = reader.bool();
                                    break;
                                case 4:
                                    message.sinceSeconds = reader.int64();
                                    break;
                                case 5:
                                    message.sinceTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.timestamps = reader.bool();
                                    break;
                                case 7:
                                    message.tailLines = reader.int64();
                                    break;
                                case 8:
                                    message.limitBytes = reader.int64();
                                    break;
                                case 9:
                                    message.insecureSkipTLSVerifyBackend = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodLogOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodLogOptions} PodLogOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodLogOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodLogOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodLogOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.container != null && message.hasOwnProperty("container"))
                                if (!$util.isString(message.container))
                                    return "container: string expected";
                            if (message.follow != null && message.hasOwnProperty("follow"))
                                if (typeof message.follow !== "boolean")
                                    return "follow: boolean expected";
                            if (message.previous != null && message.hasOwnProperty("previous"))
                                if (typeof message.previous !== "boolean")
                                    return "previous: boolean expected";
                            if (message.sinceSeconds != null && message.hasOwnProperty("sinceSeconds"))
                                if (!$util.isInteger(message.sinceSeconds) && !(message.sinceSeconds && $util.isInteger(message.sinceSeconds.low) && $util.isInteger(message.sinceSeconds.high)))
                                    return "sinceSeconds: integer|Long expected";
                            if (message.sinceTime != null && message.hasOwnProperty("sinceTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.sinceTime);
                                if (error)
                                    return "sinceTime." + error;
                            }
                            if (message.timestamps != null && message.hasOwnProperty("timestamps"))
                                if (typeof message.timestamps !== "boolean")
                                    return "timestamps: boolean expected";
                            if (message.tailLines != null && message.hasOwnProperty("tailLines"))
                                if (!$util.isInteger(message.tailLines) && !(message.tailLines && $util.isInteger(message.tailLines.low) && $util.isInteger(message.tailLines.high)))
                                    return "tailLines: integer|Long expected";
                            if (message.limitBytes != null && message.hasOwnProperty("limitBytes"))
                                if (!$util.isInteger(message.limitBytes) && !(message.limitBytes && $util.isInteger(message.limitBytes.low) && $util.isInteger(message.limitBytes.high)))
                                    return "limitBytes: integer|Long expected";
                            if (message.insecureSkipTLSVerifyBackend != null && message.hasOwnProperty("insecureSkipTLSVerifyBackend"))
                                if (typeof message.insecureSkipTLSVerifyBackend !== "boolean")
                                    return "insecureSkipTLSVerifyBackend: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a PodLogOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodLogOptions} PodLogOptions
                         */
                        PodLogOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodLogOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodLogOptions();
                            if (object.container != null)
                                message.container = String(object.container);
                            if (object.follow != null)
                                message.follow = Boolean(object.follow);
                            if (object.previous != null)
                                message.previous = Boolean(object.previous);
                            if (object.sinceSeconds != null)
                                if ($util.Long)
                                    (message.sinceSeconds = $util.Long.fromValue(object.sinceSeconds)).unsigned = false;
                                else if (typeof object.sinceSeconds === "string")
                                    message.sinceSeconds = parseInt(object.sinceSeconds, 10);
                                else if (typeof object.sinceSeconds === "number")
                                    message.sinceSeconds = object.sinceSeconds;
                                else if (typeof object.sinceSeconds === "object")
                                    message.sinceSeconds = new $util.LongBits(object.sinceSeconds.low >>> 0, object.sinceSeconds.high >>> 0).toNumber();
                            if (object.sinceTime != null) {
                                if (typeof object.sinceTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodLogOptions.sinceTime: object expected");
                                message.sinceTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.sinceTime);
                            }
                            if (object.timestamps != null)
                                message.timestamps = Boolean(object.timestamps);
                            if (object.tailLines != null)
                                if ($util.Long)
                                    (message.tailLines = $util.Long.fromValue(object.tailLines)).unsigned = false;
                                else if (typeof object.tailLines === "string")
                                    message.tailLines = parseInt(object.tailLines, 10);
                                else if (typeof object.tailLines === "number")
                                    message.tailLines = object.tailLines;
                                else if (typeof object.tailLines === "object")
                                    message.tailLines = new $util.LongBits(object.tailLines.low >>> 0, object.tailLines.high >>> 0).toNumber();
                            if (object.limitBytes != null)
                                if ($util.Long)
                                    (message.limitBytes = $util.Long.fromValue(object.limitBytes)).unsigned = false;
                                else if (typeof object.limitBytes === "string")
                                    message.limitBytes = parseInt(object.limitBytes, 10);
                                else if (typeof object.limitBytes === "number")
                                    message.limitBytes = object.limitBytes;
                                else if (typeof object.limitBytes === "object")
                                    message.limitBytes = new $util.LongBits(object.limitBytes.low >>> 0, object.limitBytes.high >>> 0).toNumber();
                            if (object.insecureSkipTLSVerifyBackend != null)
                                message.insecureSkipTLSVerifyBackend = Boolean(object.insecureSkipTLSVerifyBackend);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodLogOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @static
                         * @param {k8s.io.api.core.v1.PodLogOptions} message PodLogOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodLogOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.container = "";
                                object.follow = false;
                                object.previous = false;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.sinceSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.sinceSeconds = options.longs === String ? "0" : 0;
                                object.sinceTime = null;
                                object.timestamps = false;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.tailLines = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.tailLines = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.limitBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.limitBytes = options.longs === String ? "0" : 0;
                                object.insecureSkipTLSVerifyBackend = false;
                            }
                            if (message.container != null && message.hasOwnProperty("container"))
                                object.container = message.container;
                            if (message.follow != null && message.hasOwnProperty("follow"))
                                object.follow = message.follow;
                            if (message.previous != null && message.hasOwnProperty("previous"))
                                object.previous = message.previous;
                            if (message.sinceSeconds != null && message.hasOwnProperty("sinceSeconds"))
                                if (typeof message.sinceSeconds === "number")
                                    object.sinceSeconds = options.longs === String ? String(message.sinceSeconds) : message.sinceSeconds;
                                else
                                    object.sinceSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.sinceSeconds) : options.longs === Number ? new $util.LongBits(message.sinceSeconds.low >>> 0, message.sinceSeconds.high >>> 0).toNumber() : message.sinceSeconds;
                            if (message.sinceTime != null && message.hasOwnProperty("sinceTime"))
                                object.sinceTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.sinceTime, options);
                            if (message.timestamps != null && message.hasOwnProperty("timestamps"))
                                object.timestamps = message.timestamps;
                            if (message.tailLines != null && message.hasOwnProperty("tailLines"))
                                if (typeof message.tailLines === "number")
                                    object.tailLines = options.longs === String ? String(message.tailLines) : message.tailLines;
                                else
                                    object.tailLines = options.longs === String ? $util.Long.prototype.toString.call(message.tailLines) : options.longs === Number ? new $util.LongBits(message.tailLines.low >>> 0, message.tailLines.high >>> 0).toNumber() : message.tailLines;
                            if (message.limitBytes != null && message.hasOwnProperty("limitBytes"))
                                if (typeof message.limitBytes === "number")
                                    object.limitBytes = options.longs === String ? String(message.limitBytes) : message.limitBytes;
                                else
                                    object.limitBytes = options.longs === String ? $util.Long.prototype.toString.call(message.limitBytes) : options.longs === Number ? new $util.LongBits(message.limitBytes.low >>> 0, message.limitBytes.high >>> 0).toNumber() : message.limitBytes;
                            if (message.insecureSkipTLSVerifyBackend != null && message.hasOwnProperty("insecureSkipTLSVerifyBackend"))
                                object.insecureSkipTLSVerifyBackend = message.insecureSkipTLSVerifyBackend;
                            return object;
                        };

                        /**
                         * Converts this PodLogOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodLogOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodLogOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodLogOptions;
                    })();

                    v1.PodPortForwardOptions = (function() {

                        /**
                         * Properties of a PodPortForwardOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodPortForwardOptions
                         * @property {Array.<number>|null} [ports] PodPortForwardOptions ports
                         */

                        /**
                         * Constructs a new PodPortForwardOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodPortForwardOptions.
                         * @implements IPodPortForwardOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodPortForwardOptions=} [properties] Properties to set
                         */
                        function PodPortForwardOptions(properties) {
                            this.ports = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodPortForwardOptions ports.
                         * @member {Array.<number>} ports
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @instance
                         */
                        PodPortForwardOptions.prototype.ports = $util.emptyArray;

                        /**
                         * Creates a new PodPortForwardOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodPortForwardOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodPortForwardOptions} PodPortForwardOptions instance
                         */
                        PodPortForwardOptions.create = function create(properties) {
                            return new PodPortForwardOptions(properties);
                        };

                        /**
                         * Encodes the specified PodPortForwardOptions message. Does not implicitly {@link k8s.io.api.core.v1.PodPortForwardOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodPortForwardOptions} message PodPortForwardOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodPortForwardOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ports != null && message.ports.length)
                                for (var i = 0; i < message.ports.length; ++i)
                                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ports[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodPortForwardOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodPortForwardOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodPortForwardOptions} message PodPortForwardOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodPortForwardOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodPortForwardOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodPortForwardOptions} PodPortForwardOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodPortForwardOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodPortForwardOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.ports && message.ports.length))
                                        message.ports = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.ports.push(reader.int32());
                                    } else
                                        message.ports.push(reader.int32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodPortForwardOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodPortForwardOptions} PodPortForwardOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodPortForwardOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodPortForwardOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodPortForwardOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ports != null && message.hasOwnProperty("ports")) {
                                if (!Array.isArray(message.ports))
                                    return "ports: array expected";
                                for (var i = 0; i < message.ports.length; ++i)
                                    if (!$util.isInteger(message.ports[i]))
                                        return "ports: integer[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a PodPortForwardOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodPortForwardOptions} PodPortForwardOptions
                         */
                        PodPortForwardOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodPortForwardOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodPortForwardOptions();
                            if (object.ports) {
                                if (!Array.isArray(object.ports))
                                    throw TypeError(".k8s.io.api.core.v1.PodPortForwardOptions.ports: array expected");
                                message.ports = [];
                                for (var i = 0; i < object.ports.length; ++i)
                                    message.ports[i] = object.ports[i] | 0;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodPortForwardOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @static
                         * @param {k8s.io.api.core.v1.PodPortForwardOptions} message PodPortForwardOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodPortForwardOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.ports = [];
                            if (message.ports && message.ports.length) {
                                object.ports = [];
                                for (var j = 0; j < message.ports.length; ++j)
                                    object.ports[j] = message.ports[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this PodPortForwardOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodPortForwardOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodPortForwardOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodPortForwardOptions;
                    })();

                    v1.PodProxyOptions = (function() {

                        /**
                         * Properties of a PodProxyOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodProxyOptions
                         * @property {string|null} [path] PodProxyOptions path
                         */

                        /**
                         * Constructs a new PodProxyOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodProxyOptions.
                         * @implements IPodProxyOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodProxyOptions=} [properties] Properties to set
                         */
                        function PodProxyOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodProxyOptions path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @instance
                         */
                        PodProxyOptions.prototype.path = "";

                        /**
                         * Creates a new PodProxyOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodProxyOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodProxyOptions} PodProxyOptions instance
                         */
                        PodProxyOptions.create = function create(properties) {
                            return new PodProxyOptions(properties);
                        };

                        /**
                         * Encodes the specified PodProxyOptions message. Does not implicitly {@link k8s.io.api.core.v1.PodProxyOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodProxyOptions} message PodProxyOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodProxyOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodProxyOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodProxyOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IPodProxyOptions} message PodProxyOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodProxyOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodProxyOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodProxyOptions} PodProxyOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodProxyOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodProxyOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodProxyOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodProxyOptions} PodProxyOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodProxyOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodProxyOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodProxyOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodProxyOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodProxyOptions} PodProxyOptions
                         */
                        PodProxyOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodProxyOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodProxyOptions();
                            if (object.path != null)
                                message.path = String(object.path);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodProxyOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.PodProxyOptions} message PodProxyOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodProxyOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.path = "";
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            return object;
                        };

                        /**
                         * Converts this PodProxyOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodProxyOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodProxyOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodProxyOptions;
                    })();

                    v1.PodReadinessGate = (function() {

                        /**
                         * Properties of a PodReadinessGate.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodReadinessGate
                         * @property {string|null} [conditionType] PodReadinessGate conditionType
                         */

                        /**
                         * Constructs a new PodReadinessGate.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodReadinessGate.
                         * @implements IPodReadinessGate
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodReadinessGate=} [properties] Properties to set
                         */
                        function PodReadinessGate(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodReadinessGate conditionType.
                         * @member {string} conditionType
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @instance
                         */
                        PodReadinessGate.prototype.conditionType = "";

                        /**
                         * Creates a new PodReadinessGate instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {k8s.io.api.core.v1.IPodReadinessGate=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodReadinessGate} PodReadinessGate instance
                         */
                        PodReadinessGate.create = function create(properties) {
                            return new PodReadinessGate(properties);
                        };

                        /**
                         * Encodes the specified PodReadinessGate message. Does not implicitly {@link k8s.io.api.core.v1.PodReadinessGate.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {k8s.io.api.core.v1.IPodReadinessGate} message PodReadinessGate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodReadinessGate.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.conditionType != null && Object.hasOwnProperty.call(message, "conditionType"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.conditionType);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodReadinessGate message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodReadinessGate.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {k8s.io.api.core.v1.IPodReadinessGate} message PodReadinessGate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodReadinessGate.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodReadinessGate message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodReadinessGate} PodReadinessGate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodReadinessGate.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodReadinessGate();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.conditionType = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodReadinessGate message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodReadinessGate} PodReadinessGate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodReadinessGate.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodReadinessGate message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodReadinessGate.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.conditionType != null && message.hasOwnProperty("conditionType"))
                                if (!$util.isString(message.conditionType))
                                    return "conditionType: string expected";
                            return null;
                        };

                        /**
                         * Creates a PodReadinessGate message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodReadinessGate} PodReadinessGate
                         */
                        PodReadinessGate.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodReadinessGate)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodReadinessGate();
                            if (object.conditionType != null)
                                message.conditionType = String(object.conditionType);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodReadinessGate message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @static
                         * @param {k8s.io.api.core.v1.PodReadinessGate} message PodReadinessGate
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodReadinessGate.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.conditionType = "";
                            if (message.conditionType != null && message.hasOwnProperty("conditionType"))
                                object.conditionType = message.conditionType;
                            return object;
                        };

                        /**
                         * Converts this PodReadinessGate to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodReadinessGate
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodReadinessGate.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodReadinessGate;
                    })();

                    v1.PodSecurityContext = (function() {

                        /**
                         * Properties of a PodSecurityContext.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodSecurityContext
                         * @property {k8s.io.api.core.v1.ISELinuxOptions|null} [seLinuxOptions] PodSecurityContext seLinuxOptions
                         * @property {k8s.io.api.core.v1.IWindowsSecurityContextOptions|null} [windowsOptions] PodSecurityContext windowsOptions
                         * @property {number|Long|null} [runAsUser] PodSecurityContext runAsUser
                         * @property {number|Long|null} [runAsGroup] PodSecurityContext runAsGroup
                         * @property {boolean|null} [runAsNonRoot] PodSecurityContext runAsNonRoot
                         * @property {Array.<number|Long>|null} [supplementalGroups] PodSecurityContext supplementalGroups
                         * @property {number|Long|null} [fsGroup] PodSecurityContext fsGroup
                         * @property {Array.<k8s.io.api.core.v1.ISysctl>|null} [sysctls] PodSecurityContext sysctls
                         * @property {string|null} [fsGroupChangePolicy] PodSecurityContext fsGroupChangePolicy
                         * @property {k8s.io.api.core.v1.ISeccompProfile|null} [seccompProfile] PodSecurityContext seccompProfile
                         */

                        /**
                         * Constructs a new PodSecurityContext.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodSecurityContext.
                         * @implements IPodSecurityContext
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodSecurityContext=} [properties] Properties to set
                         */
                        function PodSecurityContext(properties) {
                            this.supplementalGroups = [];
                            this.sysctls = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodSecurityContext seLinuxOptions.
                         * @member {k8s.io.api.core.v1.ISELinuxOptions|null|undefined} seLinuxOptions
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.seLinuxOptions = null;

                        /**
                         * PodSecurityContext windowsOptions.
                         * @member {k8s.io.api.core.v1.IWindowsSecurityContextOptions|null|undefined} windowsOptions
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.windowsOptions = null;

                        /**
                         * PodSecurityContext runAsUser.
                         * @member {number|Long} runAsUser
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.runAsUser = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodSecurityContext runAsGroup.
                         * @member {number|Long} runAsGroup
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.runAsGroup = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodSecurityContext runAsNonRoot.
                         * @member {boolean} runAsNonRoot
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.runAsNonRoot = false;

                        /**
                         * PodSecurityContext supplementalGroups.
                         * @member {Array.<number|Long>} supplementalGroups
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.supplementalGroups = $util.emptyArray;

                        /**
                         * PodSecurityContext fsGroup.
                         * @member {number|Long} fsGroup
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.fsGroup = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodSecurityContext sysctls.
                         * @member {Array.<k8s.io.api.core.v1.ISysctl>} sysctls
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.sysctls = $util.emptyArray;

                        /**
                         * PodSecurityContext fsGroupChangePolicy.
                         * @member {string} fsGroupChangePolicy
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.fsGroupChangePolicy = "";

                        /**
                         * PodSecurityContext seccompProfile.
                         * @member {k8s.io.api.core.v1.ISeccompProfile|null|undefined} seccompProfile
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         */
                        PodSecurityContext.prototype.seccompProfile = null;

                        /**
                         * Creates a new PodSecurityContext instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSecurityContext=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodSecurityContext} PodSecurityContext instance
                         */
                        PodSecurityContext.create = function create(properties) {
                            return new PodSecurityContext(properties);
                        };

                        /**
                         * Encodes the specified PodSecurityContext message. Does not implicitly {@link k8s.io.api.core.v1.PodSecurityContext.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSecurityContext} message PodSecurityContext message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodSecurityContext.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.seLinuxOptions != null && Object.hasOwnProperty.call(message, "seLinuxOptions"))
                                $root.k8s.io.api.core.v1.SELinuxOptions.encode(message.seLinuxOptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.runAsUser != null && Object.hasOwnProperty.call(message, "runAsUser"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.runAsUser);
                            if (message.runAsNonRoot != null && Object.hasOwnProperty.call(message, "runAsNonRoot"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.runAsNonRoot);
                            if (message.supplementalGroups != null && message.supplementalGroups.length)
                                for (var i = 0; i < message.supplementalGroups.length; ++i)
                                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.supplementalGroups[i]);
                            if (message.fsGroup != null && Object.hasOwnProperty.call(message, "fsGroup"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.fsGroup);
                            if (message.runAsGroup != null && Object.hasOwnProperty.call(message, "runAsGroup"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.runAsGroup);
                            if (message.sysctls != null && message.sysctls.length)
                                for (var i = 0; i < message.sysctls.length; ++i)
                                    $root.k8s.io.api.core.v1.Sysctl.encode(message.sysctls[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.windowsOptions != null && Object.hasOwnProperty.call(message, "windowsOptions"))
                                $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.encode(message.windowsOptions, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.fsGroupChangePolicy != null && Object.hasOwnProperty.call(message, "fsGroupChangePolicy"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.fsGroupChangePolicy);
                            if (message.seccompProfile != null && Object.hasOwnProperty.call(message, "seccompProfile"))
                                $root.k8s.io.api.core.v1.SeccompProfile.encode(message.seccompProfile, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodSecurityContext message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodSecurityContext.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSecurityContext} message PodSecurityContext message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodSecurityContext.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodSecurityContext message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodSecurityContext} PodSecurityContext
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodSecurityContext.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodSecurityContext();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.seLinuxOptions = $root.k8s.io.api.core.v1.SELinuxOptions.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.windowsOptions = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.runAsUser = reader.int64();
                                    break;
                                case 6:
                                    message.runAsGroup = reader.int64();
                                    break;
                                case 3:
                                    message.runAsNonRoot = reader.bool();
                                    break;
                                case 4:
                                    if (!(message.supplementalGroups && message.supplementalGroups.length))
                                        message.supplementalGroups = [];
                                    if ((tag & 7) === 2) {
                                        var end2 = reader.uint32() + reader.pos;
                                        while (reader.pos < end2)
                                            message.supplementalGroups.push(reader.int64());
                                    } else
                                        message.supplementalGroups.push(reader.int64());
                                    break;
                                case 5:
                                    message.fsGroup = reader.int64();
                                    break;
                                case 7:
                                    if (!(message.sysctls && message.sysctls.length))
                                        message.sysctls = [];
                                    message.sysctls.push($root.k8s.io.api.core.v1.Sysctl.decode(reader, reader.uint32()));
                                    break;
                                case 9:
                                    message.fsGroupChangePolicy = reader.string();
                                    break;
                                case 10:
                                    message.seccompProfile = $root.k8s.io.api.core.v1.SeccompProfile.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodSecurityContext message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodSecurityContext} PodSecurityContext
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodSecurityContext.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodSecurityContext message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodSecurityContext.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.seLinuxOptions != null && message.hasOwnProperty("seLinuxOptions")) {
                                var error = $root.k8s.io.api.core.v1.SELinuxOptions.verify(message.seLinuxOptions);
                                if (error)
                                    return "seLinuxOptions." + error;
                            }
                            if (message.windowsOptions != null && message.hasOwnProperty("windowsOptions")) {
                                var error = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.verify(message.windowsOptions);
                                if (error)
                                    return "windowsOptions." + error;
                            }
                            if (message.runAsUser != null && message.hasOwnProperty("runAsUser"))
                                if (!$util.isInteger(message.runAsUser) && !(message.runAsUser && $util.isInteger(message.runAsUser.low) && $util.isInteger(message.runAsUser.high)))
                                    return "runAsUser: integer|Long expected";
                            if (message.runAsGroup != null && message.hasOwnProperty("runAsGroup"))
                                if (!$util.isInteger(message.runAsGroup) && !(message.runAsGroup && $util.isInteger(message.runAsGroup.low) && $util.isInteger(message.runAsGroup.high)))
                                    return "runAsGroup: integer|Long expected";
                            if (message.runAsNonRoot != null && message.hasOwnProperty("runAsNonRoot"))
                                if (typeof message.runAsNonRoot !== "boolean")
                                    return "runAsNonRoot: boolean expected";
                            if (message.supplementalGroups != null && message.hasOwnProperty("supplementalGroups")) {
                                if (!Array.isArray(message.supplementalGroups))
                                    return "supplementalGroups: array expected";
                                for (var i = 0; i < message.supplementalGroups.length; ++i)
                                    if (!$util.isInteger(message.supplementalGroups[i]) && !(message.supplementalGroups[i] && $util.isInteger(message.supplementalGroups[i].low) && $util.isInteger(message.supplementalGroups[i].high)))
                                        return "supplementalGroups: integer|Long[] expected";
                            }
                            if (message.fsGroup != null && message.hasOwnProperty("fsGroup"))
                                if (!$util.isInteger(message.fsGroup) && !(message.fsGroup && $util.isInteger(message.fsGroup.low) && $util.isInteger(message.fsGroup.high)))
                                    return "fsGroup: integer|Long expected";
                            if (message.sysctls != null && message.hasOwnProperty("sysctls")) {
                                if (!Array.isArray(message.sysctls))
                                    return "sysctls: array expected";
                                for (var i = 0; i < message.sysctls.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Sysctl.verify(message.sysctls[i]);
                                    if (error)
                                        return "sysctls." + error;
                                }
                            }
                            if (message.fsGroupChangePolicy != null && message.hasOwnProperty("fsGroupChangePolicy"))
                                if (!$util.isString(message.fsGroupChangePolicy))
                                    return "fsGroupChangePolicy: string expected";
                            if (message.seccompProfile != null && message.hasOwnProperty("seccompProfile")) {
                                var error = $root.k8s.io.api.core.v1.SeccompProfile.verify(message.seccompProfile);
                                if (error)
                                    return "seccompProfile." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PodSecurityContext message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodSecurityContext} PodSecurityContext
                         */
                        PodSecurityContext.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodSecurityContext)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodSecurityContext();
                            if (object.seLinuxOptions != null) {
                                if (typeof object.seLinuxOptions !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSecurityContext.seLinuxOptions: object expected");
                                message.seLinuxOptions = $root.k8s.io.api.core.v1.SELinuxOptions.fromObject(object.seLinuxOptions);
                            }
                            if (object.windowsOptions != null) {
                                if (typeof object.windowsOptions !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSecurityContext.windowsOptions: object expected");
                                message.windowsOptions = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.fromObject(object.windowsOptions);
                            }
                            if (object.runAsUser != null)
                                if ($util.Long)
                                    (message.runAsUser = $util.Long.fromValue(object.runAsUser)).unsigned = false;
                                else if (typeof object.runAsUser === "string")
                                    message.runAsUser = parseInt(object.runAsUser, 10);
                                else if (typeof object.runAsUser === "number")
                                    message.runAsUser = object.runAsUser;
                                else if (typeof object.runAsUser === "object")
                                    message.runAsUser = new $util.LongBits(object.runAsUser.low >>> 0, object.runAsUser.high >>> 0).toNumber();
                            if (object.runAsGroup != null)
                                if ($util.Long)
                                    (message.runAsGroup = $util.Long.fromValue(object.runAsGroup)).unsigned = false;
                                else if (typeof object.runAsGroup === "string")
                                    message.runAsGroup = parseInt(object.runAsGroup, 10);
                                else if (typeof object.runAsGroup === "number")
                                    message.runAsGroup = object.runAsGroup;
                                else if (typeof object.runAsGroup === "object")
                                    message.runAsGroup = new $util.LongBits(object.runAsGroup.low >>> 0, object.runAsGroup.high >>> 0).toNumber();
                            if (object.runAsNonRoot != null)
                                message.runAsNonRoot = Boolean(object.runAsNonRoot);
                            if (object.supplementalGroups) {
                                if (!Array.isArray(object.supplementalGroups))
                                    throw TypeError(".k8s.io.api.core.v1.PodSecurityContext.supplementalGroups: array expected");
                                message.supplementalGroups = [];
                                for (var i = 0; i < object.supplementalGroups.length; ++i)
                                    if ($util.Long)
                                        (message.supplementalGroups[i] = $util.Long.fromValue(object.supplementalGroups[i])).unsigned = false;
                                    else if (typeof object.supplementalGroups[i] === "string")
                                        message.supplementalGroups[i] = parseInt(object.supplementalGroups[i], 10);
                                    else if (typeof object.supplementalGroups[i] === "number")
                                        message.supplementalGroups[i] = object.supplementalGroups[i];
                                    else if (typeof object.supplementalGroups[i] === "object")
                                        message.supplementalGroups[i] = new $util.LongBits(object.supplementalGroups[i].low >>> 0, object.supplementalGroups[i].high >>> 0).toNumber();
                            }
                            if (object.fsGroup != null)
                                if ($util.Long)
                                    (message.fsGroup = $util.Long.fromValue(object.fsGroup)).unsigned = false;
                                else if (typeof object.fsGroup === "string")
                                    message.fsGroup = parseInt(object.fsGroup, 10);
                                else if (typeof object.fsGroup === "number")
                                    message.fsGroup = object.fsGroup;
                                else if (typeof object.fsGroup === "object")
                                    message.fsGroup = new $util.LongBits(object.fsGroup.low >>> 0, object.fsGroup.high >>> 0).toNumber();
                            if (object.sysctls) {
                                if (!Array.isArray(object.sysctls))
                                    throw TypeError(".k8s.io.api.core.v1.PodSecurityContext.sysctls: array expected");
                                message.sysctls = [];
                                for (var i = 0; i < object.sysctls.length; ++i) {
                                    if (typeof object.sysctls[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSecurityContext.sysctls: object expected");
                                    message.sysctls[i] = $root.k8s.io.api.core.v1.Sysctl.fromObject(object.sysctls[i]);
                                }
                            }
                            if (object.fsGroupChangePolicy != null)
                                message.fsGroupChangePolicy = String(object.fsGroupChangePolicy);
                            if (object.seccompProfile != null) {
                                if (typeof object.seccompProfile !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSecurityContext.seccompProfile: object expected");
                                message.seccompProfile = $root.k8s.io.api.core.v1.SeccompProfile.fromObject(object.seccompProfile);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodSecurityContext message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.PodSecurityContext} message PodSecurityContext
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodSecurityContext.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.supplementalGroups = [];
                                object.sysctls = [];
                            }
                            if (options.defaults) {
                                object.seLinuxOptions = null;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.runAsUser = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.runAsUser = options.longs === String ? "0" : 0;
                                object.runAsNonRoot = false;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.fsGroup = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.fsGroup = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.runAsGroup = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.runAsGroup = options.longs === String ? "0" : 0;
                                object.windowsOptions = null;
                                object.fsGroupChangePolicy = "";
                                object.seccompProfile = null;
                            }
                            if (message.seLinuxOptions != null && message.hasOwnProperty("seLinuxOptions"))
                                object.seLinuxOptions = $root.k8s.io.api.core.v1.SELinuxOptions.toObject(message.seLinuxOptions, options);
                            if (message.runAsUser != null && message.hasOwnProperty("runAsUser"))
                                if (typeof message.runAsUser === "number")
                                    object.runAsUser = options.longs === String ? String(message.runAsUser) : message.runAsUser;
                                else
                                    object.runAsUser = options.longs === String ? $util.Long.prototype.toString.call(message.runAsUser) : options.longs === Number ? new $util.LongBits(message.runAsUser.low >>> 0, message.runAsUser.high >>> 0).toNumber() : message.runAsUser;
                            if (message.runAsNonRoot != null && message.hasOwnProperty("runAsNonRoot"))
                                object.runAsNonRoot = message.runAsNonRoot;
                            if (message.supplementalGroups && message.supplementalGroups.length) {
                                object.supplementalGroups = [];
                                for (var j = 0; j < message.supplementalGroups.length; ++j)
                                    if (typeof message.supplementalGroups[j] === "number")
                                        object.supplementalGroups[j] = options.longs === String ? String(message.supplementalGroups[j]) : message.supplementalGroups[j];
                                    else
                                        object.supplementalGroups[j] = options.longs === String ? $util.Long.prototype.toString.call(message.supplementalGroups[j]) : options.longs === Number ? new $util.LongBits(message.supplementalGroups[j].low >>> 0, message.supplementalGroups[j].high >>> 0).toNumber() : message.supplementalGroups[j];
                            }
                            if (message.fsGroup != null && message.hasOwnProperty("fsGroup"))
                                if (typeof message.fsGroup === "number")
                                    object.fsGroup = options.longs === String ? String(message.fsGroup) : message.fsGroup;
                                else
                                    object.fsGroup = options.longs === String ? $util.Long.prototype.toString.call(message.fsGroup) : options.longs === Number ? new $util.LongBits(message.fsGroup.low >>> 0, message.fsGroup.high >>> 0).toNumber() : message.fsGroup;
                            if (message.runAsGroup != null && message.hasOwnProperty("runAsGroup"))
                                if (typeof message.runAsGroup === "number")
                                    object.runAsGroup = options.longs === String ? String(message.runAsGroup) : message.runAsGroup;
                                else
                                    object.runAsGroup = options.longs === String ? $util.Long.prototype.toString.call(message.runAsGroup) : options.longs === Number ? new $util.LongBits(message.runAsGroup.low >>> 0, message.runAsGroup.high >>> 0).toNumber() : message.runAsGroup;
                            if (message.sysctls && message.sysctls.length) {
                                object.sysctls = [];
                                for (var j = 0; j < message.sysctls.length; ++j)
                                    object.sysctls[j] = $root.k8s.io.api.core.v1.Sysctl.toObject(message.sysctls[j], options);
                            }
                            if (message.windowsOptions != null && message.hasOwnProperty("windowsOptions"))
                                object.windowsOptions = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.toObject(message.windowsOptions, options);
                            if (message.fsGroupChangePolicy != null && message.hasOwnProperty("fsGroupChangePolicy"))
                                object.fsGroupChangePolicy = message.fsGroupChangePolicy;
                            if (message.seccompProfile != null && message.hasOwnProperty("seccompProfile"))
                                object.seccompProfile = $root.k8s.io.api.core.v1.SeccompProfile.toObject(message.seccompProfile, options);
                            return object;
                        };

                        /**
                         * Converts this PodSecurityContext to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodSecurityContext
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodSecurityContext.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodSecurityContext;
                    })();

                    v1.PodSignature = (function() {

                        /**
                         * Properties of a PodSignature.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodSignature
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference|null} [podController] PodSignature podController
                         */

                        /**
                         * Constructs a new PodSignature.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodSignature.
                         * @implements IPodSignature
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodSignature=} [properties] Properties to set
                         */
                        function PodSignature(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodSignature podController.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference|null|undefined} podController
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @instance
                         */
                        PodSignature.prototype.podController = null;

                        /**
                         * Creates a new PodSignature instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSignature=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodSignature} PodSignature instance
                         */
                        PodSignature.create = function create(properties) {
                            return new PodSignature(properties);
                        };

                        /**
                         * Encodes the specified PodSignature message. Does not implicitly {@link k8s.io.api.core.v1.PodSignature.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSignature} message PodSignature message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodSignature.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.podController != null && Object.hasOwnProperty.call(message, "podController"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.encode(message.podController, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodSignature message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodSignature.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSignature} message PodSignature message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodSignature.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodSignature message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodSignature} PodSignature
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodSignature.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodSignature();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.podController = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodSignature message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodSignature} PodSignature
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodSignature.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodSignature message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodSignature.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.podController != null && message.hasOwnProperty("podController")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.verify(message.podController);
                                if (error)
                                    return "podController." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PodSignature message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodSignature} PodSignature
                         */
                        PodSignature.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodSignature)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodSignature();
                            if (object.podController != null) {
                                if (typeof object.podController !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSignature.podController: object expected");
                                message.podController = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.fromObject(object.podController);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodSignature message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @static
                         * @param {k8s.io.api.core.v1.PodSignature} message PodSignature
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodSignature.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.podController = null;
                            if (message.podController != null && message.hasOwnProperty("podController"))
                                object.podController = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.toObject(message.podController, options);
                            return object;
                        };

                        /**
                         * Converts this PodSignature to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodSignature
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodSignature.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodSignature;
                    })();

                    v1.PodSpec = (function() {

                        /**
                         * Properties of a PodSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodSpec
                         * @property {Array.<k8s.io.api.core.v1.IVolume>|null} [volumes] PodSpec volumes
                         * @property {Array.<k8s.io.api.core.v1.IContainer>|null} [initContainers] PodSpec initContainers
                         * @property {Array.<k8s.io.api.core.v1.IContainer>|null} [containers] PodSpec containers
                         * @property {Array.<k8s.io.api.core.v1.IEphemeralContainer>|null} [ephemeralContainers] PodSpec ephemeralContainers
                         * @property {string|null} [restartPolicy] PodSpec restartPolicy
                         * @property {number|Long|null} [terminationGracePeriodSeconds] PodSpec terminationGracePeriodSeconds
                         * @property {number|Long|null} [activeDeadlineSeconds] PodSpec activeDeadlineSeconds
                         * @property {string|null} [dnsPolicy] PodSpec dnsPolicy
                         * @property {Object.<string,string>|null} [nodeSelector] PodSpec nodeSelector
                         * @property {string|null} [serviceAccountName] PodSpec serviceAccountName
                         * @property {string|null} [serviceAccount] PodSpec serviceAccount
                         * @property {boolean|null} [automountServiceAccountToken] PodSpec automountServiceAccountToken
                         * @property {string|null} [nodeName] PodSpec nodeName
                         * @property {boolean|null} [hostNetwork] PodSpec hostNetwork
                         * @property {boolean|null} [hostPID] PodSpec hostPID
                         * @property {boolean|null} [hostIPC] PodSpec hostIPC
                         * @property {boolean|null} [shareProcessNamespace] PodSpec shareProcessNamespace
                         * @property {k8s.io.api.core.v1.IPodSecurityContext|null} [securityContext] PodSpec securityContext
                         * @property {Array.<k8s.io.api.core.v1.ILocalObjectReference>|null} [imagePullSecrets] PodSpec imagePullSecrets
                         * @property {string|null} [hostname] PodSpec hostname
                         * @property {string|null} [subdomain] PodSpec subdomain
                         * @property {k8s.io.api.core.v1.IAffinity|null} [affinity] PodSpec affinity
                         * @property {string|null} [schedulerName] PodSpec schedulerName
                         * @property {Array.<k8s.io.api.core.v1.IToleration>|null} [tolerations] PodSpec tolerations
                         * @property {Array.<k8s.io.api.core.v1.IHostAlias>|null} [hostAliases] PodSpec hostAliases
                         * @property {string|null} [priorityClassName] PodSpec priorityClassName
                         * @property {number|null} [priority] PodSpec priority
                         * @property {k8s.io.api.core.v1.IPodDNSConfig|null} [dnsConfig] PodSpec dnsConfig
                         * @property {Array.<k8s.io.api.core.v1.IPodReadinessGate>|null} [readinessGates] PodSpec readinessGates
                         * @property {string|null} [runtimeClassName] PodSpec runtimeClassName
                         * @property {boolean|null} [enableServiceLinks] PodSpec enableServiceLinks
                         * @property {string|null} [preemptionPolicy] PodSpec preemptionPolicy
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [overhead] PodSpec overhead
                         * @property {Array.<k8s.io.api.core.v1.ITopologySpreadConstraint>|null} [topologySpreadConstraints] PodSpec topologySpreadConstraints
                         * @property {boolean|null} [setHostnameAsFQDN] PodSpec setHostnameAsFQDN
                         */

                        /**
                         * Constructs a new PodSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodSpec.
                         * @implements IPodSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodSpec=} [properties] Properties to set
                         */
                        function PodSpec(properties) {
                            this.volumes = [];
                            this.initContainers = [];
                            this.containers = [];
                            this.ephemeralContainers = [];
                            this.nodeSelector = {};
                            this.imagePullSecrets = [];
                            this.tolerations = [];
                            this.hostAliases = [];
                            this.readinessGates = [];
                            this.overhead = {};
                            this.topologySpreadConstraints = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodSpec volumes.
                         * @member {Array.<k8s.io.api.core.v1.IVolume>} volumes
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.volumes = $util.emptyArray;

                        /**
                         * PodSpec initContainers.
                         * @member {Array.<k8s.io.api.core.v1.IContainer>} initContainers
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.initContainers = $util.emptyArray;

                        /**
                         * PodSpec containers.
                         * @member {Array.<k8s.io.api.core.v1.IContainer>} containers
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.containers = $util.emptyArray;

                        /**
                         * PodSpec ephemeralContainers.
                         * @member {Array.<k8s.io.api.core.v1.IEphemeralContainer>} ephemeralContainers
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.ephemeralContainers = $util.emptyArray;

                        /**
                         * PodSpec restartPolicy.
                         * @member {string} restartPolicy
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.restartPolicy = "";

                        /**
                         * PodSpec terminationGracePeriodSeconds.
                         * @member {number|Long} terminationGracePeriodSeconds
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.terminationGracePeriodSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodSpec activeDeadlineSeconds.
                         * @member {number|Long} activeDeadlineSeconds
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.activeDeadlineSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * PodSpec dnsPolicy.
                         * @member {string} dnsPolicy
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.dnsPolicy = "";

                        /**
                         * PodSpec nodeSelector.
                         * @member {Object.<string,string>} nodeSelector
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.nodeSelector = $util.emptyObject;

                        /**
                         * PodSpec serviceAccountName.
                         * @member {string} serviceAccountName
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.serviceAccountName = "";

                        /**
                         * PodSpec serviceAccount.
                         * @member {string} serviceAccount
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.serviceAccount = "";

                        /**
                         * PodSpec automountServiceAccountToken.
                         * @member {boolean} automountServiceAccountToken
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.automountServiceAccountToken = false;

                        /**
                         * PodSpec nodeName.
                         * @member {string} nodeName
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.nodeName = "";

                        /**
                         * PodSpec hostNetwork.
                         * @member {boolean} hostNetwork
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.hostNetwork = false;

                        /**
                         * PodSpec hostPID.
                         * @member {boolean} hostPID
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.hostPID = false;

                        /**
                         * PodSpec hostIPC.
                         * @member {boolean} hostIPC
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.hostIPC = false;

                        /**
                         * PodSpec shareProcessNamespace.
                         * @member {boolean} shareProcessNamespace
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.shareProcessNamespace = false;

                        /**
                         * PodSpec securityContext.
                         * @member {k8s.io.api.core.v1.IPodSecurityContext|null|undefined} securityContext
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.securityContext = null;

                        /**
                         * PodSpec imagePullSecrets.
                         * @member {Array.<k8s.io.api.core.v1.ILocalObjectReference>} imagePullSecrets
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.imagePullSecrets = $util.emptyArray;

                        /**
                         * PodSpec hostname.
                         * @member {string} hostname
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.hostname = "";

                        /**
                         * PodSpec subdomain.
                         * @member {string} subdomain
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.subdomain = "";

                        /**
                         * PodSpec affinity.
                         * @member {k8s.io.api.core.v1.IAffinity|null|undefined} affinity
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.affinity = null;

                        /**
                         * PodSpec schedulerName.
                         * @member {string} schedulerName
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.schedulerName = "";

                        /**
                         * PodSpec tolerations.
                         * @member {Array.<k8s.io.api.core.v1.IToleration>} tolerations
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.tolerations = $util.emptyArray;

                        /**
                         * PodSpec hostAliases.
                         * @member {Array.<k8s.io.api.core.v1.IHostAlias>} hostAliases
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.hostAliases = $util.emptyArray;

                        /**
                         * PodSpec priorityClassName.
                         * @member {string} priorityClassName
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.priorityClassName = "";

                        /**
                         * PodSpec priority.
                         * @member {number} priority
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.priority = 0;

                        /**
                         * PodSpec dnsConfig.
                         * @member {k8s.io.api.core.v1.IPodDNSConfig|null|undefined} dnsConfig
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.dnsConfig = null;

                        /**
                         * PodSpec readinessGates.
                         * @member {Array.<k8s.io.api.core.v1.IPodReadinessGate>} readinessGates
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.readinessGates = $util.emptyArray;

                        /**
                         * PodSpec runtimeClassName.
                         * @member {string} runtimeClassName
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.runtimeClassName = "";

                        /**
                         * PodSpec enableServiceLinks.
                         * @member {boolean} enableServiceLinks
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.enableServiceLinks = false;

                        /**
                         * PodSpec preemptionPolicy.
                         * @member {string} preemptionPolicy
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.preemptionPolicy = "";

                        /**
                         * PodSpec overhead.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} overhead
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.overhead = $util.emptyObject;

                        /**
                         * PodSpec topologySpreadConstraints.
                         * @member {Array.<k8s.io.api.core.v1.ITopologySpreadConstraint>} topologySpreadConstraints
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.topologySpreadConstraints = $util.emptyArray;

                        /**
                         * PodSpec setHostnameAsFQDN.
                         * @member {boolean} setHostnameAsFQDN
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         */
                        PodSpec.prototype.setHostnameAsFQDN = false;

                        /**
                         * Creates a new PodSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodSpec} PodSpec instance
                         */
                        PodSpec.create = function create(properties) {
                            return new PodSpec(properties);
                        };

                        /**
                         * Encodes the specified PodSpec message. Does not implicitly {@link k8s.io.api.core.v1.PodSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSpec} message PodSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumes != null && message.volumes.length)
                                for (var i = 0; i < message.volumes.length; ++i)
                                    $root.k8s.io.api.core.v1.Volume.encode(message.volumes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.containers != null && message.containers.length)
                                for (var i = 0; i < message.containers.length; ++i)
                                    $root.k8s.io.api.core.v1.Container.encode(message.containers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.restartPolicy != null && Object.hasOwnProperty.call(message, "restartPolicy"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.restartPolicy);
                            if (message.terminationGracePeriodSeconds != null && Object.hasOwnProperty.call(message, "terminationGracePeriodSeconds"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.terminationGracePeriodSeconds);
                            if (message.activeDeadlineSeconds != null && Object.hasOwnProperty.call(message, "activeDeadlineSeconds"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.activeDeadlineSeconds);
                            if (message.dnsPolicy != null && Object.hasOwnProperty.call(message, "dnsPolicy"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.dnsPolicy);
                            if (message.nodeSelector != null && Object.hasOwnProperty.call(message, "nodeSelector"))
                                for (var keys = Object.keys(message.nodeSelector), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 7, wireType 2 =*/58).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.nodeSelector[keys[i]]).ldelim();
                            if (message.serviceAccountName != null && Object.hasOwnProperty.call(message, "serviceAccountName"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.serviceAccountName);
                            if (message.serviceAccount != null && Object.hasOwnProperty.call(message, "serviceAccount"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.serviceAccount);
                            if (message.nodeName != null && Object.hasOwnProperty.call(message, "nodeName"))
                                writer.uint32(/* id 10, wireType 2 =*/82).string(message.nodeName);
                            if (message.hostNetwork != null && Object.hasOwnProperty.call(message, "hostNetwork"))
                                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.hostNetwork);
                            if (message.hostPID != null && Object.hasOwnProperty.call(message, "hostPID"))
                                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.hostPID);
                            if (message.hostIPC != null && Object.hasOwnProperty.call(message, "hostIPC"))
                                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.hostIPC);
                            if (message.securityContext != null && Object.hasOwnProperty.call(message, "securityContext"))
                                $root.k8s.io.api.core.v1.PodSecurityContext.encode(message.securityContext, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.imagePullSecrets != null && message.imagePullSecrets.length)
                                for (var i = 0; i < message.imagePullSecrets.length; ++i)
                                    $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.imagePullSecrets[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.hostname != null && Object.hasOwnProperty.call(message, "hostname"))
                                writer.uint32(/* id 16, wireType 2 =*/130).string(message.hostname);
                            if (message.subdomain != null && Object.hasOwnProperty.call(message, "subdomain"))
                                writer.uint32(/* id 17, wireType 2 =*/138).string(message.subdomain);
                            if (message.affinity != null && Object.hasOwnProperty.call(message, "affinity"))
                                $root.k8s.io.api.core.v1.Affinity.encode(message.affinity, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.schedulerName != null && Object.hasOwnProperty.call(message, "schedulerName"))
                                writer.uint32(/* id 19, wireType 2 =*/154).string(message.schedulerName);
                            if (message.initContainers != null && message.initContainers.length)
                                for (var i = 0; i < message.initContainers.length; ++i)
                                    $root.k8s.io.api.core.v1.Container.encode(message.initContainers[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.automountServiceAccountToken != null && Object.hasOwnProperty.call(message, "automountServiceAccountToken"))
                                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.automountServiceAccountToken);
                            if (message.tolerations != null && message.tolerations.length)
                                for (var i = 0; i < message.tolerations.length; ++i)
                                    $root.k8s.io.api.core.v1.Toleration.encode(message.tolerations[i], writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            if (message.hostAliases != null && message.hostAliases.length)
                                for (var i = 0; i < message.hostAliases.length; ++i)
                                    $root.k8s.io.api.core.v1.HostAlias.encode(message.hostAliases[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                            if (message.priorityClassName != null && Object.hasOwnProperty.call(message, "priorityClassName"))
                                writer.uint32(/* id 24, wireType 2 =*/194).string(message.priorityClassName);
                            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                                writer.uint32(/* id 25, wireType 0 =*/200).int32(message.priority);
                            if (message.dnsConfig != null && Object.hasOwnProperty.call(message, "dnsConfig"))
                                $root.k8s.io.api.core.v1.PodDNSConfig.encode(message.dnsConfig, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                            if (message.shareProcessNamespace != null && Object.hasOwnProperty.call(message, "shareProcessNamespace"))
                                writer.uint32(/* id 27, wireType 0 =*/216).bool(message.shareProcessNamespace);
                            if (message.readinessGates != null && message.readinessGates.length)
                                for (var i = 0; i < message.readinessGates.length; ++i)
                                    $root.k8s.io.api.core.v1.PodReadinessGate.encode(message.readinessGates[i], writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                            if (message.runtimeClassName != null && Object.hasOwnProperty.call(message, "runtimeClassName"))
                                writer.uint32(/* id 29, wireType 2 =*/234).string(message.runtimeClassName);
                            if (message.enableServiceLinks != null && Object.hasOwnProperty.call(message, "enableServiceLinks"))
                                writer.uint32(/* id 30, wireType 0 =*/240).bool(message.enableServiceLinks);
                            if (message.preemptionPolicy != null && Object.hasOwnProperty.call(message, "preemptionPolicy"))
                                writer.uint32(/* id 31, wireType 2 =*/250).string(message.preemptionPolicy);
                            if (message.overhead != null && Object.hasOwnProperty.call(message, "overhead"))
                                for (var keys = Object.keys(message.overhead), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 32, wireType 2 =*/258).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.overhead[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.topologySpreadConstraints != null && message.topologySpreadConstraints.length)
                                for (var i = 0; i < message.topologySpreadConstraints.length; ++i)
                                    $root.k8s.io.api.core.v1.TopologySpreadConstraint.encode(message.topologySpreadConstraints[i], writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                            if (message.ephemeralContainers != null && message.ephemeralContainers.length)
                                for (var i = 0; i < message.ephemeralContainers.length; ++i)
                                    $root.k8s.io.api.core.v1.EphemeralContainer.encode(message.ephemeralContainers[i], writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                            if (message.setHostnameAsFQDN != null && Object.hasOwnProperty.call(message, "setHostnameAsFQDN"))
                                writer.uint32(/* id 35, wireType 0 =*/280).bool(message.setHostnameAsFQDN);
                            return writer;
                        };

                        /**
                         * Encodes the specified PodSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPodSpec} message PodSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodSpec} PodSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodSpec(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.volumes && message.volumes.length))
                                        message.volumes = [];
                                    message.volumes.push($root.k8s.io.api.core.v1.Volume.decode(reader, reader.uint32()));
                                    break;
                                case 20:
                                    if (!(message.initContainers && message.initContainers.length))
                                        message.initContainers = [];
                                    message.initContainers.push($root.k8s.io.api.core.v1.Container.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (!(message.containers && message.containers.length))
                                        message.containers = [];
                                    message.containers.push($root.k8s.io.api.core.v1.Container.decode(reader, reader.uint32()));
                                    break;
                                case 34:
                                    if (!(message.ephemeralContainers && message.ephemeralContainers.length))
                                        message.ephemeralContainers = [];
                                    message.ephemeralContainers.push($root.k8s.io.api.core.v1.EphemeralContainer.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    message.restartPolicy = reader.string();
                                    break;
                                case 4:
                                    message.terminationGracePeriodSeconds = reader.int64();
                                    break;
                                case 5:
                                    message.activeDeadlineSeconds = reader.int64();
                                    break;
                                case 6:
                                    message.dnsPolicy = reader.string();
                                    break;
                                case 7:
                                    if (message.nodeSelector === $util.emptyObject)
                                        message.nodeSelector = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.nodeSelector[key] = value;
                                    break;
                                case 8:
                                    message.serviceAccountName = reader.string();
                                    break;
                                case 9:
                                    message.serviceAccount = reader.string();
                                    break;
                                case 21:
                                    message.automountServiceAccountToken = reader.bool();
                                    break;
                                case 10:
                                    message.nodeName = reader.string();
                                    break;
                                case 11:
                                    message.hostNetwork = reader.bool();
                                    break;
                                case 12:
                                    message.hostPID = reader.bool();
                                    break;
                                case 13:
                                    message.hostIPC = reader.bool();
                                    break;
                                case 27:
                                    message.shareProcessNamespace = reader.bool();
                                    break;
                                case 14:
                                    message.securityContext = $root.k8s.io.api.core.v1.PodSecurityContext.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    if (!(message.imagePullSecrets && message.imagePullSecrets.length))
                                        message.imagePullSecrets = [];
                                    message.imagePullSecrets.push($root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32()));
                                    break;
                                case 16:
                                    message.hostname = reader.string();
                                    break;
                                case 17:
                                    message.subdomain = reader.string();
                                    break;
                                case 18:
                                    message.affinity = $root.k8s.io.api.core.v1.Affinity.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.schedulerName = reader.string();
                                    break;
                                case 22:
                                    if (!(message.tolerations && message.tolerations.length))
                                        message.tolerations = [];
                                    message.tolerations.push($root.k8s.io.api.core.v1.Toleration.decode(reader, reader.uint32()));
                                    break;
                                case 23:
                                    if (!(message.hostAliases && message.hostAliases.length))
                                        message.hostAliases = [];
                                    message.hostAliases.push($root.k8s.io.api.core.v1.HostAlias.decode(reader, reader.uint32()));
                                    break;
                                case 24:
                                    message.priorityClassName = reader.string();
                                    break;
                                case 25:
                                    message.priority = reader.int32();
                                    break;
                                case 26:
                                    message.dnsConfig = $root.k8s.io.api.core.v1.PodDNSConfig.decode(reader, reader.uint32());
                                    break;
                                case 28:
                                    if (!(message.readinessGates && message.readinessGates.length))
                                        message.readinessGates = [];
                                    message.readinessGates.push($root.k8s.io.api.core.v1.PodReadinessGate.decode(reader, reader.uint32()));
                                    break;
                                case 29:
                                    message.runtimeClassName = reader.string();
                                    break;
                                case 30:
                                    message.enableServiceLinks = reader.bool();
                                    break;
                                case 31:
                                    message.preemptionPolicy = reader.string();
                                    break;
                                case 32:
                                    if (message.overhead === $util.emptyObject)
                                        message.overhead = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.overhead[key] = value;
                                    break;
                                case 33:
                                    if (!(message.topologySpreadConstraints && message.topologySpreadConstraints.length))
                                        message.topologySpreadConstraints = [];
                                    message.topologySpreadConstraints.push($root.k8s.io.api.core.v1.TopologySpreadConstraint.decode(reader, reader.uint32()));
                                    break;
                                case 35:
                                    message.setHostnameAsFQDN = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodSpec} PodSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumes != null && message.hasOwnProperty("volumes")) {
                                if (!Array.isArray(message.volumes))
                                    return "volumes: array expected";
                                for (var i = 0; i < message.volumes.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Volume.verify(message.volumes[i]);
                                    if (error)
                                        return "volumes." + error;
                                }
                            }
                            if (message.initContainers != null && message.hasOwnProperty("initContainers")) {
                                if (!Array.isArray(message.initContainers))
                                    return "initContainers: array expected";
                                for (var i = 0; i < message.initContainers.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Container.verify(message.initContainers[i]);
                                    if (error)
                                        return "initContainers." + error;
                                }
                            }
                            if (message.containers != null && message.hasOwnProperty("containers")) {
                                if (!Array.isArray(message.containers))
                                    return "containers: array expected";
                                for (var i = 0; i < message.containers.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Container.verify(message.containers[i]);
                                    if (error)
                                        return "containers." + error;
                                }
                            }
                            if (message.ephemeralContainers != null && message.hasOwnProperty("ephemeralContainers")) {
                                if (!Array.isArray(message.ephemeralContainers))
                                    return "ephemeralContainers: array expected";
                                for (var i = 0; i < message.ephemeralContainers.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.EphemeralContainer.verify(message.ephemeralContainers[i]);
                                    if (error)
                                        return "ephemeralContainers." + error;
                                }
                            }
                            if (message.restartPolicy != null && message.hasOwnProperty("restartPolicy"))
                                if (!$util.isString(message.restartPolicy))
                                    return "restartPolicy: string expected";
                            if (message.terminationGracePeriodSeconds != null && message.hasOwnProperty("terminationGracePeriodSeconds"))
                                if (!$util.isInteger(message.terminationGracePeriodSeconds) && !(message.terminationGracePeriodSeconds && $util.isInteger(message.terminationGracePeriodSeconds.low) && $util.isInteger(message.terminationGracePeriodSeconds.high)))
                                    return "terminationGracePeriodSeconds: integer|Long expected";
                            if (message.activeDeadlineSeconds != null && message.hasOwnProperty("activeDeadlineSeconds"))
                                if (!$util.isInteger(message.activeDeadlineSeconds) && !(message.activeDeadlineSeconds && $util.isInteger(message.activeDeadlineSeconds.low) && $util.isInteger(message.activeDeadlineSeconds.high)))
                                    return "activeDeadlineSeconds: integer|Long expected";
                            if (message.dnsPolicy != null && message.hasOwnProperty("dnsPolicy"))
                                if (!$util.isString(message.dnsPolicy))
                                    return "dnsPolicy: string expected";
                            if (message.nodeSelector != null && message.hasOwnProperty("nodeSelector")) {
                                if (!$util.isObject(message.nodeSelector))
                                    return "nodeSelector: object expected";
                                var key = Object.keys(message.nodeSelector);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.nodeSelector[key[i]]))
                                        return "nodeSelector: string{k:string} expected";
                            }
                            if (message.serviceAccountName != null && message.hasOwnProperty("serviceAccountName"))
                                if (!$util.isString(message.serviceAccountName))
                                    return "serviceAccountName: string expected";
                            if (message.serviceAccount != null && message.hasOwnProperty("serviceAccount"))
                                if (!$util.isString(message.serviceAccount))
                                    return "serviceAccount: string expected";
                            if (message.automountServiceAccountToken != null && message.hasOwnProperty("automountServiceAccountToken"))
                                if (typeof message.automountServiceAccountToken !== "boolean")
                                    return "automountServiceAccountToken: boolean expected";
                            if (message.nodeName != null && message.hasOwnProperty("nodeName"))
                                if (!$util.isString(message.nodeName))
                                    return "nodeName: string expected";
                            if (message.hostNetwork != null && message.hasOwnProperty("hostNetwork"))
                                if (typeof message.hostNetwork !== "boolean")
                                    return "hostNetwork: boolean expected";
                            if (message.hostPID != null && message.hasOwnProperty("hostPID"))
                                if (typeof message.hostPID !== "boolean")
                                    return "hostPID: boolean expected";
                            if (message.hostIPC != null && message.hasOwnProperty("hostIPC"))
                                if (typeof message.hostIPC !== "boolean")
                                    return "hostIPC: boolean expected";
                            if (message.shareProcessNamespace != null && message.hasOwnProperty("shareProcessNamespace"))
                                if (typeof message.shareProcessNamespace !== "boolean")
                                    return "shareProcessNamespace: boolean expected";
                            if (message.securityContext != null && message.hasOwnProperty("securityContext")) {
                                var error = $root.k8s.io.api.core.v1.PodSecurityContext.verify(message.securityContext);
                                if (error)
                                    return "securityContext." + error;
                            }
                            if (message.imagePullSecrets != null && message.hasOwnProperty("imagePullSecrets")) {
                                if (!Array.isArray(message.imagePullSecrets))
                                    return "imagePullSecrets: array expected";
                                for (var i = 0; i < message.imagePullSecrets.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.imagePullSecrets[i]);
                                    if (error)
                                        return "imagePullSecrets." + error;
                                }
                            }
                            if (message.hostname != null && message.hasOwnProperty("hostname"))
                                if (!$util.isString(message.hostname))
                                    return "hostname: string expected";
                            if (message.subdomain != null && message.hasOwnProperty("subdomain"))
                                if (!$util.isString(message.subdomain))
                                    return "subdomain: string expected";
                            if (message.affinity != null && message.hasOwnProperty("affinity")) {
                                var error = $root.k8s.io.api.core.v1.Affinity.verify(message.affinity);
                                if (error)
                                    return "affinity." + error;
                            }
                            if (message.schedulerName != null && message.hasOwnProperty("schedulerName"))
                                if (!$util.isString(message.schedulerName))
                                    return "schedulerName: string expected";
                            if (message.tolerations != null && message.hasOwnProperty("tolerations")) {
                                if (!Array.isArray(message.tolerations))
                                    return "tolerations: array expected";
                                for (var i = 0; i < message.tolerations.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Toleration.verify(message.tolerations[i]);
                                    if (error)
                                        return "tolerations." + error;
                                }
                            }
                            if (message.hostAliases != null && message.hasOwnProperty("hostAliases")) {
                                if (!Array.isArray(message.hostAliases))
                                    return "hostAliases: array expected";
                                for (var i = 0; i < message.hostAliases.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.HostAlias.verify(message.hostAliases[i]);
                                    if (error)
                                        return "hostAliases." + error;
                                }
                            }
                            if (message.priorityClassName != null && message.hasOwnProperty("priorityClassName"))
                                if (!$util.isString(message.priorityClassName))
                                    return "priorityClassName: string expected";
                            if (message.priority != null && message.hasOwnProperty("priority"))
                                if (!$util.isInteger(message.priority))
                                    return "priority: integer expected";
                            if (message.dnsConfig != null && message.hasOwnProperty("dnsConfig")) {
                                var error = $root.k8s.io.api.core.v1.PodDNSConfig.verify(message.dnsConfig);
                                if (error)
                                    return "dnsConfig." + error;
                            }
                            if (message.readinessGates != null && message.hasOwnProperty("readinessGates")) {
                                if (!Array.isArray(message.readinessGates))
                                    return "readinessGates: array expected";
                                for (var i = 0; i < message.readinessGates.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodReadinessGate.verify(message.readinessGates[i]);
                                    if (error)
                                        return "readinessGates." + error;
                                }
                            }
                            if (message.runtimeClassName != null && message.hasOwnProperty("runtimeClassName"))
                                if (!$util.isString(message.runtimeClassName))
                                    return "runtimeClassName: string expected";
                            if (message.enableServiceLinks != null && message.hasOwnProperty("enableServiceLinks"))
                                if (typeof message.enableServiceLinks !== "boolean")
                                    return "enableServiceLinks: boolean expected";
                            if (message.preemptionPolicy != null && message.hasOwnProperty("preemptionPolicy"))
                                if (!$util.isString(message.preemptionPolicy))
                                    return "preemptionPolicy: string expected";
                            if (message.overhead != null && message.hasOwnProperty("overhead")) {
                                if (!$util.isObject(message.overhead))
                                    return "overhead: object expected";
                                var key = Object.keys(message.overhead);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.overhead[key[i]]);
                                    if (error)
                                        return "overhead." + error;
                                }
                            }
                            if (message.topologySpreadConstraints != null && message.hasOwnProperty("topologySpreadConstraints")) {
                                if (!Array.isArray(message.topologySpreadConstraints))
                                    return "topologySpreadConstraints: array expected";
                                for (var i = 0; i < message.topologySpreadConstraints.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.TopologySpreadConstraint.verify(message.topologySpreadConstraints[i]);
                                    if (error)
                                        return "topologySpreadConstraints." + error;
                                }
                            }
                            if (message.setHostnameAsFQDN != null && message.hasOwnProperty("setHostnameAsFQDN"))
                                if (typeof message.setHostnameAsFQDN !== "boolean")
                                    return "setHostnameAsFQDN: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a PodSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodSpec} PodSpec
                         */
                        PodSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodSpec();
                            if (object.volumes) {
                                if (!Array.isArray(object.volumes))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.volumes: array expected");
                                message.volumes = [];
                                for (var i = 0; i < object.volumes.length; ++i) {
                                    if (typeof object.volumes[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.volumes: object expected");
                                    message.volumes[i] = $root.k8s.io.api.core.v1.Volume.fromObject(object.volumes[i]);
                                }
                            }
                            if (object.initContainers) {
                                if (!Array.isArray(object.initContainers))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.initContainers: array expected");
                                message.initContainers = [];
                                for (var i = 0; i < object.initContainers.length; ++i) {
                                    if (typeof object.initContainers[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.initContainers: object expected");
                                    message.initContainers[i] = $root.k8s.io.api.core.v1.Container.fromObject(object.initContainers[i]);
                                }
                            }
                            if (object.containers) {
                                if (!Array.isArray(object.containers))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.containers: array expected");
                                message.containers = [];
                                for (var i = 0; i < object.containers.length; ++i) {
                                    if (typeof object.containers[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.containers: object expected");
                                    message.containers[i] = $root.k8s.io.api.core.v1.Container.fromObject(object.containers[i]);
                                }
                            }
                            if (object.ephemeralContainers) {
                                if (!Array.isArray(object.ephemeralContainers))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.ephemeralContainers: array expected");
                                message.ephemeralContainers = [];
                                for (var i = 0; i < object.ephemeralContainers.length; ++i) {
                                    if (typeof object.ephemeralContainers[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.ephemeralContainers: object expected");
                                    message.ephemeralContainers[i] = $root.k8s.io.api.core.v1.EphemeralContainer.fromObject(object.ephemeralContainers[i]);
                                }
                            }
                            if (object.restartPolicy != null)
                                message.restartPolicy = String(object.restartPolicy);
                            if (object.terminationGracePeriodSeconds != null)
                                if ($util.Long)
                                    (message.terminationGracePeriodSeconds = $util.Long.fromValue(object.terminationGracePeriodSeconds)).unsigned = false;
                                else if (typeof object.terminationGracePeriodSeconds === "string")
                                    message.terminationGracePeriodSeconds = parseInt(object.terminationGracePeriodSeconds, 10);
                                else if (typeof object.terminationGracePeriodSeconds === "number")
                                    message.terminationGracePeriodSeconds = object.terminationGracePeriodSeconds;
                                else if (typeof object.terminationGracePeriodSeconds === "object")
                                    message.terminationGracePeriodSeconds = new $util.LongBits(object.terminationGracePeriodSeconds.low >>> 0, object.terminationGracePeriodSeconds.high >>> 0).toNumber();
                            if (object.activeDeadlineSeconds != null)
                                if ($util.Long)
                                    (message.activeDeadlineSeconds = $util.Long.fromValue(object.activeDeadlineSeconds)).unsigned = false;
                                else if (typeof object.activeDeadlineSeconds === "string")
                                    message.activeDeadlineSeconds = parseInt(object.activeDeadlineSeconds, 10);
                                else if (typeof object.activeDeadlineSeconds === "number")
                                    message.activeDeadlineSeconds = object.activeDeadlineSeconds;
                                else if (typeof object.activeDeadlineSeconds === "object")
                                    message.activeDeadlineSeconds = new $util.LongBits(object.activeDeadlineSeconds.low >>> 0, object.activeDeadlineSeconds.high >>> 0).toNumber();
                            if (object.dnsPolicy != null)
                                message.dnsPolicy = String(object.dnsPolicy);
                            if (object.nodeSelector) {
                                if (typeof object.nodeSelector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.nodeSelector: object expected");
                                message.nodeSelector = {};
                                for (var keys = Object.keys(object.nodeSelector), i = 0; i < keys.length; ++i)
                                    message.nodeSelector[keys[i]] = String(object.nodeSelector[keys[i]]);
                            }
                            if (object.serviceAccountName != null)
                                message.serviceAccountName = String(object.serviceAccountName);
                            if (object.serviceAccount != null)
                                message.serviceAccount = String(object.serviceAccount);
                            if (object.automountServiceAccountToken != null)
                                message.automountServiceAccountToken = Boolean(object.automountServiceAccountToken);
                            if (object.nodeName != null)
                                message.nodeName = String(object.nodeName);
                            if (object.hostNetwork != null)
                                message.hostNetwork = Boolean(object.hostNetwork);
                            if (object.hostPID != null)
                                message.hostPID = Boolean(object.hostPID);
                            if (object.hostIPC != null)
                                message.hostIPC = Boolean(object.hostIPC);
                            if (object.shareProcessNamespace != null)
                                message.shareProcessNamespace = Boolean(object.shareProcessNamespace);
                            if (object.securityContext != null) {
                                if (typeof object.securityContext !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.securityContext: object expected");
                                message.securityContext = $root.k8s.io.api.core.v1.PodSecurityContext.fromObject(object.securityContext);
                            }
                            if (object.imagePullSecrets) {
                                if (!Array.isArray(object.imagePullSecrets))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.imagePullSecrets: array expected");
                                message.imagePullSecrets = [];
                                for (var i = 0; i < object.imagePullSecrets.length; ++i) {
                                    if (typeof object.imagePullSecrets[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.imagePullSecrets: object expected");
                                    message.imagePullSecrets[i] = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.imagePullSecrets[i]);
                                }
                            }
                            if (object.hostname != null)
                                message.hostname = String(object.hostname);
                            if (object.subdomain != null)
                                message.subdomain = String(object.subdomain);
                            if (object.affinity != null) {
                                if (typeof object.affinity !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.affinity: object expected");
                                message.affinity = $root.k8s.io.api.core.v1.Affinity.fromObject(object.affinity);
                            }
                            if (object.schedulerName != null)
                                message.schedulerName = String(object.schedulerName);
                            if (object.tolerations) {
                                if (!Array.isArray(object.tolerations))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.tolerations: array expected");
                                message.tolerations = [];
                                for (var i = 0; i < object.tolerations.length; ++i) {
                                    if (typeof object.tolerations[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.tolerations: object expected");
                                    message.tolerations[i] = $root.k8s.io.api.core.v1.Toleration.fromObject(object.tolerations[i]);
                                }
                            }
                            if (object.hostAliases) {
                                if (!Array.isArray(object.hostAliases))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.hostAliases: array expected");
                                message.hostAliases = [];
                                for (var i = 0; i < object.hostAliases.length; ++i) {
                                    if (typeof object.hostAliases[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.hostAliases: object expected");
                                    message.hostAliases[i] = $root.k8s.io.api.core.v1.HostAlias.fromObject(object.hostAliases[i]);
                                }
                            }
                            if (object.priorityClassName != null)
                                message.priorityClassName = String(object.priorityClassName);
                            if (object.priority != null)
                                message.priority = object.priority | 0;
                            if (object.dnsConfig != null) {
                                if (typeof object.dnsConfig !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.dnsConfig: object expected");
                                message.dnsConfig = $root.k8s.io.api.core.v1.PodDNSConfig.fromObject(object.dnsConfig);
                            }
                            if (object.readinessGates) {
                                if (!Array.isArray(object.readinessGates))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.readinessGates: array expected");
                                message.readinessGates = [];
                                for (var i = 0; i < object.readinessGates.length; ++i) {
                                    if (typeof object.readinessGates[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.readinessGates: object expected");
                                    message.readinessGates[i] = $root.k8s.io.api.core.v1.PodReadinessGate.fromObject(object.readinessGates[i]);
                                }
                            }
                            if (object.runtimeClassName != null)
                                message.runtimeClassName = String(object.runtimeClassName);
                            if (object.enableServiceLinks != null)
                                message.enableServiceLinks = Boolean(object.enableServiceLinks);
                            if (object.preemptionPolicy != null)
                                message.preemptionPolicy = String(object.preemptionPolicy);
                            if (object.overhead) {
                                if (typeof object.overhead !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.overhead: object expected");
                                message.overhead = {};
                                for (var keys = Object.keys(object.overhead), i = 0; i < keys.length; ++i) {
                                    if (typeof object.overhead[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.overhead: object expected");
                                    message.overhead[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.overhead[keys[i]]);
                                }
                            }
                            if (object.topologySpreadConstraints) {
                                if (!Array.isArray(object.topologySpreadConstraints))
                                    throw TypeError(".k8s.io.api.core.v1.PodSpec.topologySpreadConstraints: array expected");
                                message.topologySpreadConstraints = [];
                                for (var i = 0; i < object.topologySpreadConstraints.length; ++i) {
                                    if (typeof object.topologySpreadConstraints[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodSpec.topologySpreadConstraints: object expected");
                                    message.topologySpreadConstraints[i] = $root.k8s.io.api.core.v1.TopologySpreadConstraint.fromObject(object.topologySpreadConstraints[i]);
                                }
                            }
                            if (object.setHostnameAsFQDN != null)
                                message.setHostnameAsFQDN = Boolean(object.setHostnameAsFQDN);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @static
                         * @param {k8s.io.api.core.v1.PodSpec} message PodSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.volumes = [];
                                object.containers = [];
                                object.imagePullSecrets = [];
                                object.initContainers = [];
                                object.tolerations = [];
                                object.hostAliases = [];
                                object.readinessGates = [];
                                object.topologySpreadConstraints = [];
                                object.ephemeralContainers = [];
                            }
                            if (options.objects || options.defaults) {
                                object.nodeSelector = {};
                                object.overhead = {};
                            }
                            if (options.defaults) {
                                object.restartPolicy = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.terminationGracePeriodSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.terminationGracePeriodSeconds = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.activeDeadlineSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.activeDeadlineSeconds = options.longs === String ? "0" : 0;
                                object.dnsPolicy = "";
                                object.serviceAccountName = "";
                                object.serviceAccount = "";
                                object.nodeName = "";
                                object.hostNetwork = false;
                                object.hostPID = false;
                                object.hostIPC = false;
                                object.securityContext = null;
                                object.hostname = "";
                                object.subdomain = "";
                                object.affinity = null;
                                object.schedulerName = "";
                                object.automountServiceAccountToken = false;
                                object.priorityClassName = "";
                                object.priority = 0;
                                object.dnsConfig = null;
                                object.shareProcessNamespace = false;
                                object.runtimeClassName = "";
                                object.enableServiceLinks = false;
                                object.preemptionPolicy = "";
                                object.setHostnameAsFQDN = false;
                            }
                            if (message.volumes && message.volumes.length) {
                                object.volumes = [];
                                for (var j = 0; j < message.volumes.length; ++j)
                                    object.volumes[j] = $root.k8s.io.api.core.v1.Volume.toObject(message.volumes[j], options);
                            }
                            if (message.containers && message.containers.length) {
                                object.containers = [];
                                for (var j = 0; j < message.containers.length; ++j)
                                    object.containers[j] = $root.k8s.io.api.core.v1.Container.toObject(message.containers[j], options);
                            }
                            if (message.restartPolicy != null && message.hasOwnProperty("restartPolicy"))
                                object.restartPolicy = message.restartPolicy;
                            if (message.terminationGracePeriodSeconds != null && message.hasOwnProperty("terminationGracePeriodSeconds"))
                                if (typeof message.terminationGracePeriodSeconds === "number")
                                    object.terminationGracePeriodSeconds = options.longs === String ? String(message.terminationGracePeriodSeconds) : message.terminationGracePeriodSeconds;
                                else
                                    object.terminationGracePeriodSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.terminationGracePeriodSeconds) : options.longs === Number ? new $util.LongBits(message.terminationGracePeriodSeconds.low >>> 0, message.terminationGracePeriodSeconds.high >>> 0).toNumber() : message.terminationGracePeriodSeconds;
                            if (message.activeDeadlineSeconds != null && message.hasOwnProperty("activeDeadlineSeconds"))
                                if (typeof message.activeDeadlineSeconds === "number")
                                    object.activeDeadlineSeconds = options.longs === String ? String(message.activeDeadlineSeconds) : message.activeDeadlineSeconds;
                                else
                                    object.activeDeadlineSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.activeDeadlineSeconds) : options.longs === Number ? new $util.LongBits(message.activeDeadlineSeconds.low >>> 0, message.activeDeadlineSeconds.high >>> 0).toNumber() : message.activeDeadlineSeconds;
                            if (message.dnsPolicy != null && message.hasOwnProperty("dnsPolicy"))
                                object.dnsPolicy = message.dnsPolicy;
                            var keys2;
                            if (message.nodeSelector && (keys2 = Object.keys(message.nodeSelector)).length) {
                                object.nodeSelector = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.nodeSelector[keys2[j]] = message.nodeSelector[keys2[j]];
                            }
                            if (message.serviceAccountName != null && message.hasOwnProperty("serviceAccountName"))
                                object.serviceAccountName = message.serviceAccountName;
                            if (message.serviceAccount != null && message.hasOwnProperty("serviceAccount"))
                                object.serviceAccount = message.serviceAccount;
                            if (message.nodeName != null && message.hasOwnProperty("nodeName"))
                                object.nodeName = message.nodeName;
                            if (message.hostNetwork != null && message.hasOwnProperty("hostNetwork"))
                                object.hostNetwork = message.hostNetwork;
                            if (message.hostPID != null && message.hasOwnProperty("hostPID"))
                                object.hostPID = message.hostPID;
                            if (message.hostIPC != null && message.hasOwnProperty("hostIPC"))
                                object.hostIPC = message.hostIPC;
                            if (message.securityContext != null && message.hasOwnProperty("securityContext"))
                                object.securityContext = $root.k8s.io.api.core.v1.PodSecurityContext.toObject(message.securityContext, options);
                            if (message.imagePullSecrets && message.imagePullSecrets.length) {
                                object.imagePullSecrets = [];
                                for (var j = 0; j < message.imagePullSecrets.length; ++j)
                                    object.imagePullSecrets[j] = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.imagePullSecrets[j], options);
                            }
                            if (message.hostname != null && message.hasOwnProperty("hostname"))
                                object.hostname = message.hostname;
                            if (message.subdomain != null && message.hasOwnProperty("subdomain"))
                                object.subdomain = message.subdomain;
                            if (message.affinity != null && message.hasOwnProperty("affinity"))
                                object.affinity = $root.k8s.io.api.core.v1.Affinity.toObject(message.affinity, options);
                            if (message.schedulerName != null && message.hasOwnProperty("schedulerName"))
                                object.schedulerName = message.schedulerName;
                            if (message.initContainers && message.initContainers.length) {
                                object.initContainers = [];
                                for (var j = 0; j < message.initContainers.length; ++j)
                                    object.initContainers[j] = $root.k8s.io.api.core.v1.Container.toObject(message.initContainers[j], options);
                            }
                            if (message.automountServiceAccountToken != null && message.hasOwnProperty("automountServiceAccountToken"))
                                object.automountServiceAccountToken = message.automountServiceAccountToken;
                            if (message.tolerations && message.tolerations.length) {
                                object.tolerations = [];
                                for (var j = 0; j < message.tolerations.length; ++j)
                                    object.tolerations[j] = $root.k8s.io.api.core.v1.Toleration.toObject(message.tolerations[j], options);
                            }
                            if (message.hostAliases && message.hostAliases.length) {
                                object.hostAliases = [];
                                for (var j = 0; j < message.hostAliases.length; ++j)
                                    object.hostAliases[j] = $root.k8s.io.api.core.v1.HostAlias.toObject(message.hostAliases[j], options);
                            }
                            if (message.priorityClassName != null && message.hasOwnProperty("priorityClassName"))
                                object.priorityClassName = message.priorityClassName;
                            if (message.priority != null && message.hasOwnProperty("priority"))
                                object.priority = message.priority;
                            if (message.dnsConfig != null && message.hasOwnProperty("dnsConfig"))
                                object.dnsConfig = $root.k8s.io.api.core.v1.PodDNSConfig.toObject(message.dnsConfig, options);
                            if (message.shareProcessNamespace != null && message.hasOwnProperty("shareProcessNamespace"))
                                object.shareProcessNamespace = message.shareProcessNamespace;
                            if (message.readinessGates && message.readinessGates.length) {
                                object.readinessGates = [];
                                for (var j = 0; j < message.readinessGates.length; ++j)
                                    object.readinessGates[j] = $root.k8s.io.api.core.v1.PodReadinessGate.toObject(message.readinessGates[j], options);
                            }
                            if (message.runtimeClassName != null && message.hasOwnProperty("runtimeClassName"))
                                object.runtimeClassName = message.runtimeClassName;
                            if (message.enableServiceLinks != null && message.hasOwnProperty("enableServiceLinks"))
                                object.enableServiceLinks = message.enableServiceLinks;
                            if (message.preemptionPolicy != null && message.hasOwnProperty("preemptionPolicy"))
                                object.preemptionPolicy = message.preemptionPolicy;
                            if (message.overhead && (keys2 = Object.keys(message.overhead)).length) {
                                object.overhead = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.overhead[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.overhead[keys2[j]], options);
                            }
                            if (message.topologySpreadConstraints && message.topologySpreadConstraints.length) {
                                object.topologySpreadConstraints = [];
                                for (var j = 0; j < message.topologySpreadConstraints.length; ++j)
                                    object.topologySpreadConstraints[j] = $root.k8s.io.api.core.v1.TopologySpreadConstraint.toObject(message.topologySpreadConstraints[j], options);
                            }
                            if (message.ephemeralContainers && message.ephemeralContainers.length) {
                                object.ephemeralContainers = [];
                                for (var j = 0; j < message.ephemeralContainers.length; ++j)
                                    object.ephemeralContainers[j] = $root.k8s.io.api.core.v1.EphemeralContainer.toObject(message.ephemeralContainers[j], options);
                            }
                            if (message.setHostnameAsFQDN != null && message.hasOwnProperty("setHostnameAsFQDN"))
                                object.setHostnameAsFQDN = message.setHostnameAsFQDN;
                            return object;
                        };

                        /**
                         * Converts this PodSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodSpec;
                    })();

                    v1.PodStatus = (function() {

                        /**
                         * Properties of a PodStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodStatus
                         * @property {string|null} [phase] PodStatus phase
                         * @property {Array.<k8s.io.api.core.v1.IPodCondition>|null} [conditions] PodStatus conditions
                         * @property {string|null} [message] PodStatus message
                         * @property {string|null} [reason] PodStatus reason
                         * @property {string|null} [nominatedNodeName] PodStatus nominatedNodeName
                         * @property {string|null} [hostIP] PodStatus hostIP
                         * @property {string|null} [podIP] PodStatus podIP
                         * @property {Array.<k8s.io.api.core.v1.IPodIP>|null} [podIPs] PodStatus podIPs
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [startTime] PodStatus startTime
                         * @property {Array.<k8s.io.api.core.v1.IContainerStatus>|null} [initContainerStatuses] PodStatus initContainerStatuses
                         * @property {Array.<k8s.io.api.core.v1.IContainerStatus>|null} [containerStatuses] PodStatus containerStatuses
                         * @property {string|null} [qosClass] PodStatus qosClass
                         * @property {Array.<k8s.io.api.core.v1.IContainerStatus>|null} [ephemeralContainerStatuses] PodStatus ephemeralContainerStatuses
                         */

                        /**
                         * Constructs a new PodStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodStatus.
                         * @implements IPodStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodStatus=} [properties] Properties to set
                         */
                        function PodStatus(properties) {
                            this.conditions = [];
                            this.podIPs = [];
                            this.initContainerStatuses = [];
                            this.containerStatuses = [];
                            this.ephemeralContainerStatuses = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodStatus phase.
                         * @member {string} phase
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.phase = "";

                        /**
                         * PodStatus conditions.
                         * @member {Array.<k8s.io.api.core.v1.IPodCondition>} conditions
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * PodStatus message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.message = "";

                        /**
                         * PodStatus reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.reason = "";

                        /**
                         * PodStatus nominatedNodeName.
                         * @member {string} nominatedNodeName
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.nominatedNodeName = "";

                        /**
                         * PodStatus hostIP.
                         * @member {string} hostIP
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.hostIP = "";

                        /**
                         * PodStatus podIP.
                         * @member {string} podIP
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.podIP = "";

                        /**
                         * PodStatus podIPs.
                         * @member {Array.<k8s.io.api.core.v1.IPodIP>} podIPs
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.podIPs = $util.emptyArray;

                        /**
                         * PodStatus startTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} startTime
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.startTime = null;

                        /**
                         * PodStatus initContainerStatuses.
                         * @member {Array.<k8s.io.api.core.v1.IContainerStatus>} initContainerStatuses
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.initContainerStatuses = $util.emptyArray;

                        /**
                         * PodStatus containerStatuses.
                         * @member {Array.<k8s.io.api.core.v1.IContainerStatus>} containerStatuses
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.containerStatuses = $util.emptyArray;

                        /**
                         * PodStatus qosClass.
                         * @member {string} qosClass
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.qosClass = "";

                        /**
                         * PodStatus ephemeralContainerStatuses.
                         * @member {Array.<k8s.io.api.core.v1.IContainerStatus>} ephemeralContainerStatuses
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         */
                        PodStatus.prototype.ephemeralContainerStatuses = $util.emptyArray;

                        /**
                         * Creates a new PodStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPodStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodStatus} PodStatus instance
                         */
                        PodStatus.create = function create(properties) {
                            return new PodStatus(properties);
                        };

                        /**
                         * Encodes the specified PodStatus message. Does not implicitly {@link k8s.io.api.core.v1.PodStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPodStatus} message PodStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.phase != null && Object.hasOwnProperty.call(message, "phase"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phase);
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.api.core.v1.PodCondition.encode(message.conditions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.reason);
                            if (message.hostIP != null && Object.hasOwnProperty.call(message, "hostIP"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.hostIP);
                            if (message.podIP != null && Object.hasOwnProperty.call(message, "podIP"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.podIP);
                            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.startTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.containerStatuses != null && message.containerStatuses.length)
                                for (var i = 0; i < message.containerStatuses.length; ++i)
                                    $root.k8s.io.api.core.v1.ContainerStatus.encode(message.containerStatuses[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.qosClass != null && Object.hasOwnProperty.call(message, "qosClass"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.qosClass);
                            if (message.initContainerStatuses != null && message.initContainerStatuses.length)
                                for (var i = 0; i < message.initContainerStatuses.length; ++i)
                                    $root.k8s.io.api.core.v1.ContainerStatus.encode(message.initContainerStatuses[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.nominatedNodeName != null && Object.hasOwnProperty.call(message, "nominatedNodeName"))
                                writer.uint32(/* id 11, wireType 2 =*/90).string(message.nominatedNodeName);
                            if (message.podIPs != null && message.podIPs.length)
                                for (var i = 0; i < message.podIPs.length; ++i)
                                    $root.k8s.io.api.core.v1.PodIP.encode(message.podIPs[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.ephemeralContainerStatuses != null && message.ephemeralContainerStatuses.length)
                                for (var i = 0; i < message.ephemeralContainerStatuses.length; ++i)
                                    $root.k8s.io.api.core.v1.ContainerStatus.encode(message.ephemeralContainerStatuses[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPodStatus} message PodStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodStatus} PodStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.phase = reader.string();
                                    break;
                                case 2:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.api.core.v1.PodCondition.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    message.message = reader.string();
                                    break;
                                case 4:
                                    message.reason = reader.string();
                                    break;
                                case 11:
                                    message.nominatedNodeName = reader.string();
                                    break;
                                case 5:
                                    message.hostIP = reader.string();
                                    break;
                                case 6:
                                    message.podIP = reader.string();
                                    break;
                                case 12:
                                    if (!(message.podIPs && message.podIPs.length))
                                        message.podIPs = [];
                                    message.podIPs.push($root.k8s.io.api.core.v1.PodIP.decode(reader, reader.uint32()));
                                    break;
                                case 7:
                                    message.startTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    if (!(message.initContainerStatuses && message.initContainerStatuses.length))
                                        message.initContainerStatuses = [];
                                    message.initContainerStatuses.push($root.k8s.io.api.core.v1.ContainerStatus.decode(reader, reader.uint32()));
                                    break;
                                case 8:
                                    if (!(message.containerStatuses && message.containerStatuses.length))
                                        message.containerStatuses = [];
                                    message.containerStatuses.push($root.k8s.io.api.core.v1.ContainerStatus.decode(reader, reader.uint32()));
                                    break;
                                case 9:
                                    message.qosClass = reader.string();
                                    break;
                                case 13:
                                    if (!(message.ephemeralContainerStatuses && message.ephemeralContainerStatuses.length))
                                        message.ephemeralContainerStatuses = [];
                                    message.ephemeralContainerStatuses.push($root.k8s.io.api.core.v1.ContainerStatus.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodStatus} PodStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                if (!$util.isString(message.phase))
                                    return "phase: string expected";
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.nominatedNodeName != null && message.hasOwnProperty("nominatedNodeName"))
                                if (!$util.isString(message.nominatedNodeName))
                                    return "nominatedNodeName: string expected";
                            if (message.hostIP != null && message.hasOwnProperty("hostIP"))
                                if (!$util.isString(message.hostIP))
                                    return "hostIP: string expected";
                            if (message.podIP != null && message.hasOwnProperty("podIP"))
                                if (!$util.isString(message.podIP))
                                    return "podIP: string expected";
                            if (message.podIPs != null && message.hasOwnProperty("podIPs")) {
                                if (!Array.isArray(message.podIPs))
                                    return "podIPs: array expected";
                                for (var i = 0; i < message.podIPs.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodIP.verify(message.podIPs[i]);
                                    if (error)
                                        return "podIPs." + error;
                                }
                            }
                            if (message.startTime != null && message.hasOwnProperty("startTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.startTime);
                                if (error)
                                    return "startTime." + error;
                            }
                            if (message.initContainerStatuses != null && message.hasOwnProperty("initContainerStatuses")) {
                                if (!Array.isArray(message.initContainerStatuses))
                                    return "initContainerStatuses: array expected";
                                for (var i = 0; i < message.initContainerStatuses.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ContainerStatus.verify(message.initContainerStatuses[i]);
                                    if (error)
                                        return "initContainerStatuses." + error;
                                }
                            }
                            if (message.containerStatuses != null && message.hasOwnProperty("containerStatuses")) {
                                if (!Array.isArray(message.containerStatuses))
                                    return "containerStatuses: array expected";
                                for (var i = 0; i < message.containerStatuses.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ContainerStatus.verify(message.containerStatuses[i]);
                                    if (error)
                                        return "containerStatuses." + error;
                                }
                            }
                            if (message.qosClass != null && message.hasOwnProperty("qosClass"))
                                if (!$util.isString(message.qosClass))
                                    return "qosClass: string expected";
                            if (message.ephemeralContainerStatuses != null && message.hasOwnProperty("ephemeralContainerStatuses")) {
                                if (!Array.isArray(message.ephemeralContainerStatuses))
                                    return "ephemeralContainerStatuses: array expected";
                                for (var i = 0; i < message.ephemeralContainerStatuses.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ContainerStatus.verify(message.ephemeralContainerStatuses[i]);
                                    if (error)
                                        return "ephemeralContainerStatuses." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PodStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodStatus} PodStatus
                         */
                        PodStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodStatus();
                            if (object.phase != null)
                                message.phase = String(object.phase);
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.PodStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.api.core.v1.PodCondition.fromObject(object.conditions[i]);
                                }
                            }
                            if (object.message != null)
                                message.message = String(object.message);
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.nominatedNodeName != null)
                                message.nominatedNodeName = String(object.nominatedNodeName);
                            if (object.hostIP != null)
                                message.hostIP = String(object.hostIP);
                            if (object.podIP != null)
                                message.podIP = String(object.podIP);
                            if (object.podIPs) {
                                if (!Array.isArray(object.podIPs))
                                    throw TypeError(".k8s.io.api.core.v1.PodStatus.podIPs: array expected");
                                message.podIPs = [];
                                for (var i = 0; i < object.podIPs.length; ++i) {
                                    if (typeof object.podIPs[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodStatus.podIPs: object expected");
                                    message.podIPs[i] = $root.k8s.io.api.core.v1.PodIP.fromObject(object.podIPs[i]);
                                }
                            }
                            if (object.startTime != null) {
                                if (typeof object.startTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodStatus.startTime: object expected");
                                message.startTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.startTime);
                            }
                            if (object.initContainerStatuses) {
                                if (!Array.isArray(object.initContainerStatuses))
                                    throw TypeError(".k8s.io.api.core.v1.PodStatus.initContainerStatuses: array expected");
                                message.initContainerStatuses = [];
                                for (var i = 0; i < object.initContainerStatuses.length; ++i) {
                                    if (typeof object.initContainerStatuses[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodStatus.initContainerStatuses: object expected");
                                    message.initContainerStatuses[i] = $root.k8s.io.api.core.v1.ContainerStatus.fromObject(object.initContainerStatuses[i]);
                                }
                            }
                            if (object.containerStatuses) {
                                if (!Array.isArray(object.containerStatuses))
                                    throw TypeError(".k8s.io.api.core.v1.PodStatus.containerStatuses: array expected");
                                message.containerStatuses = [];
                                for (var i = 0; i < object.containerStatuses.length; ++i) {
                                    if (typeof object.containerStatuses[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodStatus.containerStatuses: object expected");
                                    message.containerStatuses[i] = $root.k8s.io.api.core.v1.ContainerStatus.fromObject(object.containerStatuses[i]);
                                }
                            }
                            if (object.qosClass != null)
                                message.qosClass = String(object.qosClass);
                            if (object.ephemeralContainerStatuses) {
                                if (!Array.isArray(object.ephemeralContainerStatuses))
                                    throw TypeError(".k8s.io.api.core.v1.PodStatus.ephemeralContainerStatuses: array expected");
                                message.ephemeralContainerStatuses = [];
                                for (var i = 0; i < object.ephemeralContainerStatuses.length; ++i) {
                                    if (typeof object.ephemeralContainerStatuses[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodStatus.ephemeralContainerStatuses: object expected");
                                    message.ephemeralContainerStatuses[i] = $root.k8s.io.api.core.v1.ContainerStatus.fromObject(object.ephemeralContainerStatuses[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @static
                         * @param {k8s.io.api.core.v1.PodStatus} message PodStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.conditions = [];
                                object.containerStatuses = [];
                                object.initContainerStatuses = [];
                                object.podIPs = [];
                                object.ephemeralContainerStatuses = [];
                            }
                            if (options.defaults) {
                                object.phase = "";
                                object.message = "";
                                object.reason = "";
                                object.hostIP = "";
                                object.podIP = "";
                                object.startTime = null;
                                object.qosClass = "";
                                object.nominatedNodeName = "";
                            }
                            if (message.phase != null && message.hasOwnProperty("phase"))
                                object.phase = message.phase;
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.api.core.v1.PodCondition.toObject(message.conditions[j], options);
                            }
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.hostIP != null && message.hasOwnProperty("hostIP"))
                                object.hostIP = message.hostIP;
                            if (message.podIP != null && message.hasOwnProperty("podIP"))
                                object.podIP = message.podIP;
                            if (message.startTime != null && message.hasOwnProperty("startTime"))
                                object.startTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.startTime, options);
                            if (message.containerStatuses && message.containerStatuses.length) {
                                object.containerStatuses = [];
                                for (var j = 0; j < message.containerStatuses.length; ++j)
                                    object.containerStatuses[j] = $root.k8s.io.api.core.v1.ContainerStatus.toObject(message.containerStatuses[j], options);
                            }
                            if (message.qosClass != null && message.hasOwnProperty("qosClass"))
                                object.qosClass = message.qosClass;
                            if (message.initContainerStatuses && message.initContainerStatuses.length) {
                                object.initContainerStatuses = [];
                                for (var j = 0; j < message.initContainerStatuses.length; ++j)
                                    object.initContainerStatuses[j] = $root.k8s.io.api.core.v1.ContainerStatus.toObject(message.initContainerStatuses[j], options);
                            }
                            if (message.nominatedNodeName != null && message.hasOwnProperty("nominatedNodeName"))
                                object.nominatedNodeName = message.nominatedNodeName;
                            if (message.podIPs && message.podIPs.length) {
                                object.podIPs = [];
                                for (var j = 0; j < message.podIPs.length; ++j)
                                    object.podIPs[j] = $root.k8s.io.api.core.v1.PodIP.toObject(message.podIPs[j], options);
                            }
                            if (message.ephemeralContainerStatuses && message.ephemeralContainerStatuses.length) {
                                object.ephemeralContainerStatuses = [];
                                for (var j = 0; j < message.ephemeralContainerStatuses.length; ++j)
                                    object.ephemeralContainerStatuses[j] = $root.k8s.io.api.core.v1.ContainerStatus.toObject(message.ephemeralContainerStatuses[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PodStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodStatus;
                    })();

                    v1.PodStatusResult = (function() {

                        /**
                         * Properties of a PodStatusResult.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodStatusResult
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PodStatusResult metadata
                         * @property {k8s.io.api.core.v1.IPodStatus|null} [status] PodStatusResult status
                         */

                        /**
                         * Constructs a new PodStatusResult.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodStatusResult.
                         * @implements IPodStatusResult
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodStatusResult=} [properties] Properties to set
                         */
                        function PodStatusResult(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodStatusResult metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @instance
                         */
                        PodStatusResult.prototype.metadata = null;

                        /**
                         * PodStatusResult status.
                         * @member {k8s.io.api.core.v1.IPodStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @instance
                         */
                        PodStatusResult.prototype.status = null;

                        /**
                         * Creates a new PodStatusResult instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {k8s.io.api.core.v1.IPodStatusResult=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodStatusResult} PodStatusResult instance
                         */
                        PodStatusResult.create = function create(properties) {
                            return new PodStatusResult(properties);
                        };

                        /**
                         * Encodes the specified PodStatusResult message. Does not implicitly {@link k8s.io.api.core.v1.PodStatusResult.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {k8s.io.api.core.v1.IPodStatusResult} message PodStatusResult message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodStatusResult.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.PodStatus.encode(message.status, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodStatusResult message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodStatusResult.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {k8s.io.api.core.v1.IPodStatusResult} message PodStatusResult message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodStatusResult.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodStatusResult message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodStatusResult} PodStatusResult
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodStatusResult.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodStatusResult();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.status = $root.k8s.io.api.core.v1.PodStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodStatusResult message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodStatusResult} PodStatusResult
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodStatusResult.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodStatusResult message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodStatusResult.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.PodStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PodStatusResult message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodStatusResult} PodStatusResult
                         */
                        PodStatusResult.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodStatusResult)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodStatusResult();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodStatusResult.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodStatusResult.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.PodStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodStatusResult message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @static
                         * @param {k8s.io.api.core.v1.PodStatusResult} message PodStatusResult
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodStatusResult.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.PodStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this PodStatusResult to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodStatusResult
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodStatusResult.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodStatusResult;
                    })();

                    v1.PodTemplate = (function() {

                        /**
                         * Properties of a PodTemplate.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodTemplate
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PodTemplate metadata
                         * @property {k8s.io.api.core.v1.IPodTemplateSpec|null} [template] PodTemplate template
                         */

                        /**
                         * Constructs a new PodTemplate.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodTemplate.
                         * @implements IPodTemplate
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodTemplate=} [properties] Properties to set
                         */
                        function PodTemplate(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodTemplate metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @instance
                         */
                        PodTemplate.prototype.metadata = null;

                        /**
                         * PodTemplate template.
                         * @member {k8s.io.api.core.v1.IPodTemplateSpec|null|undefined} template
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @instance
                         */
                        PodTemplate.prototype.template = null;

                        /**
                         * Creates a new PodTemplate instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplate=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodTemplate} PodTemplate instance
                         */
                        PodTemplate.create = function create(properties) {
                            return new PodTemplate(properties);
                        };

                        /**
                         * Encodes the specified PodTemplate message. Does not implicitly {@link k8s.io.api.core.v1.PodTemplate.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplate} message PodTemplate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodTemplate.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.template != null && Object.hasOwnProperty.call(message, "template"))
                                $root.k8s.io.api.core.v1.PodTemplateSpec.encode(message.template, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodTemplate message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodTemplate.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplate} message PodTemplate message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodTemplate.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodTemplate message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodTemplate} PodTemplate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodTemplate.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodTemplate();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.template = $root.k8s.io.api.core.v1.PodTemplateSpec.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodTemplate message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodTemplate} PodTemplate
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodTemplate.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodTemplate message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodTemplate.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.template != null && message.hasOwnProperty("template")) {
                                var error = $root.k8s.io.api.core.v1.PodTemplateSpec.verify(message.template);
                                if (error)
                                    return "template." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PodTemplate message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodTemplate} PodTemplate
                         */
                        PodTemplate.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodTemplate)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodTemplate();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodTemplate.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.template != null) {
                                if (typeof object.template !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodTemplate.template: object expected");
                                message.template = $root.k8s.io.api.core.v1.PodTemplateSpec.fromObject(object.template);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodTemplate message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @static
                         * @param {k8s.io.api.core.v1.PodTemplate} message PodTemplate
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodTemplate.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.template = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.template != null && message.hasOwnProperty("template"))
                                object.template = $root.k8s.io.api.core.v1.PodTemplateSpec.toObject(message.template, options);
                            return object;
                        };

                        /**
                         * Converts this PodTemplate to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodTemplate
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodTemplate.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodTemplate;
                    })();

                    v1.PodTemplateList = (function() {

                        /**
                         * Properties of a PodTemplateList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodTemplateList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] PodTemplateList metadata
                         * @property {Array.<k8s.io.api.core.v1.IPodTemplate>|null} [items] PodTemplateList items
                         */

                        /**
                         * Constructs a new PodTemplateList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodTemplateList.
                         * @implements IPodTemplateList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodTemplateList=} [properties] Properties to set
                         */
                        function PodTemplateList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodTemplateList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @instance
                         */
                        PodTemplateList.prototype.metadata = null;

                        /**
                         * PodTemplateList items.
                         * @member {Array.<k8s.io.api.core.v1.IPodTemplate>} items
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @instance
                         */
                        PodTemplateList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new PodTemplateList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplateList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodTemplateList} PodTemplateList instance
                         */
                        PodTemplateList.create = function create(properties) {
                            return new PodTemplateList(properties);
                        };

                        /**
                         * Encodes the specified PodTemplateList message. Does not implicitly {@link k8s.io.api.core.v1.PodTemplateList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplateList} message PodTemplateList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodTemplateList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.PodTemplate.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodTemplateList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodTemplateList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplateList} message PodTemplateList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodTemplateList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodTemplateList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodTemplateList} PodTemplateList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodTemplateList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodTemplateList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.PodTemplate.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodTemplateList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodTemplateList} PodTemplateList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodTemplateList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodTemplateList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodTemplateList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.PodTemplate.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a PodTemplateList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodTemplateList} PodTemplateList
                         */
                        PodTemplateList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodTemplateList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodTemplateList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodTemplateList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.PodTemplateList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.PodTemplateList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.PodTemplate.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodTemplateList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @static
                         * @param {k8s.io.api.core.v1.PodTemplateList} message PodTemplateList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodTemplateList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.PodTemplate.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this PodTemplateList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodTemplateList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodTemplateList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodTemplateList;
                    })();

                    v1.PodTemplateSpec = (function() {

                        /**
                         * Properties of a PodTemplateSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPodTemplateSpec
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PodTemplateSpec metadata
                         * @property {k8s.io.api.core.v1.IPodSpec|null} [spec] PodTemplateSpec spec
                         */

                        /**
                         * Constructs a new PodTemplateSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PodTemplateSpec.
                         * @implements IPodTemplateSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPodTemplateSpec=} [properties] Properties to set
                         */
                        function PodTemplateSpec(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PodTemplateSpec metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @instance
                         */
                        PodTemplateSpec.prototype.metadata = null;

                        /**
                         * PodTemplateSpec spec.
                         * @member {k8s.io.api.core.v1.IPodSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @instance
                         */
                        PodTemplateSpec.prototype.spec = null;

                        /**
                         * Creates a new PodTemplateSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplateSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PodTemplateSpec} PodTemplateSpec instance
                         */
                        PodTemplateSpec.create = function create(properties) {
                            return new PodTemplateSpec(properties);
                        };

                        /**
                         * Encodes the specified PodTemplateSpec message. Does not implicitly {@link k8s.io.api.core.v1.PodTemplateSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplateSpec} message PodTemplateSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodTemplateSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.PodSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PodTemplateSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PodTemplateSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IPodTemplateSpec} message PodTemplateSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PodTemplateSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PodTemplateSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PodTemplateSpec} PodTemplateSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodTemplateSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PodTemplateSpec();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.PodSpec.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PodTemplateSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PodTemplateSpec} PodTemplateSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PodTemplateSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PodTemplateSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PodTemplateSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.PodSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PodTemplateSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PodTemplateSpec} PodTemplateSpec
                         */
                        PodTemplateSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PodTemplateSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PodTemplateSpec();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodTemplateSpec.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PodTemplateSpec.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.PodSpec.fromObject(object.spec);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PodTemplateSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @static
                         * @param {k8s.io.api.core.v1.PodTemplateSpec} message PodTemplateSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PodTemplateSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.PodSpec.toObject(message.spec, options);
                            return object;
                        };

                        /**
                         * Converts this PodTemplateSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PodTemplateSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PodTemplateSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PodTemplateSpec;
                    })();

                    v1.PortStatus = (function() {

                        /**
                         * Properties of a PortStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPortStatus
                         * @property {number|null} [port] PortStatus port
                         * @property {string|null} [protocol] PortStatus protocol
                         * @property {string|null} [error] PortStatus error
                         */

                        /**
                         * Constructs a new PortStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PortStatus.
                         * @implements IPortStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPortStatus=} [properties] Properties to set
                         */
                        function PortStatus(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PortStatus port.
                         * @member {number} port
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @instance
                         */
                        PortStatus.prototype.port = 0;

                        /**
                         * PortStatus protocol.
                         * @member {string} protocol
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @instance
                         */
                        PortStatus.prototype.protocol = "";

                        /**
                         * PortStatus error.
                         * @member {string} error
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @instance
                         */
                        PortStatus.prototype.error = "";

                        /**
                         * Creates a new PortStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPortStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PortStatus} PortStatus instance
                         */
                        PortStatus.create = function create(properties) {
                            return new PortStatus(properties);
                        };

                        /**
                         * Encodes the specified PortStatus message. Does not implicitly {@link k8s.io.api.core.v1.PortStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPortStatus} message PortStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PortStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.port);
                            if (message.protocol != null && Object.hasOwnProperty.call(message, "protocol"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.protocol);
                            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
                            return writer;
                        };

                        /**
                         * Encodes the specified PortStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PortStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IPortStatus} message PortStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PortStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PortStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PortStatus} PortStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PortStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PortStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.port = reader.int32();
                                    break;
                                case 2:
                                    message.protocol = reader.string();
                                    break;
                                case 3:
                                    message.error = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PortStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PortStatus} PortStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PortStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PortStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PortStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.port != null && message.hasOwnProperty("port"))
                                if (!$util.isInteger(message.port))
                                    return "port: integer expected";
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                if (!$util.isString(message.protocol))
                                    return "protocol: string expected";
                            if (message.error != null && message.hasOwnProperty("error"))
                                if (!$util.isString(message.error))
                                    return "error: string expected";
                            return null;
                        };

                        /**
                         * Creates a PortStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PortStatus} PortStatus
                         */
                        PortStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PortStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PortStatus();
                            if (object.port != null)
                                message.port = object.port | 0;
                            if (object.protocol != null)
                                message.protocol = String(object.protocol);
                            if (object.error != null)
                                message.error = String(object.error);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PortStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @static
                         * @param {k8s.io.api.core.v1.PortStatus} message PortStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PortStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.port = 0;
                                object.protocol = "";
                                object.error = "";
                            }
                            if (message.port != null && message.hasOwnProperty("port"))
                                object.port = message.port;
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                object.protocol = message.protocol;
                            if (message.error != null && message.hasOwnProperty("error"))
                                object.error = message.error;
                            return object;
                        };

                        /**
                         * Converts this PortStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PortStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PortStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PortStatus;
                    })();

                    v1.PortworxVolumeSource = (function() {

                        /**
                         * Properties of a PortworxVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPortworxVolumeSource
                         * @property {string|null} [volumeID] PortworxVolumeSource volumeID
                         * @property {string|null} [fsType] PortworxVolumeSource fsType
                         * @property {boolean|null} [readOnly] PortworxVolumeSource readOnly
                         */

                        /**
                         * Constructs a new PortworxVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PortworxVolumeSource.
                         * @implements IPortworxVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPortworxVolumeSource=} [properties] Properties to set
                         */
                        function PortworxVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PortworxVolumeSource volumeID.
                         * @member {string} volumeID
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @instance
                         */
                        PortworxVolumeSource.prototype.volumeID = "";

                        /**
                         * PortworxVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @instance
                         */
                        PortworxVolumeSource.prototype.fsType = "";

                        /**
                         * PortworxVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @instance
                         */
                        PortworxVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new PortworxVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPortworxVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PortworxVolumeSource} PortworxVolumeSource instance
                         */
                        PortworxVolumeSource.create = function create(properties) {
                            return new PortworxVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified PortworxVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.PortworxVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPortworxVolumeSource} message PortworxVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PortworxVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeID != null && Object.hasOwnProperty.call(message, "volumeID"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumeID);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified PortworxVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PortworxVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IPortworxVolumeSource} message PortworxVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PortworxVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PortworxVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PortworxVolumeSource} PortworxVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PortworxVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PortworxVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeID = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PortworxVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PortworxVolumeSource} PortworxVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PortworxVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PortworxVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PortworxVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                if (!$util.isString(message.volumeID))
                                    return "volumeID: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a PortworxVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PortworxVolumeSource} PortworxVolumeSource
                         */
                        PortworxVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PortworxVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PortworxVolumeSource();
                            if (object.volumeID != null)
                                message.volumeID = String(object.volumeID);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PortworxVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.PortworxVolumeSource} message PortworxVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PortworxVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeID = "";
                                object.fsType = "";
                                object.readOnly = false;
                            }
                            if (message.volumeID != null && message.hasOwnProperty("volumeID"))
                                object.volumeID = message.volumeID;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this PortworxVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PortworxVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PortworxVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PortworxVolumeSource;
                    })();

                    v1.Preconditions = (function() {

                        /**
                         * Properties of a Preconditions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPreconditions
                         * @property {string|null} [uid] Preconditions uid
                         */

                        /**
                         * Constructs a new Preconditions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Preconditions.
                         * @implements IPreconditions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPreconditions=} [properties] Properties to set
                         */
                        function Preconditions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Preconditions uid.
                         * @member {string} uid
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @instance
                         */
                        Preconditions.prototype.uid = "";

                        /**
                         * Creates a new Preconditions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {k8s.io.api.core.v1.IPreconditions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Preconditions} Preconditions instance
                         */
                        Preconditions.create = function create(properties) {
                            return new Preconditions(properties);
                        };

                        /**
                         * Encodes the specified Preconditions message. Does not implicitly {@link k8s.io.api.core.v1.Preconditions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {k8s.io.api.core.v1.IPreconditions} message Preconditions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Preconditions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
                            return writer;
                        };

                        /**
                         * Encodes the specified Preconditions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Preconditions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {k8s.io.api.core.v1.IPreconditions} message Preconditions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Preconditions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Preconditions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Preconditions} Preconditions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Preconditions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Preconditions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.uid = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Preconditions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Preconditions} Preconditions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Preconditions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Preconditions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Preconditions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.uid != null && message.hasOwnProperty("uid"))
                                if (!$util.isString(message.uid))
                                    return "uid: string expected";
                            return null;
                        };

                        /**
                         * Creates a Preconditions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Preconditions} Preconditions
                         */
                        Preconditions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Preconditions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Preconditions();
                            if (object.uid != null)
                                message.uid = String(object.uid);
                            return message;
                        };

                        /**
                         * Creates a plain object from a Preconditions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @static
                         * @param {k8s.io.api.core.v1.Preconditions} message Preconditions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Preconditions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.uid = "";
                            if (message.uid != null && message.hasOwnProperty("uid"))
                                object.uid = message.uid;
                            return object;
                        };

                        /**
                         * Converts this Preconditions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Preconditions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Preconditions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Preconditions;
                    })();

                    v1.PreferAvoidPodsEntry = (function() {

                        /**
                         * Properties of a PreferAvoidPodsEntry.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPreferAvoidPodsEntry
                         * @property {k8s.io.api.core.v1.IPodSignature|null} [podSignature] PreferAvoidPodsEntry podSignature
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [evictionTime] PreferAvoidPodsEntry evictionTime
                         * @property {string|null} [reason] PreferAvoidPodsEntry reason
                         * @property {string|null} [message] PreferAvoidPodsEntry message
                         */

                        /**
                         * Constructs a new PreferAvoidPodsEntry.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PreferAvoidPodsEntry.
                         * @implements IPreferAvoidPodsEntry
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPreferAvoidPodsEntry=} [properties] Properties to set
                         */
                        function PreferAvoidPodsEntry(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PreferAvoidPodsEntry podSignature.
                         * @member {k8s.io.api.core.v1.IPodSignature|null|undefined} podSignature
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @instance
                         */
                        PreferAvoidPodsEntry.prototype.podSignature = null;

                        /**
                         * PreferAvoidPodsEntry evictionTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} evictionTime
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @instance
                         */
                        PreferAvoidPodsEntry.prototype.evictionTime = null;

                        /**
                         * PreferAvoidPodsEntry reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @instance
                         */
                        PreferAvoidPodsEntry.prototype.reason = "";

                        /**
                         * PreferAvoidPodsEntry message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @instance
                         */
                        PreferAvoidPodsEntry.prototype.message = "";

                        /**
                         * Creates a new PreferAvoidPodsEntry instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {k8s.io.api.core.v1.IPreferAvoidPodsEntry=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PreferAvoidPodsEntry} PreferAvoidPodsEntry instance
                         */
                        PreferAvoidPodsEntry.create = function create(properties) {
                            return new PreferAvoidPodsEntry(properties);
                        };

                        /**
                         * Encodes the specified PreferAvoidPodsEntry message. Does not implicitly {@link k8s.io.api.core.v1.PreferAvoidPodsEntry.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {k8s.io.api.core.v1.IPreferAvoidPodsEntry} message PreferAvoidPodsEntry message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PreferAvoidPodsEntry.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.podSignature != null && Object.hasOwnProperty.call(message, "podSignature"))
                                $root.k8s.io.api.core.v1.PodSignature.encode(message.podSignature, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.evictionTime != null && Object.hasOwnProperty.call(message, "evictionTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.evictionTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified PreferAvoidPodsEntry message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PreferAvoidPodsEntry.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {k8s.io.api.core.v1.IPreferAvoidPodsEntry} message PreferAvoidPodsEntry message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PreferAvoidPodsEntry.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PreferAvoidPodsEntry message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PreferAvoidPodsEntry} PreferAvoidPodsEntry
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PreferAvoidPodsEntry.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PreferAvoidPodsEntry();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.podSignature = $root.k8s.io.api.core.v1.PodSignature.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.evictionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.reason = reader.string();
                                    break;
                                case 4:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PreferAvoidPodsEntry message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PreferAvoidPodsEntry} PreferAvoidPodsEntry
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PreferAvoidPodsEntry.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PreferAvoidPodsEntry message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PreferAvoidPodsEntry.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.podSignature != null && message.hasOwnProperty("podSignature")) {
                                var error = $root.k8s.io.api.core.v1.PodSignature.verify(message.podSignature);
                                if (error)
                                    return "podSignature." + error;
                            }
                            if (message.evictionTime != null && message.hasOwnProperty("evictionTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.evictionTime);
                                if (error)
                                    return "evictionTime." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a PreferAvoidPodsEntry message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PreferAvoidPodsEntry} PreferAvoidPodsEntry
                         */
                        PreferAvoidPodsEntry.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PreferAvoidPodsEntry)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PreferAvoidPodsEntry();
                            if (object.podSignature != null) {
                                if (typeof object.podSignature !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PreferAvoidPodsEntry.podSignature: object expected");
                                message.podSignature = $root.k8s.io.api.core.v1.PodSignature.fromObject(object.podSignature);
                            }
                            if (object.evictionTime != null) {
                                if (typeof object.evictionTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PreferAvoidPodsEntry.evictionTime: object expected");
                                message.evictionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.evictionTime);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PreferAvoidPodsEntry message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @static
                         * @param {k8s.io.api.core.v1.PreferAvoidPodsEntry} message PreferAvoidPodsEntry
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PreferAvoidPodsEntry.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.podSignature = null;
                                object.evictionTime = null;
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.podSignature != null && message.hasOwnProperty("podSignature"))
                                object.podSignature = $root.k8s.io.api.core.v1.PodSignature.toObject(message.podSignature, options);
                            if (message.evictionTime != null && message.hasOwnProperty("evictionTime"))
                                object.evictionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.evictionTime, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this PreferAvoidPodsEntry to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PreferAvoidPodsEntry
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PreferAvoidPodsEntry.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PreferAvoidPodsEntry;
                    })();

                    v1.PreferredSchedulingTerm = (function() {

                        /**
                         * Properties of a PreferredSchedulingTerm.
                         * @memberof k8s.io.api.core.v1
                         * @interface IPreferredSchedulingTerm
                         * @property {number|null} [weight] PreferredSchedulingTerm weight
                         * @property {k8s.io.api.core.v1.INodeSelectorTerm|null} [preference] PreferredSchedulingTerm preference
                         */

                        /**
                         * Constructs a new PreferredSchedulingTerm.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a PreferredSchedulingTerm.
                         * @implements IPreferredSchedulingTerm
                         * @constructor
                         * @param {k8s.io.api.core.v1.IPreferredSchedulingTerm=} [properties] Properties to set
                         */
                        function PreferredSchedulingTerm(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PreferredSchedulingTerm weight.
                         * @member {number} weight
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @instance
                         */
                        PreferredSchedulingTerm.prototype.weight = 0;

                        /**
                         * PreferredSchedulingTerm preference.
                         * @member {k8s.io.api.core.v1.INodeSelectorTerm|null|undefined} preference
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @instance
                         */
                        PreferredSchedulingTerm.prototype.preference = null;

                        /**
                         * Creates a new PreferredSchedulingTerm instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IPreferredSchedulingTerm=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.PreferredSchedulingTerm} PreferredSchedulingTerm instance
                         */
                        PreferredSchedulingTerm.create = function create(properties) {
                            return new PreferredSchedulingTerm(properties);
                        };

                        /**
                         * Encodes the specified PreferredSchedulingTerm message. Does not implicitly {@link k8s.io.api.core.v1.PreferredSchedulingTerm.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IPreferredSchedulingTerm} message PreferredSchedulingTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PreferredSchedulingTerm.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.weight);
                            if (message.preference != null && Object.hasOwnProperty.call(message, "preference"))
                                $root.k8s.io.api.core.v1.NodeSelectorTerm.encode(message.preference, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified PreferredSchedulingTerm message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.PreferredSchedulingTerm.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IPreferredSchedulingTerm} message PreferredSchedulingTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PreferredSchedulingTerm.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PreferredSchedulingTerm message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.PreferredSchedulingTerm} PreferredSchedulingTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PreferredSchedulingTerm.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.PreferredSchedulingTerm();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.weight = reader.int32();
                                    break;
                                case 2:
                                    message.preference = $root.k8s.io.api.core.v1.NodeSelectorTerm.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PreferredSchedulingTerm message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.PreferredSchedulingTerm} PreferredSchedulingTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PreferredSchedulingTerm.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PreferredSchedulingTerm message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PreferredSchedulingTerm.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.weight != null && message.hasOwnProperty("weight"))
                                if (!$util.isInteger(message.weight))
                                    return "weight: integer expected";
                            if (message.preference != null && message.hasOwnProperty("preference")) {
                                var error = $root.k8s.io.api.core.v1.NodeSelectorTerm.verify(message.preference);
                                if (error)
                                    return "preference." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a PreferredSchedulingTerm message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.PreferredSchedulingTerm} PreferredSchedulingTerm
                         */
                        PreferredSchedulingTerm.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.PreferredSchedulingTerm)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.PreferredSchedulingTerm();
                            if (object.weight != null)
                                message.weight = object.weight | 0;
                            if (object.preference != null) {
                                if (typeof object.preference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.PreferredSchedulingTerm.preference: object expected");
                                message.preference = $root.k8s.io.api.core.v1.NodeSelectorTerm.fromObject(object.preference);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a PreferredSchedulingTerm message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @static
                         * @param {k8s.io.api.core.v1.PreferredSchedulingTerm} message PreferredSchedulingTerm
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PreferredSchedulingTerm.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.weight = 0;
                                object.preference = null;
                            }
                            if (message.weight != null && message.hasOwnProperty("weight"))
                                object.weight = message.weight;
                            if (message.preference != null && message.hasOwnProperty("preference"))
                                object.preference = $root.k8s.io.api.core.v1.NodeSelectorTerm.toObject(message.preference, options);
                            return object;
                        };

                        /**
                         * Converts this PreferredSchedulingTerm to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.PreferredSchedulingTerm
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PreferredSchedulingTerm.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PreferredSchedulingTerm;
                    })();

                    v1.Probe = (function() {

                        /**
                         * Properties of a Probe.
                         * @memberof k8s.io.api.core.v1
                         * @interface IProbe
                         * @property {k8s.io.api.core.v1.IHandler|null} [handler] Probe handler
                         * @property {number|null} [initialDelaySeconds] Probe initialDelaySeconds
                         * @property {number|null} [timeoutSeconds] Probe timeoutSeconds
                         * @property {number|null} [periodSeconds] Probe periodSeconds
                         * @property {number|null} [successThreshold] Probe successThreshold
                         * @property {number|null} [failureThreshold] Probe failureThreshold
                         */

                        /**
                         * Constructs a new Probe.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Probe.
                         * @implements IProbe
                         * @constructor
                         * @param {k8s.io.api.core.v1.IProbe=} [properties] Properties to set
                         */
                        function Probe(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Probe handler.
                         * @member {k8s.io.api.core.v1.IHandler|null|undefined} handler
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         */
                        Probe.prototype.handler = null;

                        /**
                         * Probe initialDelaySeconds.
                         * @member {number} initialDelaySeconds
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         */
                        Probe.prototype.initialDelaySeconds = 0;

                        /**
                         * Probe timeoutSeconds.
                         * @member {number} timeoutSeconds
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         */
                        Probe.prototype.timeoutSeconds = 0;

                        /**
                         * Probe periodSeconds.
                         * @member {number} periodSeconds
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         */
                        Probe.prototype.periodSeconds = 0;

                        /**
                         * Probe successThreshold.
                         * @member {number} successThreshold
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         */
                        Probe.prototype.successThreshold = 0;

                        /**
                         * Probe failureThreshold.
                         * @member {number} failureThreshold
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         */
                        Probe.prototype.failureThreshold = 0;

                        /**
                         * Creates a new Probe instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {k8s.io.api.core.v1.IProbe=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Probe} Probe instance
                         */
                        Probe.create = function create(properties) {
                            return new Probe(properties);
                        };

                        /**
                         * Encodes the specified Probe message. Does not implicitly {@link k8s.io.api.core.v1.Probe.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {k8s.io.api.core.v1.IProbe} message Probe message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Probe.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.handler != null && Object.hasOwnProperty.call(message, "handler"))
                                $root.k8s.io.api.core.v1.Handler.encode(message.handler, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.initialDelaySeconds != null && Object.hasOwnProperty.call(message, "initialDelaySeconds"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.initialDelaySeconds);
                            if (message.timeoutSeconds != null && Object.hasOwnProperty.call(message, "timeoutSeconds"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.timeoutSeconds);
                            if (message.periodSeconds != null && Object.hasOwnProperty.call(message, "periodSeconds"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.periodSeconds);
                            if (message.successThreshold != null && Object.hasOwnProperty.call(message, "successThreshold"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.successThreshold);
                            if (message.failureThreshold != null && Object.hasOwnProperty.call(message, "failureThreshold"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.failureThreshold);
                            return writer;
                        };

                        /**
                         * Encodes the specified Probe message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Probe.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {k8s.io.api.core.v1.IProbe} message Probe message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Probe.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Probe message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Probe} Probe
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Probe.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Probe();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.handler = $root.k8s.io.api.core.v1.Handler.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.initialDelaySeconds = reader.int32();
                                    break;
                                case 3:
                                    message.timeoutSeconds = reader.int32();
                                    break;
                                case 4:
                                    message.periodSeconds = reader.int32();
                                    break;
                                case 5:
                                    message.successThreshold = reader.int32();
                                    break;
                                case 6:
                                    message.failureThreshold = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Probe message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Probe} Probe
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Probe.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Probe message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Probe.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.handler != null && message.hasOwnProperty("handler")) {
                                var error = $root.k8s.io.api.core.v1.Handler.verify(message.handler);
                                if (error)
                                    return "handler." + error;
                            }
                            if (message.initialDelaySeconds != null && message.hasOwnProperty("initialDelaySeconds"))
                                if (!$util.isInteger(message.initialDelaySeconds))
                                    return "initialDelaySeconds: integer expected";
                            if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                                if (!$util.isInteger(message.timeoutSeconds))
                                    return "timeoutSeconds: integer expected";
                            if (message.periodSeconds != null && message.hasOwnProperty("periodSeconds"))
                                if (!$util.isInteger(message.periodSeconds))
                                    return "periodSeconds: integer expected";
                            if (message.successThreshold != null && message.hasOwnProperty("successThreshold"))
                                if (!$util.isInteger(message.successThreshold))
                                    return "successThreshold: integer expected";
                            if (message.failureThreshold != null && message.hasOwnProperty("failureThreshold"))
                                if (!$util.isInteger(message.failureThreshold))
                                    return "failureThreshold: integer expected";
                            return null;
                        };

                        /**
                         * Creates a Probe message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Probe} Probe
                         */
                        Probe.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Probe)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Probe();
                            if (object.handler != null) {
                                if (typeof object.handler !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Probe.handler: object expected");
                                message.handler = $root.k8s.io.api.core.v1.Handler.fromObject(object.handler);
                            }
                            if (object.initialDelaySeconds != null)
                                message.initialDelaySeconds = object.initialDelaySeconds | 0;
                            if (object.timeoutSeconds != null)
                                message.timeoutSeconds = object.timeoutSeconds | 0;
                            if (object.periodSeconds != null)
                                message.periodSeconds = object.periodSeconds | 0;
                            if (object.successThreshold != null)
                                message.successThreshold = object.successThreshold | 0;
                            if (object.failureThreshold != null)
                                message.failureThreshold = object.failureThreshold | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a Probe message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Probe
                         * @static
                         * @param {k8s.io.api.core.v1.Probe} message Probe
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Probe.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.handler = null;
                                object.initialDelaySeconds = 0;
                                object.timeoutSeconds = 0;
                                object.periodSeconds = 0;
                                object.successThreshold = 0;
                                object.failureThreshold = 0;
                            }
                            if (message.handler != null && message.hasOwnProperty("handler"))
                                object.handler = $root.k8s.io.api.core.v1.Handler.toObject(message.handler, options);
                            if (message.initialDelaySeconds != null && message.hasOwnProperty("initialDelaySeconds"))
                                object.initialDelaySeconds = message.initialDelaySeconds;
                            if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                                object.timeoutSeconds = message.timeoutSeconds;
                            if (message.periodSeconds != null && message.hasOwnProperty("periodSeconds"))
                                object.periodSeconds = message.periodSeconds;
                            if (message.successThreshold != null && message.hasOwnProperty("successThreshold"))
                                object.successThreshold = message.successThreshold;
                            if (message.failureThreshold != null && message.hasOwnProperty("failureThreshold"))
                                object.failureThreshold = message.failureThreshold;
                            return object;
                        };

                        /**
                         * Converts this Probe to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Probe
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Probe.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Probe;
                    })();

                    v1.ProjectedVolumeSource = (function() {

                        /**
                         * Properties of a ProjectedVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IProjectedVolumeSource
                         * @property {Array.<k8s.io.api.core.v1.IVolumeProjection>|null} [sources] ProjectedVolumeSource sources
                         * @property {number|null} [defaultMode] ProjectedVolumeSource defaultMode
                         */

                        /**
                         * Constructs a new ProjectedVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ProjectedVolumeSource.
                         * @implements IProjectedVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IProjectedVolumeSource=} [properties] Properties to set
                         */
                        function ProjectedVolumeSource(properties) {
                            this.sources = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ProjectedVolumeSource sources.
                         * @member {Array.<k8s.io.api.core.v1.IVolumeProjection>} sources
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @instance
                         */
                        ProjectedVolumeSource.prototype.sources = $util.emptyArray;

                        /**
                         * ProjectedVolumeSource defaultMode.
                         * @member {number} defaultMode
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @instance
                         */
                        ProjectedVolumeSource.prototype.defaultMode = 0;

                        /**
                         * Creates a new ProjectedVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IProjectedVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ProjectedVolumeSource} ProjectedVolumeSource instance
                         */
                        ProjectedVolumeSource.create = function create(properties) {
                            return new ProjectedVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified ProjectedVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.ProjectedVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IProjectedVolumeSource} message ProjectedVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ProjectedVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.sources != null && message.sources.length)
                                for (var i = 0; i < message.sources.length; ++i)
                                    $root.k8s.io.api.core.v1.VolumeProjection.encode(message.sources[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.defaultMode != null && Object.hasOwnProperty.call(message, "defaultMode"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.defaultMode);
                            return writer;
                        };

                        /**
                         * Encodes the specified ProjectedVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ProjectedVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IProjectedVolumeSource} message ProjectedVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ProjectedVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ProjectedVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ProjectedVolumeSource} ProjectedVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ProjectedVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ProjectedVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.sources && message.sources.length))
                                        message.sources = [];
                                    message.sources.push($root.k8s.io.api.core.v1.VolumeProjection.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    message.defaultMode = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ProjectedVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ProjectedVolumeSource} ProjectedVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ProjectedVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ProjectedVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ProjectedVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.sources != null && message.hasOwnProperty("sources")) {
                                if (!Array.isArray(message.sources))
                                    return "sources: array expected";
                                for (var i = 0; i < message.sources.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.VolumeProjection.verify(message.sources[i]);
                                    if (error)
                                        return "sources." + error;
                                }
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                if (!$util.isInteger(message.defaultMode))
                                    return "defaultMode: integer expected";
                            return null;
                        };

                        /**
                         * Creates a ProjectedVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ProjectedVolumeSource} ProjectedVolumeSource
                         */
                        ProjectedVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ProjectedVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ProjectedVolumeSource();
                            if (object.sources) {
                                if (!Array.isArray(object.sources))
                                    throw TypeError(".k8s.io.api.core.v1.ProjectedVolumeSource.sources: array expected");
                                message.sources = [];
                                for (var i = 0; i < object.sources.length; ++i) {
                                    if (typeof object.sources[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ProjectedVolumeSource.sources: object expected");
                                    message.sources[i] = $root.k8s.io.api.core.v1.VolumeProjection.fromObject(object.sources[i]);
                                }
                            }
                            if (object.defaultMode != null)
                                message.defaultMode = object.defaultMode | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a ProjectedVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ProjectedVolumeSource} message ProjectedVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ProjectedVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.sources = [];
                            if (options.defaults)
                                object.defaultMode = 0;
                            if (message.sources && message.sources.length) {
                                object.sources = [];
                                for (var j = 0; j < message.sources.length; ++j)
                                    object.sources[j] = $root.k8s.io.api.core.v1.VolumeProjection.toObject(message.sources[j], options);
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                object.defaultMode = message.defaultMode;
                            return object;
                        };

                        /**
                         * Converts this ProjectedVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ProjectedVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ProjectedVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ProjectedVolumeSource;
                    })();

                    v1.QuobyteVolumeSource = (function() {

                        /**
                         * Properties of a QuobyteVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IQuobyteVolumeSource
                         * @property {string|null} [registry] QuobyteVolumeSource registry
                         * @property {string|null} [volume] QuobyteVolumeSource volume
                         * @property {boolean|null} [readOnly] QuobyteVolumeSource readOnly
                         * @property {string|null} [user] QuobyteVolumeSource user
                         * @property {string|null} [group] QuobyteVolumeSource group
                         * @property {string|null} [tenant] QuobyteVolumeSource tenant
                         */

                        /**
                         * Constructs a new QuobyteVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a QuobyteVolumeSource.
                         * @implements IQuobyteVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IQuobyteVolumeSource=} [properties] Properties to set
                         */
                        function QuobyteVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * QuobyteVolumeSource registry.
                         * @member {string} registry
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         */
                        QuobyteVolumeSource.prototype.registry = "";

                        /**
                         * QuobyteVolumeSource volume.
                         * @member {string} volume
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         */
                        QuobyteVolumeSource.prototype.volume = "";

                        /**
                         * QuobyteVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         */
                        QuobyteVolumeSource.prototype.readOnly = false;

                        /**
                         * QuobyteVolumeSource user.
                         * @member {string} user
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         */
                        QuobyteVolumeSource.prototype.user = "";

                        /**
                         * QuobyteVolumeSource group.
                         * @member {string} group
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         */
                        QuobyteVolumeSource.prototype.group = "";

                        /**
                         * QuobyteVolumeSource tenant.
                         * @member {string} tenant
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         */
                        QuobyteVolumeSource.prototype.tenant = "";

                        /**
                         * Creates a new QuobyteVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IQuobyteVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.QuobyteVolumeSource} QuobyteVolumeSource instance
                         */
                        QuobyteVolumeSource.create = function create(properties) {
                            return new QuobyteVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified QuobyteVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.QuobyteVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IQuobyteVolumeSource} message QuobyteVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        QuobyteVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.registry != null && Object.hasOwnProperty.call(message, "registry"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.registry);
                            if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.volume);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnly);
                            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.user);
                            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.group);
                            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.tenant);
                            return writer;
                        };

                        /**
                         * Encodes the specified QuobyteVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.QuobyteVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IQuobyteVolumeSource} message QuobyteVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        QuobyteVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a QuobyteVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.QuobyteVolumeSource} QuobyteVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        QuobyteVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.QuobyteVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.registry = reader.string();
                                    break;
                                case 2:
                                    message.volume = reader.string();
                                    break;
                                case 3:
                                    message.readOnly = reader.bool();
                                    break;
                                case 4:
                                    message.user = reader.string();
                                    break;
                                case 5:
                                    message.group = reader.string();
                                    break;
                                case 6:
                                    message.tenant = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a QuobyteVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.QuobyteVolumeSource} QuobyteVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        QuobyteVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a QuobyteVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        QuobyteVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.registry != null && message.hasOwnProperty("registry"))
                                if (!$util.isString(message.registry))
                                    return "registry: string expected";
                            if (message.volume != null && message.hasOwnProperty("volume"))
                                if (!$util.isString(message.volume))
                                    return "volume: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.user != null && message.hasOwnProperty("user"))
                                if (!$util.isString(message.user))
                                    return "user: string expected";
                            if (message.group != null && message.hasOwnProperty("group"))
                                if (!$util.isString(message.group))
                                    return "group: string expected";
                            if (message.tenant != null && message.hasOwnProperty("tenant"))
                                if (!$util.isString(message.tenant))
                                    return "tenant: string expected";
                            return null;
                        };

                        /**
                         * Creates a QuobyteVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.QuobyteVolumeSource} QuobyteVolumeSource
                         */
                        QuobyteVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.QuobyteVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.QuobyteVolumeSource();
                            if (object.registry != null)
                                message.registry = String(object.registry);
                            if (object.volume != null)
                                message.volume = String(object.volume);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.user != null)
                                message.user = String(object.user);
                            if (object.group != null)
                                message.group = String(object.group);
                            if (object.tenant != null)
                                message.tenant = String(object.tenant);
                            return message;
                        };

                        /**
                         * Creates a plain object from a QuobyteVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.QuobyteVolumeSource} message QuobyteVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        QuobyteVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.registry = "";
                                object.volume = "";
                                object.readOnly = false;
                                object.user = "";
                                object.group = "";
                                object.tenant = "";
                            }
                            if (message.registry != null && message.hasOwnProperty("registry"))
                                object.registry = message.registry;
                            if (message.volume != null && message.hasOwnProperty("volume"))
                                object.volume = message.volume;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.user != null && message.hasOwnProperty("user"))
                                object.user = message.user;
                            if (message.group != null && message.hasOwnProperty("group"))
                                object.group = message.group;
                            if (message.tenant != null && message.hasOwnProperty("tenant"))
                                object.tenant = message.tenant;
                            return object;
                        };

                        /**
                         * Converts this QuobyteVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.QuobyteVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        QuobyteVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return QuobyteVolumeSource;
                    })();

                    v1.RBDPersistentVolumeSource = (function() {

                        /**
                         * Properties of a RBDPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IRBDPersistentVolumeSource
                         * @property {Array.<string>|null} [monitors] RBDPersistentVolumeSource monitors
                         * @property {string|null} [image] RBDPersistentVolumeSource image
                         * @property {string|null} [fsType] RBDPersistentVolumeSource fsType
                         * @property {string|null} [pool] RBDPersistentVolumeSource pool
                         * @property {string|null} [user] RBDPersistentVolumeSource user
                         * @property {string|null} [keyring] RBDPersistentVolumeSource keyring
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [secretRef] RBDPersistentVolumeSource secretRef
                         * @property {boolean|null} [readOnly] RBDPersistentVolumeSource readOnly
                         */

                        /**
                         * Constructs a new RBDPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a RBDPersistentVolumeSource.
                         * @implements IRBDPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IRBDPersistentVolumeSource=} [properties] Properties to set
                         */
                        function RBDPersistentVolumeSource(properties) {
                            this.monitors = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RBDPersistentVolumeSource monitors.
                         * @member {Array.<string>} monitors
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.monitors = $util.emptyArray;

                        /**
                         * RBDPersistentVolumeSource image.
                         * @member {string} image
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.image = "";

                        /**
                         * RBDPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * RBDPersistentVolumeSource pool.
                         * @member {string} pool
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.pool = "";

                        /**
                         * RBDPersistentVolumeSource user.
                         * @member {string} user
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.user = "";

                        /**
                         * RBDPersistentVolumeSource keyring.
                         * @member {string} keyring
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.keyring = "";

                        /**
                         * RBDPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * RBDPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         */
                        RBDPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new RBDPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IRBDPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.RBDPersistentVolumeSource} RBDPersistentVolumeSource instance
                         */
                        RBDPersistentVolumeSource.create = function create(properties) {
                            return new RBDPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified RBDPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.RBDPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IRBDPersistentVolumeSource} message RBDPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RBDPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.monitors != null && message.monitors.length)
                                for (var i = 0; i < message.monitors.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.monitors[i]);
                            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.image);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fsType);
                            if (message.pool != null && Object.hasOwnProperty.call(message, "pool"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.pool);
                            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.user);
                            if (message.keyring != null && Object.hasOwnProperty.call(message, "keyring"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.keyring);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.secretRef, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified RBDPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.RBDPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IRBDPersistentVolumeSource} message RBDPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RBDPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a RBDPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.RBDPersistentVolumeSource} RBDPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RBDPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.RBDPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.monitors && message.monitors.length))
                                        message.monitors = [];
                                    message.monitors.push(reader.string());
                                    break;
                                case 2:
                                    message.image = reader.string();
                                    break;
                                case 3:
                                    message.fsType = reader.string();
                                    break;
                                case 4:
                                    message.pool = reader.string();
                                    break;
                                case 5:
                                    message.user = reader.string();
                                    break;
                                case 6:
                                    message.keyring = reader.string();
                                    break;
                                case 7:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a RBDPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.RBDPersistentVolumeSource} RBDPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RBDPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a RBDPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RBDPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.monitors != null && message.hasOwnProperty("monitors")) {
                                if (!Array.isArray(message.monitors))
                                    return "monitors: array expected";
                                for (var i = 0; i < message.monitors.length; ++i)
                                    if (!$util.isString(message.monitors[i]))
                                        return "monitors: string[] expected";
                            }
                            if (message.image != null && message.hasOwnProperty("image"))
                                if (!$util.isString(message.image))
                                    return "image: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.pool != null && message.hasOwnProperty("pool"))
                                if (!$util.isString(message.pool))
                                    return "pool: string expected";
                            if (message.user != null && message.hasOwnProperty("user"))
                                if (!$util.isString(message.user))
                                    return "user: string expected";
                            if (message.keyring != null && message.hasOwnProperty("keyring"))
                                if (!$util.isString(message.keyring))
                                    return "keyring: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a RBDPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.RBDPersistentVolumeSource} RBDPersistentVolumeSource
                         */
                        RBDPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.RBDPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.RBDPersistentVolumeSource();
                            if (object.monitors) {
                                if (!Array.isArray(object.monitors))
                                    throw TypeError(".k8s.io.api.core.v1.RBDPersistentVolumeSource.monitors: array expected");
                                message.monitors = [];
                                for (var i = 0; i < object.monitors.length; ++i)
                                    message.monitors[i] = String(object.monitors[i]);
                            }
                            if (object.image != null)
                                message.image = String(object.image);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.pool != null)
                                message.pool = String(object.pool);
                            if (object.user != null)
                                message.user = String(object.user);
                            if (object.keyring != null)
                                message.keyring = String(object.keyring);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.RBDPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.secretRef);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a RBDPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.RBDPersistentVolumeSource} message RBDPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RBDPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.monitors = [];
                            if (options.defaults) {
                                object.image = "";
                                object.fsType = "";
                                object.pool = "";
                                object.user = "";
                                object.keyring = "";
                                object.secretRef = null;
                                object.readOnly = false;
                            }
                            if (message.monitors && message.monitors.length) {
                                object.monitors = [];
                                for (var j = 0; j < message.monitors.length; ++j)
                                    object.monitors[j] = message.monitors[j];
                            }
                            if (message.image != null && message.hasOwnProperty("image"))
                                object.image = message.image;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.pool != null && message.hasOwnProperty("pool"))
                                object.pool = message.pool;
                            if (message.user != null && message.hasOwnProperty("user"))
                                object.user = message.user;
                            if (message.keyring != null && message.hasOwnProperty("keyring"))
                                object.keyring = message.keyring;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.secretRef, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this RBDPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.RBDPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RBDPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return RBDPersistentVolumeSource;
                    })();

                    v1.RBDVolumeSource = (function() {

                        /**
                         * Properties of a RBDVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IRBDVolumeSource
                         * @property {Array.<string>|null} [monitors] RBDVolumeSource monitors
                         * @property {string|null} [image] RBDVolumeSource image
                         * @property {string|null} [fsType] RBDVolumeSource fsType
                         * @property {string|null} [pool] RBDVolumeSource pool
                         * @property {string|null} [user] RBDVolumeSource user
                         * @property {string|null} [keyring] RBDVolumeSource keyring
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] RBDVolumeSource secretRef
                         * @property {boolean|null} [readOnly] RBDVolumeSource readOnly
                         */

                        /**
                         * Constructs a new RBDVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a RBDVolumeSource.
                         * @implements IRBDVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IRBDVolumeSource=} [properties] Properties to set
                         */
                        function RBDVolumeSource(properties) {
                            this.monitors = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RBDVolumeSource monitors.
                         * @member {Array.<string>} monitors
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.monitors = $util.emptyArray;

                        /**
                         * RBDVolumeSource image.
                         * @member {string} image
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.image = "";

                        /**
                         * RBDVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.fsType = "";

                        /**
                         * RBDVolumeSource pool.
                         * @member {string} pool
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.pool = "";

                        /**
                         * RBDVolumeSource user.
                         * @member {string} user
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.user = "";

                        /**
                         * RBDVolumeSource keyring.
                         * @member {string} keyring
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.keyring = "";

                        /**
                         * RBDVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.secretRef = null;

                        /**
                         * RBDVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         */
                        RBDVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new RBDVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IRBDVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.RBDVolumeSource} RBDVolumeSource instance
                         */
                        RBDVolumeSource.create = function create(properties) {
                            return new RBDVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified RBDVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.RBDVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IRBDVolumeSource} message RBDVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RBDVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.monitors != null && message.monitors.length)
                                for (var i = 0; i < message.monitors.length; ++i)
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.monitors[i]);
                            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.image);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fsType);
                            if (message.pool != null && Object.hasOwnProperty.call(message, "pool"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.pool);
                            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.user);
                            if (message.keyring != null && Object.hasOwnProperty.call(message, "keyring"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.keyring);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified RBDVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.RBDVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IRBDVolumeSource} message RBDVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RBDVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a RBDVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.RBDVolumeSource} RBDVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RBDVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.RBDVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.monitors && message.monitors.length))
                                        message.monitors = [];
                                    message.monitors.push(reader.string());
                                    break;
                                case 2:
                                    message.image = reader.string();
                                    break;
                                case 3:
                                    message.fsType = reader.string();
                                    break;
                                case 4:
                                    message.pool = reader.string();
                                    break;
                                case 5:
                                    message.user = reader.string();
                                    break;
                                case 6:
                                    message.keyring = reader.string();
                                    break;
                                case 7:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a RBDVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.RBDVolumeSource} RBDVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RBDVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a RBDVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RBDVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.monitors != null && message.hasOwnProperty("monitors")) {
                                if (!Array.isArray(message.monitors))
                                    return "monitors: array expected";
                                for (var i = 0; i < message.monitors.length; ++i)
                                    if (!$util.isString(message.monitors[i]))
                                        return "monitors: string[] expected";
                            }
                            if (message.image != null && message.hasOwnProperty("image"))
                                if (!$util.isString(message.image))
                                    return "image: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.pool != null && message.hasOwnProperty("pool"))
                                if (!$util.isString(message.pool))
                                    return "pool: string expected";
                            if (message.user != null && message.hasOwnProperty("user"))
                                if (!$util.isString(message.user))
                                    return "user: string expected";
                            if (message.keyring != null && message.hasOwnProperty("keyring"))
                                if (!$util.isString(message.keyring))
                                    return "keyring: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a RBDVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.RBDVolumeSource} RBDVolumeSource
                         */
                        RBDVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.RBDVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.RBDVolumeSource();
                            if (object.monitors) {
                                if (!Array.isArray(object.monitors))
                                    throw TypeError(".k8s.io.api.core.v1.RBDVolumeSource.monitors: array expected");
                                message.monitors = [];
                                for (var i = 0; i < object.monitors.length; ++i)
                                    message.monitors[i] = String(object.monitors[i]);
                            }
                            if (object.image != null)
                                message.image = String(object.image);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.pool != null)
                                message.pool = String(object.pool);
                            if (object.user != null)
                                message.user = String(object.user);
                            if (object.keyring != null)
                                message.keyring = String(object.keyring);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.RBDVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a RBDVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.RBDVolumeSource} message RBDVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RBDVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.monitors = [];
                            if (options.defaults) {
                                object.image = "";
                                object.fsType = "";
                                object.pool = "";
                                object.user = "";
                                object.keyring = "";
                                object.secretRef = null;
                                object.readOnly = false;
                            }
                            if (message.monitors && message.monitors.length) {
                                object.monitors = [];
                                for (var j = 0; j < message.monitors.length; ++j)
                                    object.monitors[j] = message.monitors[j];
                            }
                            if (message.image != null && message.hasOwnProperty("image"))
                                object.image = message.image;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.pool != null && message.hasOwnProperty("pool"))
                                object.pool = message.pool;
                            if (message.user != null && message.hasOwnProperty("user"))
                                object.user = message.user;
                            if (message.keyring != null && message.hasOwnProperty("keyring"))
                                object.keyring = message.keyring;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this RBDVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.RBDVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RBDVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return RBDVolumeSource;
                    })();

                    v1.RangeAllocation = (function() {

                        /**
                         * Properties of a RangeAllocation.
                         * @memberof k8s.io.api.core.v1
                         * @interface IRangeAllocation
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] RangeAllocation metadata
                         * @property {string|null} [range] RangeAllocation range
                         * @property {Uint8Array|null} [data] RangeAllocation data
                         */

                        /**
                         * Constructs a new RangeAllocation.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a RangeAllocation.
                         * @implements IRangeAllocation
                         * @constructor
                         * @param {k8s.io.api.core.v1.IRangeAllocation=} [properties] Properties to set
                         */
                        function RangeAllocation(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RangeAllocation metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @instance
                         */
                        RangeAllocation.prototype.metadata = null;

                        /**
                         * RangeAllocation range.
                         * @member {string} range
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @instance
                         */
                        RangeAllocation.prototype.range = "";

                        /**
                         * RangeAllocation data.
                         * @member {Uint8Array} data
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @instance
                         */
                        RangeAllocation.prototype.data = $util.newBuffer([]);

                        /**
                         * Creates a new RangeAllocation instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {k8s.io.api.core.v1.IRangeAllocation=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.RangeAllocation} RangeAllocation instance
                         */
                        RangeAllocation.create = function create(properties) {
                            return new RangeAllocation(properties);
                        };

                        /**
                         * Encodes the specified RangeAllocation message. Does not implicitly {@link k8s.io.api.core.v1.RangeAllocation.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {k8s.io.api.core.v1.IRangeAllocation} message RangeAllocation message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RangeAllocation.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.range);
                            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
                            return writer;
                        };

                        /**
                         * Encodes the specified RangeAllocation message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.RangeAllocation.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {k8s.io.api.core.v1.IRangeAllocation} message RangeAllocation message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RangeAllocation.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a RangeAllocation message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.RangeAllocation} RangeAllocation
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RangeAllocation.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.RangeAllocation();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.range = reader.string();
                                    break;
                                case 3:
                                    message.data = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a RangeAllocation message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.RangeAllocation} RangeAllocation
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RangeAllocation.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a RangeAllocation message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RangeAllocation.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.range != null && message.hasOwnProperty("range"))
                                if (!$util.isString(message.range))
                                    return "range: string expected";
                            if (message.data != null && message.hasOwnProperty("data"))
                                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                                    return "data: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a RangeAllocation message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.RangeAllocation} RangeAllocation
                         */
                        RangeAllocation.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.RangeAllocation)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.RangeAllocation();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.RangeAllocation.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.range != null)
                                message.range = String(object.range);
                            if (object.data != null)
                                if (typeof object.data === "string")
                                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                                else if (object.data.length)
                                    message.data = object.data;
                            return message;
                        };

                        /**
                         * Creates a plain object from a RangeAllocation message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @static
                         * @param {k8s.io.api.core.v1.RangeAllocation} message RangeAllocation
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RangeAllocation.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.range = "";
                                if (options.bytes === String)
                                    object.data = "";
                                else {
                                    object.data = [];
                                    if (options.bytes !== Array)
                                        object.data = $util.newBuffer(object.data);
                                }
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.range != null && message.hasOwnProperty("range"))
                                object.range = message.range;
                            if (message.data != null && message.hasOwnProperty("data"))
                                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                            return object;
                        };

                        /**
                         * Converts this RangeAllocation to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.RangeAllocation
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RangeAllocation.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return RangeAllocation;
                    })();

                    v1.ReplicationController = (function() {

                        /**
                         * Properties of a ReplicationController.
                         * @memberof k8s.io.api.core.v1
                         * @interface IReplicationController
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] ReplicationController metadata
                         * @property {k8s.io.api.core.v1.IReplicationControllerSpec|null} [spec] ReplicationController spec
                         * @property {k8s.io.api.core.v1.IReplicationControllerStatus|null} [status] ReplicationController status
                         */

                        /**
                         * Constructs a new ReplicationController.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ReplicationController.
                         * @implements IReplicationController
                         * @constructor
                         * @param {k8s.io.api.core.v1.IReplicationController=} [properties] Properties to set
                         */
                        function ReplicationController(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ReplicationController metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @instance
                         */
                        ReplicationController.prototype.metadata = null;

                        /**
                         * ReplicationController spec.
                         * @member {k8s.io.api.core.v1.IReplicationControllerSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @instance
                         */
                        ReplicationController.prototype.spec = null;

                        /**
                         * ReplicationController status.
                         * @member {k8s.io.api.core.v1.IReplicationControllerStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @instance
                         */
                        ReplicationController.prototype.status = null;

                        /**
                         * Creates a new ReplicationController instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationController=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ReplicationController} ReplicationController instance
                         */
                        ReplicationController.create = function create(properties) {
                            return new ReplicationController(properties);
                        };

                        /**
                         * Encodes the specified ReplicationController message. Does not implicitly {@link k8s.io.api.core.v1.ReplicationController.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationController} message ReplicationController message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationController.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.ReplicationControllerSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.ReplicationControllerStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ReplicationController message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ReplicationController.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationController} message ReplicationController message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationController.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ReplicationController message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ReplicationController} ReplicationController
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationController.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ReplicationController();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.ReplicationControllerSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.ReplicationControllerStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ReplicationController message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ReplicationController} ReplicationController
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationController.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ReplicationController message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReplicationController.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.ReplicationControllerSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.ReplicationControllerStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ReplicationController message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ReplicationController} ReplicationController
                         */
                        ReplicationController.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ReplicationController)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ReplicationController();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationController.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationController.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.ReplicationControllerSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationController.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.ReplicationControllerStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ReplicationController message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @static
                         * @param {k8s.io.api.core.v1.ReplicationController} message ReplicationController
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReplicationController.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.ReplicationControllerSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.ReplicationControllerStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this ReplicationController to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ReplicationController
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReplicationController.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ReplicationController;
                    })();

                    v1.ReplicationControllerCondition = (function() {

                        /**
                         * Properties of a ReplicationControllerCondition.
                         * @memberof k8s.io.api.core.v1
                         * @interface IReplicationControllerCondition
                         * @property {string|null} [type] ReplicationControllerCondition type
                         * @property {string|null} [status] ReplicationControllerCondition status
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTransitionTime] ReplicationControllerCondition lastTransitionTime
                         * @property {string|null} [reason] ReplicationControllerCondition reason
                         * @property {string|null} [message] ReplicationControllerCondition message
                         */

                        /**
                         * Constructs a new ReplicationControllerCondition.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ReplicationControllerCondition.
                         * @implements IReplicationControllerCondition
                         * @constructor
                         * @param {k8s.io.api.core.v1.IReplicationControllerCondition=} [properties] Properties to set
                         */
                        function ReplicationControllerCondition(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ReplicationControllerCondition type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @instance
                         */
                        ReplicationControllerCondition.prototype.type = "";

                        /**
                         * ReplicationControllerCondition status.
                         * @member {string} status
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @instance
                         */
                        ReplicationControllerCondition.prototype.status = "";

                        /**
                         * ReplicationControllerCondition lastTransitionTime.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTransitionTime
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @instance
                         */
                        ReplicationControllerCondition.prototype.lastTransitionTime = null;

                        /**
                         * ReplicationControllerCondition reason.
                         * @member {string} reason
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @instance
                         */
                        ReplicationControllerCondition.prototype.reason = "";

                        /**
                         * ReplicationControllerCondition message.
                         * @member {string} message
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @instance
                         */
                        ReplicationControllerCondition.prototype.message = "";

                        /**
                         * Creates a new ReplicationControllerCondition instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerCondition=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ReplicationControllerCondition} ReplicationControllerCondition instance
                         */
                        ReplicationControllerCondition.create = function create(properties) {
                            return new ReplicationControllerCondition(properties);
                        };

                        /**
                         * Encodes the specified ReplicationControllerCondition message. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerCondition.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerCondition} message ReplicationControllerCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerCondition.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                            if (message.lastTransitionTime != null && Object.hasOwnProperty.call(message, "lastTransitionTime"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTransitionTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.reason);
                            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
                            return writer;
                        };

                        /**
                         * Encodes the specified ReplicationControllerCondition message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerCondition.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerCondition} message ReplicationControllerCondition message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerCondition.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ReplicationControllerCondition message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ReplicationControllerCondition} ReplicationControllerCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerCondition.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ReplicationControllerCondition();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.status = reader.string();
                                    break;
                                case 3:
                                    message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.reason = reader.string();
                                    break;
                                case 5:
                                    message.message = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ReplicationControllerCondition message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ReplicationControllerCondition} ReplicationControllerCondition
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerCondition.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ReplicationControllerCondition message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReplicationControllerCondition.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.status != null && message.hasOwnProperty("status"))
                                if (!$util.isString(message.status))
                                    return "status: string expected";
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTransitionTime);
                                if (error)
                                    return "lastTransitionTime." + error;
                            }
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                if (!$util.isString(message.reason))
                                    return "reason: string expected";
                            if (message.message != null && message.hasOwnProperty("message"))
                                if (!$util.isString(message.message))
                                    return "message: string expected";
                            return null;
                        };

                        /**
                         * Creates a ReplicationControllerCondition message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ReplicationControllerCondition} ReplicationControllerCondition
                         */
                        ReplicationControllerCondition.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ReplicationControllerCondition)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ReplicationControllerCondition();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.status != null)
                                message.status = String(object.status);
                            if (object.lastTransitionTime != null) {
                                if (typeof object.lastTransitionTime !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationControllerCondition.lastTransitionTime: object expected");
                                message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTransitionTime);
                            }
                            if (object.reason != null)
                                message.reason = String(object.reason);
                            if (object.message != null)
                                message.message = String(object.message);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ReplicationControllerCondition message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @static
                         * @param {k8s.io.api.core.v1.ReplicationControllerCondition} message ReplicationControllerCondition
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReplicationControllerCondition.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.status = "";
                                object.lastTransitionTime = null;
                                object.reason = "";
                                object.message = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = message.status;
                            if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime"))
                                object.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTransitionTime, options);
                            if (message.reason != null && message.hasOwnProperty("reason"))
                                object.reason = message.reason;
                            if (message.message != null && message.hasOwnProperty("message"))
                                object.message = message.message;
                            return object;
                        };

                        /**
                         * Converts this ReplicationControllerCondition to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ReplicationControllerCondition
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReplicationControllerCondition.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ReplicationControllerCondition;
                    })();

                    v1.ReplicationControllerList = (function() {

                        /**
                         * Properties of a ReplicationControllerList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IReplicationControllerList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] ReplicationControllerList metadata
                         * @property {Array.<k8s.io.api.core.v1.IReplicationController>|null} [items] ReplicationControllerList items
                         */

                        /**
                         * Constructs a new ReplicationControllerList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ReplicationControllerList.
                         * @implements IReplicationControllerList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IReplicationControllerList=} [properties] Properties to set
                         */
                        function ReplicationControllerList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ReplicationControllerList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @instance
                         */
                        ReplicationControllerList.prototype.metadata = null;

                        /**
                         * ReplicationControllerList items.
                         * @member {Array.<k8s.io.api.core.v1.IReplicationController>} items
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @instance
                         */
                        ReplicationControllerList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new ReplicationControllerList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ReplicationControllerList} ReplicationControllerList instance
                         */
                        ReplicationControllerList.create = function create(properties) {
                            return new ReplicationControllerList(properties);
                        };

                        /**
                         * Encodes the specified ReplicationControllerList message. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerList} message ReplicationControllerList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.ReplicationController.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ReplicationControllerList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerList} message ReplicationControllerList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ReplicationControllerList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ReplicationControllerList} ReplicationControllerList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ReplicationControllerList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.ReplicationController.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ReplicationControllerList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ReplicationControllerList} ReplicationControllerList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ReplicationControllerList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReplicationControllerList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ReplicationController.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ReplicationControllerList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ReplicationControllerList} ReplicationControllerList
                         */
                        ReplicationControllerList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ReplicationControllerList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ReplicationControllerList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationControllerList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationControllerList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ReplicationControllerList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.ReplicationController.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ReplicationControllerList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @static
                         * @param {k8s.io.api.core.v1.ReplicationControllerList} message ReplicationControllerList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReplicationControllerList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.ReplicationController.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ReplicationControllerList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ReplicationControllerList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReplicationControllerList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ReplicationControllerList;
                    })();

                    v1.ReplicationControllerSpec = (function() {

                        /**
                         * Properties of a ReplicationControllerSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IReplicationControllerSpec
                         * @property {number|null} [replicas] ReplicationControllerSpec replicas
                         * @property {number|null} [minReadySeconds] ReplicationControllerSpec minReadySeconds
                         * @property {Object.<string,string>|null} [selector] ReplicationControllerSpec selector
                         * @property {k8s.io.api.core.v1.IPodTemplateSpec|null} [template] ReplicationControllerSpec template
                         */

                        /**
                         * Constructs a new ReplicationControllerSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ReplicationControllerSpec.
                         * @implements IReplicationControllerSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IReplicationControllerSpec=} [properties] Properties to set
                         */
                        function ReplicationControllerSpec(properties) {
                            this.selector = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ReplicationControllerSpec replicas.
                         * @member {number} replicas
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @instance
                         */
                        ReplicationControllerSpec.prototype.replicas = 0;

                        /**
                         * ReplicationControllerSpec minReadySeconds.
                         * @member {number} minReadySeconds
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @instance
                         */
                        ReplicationControllerSpec.prototype.minReadySeconds = 0;

                        /**
                         * ReplicationControllerSpec selector.
                         * @member {Object.<string,string>} selector
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @instance
                         */
                        ReplicationControllerSpec.prototype.selector = $util.emptyObject;

                        /**
                         * ReplicationControllerSpec template.
                         * @member {k8s.io.api.core.v1.IPodTemplateSpec|null|undefined} template
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @instance
                         */
                        ReplicationControllerSpec.prototype.template = null;

                        /**
                         * Creates a new ReplicationControllerSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ReplicationControllerSpec} ReplicationControllerSpec instance
                         */
                        ReplicationControllerSpec.create = function create(properties) {
                            return new ReplicationControllerSpec(properties);
                        };

                        /**
                         * Encodes the specified ReplicationControllerSpec message. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerSpec} message ReplicationControllerSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.replicas != null && Object.hasOwnProperty.call(message, "replicas"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.replicas);
                            if (message.selector != null && Object.hasOwnProperty.call(message, "selector"))
                                for (var keys = Object.keys(message.selector), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.selector[keys[i]]).ldelim();
                            if (message.template != null && Object.hasOwnProperty.call(message, "template"))
                                $root.k8s.io.api.core.v1.PodTemplateSpec.encode(message.template, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.minReadySeconds != null && Object.hasOwnProperty.call(message, "minReadySeconds"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minReadySeconds);
                            return writer;
                        };

                        /**
                         * Encodes the specified ReplicationControllerSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerSpec} message ReplicationControllerSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ReplicationControllerSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ReplicationControllerSpec} ReplicationControllerSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ReplicationControllerSpec(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.replicas = reader.int32();
                                    break;
                                case 4:
                                    message.minReadySeconds = reader.int32();
                                    break;
                                case 2:
                                    if (message.selector === $util.emptyObject)
                                        message.selector = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.selector[key] = value;
                                    break;
                                case 3:
                                    message.template = $root.k8s.io.api.core.v1.PodTemplateSpec.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ReplicationControllerSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ReplicationControllerSpec} ReplicationControllerSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ReplicationControllerSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReplicationControllerSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.replicas != null && message.hasOwnProperty("replicas"))
                                if (!$util.isInteger(message.replicas))
                                    return "replicas: integer expected";
                            if (message.minReadySeconds != null && message.hasOwnProperty("minReadySeconds"))
                                if (!$util.isInteger(message.minReadySeconds))
                                    return "minReadySeconds: integer expected";
                            if (message.selector != null && message.hasOwnProperty("selector")) {
                                if (!$util.isObject(message.selector))
                                    return "selector: object expected";
                                var key = Object.keys(message.selector);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.selector[key[i]]))
                                        return "selector: string{k:string} expected";
                            }
                            if (message.template != null && message.hasOwnProperty("template")) {
                                var error = $root.k8s.io.api.core.v1.PodTemplateSpec.verify(message.template);
                                if (error)
                                    return "template." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ReplicationControllerSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ReplicationControllerSpec} ReplicationControllerSpec
                         */
                        ReplicationControllerSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ReplicationControllerSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ReplicationControllerSpec();
                            if (object.replicas != null)
                                message.replicas = object.replicas | 0;
                            if (object.minReadySeconds != null)
                                message.minReadySeconds = object.minReadySeconds | 0;
                            if (object.selector) {
                                if (typeof object.selector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationControllerSpec.selector: object expected");
                                message.selector = {};
                                for (var keys = Object.keys(object.selector), i = 0; i < keys.length; ++i)
                                    message.selector[keys[i]] = String(object.selector[keys[i]]);
                            }
                            if (object.template != null) {
                                if (typeof object.template !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationControllerSpec.template: object expected");
                                message.template = $root.k8s.io.api.core.v1.PodTemplateSpec.fromObject(object.template);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ReplicationControllerSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @static
                         * @param {k8s.io.api.core.v1.ReplicationControllerSpec} message ReplicationControllerSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReplicationControllerSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults)
                                object.selector = {};
                            if (options.defaults) {
                                object.replicas = 0;
                                object.template = null;
                                object.minReadySeconds = 0;
                            }
                            if (message.replicas != null && message.hasOwnProperty("replicas"))
                                object.replicas = message.replicas;
                            var keys2;
                            if (message.selector && (keys2 = Object.keys(message.selector)).length) {
                                object.selector = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.selector[keys2[j]] = message.selector[keys2[j]];
                            }
                            if (message.template != null && message.hasOwnProperty("template"))
                                object.template = $root.k8s.io.api.core.v1.PodTemplateSpec.toObject(message.template, options);
                            if (message.minReadySeconds != null && message.hasOwnProperty("minReadySeconds"))
                                object.minReadySeconds = message.minReadySeconds;
                            return object;
                        };

                        /**
                         * Converts this ReplicationControllerSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ReplicationControllerSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReplicationControllerSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ReplicationControllerSpec;
                    })();

                    v1.ReplicationControllerStatus = (function() {

                        /**
                         * Properties of a ReplicationControllerStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IReplicationControllerStatus
                         * @property {number|null} [replicas] ReplicationControllerStatus replicas
                         * @property {number|null} [fullyLabeledReplicas] ReplicationControllerStatus fullyLabeledReplicas
                         * @property {number|null} [readyReplicas] ReplicationControllerStatus readyReplicas
                         * @property {number|null} [availableReplicas] ReplicationControllerStatus availableReplicas
                         * @property {number|Long|null} [observedGeneration] ReplicationControllerStatus observedGeneration
                         * @property {Array.<k8s.io.api.core.v1.IReplicationControllerCondition>|null} [conditions] ReplicationControllerStatus conditions
                         */

                        /**
                         * Constructs a new ReplicationControllerStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ReplicationControllerStatus.
                         * @implements IReplicationControllerStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IReplicationControllerStatus=} [properties] Properties to set
                         */
                        function ReplicationControllerStatus(properties) {
                            this.conditions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ReplicationControllerStatus replicas.
                         * @member {number} replicas
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         */
                        ReplicationControllerStatus.prototype.replicas = 0;

                        /**
                         * ReplicationControllerStatus fullyLabeledReplicas.
                         * @member {number} fullyLabeledReplicas
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         */
                        ReplicationControllerStatus.prototype.fullyLabeledReplicas = 0;

                        /**
                         * ReplicationControllerStatus readyReplicas.
                         * @member {number} readyReplicas
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         */
                        ReplicationControllerStatus.prototype.readyReplicas = 0;

                        /**
                         * ReplicationControllerStatus availableReplicas.
                         * @member {number} availableReplicas
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         */
                        ReplicationControllerStatus.prototype.availableReplicas = 0;

                        /**
                         * ReplicationControllerStatus observedGeneration.
                         * @member {number|Long} observedGeneration
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         */
                        ReplicationControllerStatus.prototype.observedGeneration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * ReplicationControllerStatus conditions.
                         * @member {Array.<k8s.io.api.core.v1.IReplicationControllerCondition>} conditions
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         */
                        ReplicationControllerStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * Creates a new ReplicationControllerStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ReplicationControllerStatus} ReplicationControllerStatus instance
                         */
                        ReplicationControllerStatus.create = function create(properties) {
                            return new ReplicationControllerStatus(properties);
                        };

                        /**
                         * Encodes the specified ReplicationControllerStatus message. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerStatus} message ReplicationControllerStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.replicas != null && Object.hasOwnProperty.call(message, "replicas"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.replicas);
                            if (message.fullyLabeledReplicas != null && Object.hasOwnProperty.call(message, "fullyLabeledReplicas"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.fullyLabeledReplicas);
                            if (message.observedGeneration != null && Object.hasOwnProperty.call(message, "observedGeneration"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.observedGeneration);
                            if (message.readyReplicas != null && Object.hasOwnProperty.call(message, "readyReplicas"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.readyReplicas);
                            if (message.availableReplicas != null && Object.hasOwnProperty.call(message, "availableReplicas"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.availableReplicas);
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.api.core.v1.ReplicationControllerCondition.encode(message.conditions[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ReplicationControllerStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ReplicationControllerStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IReplicationControllerStatus} message ReplicationControllerStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ReplicationControllerStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ReplicationControllerStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ReplicationControllerStatus} ReplicationControllerStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ReplicationControllerStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.replicas = reader.int32();
                                    break;
                                case 2:
                                    message.fullyLabeledReplicas = reader.int32();
                                    break;
                                case 4:
                                    message.readyReplicas = reader.int32();
                                    break;
                                case 5:
                                    message.availableReplicas = reader.int32();
                                    break;
                                case 3:
                                    message.observedGeneration = reader.int64();
                                    break;
                                case 6:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.api.core.v1.ReplicationControllerCondition.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ReplicationControllerStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ReplicationControllerStatus} ReplicationControllerStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ReplicationControllerStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ReplicationControllerStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ReplicationControllerStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.replicas != null && message.hasOwnProperty("replicas"))
                                if (!$util.isInteger(message.replicas))
                                    return "replicas: integer expected";
                            if (message.fullyLabeledReplicas != null && message.hasOwnProperty("fullyLabeledReplicas"))
                                if (!$util.isInteger(message.fullyLabeledReplicas))
                                    return "fullyLabeledReplicas: integer expected";
                            if (message.readyReplicas != null && message.hasOwnProperty("readyReplicas"))
                                if (!$util.isInteger(message.readyReplicas))
                                    return "readyReplicas: integer expected";
                            if (message.availableReplicas != null && message.hasOwnProperty("availableReplicas"))
                                if (!$util.isInteger(message.availableReplicas))
                                    return "availableReplicas: integer expected";
                            if (message.observedGeneration != null && message.hasOwnProperty("observedGeneration"))
                                if (!$util.isInteger(message.observedGeneration) && !(message.observedGeneration && $util.isInteger(message.observedGeneration.low) && $util.isInteger(message.observedGeneration.high)))
                                    return "observedGeneration: integer|Long expected";
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ReplicationControllerCondition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ReplicationControllerStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ReplicationControllerStatus} ReplicationControllerStatus
                         */
                        ReplicationControllerStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ReplicationControllerStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ReplicationControllerStatus();
                            if (object.replicas != null)
                                message.replicas = object.replicas | 0;
                            if (object.fullyLabeledReplicas != null)
                                message.fullyLabeledReplicas = object.fullyLabeledReplicas | 0;
                            if (object.readyReplicas != null)
                                message.readyReplicas = object.readyReplicas | 0;
                            if (object.availableReplicas != null)
                                message.availableReplicas = object.availableReplicas | 0;
                            if (object.observedGeneration != null)
                                if ($util.Long)
                                    (message.observedGeneration = $util.Long.fromValue(object.observedGeneration)).unsigned = false;
                                else if (typeof object.observedGeneration === "string")
                                    message.observedGeneration = parseInt(object.observedGeneration, 10);
                                else if (typeof object.observedGeneration === "number")
                                    message.observedGeneration = object.observedGeneration;
                                else if (typeof object.observedGeneration === "object")
                                    message.observedGeneration = new $util.LongBits(object.observedGeneration.low >>> 0, object.observedGeneration.high >>> 0).toNumber();
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.ReplicationControllerStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ReplicationControllerStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.api.core.v1.ReplicationControllerCondition.fromObject(object.conditions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ReplicationControllerStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ReplicationControllerStatus} message ReplicationControllerStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ReplicationControllerStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.conditions = [];
                            if (options.defaults) {
                                object.replicas = 0;
                                object.fullyLabeledReplicas = 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.observedGeneration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.observedGeneration = options.longs === String ? "0" : 0;
                                object.readyReplicas = 0;
                                object.availableReplicas = 0;
                            }
                            if (message.replicas != null && message.hasOwnProperty("replicas"))
                                object.replicas = message.replicas;
                            if (message.fullyLabeledReplicas != null && message.hasOwnProperty("fullyLabeledReplicas"))
                                object.fullyLabeledReplicas = message.fullyLabeledReplicas;
                            if (message.observedGeneration != null && message.hasOwnProperty("observedGeneration"))
                                if (typeof message.observedGeneration === "number")
                                    object.observedGeneration = options.longs === String ? String(message.observedGeneration) : message.observedGeneration;
                                else
                                    object.observedGeneration = options.longs === String ? $util.Long.prototype.toString.call(message.observedGeneration) : options.longs === Number ? new $util.LongBits(message.observedGeneration.low >>> 0, message.observedGeneration.high >>> 0).toNumber() : message.observedGeneration;
                            if (message.readyReplicas != null && message.hasOwnProperty("readyReplicas"))
                                object.readyReplicas = message.readyReplicas;
                            if (message.availableReplicas != null && message.hasOwnProperty("availableReplicas"))
                                object.availableReplicas = message.availableReplicas;
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.api.core.v1.ReplicationControllerCondition.toObject(message.conditions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ReplicationControllerStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ReplicationControllerStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ReplicationControllerStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ReplicationControllerStatus;
                    })();

                    v1.ResourceFieldSelector = (function() {

                        /**
                         * Properties of a ResourceFieldSelector.
                         * @memberof k8s.io.api.core.v1
                         * @interface IResourceFieldSelector
                         * @property {string|null} [containerName] ResourceFieldSelector containerName
                         * @property {string|null} [resource] ResourceFieldSelector resource
                         * @property {k8s.io.apimachinery.pkg.api.resource.IQuantity|null} [divisor] ResourceFieldSelector divisor
                         */

                        /**
                         * Constructs a new ResourceFieldSelector.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ResourceFieldSelector.
                         * @implements IResourceFieldSelector
                         * @constructor
                         * @param {k8s.io.api.core.v1.IResourceFieldSelector=} [properties] Properties to set
                         */
                        function ResourceFieldSelector(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ResourceFieldSelector containerName.
                         * @member {string} containerName
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @instance
                         */
                        ResourceFieldSelector.prototype.containerName = "";

                        /**
                         * ResourceFieldSelector resource.
                         * @member {string} resource
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @instance
                         */
                        ResourceFieldSelector.prototype.resource = "";

                        /**
                         * ResourceFieldSelector divisor.
                         * @member {k8s.io.apimachinery.pkg.api.resource.IQuantity|null|undefined} divisor
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @instance
                         */
                        ResourceFieldSelector.prototype.divisor = null;

                        /**
                         * Creates a new ResourceFieldSelector instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceFieldSelector=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ResourceFieldSelector} ResourceFieldSelector instance
                         */
                        ResourceFieldSelector.create = function create(properties) {
                            return new ResourceFieldSelector(properties);
                        };

                        /**
                         * Encodes the specified ResourceFieldSelector message. Does not implicitly {@link k8s.io.api.core.v1.ResourceFieldSelector.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceFieldSelector} message ResourceFieldSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceFieldSelector.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.containerName != null && Object.hasOwnProperty.call(message, "containerName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.containerName);
                            if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.resource);
                            if (message.divisor != null && Object.hasOwnProperty.call(message, "divisor"))
                                $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.divisor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ResourceFieldSelector message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ResourceFieldSelector.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceFieldSelector} message ResourceFieldSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceFieldSelector.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ResourceFieldSelector message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ResourceFieldSelector} ResourceFieldSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceFieldSelector.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ResourceFieldSelector();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.containerName = reader.string();
                                    break;
                                case 2:
                                    message.resource = reader.string();
                                    break;
                                case 3:
                                    message.divisor = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ResourceFieldSelector message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ResourceFieldSelector} ResourceFieldSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceFieldSelector.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ResourceFieldSelector message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ResourceFieldSelector.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.containerName != null && message.hasOwnProperty("containerName"))
                                if (!$util.isString(message.containerName))
                                    return "containerName: string expected";
                            if (message.resource != null && message.hasOwnProperty("resource"))
                                if (!$util.isString(message.resource))
                                    return "resource: string expected";
                            if (message.divisor != null && message.hasOwnProperty("divisor")) {
                                var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.divisor);
                                if (error)
                                    return "divisor." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ResourceFieldSelector message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ResourceFieldSelector} ResourceFieldSelector
                         */
                        ResourceFieldSelector.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ResourceFieldSelector)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ResourceFieldSelector();
                            if (object.containerName != null)
                                message.containerName = String(object.containerName);
                            if (object.resource != null)
                                message.resource = String(object.resource);
                            if (object.divisor != null) {
                                if (typeof object.divisor !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceFieldSelector.divisor: object expected");
                                message.divisor = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.divisor);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ResourceFieldSelector message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @static
                         * @param {k8s.io.api.core.v1.ResourceFieldSelector} message ResourceFieldSelector
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ResourceFieldSelector.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.containerName = "";
                                object.resource = "";
                                object.divisor = null;
                            }
                            if (message.containerName != null && message.hasOwnProperty("containerName"))
                                object.containerName = message.containerName;
                            if (message.resource != null && message.hasOwnProperty("resource"))
                                object.resource = message.resource;
                            if (message.divisor != null && message.hasOwnProperty("divisor"))
                                object.divisor = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.divisor, options);
                            return object;
                        };

                        /**
                         * Converts this ResourceFieldSelector to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ResourceFieldSelector
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ResourceFieldSelector.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ResourceFieldSelector;
                    })();

                    v1.ResourceQuota = (function() {

                        /**
                         * Properties of a ResourceQuota.
                         * @memberof k8s.io.api.core.v1
                         * @interface IResourceQuota
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] ResourceQuota metadata
                         * @property {k8s.io.api.core.v1.IResourceQuotaSpec|null} [spec] ResourceQuota spec
                         * @property {k8s.io.api.core.v1.IResourceQuotaStatus|null} [status] ResourceQuota status
                         */

                        /**
                         * Constructs a new ResourceQuota.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ResourceQuota.
                         * @implements IResourceQuota
                         * @constructor
                         * @param {k8s.io.api.core.v1.IResourceQuota=} [properties] Properties to set
                         */
                        function ResourceQuota(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ResourceQuota metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @instance
                         */
                        ResourceQuota.prototype.metadata = null;

                        /**
                         * ResourceQuota spec.
                         * @member {k8s.io.api.core.v1.IResourceQuotaSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @instance
                         */
                        ResourceQuota.prototype.spec = null;

                        /**
                         * ResourceQuota status.
                         * @member {k8s.io.api.core.v1.IResourceQuotaStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @instance
                         */
                        ResourceQuota.prototype.status = null;

                        /**
                         * Creates a new ResourceQuota instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuota=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ResourceQuota} ResourceQuota instance
                         */
                        ResourceQuota.create = function create(properties) {
                            return new ResourceQuota(properties);
                        };

                        /**
                         * Encodes the specified ResourceQuota message. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuota.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuota} message ResourceQuota message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuota.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.ResourceQuotaSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.ResourceQuotaStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ResourceQuota message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuota.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuota} message ResourceQuota message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuota.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ResourceQuota message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ResourceQuota} ResourceQuota
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuota.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ResourceQuota();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.ResourceQuotaSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.ResourceQuotaStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ResourceQuota message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ResourceQuota} ResourceQuota
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuota.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ResourceQuota message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ResourceQuota.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.ResourceQuotaSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.ResourceQuotaStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ResourceQuota message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ResourceQuota} ResourceQuota
                         */
                        ResourceQuota.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ResourceQuota)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ResourceQuota();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuota.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuota.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.ResourceQuotaSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuota.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.ResourceQuotaStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ResourceQuota message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @static
                         * @param {k8s.io.api.core.v1.ResourceQuota} message ResourceQuota
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ResourceQuota.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.ResourceQuotaSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.ResourceQuotaStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this ResourceQuota to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ResourceQuota
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ResourceQuota.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ResourceQuota;
                    })();

                    v1.ResourceQuotaList = (function() {

                        /**
                         * Properties of a ResourceQuotaList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IResourceQuotaList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] ResourceQuotaList metadata
                         * @property {Array.<k8s.io.api.core.v1.IResourceQuota>|null} [items] ResourceQuotaList items
                         */

                        /**
                         * Constructs a new ResourceQuotaList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ResourceQuotaList.
                         * @implements IResourceQuotaList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IResourceQuotaList=} [properties] Properties to set
                         */
                        function ResourceQuotaList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ResourceQuotaList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @instance
                         */
                        ResourceQuotaList.prototype.metadata = null;

                        /**
                         * ResourceQuotaList items.
                         * @member {Array.<k8s.io.api.core.v1.IResourceQuota>} items
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @instance
                         */
                        ResourceQuotaList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new ResourceQuotaList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ResourceQuotaList} ResourceQuotaList instance
                         */
                        ResourceQuotaList.create = function create(properties) {
                            return new ResourceQuotaList(properties);
                        };

                        /**
                         * Encodes the specified ResourceQuotaList message. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuotaList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaList} message ResourceQuotaList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuotaList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.ResourceQuota.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ResourceQuotaList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuotaList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaList} message ResourceQuotaList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuotaList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ResourceQuotaList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ResourceQuotaList} ResourceQuotaList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuotaList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ResourceQuotaList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.ResourceQuota.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ResourceQuotaList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ResourceQuotaList} ResourceQuotaList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuotaList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ResourceQuotaList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ResourceQuotaList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ResourceQuota.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ResourceQuotaList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ResourceQuotaList} ResourceQuotaList
                         */
                        ResourceQuotaList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ResourceQuotaList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ResourceQuotaList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ResourceQuotaList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.ResourceQuota.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ResourceQuotaList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @static
                         * @param {k8s.io.api.core.v1.ResourceQuotaList} message ResourceQuotaList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ResourceQuotaList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.ResourceQuota.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ResourceQuotaList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ResourceQuotaList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ResourceQuotaList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ResourceQuotaList;
                    })();

                    v1.ResourceQuotaSpec = (function() {

                        /**
                         * Properties of a ResourceQuotaSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IResourceQuotaSpec
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [hard] ResourceQuotaSpec hard
                         * @property {Array.<string>|null} [scopes] ResourceQuotaSpec scopes
                         * @property {k8s.io.api.core.v1.IScopeSelector|null} [scopeSelector] ResourceQuotaSpec scopeSelector
                         */

                        /**
                         * Constructs a new ResourceQuotaSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ResourceQuotaSpec.
                         * @implements IResourceQuotaSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IResourceQuotaSpec=} [properties] Properties to set
                         */
                        function ResourceQuotaSpec(properties) {
                            this.hard = {};
                            this.scopes = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ResourceQuotaSpec hard.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} hard
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @instance
                         */
                        ResourceQuotaSpec.prototype.hard = $util.emptyObject;

                        /**
                         * ResourceQuotaSpec scopes.
                         * @member {Array.<string>} scopes
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @instance
                         */
                        ResourceQuotaSpec.prototype.scopes = $util.emptyArray;

                        /**
                         * ResourceQuotaSpec scopeSelector.
                         * @member {k8s.io.api.core.v1.IScopeSelector|null|undefined} scopeSelector
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @instance
                         */
                        ResourceQuotaSpec.prototype.scopeSelector = null;

                        /**
                         * Creates a new ResourceQuotaSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ResourceQuotaSpec} ResourceQuotaSpec instance
                         */
                        ResourceQuotaSpec.create = function create(properties) {
                            return new ResourceQuotaSpec(properties);
                        };

                        /**
                         * Encodes the specified ResourceQuotaSpec message. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuotaSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaSpec} message ResourceQuotaSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuotaSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.hard != null && Object.hasOwnProperty.call(message, "hard"))
                                for (var keys = Object.keys(message.hard), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.hard[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.scopes != null && message.scopes.length)
                                for (var i = 0; i < message.scopes.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.scopes[i]);
                            if (message.scopeSelector != null && Object.hasOwnProperty.call(message, "scopeSelector"))
                                $root.k8s.io.api.core.v1.ScopeSelector.encode(message.scopeSelector, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ResourceQuotaSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuotaSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaSpec} message ResourceQuotaSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuotaSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ResourceQuotaSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ResourceQuotaSpec} ResourceQuotaSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuotaSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ResourceQuotaSpec(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (message.hard === $util.emptyObject)
                                        message.hard = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.hard[key] = value;
                                    break;
                                case 2:
                                    if (!(message.scopes && message.scopes.length))
                                        message.scopes = [];
                                    message.scopes.push(reader.string());
                                    break;
                                case 3:
                                    message.scopeSelector = $root.k8s.io.api.core.v1.ScopeSelector.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ResourceQuotaSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ResourceQuotaSpec} ResourceQuotaSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuotaSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ResourceQuotaSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ResourceQuotaSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.hard != null && message.hasOwnProperty("hard")) {
                                if (!$util.isObject(message.hard))
                                    return "hard: object expected";
                                var key = Object.keys(message.hard);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.hard[key[i]]);
                                    if (error)
                                        return "hard." + error;
                                }
                            }
                            if (message.scopes != null && message.hasOwnProperty("scopes")) {
                                if (!Array.isArray(message.scopes))
                                    return "scopes: array expected";
                                for (var i = 0; i < message.scopes.length; ++i)
                                    if (!$util.isString(message.scopes[i]))
                                        return "scopes: string[] expected";
                            }
                            if (message.scopeSelector != null && message.hasOwnProperty("scopeSelector")) {
                                var error = $root.k8s.io.api.core.v1.ScopeSelector.verify(message.scopeSelector);
                                if (error)
                                    return "scopeSelector." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a ResourceQuotaSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ResourceQuotaSpec} ResourceQuotaSpec
                         */
                        ResourceQuotaSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ResourceQuotaSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ResourceQuotaSpec();
                            if (object.hard) {
                                if (typeof object.hard !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaSpec.hard: object expected");
                                message.hard = {};
                                for (var keys = Object.keys(object.hard), i = 0; i < keys.length; ++i) {
                                    if (typeof object.hard[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ResourceQuotaSpec.hard: object expected");
                                    message.hard[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.hard[keys[i]]);
                                }
                            }
                            if (object.scopes) {
                                if (!Array.isArray(object.scopes))
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaSpec.scopes: array expected");
                                message.scopes = [];
                                for (var i = 0; i < object.scopes.length; ++i)
                                    message.scopes[i] = String(object.scopes[i]);
                            }
                            if (object.scopeSelector != null) {
                                if (typeof object.scopeSelector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaSpec.scopeSelector: object expected");
                                message.scopeSelector = $root.k8s.io.api.core.v1.ScopeSelector.fromObject(object.scopeSelector);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ResourceQuotaSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @static
                         * @param {k8s.io.api.core.v1.ResourceQuotaSpec} message ResourceQuotaSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ResourceQuotaSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.scopes = [];
                            if (options.objects || options.defaults)
                                object.hard = {};
                            if (options.defaults)
                                object.scopeSelector = null;
                            var keys2;
                            if (message.hard && (keys2 = Object.keys(message.hard)).length) {
                                object.hard = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.hard[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.hard[keys2[j]], options);
                            }
                            if (message.scopes && message.scopes.length) {
                                object.scopes = [];
                                for (var j = 0; j < message.scopes.length; ++j)
                                    object.scopes[j] = message.scopes[j];
                            }
                            if (message.scopeSelector != null && message.hasOwnProperty("scopeSelector"))
                                object.scopeSelector = $root.k8s.io.api.core.v1.ScopeSelector.toObject(message.scopeSelector, options);
                            return object;
                        };

                        /**
                         * Converts this ResourceQuotaSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ResourceQuotaSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ResourceQuotaSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ResourceQuotaSpec;
                    })();

                    v1.ResourceQuotaStatus = (function() {

                        /**
                         * Properties of a ResourceQuotaStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IResourceQuotaStatus
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [hard] ResourceQuotaStatus hard
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [used] ResourceQuotaStatus used
                         */

                        /**
                         * Constructs a new ResourceQuotaStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ResourceQuotaStatus.
                         * @implements IResourceQuotaStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IResourceQuotaStatus=} [properties] Properties to set
                         */
                        function ResourceQuotaStatus(properties) {
                            this.hard = {};
                            this.used = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ResourceQuotaStatus hard.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} hard
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @instance
                         */
                        ResourceQuotaStatus.prototype.hard = $util.emptyObject;

                        /**
                         * ResourceQuotaStatus used.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} used
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @instance
                         */
                        ResourceQuotaStatus.prototype.used = $util.emptyObject;

                        /**
                         * Creates a new ResourceQuotaStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ResourceQuotaStatus} ResourceQuotaStatus instance
                         */
                        ResourceQuotaStatus.create = function create(properties) {
                            return new ResourceQuotaStatus(properties);
                        };

                        /**
                         * Encodes the specified ResourceQuotaStatus message. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuotaStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaStatus} message ResourceQuotaStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuotaStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.hard != null && Object.hasOwnProperty.call(message, "hard"))
                                for (var keys = Object.keys(message.hard), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.hard[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.used != null && Object.hasOwnProperty.call(message, "used"))
                                for (var keys = Object.keys(message.used), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.used[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            return writer;
                        };

                        /**
                         * Encodes the specified ResourceQuotaStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ResourceQuotaStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceQuotaStatus} message ResourceQuotaStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceQuotaStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ResourceQuotaStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ResourceQuotaStatus} ResourceQuotaStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuotaStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ResourceQuotaStatus(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (message.hard === $util.emptyObject)
                                        message.hard = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.hard[key] = value;
                                    break;
                                case 2:
                                    if (message.used === $util.emptyObject)
                                        message.used = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.used[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ResourceQuotaStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ResourceQuotaStatus} ResourceQuotaStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceQuotaStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ResourceQuotaStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ResourceQuotaStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.hard != null && message.hasOwnProperty("hard")) {
                                if (!$util.isObject(message.hard))
                                    return "hard: object expected";
                                var key = Object.keys(message.hard);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.hard[key[i]]);
                                    if (error)
                                        return "hard." + error;
                                }
                            }
                            if (message.used != null && message.hasOwnProperty("used")) {
                                if (!$util.isObject(message.used))
                                    return "used: object expected";
                                var key = Object.keys(message.used);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.used[key[i]]);
                                    if (error)
                                        return "used." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ResourceQuotaStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ResourceQuotaStatus} ResourceQuotaStatus
                         */
                        ResourceQuotaStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ResourceQuotaStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ResourceQuotaStatus();
                            if (object.hard) {
                                if (typeof object.hard !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaStatus.hard: object expected");
                                message.hard = {};
                                for (var keys = Object.keys(object.hard), i = 0; i < keys.length; ++i) {
                                    if (typeof object.hard[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ResourceQuotaStatus.hard: object expected");
                                    message.hard[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.hard[keys[i]]);
                                }
                            }
                            if (object.used) {
                                if (typeof object.used !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceQuotaStatus.used: object expected");
                                message.used = {};
                                for (var keys = Object.keys(object.used), i = 0; i < keys.length; ++i) {
                                    if (typeof object.used[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ResourceQuotaStatus.used: object expected");
                                    message.used[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.used[keys[i]]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ResourceQuotaStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ResourceQuotaStatus} message ResourceQuotaStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ResourceQuotaStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults) {
                                object.hard = {};
                                object.used = {};
                            }
                            var keys2;
                            if (message.hard && (keys2 = Object.keys(message.hard)).length) {
                                object.hard = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.hard[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.hard[keys2[j]], options);
                            }
                            if (message.used && (keys2 = Object.keys(message.used)).length) {
                                object.used = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.used[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.used[keys2[j]], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ResourceQuotaStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ResourceQuotaStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ResourceQuotaStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ResourceQuotaStatus;
                    })();

                    v1.ResourceRequirements = (function() {

                        /**
                         * Properties of a ResourceRequirements.
                         * @memberof k8s.io.api.core.v1
                         * @interface IResourceRequirements
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [limits] ResourceRequirements limits
                         * @property {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>|null} [requests] ResourceRequirements requests
                         */

                        /**
                         * Constructs a new ResourceRequirements.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ResourceRequirements.
                         * @implements IResourceRequirements
                         * @constructor
                         * @param {k8s.io.api.core.v1.IResourceRequirements=} [properties] Properties to set
                         */
                        function ResourceRequirements(properties) {
                            this.limits = {};
                            this.requests = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ResourceRequirements limits.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} limits
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @instance
                         */
                        ResourceRequirements.prototype.limits = $util.emptyObject;

                        /**
                         * ResourceRequirements requests.
                         * @member {Object.<string,k8s.io.apimachinery.pkg.api.resource.IQuantity>} requests
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @instance
                         */
                        ResourceRequirements.prototype.requests = $util.emptyObject;

                        /**
                         * Creates a new ResourceRequirements instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceRequirements=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ResourceRequirements} ResourceRequirements instance
                         */
                        ResourceRequirements.create = function create(properties) {
                            return new ResourceRequirements(properties);
                        };

                        /**
                         * Encodes the specified ResourceRequirements message. Does not implicitly {@link k8s.io.api.core.v1.ResourceRequirements.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceRequirements} message ResourceRequirements message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceRequirements.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.limits != null && Object.hasOwnProperty.call(message, "limits"))
                                for (var keys = Object.keys(message.limits), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.limits[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            if (message.requests != null && Object.hasOwnProperty.call(message, "requests"))
                                for (var keys = Object.keys(message.requests), i = 0; i < keys.length; ++i) {
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                                    $root.k8s.io.apimachinery.pkg.api.resource.Quantity.encode(message.requests[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                                }
                            return writer;
                        };

                        /**
                         * Encodes the specified ResourceRequirements message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ResourceRequirements.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {k8s.io.api.core.v1.IResourceRequirements} message ResourceRequirements message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ResourceRequirements.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ResourceRequirements message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ResourceRequirements} ResourceRequirements
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceRequirements.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ResourceRequirements(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (message.limits === $util.emptyObject)
                                        message.limits = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.limits[key] = value;
                                    break;
                                case 2:
                                    if (message.requests === $util.emptyObject)
                                        message.requests = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = null;
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.requests[key] = value;
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ResourceRequirements message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ResourceRequirements} ResourceRequirements
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ResourceRequirements.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ResourceRequirements message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ResourceRequirements.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.limits != null && message.hasOwnProperty("limits")) {
                                if (!$util.isObject(message.limits))
                                    return "limits: object expected";
                                var key = Object.keys(message.limits);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.limits[key[i]]);
                                    if (error)
                                        return "limits." + error;
                                }
                            }
                            if (message.requests != null && message.hasOwnProperty("requests")) {
                                if (!$util.isObject(message.requests))
                                    return "requests: object expected";
                                var key = Object.keys(message.requests);
                                for (var i = 0; i < key.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.verify(message.requests[key[i]]);
                                    if (error)
                                        return "requests." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ResourceRequirements message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ResourceRequirements} ResourceRequirements
                         */
                        ResourceRequirements.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ResourceRequirements)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ResourceRequirements();
                            if (object.limits) {
                                if (typeof object.limits !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceRequirements.limits: object expected");
                                message.limits = {};
                                for (var keys = Object.keys(object.limits), i = 0; i < keys.length; ++i) {
                                    if (typeof object.limits[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ResourceRequirements.limits: object expected");
                                    message.limits[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.limits[keys[i]]);
                                }
                            }
                            if (object.requests) {
                                if (typeof object.requests !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ResourceRequirements.requests: object expected");
                                message.requests = {};
                                for (var keys = Object.keys(object.requests), i = 0; i < keys.length; ++i) {
                                    if (typeof object.requests[keys[i]] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ResourceRequirements.requests: object expected");
                                    message.requests[keys[i]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.fromObject(object.requests[keys[i]]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ResourceRequirements message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @static
                         * @param {k8s.io.api.core.v1.ResourceRequirements} message ResourceRequirements
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ResourceRequirements.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults) {
                                object.limits = {};
                                object.requests = {};
                            }
                            var keys2;
                            if (message.limits && (keys2 = Object.keys(message.limits)).length) {
                                object.limits = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.limits[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.limits[keys2[j]], options);
                            }
                            if (message.requests && (keys2 = Object.keys(message.requests)).length) {
                                object.requests = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.requests[keys2[j]] = $root.k8s.io.apimachinery.pkg.api.resource.Quantity.toObject(message.requests[keys2[j]], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ResourceRequirements to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ResourceRequirements
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ResourceRequirements.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ResourceRequirements;
                    })();

                    v1.SELinuxOptions = (function() {

                        /**
                         * Properties of a SELinuxOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISELinuxOptions
                         * @property {string|null} [user] SELinuxOptions user
                         * @property {string|null} [role] SELinuxOptions role
                         * @property {string|null} [type] SELinuxOptions type
                         * @property {string|null} [level] SELinuxOptions level
                         */

                        /**
                         * Constructs a new SELinuxOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SELinuxOptions.
                         * @implements ISELinuxOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISELinuxOptions=} [properties] Properties to set
                         */
                        function SELinuxOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SELinuxOptions user.
                         * @member {string} user
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @instance
                         */
                        SELinuxOptions.prototype.user = "";

                        /**
                         * SELinuxOptions role.
                         * @member {string} role
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @instance
                         */
                        SELinuxOptions.prototype.role = "";

                        /**
                         * SELinuxOptions type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @instance
                         */
                        SELinuxOptions.prototype.type = "";

                        /**
                         * SELinuxOptions level.
                         * @member {string} level
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @instance
                         */
                        SELinuxOptions.prototype.level = "";

                        /**
                         * Creates a new SELinuxOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {k8s.io.api.core.v1.ISELinuxOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SELinuxOptions} SELinuxOptions instance
                         */
                        SELinuxOptions.create = function create(properties) {
                            return new SELinuxOptions(properties);
                        };

                        /**
                         * Encodes the specified SELinuxOptions message. Does not implicitly {@link k8s.io.api.core.v1.SELinuxOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {k8s.io.api.core.v1.ISELinuxOptions} message SELinuxOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SELinuxOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.user);
                            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.role);
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
                            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.level);
                            return writer;
                        };

                        /**
                         * Encodes the specified SELinuxOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SELinuxOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {k8s.io.api.core.v1.ISELinuxOptions} message SELinuxOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SELinuxOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SELinuxOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SELinuxOptions} SELinuxOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SELinuxOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SELinuxOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.user = reader.string();
                                    break;
                                case 2:
                                    message.role = reader.string();
                                    break;
                                case 3:
                                    message.type = reader.string();
                                    break;
                                case 4:
                                    message.level = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SELinuxOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SELinuxOptions} SELinuxOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SELinuxOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SELinuxOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SELinuxOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.user != null && message.hasOwnProperty("user"))
                                if (!$util.isString(message.user))
                                    return "user: string expected";
                            if (message.role != null && message.hasOwnProperty("role"))
                                if (!$util.isString(message.role))
                                    return "role: string expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.level != null && message.hasOwnProperty("level"))
                                if (!$util.isString(message.level))
                                    return "level: string expected";
                            return null;
                        };

                        /**
                         * Creates a SELinuxOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SELinuxOptions} SELinuxOptions
                         */
                        SELinuxOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SELinuxOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SELinuxOptions();
                            if (object.user != null)
                                message.user = String(object.user);
                            if (object.role != null)
                                message.role = String(object.role);
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.level != null)
                                message.level = String(object.level);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SELinuxOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @static
                         * @param {k8s.io.api.core.v1.SELinuxOptions} message SELinuxOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SELinuxOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.user = "";
                                object.role = "";
                                object.type = "";
                                object.level = "";
                            }
                            if (message.user != null && message.hasOwnProperty("user"))
                                object.user = message.user;
                            if (message.role != null && message.hasOwnProperty("role"))
                                object.role = message.role;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.level != null && message.hasOwnProperty("level"))
                                object.level = message.level;
                            return object;
                        };

                        /**
                         * Converts this SELinuxOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SELinuxOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SELinuxOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SELinuxOptions;
                    })();

                    v1.ScaleIOPersistentVolumeSource = (function() {

                        /**
                         * Properties of a ScaleIOPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IScaleIOPersistentVolumeSource
                         * @property {string|null} [gateway] ScaleIOPersistentVolumeSource gateway
                         * @property {string|null} [system] ScaleIOPersistentVolumeSource system
                         * @property {k8s.io.api.core.v1.ISecretReference|null} [secretRef] ScaleIOPersistentVolumeSource secretRef
                         * @property {boolean|null} [sslEnabled] ScaleIOPersistentVolumeSource sslEnabled
                         * @property {string|null} [protectionDomain] ScaleIOPersistentVolumeSource protectionDomain
                         * @property {string|null} [storagePool] ScaleIOPersistentVolumeSource storagePool
                         * @property {string|null} [storageMode] ScaleIOPersistentVolumeSource storageMode
                         * @property {string|null} [volumeName] ScaleIOPersistentVolumeSource volumeName
                         * @property {string|null} [fsType] ScaleIOPersistentVolumeSource fsType
                         * @property {boolean|null} [readOnly] ScaleIOPersistentVolumeSource readOnly
                         */

                        /**
                         * Constructs a new ScaleIOPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ScaleIOPersistentVolumeSource.
                         * @implements IScaleIOPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IScaleIOPersistentVolumeSource=} [properties] Properties to set
                         */
                        function ScaleIOPersistentVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ScaleIOPersistentVolumeSource gateway.
                         * @member {string} gateway
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.gateway = "";

                        /**
                         * ScaleIOPersistentVolumeSource system.
                         * @member {string} system
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.system = "";

                        /**
                         * ScaleIOPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ISecretReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * ScaleIOPersistentVolumeSource sslEnabled.
                         * @member {boolean} sslEnabled
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.sslEnabled = false;

                        /**
                         * ScaleIOPersistentVolumeSource protectionDomain.
                         * @member {string} protectionDomain
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.protectionDomain = "";

                        /**
                         * ScaleIOPersistentVolumeSource storagePool.
                         * @member {string} storagePool
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.storagePool = "";

                        /**
                         * ScaleIOPersistentVolumeSource storageMode.
                         * @member {string} storageMode
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.storageMode = "";

                        /**
                         * ScaleIOPersistentVolumeSource volumeName.
                         * @member {string} volumeName
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.volumeName = "";

                        /**
                         * ScaleIOPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * ScaleIOPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         */
                        ScaleIOPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new ScaleIOPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IScaleIOPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ScaleIOPersistentVolumeSource} ScaleIOPersistentVolumeSource instance
                         */
                        ScaleIOPersistentVolumeSource.create = function create(properties) {
                            return new ScaleIOPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified ScaleIOPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IScaleIOPersistentVolumeSource} message ScaleIOPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScaleIOPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.gateway != null && Object.hasOwnProperty.call(message, "gateway"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gateway);
                            if (message.system != null && Object.hasOwnProperty.call(message, "system"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.system);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.SecretReference.encode(message.secretRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.sslEnabled != null && Object.hasOwnProperty.call(message, "sslEnabled"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.sslEnabled);
                            if (message.protectionDomain != null && Object.hasOwnProperty.call(message, "protectionDomain"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.protectionDomain);
                            if (message.storagePool != null && Object.hasOwnProperty.call(message, "storagePool"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.storagePool);
                            if (message.storageMode != null && Object.hasOwnProperty.call(message, "storageMode"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.storageMode);
                            if (message.volumeName != null && Object.hasOwnProperty.call(message, "volumeName"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.volumeName);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified ScaleIOPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IScaleIOPersistentVolumeSource} message ScaleIOPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScaleIOPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ScaleIOPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ScaleIOPersistentVolumeSource} ScaleIOPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScaleIOPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.gateway = reader.string();
                                    break;
                                case 2:
                                    message.system = reader.string();
                                    break;
                                case 3:
                                    message.secretRef = $root.k8s.io.api.core.v1.SecretReference.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.sslEnabled = reader.bool();
                                    break;
                                case 5:
                                    message.protectionDomain = reader.string();
                                    break;
                                case 6:
                                    message.storagePool = reader.string();
                                    break;
                                case 7:
                                    message.storageMode = reader.string();
                                    break;
                                case 8:
                                    message.volumeName = reader.string();
                                    break;
                                case 9:
                                    message.fsType = reader.string();
                                    break;
                                case 10:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ScaleIOPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ScaleIOPersistentVolumeSource} ScaleIOPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScaleIOPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ScaleIOPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ScaleIOPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.gateway != null && message.hasOwnProperty("gateway"))
                                if (!$util.isString(message.gateway))
                                    return "gateway: string expected";
                            if (message.system != null && message.hasOwnProperty("system"))
                                if (!$util.isString(message.system))
                                    return "system: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.SecretReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.sslEnabled != null && message.hasOwnProperty("sslEnabled"))
                                if (typeof message.sslEnabled !== "boolean")
                                    return "sslEnabled: boolean expected";
                            if (message.protectionDomain != null && message.hasOwnProperty("protectionDomain"))
                                if (!$util.isString(message.protectionDomain))
                                    return "protectionDomain: string expected";
                            if (message.storagePool != null && message.hasOwnProperty("storagePool"))
                                if (!$util.isString(message.storagePool))
                                    return "storagePool: string expected";
                            if (message.storageMode != null && message.hasOwnProperty("storageMode"))
                                if (!$util.isString(message.storageMode))
                                    return "storageMode: string expected";
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                if (!$util.isString(message.volumeName))
                                    return "volumeName: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ScaleIOPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ScaleIOPersistentVolumeSource} ScaleIOPersistentVolumeSource
                         */
                        ScaleIOPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ScaleIOPersistentVolumeSource();
                            if (object.gateway != null)
                                message.gateway = String(object.gateway);
                            if (object.system != null)
                                message.system = String(object.system);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ScaleIOPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.SecretReference.fromObject(object.secretRef);
                            }
                            if (object.sslEnabled != null)
                                message.sslEnabled = Boolean(object.sslEnabled);
                            if (object.protectionDomain != null)
                                message.protectionDomain = String(object.protectionDomain);
                            if (object.storagePool != null)
                                message.storagePool = String(object.storagePool);
                            if (object.storageMode != null)
                                message.storageMode = String(object.storageMode);
                            if (object.volumeName != null)
                                message.volumeName = String(object.volumeName);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ScaleIOPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ScaleIOPersistentVolumeSource} message ScaleIOPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ScaleIOPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.gateway = "";
                                object.system = "";
                                object.secretRef = null;
                                object.sslEnabled = false;
                                object.protectionDomain = "";
                                object.storagePool = "";
                                object.storageMode = "";
                                object.volumeName = "";
                                object.fsType = "";
                                object.readOnly = false;
                            }
                            if (message.gateway != null && message.hasOwnProperty("gateway"))
                                object.gateway = message.gateway;
                            if (message.system != null && message.hasOwnProperty("system"))
                                object.system = message.system;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.SecretReference.toObject(message.secretRef, options);
                            if (message.sslEnabled != null && message.hasOwnProperty("sslEnabled"))
                                object.sslEnabled = message.sslEnabled;
                            if (message.protectionDomain != null && message.hasOwnProperty("protectionDomain"))
                                object.protectionDomain = message.protectionDomain;
                            if (message.storagePool != null && message.hasOwnProperty("storagePool"))
                                object.storagePool = message.storagePool;
                            if (message.storageMode != null && message.hasOwnProperty("storageMode"))
                                object.storageMode = message.storageMode;
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                object.volumeName = message.volumeName;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this ScaleIOPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ScaleIOPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ScaleIOPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ScaleIOPersistentVolumeSource;
                    })();

                    v1.ScaleIOVolumeSource = (function() {

                        /**
                         * Properties of a ScaleIOVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IScaleIOVolumeSource
                         * @property {string|null} [gateway] ScaleIOVolumeSource gateway
                         * @property {string|null} [system] ScaleIOVolumeSource system
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] ScaleIOVolumeSource secretRef
                         * @property {boolean|null} [sslEnabled] ScaleIOVolumeSource sslEnabled
                         * @property {string|null} [protectionDomain] ScaleIOVolumeSource protectionDomain
                         * @property {string|null} [storagePool] ScaleIOVolumeSource storagePool
                         * @property {string|null} [storageMode] ScaleIOVolumeSource storageMode
                         * @property {string|null} [volumeName] ScaleIOVolumeSource volumeName
                         * @property {string|null} [fsType] ScaleIOVolumeSource fsType
                         * @property {boolean|null} [readOnly] ScaleIOVolumeSource readOnly
                         */

                        /**
                         * Constructs a new ScaleIOVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ScaleIOVolumeSource.
                         * @implements IScaleIOVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IScaleIOVolumeSource=} [properties] Properties to set
                         */
                        function ScaleIOVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ScaleIOVolumeSource gateway.
                         * @member {string} gateway
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.gateway = "";

                        /**
                         * ScaleIOVolumeSource system.
                         * @member {string} system
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.system = "";

                        /**
                         * ScaleIOVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.secretRef = null;

                        /**
                         * ScaleIOVolumeSource sslEnabled.
                         * @member {boolean} sslEnabled
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.sslEnabled = false;

                        /**
                         * ScaleIOVolumeSource protectionDomain.
                         * @member {string} protectionDomain
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.protectionDomain = "";

                        /**
                         * ScaleIOVolumeSource storagePool.
                         * @member {string} storagePool
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.storagePool = "";

                        /**
                         * ScaleIOVolumeSource storageMode.
                         * @member {string} storageMode
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.storageMode = "";

                        /**
                         * ScaleIOVolumeSource volumeName.
                         * @member {string} volumeName
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.volumeName = "";

                        /**
                         * ScaleIOVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.fsType = "";

                        /**
                         * ScaleIOVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         */
                        ScaleIOVolumeSource.prototype.readOnly = false;

                        /**
                         * Creates a new ScaleIOVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IScaleIOVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ScaleIOVolumeSource} ScaleIOVolumeSource instance
                         */
                        ScaleIOVolumeSource.create = function create(properties) {
                            return new ScaleIOVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified ScaleIOVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.ScaleIOVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IScaleIOVolumeSource} message ScaleIOVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScaleIOVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.gateway != null && Object.hasOwnProperty.call(message, "gateway"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gateway);
                            if (message.system != null && Object.hasOwnProperty.call(message, "system"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.system);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.sslEnabled != null && Object.hasOwnProperty.call(message, "sslEnabled"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.sslEnabled);
                            if (message.protectionDomain != null && Object.hasOwnProperty.call(message, "protectionDomain"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.protectionDomain);
                            if (message.storagePool != null && Object.hasOwnProperty.call(message, "storagePool"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.storagePool);
                            if (message.storageMode != null && Object.hasOwnProperty.call(message, "storageMode"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.storageMode);
                            if (message.volumeName != null && Object.hasOwnProperty.call(message, "volumeName"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.volumeName);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.readOnly);
                            return writer;
                        };

                        /**
                         * Encodes the specified ScaleIOVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ScaleIOVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IScaleIOVolumeSource} message ScaleIOVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScaleIOVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ScaleIOVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ScaleIOVolumeSource} ScaleIOVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScaleIOVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ScaleIOVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.gateway = reader.string();
                                    break;
                                case 2:
                                    message.system = reader.string();
                                    break;
                                case 3:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.sslEnabled = reader.bool();
                                    break;
                                case 5:
                                    message.protectionDomain = reader.string();
                                    break;
                                case 6:
                                    message.storagePool = reader.string();
                                    break;
                                case 7:
                                    message.storageMode = reader.string();
                                    break;
                                case 8:
                                    message.volumeName = reader.string();
                                    break;
                                case 9:
                                    message.fsType = reader.string();
                                    break;
                                case 10:
                                    message.readOnly = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ScaleIOVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ScaleIOVolumeSource} ScaleIOVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScaleIOVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ScaleIOVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ScaleIOVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.gateway != null && message.hasOwnProperty("gateway"))
                                if (!$util.isString(message.gateway))
                                    return "gateway: string expected";
                            if (message.system != null && message.hasOwnProperty("system"))
                                if (!$util.isString(message.system))
                                    return "system: string expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            if (message.sslEnabled != null && message.hasOwnProperty("sslEnabled"))
                                if (typeof message.sslEnabled !== "boolean")
                                    return "sslEnabled: boolean expected";
                            if (message.protectionDomain != null && message.hasOwnProperty("protectionDomain"))
                                if (!$util.isString(message.protectionDomain))
                                    return "protectionDomain: string expected";
                            if (message.storagePool != null && message.hasOwnProperty("storagePool"))
                                if (!$util.isString(message.storagePool))
                                    return "storagePool: string expected";
                            if (message.storageMode != null && message.hasOwnProperty("storageMode"))
                                if (!$util.isString(message.storageMode))
                                    return "storageMode: string expected";
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                if (!$util.isString(message.volumeName))
                                    return "volumeName: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ScaleIOVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ScaleIOVolumeSource} ScaleIOVolumeSource
                         */
                        ScaleIOVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ScaleIOVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ScaleIOVolumeSource();
                            if (object.gateway != null)
                                message.gateway = String(object.gateway);
                            if (object.system != null)
                                message.system = String(object.system);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ScaleIOVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            if (object.sslEnabled != null)
                                message.sslEnabled = Boolean(object.sslEnabled);
                            if (object.protectionDomain != null)
                                message.protectionDomain = String(object.protectionDomain);
                            if (object.storagePool != null)
                                message.storagePool = String(object.storagePool);
                            if (object.storageMode != null)
                                message.storageMode = String(object.storageMode);
                            if (object.volumeName != null)
                                message.volumeName = String(object.volumeName);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ScaleIOVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ScaleIOVolumeSource} message ScaleIOVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ScaleIOVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.gateway = "";
                                object.system = "";
                                object.secretRef = null;
                                object.sslEnabled = false;
                                object.protectionDomain = "";
                                object.storagePool = "";
                                object.storageMode = "";
                                object.volumeName = "";
                                object.fsType = "";
                                object.readOnly = false;
                            }
                            if (message.gateway != null && message.hasOwnProperty("gateway"))
                                object.gateway = message.gateway;
                            if (message.system != null && message.hasOwnProperty("system"))
                                object.system = message.system;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            if (message.sslEnabled != null && message.hasOwnProperty("sslEnabled"))
                                object.sslEnabled = message.sslEnabled;
                            if (message.protectionDomain != null && message.hasOwnProperty("protectionDomain"))
                                object.protectionDomain = message.protectionDomain;
                            if (message.storagePool != null && message.hasOwnProperty("storagePool"))
                                object.storagePool = message.storagePool;
                            if (message.storageMode != null && message.hasOwnProperty("storageMode"))
                                object.storageMode = message.storageMode;
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                object.volumeName = message.volumeName;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            return object;
                        };

                        /**
                         * Converts this ScaleIOVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ScaleIOVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ScaleIOVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ScaleIOVolumeSource;
                    })();

                    v1.ScopeSelector = (function() {

                        /**
                         * Properties of a ScopeSelector.
                         * @memberof k8s.io.api.core.v1
                         * @interface IScopeSelector
                         * @property {Array.<k8s.io.api.core.v1.IScopedResourceSelectorRequirement>|null} [matchExpressions] ScopeSelector matchExpressions
                         */

                        /**
                         * Constructs a new ScopeSelector.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ScopeSelector.
                         * @implements IScopeSelector
                         * @constructor
                         * @param {k8s.io.api.core.v1.IScopeSelector=} [properties] Properties to set
                         */
                        function ScopeSelector(properties) {
                            this.matchExpressions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ScopeSelector matchExpressions.
                         * @member {Array.<k8s.io.api.core.v1.IScopedResourceSelectorRequirement>} matchExpressions
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @instance
                         */
                        ScopeSelector.prototype.matchExpressions = $util.emptyArray;

                        /**
                         * Creates a new ScopeSelector instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IScopeSelector=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ScopeSelector} ScopeSelector instance
                         */
                        ScopeSelector.create = function create(properties) {
                            return new ScopeSelector(properties);
                        };

                        /**
                         * Encodes the specified ScopeSelector message. Does not implicitly {@link k8s.io.api.core.v1.ScopeSelector.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IScopeSelector} message ScopeSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScopeSelector.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.matchExpressions != null && message.matchExpressions.length)
                                for (var i = 0; i < message.matchExpressions.length; ++i)
                                    $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement.encode(message.matchExpressions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ScopeSelector message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ScopeSelector.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.IScopeSelector} message ScopeSelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScopeSelector.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ScopeSelector message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ScopeSelector} ScopeSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScopeSelector.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ScopeSelector();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.matchExpressions && message.matchExpressions.length))
                                        message.matchExpressions = [];
                                    message.matchExpressions.push($root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ScopeSelector message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ScopeSelector} ScopeSelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScopeSelector.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ScopeSelector message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ScopeSelector.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.matchExpressions != null && message.hasOwnProperty("matchExpressions")) {
                                if (!Array.isArray(message.matchExpressions))
                                    return "matchExpressions: array expected";
                                for (var i = 0; i < message.matchExpressions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement.verify(message.matchExpressions[i]);
                                    if (error)
                                        return "matchExpressions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ScopeSelector message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ScopeSelector} ScopeSelector
                         */
                        ScopeSelector.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ScopeSelector)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ScopeSelector();
                            if (object.matchExpressions) {
                                if (!Array.isArray(object.matchExpressions))
                                    throw TypeError(".k8s.io.api.core.v1.ScopeSelector.matchExpressions: array expected");
                                message.matchExpressions = [];
                                for (var i = 0; i < object.matchExpressions.length; ++i) {
                                    if (typeof object.matchExpressions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ScopeSelector.matchExpressions: object expected");
                                    message.matchExpressions[i] = $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement.fromObject(object.matchExpressions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ScopeSelector message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @static
                         * @param {k8s.io.api.core.v1.ScopeSelector} message ScopeSelector
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ScopeSelector.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.matchExpressions = [];
                            if (message.matchExpressions && message.matchExpressions.length) {
                                object.matchExpressions = [];
                                for (var j = 0; j < message.matchExpressions.length; ++j)
                                    object.matchExpressions[j] = $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement.toObject(message.matchExpressions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ScopeSelector to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ScopeSelector
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ScopeSelector.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ScopeSelector;
                    })();

                    v1.ScopedResourceSelectorRequirement = (function() {

                        /**
                         * Properties of a ScopedResourceSelectorRequirement.
                         * @memberof k8s.io.api.core.v1
                         * @interface IScopedResourceSelectorRequirement
                         * @property {string|null} [scopeName] ScopedResourceSelectorRequirement scopeName
                         * @property {string|null} [operator] ScopedResourceSelectorRequirement operator
                         * @property {Array.<string>|null} [values] ScopedResourceSelectorRequirement values
                         */

                        /**
                         * Constructs a new ScopedResourceSelectorRequirement.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ScopedResourceSelectorRequirement.
                         * @implements IScopedResourceSelectorRequirement
                         * @constructor
                         * @param {k8s.io.api.core.v1.IScopedResourceSelectorRequirement=} [properties] Properties to set
                         */
                        function ScopedResourceSelectorRequirement(properties) {
                            this.values = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ScopedResourceSelectorRequirement scopeName.
                         * @member {string} scopeName
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @instance
                         */
                        ScopedResourceSelectorRequirement.prototype.scopeName = "";

                        /**
                         * ScopedResourceSelectorRequirement operator.
                         * @member {string} operator
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @instance
                         */
                        ScopedResourceSelectorRequirement.prototype.operator = "";

                        /**
                         * ScopedResourceSelectorRequirement values.
                         * @member {Array.<string>} values
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @instance
                         */
                        ScopedResourceSelectorRequirement.prototype.values = $util.emptyArray;

                        /**
                         * Creates a new ScopedResourceSelectorRequirement instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.IScopedResourceSelectorRequirement=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ScopedResourceSelectorRequirement} ScopedResourceSelectorRequirement instance
                         */
                        ScopedResourceSelectorRequirement.create = function create(properties) {
                            return new ScopedResourceSelectorRequirement(properties);
                        };

                        /**
                         * Encodes the specified ScopedResourceSelectorRequirement message. Does not implicitly {@link k8s.io.api.core.v1.ScopedResourceSelectorRequirement.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.IScopedResourceSelectorRequirement} message ScopedResourceSelectorRequirement message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScopedResourceSelectorRequirement.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.scopeName != null && Object.hasOwnProperty.call(message, "scopeName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scopeName);
                            if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operator);
                            if (message.values != null && message.values.length)
                                for (var i = 0; i < message.values.length; ++i)
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.values[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified ScopedResourceSelectorRequirement message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ScopedResourceSelectorRequirement.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.IScopedResourceSelectorRequirement} message ScopedResourceSelectorRequirement message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ScopedResourceSelectorRequirement.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ScopedResourceSelectorRequirement message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ScopedResourceSelectorRequirement} ScopedResourceSelectorRequirement
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScopedResourceSelectorRequirement.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.scopeName = reader.string();
                                    break;
                                case 2:
                                    message.operator = reader.string();
                                    break;
                                case 3:
                                    if (!(message.values && message.values.length))
                                        message.values = [];
                                    message.values.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ScopedResourceSelectorRequirement message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ScopedResourceSelectorRequirement} ScopedResourceSelectorRequirement
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ScopedResourceSelectorRequirement.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ScopedResourceSelectorRequirement message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ScopedResourceSelectorRequirement.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.scopeName != null && message.hasOwnProperty("scopeName"))
                                if (!$util.isString(message.scopeName))
                                    return "scopeName: string expected";
                            if (message.operator != null && message.hasOwnProperty("operator"))
                                if (!$util.isString(message.operator))
                                    return "operator: string expected";
                            if (message.values != null && message.hasOwnProperty("values")) {
                                if (!Array.isArray(message.values))
                                    return "values: array expected";
                                for (var i = 0; i < message.values.length; ++i)
                                    if (!$util.isString(message.values[i]))
                                        return "values: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a ScopedResourceSelectorRequirement message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ScopedResourceSelectorRequirement} ScopedResourceSelectorRequirement
                         */
                        ScopedResourceSelectorRequirement.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ScopedResourceSelectorRequirement();
                            if (object.scopeName != null)
                                message.scopeName = String(object.scopeName);
                            if (object.operator != null)
                                message.operator = String(object.operator);
                            if (object.values) {
                                if (!Array.isArray(object.values))
                                    throw TypeError(".k8s.io.api.core.v1.ScopedResourceSelectorRequirement.values: array expected");
                                message.values = [];
                                for (var i = 0; i < object.values.length; ++i)
                                    message.values[i] = String(object.values[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ScopedResourceSelectorRequirement message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.ScopedResourceSelectorRequirement} message ScopedResourceSelectorRequirement
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ScopedResourceSelectorRequirement.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.values = [];
                            if (options.defaults) {
                                object.scopeName = "";
                                object.operator = "";
                            }
                            if (message.scopeName != null && message.hasOwnProperty("scopeName"))
                                object.scopeName = message.scopeName;
                            if (message.operator != null && message.hasOwnProperty("operator"))
                                object.operator = message.operator;
                            if (message.values && message.values.length) {
                                object.values = [];
                                for (var j = 0; j < message.values.length; ++j)
                                    object.values[j] = message.values[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this ScopedResourceSelectorRequirement to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ScopedResourceSelectorRequirement
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ScopedResourceSelectorRequirement.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ScopedResourceSelectorRequirement;
                    })();

                    v1.SeccompProfile = (function() {

                        /**
                         * Properties of a SeccompProfile.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISeccompProfile
                         * @property {string|null} [type] SeccompProfile type
                         * @property {string|null} [localhostProfile] SeccompProfile localhostProfile
                         */

                        /**
                         * Constructs a new SeccompProfile.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SeccompProfile.
                         * @implements ISeccompProfile
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISeccompProfile=} [properties] Properties to set
                         */
                        function SeccompProfile(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SeccompProfile type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @instance
                         */
                        SeccompProfile.prototype.type = "";

                        /**
                         * SeccompProfile localhostProfile.
                         * @member {string} localhostProfile
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @instance
                         */
                        SeccompProfile.prototype.localhostProfile = "";

                        /**
                         * Creates a new SeccompProfile instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {k8s.io.api.core.v1.ISeccompProfile=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SeccompProfile} SeccompProfile instance
                         */
                        SeccompProfile.create = function create(properties) {
                            return new SeccompProfile(properties);
                        };

                        /**
                         * Encodes the specified SeccompProfile message. Does not implicitly {@link k8s.io.api.core.v1.SeccompProfile.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {k8s.io.api.core.v1.ISeccompProfile} message SeccompProfile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SeccompProfile.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                            if (message.localhostProfile != null && Object.hasOwnProperty.call(message, "localhostProfile"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localhostProfile);
                            return writer;
                        };

                        /**
                         * Encodes the specified SeccompProfile message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SeccompProfile.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {k8s.io.api.core.v1.ISeccompProfile} message SeccompProfile message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SeccompProfile.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SeccompProfile message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SeccompProfile} SeccompProfile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SeccompProfile.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SeccompProfile();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.type = reader.string();
                                    break;
                                case 2:
                                    message.localhostProfile = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SeccompProfile message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SeccompProfile} SeccompProfile
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SeccompProfile.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SeccompProfile message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SeccompProfile.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.localhostProfile != null && message.hasOwnProperty("localhostProfile"))
                                if (!$util.isString(message.localhostProfile))
                                    return "localhostProfile: string expected";
                            return null;
                        };

                        /**
                         * Creates a SeccompProfile message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SeccompProfile} SeccompProfile
                         */
                        SeccompProfile.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SeccompProfile)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SeccompProfile();
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.localhostProfile != null)
                                message.localhostProfile = String(object.localhostProfile);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SeccompProfile message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @static
                         * @param {k8s.io.api.core.v1.SeccompProfile} message SeccompProfile
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SeccompProfile.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.type = "";
                                object.localhostProfile = "";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.localhostProfile != null && message.hasOwnProperty("localhostProfile"))
                                object.localhostProfile = message.localhostProfile;
                            return object;
                        };

                        /**
                         * Converts this SeccompProfile to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SeccompProfile
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SeccompProfile.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SeccompProfile;
                    })();

                    v1.Secret = (function() {

                        /**
                         * Properties of a Secret.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecret
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Secret metadata
                         * @property {boolean|null} [immutable] Secret immutable
                         * @property {Object.<string,Uint8Array>|null} [data] Secret data
                         * @property {Object.<string,string>|null} [stringData] Secret stringData
                         * @property {string|null} [type] Secret type
                         */

                        /**
                         * Constructs a new Secret.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Secret.
                         * @implements ISecret
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecret=} [properties] Properties to set
                         */
                        function Secret(properties) {
                            this.data = {};
                            this.stringData = {};
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Secret metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Secret
                         * @instance
                         */
                        Secret.prototype.metadata = null;

                        /**
                         * Secret immutable.
                         * @member {boolean} immutable
                         * @memberof k8s.io.api.core.v1.Secret
                         * @instance
                         */
                        Secret.prototype.immutable = false;

                        /**
                         * Secret data.
                         * @member {Object.<string,Uint8Array>} data
                         * @memberof k8s.io.api.core.v1.Secret
                         * @instance
                         */
                        Secret.prototype.data = $util.emptyObject;

                        /**
                         * Secret stringData.
                         * @member {Object.<string,string>} stringData
                         * @memberof k8s.io.api.core.v1.Secret
                         * @instance
                         */
                        Secret.prototype.stringData = $util.emptyObject;

                        /**
                         * Secret type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.Secret
                         * @instance
                         */
                        Secret.prototype.type = "";

                        /**
                         * Creates a new Secret instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {k8s.io.api.core.v1.ISecret=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Secret} Secret instance
                         */
                        Secret.create = function create(properties) {
                            return new Secret(properties);
                        };

                        /**
                         * Encodes the specified Secret message. Does not implicitly {@link k8s.io.api.core.v1.Secret.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {k8s.io.api.core.v1.ISecret} message Secret message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Secret.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                                for (var keys = Object.keys(message.data), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.data[keys[i]]).ldelim();
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
                            if (message.stringData != null && Object.hasOwnProperty.call(message, "stringData"))
                                for (var keys = Object.keys(message.stringData), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.stringData[keys[i]]).ldelim();
                            if (message.immutable != null && Object.hasOwnProperty.call(message, "immutable"))
                                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.immutable);
                            return writer;
                        };

                        /**
                         * Encodes the specified Secret message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Secret.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {k8s.io.api.core.v1.ISecret} message Secret message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Secret.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Secret message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Secret} Secret
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Secret.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Secret(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.immutable = reader.bool();
                                    break;
                                case 2:
                                    if (message.data === $util.emptyObject)
                                        message.data = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = [];
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.bytes();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.data[key] = value;
                                    break;
                                case 4:
                                    if (message.stringData === $util.emptyObject)
                                        message.stringData = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.stringData[key] = value;
                                    break;
                                case 3:
                                    message.type = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Secret message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Secret} Secret
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Secret.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Secret message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Secret.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.immutable != null && message.hasOwnProperty("immutable"))
                                if (typeof message.immutable !== "boolean")
                                    return "immutable: boolean expected";
                            if (message.data != null && message.hasOwnProperty("data")) {
                                if (!$util.isObject(message.data))
                                    return "data: object expected";
                                var key = Object.keys(message.data);
                                for (var i = 0; i < key.length; ++i)
                                    if (!(message.data[key[i]] && typeof message.data[key[i]].length === "number" || $util.isString(message.data[key[i]])))
                                        return "data: buffer{k:string} expected";
                            }
                            if (message.stringData != null && message.hasOwnProperty("stringData")) {
                                if (!$util.isObject(message.stringData))
                                    return "stringData: object expected";
                                var key = Object.keys(message.stringData);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.stringData[key[i]]))
                                        return "stringData: string{k:string} expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            return null;
                        };

                        /**
                         * Creates a Secret message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Secret} Secret
                         */
                        Secret.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Secret)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Secret();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Secret.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.immutable != null)
                                message.immutable = Boolean(object.immutable);
                            if (object.data) {
                                if (typeof object.data !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Secret.data: object expected");
                                message.data = {};
                                for (var keys = Object.keys(object.data), i = 0; i < keys.length; ++i)
                                    if (typeof object.data[keys[i]] === "string")
                                        $util.base64.decode(object.data[keys[i]], message.data[keys[i]] = $util.newBuffer($util.base64.length(object.data[keys[i]])), 0);
                                    else if (object.data[keys[i]].length)
                                        message.data[keys[i]] = object.data[keys[i]];
                            }
                            if (object.stringData) {
                                if (typeof object.stringData !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Secret.stringData: object expected");
                                message.stringData = {};
                                for (var keys = Object.keys(object.stringData), i = 0; i < keys.length; ++i)
                                    message.stringData[keys[i]] = String(object.stringData[keys[i]]);
                            }
                            if (object.type != null)
                                message.type = String(object.type);
                            return message;
                        };

                        /**
                         * Creates a plain object from a Secret message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Secret
                         * @static
                         * @param {k8s.io.api.core.v1.Secret} message Secret
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Secret.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.objects || options.defaults) {
                                object.data = {};
                                object.stringData = {};
                            }
                            if (options.defaults) {
                                object.metadata = null;
                                object.type = "";
                                object.immutable = false;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            var keys2;
                            if (message.data && (keys2 = Object.keys(message.data)).length) {
                                object.data = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.data[keys2[j]] = options.bytes === String ? $util.base64.encode(message.data[keys2[j]], 0, message.data[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.data[keys2[j]]) : message.data[keys2[j]];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.stringData && (keys2 = Object.keys(message.stringData)).length) {
                                object.stringData = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.stringData[keys2[j]] = message.stringData[keys2[j]];
                            }
                            if (message.immutable != null && message.hasOwnProperty("immutable"))
                                object.immutable = message.immutable;
                            return object;
                        };

                        /**
                         * Converts this Secret to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Secret
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Secret.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Secret;
                    })();

                    v1.SecretEnvSource = (function() {

                        /**
                         * Properties of a SecretEnvSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecretEnvSource
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] SecretEnvSource localObjectReference
                         * @property {boolean|null} [optional] SecretEnvSource optional
                         */

                        /**
                         * Constructs a new SecretEnvSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecretEnvSource.
                         * @implements ISecretEnvSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecretEnvSource=} [properties] Properties to set
                         */
                        function SecretEnvSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecretEnvSource localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @instance
                         */
                        SecretEnvSource.prototype.localObjectReference = null;

                        /**
                         * SecretEnvSource optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @instance
                         */
                        SecretEnvSource.prototype.optional = false;

                        /**
                         * Creates a new SecretEnvSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretEnvSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecretEnvSource} SecretEnvSource instance
                         */
                        SecretEnvSource.create = function create(properties) {
                            return new SecretEnvSource(properties);
                        };

                        /**
                         * Encodes the specified SecretEnvSource message. Does not implicitly {@link k8s.io.api.core.v1.SecretEnvSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretEnvSource} message SecretEnvSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretEnvSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified SecretEnvSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecretEnvSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretEnvSource} message SecretEnvSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretEnvSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecretEnvSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecretEnvSource} SecretEnvSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretEnvSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecretEnvSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecretEnvSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecretEnvSource} SecretEnvSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretEnvSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecretEnvSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecretEnvSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a SecretEnvSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecretEnvSource} SecretEnvSource
                         */
                        SecretEnvSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecretEnvSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecretEnvSource();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecretEnvSource.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecretEnvSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @static
                         * @param {k8s.io.api.core.v1.SecretEnvSource} message SecretEnvSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecretEnvSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this SecretEnvSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecretEnvSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecretEnvSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecretEnvSource;
                    })();

                    v1.SecretKeySelector = (function() {

                        /**
                         * Properties of a SecretKeySelector.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecretKeySelector
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] SecretKeySelector localObjectReference
                         * @property {string|null} [key] SecretKeySelector key
                         * @property {boolean|null} [optional] SecretKeySelector optional
                         */

                        /**
                         * Constructs a new SecretKeySelector.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecretKeySelector.
                         * @implements ISecretKeySelector
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecretKeySelector=} [properties] Properties to set
                         */
                        function SecretKeySelector(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecretKeySelector localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @instance
                         */
                        SecretKeySelector.prototype.localObjectReference = null;

                        /**
                         * SecretKeySelector key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @instance
                         */
                        SecretKeySelector.prototype.key = "";

                        /**
                         * SecretKeySelector optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @instance
                         */
                        SecretKeySelector.prototype.optional = false;

                        /**
                         * Creates a new SecretKeySelector instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretKeySelector=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecretKeySelector} SecretKeySelector instance
                         */
                        SecretKeySelector.create = function create(properties) {
                            return new SecretKeySelector(properties);
                        };

                        /**
                         * Encodes the specified SecretKeySelector message. Does not implicitly {@link k8s.io.api.core.v1.SecretKeySelector.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretKeySelector} message SecretKeySelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretKeySelector.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified SecretKeySelector message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecretKeySelector.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretKeySelector} message SecretKeySelector message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretKeySelector.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecretKeySelector message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecretKeySelector} SecretKeySelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretKeySelector.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecretKeySelector();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.key = reader.string();
                                    break;
                                case 3:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecretKeySelector message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecretKeySelector} SecretKeySelector
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretKeySelector.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecretKeySelector message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecretKeySelector.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a SecretKeySelector message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecretKeySelector} SecretKeySelector
                         */
                        SecretKeySelector.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecretKeySelector)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecretKeySelector();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecretKeySelector.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecretKeySelector message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @static
                         * @param {k8s.io.api.core.v1.SecretKeySelector} message SecretKeySelector
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecretKeySelector.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.key = "";
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this SecretKeySelector to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecretKeySelector
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecretKeySelector.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecretKeySelector;
                    })();

                    v1.SecretList = (function() {

                        /**
                         * Properties of a SecretList.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecretList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] SecretList metadata
                         * @property {Array.<k8s.io.api.core.v1.ISecret>|null} [items] SecretList items
                         */

                        /**
                         * Constructs a new SecretList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecretList.
                         * @implements ISecretList
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecretList=} [properties] Properties to set
                         */
                        function SecretList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecretList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @instance
                         */
                        SecretList.prototype.metadata = null;

                        /**
                         * SecretList items.
                         * @member {Array.<k8s.io.api.core.v1.ISecret>} items
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @instance
                         */
                        SecretList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new SecretList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecretList} SecretList instance
                         */
                        SecretList.create = function create(properties) {
                            return new SecretList(properties);
                        };

                        /**
                         * Encodes the specified SecretList message. Does not implicitly {@link k8s.io.api.core.v1.SecretList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretList} message SecretList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Secret.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified SecretList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecretList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretList} message SecretList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecretList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecretList} SecretList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecretList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Secret.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecretList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecretList} SecretList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecretList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecretList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Secret.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a SecretList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecretList} SecretList
                         */
                        SecretList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecretList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecretList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecretList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.SecretList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.SecretList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Secret.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecretList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @static
                         * @param {k8s.io.api.core.v1.SecretList} message SecretList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecretList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Secret.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this SecretList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecretList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecretList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecretList;
                    })();

                    v1.SecretProjection = (function() {

                        /**
                         * Properties of a SecretProjection.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecretProjection
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [localObjectReference] SecretProjection localObjectReference
                         * @property {Array.<k8s.io.api.core.v1.IKeyToPath>|null} [items] SecretProjection items
                         * @property {boolean|null} [optional] SecretProjection optional
                         */

                        /**
                         * Constructs a new SecretProjection.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecretProjection.
                         * @implements ISecretProjection
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecretProjection=} [properties] Properties to set
                         */
                        function SecretProjection(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecretProjection localObjectReference.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} localObjectReference
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @instance
                         */
                        SecretProjection.prototype.localObjectReference = null;

                        /**
                         * SecretProjection items.
                         * @member {Array.<k8s.io.api.core.v1.IKeyToPath>} items
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @instance
                         */
                        SecretProjection.prototype.items = $util.emptyArray;

                        /**
                         * SecretProjection optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @instance
                         */
                        SecretProjection.prototype.optional = false;

                        /**
                         * Creates a new SecretProjection instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretProjection=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecretProjection} SecretProjection instance
                         */
                        SecretProjection.create = function create(properties) {
                            return new SecretProjection(properties);
                        };

                        /**
                         * Encodes the specified SecretProjection message. Does not implicitly {@link k8s.io.api.core.v1.SecretProjection.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretProjection} message SecretProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretProjection.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.localObjectReference != null && Object.hasOwnProperty.call(message, "localObjectReference"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.localObjectReference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.KeyToPath.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified SecretProjection message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecretProjection.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretProjection} message SecretProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretProjection.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecretProjection message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecretProjection} SecretProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretProjection.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecretProjection();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.KeyToPath.decode(reader, reader.uint32()));
                                    break;
                                case 4:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecretProjection message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecretProjection} SecretProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretProjection.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecretProjection message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecretProjection.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.localObjectReference);
                                if (error)
                                    return "localObjectReference." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.KeyToPath.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a SecretProjection message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecretProjection} SecretProjection
                         */
                        SecretProjection.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecretProjection)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecretProjection();
                            if (object.localObjectReference != null) {
                                if (typeof object.localObjectReference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecretProjection.localObjectReference: object expected");
                                message.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.localObjectReference);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.SecretProjection.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.SecretProjection.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.KeyToPath.fromObject(object.items[i]);
                                }
                            }
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecretProjection message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @static
                         * @param {k8s.io.api.core.v1.SecretProjection} message SecretProjection
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecretProjection.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults) {
                                object.localObjectReference = null;
                                object.optional = false;
                            }
                            if (message.localObjectReference != null && message.hasOwnProperty("localObjectReference"))
                                object.localObjectReference = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.localObjectReference, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.KeyToPath.toObject(message.items[j], options);
                            }
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this SecretProjection to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecretProjection
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecretProjection.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecretProjection;
                    })();

                    v1.SecretReference = (function() {

                        /**
                         * Properties of a SecretReference.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecretReference
                         * @property {string|null} [name] SecretReference name
                         * @property {string|null} [namespace] SecretReference namespace
                         */

                        /**
                         * Constructs a new SecretReference.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecretReference.
                         * @implements ISecretReference
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecretReference=} [properties] Properties to set
                         */
                        function SecretReference(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecretReference name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @instance
                         */
                        SecretReference.prototype.name = "";

                        /**
                         * SecretReference namespace.
                         * @member {string} namespace
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @instance
                         */
                        SecretReference.prototype.namespace = "";

                        /**
                         * Creates a new SecretReference instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretReference=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecretReference} SecretReference instance
                         */
                        SecretReference.create = function create(properties) {
                            return new SecretReference(properties);
                        };

                        /**
                         * Encodes the specified SecretReference message. Does not implicitly {@link k8s.io.api.core.v1.SecretReference.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretReference} message SecretReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretReference.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.namespace != null && Object.hasOwnProperty.call(message, "namespace"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.namespace);
                            return writer;
                        };

                        /**
                         * Encodes the specified SecretReference message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecretReference.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretReference} message SecretReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretReference.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecretReference message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecretReference} SecretReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretReference.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecretReference();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.namespace = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecretReference message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecretReference} SecretReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretReference.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecretReference message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecretReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.namespace != null && message.hasOwnProperty("namespace"))
                                if (!$util.isString(message.namespace))
                                    return "namespace: string expected";
                            return null;
                        };

                        /**
                         * Creates a SecretReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecretReference} SecretReference
                         */
                        SecretReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecretReference)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecretReference();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.namespace != null)
                                message.namespace = String(object.namespace);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecretReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @static
                         * @param {k8s.io.api.core.v1.SecretReference} message SecretReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecretReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.namespace = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.namespace != null && message.hasOwnProperty("namespace"))
                                object.namespace = message.namespace;
                            return object;
                        };

                        /**
                         * Converts this SecretReference to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecretReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecretReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecretReference;
                    })();

                    v1.SecretVolumeSource = (function() {

                        /**
                         * Properties of a SecretVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecretVolumeSource
                         * @property {string|null} [secretName] SecretVolumeSource secretName
                         * @property {Array.<k8s.io.api.core.v1.IKeyToPath>|null} [items] SecretVolumeSource items
                         * @property {number|null} [defaultMode] SecretVolumeSource defaultMode
                         * @property {boolean|null} [optional] SecretVolumeSource optional
                         */

                        /**
                         * Constructs a new SecretVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecretVolumeSource.
                         * @implements ISecretVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecretVolumeSource=} [properties] Properties to set
                         */
                        function SecretVolumeSource(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecretVolumeSource secretName.
                         * @member {string} secretName
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @instance
                         */
                        SecretVolumeSource.prototype.secretName = "";

                        /**
                         * SecretVolumeSource items.
                         * @member {Array.<k8s.io.api.core.v1.IKeyToPath>} items
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @instance
                         */
                        SecretVolumeSource.prototype.items = $util.emptyArray;

                        /**
                         * SecretVolumeSource defaultMode.
                         * @member {number} defaultMode
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @instance
                         */
                        SecretVolumeSource.prototype.defaultMode = 0;

                        /**
                         * SecretVolumeSource optional.
                         * @member {boolean} optional
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @instance
                         */
                        SecretVolumeSource.prototype.optional = false;

                        /**
                         * Creates a new SecretVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecretVolumeSource} SecretVolumeSource instance
                         */
                        SecretVolumeSource.create = function create(properties) {
                            return new SecretVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified SecretVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.SecretVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretVolumeSource} message SecretVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.secretName != null && Object.hasOwnProperty.call(message, "secretName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretName);
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.KeyToPath.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.defaultMode != null && Object.hasOwnProperty.call(message, "defaultMode"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.defaultMode);
                            if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.optional);
                            return writer;
                        };

                        /**
                         * Encodes the specified SecretVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecretVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.ISecretVolumeSource} message SecretVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecretVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecretVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecretVolumeSource} SecretVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecretVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.secretName = reader.string();
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.KeyToPath.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    message.defaultMode = reader.int32();
                                    break;
                                case 4:
                                    message.optional = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecretVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecretVolumeSource} SecretVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecretVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecretVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecretVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.secretName != null && message.hasOwnProperty("secretName"))
                                if (!$util.isString(message.secretName))
                                    return "secretName: string expected";
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.KeyToPath.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                if (!$util.isInteger(message.defaultMode))
                                    return "defaultMode: integer expected";
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                if (typeof message.optional !== "boolean")
                                    return "optional: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a SecretVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecretVolumeSource} SecretVolumeSource
                         */
                        SecretVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecretVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecretVolumeSource();
                            if (object.secretName != null)
                                message.secretName = String(object.secretName);
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.SecretVolumeSource.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.SecretVolumeSource.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.KeyToPath.fromObject(object.items[i]);
                                }
                            }
                            if (object.defaultMode != null)
                                message.defaultMode = object.defaultMode | 0;
                            if (object.optional != null)
                                message.optional = Boolean(object.optional);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecretVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.SecretVolumeSource} message SecretVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecretVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults) {
                                object.secretName = "";
                                object.defaultMode = 0;
                                object.optional = false;
                            }
                            if (message.secretName != null && message.hasOwnProperty("secretName"))
                                object.secretName = message.secretName;
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.KeyToPath.toObject(message.items[j], options);
                            }
                            if (message.defaultMode != null && message.hasOwnProperty("defaultMode"))
                                object.defaultMode = message.defaultMode;
                            if (message.optional != null && message.hasOwnProperty("optional"))
                                object.optional = message.optional;
                            return object;
                        };

                        /**
                         * Converts this SecretVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecretVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecretVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecretVolumeSource;
                    })();

                    v1.SecurityContext = (function() {

                        /**
                         * Properties of a SecurityContext.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISecurityContext
                         * @property {k8s.io.api.core.v1.ICapabilities|null} [capabilities] SecurityContext capabilities
                         * @property {boolean|null} [privileged] SecurityContext privileged
                         * @property {k8s.io.api.core.v1.ISELinuxOptions|null} [seLinuxOptions] SecurityContext seLinuxOptions
                         * @property {k8s.io.api.core.v1.IWindowsSecurityContextOptions|null} [windowsOptions] SecurityContext windowsOptions
                         * @property {number|Long|null} [runAsUser] SecurityContext runAsUser
                         * @property {number|Long|null} [runAsGroup] SecurityContext runAsGroup
                         * @property {boolean|null} [runAsNonRoot] SecurityContext runAsNonRoot
                         * @property {boolean|null} [readOnlyRootFilesystem] SecurityContext readOnlyRootFilesystem
                         * @property {boolean|null} [allowPrivilegeEscalation] SecurityContext allowPrivilegeEscalation
                         * @property {string|null} [procMount] SecurityContext procMount
                         * @property {k8s.io.api.core.v1.ISeccompProfile|null} [seccompProfile] SecurityContext seccompProfile
                         */

                        /**
                         * Constructs a new SecurityContext.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SecurityContext.
                         * @implements ISecurityContext
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISecurityContext=} [properties] Properties to set
                         */
                        function SecurityContext(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SecurityContext capabilities.
                         * @member {k8s.io.api.core.v1.ICapabilities|null|undefined} capabilities
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.capabilities = null;

                        /**
                         * SecurityContext privileged.
                         * @member {boolean} privileged
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.privileged = false;

                        /**
                         * SecurityContext seLinuxOptions.
                         * @member {k8s.io.api.core.v1.ISELinuxOptions|null|undefined} seLinuxOptions
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.seLinuxOptions = null;

                        /**
                         * SecurityContext windowsOptions.
                         * @member {k8s.io.api.core.v1.IWindowsSecurityContextOptions|null|undefined} windowsOptions
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.windowsOptions = null;

                        /**
                         * SecurityContext runAsUser.
                         * @member {number|Long} runAsUser
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.runAsUser = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * SecurityContext runAsGroup.
                         * @member {number|Long} runAsGroup
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.runAsGroup = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * SecurityContext runAsNonRoot.
                         * @member {boolean} runAsNonRoot
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.runAsNonRoot = false;

                        /**
                         * SecurityContext readOnlyRootFilesystem.
                         * @member {boolean} readOnlyRootFilesystem
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.readOnlyRootFilesystem = false;

                        /**
                         * SecurityContext allowPrivilegeEscalation.
                         * @member {boolean} allowPrivilegeEscalation
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.allowPrivilegeEscalation = false;

                        /**
                         * SecurityContext procMount.
                         * @member {string} procMount
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.procMount = "";

                        /**
                         * SecurityContext seccompProfile.
                         * @member {k8s.io.api.core.v1.ISeccompProfile|null|undefined} seccompProfile
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         */
                        SecurityContext.prototype.seccompProfile = null;

                        /**
                         * Creates a new SecurityContext instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.ISecurityContext=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SecurityContext} SecurityContext instance
                         */
                        SecurityContext.create = function create(properties) {
                            return new SecurityContext(properties);
                        };

                        /**
                         * Encodes the specified SecurityContext message. Does not implicitly {@link k8s.io.api.core.v1.SecurityContext.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.ISecurityContext} message SecurityContext message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecurityContext.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.capabilities != null && Object.hasOwnProperty.call(message, "capabilities"))
                                $root.k8s.io.api.core.v1.Capabilities.encode(message.capabilities, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.privileged != null && Object.hasOwnProperty.call(message, "privileged"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.privileged);
                            if (message.seLinuxOptions != null && Object.hasOwnProperty.call(message, "seLinuxOptions"))
                                $root.k8s.io.api.core.v1.SELinuxOptions.encode(message.seLinuxOptions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.runAsUser != null && Object.hasOwnProperty.call(message, "runAsUser"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.runAsUser);
                            if (message.runAsNonRoot != null && Object.hasOwnProperty.call(message, "runAsNonRoot"))
                                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.runAsNonRoot);
                            if (message.readOnlyRootFilesystem != null && Object.hasOwnProperty.call(message, "readOnlyRootFilesystem"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readOnlyRootFilesystem);
                            if (message.allowPrivilegeEscalation != null && Object.hasOwnProperty.call(message, "allowPrivilegeEscalation"))
                                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.allowPrivilegeEscalation);
                            if (message.runAsGroup != null && Object.hasOwnProperty.call(message, "runAsGroup"))
                                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.runAsGroup);
                            if (message.procMount != null && Object.hasOwnProperty.call(message, "procMount"))
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.procMount);
                            if (message.windowsOptions != null && Object.hasOwnProperty.call(message, "windowsOptions"))
                                $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.encode(message.windowsOptions, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.seccompProfile != null && Object.hasOwnProperty.call(message, "seccompProfile"))
                                $root.k8s.io.api.core.v1.SeccompProfile.encode(message.seccompProfile, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified SecurityContext message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SecurityContext.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.ISecurityContext} message SecurityContext message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SecurityContext.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SecurityContext message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SecurityContext} SecurityContext
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecurityContext.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SecurityContext();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.capabilities = $root.k8s.io.api.core.v1.Capabilities.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.privileged = reader.bool();
                                    break;
                                case 3:
                                    message.seLinuxOptions = $root.k8s.io.api.core.v1.SELinuxOptions.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.windowsOptions = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.runAsUser = reader.int64();
                                    break;
                                case 8:
                                    message.runAsGroup = reader.int64();
                                    break;
                                case 5:
                                    message.runAsNonRoot = reader.bool();
                                    break;
                                case 6:
                                    message.readOnlyRootFilesystem = reader.bool();
                                    break;
                                case 7:
                                    message.allowPrivilegeEscalation = reader.bool();
                                    break;
                                case 9:
                                    message.procMount = reader.string();
                                    break;
                                case 11:
                                    message.seccompProfile = $root.k8s.io.api.core.v1.SeccompProfile.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SecurityContext message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SecurityContext} SecurityContext
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SecurityContext.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SecurityContext message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SecurityContext.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                                var error = $root.k8s.io.api.core.v1.Capabilities.verify(message.capabilities);
                                if (error)
                                    return "capabilities." + error;
                            }
                            if (message.privileged != null && message.hasOwnProperty("privileged"))
                                if (typeof message.privileged !== "boolean")
                                    return "privileged: boolean expected";
                            if (message.seLinuxOptions != null && message.hasOwnProperty("seLinuxOptions")) {
                                var error = $root.k8s.io.api.core.v1.SELinuxOptions.verify(message.seLinuxOptions);
                                if (error)
                                    return "seLinuxOptions." + error;
                            }
                            if (message.windowsOptions != null && message.hasOwnProperty("windowsOptions")) {
                                var error = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.verify(message.windowsOptions);
                                if (error)
                                    return "windowsOptions." + error;
                            }
                            if (message.runAsUser != null && message.hasOwnProperty("runAsUser"))
                                if (!$util.isInteger(message.runAsUser) && !(message.runAsUser && $util.isInteger(message.runAsUser.low) && $util.isInteger(message.runAsUser.high)))
                                    return "runAsUser: integer|Long expected";
                            if (message.runAsGroup != null && message.hasOwnProperty("runAsGroup"))
                                if (!$util.isInteger(message.runAsGroup) && !(message.runAsGroup && $util.isInteger(message.runAsGroup.low) && $util.isInteger(message.runAsGroup.high)))
                                    return "runAsGroup: integer|Long expected";
                            if (message.runAsNonRoot != null && message.hasOwnProperty("runAsNonRoot"))
                                if (typeof message.runAsNonRoot !== "boolean")
                                    return "runAsNonRoot: boolean expected";
                            if (message.readOnlyRootFilesystem != null && message.hasOwnProperty("readOnlyRootFilesystem"))
                                if (typeof message.readOnlyRootFilesystem !== "boolean")
                                    return "readOnlyRootFilesystem: boolean expected";
                            if (message.allowPrivilegeEscalation != null && message.hasOwnProperty("allowPrivilegeEscalation"))
                                if (typeof message.allowPrivilegeEscalation !== "boolean")
                                    return "allowPrivilegeEscalation: boolean expected";
                            if (message.procMount != null && message.hasOwnProperty("procMount"))
                                if (!$util.isString(message.procMount))
                                    return "procMount: string expected";
                            if (message.seccompProfile != null && message.hasOwnProperty("seccompProfile")) {
                                var error = $root.k8s.io.api.core.v1.SeccompProfile.verify(message.seccompProfile);
                                if (error)
                                    return "seccompProfile." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a SecurityContext message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SecurityContext} SecurityContext
                         */
                        SecurityContext.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SecurityContext)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SecurityContext();
                            if (object.capabilities != null) {
                                if (typeof object.capabilities !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecurityContext.capabilities: object expected");
                                message.capabilities = $root.k8s.io.api.core.v1.Capabilities.fromObject(object.capabilities);
                            }
                            if (object.privileged != null)
                                message.privileged = Boolean(object.privileged);
                            if (object.seLinuxOptions != null) {
                                if (typeof object.seLinuxOptions !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecurityContext.seLinuxOptions: object expected");
                                message.seLinuxOptions = $root.k8s.io.api.core.v1.SELinuxOptions.fromObject(object.seLinuxOptions);
                            }
                            if (object.windowsOptions != null) {
                                if (typeof object.windowsOptions !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecurityContext.windowsOptions: object expected");
                                message.windowsOptions = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.fromObject(object.windowsOptions);
                            }
                            if (object.runAsUser != null)
                                if ($util.Long)
                                    (message.runAsUser = $util.Long.fromValue(object.runAsUser)).unsigned = false;
                                else if (typeof object.runAsUser === "string")
                                    message.runAsUser = parseInt(object.runAsUser, 10);
                                else if (typeof object.runAsUser === "number")
                                    message.runAsUser = object.runAsUser;
                                else if (typeof object.runAsUser === "object")
                                    message.runAsUser = new $util.LongBits(object.runAsUser.low >>> 0, object.runAsUser.high >>> 0).toNumber();
                            if (object.runAsGroup != null)
                                if ($util.Long)
                                    (message.runAsGroup = $util.Long.fromValue(object.runAsGroup)).unsigned = false;
                                else if (typeof object.runAsGroup === "string")
                                    message.runAsGroup = parseInt(object.runAsGroup, 10);
                                else if (typeof object.runAsGroup === "number")
                                    message.runAsGroup = object.runAsGroup;
                                else if (typeof object.runAsGroup === "object")
                                    message.runAsGroup = new $util.LongBits(object.runAsGroup.low >>> 0, object.runAsGroup.high >>> 0).toNumber();
                            if (object.runAsNonRoot != null)
                                message.runAsNonRoot = Boolean(object.runAsNonRoot);
                            if (object.readOnlyRootFilesystem != null)
                                message.readOnlyRootFilesystem = Boolean(object.readOnlyRootFilesystem);
                            if (object.allowPrivilegeEscalation != null)
                                message.allowPrivilegeEscalation = Boolean(object.allowPrivilegeEscalation);
                            if (object.procMount != null)
                                message.procMount = String(object.procMount);
                            if (object.seccompProfile != null) {
                                if (typeof object.seccompProfile !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SecurityContext.seccompProfile: object expected");
                                message.seccompProfile = $root.k8s.io.api.core.v1.SeccompProfile.fromObject(object.seccompProfile);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a SecurityContext message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @static
                         * @param {k8s.io.api.core.v1.SecurityContext} message SecurityContext
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SecurityContext.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.capabilities = null;
                                object.privileged = false;
                                object.seLinuxOptions = null;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.runAsUser = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.runAsUser = options.longs === String ? "0" : 0;
                                object.runAsNonRoot = false;
                                object.readOnlyRootFilesystem = false;
                                object.allowPrivilegeEscalation = false;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.runAsGroup = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.runAsGroup = options.longs === String ? "0" : 0;
                                object.procMount = "";
                                object.windowsOptions = null;
                                object.seccompProfile = null;
                            }
                            if (message.capabilities != null && message.hasOwnProperty("capabilities"))
                                object.capabilities = $root.k8s.io.api.core.v1.Capabilities.toObject(message.capabilities, options);
                            if (message.privileged != null && message.hasOwnProperty("privileged"))
                                object.privileged = message.privileged;
                            if (message.seLinuxOptions != null && message.hasOwnProperty("seLinuxOptions"))
                                object.seLinuxOptions = $root.k8s.io.api.core.v1.SELinuxOptions.toObject(message.seLinuxOptions, options);
                            if (message.runAsUser != null && message.hasOwnProperty("runAsUser"))
                                if (typeof message.runAsUser === "number")
                                    object.runAsUser = options.longs === String ? String(message.runAsUser) : message.runAsUser;
                                else
                                    object.runAsUser = options.longs === String ? $util.Long.prototype.toString.call(message.runAsUser) : options.longs === Number ? new $util.LongBits(message.runAsUser.low >>> 0, message.runAsUser.high >>> 0).toNumber() : message.runAsUser;
                            if (message.runAsNonRoot != null && message.hasOwnProperty("runAsNonRoot"))
                                object.runAsNonRoot = message.runAsNonRoot;
                            if (message.readOnlyRootFilesystem != null && message.hasOwnProperty("readOnlyRootFilesystem"))
                                object.readOnlyRootFilesystem = message.readOnlyRootFilesystem;
                            if (message.allowPrivilegeEscalation != null && message.hasOwnProperty("allowPrivilegeEscalation"))
                                object.allowPrivilegeEscalation = message.allowPrivilegeEscalation;
                            if (message.runAsGroup != null && message.hasOwnProperty("runAsGroup"))
                                if (typeof message.runAsGroup === "number")
                                    object.runAsGroup = options.longs === String ? String(message.runAsGroup) : message.runAsGroup;
                                else
                                    object.runAsGroup = options.longs === String ? $util.Long.prototype.toString.call(message.runAsGroup) : options.longs === Number ? new $util.LongBits(message.runAsGroup.low >>> 0, message.runAsGroup.high >>> 0).toNumber() : message.runAsGroup;
                            if (message.procMount != null && message.hasOwnProperty("procMount"))
                                object.procMount = message.procMount;
                            if (message.windowsOptions != null && message.hasOwnProperty("windowsOptions"))
                                object.windowsOptions = $root.k8s.io.api.core.v1.WindowsSecurityContextOptions.toObject(message.windowsOptions, options);
                            if (message.seccompProfile != null && message.hasOwnProperty("seccompProfile"))
                                object.seccompProfile = $root.k8s.io.api.core.v1.SeccompProfile.toObject(message.seccompProfile, options);
                            return object;
                        };

                        /**
                         * Converts this SecurityContext to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SecurityContext
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SecurityContext.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SecurityContext;
                    })();

                    v1.SerializedReference = (function() {

                        /**
                         * Properties of a SerializedReference.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISerializedReference
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [reference] SerializedReference reference
                         */

                        /**
                         * Constructs a new SerializedReference.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SerializedReference.
                         * @implements ISerializedReference
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISerializedReference=} [properties] Properties to set
                         */
                        function SerializedReference(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SerializedReference reference.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} reference
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @instance
                         */
                        SerializedReference.prototype.reference = null;

                        /**
                         * Creates a new SerializedReference instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {k8s.io.api.core.v1.ISerializedReference=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SerializedReference} SerializedReference instance
                         */
                        SerializedReference.create = function create(properties) {
                            return new SerializedReference(properties);
                        };

                        /**
                         * Encodes the specified SerializedReference message. Does not implicitly {@link k8s.io.api.core.v1.SerializedReference.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {k8s.io.api.core.v1.ISerializedReference} message SerializedReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SerializedReference.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.reference, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified SerializedReference message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SerializedReference.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {k8s.io.api.core.v1.ISerializedReference} message SerializedReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SerializedReference.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SerializedReference message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SerializedReference} SerializedReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SerializedReference.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SerializedReference();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.reference = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SerializedReference message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SerializedReference} SerializedReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SerializedReference.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SerializedReference message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SerializedReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.reference != null && message.hasOwnProperty("reference")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.reference);
                                if (error)
                                    return "reference." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a SerializedReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SerializedReference} SerializedReference
                         */
                        SerializedReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SerializedReference)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SerializedReference();
                            if (object.reference != null) {
                                if (typeof object.reference !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SerializedReference.reference: object expected");
                                message.reference = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.reference);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a SerializedReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @static
                         * @param {k8s.io.api.core.v1.SerializedReference} message SerializedReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SerializedReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.reference = null;
                            if (message.reference != null && message.hasOwnProperty("reference"))
                                object.reference = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.reference, options);
                            return object;
                        };

                        /**
                         * Converts this SerializedReference to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SerializedReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SerializedReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SerializedReference;
                    })();

                    v1.Service = (function() {

                        /**
                         * Properties of a Service.
                         * @memberof k8s.io.api.core.v1
                         * @interface IService
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] Service metadata
                         * @property {k8s.io.api.core.v1.IServiceSpec|null} [spec] Service spec
                         * @property {k8s.io.api.core.v1.IServiceStatus|null} [status] Service status
                         */

                        /**
                         * Constructs a new Service.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Service.
                         * @implements IService
                         * @constructor
                         * @param {k8s.io.api.core.v1.IService=} [properties] Properties to set
                         */
                        function Service(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Service metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.Service
                         * @instance
                         */
                        Service.prototype.metadata = null;

                        /**
                         * Service spec.
                         * @member {k8s.io.api.core.v1.IServiceSpec|null|undefined} spec
                         * @memberof k8s.io.api.core.v1.Service
                         * @instance
                         */
                        Service.prototype.spec = null;

                        /**
                         * Service status.
                         * @member {k8s.io.api.core.v1.IServiceStatus|null|undefined} status
                         * @memberof k8s.io.api.core.v1.Service
                         * @instance
                         */
                        Service.prototype.status = null;

                        /**
                         * Creates a new Service instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {k8s.io.api.core.v1.IService=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Service} Service instance
                         */
                        Service.create = function create(properties) {
                            return new Service(properties);
                        };

                        /**
                         * Encodes the specified Service message. Does not implicitly {@link k8s.io.api.core.v1.Service.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {k8s.io.api.core.v1.IService} message Service message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Service.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
                                $root.k8s.io.api.core.v1.ServiceSpec.encode(message.spec, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                $root.k8s.io.api.core.v1.ServiceStatus.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Service message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Service.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {k8s.io.api.core.v1.IService} message Service message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Service.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Service message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Service} Service
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Service.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Service();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.spec = $root.k8s.io.api.core.v1.ServiceSpec.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.status = $root.k8s.io.api.core.v1.ServiceStatus.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Service message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Service} Service
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Service.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Service message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Service.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.spec != null && message.hasOwnProperty("spec")) {
                                var error = $root.k8s.io.api.core.v1.ServiceSpec.verify(message.spec);
                                if (error)
                                    return "spec." + error;
                            }
                            if (message.status != null && message.hasOwnProperty("status")) {
                                var error = $root.k8s.io.api.core.v1.ServiceStatus.verify(message.status);
                                if (error)
                                    return "status." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Service message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Service} Service
                         */
                        Service.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Service)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Service();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Service.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.spec != null) {
                                if (typeof object.spec !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Service.spec: object expected");
                                message.spec = $root.k8s.io.api.core.v1.ServiceSpec.fromObject(object.spec);
                            }
                            if (object.status != null) {
                                if (typeof object.status !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Service.status: object expected");
                                message.status = $root.k8s.io.api.core.v1.ServiceStatus.fromObject(object.status);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Service message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Service
                         * @static
                         * @param {k8s.io.api.core.v1.Service} message Service
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Service.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.metadata = null;
                                object.spec = null;
                                object.status = null;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.spec != null && message.hasOwnProperty("spec"))
                                object.spec = $root.k8s.io.api.core.v1.ServiceSpec.toObject(message.spec, options);
                            if (message.status != null && message.hasOwnProperty("status"))
                                object.status = $root.k8s.io.api.core.v1.ServiceStatus.toObject(message.status, options);
                            return object;
                        };

                        /**
                         * Converts this Service to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Service
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Service.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Service;
                    })();

                    v1.ServiceAccount = (function() {

                        /**
                         * Properties of a ServiceAccount.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceAccount
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] ServiceAccount metadata
                         * @property {Array.<k8s.io.api.core.v1.IObjectReference>|null} [secrets] ServiceAccount secrets
                         * @property {Array.<k8s.io.api.core.v1.ILocalObjectReference>|null} [imagePullSecrets] ServiceAccount imagePullSecrets
                         * @property {boolean|null} [automountServiceAccountToken] ServiceAccount automountServiceAccountToken
                         */

                        /**
                         * Constructs a new ServiceAccount.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceAccount.
                         * @implements IServiceAccount
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceAccount=} [properties] Properties to set
                         */
                        function ServiceAccount(properties) {
                            this.secrets = [];
                            this.imagePullSecrets = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceAccount metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @instance
                         */
                        ServiceAccount.prototype.metadata = null;

                        /**
                         * ServiceAccount secrets.
                         * @member {Array.<k8s.io.api.core.v1.IObjectReference>} secrets
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @instance
                         */
                        ServiceAccount.prototype.secrets = $util.emptyArray;

                        /**
                         * ServiceAccount imagePullSecrets.
                         * @member {Array.<k8s.io.api.core.v1.ILocalObjectReference>} imagePullSecrets
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @instance
                         */
                        ServiceAccount.prototype.imagePullSecrets = $util.emptyArray;

                        /**
                         * ServiceAccount automountServiceAccountToken.
                         * @member {boolean} automountServiceAccountToken
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @instance
                         */
                        ServiceAccount.prototype.automountServiceAccountToken = false;

                        /**
                         * Creates a new ServiceAccount instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccount=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceAccount} ServiceAccount instance
                         */
                        ServiceAccount.create = function create(properties) {
                            return new ServiceAccount(properties);
                        };

                        /**
                         * Encodes the specified ServiceAccount message. Does not implicitly {@link k8s.io.api.core.v1.ServiceAccount.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccount} message ServiceAccount message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceAccount.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.secrets != null && message.secrets.length)
                                for (var i = 0; i < message.secrets.length; ++i)
                                    $root.k8s.io.api.core.v1.ObjectReference.encode(message.secrets[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.imagePullSecrets != null && message.imagePullSecrets.length)
                                for (var i = 0; i < message.imagePullSecrets.length; ++i)
                                    $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.imagePullSecrets[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.automountServiceAccountToken != null && Object.hasOwnProperty.call(message, "automountServiceAccountToken"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.automountServiceAccountToken);
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceAccount message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceAccount.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccount} message ServiceAccount message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceAccount.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceAccount message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceAccount} ServiceAccount
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceAccount.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceAccount();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.secrets && message.secrets.length))
                                        message.secrets = [];
                                    message.secrets.push($root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    if (!(message.imagePullSecrets && message.imagePullSecrets.length))
                                        message.imagePullSecrets = [];
                                    message.imagePullSecrets.push($root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32()));
                                    break;
                                case 4:
                                    message.automountServiceAccountToken = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceAccount message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceAccount} ServiceAccount
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceAccount.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceAccount message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceAccount.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.secrets != null && message.hasOwnProperty("secrets")) {
                                if (!Array.isArray(message.secrets))
                                    return "secrets: array expected";
                                for (var i = 0; i < message.secrets.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.secrets[i]);
                                    if (error)
                                        return "secrets." + error;
                                }
                            }
                            if (message.imagePullSecrets != null && message.hasOwnProperty("imagePullSecrets")) {
                                if (!Array.isArray(message.imagePullSecrets))
                                    return "imagePullSecrets: array expected";
                                for (var i = 0; i < message.imagePullSecrets.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.imagePullSecrets[i]);
                                    if (error)
                                        return "imagePullSecrets." + error;
                                }
                            }
                            if (message.automountServiceAccountToken != null && message.hasOwnProperty("automountServiceAccountToken"))
                                if (typeof message.automountServiceAccountToken !== "boolean")
                                    return "automountServiceAccountToken: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ServiceAccount message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceAccount} ServiceAccount
                         */
                        ServiceAccount.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceAccount)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceAccount();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServiceAccount.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                            }
                            if (object.secrets) {
                                if (!Array.isArray(object.secrets))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceAccount.secrets: array expected");
                                message.secrets = [];
                                for (var i = 0; i < object.secrets.length; ++i) {
                                    if (typeof object.secrets[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ServiceAccount.secrets: object expected");
                                    message.secrets[i] = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.secrets[i]);
                                }
                            }
                            if (object.imagePullSecrets) {
                                if (!Array.isArray(object.imagePullSecrets))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceAccount.imagePullSecrets: array expected");
                                message.imagePullSecrets = [];
                                for (var i = 0; i < object.imagePullSecrets.length; ++i) {
                                    if (typeof object.imagePullSecrets[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ServiceAccount.imagePullSecrets: object expected");
                                    message.imagePullSecrets[i] = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.imagePullSecrets[i]);
                                }
                            }
                            if (object.automountServiceAccountToken != null)
                                message.automountServiceAccountToken = Boolean(object.automountServiceAccountToken);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceAccount message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceAccount} message ServiceAccount
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceAccount.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.secrets = [];
                                object.imagePullSecrets = [];
                            }
                            if (options.defaults) {
                                object.metadata = null;
                                object.automountServiceAccountToken = false;
                            }
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                            if (message.secrets && message.secrets.length) {
                                object.secrets = [];
                                for (var j = 0; j < message.secrets.length; ++j)
                                    object.secrets[j] = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.secrets[j], options);
                            }
                            if (message.imagePullSecrets && message.imagePullSecrets.length) {
                                object.imagePullSecrets = [];
                                for (var j = 0; j < message.imagePullSecrets.length; ++j)
                                    object.imagePullSecrets[j] = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.imagePullSecrets[j], options);
                            }
                            if (message.automountServiceAccountToken != null && message.hasOwnProperty("automountServiceAccountToken"))
                                object.automountServiceAccountToken = message.automountServiceAccountToken;
                            return object;
                        };

                        /**
                         * Converts this ServiceAccount to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceAccount
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceAccount.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceAccount;
                    })();

                    v1.ServiceAccountList = (function() {

                        /**
                         * Properties of a ServiceAccountList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceAccountList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] ServiceAccountList metadata
                         * @property {Array.<k8s.io.api.core.v1.IServiceAccount>|null} [items] ServiceAccountList items
                         */

                        /**
                         * Constructs a new ServiceAccountList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceAccountList.
                         * @implements IServiceAccountList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceAccountList=} [properties] Properties to set
                         */
                        function ServiceAccountList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceAccountList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @instance
                         */
                        ServiceAccountList.prototype.metadata = null;

                        /**
                         * ServiceAccountList items.
                         * @member {Array.<k8s.io.api.core.v1.IServiceAccount>} items
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @instance
                         */
                        ServiceAccountList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new ServiceAccountList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccountList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceAccountList} ServiceAccountList instance
                         */
                        ServiceAccountList.create = function create(properties) {
                            return new ServiceAccountList(properties);
                        };

                        /**
                         * Encodes the specified ServiceAccountList message. Does not implicitly {@link k8s.io.api.core.v1.ServiceAccountList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccountList} message ServiceAccountList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceAccountList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.ServiceAccount.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceAccountList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceAccountList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccountList} message ServiceAccountList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceAccountList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceAccountList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceAccountList} ServiceAccountList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceAccountList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceAccountList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.ServiceAccount.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceAccountList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceAccountList} ServiceAccountList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceAccountList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceAccountList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceAccountList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ServiceAccount.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ServiceAccountList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceAccountList} ServiceAccountList
                         */
                        ServiceAccountList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceAccountList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceAccountList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServiceAccountList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceAccountList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ServiceAccountList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.ServiceAccount.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceAccountList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceAccountList} message ServiceAccountList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceAccountList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.ServiceAccount.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ServiceAccountList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceAccountList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceAccountList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceAccountList;
                    })();

                    v1.ServiceAccountTokenProjection = (function() {

                        /**
                         * Properties of a ServiceAccountTokenProjection.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceAccountTokenProjection
                         * @property {string|null} [audience] ServiceAccountTokenProjection audience
                         * @property {number|Long|null} [expirationSeconds] ServiceAccountTokenProjection expirationSeconds
                         * @property {string|null} [path] ServiceAccountTokenProjection path
                         */

                        /**
                         * Constructs a new ServiceAccountTokenProjection.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceAccountTokenProjection.
                         * @implements IServiceAccountTokenProjection
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceAccountTokenProjection=} [properties] Properties to set
                         */
                        function ServiceAccountTokenProjection(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceAccountTokenProjection audience.
                         * @member {string} audience
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @instance
                         */
                        ServiceAccountTokenProjection.prototype.audience = "";

                        /**
                         * ServiceAccountTokenProjection expirationSeconds.
                         * @member {number|Long} expirationSeconds
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @instance
                         */
                        ServiceAccountTokenProjection.prototype.expirationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * ServiceAccountTokenProjection path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @instance
                         */
                        ServiceAccountTokenProjection.prototype.path = "";

                        /**
                         * Creates a new ServiceAccountTokenProjection instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccountTokenProjection=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceAccountTokenProjection} ServiceAccountTokenProjection instance
                         */
                        ServiceAccountTokenProjection.create = function create(properties) {
                            return new ServiceAccountTokenProjection(properties);
                        };

                        /**
                         * Encodes the specified ServiceAccountTokenProjection message. Does not implicitly {@link k8s.io.api.core.v1.ServiceAccountTokenProjection.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccountTokenProjection} message ServiceAccountTokenProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceAccountTokenProjection.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.audience != null && Object.hasOwnProperty.call(message, "audience"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.audience);
                            if (message.expirationSeconds != null && Object.hasOwnProperty.call(message, "expirationSeconds"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.expirationSeconds);
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.path);
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceAccountTokenProjection message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceAccountTokenProjection.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceAccountTokenProjection} message ServiceAccountTokenProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceAccountTokenProjection.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceAccountTokenProjection message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceAccountTokenProjection} ServiceAccountTokenProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceAccountTokenProjection.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceAccountTokenProjection();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.audience = reader.string();
                                    break;
                                case 2:
                                    message.expirationSeconds = reader.int64();
                                    break;
                                case 3:
                                    message.path = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceAccountTokenProjection message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceAccountTokenProjection} ServiceAccountTokenProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceAccountTokenProjection.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceAccountTokenProjection message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceAccountTokenProjection.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.audience != null && message.hasOwnProperty("audience"))
                                if (!$util.isString(message.audience))
                                    return "audience: string expected";
                            if (message.expirationSeconds != null && message.hasOwnProperty("expirationSeconds"))
                                if (!$util.isInteger(message.expirationSeconds) && !(message.expirationSeconds && $util.isInteger(message.expirationSeconds.low) && $util.isInteger(message.expirationSeconds.high)))
                                    return "expirationSeconds: integer|Long expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            return null;
                        };

                        /**
                         * Creates a ServiceAccountTokenProjection message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceAccountTokenProjection} ServiceAccountTokenProjection
                         */
                        ServiceAccountTokenProjection.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceAccountTokenProjection)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceAccountTokenProjection();
                            if (object.audience != null)
                                message.audience = String(object.audience);
                            if (object.expirationSeconds != null)
                                if ($util.Long)
                                    (message.expirationSeconds = $util.Long.fromValue(object.expirationSeconds)).unsigned = false;
                                else if (typeof object.expirationSeconds === "string")
                                    message.expirationSeconds = parseInt(object.expirationSeconds, 10);
                                else if (typeof object.expirationSeconds === "number")
                                    message.expirationSeconds = object.expirationSeconds;
                                else if (typeof object.expirationSeconds === "object")
                                    message.expirationSeconds = new $util.LongBits(object.expirationSeconds.low >>> 0, object.expirationSeconds.high >>> 0).toNumber();
                            if (object.path != null)
                                message.path = String(object.path);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceAccountTokenProjection message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceAccountTokenProjection} message ServiceAccountTokenProjection
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceAccountTokenProjection.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.audience = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.expirationSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.expirationSeconds = options.longs === String ? "0" : 0;
                                object.path = "";
                            }
                            if (message.audience != null && message.hasOwnProperty("audience"))
                                object.audience = message.audience;
                            if (message.expirationSeconds != null && message.hasOwnProperty("expirationSeconds"))
                                if (typeof message.expirationSeconds === "number")
                                    object.expirationSeconds = options.longs === String ? String(message.expirationSeconds) : message.expirationSeconds;
                                else
                                    object.expirationSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.expirationSeconds) : options.longs === Number ? new $util.LongBits(message.expirationSeconds.low >>> 0, message.expirationSeconds.high >>> 0).toNumber() : message.expirationSeconds;
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            return object;
                        };

                        /**
                         * Converts this ServiceAccountTokenProjection to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceAccountTokenProjection
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceAccountTokenProjection.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceAccountTokenProjection;
                    })();

                    v1.ServiceList = (function() {

                        /**
                         * Properties of a ServiceList.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceList
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] ServiceList metadata
                         * @property {Array.<k8s.io.api.core.v1.IService>|null} [items] ServiceList items
                         */

                        /**
                         * Constructs a new ServiceList.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceList.
                         * @implements IServiceList
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceList=} [properties] Properties to set
                         */
                        function ServiceList(properties) {
                            this.items = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceList metadata.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @instance
                         */
                        ServiceList.prototype.metadata = null;

                        /**
                         * ServiceList items.
                         * @member {Array.<k8s.io.api.core.v1.IService>} items
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @instance
                         */
                        ServiceList.prototype.items = $util.emptyArray;

                        /**
                         * Creates a new ServiceList instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceList=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceList} ServiceList instance
                         */
                        ServiceList.create = function create(properties) {
                            return new ServiceList(properties);
                        };

                        /**
                         * Encodes the specified ServiceList message. Does not implicitly {@link k8s.io.api.core.v1.ServiceList.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceList} message ServiceList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceList.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.items != null && message.items.length)
                                for (var i = 0; i < message.items.length; ++i)
                                    $root.k8s.io.api.core.v1.Service.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceList message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceList.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceList} message ServiceList message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceList.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceList message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceList} ServiceList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceList.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceList();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.items && message.items.length))
                                        message.items = [];
                                    message.items.push($root.k8s.io.api.core.v1.Service.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceList message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceList} ServiceList
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceList.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceList message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceList.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                if (error)
                                    return "metadata." + error;
                            }
                            if (message.items != null && message.hasOwnProperty("items")) {
                                if (!Array.isArray(message.items))
                                    return "items: array expected";
                                for (var i = 0; i < message.items.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.Service.verify(message.items[i]);
                                    if (error)
                                        return "items." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ServiceList message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceList} ServiceList
                         */
                        ServiceList.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceList)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceList();
                            if (object.metadata != null) {
                                if (typeof object.metadata !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServiceList.metadata: object expected");
                                message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                            }
                            if (object.items) {
                                if (!Array.isArray(object.items))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceList.items: array expected");
                                message.items = [];
                                for (var i = 0; i < object.items.length; ++i) {
                                    if (typeof object.items[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ServiceList.items: object expected");
                                    message.items[i] = $root.k8s.io.api.core.v1.Service.fromObject(object.items[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceList message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceList} message ServiceList
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceList.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.items = [];
                            if (options.defaults)
                                object.metadata = null;
                            if (message.metadata != null && message.hasOwnProperty("metadata"))
                                object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                            if (message.items && message.items.length) {
                                object.items = [];
                                for (var j = 0; j < message.items.length; ++j)
                                    object.items[j] = $root.k8s.io.api.core.v1.Service.toObject(message.items[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ServiceList to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceList
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceList.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceList;
                    })();

                    v1.ServicePort = (function() {

                        /**
                         * Properties of a ServicePort.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServicePort
                         * @property {string|null} [name] ServicePort name
                         * @property {string|null} [protocol] ServicePort protocol
                         * @property {string|null} [appProtocol] ServicePort appProtocol
                         * @property {number|null} [port] ServicePort port
                         * @property {k8s.io.apimachinery.pkg.util.intstr.IIntOrString|null} [targetPort] ServicePort targetPort
                         * @property {number|null} [nodePort] ServicePort nodePort
                         */

                        /**
                         * Constructs a new ServicePort.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServicePort.
                         * @implements IServicePort
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServicePort=} [properties] Properties to set
                         */
                        function ServicePort(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServicePort name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         */
                        ServicePort.prototype.name = "";

                        /**
                         * ServicePort protocol.
                         * @member {string} protocol
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         */
                        ServicePort.prototype.protocol = "";

                        /**
                         * ServicePort appProtocol.
                         * @member {string} appProtocol
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         */
                        ServicePort.prototype.appProtocol = "";

                        /**
                         * ServicePort port.
                         * @member {number} port
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         */
                        ServicePort.prototype.port = 0;

                        /**
                         * ServicePort targetPort.
                         * @member {k8s.io.apimachinery.pkg.util.intstr.IIntOrString|null|undefined} targetPort
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         */
                        ServicePort.prototype.targetPort = null;

                        /**
                         * ServicePort nodePort.
                         * @member {number} nodePort
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         */
                        ServicePort.prototype.nodePort = 0;

                        /**
                         * Creates a new ServicePort instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {k8s.io.api.core.v1.IServicePort=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServicePort} ServicePort instance
                         */
                        ServicePort.create = function create(properties) {
                            return new ServicePort(properties);
                        };

                        /**
                         * Encodes the specified ServicePort message. Does not implicitly {@link k8s.io.api.core.v1.ServicePort.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {k8s.io.api.core.v1.IServicePort} message ServicePort message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServicePort.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.protocol != null && Object.hasOwnProperty.call(message, "protocol"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.protocol);
                            if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.port);
                            if (message.targetPort != null && Object.hasOwnProperty.call(message, "targetPort"))
                                $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.encode(message.targetPort, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.nodePort != null && Object.hasOwnProperty.call(message, "nodePort"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.nodePort);
                            if (message.appProtocol != null && Object.hasOwnProperty.call(message, "appProtocol"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.appProtocol);
                            return writer;
                        };

                        /**
                         * Encodes the specified ServicePort message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServicePort.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {k8s.io.api.core.v1.IServicePort} message ServicePort message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServicePort.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServicePort message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServicePort} ServicePort
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServicePort.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServicePort();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.protocol = reader.string();
                                    break;
                                case 6:
                                    message.appProtocol = reader.string();
                                    break;
                                case 3:
                                    message.port = reader.int32();
                                    break;
                                case 4:
                                    message.targetPort = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.nodePort = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServicePort message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServicePort} ServicePort
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServicePort.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServicePort message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServicePort.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                if (!$util.isString(message.protocol))
                                    return "protocol: string expected";
                            if (message.appProtocol != null && message.hasOwnProperty("appProtocol"))
                                if (!$util.isString(message.appProtocol))
                                    return "appProtocol: string expected";
                            if (message.port != null && message.hasOwnProperty("port"))
                                if (!$util.isInteger(message.port))
                                    return "port: integer expected";
                            if (message.targetPort != null && message.hasOwnProperty("targetPort")) {
                                var error = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.verify(message.targetPort);
                                if (error)
                                    return "targetPort." + error;
                            }
                            if (message.nodePort != null && message.hasOwnProperty("nodePort"))
                                if (!$util.isInteger(message.nodePort))
                                    return "nodePort: integer expected";
                            return null;
                        };

                        /**
                         * Creates a ServicePort message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServicePort} ServicePort
                         */
                        ServicePort.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServicePort)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServicePort();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.protocol != null)
                                message.protocol = String(object.protocol);
                            if (object.appProtocol != null)
                                message.appProtocol = String(object.appProtocol);
                            if (object.port != null)
                                message.port = object.port | 0;
                            if (object.targetPort != null) {
                                if (typeof object.targetPort !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServicePort.targetPort: object expected");
                                message.targetPort = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.fromObject(object.targetPort);
                            }
                            if (object.nodePort != null)
                                message.nodePort = object.nodePort | 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServicePort message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @static
                         * @param {k8s.io.api.core.v1.ServicePort} message ServicePort
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServicePort.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.protocol = "";
                                object.port = 0;
                                object.targetPort = null;
                                object.nodePort = 0;
                                object.appProtocol = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.protocol != null && message.hasOwnProperty("protocol"))
                                object.protocol = message.protocol;
                            if (message.port != null && message.hasOwnProperty("port"))
                                object.port = message.port;
                            if (message.targetPort != null && message.hasOwnProperty("targetPort"))
                                object.targetPort = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.toObject(message.targetPort, options);
                            if (message.nodePort != null && message.hasOwnProperty("nodePort"))
                                object.nodePort = message.nodePort;
                            if (message.appProtocol != null && message.hasOwnProperty("appProtocol"))
                                object.appProtocol = message.appProtocol;
                            return object;
                        };

                        /**
                         * Converts this ServicePort to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServicePort
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServicePort.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServicePort;
                    })();

                    v1.ServiceProxyOptions = (function() {

                        /**
                         * Properties of a ServiceProxyOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceProxyOptions
                         * @property {string|null} [path] ServiceProxyOptions path
                         */

                        /**
                         * Constructs a new ServiceProxyOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceProxyOptions.
                         * @implements IServiceProxyOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceProxyOptions=} [properties] Properties to set
                         */
                        function ServiceProxyOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceProxyOptions path.
                         * @member {string} path
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @instance
                         */
                        ServiceProxyOptions.prototype.path = "";

                        /**
                         * Creates a new ServiceProxyOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceProxyOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceProxyOptions} ServiceProxyOptions instance
                         */
                        ServiceProxyOptions.create = function create(properties) {
                            return new ServiceProxyOptions(properties);
                        };

                        /**
                         * Encodes the specified ServiceProxyOptions message. Does not implicitly {@link k8s.io.api.core.v1.ServiceProxyOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceProxyOptions} message ServiceProxyOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceProxyOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceProxyOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceProxyOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceProxyOptions} message ServiceProxyOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceProxyOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceProxyOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceProxyOptions} ServiceProxyOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceProxyOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceProxyOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.path = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceProxyOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceProxyOptions} ServiceProxyOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceProxyOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceProxyOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceProxyOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.path != null && message.hasOwnProperty("path"))
                                if (!$util.isString(message.path))
                                    return "path: string expected";
                            return null;
                        };

                        /**
                         * Creates a ServiceProxyOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceProxyOptions} ServiceProxyOptions
                         */
                        ServiceProxyOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceProxyOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceProxyOptions();
                            if (object.path != null)
                                message.path = String(object.path);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceProxyOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceProxyOptions} message ServiceProxyOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceProxyOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.path = "";
                            if (message.path != null && message.hasOwnProperty("path"))
                                object.path = message.path;
                            return object;
                        };

                        /**
                         * Converts this ServiceProxyOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceProxyOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceProxyOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceProxyOptions;
                    })();

                    v1.ServiceSpec = (function() {

                        /**
                         * Properties of a ServiceSpec.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceSpec
                         * @property {Array.<k8s.io.api.core.v1.IServicePort>|null} [ports] ServiceSpec ports
                         * @property {Object.<string,string>|null} [selector] ServiceSpec selector
                         * @property {string|null} [clusterIP] ServiceSpec clusterIP
                         * @property {Array.<string>|null} [clusterIPs] ServiceSpec clusterIPs
                         * @property {string|null} [type] ServiceSpec type
                         * @property {Array.<string>|null} [externalIPs] ServiceSpec externalIPs
                         * @property {string|null} [sessionAffinity] ServiceSpec sessionAffinity
                         * @property {string|null} [loadBalancerIP] ServiceSpec loadBalancerIP
                         * @property {Array.<string>|null} [loadBalancerSourceRanges] ServiceSpec loadBalancerSourceRanges
                         * @property {string|null} [externalName] ServiceSpec externalName
                         * @property {string|null} [externalTrafficPolicy] ServiceSpec externalTrafficPolicy
                         * @property {number|null} [healthCheckNodePort] ServiceSpec healthCheckNodePort
                         * @property {boolean|null} [publishNotReadyAddresses] ServiceSpec publishNotReadyAddresses
                         * @property {k8s.io.api.core.v1.ISessionAffinityConfig|null} [sessionAffinityConfig] ServiceSpec sessionAffinityConfig
                         * @property {Array.<string>|null} [topologyKeys] ServiceSpec topologyKeys
                         * @property {Array.<string>|null} [ipFamilies] ServiceSpec ipFamilies
                         * @property {string|null} [ipFamilyPolicy] ServiceSpec ipFamilyPolicy
                         * @property {boolean|null} [allocateLoadBalancerNodePorts] ServiceSpec allocateLoadBalancerNodePorts
                         */

                        /**
                         * Constructs a new ServiceSpec.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceSpec.
                         * @implements IServiceSpec
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceSpec=} [properties] Properties to set
                         */
                        function ServiceSpec(properties) {
                            this.ports = [];
                            this.selector = {};
                            this.clusterIPs = [];
                            this.externalIPs = [];
                            this.loadBalancerSourceRanges = [];
                            this.topologyKeys = [];
                            this.ipFamilies = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceSpec ports.
                         * @member {Array.<k8s.io.api.core.v1.IServicePort>} ports
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.ports = $util.emptyArray;

                        /**
                         * ServiceSpec selector.
                         * @member {Object.<string,string>} selector
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.selector = $util.emptyObject;

                        /**
                         * ServiceSpec clusterIP.
                         * @member {string} clusterIP
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.clusterIP = "";

                        /**
                         * ServiceSpec clusterIPs.
                         * @member {Array.<string>} clusterIPs
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.clusterIPs = $util.emptyArray;

                        /**
                         * ServiceSpec type.
                         * @member {string} type
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.type = "";

                        /**
                         * ServiceSpec externalIPs.
                         * @member {Array.<string>} externalIPs
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.externalIPs = $util.emptyArray;

                        /**
                         * ServiceSpec sessionAffinity.
                         * @member {string} sessionAffinity
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.sessionAffinity = "";

                        /**
                         * ServiceSpec loadBalancerIP.
                         * @member {string} loadBalancerIP
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.loadBalancerIP = "";

                        /**
                         * ServiceSpec loadBalancerSourceRanges.
                         * @member {Array.<string>} loadBalancerSourceRanges
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.loadBalancerSourceRanges = $util.emptyArray;

                        /**
                         * ServiceSpec externalName.
                         * @member {string} externalName
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.externalName = "";

                        /**
                         * ServiceSpec externalTrafficPolicy.
                         * @member {string} externalTrafficPolicy
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.externalTrafficPolicy = "";

                        /**
                         * ServiceSpec healthCheckNodePort.
                         * @member {number} healthCheckNodePort
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.healthCheckNodePort = 0;

                        /**
                         * ServiceSpec publishNotReadyAddresses.
                         * @member {boolean} publishNotReadyAddresses
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.publishNotReadyAddresses = false;

                        /**
                         * ServiceSpec sessionAffinityConfig.
                         * @member {k8s.io.api.core.v1.ISessionAffinityConfig|null|undefined} sessionAffinityConfig
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.sessionAffinityConfig = null;

                        /**
                         * ServiceSpec topologyKeys.
                         * @member {Array.<string>} topologyKeys
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.topologyKeys = $util.emptyArray;

                        /**
                         * ServiceSpec ipFamilies.
                         * @member {Array.<string>} ipFamilies
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.ipFamilies = $util.emptyArray;

                        /**
                         * ServiceSpec ipFamilyPolicy.
                         * @member {string} ipFamilyPolicy
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.ipFamilyPolicy = "";

                        /**
                         * ServiceSpec allocateLoadBalancerNodePorts.
                         * @member {boolean} allocateLoadBalancerNodePorts
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         */
                        ServiceSpec.prototype.allocateLoadBalancerNodePorts = false;

                        /**
                         * Creates a new ServiceSpec instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceSpec=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceSpec} ServiceSpec instance
                         */
                        ServiceSpec.create = function create(properties) {
                            return new ServiceSpec(properties);
                        };

                        /**
                         * Encodes the specified ServiceSpec message. Does not implicitly {@link k8s.io.api.core.v1.ServiceSpec.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceSpec} message ServiceSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceSpec.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ports != null && message.ports.length)
                                for (var i = 0; i < message.ports.length; ++i)
                                    $root.k8s.io.api.core.v1.ServicePort.encode(message.ports[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.selector != null && Object.hasOwnProperty.call(message, "selector"))
                                for (var keys = Object.keys(message.selector), i = 0; i < keys.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.selector[keys[i]]).ldelim();
                            if (message.clusterIP != null && Object.hasOwnProperty.call(message, "clusterIP"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.clusterIP);
                            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.type);
                            if (message.externalIPs != null && message.externalIPs.length)
                                for (var i = 0; i < message.externalIPs.length; ++i)
                                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.externalIPs[i]);
                            if (message.sessionAffinity != null && Object.hasOwnProperty.call(message, "sessionAffinity"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.sessionAffinity);
                            if (message.loadBalancerIP != null && Object.hasOwnProperty.call(message, "loadBalancerIP"))
                                writer.uint32(/* id 8, wireType 2 =*/66).string(message.loadBalancerIP);
                            if (message.loadBalancerSourceRanges != null && message.loadBalancerSourceRanges.length)
                                for (var i = 0; i < message.loadBalancerSourceRanges.length; ++i)
                                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.loadBalancerSourceRanges[i]);
                            if (message.externalName != null && Object.hasOwnProperty.call(message, "externalName"))
                                writer.uint32(/* id 10, wireType 2 =*/82).string(message.externalName);
                            if (message.externalTrafficPolicy != null && Object.hasOwnProperty.call(message, "externalTrafficPolicy"))
                                writer.uint32(/* id 11, wireType 2 =*/90).string(message.externalTrafficPolicy);
                            if (message.healthCheckNodePort != null && Object.hasOwnProperty.call(message, "healthCheckNodePort"))
                                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.healthCheckNodePort);
                            if (message.publishNotReadyAddresses != null && Object.hasOwnProperty.call(message, "publishNotReadyAddresses"))
                                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.publishNotReadyAddresses);
                            if (message.sessionAffinityConfig != null && Object.hasOwnProperty.call(message, "sessionAffinityConfig"))
                                $root.k8s.io.api.core.v1.SessionAffinityConfig.encode(message.sessionAffinityConfig, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.topologyKeys != null && message.topologyKeys.length)
                                for (var i = 0; i < message.topologyKeys.length; ++i)
                                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.topologyKeys[i]);
                            if (message.ipFamilyPolicy != null && Object.hasOwnProperty.call(message, "ipFamilyPolicy"))
                                writer.uint32(/* id 17, wireType 2 =*/138).string(message.ipFamilyPolicy);
                            if (message.clusterIPs != null && message.clusterIPs.length)
                                for (var i = 0; i < message.clusterIPs.length; ++i)
                                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.clusterIPs[i]);
                            if (message.ipFamilies != null && message.ipFamilies.length)
                                for (var i = 0; i < message.ipFamilies.length; ++i)
                                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.ipFamilies[i]);
                            if (message.allocateLoadBalancerNodePorts != null && Object.hasOwnProperty.call(message, "allocateLoadBalancerNodePorts"))
                                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.allocateLoadBalancerNodePorts);
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceSpec message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceSpec.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceSpec} message ServiceSpec message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceSpec.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceSpec message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceSpec} ServiceSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceSpec.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceSpec(), key, value;
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.ports && message.ports.length))
                                        message.ports = [];
                                    message.ports.push($root.k8s.io.api.core.v1.ServicePort.decode(reader, reader.uint32()));
                                    break;
                                case 2:
                                    if (message.selector === $util.emptyObject)
                                        message.selector = {};
                                    var end2 = reader.uint32() + reader.pos;
                                    key = "";
                                    value = "";
                                    while (reader.pos < end2) {
                                        var tag2 = reader.uint32();
                                        switch (tag2 >>> 3) {
                                        case 1:
                                            key = reader.string();
                                            break;
                                        case 2:
                                            value = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag2 & 7);
                                            break;
                                        }
                                    }
                                    message.selector[key] = value;
                                    break;
                                case 3:
                                    message.clusterIP = reader.string();
                                    break;
                                case 18:
                                    if (!(message.clusterIPs && message.clusterIPs.length))
                                        message.clusterIPs = [];
                                    message.clusterIPs.push(reader.string());
                                    break;
                                case 4:
                                    message.type = reader.string();
                                    break;
                                case 5:
                                    if (!(message.externalIPs && message.externalIPs.length))
                                        message.externalIPs = [];
                                    message.externalIPs.push(reader.string());
                                    break;
                                case 7:
                                    message.sessionAffinity = reader.string();
                                    break;
                                case 8:
                                    message.loadBalancerIP = reader.string();
                                    break;
                                case 9:
                                    if (!(message.loadBalancerSourceRanges && message.loadBalancerSourceRanges.length))
                                        message.loadBalancerSourceRanges = [];
                                    message.loadBalancerSourceRanges.push(reader.string());
                                    break;
                                case 10:
                                    message.externalName = reader.string();
                                    break;
                                case 11:
                                    message.externalTrafficPolicy = reader.string();
                                    break;
                                case 12:
                                    message.healthCheckNodePort = reader.int32();
                                    break;
                                case 13:
                                    message.publishNotReadyAddresses = reader.bool();
                                    break;
                                case 14:
                                    message.sessionAffinityConfig = $root.k8s.io.api.core.v1.SessionAffinityConfig.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    if (!(message.topologyKeys && message.topologyKeys.length))
                                        message.topologyKeys = [];
                                    message.topologyKeys.push(reader.string());
                                    break;
                                case 19:
                                    if (!(message.ipFamilies && message.ipFamilies.length))
                                        message.ipFamilies = [];
                                    message.ipFamilies.push(reader.string());
                                    break;
                                case 17:
                                    message.ipFamilyPolicy = reader.string();
                                    break;
                                case 20:
                                    message.allocateLoadBalancerNodePorts = reader.bool();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceSpec message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceSpec} ServiceSpec
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceSpec.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceSpec message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceSpec.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ports != null && message.hasOwnProperty("ports")) {
                                if (!Array.isArray(message.ports))
                                    return "ports: array expected";
                                for (var i = 0; i < message.ports.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.ServicePort.verify(message.ports[i]);
                                    if (error)
                                        return "ports." + error;
                                }
                            }
                            if (message.selector != null && message.hasOwnProperty("selector")) {
                                if (!$util.isObject(message.selector))
                                    return "selector: object expected";
                                var key = Object.keys(message.selector);
                                for (var i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.selector[key[i]]))
                                        return "selector: string{k:string} expected";
                            }
                            if (message.clusterIP != null && message.hasOwnProperty("clusterIP"))
                                if (!$util.isString(message.clusterIP))
                                    return "clusterIP: string expected";
                            if (message.clusterIPs != null && message.hasOwnProperty("clusterIPs")) {
                                if (!Array.isArray(message.clusterIPs))
                                    return "clusterIPs: array expected";
                                for (var i = 0; i < message.clusterIPs.length; ++i)
                                    if (!$util.isString(message.clusterIPs[i]))
                                        return "clusterIPs: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                if (!$util.isString(message.type))
                                    return "type: string expected";
                            if (message.externalIPs != null && message.hasOwnProperty("externalIPs")) {
                                if (!Array.isArray(message.externalIPs))
                                    return "externalIPs: array expected";
                                for (var i = 0; i < message.externalIPs.length; ++i)
                                    if (!$util.isString(message.externalIPs[i]))
                                        return "externalIPs: string[] expected";
                            }
                            if (message.sessionAffinity != null && message.hasOwnProperty("sessionAffinity"))
                                if (!$util.isString(message.sessionAffinity))
                                    return "sessionAffinity: string expected";
                            if (message.loadBalancerIP != null && message.hasOwnProperty("loadBalancerIP"))
                                if (!$util.isString(message.loadBalancerIP))
                                    return "loadBalancerIP: string expected";
                            if (message.loadBalancerSourceRanges != null && message.hasOwnProperty("loadBalancerSourceRanges")) {
                                if (!Array.isArray(message.loadBalancerSourceRanges))
                                    return "loadBalancerSourceRanges: array expected";
                                for (var i = 0; i < message.loadBalancerSourceRanges.length; ++i)
                                    if (!$util.isString(message.loadBalancerSourceRanges[i]))
                                        return "loadBalancerSourceRanges: string[] expected";
                            }
                            if (message.externalName != null && message.hasOwnProperty("externalName"))
                                if (!$util.isString(message.externalName))
                                    return "externalName: string expected";
                            if (message.externalTrafficPolicy != null && message.hasOwnProperty("externalTrafficPolicy"))
                                if (!$util.isString(message.externalTrafficPolicy))
                                    return "externalTrafficPolicy: string expected";
                            if (message.healthCheckNodePort != null && message.hasOwnProperty("healthCheckNodePort"))
                                if (!$util.isInteger(message.healthCheckNodePort))
                                    return "healthCheckNodePort: integer expected";
                            if (message.publishNotReadyAddresses != null && message.hasOwnProperty("publishNotReadyAddresses"))
                                if (typeof message.publishNotReadyAddresses !== "boolean")
                                    return "publishNotReadyAddresses: boolean expected";
                            if (message.sessionAffinityConfig != null && message.hasOwnProperty("sessionAffinityConfig")) {
                                var error = $root.k8s.io.api.core.v1.SessionAffinityConfig.verify(message.sessionAffinityConfig);
                                if (error)
                                    return "sessionAffinityConfig." + error;
                            }
                            if (message.topologyKeys != null && message.hasOwnProperty("topologyKeys")) {
                                if (!Array.isArray(message.topologyKeys))
                                    return "topologyKeys: array expected";
                                for (var i = 0; i < message.topologyKeys.length; ++i)
                                    if (!$util.isString(message.topologyKeys[i]))
                                        return "topologyKeys: string[] expected";
                            }
                            if (message.ipFamilies != null && message.hasOwnProperty("ipFamilies")) {
                                if (!Array.isArray(message.ipFamilies))
                                    return "ipFamilies: array expected";
                                for (var i = 0; i < message.ipFamilies.length; ++i)
                                    if (!$util.isString(message.ipFamilies[i]))
                                        return "ipFamilies: string[] expected";
                            }
                            if (message.ipFamilyPolicy != null && message.hasOwnProperty("ipFamilyPolicy"))
                                if (!$util.isString(message.ipFamilyPolicy))
                                    return "ipFamilyPolicy: string expected";
                            if (message.allocateLoadBalancerNodePorts != null && message.hasOwnProperty("allocateLoadBalancerNodePorts"))
                                if (typeof message.allocateLoadBalancerNodePorts !== "boolean")
                                    return "allocateLoadBalancerNodePorts: boolean expected";
                            return null;
                        };

                        /**
                         * Creates a ServiceSpec message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceSpec} ServiceSpec
                         */
                        ServiceSpec.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceSpec)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceSpec();
                            if (object.ports) {
                                if (!Array.isArray(object.ports))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.ports: array expected");
                                message.ports = [];
                                for (var i = 0; i < object.ports.length; ++i) {
                                    if (typeof object.ports[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ServiceSpec.ports: object expected");
                                    message.ports[i] = $root.k8s.io.api.core.v1.ServicePort.fromObject(object.ports[i]);
                                }
                            }
                            if (object.selector) {
                                if (typeof object.selector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.selector: object expected");
                                message.selector = {};
                                for (var keys = Object.keys(object.selector), i = 0; i < keys.length; ++i)
                                    message.selector[keys[i]] = String(object.selector[keys[i]]);
                            }
                            if (object.clusterIP != null)
                                message.clusterIP = String(object.clusterIP);
                            if (object.clusterIPs) {
                                if (!Array.isArray(object.clusterIPs))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.clusterIPs: array expected");
                                message.clusterIPs = [];
                                for (var i = 0; i < object.clusterIPs.length; ++i)
                                    message.clusterIPs[i] = String(object.clusterIPs[i]);
                            }
                            if (object.type != null)
                                message.type = String(object.type);
                            if (object.externalIPs) {
                                if (!Array.isArray(object.externalIPs))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.externalIPs: array expected");
                                message.externalIPs = [];
                                for (var i = 0; i < object.externalIPs.length; ++i)
                                    message.externalIPs[i] = String(object.externalIPs[i]);
                            }
                            if (object.sessionAffinity != null)
                                message.sessionAffinity = String(object.sessionAffinity);
                            if (object.loadBalancerIP != null)
                                message.loadBalancerIP = String(object.loadBalancerIP);
                            if (object.loadBalancerSourceRanges) {
                                if (!Array.isArray(object.loadBalancerSourceRanges))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.loadBalancerSourceRanges: array expected");
                                message.loadBalancerSourceRanges = [];
                                for (var i = 0; i < object.loadBalancerSourceRanges.length; ++i)
                                    message.loadBalancerSourceRanges[i] = String(object.loadBalancerSourceRanges[i]);
                            }
                            if (object.externalName != null)
                                message.externalName = String(object.externalName);
                            if (object.externalTrafficPolicy != null)
                                message.externalTrafficPolicy = String(object.externalTrafficPolicy);
                            if (object.healthCheckNodePort != null)
                                message.healthCheckNodePort = object.healthCheckNodePort | 0;
                            if (object.publishNotReadyAddresses != null)
                                message.publishNotReadyAddresses = Boolean(object.publishNotReadyAddresses);
                            if (object.sessionAffinityConfig != null) {
                                if (typeof object.sessionAffinityConfig !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.sessionAffinityConfig: object expected");
                                message.sessionAffinityConfig = $root.k8s.io.api.core.v1.SessionAffinityConfig.fromObject(object.sessionAffinityConfig);
                            }
                            if (object.topologyKeys) {
                                if (!Array.isArray(object.topologyKeys))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.topologyKeys: array expected");
                                message.topologyKeys = [];
                                for (var i = 0; i < object.topologyKeys.length; ++i)
                                    message.topologyKeys[i] = String(object.topologyKeys[i]);
                            }
                            if (object.ipFamilies) {
                                if (!Array.isArray(object.ipFamilies))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceSpec.ipFamilies: array expected");
                                message.ipFamilies = [];
                                for (var i = 0; i < object.ipFamilies.length; ++i)
                                    message.ipFamilies[i] = String(object.ipFamilies[i]);
                            }
                            if (object.ipFamilyPolicy != null)
                                message.ipFamilyPolicy = String(object.ipFamilyPolicy);
                            if (object.allocateLoadBalancerNodePorts != null)
                                message.allocateLoadBalancerNodePorts = Boolean(object.allocateLoadBalancerNodePorts);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceSpec message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceSpec} message ServiceSpec
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceSpec.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults) {
                                object.ports = [];
                                object.externalIPs = [];
                                object.loadBalancerSourceRanges = [];
                                object.topologyKeys = [];
                                object.clusterIPs = [];
                                object.ipFamilies = [];
                            }
                            if (options.objects || options.defaults)
                                object.selector = {};
                            if (options.defaults) {
                                object.clusterIP = "";
                                object.type = "";
                                object.sessionAffinity = "";
                                object.loadBalancerIP = "";
                                object.externalName = "";
                                object.externalTrafficPolicy = "";
                                object.healthCheckNodePort = 0;
                                object.publishNotReadyAddresses = false;
                                object.sessionAffinityConfig = null;
                                object.ipFamilyPolicy = "";
                                object.allocateLoadBalancerNodePorts = false;
                            }
                            if (message.ports && message.ports.length) {
                                object.ports = [];
                                for (var j = 0; j < message.ports.length; ++j)
                                    object.ports[j] = $root.k8s.io.api.core.v1.ServicePort.toObject(message.ports[j], options);
                            }
                            var keys2;
                            if (message.selector && (keys2 = Object.keys(message.selector)).length) {
                                object.selector = {};
                                for (var j = 0; j < keys2.length; ++j)
                                    object.selector[keys2[j]] = message.selector[keys2[j]];
                            }
                            if (message.clusterIP != null && message.hasOwnProperty("clusterIP"))
                                object.clusterIP = message.clusterIP;
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = message.type;
                            if (message.externalIPs && message.externalIPs.length) {
                                object.externalIPs = [];
                                for (var j = 0; j < message.externalIPs.length; ++j)
                                    object.externalIPs[j] = message.externalIPs[j];
                            }
                            if (message.sessionAffinity != null && message.hasOwnProperty("sessionAffinity"))
                                object.sessionAffinity = message.sessionAffinity;
                            if (message.loadBalancerIP != null && message.hasOwnProperty("loadBalancerIP"))
                                object.loadBalancerIP = message.loadBalancerIP;
                            if (message.loadBalancerSourceRanges && message.loadBalancerSourceRanges.length) {
                                object.loadBalancerSourceRanges = [];
                                for (var j = 0; j < message.loadBalancerSourceRanges.length; ++j)
                                    object.loadBalancerSourceRanges[j] = message.loadBalancerSourceRanges[j];
                            }
                            if (message.externalName != null && message.hasOwnProperty("externalName"))
                                object.externalName = message.externalName;
                            if (message.externalTrafficPolicy != null && message.hasOwnProperty("externalTrafficPolicy"))
                                object.externalTrafficPolicy = message.externalTrafficPolicy;
                            if (message.healthCheckNodePort != null && message.hasOwnProperty("healthCheckNodePort"))
                                object.healthCheckNodePort = message.healthCheckNodePort;
                            if (message.publishNotReadyAddresses != null && message.hasOwnProperty("publishNotReadyAddresses"))
                                object.publishNotReadyAddresses = message.publishNotReadyAddresses;
                            if (message.sessionAffinityConfig != null && message.hasOwnProperty("sessionAffinityConfig"))
                                object.sessionAffinityConfig = $root.k8s.io.api.core.v1.SessionAffinityConfig.toObject(message.sessionAffinityConfig, options);
                            if (message.topologyKeys && message.topologyKeys.length) {
                                object.topologyKeys = [];
                                for (var j = 0; j < message.topologyKeys.length; ++j)
                                    object.topologyKeys[j] = message.topologyKeys[j];
                            }
                            if (message.ipFamilyPolicy != null && message.hasOwnProperty("ipFamilyPolicy"))
                                object.ipFamilyPolicy = message.ipFamilyPolicy;
                            if (message.clusterIPs && message.clusterIPs.length) {
                                object.clusterIPs = [];
                                for (var j = 0; j < message.clusterIPs.length; ++j)
                                    object.clusterIPs[j] = message.clusterIPs[j];
                            }
                            if (message.ipFamilies && message.ipFamilies.length) {
                                object.ipFamilies = [];
                                for (var j = 0; j < message.ipFamilies.length; ++j)
                                    object.ipFamilies[j] = message.ipFamilies[j];
                            }
                            if (message.allocateLoadBalancerNodePorts != null && message.hasOwnProperty("allocateLoadBalancerNodePorts"))
                                object.allocateLoadBalancerNodePorts = message.allocateLoadBalancerNodePorts;
                            return object;
                        };

                        /**
                         * Converts this ServiceSpec to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceSpec
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceSpec.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceSpec;
                    })();

                    v1.ServiceStatus = (function() {

                        /**
                         * Properties of a ServiceStatus.
                         * @memberof k8s.io.api.core.v1
                         * @interface IServiceStatus
                         * @property {k8s.io.api.core.v1.ILoadBalancerStatus|null} [loadBalancer] ServiceStatus loadBalancer
                         * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.ICondition>|null} [conditions] ServiceStatus conditions
                         */

                        /**
                         * Constructs a new ServiceStatus.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a ServiceStatus.
                         * @implements IServiceStatus
                         * @constructor
                         * @param {k8s.io.api.core.v1.IServiceStatus=} [properties] Properties to set
                         */
                        function ServiceStatus(properties) {
                            this.conditions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ServiceStatus loadBalancer.
                         * @member {k8s.io.api.core.v1.ILoadBalancerStatus|null|undefined} loadBalancer
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @instance
                         */
                        ServiceStatus.prototype.loadBalancer = null;

                        /**
                         * ServiceStatus conditions.
                         * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.ICondition>} conditions
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @instance
                         */
                        ServiceStatus.prototype.conditions = $util.emptyArray;

                        /**
                         * Creates a new ServiceStatus instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceStatus=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.ServiceStatus} ServiceStatus instance
                         */
                        ServiceStatus.create = function create(properties) {
                            return new ServiceStatus(properties);
                        };

                        /**
                         * Encodes the specified ServiceStatus message. Does not implicitly {@link k8s.io.api.core.v1.ServiceStatus.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceStatus} message ServiceStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceStatus.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.loadBalancer != null && Object.hasOwnProperty.call(message, "loadBalancer"))
                                $root.k8s.io.api.core.v1.LoadBalancerStatus.encode(message.loadBalancer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.conditions != null && message.conditions.length)
                                for (var i = 0; i < message.conditions.length; ++i)
                                    $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition.encode(message.conditions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified ServiceStatus message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.ServiceStatus.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.IServiceStatus} message ServiceStatus message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ServiceStatus.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ServiceStatus message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.ServiceStatus} ServiceStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceStatus.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.ServiceStatus();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.loadBalancer = $root.k8s.io.api.core.v1.LoadBalancerStatus.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    if (!(message.conditions && message.conditions.length))
                                        message.conditions = [];
                                    message.conditions.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ServiceStatus message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.ServiceStatus} ServiceStatus
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ServiceStatus.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ServiceStatus message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ServiceStatus.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.loadBalancer != null && message.hasOwnProperty("loadBalancer")) {
                                var error = $root.k8s.io.api.core.v1.LoadBalancerStatus.verify(message.loadBalancer);
                                if (error)
                                    return "loadBalancer." + error;
                            }
                            if (message.conditions != null && message.hasOwnProperty("conditions")) {
                                if (!Array.isArray(message.conditions))
                                    return "conditions: array expected";
                                for (var i = 0; i < message.conditions.length; ++i) {
                                    var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition.verify(message.conditions[i]);
                                    if (error)
                                        return "conditions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a ServiceStatus message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.ServiceStatus} ServiceStatus
                         */
                        ServiceStatus.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.ServiceStatus)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.ServiceStatus();
                            if (object.loadBalancer != null) {
                                if (typeof object.loadBalancer !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.ServiceStatus.loadBalancer: object expected");
                                message.loadBalancer = $root.k8s.io.api.core.v1.LoadBalancerStatus.fromObject(object.loadBalancer);
                            }
                            if (object.conditions) {
                                if (!Array.isArray(object.conditions))
                                    throw TypeError(".k8s.io.api.core.v1.ServiceStatus.conditions: array expected");
                                message.conditions = [];
                                for (var i = 0; i < object.conditions.length; ++i) {
                                    if (typeof object.conditions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.ServiceStatus.conditions: object expected");
                                    message.conditions[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition.fromObject(object.conditions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ServiceStatus message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @static
                         * @param {k8s.io.api.core.v1.ServiceStatus} message ServiceStatus
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ServiceStatus.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.conditions = [];
                            if (options.defaults)
                                object.loadBalancer = null;
                            if (message.loadBalancer != null && message.hasOwnProperty("loadBalancer"))
                                object.loadBalancer = $root.k8s.io.api.core.v1.LoadBalancerStatus.toObject(message.loadBalancer, options);
                            if (message.conditions && message.conditions.length) {
                                object.conditions = [];
                                for (var j = 0; j < message.conditions.length; ++j)
                                    object.conditions[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition.toObject(message.conditions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this ServiceStatus to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.ServiceStatus
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ServiceStatus.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ServiceStatus;
                    })();

                    v1.SessionAffinityConfig = (function() {

                        /**
                         * Properties of a SessionAffinityConfig.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISessionAffinityConfig
                         * @property {k8s.io.api.core.v1.IClientIPConfig|null} [clientIP] SessionAffinityConfig clientIP
                         */

                        /**
                         * Constructs a new SessionAffinityConfig.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a SessionAffinityConfig.
                         * @implements ISessionAffinityConfig
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISessionAffinityConfig=} [properties] Properties to set
                         */
                        function SessionAffinityConfig(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SessionAffinityConfig clientIP.
                         * @member {k8s.io.api.core.v1.IClientIPConfig|null|undefined} clientIP
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @instance
                         */
                        SessionAffinityConfig.prototype.clientIP = null;

                        /**
                         * Creates a new SessionAffinityConfig instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {k8s.io.api.core.v1.ISessionAffinityConfig=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.SessionAffinityConfig} SessionAffinityConfig instance
                         */
                        SessionAffinityConfig.create = function create(properties) {
                            return new SessionAffinityConfig(properties);
                        };

                        /**
                         * Encodes the specified SessionAffinityConfig message. Does not implicitly {@link k8s.io.api.core.v1.SessionAffinityConfig.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {k8s.io.api.core.v1.ISessionAffinityConfig} message SessionAffinityConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SessionAffinityConfig.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.clientIP != null && Object.hasOwnProperty.call(message, "clientIP"))
                                $root.k8s.io.api.core.v1.ClientIPConfig.encode(message.clientIP, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified SessionAffinityConfig message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.SessionAffinityConfig.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {k8s.io.api.core.v1.ISessionAffinityConfig} message SessionAffinityConfig message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SessionAffinityConfig.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SessionAffinityConfig message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.SessionAffinityConfig} SessionAffinityConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SessionAffinityConfig.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.SessionAffinityConfig();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.clientIP = $root.k8s.io.api.core.v1.ClientIPConfig.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SessionAffinityConfig message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.SessionAffinityConfig} SessionAffinityConfig
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SessionAffinityConfig.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SessionAffinityConfig message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SessionAffinityConfig.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.clientIP != null && message.hasOwnProperty("clientIP")) {
                                var error = $root.k8s.io.api.core.v1.ClientIPConfig.verify(message.clientIP);
                                if (error)
                                    return "clientIP." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a SessionAffinityConfig message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.SessionAffinityConfig} SessionAffinityConfig
                         */
                        SessionAffinityConfig.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.SessionAffinityConfig)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.SessionAffinityConfig();
                            if (object.clientIP != null) {
                                if (typeof object.clientIP !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.SessionAffinityConfig.clientIP: object expected");
                                message.clientIP = $root.k8s.io.api.core.v1.ClientIPConfig.fromObject(object.clientIP);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a SessionAffinityConfig message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @static
                         * @param {k8s.io.api.core.v1.SessionAffinityConfig} message SessionAffinityConfig
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SessionAffinityConfig.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.clientIP = null;
                            if (message.clientIP != null && message.hasOwnProperty("clientIP"))
                                object.clientIP = $root.k8s.io.api.core.v1.ClientIPConfig.toObject(message.clientIP, options);
                            return object;
                        };

                        /**
                         * Converts this SessionAffinityConfig to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.SessionAffinityConfig
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SessionAffinityConfig.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SessionAffinityConfig;
                    })();

                    v1.StorageOSPersistentVolumeSource = (function() {

                        /**
                         * Properties of a StorageOSPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IStorageOSPersistentVolumeSource
                         * @property {string|null} [volumeName] StorageOSPersistentVolumeSource volumeName
                         * @property {string|null} [volumeNamespace] StorageOSPersistentVolumeSource volumeNamespace
                         * @property {string|null} [fsType] StorageOSPersistentVolumeSource fsType
                         * @property {boolean|null} [readOnly] StorageOSPersistentVolumeSource readOnly
                         * @property {k8s.io.api.core.v1.IObjectReference|null} [secretRef] StorageOSPersistentVolumeSource secretRef
                         */

                        /**
                         * Constructs a new StorageOSPersistentVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a StorageOSPersistentVolumeSource.
                         * @implements IStorageOSPersistentVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IStorageOSPersistentVolumeSource=} [properties] Properties to set
                         */
                        function StorageOSPersistentVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StorageOSPersistentVolumeSource volumeName.
                         * @member {string} volumeName
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @instance
                         */
                        StorageOSPersistentVolumeSource.prototype.volumeName = "";

                        /**
                         * StorageOSPersistentVolumeSource volumeNamespace.
                         * @member {string} volumeNamespace
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @instance
                         */
                        StorageOSPersistentVolumeSource.prototype.volumeNamespace = "";

                        /**
                         * StorageOSPersistentVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @instance
                         */
                        StorageOSPersistentVolumeSource.prototype.fsType = "";

                        /**
                         * StorageOSPersistentVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @instance
                         */
                        StorageOSPersistentVolumeSource.prototype.readOnly = false;

                        /**
                         * StorageOSPersistentVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.IObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @instance
                         */
                        StorageOSPersistentVolumeSource.prototype.secretRef = null;

                        /**
                         * Creates a new StorageOSPersistentVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IStorageOSPersistentVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.StorageOSPersistentVolumeSource} StorageOSPersistentVolumeSource instance
                         */
                        StorageOSPersistentVolumeSource.create = function create(properties) {
                            return new StorageOSPersistentVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified StorageOSPersistentVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.StorageOSPersistentVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IStorageOSPersistentVolumeSource} message StorageOSPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StorageOSPersistentVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeName != null && Object.hasOwnProperty.call(message, "volumeName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumeName);
                            if (message.volumeNamespace != null && Object.hasOwnProperty.call(message, "volumeNamespace"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.volumeNamespace);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.ObjectReference.encode(message.secretRef, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified StorageOSPersistentVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.StorageOSPersistentVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IStorageOSPersistentVolumeSource} message StorageOSPersistentVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StorageOSPersistentVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StorageOSPersistentVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.StorageOSPersistentVolumeSource} StorageOSPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StorageOSPersistentVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeName = reader.string();
                                    break;
                                case 2:
                                    message.volumeNamespace = reader.string();
                                    break;
                                case 3:
                                    message.fsType = reader.string();
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                case 5:
                                    message.secretRef = $root.k8s.io.api.core.v1.ObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StorageOSPersistentVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.StorageOSPersistentVolumeSource} StorageOSPersistentVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StorageOSPersistentVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StorageOSPersistentVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StorageOSPersistentVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                if (!$util.isString(message.volumeName))
                                    return "volumeName: string expected";
                            if (message.volumeNamespace != null && message.hasOwnProperty("volumeNamespace"))
                                if (!$util.isString(message.volumeNamespace))
                                    return "volumeNamespace: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.ObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a StorageOSPersistentVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.StorageOSPersistentVolumeSource} StorageOSPersistentVolumeSource
                         */
                        StorageOSPersistentVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.StorageOSPersistentVolumeSource();
                            if (object.volumeName != null)
                                message.volumeName = String(object.volumeName);
                            if (object.volumeNamespace != null)
                                message.volumeNamespace = String(object.volumeNamespace);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.StorageOSPersistentVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.ObjectReference.fromObject(object.secretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a StorageOSPersistentVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.StorageOSPersistentVolumeSource} message StorageOSPersistentVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StorageOSPersistentVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeName = "";
                                object.volumeNamespace = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.secretRef = null;
                            }
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                object.volumeName = message.volumeName;
                            if (message.volumeNamespace != null && message.hasOwnProperty("volumeNamespace"))
                                object.volumeNamespace = message.volumeNamespace;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.ObjectReference.toObject(message.secretRef, options);
                            return object;
                        };

                        /**
                         * Converts this StorageOSPersistentVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.StorageOSPersistentVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StorageOSPersistentVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StorageOSPersistentVolumeSource;
                    })();

                    v1.StorageOSVolumeSource = (function() {

                        /**
                         * Properties of a StorageOSVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IStorageOSVolumeSource
                         * @property {string|null} [volumeName] StorageOSVolumeSource volumeName
                         * @property {string|null} [volumeNamespace] StorageOSVolumeSource volumeNamespace
                         * @property {string|null} [fsType] StorageOSVolumeSource fsType
                         * @property {boolean|null} [readOnly] StorageOSVolumeSource readOnly
                         * @property {k8s.io.api.core.v1.ILocalObjectReference|null} [secretRef] StorageOSVolumeSource secretRef
                         */

                        /**
                         * Constructs a new StorageOSVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a StorageOSVolumeSource.
                         * @implements IStorageOSVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IStorageOSVolumeSource=} [properties] Properties to set
                         */
                        function StorageOSVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StorageOSVolumeSource volumeName.
                         * @member {string} volumeName
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @instance
                         */
                        StorageOSVolumeSource.prototype.volumeName = "";

                        /**
                         * StorageOSVolumeSource volumeNamespace.
                         * @member {string} volumeNamespace
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @instance
                         */
                        StorageOSVolumeSource.prototype.volumeNamespace = "";

                        /**
                         * StorageOSVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @instance
                         */
                        StorageOSVolumeSource.prototype.fsType = "";

                        /**
                         * StorageOSVolumeSource readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @instance
                         */
                        StorageOSVolumeSource.prototype.readOnly = false;

                        /**
                         * StorageOSVolumeSource secretRef.
                         * @member {k8s.io.api.core.v1.ILocalObjectReference|null|undefined} secretRef
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @instance
                         */
                        StorageOSVolumeSource.prototype.secretRef = null;

                        /**
                         * Creates a new StorageOSVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IStorageOSVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.StorageOSVolumeSource} StorageOSVolumeSource instance
                         */
                        StorageOSVolumeSource.create = function create(properties) {
                            return new StorageOSVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified StorageOSVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.StorageOSVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IStorageOSVolumeSource} message StorageOSVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StorageOSVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumeName != null && Object.hasOwnProperty.call(message, "volumeName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumeName);
                            if (message.volumeNamespace != null && Object.hasOwnProperty.call(message, "volumeNamespace"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.volumeNamespace);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fsType);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.readOnly);
                            if (message.secretRef != null && Object.hasOwnProperty.call(message, "secretRef"))
                                $root.k8s.io.api.core.v1.LocalObjectReference.encode(message.secretRef, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified StorageOSVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.StorageOSVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IStorageOSVolumeSource} message StorageOSVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StorageOSVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StorageOSVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.StorageOSVolumeSource} StorageOSVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StorageOSVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.StorageOSVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumeName = reader.string();
                                    break;
                                case 2:
                                    message.volumeNamespace = reader.string();
                                    break;
                                case 3:
                                    message.fsType = reader.string();
                                    break;
                                case 4:
                                    message.readOnly = reader.bool();
                                    break;
                                case 5:
                                    message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StorageOSVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.StorageOSVolumeSource} StorageOSVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StorageOSVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StorageOSVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StorageOSVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                if (!$util.isString(message.volumeName))
                                    return "volumeName: string expected";
                            if (message.volumeNamespace != null && message.hasOwnProperty("volumeNamespace"))
                                if (!$util.isString(message.volumeNamespace))
                                    return "volumeNamespace: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.secretRef != null && message.hasOwnProperty("secretRef")) {
                                var error = $root.k8s.io.api.core.v1.LocalObjectReference.verify(message.secretRef);
                                if (error)
                                    return "secretRef." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a StorageOSVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.StorageOSVolumeSource} StorageOSVolumeSource
                         */
                        StorageOSVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.StorageOSVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.StorageOSVolumeSource();
                            if (object.volumeName != null)
                                message.volumeName = String(object.volumeName);
                            if (object.volumeNamespace != null)
                                message.volumeNamespace = String(object.volumeNamespace);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.secretRef != null) {
                                if (typeof object.secretRef !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.StorageOSVolumeSource.secretRef: object expected");
                                message.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.fromObject(object.secretRef);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a StorageOSVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.StorageOSVolumeSource} message StorageOSVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StorageOSVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumeName = "";
                                object.volumeNamespace = "";
                                object.fsType = "";
                                object.readOnly = false;
                                object.secretRef = null;
                            }
                            if (message.volumeName != null && message.hasOwnProperty("volumeName"))
                                object.volumeName = message.volumeName;
                            if (message.volumeNamespace != null && message.hasOwnProperty("volumeNamespace"))
                                object.volumeNamespace = message.volumeNamespace;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.secretRef != null && message.hasOwnProperty("secretRef"))
                                object.secretRef = $root.k8s.io.api.core.v1.LocalObjectReference.toObject(message.secretRef, options);
                            return object;
                        };

                        /**
                         * Converts this StorageOSVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.StorageOSVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StorageOSVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StorageOSVolumeSource;
                    })();

                    v1.Sysctl = (function() {

                        /**
                         * Properties of a Sysctl.
                         * @memberof k8s.io.api.core.v1
                         * @interface ISysctl
                         * @property {string|null} [name] Sysctl name
                         * @property {string|null} [value] Sysctl value
                         */

                        /**
                         * Constructs a new Sysctl.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Sysctl.
                         * @implements ISysctl
                         * @constructor
                         * @param {k8s.io.api.core.v1.ISysctl=} [properties] Properties to set
                         */
                        function Sysctl(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Sysctl name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @instance
                         */
                        Sysctl.prototype.name = "";

                        /**
                         * Sysctl value.
                         * @member {string} value
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @instance
                         */
                        Sysctl.prototype.value = "";

                        /**
                         * Creates a new Sysctl instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {k8s.io.api.core.v1.ISysctl=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Sysctl} Sysctl instance
                         */
                        Sysctl.create = function create(properties) {
                            return new Sysctl(properties);
                        };

                        /**
                         * Encodes the specified Sysctl message. Does not implicitly {@link k8s.io.api.core.v1.Sysctl.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {k8s.io.api.core.v1.ISysctl} message Sysctl message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Sysctl.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                            return writer;
                        };

                        /**
                         * Encodes the specified Sysctl message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Sysctl.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {k8s.io.api.core.v1.ISysctl} message Sysctl message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Sysctl.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Sysctl message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Sysctl} Sysctl
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Sysctl.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Sysctl();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Sysctl message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Sysctl} Sysctl
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Sysctl.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Sysctl message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Sysctl.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isString(message.value))
                                    return "value: string expected";
                            return null;
                        };

                        /**
                         * Creates a Sysctl message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Sysctl} Sysctl
                         */
                        Sysctl.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Sysctl)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Sysctl();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.value != null)
                                message.value = String(object.value);
                            return message;
                        };

                        /**
                         * Creates a plain object from a Sysctl message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @static
                         * @param {k8s.io.api.core.v1.Sysctl} message Sysctl
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Sysctl.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.value = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            return object;
                        };

                        /**
                         * Converts this Sysctl to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Sysctl
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Sysctl.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Sysctl;
                    })();

                    v1.TCPSocketAction = (function() {

                        /**
                         * Properties of a TCPSocketAction.
                         * @memberof k8s.io.api.core.v1
                         * @interface ITCPSocketAction
                         * @property {k8s.io.apimachinery.pkg.util.intstr.IIntOrString|null} [port] TCPSocketAction port
                         * @property {string|null} [host] TCPSocketAction host
                         */

                        /**
                         * Constructs a new TCPSocketAction.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a TCPSocketAction.
                         * @implements ITCPSocketAction
                         * @constructor
                         * @param {k8s.io.api.core.v1.ITCPSocketAction=} [properties] Properties to set
                         */
                        function TCPSocketAction(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TCPSocketAction port.
                         * @member {k8s.io.apimachinery.pkg.util.intstr.IIntOrString|null|undefined} port
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @instance
                         */
                        TCPSocketAction.prototype.port = null;

                        /**
                         * TCPSocketAction host.
                         * @member {string} host
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @instance
                         */
                        TCPSocketAction.prototype.host = "";

                        /**
                         * Creates a new TCPSocketAction instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {k8s.io.api.core.v1.ITCPSocketAction=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.TCPSocketAction} TCPSocketAction instance
                         */
                        TCPSocketAction.create = function create(properties) {
                            return new TCPSocketAction(properties);
                        };

                        /**
                         * Encodes the specified TCPSocketAction message. Does not implicitly {@link k8s.io.api.core.v1.TCPSocketAction.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {k8s.io.api.core.v1.ITCPSocketAction} message TCPSocketAction message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TCPSocketAction.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                                $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.encode(message.port, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.host);
                            return writer;
                        };

                        /**
                         * Encodes the specified TCPSocketAction message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.TCPSocketAction.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {k8s.io.api.core.v1.ITCPSocketAction} message TCPSocketAction message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TCPSocketAction.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a TCPSocketAction message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.TCPSocketAction} TCPSocketAction
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TCPSocketAction.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.TCPSocketAction();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.port = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.host = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a TCPSocketAction message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.TCPSocketAction} TCPSocketAction
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TCPSocketAction.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a TCPSocketAction message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TCPSocketAction.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.port != null && message.hasOwnProperty("port")) {
                                var error = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.verify(message.port);
                                if (error)
                                    return "port." + error;
                            }
                            if (message.host != null && message.hasOwnProperty("host"))
                                if (!$util.isString(message.host))
                                    return "host: string expected";
                            return null;
                        };

                        /**
                         * Creates a TCPSocketAction message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.TCPSocketAction} TCPSocketAction
                         */
                        TCPSocketAction.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.TCPSocketAction)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.TCPSocketAction();
                            if (object.port != null) {
                                if (typeof object.port !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.TCPSocketAction.port: object expected");
                                message.port = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.fromObject(object.port);
                            }
                            if (object.host != null)
                                message.host = String(object.host);
                            return message;
                        };

                        /**
                         * Creates a plain object from a TCPSocketAction message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @static
                         * @param {k8s.io.api.core.v1.TCPSocketAction} message TCPSocketAction
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TCPSocketAction.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.port = null;
                                object.host = "";
                            }
                            if (message.port != null && message.hasOwnProperty("port"))
                                object.port = $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString.toObject(message.port, options);
                            if (message.host != null && message.hasOwnProperty("host"))
                                object.host = message.host;
                            return object;
                        };

                        /**
                         * Converts this TCPSocketAction to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.TCPSocketAction
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TCPSocketAction.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return TCPSocketAction;
                    })();

                    v1.Taint = (function() {

                        /**
                         * Properties of a Taint.
                         * @memberof k8s.io.api.core.v1
                         * @interface ITaint
                         * @property {string|null} [key] Taint key
                         * @property {string|null} [value] Taint value
                         * @property {string|null} [effect] Taint effect
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [timeAdded] Taint timeAdded
                         */

                        /**
                         * Constructs a new Taint.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Taint.
                         * @implements ITaint
                         * @constructor
                         * @param {k8s.io.api.core.v1.ITaint=} [properties] Properties to set
                         */
                        function Taint(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Taint key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.Taint
                         * @instance
                         */
                        Taint.prototype.key = "";

                        /**
                         * Taint value.
                         * @member {string} value
                         * @memberof k8s.io.api.core.v1.Taint
                         * @instance
                         */
                        Taint.prototype.value = "";

                        /**
                         * Taint effect.
                         * @member {string} effect
                         * @memberof k8s.io.api.core.v1.Taint
                         * @instance
                         */
                        Taint.prototype.effect = "";

                        /**
                         * Taint timeAdded.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} timeAdded
                         * @memberof k8s.io.api.core.v1.Taint
                         * @instance
                         */
                        Taint.prototype.timeAdded = null;

                        /**
                         * Creates a new Taint instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {k8s.io.api.core.v1.ITaint=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Taint} Taint instance
                         */
                        Taint.create = function create(properties) {
                            return new Taint(properties);
                        };

                        /**
                         * Encodes the specified Taint message. Does not implicitly {@link k8s.io.api.core.v1.Taint.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {k8s.io.api.core.v1.ITaint} message Taint message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Taint.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                            if (message.effect != null && Object.hasOwnProperty.call(message, "effect"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.effect);
                            if (message.timeAdded != null && Object.hasOwnProperty.call(message, "timeAdded"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.timeAdded, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Taint message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Taint.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {k8s.io.api.core.v1.ITaint} message Taint message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Taint.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Taint message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Taint} Taint
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Taint.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Taint();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.key = reader.string();
                                    break;
                                case 2:
                                    message.value = reader.string();
                                    break;
                                case 3:
                                    message.effect = reader.string();
                                    break;
                                case 4:
                                    message.timeAdded = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Taint message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Taint} Taint
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Taint.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Taint message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Taint.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isString(message.value))
                                    return "value: string expected";
                            if (message.effect != null && message.hasOwnProperty("effect"))
                                if (!$util.isString(message.effect))
                                    return "effect: string expected";
                            if (message.timeAdded != null && message.hasOwnProperty("timeAdded")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.timeAdded);
                                if (error)
                                    return "timeAdded." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Taint message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Taint} Taint
                         */
                        Taint.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Taint)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Taint();
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.value != null)
                                message.value = String(object.value);
                            if (object.effect != null)
                                message.effect = String(object.effect);
                            if (object.timeAdded != null) {
                                if (typeof object.timeAdded !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Taint.timeAdded: object expected");
                                message.timeAdded = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.timeAdded);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Taint message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Taint
                         * @static
                         * @param {k8s.io.api.core.v1.Taint} message Taint
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Taint.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.key = "";
                                object.value = "";
                                object.effect = "";
                                object.timeAdded = null;
                            }
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            if (message.effect != null && message.hasOwnProperty("effect"))
                                object.effect = message.effect;
                            if (message.timeAdded != null && message.hasOwnProperty("timeAdded"))
                                object.timeAdded = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.timeAdded, options);
                            return object;
                        };

                        /**
                         * Converts this Taint to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Taint
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Taint.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Taint;
                    })();

                    v1.Toleration = (function() {

                        /**
                         * Properties of a Toleration.
                         * @memberof k8s.io.api.core.v1
                         * @interface IToleration
                         * @property {string|null} [key] Toleration key
                         * @property {string|null} [operator] Toleration operator
                         * @property {string|null} [value] Toleration value
                         * @property {string|null} [effect] Toleration effect
                         * @property {number|Long|null} [tolerationSeconds] Toleration tolerationSeconds
                         */

                        /**
                         * Constructs a new Toleration.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Toleration.
                         * @implements IToleration
                         * @constructor
                         * @param {k8s.io.api.core.v1.IToleration=} [properties] Properties to set
                         */
                        function Toleration(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Toleration key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @instance
                         */
                        Toleration.prototype.key = "";

                        /**
                         * Toleration operator.
                         * @member {string} operator
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @instance
                         */
                        Toleration.prototype.operator = "";

                        /**
                         * Toleration value.
                         * @member {string} value
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @instance
                         */
                        Toleration.prototype.value = "";

                        /**
                         * Toleration effect.
                         * @member {string} effect
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @instance
                         */
                        Toleration.prototype.effect = "";

                        /**
                         * Toleration tolerationSeconds.
                         * @member {number|Long} tolerationSeconds
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @instance
                         */
                        Toleration.prototype.tolerationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new Toleration instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {k8s.io.api.core.v1.IToleration=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Toleration} Toleration instance
                         */
                        Toleration.create = function create(properties) {
                            return new Toleration(properties);
                        };

                        /**
                         * Encodes the specified Toleration message. Does not implicitly {@link k8s.io.api.core.v1.Toleration.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {k8s.io.api.core.v1.IToleration} message Toleration message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Toleration.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                            if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operator);
                            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
                            if (message.effect != null && Object.hasOwnProperty.call(message, "effect"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.effect);
                            if (message.tolerationSeconds != null && Object.hasOwnProperty.call(message, "tolerationSeconds"))
                                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.tolerationSeconds);
                            return writer;
                        };

                        /**
                         * Encodes the specified Toleration message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Toleration.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {k8s.io.api.core.v1.IToleration} message Toleration message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Toleration.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Toleration message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Toleration} Toleration
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Toleration.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Toleration();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.key = reader.string();
                                    break;
                                case 2:
                                    message.operator = reader.string();
                                    break;
                                case 3:
                                    message.value = reader.string();
                                    break;
                                case 4:
                                    message.effect = reader.string();
                                    break;
                                case 5:
                                    message.tolerationSeconds = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Toleration message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Toleration} Toleration
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Toleration.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Toleration message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Toleration.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.operator != null && message.hasOwnProperty("operator"))
                                if (!$util.isString(message.operator))
                                    return "operator: string expected";
                            if (message.value != null && message.hasOwnProperty("value"))
                                if (!$util.isString(message.value))
                                    return "value: string expected";
                            if (message.effect != null && message.hasOwnProperty("effect"))
                                if (!$util.isString(message.effect))
                                    return "effect: string expected";
                            if (message.tolerationSeconds != null && message.hasOwnProperty("tolerationSeconds"))
                                if (!$util.isInteger(message.tolerationSeconds) && !(message.tolerationSeconds && $util.isInteger(message.tolerationSeconds.low) && $util.isInteger(message.tolerationSeconds.high)))
                                    return "tolerationSeconds: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a Toleration message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Toleration} Toleration
                         */
                        Toleration.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Toleration)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Toleration();
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.operator != null)
                                message.operator = String(object.operator);
                            if (object.value != null)
                                message.value = String(object.value);
                            if (object.effect != null)
                                message.effect = String(object.effect);
                            if (object.tolerationSeconds != null)
                                if ($util.Long)
                                    (message.tolerationSeconds = $util.Long.fromValue(object.tolerationSeconds)).unsigned = false;
                                else if (typeof object.tolerationSeconds === "string")
                                    message.tolerationSeconds = parseInt(object.tolerationSeconds, 10);
                                else if (typeof object.tolerationSeconds === "number")
                                    message.tolerationSeconds = object.tolerationSeconds;
                                else if (typeof object.tolerationSeconds === "object")
                                    message.tolerationSeconds = new $util.LongBits(object.tolerationSeconds.low >>> 0, object.tolerationSeconds.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a Toleration message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @static
                         * @param {k8s.io.api.core.v1.Toleration} message Toleration
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Toleration.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.key = "";
                                object.operator = "";
                                object.value = "";
                                object.effect = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.tolerationSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.tolerationSeconds = options.longs === String ? "0" : 0;
                            }
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.operator != null && message.hasOwnProperty("operator"))
                                object.operator = message.operator;
                            if (message.value != null && message.hasOwnProperty("value"))
                                object.value = message.value;
                            if (message.effect != null && message.hasOwnProperty("effect"))
                                object.effect = message.effect;
                            if (message.tolerationSeconds != null && message.hasOwnProperty("tolerationSeconds"))
                                if (typeof message.tolerationSeconds === "number")
                                    object.tolerationSeconds = options.longs === String ? String(message.tolerationSeconds) : message.tolerationSeconds;
                                else
                                    object.tolerationSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.tolerationSeconds) : options.longs === Number ? new $util.LongBits(message.tolerationSeconds.low >>> 0, message.tolerationSeconds.high >>> 0).toNumber() : message.tolerationSeconds;
                            return object;
                        };

                        /**
                         * Converts this Toleration to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Toleration
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Toleration.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Toleration;
                    })();

                    v1.TopologySelectorLabelRequirement = (function() {

                        /**
                         * Properties of a TopologySelectorLabelRequirement.
                         * @memberof k8s.io.api.core.v1
                         * @interface ITopologySelectorLabelRequirement
                         * @property {string|null} [key] TopologySelectorLabelRequirement key
                         * @property {Array.<string>|null} [values] TopologySelectorLabelRequirement values
                         */

                        /**
                         * Constructs a new TopologySelectorLabelRequirement.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a TopologySelectorLabelRequirement.
                         * @implements ITopologySelectorLabelRequirement
                         * @constructor
                         * @param {k8s.io.api.core.v1.ITopologySelectorLabelRequirement=} [properties] Properties to set
                         */
                        function TopologySelectorLabelRequirement(properties) {
                            this.values = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TopologySelectorLabelRequirement key.
                         * @member {string} key
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @instance
                         */
                        TopologySelectorLabelRequirement.prototype.key = "";

                        /**
                         * TopologySelectorLabelRequirement values.
                         * @member {Array.<string>} values
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @instance
                         */
                        TopologySelectorLabelRequirement.prototype.values = $util.emptyArray;

                        /**
                         * Creates a new TopologySelectorLabelRequirement instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySelectorLabelRequirement=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.TopologySelectorLabelRequirement} TopologySelectorLabelRequirement instance
                         */
                        TopologySelectorLabelRequirement.create = function create(properties) {
                            return new TopologySelectorLabelRequirement(properties);
                        };

                        /**
                         * Encodes the specified TopologySelectorLabelRequirement message. Does not implicitly {@link k8s.io.api.core.v1.TopologySelectorLabelRequirement.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySelectorLabelRequirement} message TopologySelectorLabelRequirement message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TopologySelectorLabelRequirement.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                            if (message.values != null && message.values.length)
                                for (var i = 0; i < message.values.length; ++i)
                                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                            return writer;
                        };

                        /**
                         * Encodes the specified TopologySelectorLabelRequirement message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.TopologySelectorLabelRequirement.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySelectorLabelRequirement} message TopologySelectorLabelRequirement message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TopologySelectorLabelRequirement.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a TopologySelectorLabelRequirement message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.TopologySelectorLabelRequirement} TopologySelectorLabelRequirement
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TopologySelectorLabelRequirement.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.key = reader.string();
                                    break;
                                case 2:
                                    if (!(message.values && message.values.length))
                                        message.values = [];
                                    message.values.push(reader.string());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a TopologySelectorLabelRequirement message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.TopologySelectorLabelRequirement} TopologySelectorLabelRequirement
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TopologySelectorLabelRequirement.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a TopologySelectorLabelRequirement message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TopologySelectorLabelRequirement.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.key != null && message.hasOwnProperty("key"))
                                if (!$util.isString(message.key))
                                    return "key: string expected";
                            if (message.values != null && message.hasOwnProperty("values")) {
                                if (!Array.isArray(message.values))
                                    return "values: array expected";
                                for (var i = 0; i < message.values.length; ++i)
                                    if (!$util.isString(message.values[i]))
                                        return "values: string[] expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a TopologySelectorLabelRequirement message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.TopologySelectorLabelRequirement} TopologySelectorLabelRequirement
                         */
                        TopologySelectorLabelRequirement.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement();
                            if (object.key != null)
                                message.key = String(object.key);
                            if (object.values) {
                                if (!Array.isArray(object.values))
                                    throw TypeError(".k8s.io.api.core.v1.TopologySelectorLabelRequirement.values: array expected");
                                message.values = [];
                                for (var i = 0; i < object.values.length; ++i)
                                    message.values[i] = String(object.values[i]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a TopologySelectorLabelRequirement message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @static
                         * @param {k8s.io.api.core.v1.TopologySelectorLabelRequirement} message TopologySelectorLabelRequirement
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TopologySelectorLabelRequirement.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.values = [];
                            if (options.defaults)
                                object.key = "";
                            if (message.key != null && message.hasOwnProperty("key"))
                                object.key = message.key;
                            if (message.values && message.values.length) {
                                object.values = [];
                                for (var j = 0; j < message.values.length; ++j)
                                    object.values[j] = message.values[j];
                            }
                            return object;
                        };

                        /**
                         * Converts this TopologySelectorLabelRequirement to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.TopologySelectorLabelRequirement
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TopologySelectorLabelRequirement.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return TopologySelectorLabelRequirement;
                    })();

                    v1.TopologySelectorTerm = (function() {

                        /**
                         * Properties of a TopologySelectorTerm.
                         * @memberof k8s.io.api.core.v1
                         * @interface ITopologySelectorTerm
                         * @property {Array.<k8s.io.api.core.v1.ITopologySelectorLabelRequirement>|null} [matchLabelExpressions] TopologySelectorTerm matchLabelExpressions
                         */

                        /**
                         * Constructs a new TopologySelectorTerm.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a TopologySelectorTerm.
                         * @implements ITopologySelectorTerm
                         * @constructor
                         * @param {k8s.io.api.core.v1.ITopologySelectorTerm=} [properties] Properties to set
                         */
                        function TopologySelectorTerm(properties) {
                            this.matchLabelExpressions = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TopologySelectorTerm matchLabelExpressions.
                         * @member {Array.<k8s.io.api.core.v1.ITopologySelectorLabelRequirement>} matchLabelExpressions
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @instance
                         */
                        TopologySelectorTerm.prototype.matchLabelExpressions = $util.emptyArray;

                        /**
                         * Creates a new TopologySelectorTerm instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySelectorTerm=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.TopologySelectorTerm} TopologySelectorTerm instance
                         */
                        TopologySelectorTerm.create = function create(properties) {
                            return new TopologySelectorTerm(properties);
                        };

                        /**
                         * Encodes the specified TopologySelectorTerm message. Does not implicitly {@link k8s.io.api.core.v1.TopologySelectorTerm.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySelectorTerm} message TopologySelectorTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TopologySelectorTerm.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.matchLabelExpressions != null && message.matchLabelExpressions.length)
                                for (var i = 0; i < message.matchLabelExpressions.length; ++i)
                                    $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement.encode(message.matchLabelExpressions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified TopologySelectorTerm message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.TopologySelectorTerm.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySelectorTerm} message TopologySelectorTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TopologySelectorTerm.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a TopologySelectorTerm message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.TopologySelectorTerm} TopologySelectorTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TopologySelectorTerm.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.TopologySelectorTerm();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    if (!(message.matchLabelExpressions && message.matchLabelExpressions.length))
                                        message.matchLabelExpressions = [];
                                    message.matchLabelExpressions.push($root.k8s.io.api.core.v1.TopologySelectorLabelRequirement.decode(reader, reader.uint32()));
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a TopologySelectorTerm message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.TopologySelectorTerm} TopologySelectorTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TopologySelectorTerm.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a TopologySelectorTerm message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TopologySelectorTerm.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.matchLabelExpressions != null && message.hasOwnProperty("matchLabelExpressions")) {
                                if (!Array.isArray(message.matchLabelExpressions))
                                    return "matchLabelExpressions: array expected";
                                for (var i = 0; i < message.matchLabelExpressions.length; ++i) {
                                    var error = $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement.verify(message.matchLabelExpressions[i]);
                                    if (error)
                                        return "matchLabelExpressions." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a TopologySelectorTerm message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.TopologySelectorTerm} TopologySelectorTerm
                         */
                        TopologySelectorTerm.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.TopologySelectorTerm)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.TopologySelectorTerm();
                            if (object.matchLabelExpressions) {
                                if (!Array.isArray(object.matchLabelExpressions))
                                    throw TypeError(".k8s.io.api.core.v1.TopologySelectorTerm.matchLabelExpressions: array expected");
                                message.matchLabelExpressions = [];
                                for (var i = 0; i < object.matchLabelExpressions.length; ++i) {
                                    if (typeof object.matchLabelExpressions[i] !== "object")
                                        throw TypeError(".k8s.io.api.core.v1.TopologySelectorTerm.matchLabelExpressions: object expected");
                                    message.matchLabelExpressions[i] = $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement.fromObject(object.matchLabelExpressions[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a TopologySelectorTerm message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @static
                         * @param {k8s.io.api.core.v1.TopologySelectorTerm} message TopologySelectorTerm
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TopologySelectorTerm.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.matchLabelExpressions = [];
                            if (message.matchLabelExpressions && message.matchLabelExpressions.length) {
                                object.matchLabelExpressions = [];
                                for (var j = 0; j < message.matchLabelExpressions.length; ++j)
                                    object.matchLabelExpressions[j] = $root.k8s.io.api.core.v1.TopologySelectorLabelRequirement.toObject(message.matchLabelExpressions[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this TopologySelectorTerm to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.TopologySelectorTerm
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TopologySelectorTerm.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return TopologySelectorTerm;
                    })();

                    v1.TopologySpreadConstraint = (function() {

                        /**
                         * Properties of a TopologySpreadConstraint.
                         * @memberof k8s.io.api.core.v1
                         * @interface ITopologySpreadConstraint
                         * @property {number|null} [maxSkew] TopologySpreadConstraint maxSkew
                         * @property {string|null} [topologyKey] TopologySpreadConstraint topologyKey
                         * @property {string|null} [whenUnsatisfiable] TopologySpreadConstraint whenUnsatisfiable
                         * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector|null} [labelSelector] TopologySpreadConstraint labelSelector
                         */

                        /**
                         * Constructs a new TopologySpreadConstraint.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a TopologySpreadConstraint.
                         * @implements ITopologySpreadConstraint
                         * @constructor
                         * @param {k8s.io.api.core.v1.ITopologySpreadConstraint=} [properties] Properties to set
                         */
                        function TopologySpreadConstraint(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TopologySpreadConstraint maxSkew.
                         * @member {number} maxSkew
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @instance
                         */
                        TopologySpreadConstraint.prototype.maxSkew = 0;

                        /**
                         * TopologySpreadConstraint topologyKey.
                         * @member {string} topologyKey
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @instance
                         */
                        TopologySpreadConstraint.prototype.topologyKey = "";

                        /**
                         * TopologySpreadConstraint whenUnsatisfiable.
                         * @member {string} whenUnsatisfiable
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @instance
                         */
                        TopologySpreadConstraint.prototype.whenUnsatisfiable = "";

                        /**
                         * TopologySpreadConstraint labelSelector.
                         * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector|null|undefined} labelSelector
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @instance
                         */
                        TopologySpreadConstraint.prototype.labelSelector = null;

                        /**
                         * Creates a new TopologySpreadConstraint instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySpreadConstraint=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.TopologySpreadConstraint} TopologySpreadConstraint instance
                         */
                        TopologySpreadConstraint.create = function create(properties) {
                            return new TopologySpreadConstraint(properties);
                        };

                        /**
                         * Encodes the specified TopologySpreadConstraint message. Does not implicitly {@link k8s.io.api.core.v1.TopologySpreadConstraint.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySpreadConstraint} message TopologySpreadConstraint message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TopologySpreadConstraint.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.maxSkew != null && Object.hasOwnProperty.call(message, "maxSkew"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxSkew);
                            if (message.topologyKey != null && Object.hasOwnProperty.call(message, "topologyKey"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.topologyKey);
                            if (message.whenUnsatisfiable != null && Object.hasOwnProperty.call(message, "whenUnsatisfiable"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.whenUnsatisfiable);
                            if (message.labelSelector != null && Object.hasOwnProperty.call(message, "labelSelector"))
                                $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.encode(message.labelSelector, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified TopologySpreadConstraint message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.TopologySpreadConstraint.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {k8s.io.api.core.v1.ITopologySpreadConstraint} message TopologySpreadConstraint message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TopologySpreadConstraint.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a TopologySpreadConstraint message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.TopologySpreadConstraint} TopologySpreadConstraint
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TopologySpreadConstraint.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.TopologySpreadConstraint();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.maxSkew = reader.int32();
                                    break;
                                case 2:
                                    message.topologyKey = reader.string();
                                    break;
                                case 3:
                                    message.whenUnsatisfiable = reader.string();
                                    break;
                                case 4:
                                    message.labelSelector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a TopologySpreadConstraint message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.TopologySpreadConstraint} TopologySpreadConstraint
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TopologySpreadConstraint.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a TopologySpreadConstraint message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TopologySpreadConstraint.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.maxSkew != null && message.hasOwnProperty("maxSkew"))
                                if (!$util.isInteger(message.maxSkew))
                                    return "maxSkew: integer expected";
                            if (message.topologyKey != null && message.hasOwnProperty("topologyKey"))
                                if (!$util.isString(message.topologyKey))
                                    return "topologyKey: string expected";
                            if (message.whenUnsatisfiable != null && message.hasOwnProperty("whenUnsatisfiable"))
                                if (!$util.isString(message.whenUnsatisfiable))
                                    return "whenUnsatisfiable: string expected";
                            if (message.labelSelector != null && message.hasOwnProperty("labelSelector")) {
                                var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.verify(message.labelSelector);
                                if (error)
                                    return "labelSelector." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a TopologySpreadConstraint message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.TopologySpreadConstraint} TopologySpreadConstraint
                         */
                        TopologySpreadConstraint.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.TopologySpreadConstraint)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.TopologySpreadConstraint();
                            if (object.maxSkew != null)
                                message.maxSkew = object.maxSkew | 0;
                            if (object.topologyKey != null)
                                message.topologyKey = String(object.topologyKey);
                            if (object.whenUnsatisfiable != null)
                                message.whenUnsatisfiable = String(object.whenUnsatisfiable);
                            if (object.labelSelector != null) {
                                if (typeof object.labelSelector !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.TopologySpreadConstraint.labelSelector: object expected");
                                message.labelSelector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.fromObject(object.labelSelector);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a TopologySpreadConstraint message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @static
                         * @param {k8s.io.api.core.v1.TopologySpreadConstraint} message TopologySpreadConstraint
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TopologySpreadConstraint.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.maxSkew = 0;
                                object.topologyKey = "";
                                object.whenUnsatisfiable = "";
                                object.labelSelector = null;
                            }
                            if (message.maxSkew != null && message.hasOwnProperty("maxSkew"))
                                object.maxSkew = message.maxSkew;
                            if (message.topologyKey != null && message.hasOwnProperty("topologyKey"))
                                object.topologyKey = message.topologyKey;
                            if (message.whenUnsatisfiable != null && message.hasOwnProperty("whenUnsatisfiable"))
                                object.whenUnsatisfiable = message.whenUnsatisfiable;
                            if (message.labelSelector != null && message.hasOwnProperty("labelSelector"))
                                object.labelSelector = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.toObject(message.labelSelector, options);
                            return object;
                        };

                        /**
                         * Converts this TopologySpreadConstraint to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.TopologySpreadConstraint
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TopologySpreadConstraint.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return TopologySpreadConstraint;
                    })();

                    v1.TypedLocalObjectReference = (function() {

                        /**
                         * Properties of a TypedLocalObjectReference.
                         * @memberof k8s.io.api.core.v1
                         * @interface ITypedLocalObjectReference
                         * @property {string|null} [apiGroup] TypedLocalObjectReference apiGroup
                         * @property {string|null} [kind] TypedLocalObjectReference kind
                         * @property {string|null} [name] TypedLocalObjectReference name
                         */

                        /**
                         * Constructs a new TypedLocalObjectReference.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a TypedLocalObjectReference.
                         * @implements ITypedLocalObjectReference
                         * @constructor
                         * @param {k8s.io.api.core.v1.ITypedLocalObjectReference=} [properties] Properties to set
                         */
                        function TypedLocalObjectReference(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TypedLocalObjectReference apiGroup.
                         * @member {string} apiGroup
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @instance
                         */
                        TypedLocalObjectReference.prototype.apiGroup = "";

                        /**
                         * TypedLocalObjectReference kind.
                         * @member {string} kind
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @instance
                         */
                        TypedLocalObjectReference.prototype.kind = "";

                        /**
                         * TypedLocalObjectReference name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @instance
                         */
                        TypedLocalObjectReference.prototype.name = "";

                        /**
                         * Creates a new TypedLocalObjectReference instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ITypedLocalObjectReference=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.TypedLocalObjectReference} TypedLocalObjectReference instance
                         */
                        TypedLocalObjectReference.create = function create(properties) {
                            return new TypedLocalObjectReference(properties);
                        };

                        /**
                         * Encodes the specified TypedLocalObjectReference message. Does not implicitly {@link k8s.io.api.core.v1.TypedLocalObjectReference.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ITypedLocalObjectReference} message TypedLocalObjectReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TypedLocalObjectReference.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.apiGroup != null && Object.hasOwnProperty.call(message, "apiGroup"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.apiGroup);
                            if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.kind);
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                            return writer;
                        };

                        /**
                         * Encodes the specified TypedLocalObjectReference message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.TypedLocalObjectReference.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.ITypedLocalObjectReference} message TypedLocalObjectReference message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TypedLocalObjectReference.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a TypedLocalObjectReference message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.TypedLocalObjectReference} TypedLocalObjectReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TypedLocalObjectReference.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.TypedLocalObjectReference();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.apiGroup = reader.string();
                                    break;
                                case 2:
                                    message.kind = reader.string();
                                    break;
                                case 3:
                                    message.name = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a TypedLocalObjectReference message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.TypedLocalObjectReference} TypedLocalObjectReference
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TypedLocalObjectReference.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a TypedLocalObjectReference message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TypedLocalObjectReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.apiGroup != null && message.hasOwnProperty("apiGroup"))
                                if (!$util.isString(message.apiGroup))
                                    return "apiGroup: string expected";
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                if (!$util.isString(message.kind))
                                    return "kind: string expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            return null;
                        };

                        /**
                         * Creates a TypedLocalObjectReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.TypedLocalObjectReference} TypedLocalObjectReference
                         */
                        TypedLocalObjectReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.TypedLocalObjectReference)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.TypedLocalObjectReference();
                            if (object.apiGroup != null)
                                message.apiGroup = String(object.apiGroup);
                            if (object.kind != null)
                                message.kind = String(object.kind);
                            if (object.name != null)
                                message.name = String(object.name);
                            return message;
                        };

                        /**
                         * Creates a plain object from a TypedLocalObjectReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @static
                         * @param {k8s.io.api.core.v1.TypedLocalObjectReference} message TypedLocalObjectReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TypedLocalObjectReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.apiGroup = "";
                                object.kind = "";
                                object.name = "";
                            }
                            if (message.apiGroup != null && message.hasOwnProperty("apiGroup"))
                                object.apiGroup = message.apiGroup;
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                object.kind = message.kind;
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            return object;
                        };

                        /**
                         * Converts this TypedLocalObjectReference to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.TypedLocalObjectReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TypedLocalObjectReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return TypedLocalObjectReference;
                    })();

                    v1.Volume = (function() {

                        /**
                         * Properties of a Volume.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVolume
                         * @property {string|null} [name] Volume name
                         * @property {k8s.io.api.core.v1.IVolumeSource|null} [volumeSource] Volume volumeSource
                         */

                        /**
                         * Constructs a new Volume.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a Volume.
                         * @implements IVolume
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVolume=} [properties] Properties to set
                         */
                        function Volume(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Volume name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.Volume
                         * @instance
                         */
                        Volume.prototype.name = "";

                        /**
                         * Volume volumeSource.
                         * @member {k8s.io.api.core.v1.IVolumeSource|null|undefined} volumeSource
                         * @memberof k8s.io.api.core.v1.Volume
                         * @instance
                         */
                        Volume.prototype.volumeSource = null;

                        /**
                         * Creates a new Volume instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {k8s.io.api.core.v1.IVolume=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.Volume} Volume instance
                         */
                        Volume.create = function create(properties) {
                            return new Volume(properties);
                        };

                        /**
                         * Encodes the specified Volume message. Does not implicitly {@link k8s.io.api.core.v1.Volume.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {k8s.io.api.core.v1.IVolume} message Volume message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Volume.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.volumeSource != null && Object.hasOwnProperty.call(message, "volumeSource"))
                                $root.k8s.io.api.core.v1.VolumeSource.encode(message.volumeSource, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Volume message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.Volume.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {k8s.io.api.core.v1.IVolume} message Volume message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Volume.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Volume message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.Volume} Volume
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Volume.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.Volume();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.volumeSource = $root.k8s.io.api.core.v1.VolumeSource.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Volume message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.Volume} Volume
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Volume.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Volume message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Volume.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.volumeSource != null && message.hasOwnProperty("volumeSource")) {
                                var error = $root.k8s.io.api.core.v1.VolumeSource.verify(message.volumeSource);
                                if (error)
                                    return "volumeSource." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a Volume message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.Volume} Volume
                         */
                        Volume.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.Volume)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.Volume();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.volumeSource != null) {
                                if (typeof object.volumeSource !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.Volume.volumeSource: object expected");
                                message.volumeSource = $root.k8s.io.api.core.v1.VolumeSource.fromObject(object.volumeSource);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Volume message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.Volume
                         * @static
                         * @param {k8s.io.api.core.v1.Volume} message Volume
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Volume.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.volumeSource = null;
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.volumeSource != null && message.hasOwnProperty("volumeSource"))
                                object.volumeSource = $root.k8s.io.api.core.v1.VolumeSource.toObject(message.volumeSource, options);
                            return object;
                        };

                        /**
                         * Converts this Volume to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.Volume
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Volume.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Volume;
                    })();

                    v1.VolumeDevice = (function() {

                        /**
                         * Properties of a VolumeDevice.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVolumeDevice
                         * @property {string|null} [name] VolumeDevice name
                         * @property {string|null} [devicePath] VolumeDevice devicePath
                         */

                        /**
                         * Constructs a new VolumeDevice.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a VolumeDevice.
                         * @implements IVolumeDevice
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVolumeDevice=} [properties] Properties to set
                         */
                        function VolumeDevice(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VolumeDevice name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @instance
                         */
                        VolumeDevice.prototype.name = "";

                        /**
                         * VolumeDevice devicePath.
                         * @member {string} devicePath
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @instance
                         */
                        VolumeDevice.prototype.devicePath = "";

                        /**
                         * Creates a new VolumeDevice instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeDevice=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.VolumeDevice} VolumeDevice instance
                         */
                        VolumeDevice.create = function create(properties) {
                            return new VolumeDevice(properties);
                        };

                        /**
                         * Encodes the specified VolumeDevice message. Does not implicitly {@link k8s.io.api.core.v1.VolumeDevice.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeDevice} message VolumeDevice message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeDevice.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.devicePath != null && Object.hasOwnProperty.call(message, "devicePath"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.devicePath);
                            return writer;
                        };

                        /**
                         * Encodes the specified VolumeDevice message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.VolumeDevice.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeDevice} message VolumeDevice message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeDevice.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VolumeDevice message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.VolumeDevice} VolumeDevice
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeDevice.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.VolumeDevice();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.devicePath = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VolumeDevice message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.VolumeDevice} VolumeDevice
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeDevice.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VolumeDevice message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VolumeDevice.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.devicePath != null && message.hasOwnProperty("devicePath"))
                                if (!$util.isString(message.devicePath))
                                    return "devicePath: string expected";
                            return null;
                        };

                        /**
                         * Creates a VolumeDevice message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.VolumeDevice} VolumeDevice
                         */
                        VolumeDevice.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.VolumeDevice)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.VolumeDevice();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.devicePath != null)
                                message.devicePath = String(object.devicePath);
                            return message;
                        };

                        /**
                         * Creates a plain object from a VolumeDevice message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @static
                         * @param {k8s.io.api.core.v1.VolumeDevice} message VolumeDevice
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VolumeDevice.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.devicePath = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.devicePath != null && message.hasOwnProperty("devicePath"))
                                object.devicePath = message.devicePath;
                            return object;
                        };

                        /**
                         * Converts this VolumeDevice to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.VolumeDevice
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VolumeDevice.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VolumeDevice;
                    })();

                    v1.VolumeMount = (function() {

                        /**
                         * Properties of a VolumeMount.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVolumeMount
                         * @property {string|null} [name] VolumeMount name
                         * @property {boolean|null} [readOnly] VolumeMount readOnly
                         * @property {string|null} [mountPath] VolumeMount mountPath
                         * @property {string|null} [subPath] VolumeMount subPath
                         * @property {string|null} [mountPropagation] VolumeMount mountPropagation
                         * @property {string|null} [subPathExpr] VolumeMount subPathExpr
                         */

                        /**
                         * Constructs a new VolumeMount.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a VolumeMount.
                         * @implements IVolumeMount
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVolumeMount=} [properties] Properties to set
                         */
                        function VolumeMount(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VolumeMount name.
                         * @member {string} name
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         */
                        VolumeMount.prototype.name = "";

                        /**
                         * VolumeMount readOnly.
                         * @member {boolean} readOnly
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         */
                        VolumeMount.prototype.readOnly = false;

                        /**
                         * VolumeMount mountPath.
                         * @member {string} mountPath
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         */
                        VolumeMount.prototype.mountPath = "";

                        /**
                         * VolumeMount subPath.
                         * @member {string} subPath
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         */
                        VolumeMount.prototype.subPath = "";

                        /**
                         * VolumeMount mountPropagation.
                         * @member {string} mountPropagation
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         */
                        VolumeMount.prototype.mountPropagation = "";

                        /**
                         * VolumeMount subPathExpr.
                         * @member {string} subPathExpr
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         */
                        VolumeMount.prototype.subPathExpr = "";

                        /**
                         * Creates a new VolumeMount instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeMount=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.VolumeMount} VolumeMount instance
                         */
                        VolumeMount.create = function create(properties) {
                            return new VolumeMount(properties);
                        };

                        /**
                         * Encodes the specified VolumeMount message. Does not implicitly {@link k8s.io.api.core.v1.VolumeMount.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeMount} message VolumeMount message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeMount.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                            if (message.readOnly != null && Object.hasOwnProperty.call(message, "readOnly"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.readOnly);
                            if (message.mountPath != null && Object.hasOwnProperty.call(message, "mountPath"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.mountPath);
                            if (message.subPath != null && Object.hasOwnProperty.call(message, "subPath"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.subPath);
                            if (message.mountPropagation != null && Object.hasOwnProperty.call(message, "mountPropagation"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.mountPropagation);
                            if (message.subPathExpr != null && Object.hasOwnProperty.call(message, "subPathExpr"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.subPathExpr);
                            return writer;
                        };

                        /**
                         * Encodes the specified VolumeMount message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.VolumeMount.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeMount} message VolumeMount message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeMount.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VolumeMount message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.VolumeMount} VolumeMount
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeMount.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.VolumeMount();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.name = reader.string();
                                    break;
                                case 2:
                                    message.readOnly = reader.bool();
                                    break;
                                case 3:
                                    message.mountPath = reader.string();
                                    break;
                                case 4:
                                    message.subPath = reader.string();
                                    break;
                                case 5:
                                    message.mountPropagation = reader.string();
                                    break;
                                case 6:
                                    message.subPathExpr = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VolumeMount message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.VolumeMount} VolumeMount
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeMount.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VolumeMount message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VolumeMount.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                if (typeof message.readOnly !== "boolean")
                                    return "readOnly: boolean expected";
                            if (message.mountPath != null && message.hasOwnProperty("mountPath"))
                                if (!$util.isString(message.mountPath))
                                    return "mountPath: string expected";
                            if (message.subPath != null && message.hasOwnProperty("subPath"))
                                if (!$util.isString(message.subPath))
                                    return "subPath: string expected";
                            if (message.mountPropagation != null && message.hasOwnProperty("mountPropagation"))
                                if (!$util.isString(message.mountPropagation))
                                    return "mountPropagation: string expected";
                            if (message.subPathExpr != null && message.hasOwnProperty("subPathExpr"))
                                if (!$util.isString(message.subPathExpr))
                                    return "subPathExpr: string expected";
                            return null;
                        };

                        /**
                         * Creates a VolumeMount message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.VolumeMount} VolumeMount
                         */
                        VolumeMount.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.VolumeMount)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.VolumeMount();
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.readOnly != null)
                                message.readOnly = Boolean(object.readOnly);
                            if (object.mountPath != null)
                                message.mountPath = String(object.mountPath);
                            if (object.subPath != null)
                                message.subPath = String(object.subPath);
                            if (object.mountPropagation != null)
                                message.mountPropagation = String(object.mountPropagation);
                            if (object.subPathExpr != null)
                                message.subPathExpr = String(object.subPathExpr);
                            return message;
                        };

                        /**
                         * Creates a plain object from a VolumeMount message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @static
                         * @param {k8s.io.api.core.v1.VolumeMount} message VolumeMount
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VolumeMount.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.name = "";
                                object.readOnly = false;
                                object.mountPath = "";
                                object.subPath = "";
                                object.mountPropagation = "";
                                object.subPathExpr = "";
                            }
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.readOnly != null && message.hasOwnProperty("readOnly"))
                                object.readOnly = message.readOnly;
                            if (message.mountPath != null && message.hasOwnProperty("mountPath"))
                                object.mountPath = message.mountPath;
                            if (message.subPath != null && message.hasOwnProperty("subPath"))
                                object.subPath = message.subPath;
                            if (message.mountPropagation != null && message.hasOwnProperty("mountPropagation"))
                                object.mountPropagation = message.mountPropagation;
                            if (message.subPathExpr != null && message.hasOwnProperty("subPathExpr"))
                                object.subPathExpr = message.subPathExpr;
                            return object;
                        };

                        /**
                         * Converts this VolumeMount to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.VolumeMount
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VolumeMount.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VolumeMount;
                    })();

                    v1.VolumeNodeAffinity = (function() {

                        /**
                         * Properties of a VolumeNodeAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVolumeNodeAffinity
                         * @property {k8s.io.api.core.v1.INodeSelector|null} [required] VolumeNodeAffinity required
                         */

                        /**
                         * Constructs a new VolumeNodeAffinity.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a VolumeNodeAffinity.
                         * @implements IVolumeNodeAffinity
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVolumeNodeAffinity=} [properties] Properties to set
                         */
                        function VolumeNodeAffinity(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VolumeNodeAffinity required.
                         * @member {k8s.io.api.core.v1.INodeSelector|null|undefined} required
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @instance
                         */
                        VolumeNodeAffinity.prototype.required = null;

                        /**
                         * Creates a new VolumeNodeAffinity instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeNodeAffinity=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.VolumeNodeAffinity} VolumeNodeAffinity instance
                         */
                        VolumeNodeAffinity.create = function create(properties) {
                            return new VolumeNodeAffinity(properties);
                        };

                        /**
                         * Encodes the specified VolumeNodeAffinity message. Does not implicitly {@link k8s.io.api.core.v1.VolumeNodeAffinity.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeNodeAffinity} message VolumeNodeAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeNodeAffinity.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                                $root.k8s.io.api.core.v1.NodeSelector.encode(message.required, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified VolumeNodeAffinity message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.VolumeNodeAffinity.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeNodeAffinity} message VolumeNodeAffinity message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeNodeAffinity.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VolumeNodeAffinity message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.VolumeNodeAffinity} VolumeNodeAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeNodeAffinity.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.VolumeNodeAffinity();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.required = $root.k8s.io.api.core.v1.NodeSelector.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VolumeNodeAffinity message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.VolumeNodeAffinity} VolumeNodeAffinity
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeNodeAffinity.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VolumeNodeAffinity message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VolumeNodeAffinity.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.required != null && message.hasOwnProperty("required")) {
                                var error = $root.k8s.io.api.core.v1.NodeSelector.verify(message.required);
                                if (error)
                                    return "required." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a VolumeNodeAffinity message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.VolumeNodeAffinity} VolumeNodeAffinity
                         */
                        VolumeNodeAffinity.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.VolumeNodeAffinity)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.VolumeNodeAffinity();
                            if (object.required != null) {
                                if (typeof object.required !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeNodeAffinity.required: object expected");
                                message.required = $root.k8s.io.api.core.v1.NodeSelector.fromObject(object.required);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a VolumeNodeAffinity message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @static
                         * @param {k8s.io.api.core.v1.VolumeNodeAffinity} message VolumeNodeAffinity
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VolumeNodeAffinity.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                object.required = null;
                            if (message.required != null && message.hasOwnProperty("required"))
                                object.required = $root.k8s.io.api.core.v1.NodeSelector.toObject(message.required, options);
                            return object;
                        };

                        /**
                         * Converts this VolumeNodeAffinity to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.VolumeNodeAffinity
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VolumeNodeAffinity.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VolumeNodeAffinity;
                    })();

                    v1.VolumeProjection = (function() {

                        /**
                         * Properties of a VolumeProjection.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVolumeProjection
                         * @property {k8s.io.api.core.v1.ISecretProjection|null} [secret] VolumeProjection secret
                         * @property {k8s.io.api.core.v1.IDownwardAPIProjection|null} [downwardAPI] VolumeProjection downwardAPI
                         * @property {k8s.io.api.core.v1.IConfigMapProjection|null} [configMap] VolumeProjection configMap
                         * @property {k8s.io.api.core.v1.IServiceAccountTokenProjection|null} [serviceAccountToken] VolumeProjection serviceAccountToken
                         */

                        /**
                         * Constructs a new VolumeProjection.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a VolumeProjection.
                         * @implements IVolumeProjection
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVolumeProjection=} [properties] Properties to set
                         */
                        function VolumeProjection(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VolumeProjection secret.
                         * @member {k8s.io.api.core.v1.ISecretProjection|null|undefined} secret
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @instance
                         */
                        VolumeProjection.prototype.secret = null;

                        /**
                         * VolumeProjection downwardAPI.
                         * @member {k8s.io.api.core.v1.IDownwardAPIProjection|null|undefined} downwardAPI
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @instance
                         */
                        VolumeProjection.prototype.downwardAPI = null;

                        /**
                         * VolumeProjection configMap.
                         * @member {k8s.io.api.core.v1.IConfigMapProjection|null|undefined} configMap
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @instance
                         */
                        VolumeProjection.prototype.configMap = null;

                        /**
                         * VolumeProjection serviceAccountToken.
                         * @member {k8s.io.api.core.v1.IServiceAccountTokenProjection|null|undefined} serviceAccountToken
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @instance
                         */
                        VolumeProjection.prototype.serviceAccountToken = null;

                        /**
                         * Creates a new VolumeProjection instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeProjection=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.VolumeProjection} VolumeProjection instance
                         */
                        VolumeProjection.create = function create(properties) {
                            return new VolumeProjection(properties);
                        };

                        /**
                         * Encodes the specified VolumeProjection message. Does not implicitly {@link k8s.io.api.core.v1.VolumeProjection.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeProjection} message VolumeProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeProjection.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.secret != null && Object.hasOwnProperty.call(message, "secret"))
                                $root.k8s.io.api.core.v1.SecretProjection.encode(message.secret, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.downwardAPI != null && Object.hasOwnProperty.call(message, "downwardAPI"))
                                $root.k8s.io.api.core.v1.DownwardAPIProjection.encode(message.downwardAPI, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.configMap != null && Object.hasOwnProperty.call(message, "configMap"))
                                $root.k8s.io.api.core.v1.ConfigMapProjection.encode(message.configMap, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.serviceAccountToken != null && Object.hasOwnProperty.call(message, "serviceAccountToken"))
                                $root.k8s.io.api.core.v1.ServiceAccountTokenProjection.encode(message.serviceAccountToken, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified VolumeProjection message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.VolumeProjection.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeProjection} message VolumeProjection message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeProjection.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VolumeProjection message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.VolumeProjection} VolumeProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeProjection.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.VolumeProjection();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.secret = $root.k8s.io.api.core.v1.SecretProjection.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.downwardAPI = $root.k8s.io.api.core.v1.DownwardAPIProjection.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.configMap = $root.k8s.io.api.core.v1.ConfigMapProjection.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.serviceAccountToken = $root.k8s.io.api.core.v1.ServiceAccountTokenProjection.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VolumeProjection message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.VolumeProjection} VolumeProjection
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeProjection.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VolumeProjection message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VolumeProjection.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.secret != null && message.hasOwnProperty("secret")) {
                                var error = $root.k8s.io.api.core.v1.SecretProjection.verify(message.secret);
                                if (error)
                                    return "secret." + error;
                            }
                            if (message.downwardAPI != null && message.hasOwnProperty("downwardAPI")) {
                                var error = $root.k8s.io.api.core.v1.DownwardAPIProjection.verify(message.downwardAPI);
                                if (error)
                                    return "downwardAPI." + error;
                            }
                            if (message.configMap != null && message.hasOwnProperty("configMap")) {
                                var error = $root.k8s.io.api.core.v1.ConfigMapProjection.verify(message.configMap);
                                if (error)
                                    return "configMap." + error;
                            }
                            if (message.serviceAccountToken != null && message.hasOwnProperty("serviceAccountToken")) {
                                var error = $root.k8s.io.api.core.v1.ServiceAccountTokenProjection.verify(message.serviceAccountToken);
                                if (error)
                                    return "serviceAccountToken." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a VolumeProjection message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.VolumeProjection} VolumeProjection
                         */
                        VolumeProjection.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.VolumeProjection)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.VolumeProjection();
                            if (object.secret != null) {
                                if (typeof object.secret !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeProjection.secret: object expected");
                                message.secret = $root.k8s.io.api.core.v1.SecretProjection.fromObject(object.secret);
                            }
                            if (object.downwardAPI != null) {
                                if (typeof object.downwardAPI !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeProjection.downwardAPI: object expected");
                                message.downwardAPI = $root.k8s.io.api.core.v1.DownwardAPIProjection.fromObject(object.downwardAPI);
                            }
                            if (object.configMap != null) {
                                if (typeof object.configMap !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeProjection.configMap: object expected");
                                message.configMap = $root.k8s.io.api.core.v1.ConfigMapProjection.fromObject(object.configMap);
                            }
                            if (object.serviceAccountToken != null) {
                                if (typeof object.serviceAccountToken !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeProjection.serviceAccountToken: object expected");
                                message.serviceAccountToken = $root.k8s.io.api.core.v1.ServiceAccountTokenProjection.fromObject(object.serviceAccountToken);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a VolumeProjection message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @static
                         * @param {k8s.io.api.core.v1.VolumeProjection} message VolumeProjection
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VolumeProjection.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.secret = null;
                                object.downwardAPI = null;
                                object.configMap = null;
                                object.serviceAccountToken = null;
                            }
                            if (message.secret != null && message.hasOwnProperty("secret"))
                                object.secret = $root.k8s.io.api.core.v1.SecretProjection.toObject(message.secret, options);
                            if (message.downwardAPI != null && message.hasOwnProperty("downwardAPI"))
                                object.downwardAPI = $root.k8s.io.api.core.v1.DownwardAPIProjection.toObject(message.downwardAPI, options);
                            if (message.configMap != null && message.hasOwnProperty("configMap"))
                                object.configMap = $root.k8s.io.api.core.v1.ConfigMapProjection.toObject(message.configMap, options);
                            if (message.serviceAccountToken != null && message.hasOwnProperty("serviceAccountToken"))
                                object.serviceAccountToken = $root.k8s.io.api.core.v1.ServiceAccountTokenProjection.toObject(message.serviceAccountToken, options);
                            return object;
                        };

                        /**
                         * Converts this VolumeProjection to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.VolumeProjection
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VolumeProjection.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VolumeProjection;
                    })();

                    v1.VolumeSource = (function() {

                        /**
                         * Properties of a VolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVolumeSource
                         * @property {k8s.io.api.core.v1.IHostPathVolumeSource|null} [hostPath] VolumeSource hostPath
                         * @property {k8s.io.api.core.v1.IEmptyDirVolumeSource|null} [emptyDir] VolumeSource emptyDir
                         * @property {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource|null} [gcePersistentDisk] VolumeSource gcePersistentDisk
                         * @property {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource|null} [awsElasticBlockStore] VolumeSource awsElasticBlockStore
                         * @property {k8s.io.api.core.v1.IGitRepoVolumeSource|null} [gitRepo] VolumeSource gitRepo
                         * @property {k8s.io.api.core.v1.ISecretVolumeSource|null} [secret] VolumeSource secret
                         * @property {k8s.io.api.core.v1.INFSVolumeSource|null} [nfs] VolumeSource nfs
                         * @property {k8s.io.api.core.v1.IISCSIVolumeSource|null} [iscsi] VolumeSource iscsi
                         * @property {k8s.io.api.core.v1.IGlusterfsVolumeSource|null} [glusterfs] VolumeSource glusterfs
                         * @property {k8s.io.api.core.v1.IPersistentVolumeClaimVolumeSource|null} [persistentVolumeClaim] VolumeSource persistentVolumeClaim
                         * @property {k8s.io.api.core.v1.IRBDVolumeSource|null} [rbd] VolumeSource rbd
                         * @property {k8s.io.api.core.v1.IFlexVolumeSource|null} [flexVolume] VolumeSource flexVolume
                         * @property {k8s.io.api.core.v1.ICinderVolumeSource|null} [cinder] VolumeSource cinder
                         * @property {k8s.io.api.core.v1.ICephFSVolumeSource|null} [cephfs] VolumeSource cephfs
                         * @property {k8s.io.api.core.v1.IFlockerVolumeSource|null} [flocker] VolumeSource flocker
                         * @property {k8s.io.api.core.v1.IDownwardAPIVolumeSource|null} [downwardAPI] VolumeSource downwardAPI
                         * @property {k8s.io.api.core.v1.IFCVolumeSource|null} [fc] VolumeSource fc
                         * @property {k8s.io.api.core.v1.IAzureFileVolumeSource|null} [azureFile] VolumeSource azureFile
                         * @property {k8s.io.api.core.v1.IConfigMapVolumeSource|null} [configMap] VolumeSource configMap
                         * @property {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource|null} [vsphereVolume] VolumeSource vsphereVolume
                         * @property {k8s.io.api.core.v1.IQuobyteVolumeSource|null} [quobyte] VolumeSource quobyte
                         * @property {k8s.io.api.core.v1.IAzureDiskVolumeSource|null} [azureDisk] VolumeSource azureDisk
                         * @property {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource|null} [photonPersistentDisk] VolumeSource photonPersistentDisk
                         * @property {k8s.io.api.core.v1.IProjectedVolumeSource|null} [projected] VolumeSource projected
                         * @property {k8s.io.api.core.v1.IPortworxVolumeSource|null} [portworxVolume] VolumeSource portworxVolume
                         * @property {k8s.io.api.core.v1.IScaleIOVolumeSource|null} [scaleIO] VolumeSource scaleIO
                         * @property {k8s.io.api.core.v1.IStorageOSVolumeSource|null} [storageos] VolumeSource storageos
                         * @property {k8s.io.api.core.v1.ICSIVolumeSource|null} [csi] VolumeSource csi
                         * @property {k8s.io.api.core.v1.IEphemeralVolumeSource|null} [ephemeral] VolumeSource ephemeral
                         */

                        /**
                         * Constructs a new VolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a VolumeSource.
                         * @implements IVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVolumeSource=} [properties] Properties to set
                         */
                        function VolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VolumeSource hostPath.
                         * @member {k8s.io.api.core.v1.IHostPathVolumeSource|null|undefined} hostPath
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.hostPath = null;

                        /**
                         * VolumeSource emptyDir.
                         * @member {k8s.io.api.core.v1.IEmptyDirVolumeSource|null|undefined} emptyDir
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.emptyDir = null;

                        /**
                         * VolumeSource gcePersistentDisk.
                         * @member {k8s.io.api.core.v1.IGCEPersistentDiskVolumeSource|null|undefined} gcePersistentDisk
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.gcePersistentDisk = null;

                        /**
                         * VolumeSource awsElasticBlockStore.
                         * @member {k8s.io.api.core.v1.IAWSElasticBlockStoreVolumeSource|null|undefined} awsElasticBlockStore
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.awsElasticBlockStore = null;

                        /**
                         * VolumeSource gitRepo.
                         * @member {k8s.io.api.core.v1.IGitRepoVolumeSource|null|undefined} gitRepo
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.gitRepo = null;

                        /**
                         * VolumeSource secret.
                         * @member {k8s.io.api.core.v1.ISecretVolumeSource|null|undefined} secret
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.secret = null;

                        /**
                         * VolumeSource nfs.
                         * @member {k8s.io.api.core.v1.INFSVolumeSource|null|undefined} nfs
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.nfs = null;

                        /**
                         * VolumeSource iscsi.
                         * @member {k8s.io.api.core.v1.IISCSIVolumeSource|null|undefined} iscsi
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.iscsi = null;

                        /**
                         * VolumeSource glusterfs.
                         * @member {k8s.io.api.core.v1.IGlusterfsVolumeSource|null|undefined} glusterfs
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.glusterfs = null;

                        /**
                         * VolumeSource persistentVolumeClaim.
                         * @member {k8s.io.api.core.v1.IPersistentVolumeClaimVolumeSource|null|undefined} persistentVolumeClaim
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.persistentVolumeClaim = null;

                        /**
                         * VolumeSource rbd.
                         * @member {k8s.io.api.core.v1.IRBDVolumeSource|null|undefined} rbd
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.rbd = null;

                        /**
                         * VolumeSource flexVolume.
                         * @member {k8s.io.api.core.v1.IFlexVolumeSource|null|undefined} flexVolume
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.flexVolume = null;

                        /**
                         * VolumeSource cinder.
                         * @member {k8s.io.api.core.v1.ICinderVolumeSource|null|undefined} cinder
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.cinder = null;

                        /**
                         * VolumeSource cephfs.
                         * @member {k8s.io.api.core.v1.ICephFSVolumeSource|null|undefined} cephfs
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.cephfs = null;

                        /**
                         * VolumeSource flocker.
                         * @member {k8s.io.api.core.v1.IFlockerVolumeSource|null|undefined} flocker
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.flocker = null;

                        /**
                         * VolumeSource downwardAPI.
                         * @member {k8s.io.api.core.v1.IDownwardAPIVolumeSource|null|undefined} downwardAPI
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.downwardAPI = null;

                        /**
                         * VolumeSource fc.
                         * @member {k8s.io.api.core.v1.IFCVolumeSource|null|undefined} fc
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.fc = null;

                        /**
                         * VolumeSource azureFile.
                         * @member {k8s.io.api.core.v1.IAzureFileVolumeSource|null|undefined} azureFile
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.azureFile = null;

                        /**
                         * VolumeSource configMap.
                         * @member {k8s.io.api.core.v1.IConfigMapVolumeSource|null|undefined} configMap
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.configMap = null;

                        /**
                         * VolumeSource vsphereVolume.
                         * @member {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource|null|undefined} vsphereVolume
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.vsphereVolume = null;

                        /**
                         * VolumeSource quobyte.
                         * @member {k8s.io.api.core.v1.IQuobyteVolumeSource|null|undefined} quobyte
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.quobyte = null;

                        /**
                         * VolumeSource azureDisk.
                         * @member {k8s.io.api.core.v1.IAzureDiskVolumeSource|null|undefined} azureDisk
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.azureDisk = null;

                        /**
                         * VolumeSource photonPersistentDisk.
                         * @member {k8s.io.api.core.v1.IPhotonPersistentDiskVolumeSource|null|undefined} photonPersistentDisk
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.photonPersistentDisk = null;

                        /**
                         * VolumeSource projected.
                         * @member {k8s.io.api.core.v1.IProjectedVolumeSource|null|undefined} projected
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.projected = null;

                        /**
                         * VolumeSource portworxVolume.
                         * @member {k8s.io.api.core.v1.IPortworxVolumeSource|null|undefined} portworxVolume
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.portworxVolume = null;

                        /**
                         * VolumeSource scaleIO.
                         * @member {k8s.io.api.core.v1.IScaleIOVolumeSource|null|undefined} scaleIO
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.scaleIO = null;

                        /**
                         * VolumeSource storageos.
                         * @member {k8s.io.api.core.v1.IStorageOSVolumeSource|null|undefined} storageos
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.storageos = null;

                        /**
                         * VolumeSource csi.
                         * @member {k8s.io.api.core.v1.ICSIVolumeSource|null|undefined} csi
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.csi = null;

                        /**
                         * VolumeSource ephemeral.
                         * @member {k8s.io.api.core.v1.IEphemeralVolumeSource|null|undefined} ephemeral
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         */
                        VolumeSource.prototype.ephemeral = null;

                        /**
                         * Creates a new VolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.VolumeSource} VolumeSource instance
                         */
                        VolumeSource.create = function create(properties) {
                            return new VolumeSource(properties);
                        };

                        /**
                         * Encodes the specified VolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.VolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeSource} message VolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.hostPath != null && Object.hasOwnProperty.call(message, "hostPath"))
                                $root.k8s.io.api.core.v1.HostPathVolumeSource.encode(message.hostPath, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.emptyDir != null && Object.hasOwnProperty.call(message, "emptyDir"))
                                $root.k8s.io.api.core.v1.EmptyDirVolumeSource.encode(message.emptyDir, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.gcePersistentDisk != null && Object.hasOwnProperty.call(message, "gcePersistentDisk"))
                                $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.encode(message.gcePersistentDisk, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.awsElasticBlockStore != null && Object.hasOwnProperty.call(message, "awsElasticBlockStore"))
                                $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.encode(message.awsElasticBlockStore, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.gitRepo != null && Object.hasOwnProperty.call(message, "gitRepo"))
                                $root.k8s.io.api.core.v1.GitRepoVolumeSource.encode(message.gitRepo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            if (message.secret != null && Object.hasOwnProperty.call(message, "secret"))
                                $root.k8s.io.api.core.v1.SecretVolumeSource.encode(message.secret, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                            if (message.nfs != null && Object.hasOwnProperty.call(message, "nfs"))
                                $root.k8s.io.api.core.v1.NFSVolumeSource.encode(message.nfs, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.iscsi != null && Object.hasOwnProperty.call(message, "iscsi"))
                                $root.k8s.io.api.core.v1.ISCSIVolumeSource.encode(message.iscsi, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.glusterfs != null && Object.hasOwnProperty.call(message, "glusterfs"))
                                $root.k8s.io.api.core.v1.GlusterfsVolumeSource.encode(message.glusterfs, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.persistentVolumeClaim != null && Object.hasOwnProperty.call(message, "persistentVolumeClaim"))
                                $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.encode(message.persistentVolumeClaim, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.rbd != null && Object.hasOwnProperty.call(message, "rbd"))
                                $root.k8s.io.api.core.v1.RBDVolumeSource.encode(message.rbd, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.flexVolume != null && Object.hasOwnProperty.call(message, "flexVolume"))
                                $root.k8s.io.api.core.v1.FlexVolumeSource.encode(message.flexVolume, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.cinder != null && Object.hasOwnProperty.call(message, "cinder"))
                                $root.k8s.io.api.core.v1.CinderVolumeSource.encode(message.cinder, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.cephfs != null && Object.hasOwnProperty.call(message, "cephfs"))
                                $root.k8s.io.api.core.v1.CephFSVolumeSource.encode(message.cephfs, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.flocker != null && Object.hasOwnProperty.call(message, "flocker"))
                                $root.k8s.io.api.core.v1.FlockerVolumeSource.encode(message.flocker, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.downwardAPI != null && Object.hasOwnProperty.call(message, "downwardAPI"))
                                $root.k8s.io.api.core.v1.DownwardAPIVolumeSource.encode(message.downwardAPI, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                            if (message.fc != null && Object.hasOwnProperty.call(message, "fc"))
                                $root.k8s.io.api.core.v1.FCVolumeSource.encode(message.fc, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                            if (message.azureFile != null && Object.hasOwnProperty.call(message, "azureFile"))
                                $root.k8s.io.api.core.v1.AzureFileVolumeSource.encode(message.azureFile, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.configMap != null && Object.hasOwnProperty.call(message, "configMap"))
                                $root.k8s.io.api.core.v1.ConfigMapVolumeSource.encode(message.configMap, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.vsphereVolume != null && Object.hasOwnProperty.call(message, "vsphereVolume"))
                                $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.encode(message.vsphereVolume, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.quobyte != null && Object.hasOwnProperty.call(message, "quobyte"))
                                $root.k8s.io.api.core.v1.QuobyteVolumeSource.encode(message.quobyte, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.azureDisk != null && Object.hasOwnProperty.call(message, "azureDisk"))
                                $root.k8s.io.api.core.v1.AzureDiskVolumeSource.encode(message.azureDisk, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            if (message.photonPersistentDisk != null && Object.hasOwnProperty.call(message, "photonPersistentDisk"))
                                $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.encode(message.photonPersistentDisk, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                            if (message.portworxVolume != null && Object.hasOwnProperty.call(message, "portworxVolume"))
                                $root.k8s.io.api.core.v1.PortworxVolumeSource.encode(message.portworxVolume, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                            if (message.scaleIO != null && Object.hasOwnProperty.call(message, "scaleIO"))
                                $root.k8s.io.api.core.v1.ScaleIOVolumeSource.encode(message.scaleIO, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                            if (message.projected != null && Object.hasOwnProperty.call(message, "projected"))
                                $root.k8s.io.api.core.v1.ProjectedVolumeSource.encode(message.projected, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                            if (message.storageos != null && Object.hasOwnProperty.call(message, "storageos"))
                                $root.k8s.io.api.core.v1.StorageOSVolumeSource.encode(message.storageos, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                            if (message.csi != null && Object.hasOwnProperty.call(message, "csi"))
                                $root.k8s.io.api.core.v1.CSIVolumeSource.encode(message.csi, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                            if (message.ephemeral != null && Object.hasOwnProperty.call(message, "ephemeral"))
                                $root.k8s.io.api.core.v1.EphemeralVolumeSource.encode(message.ephemeral, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified VolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.VolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IVolumeSource} message VolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.VolumeSource} VolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.VolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.hostPath = $root.k8s.io.api.core.v1.HostPathVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.emptyDir = $root.k8s.io.api.core.v1.EmptyDirVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.gcePersistentDisk = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.awsElasticBlockStore = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.gitRepo = $root.k8s.io.api.core.v1.GitRepoVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 6:
                                    message.secret = $root.k8s.io.api.core.v1.SecretVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 7:
                                    message.nfs = $root.k8s.io.api.core.v1.NFSVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.iscsi = $root.k8s.io.api.core.v1.ISCSIVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.glusterfs = $root.k8s.io.api.core.v1.GlusterfsVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.persistentVolumeClaim = $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.rbd = $root.k8s.io.api.core.v1.RBDVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.flexVolume = $root.k8s.io.api.core.v1.FlexVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 13:
                                    message.cinder = $root.k8s.io.api.core.v1.CinderVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.cephfs = $root.k8s.io.api.core.v1.CephFSVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    message.flocker = $root.k8s.io.api.core.v1.FlockerVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.downwardAPI = $root.k8s.io.api.core.v1.DownwardAPIVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 17:
                                    message.fc = $root.k8s.io.api.core.v1.FCVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 18:
                                    message.azureFile = $root.k8s.io.api.core.v1.AzureFileVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.configMap = $root.k8s.io.api.core.v1.ConfigMapVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 20:
                                    message.vsphereVolume = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 21:
                                    message.quobyte = $root.k8s.io.api.core.v1.QuobyteVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.azureDisk = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 23:
                                    message.photonPersistentDisk = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 26:
                                    message.projected = $root.k8s.io.api.core.v1.ProjectedVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 24:
                                    message.portworxVolume = $root.k8s.io.api.core.v1.PortworxVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 25:
                                    message.scaleIO = $root.k8s.io.api.core.v1.ScaleIOVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 27:
                                    message.storageos = $root.k8s.io.api.core.v1.StorageOSVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 28:
                                    message.csi = $root.k8s.io.api.core.v1.CSIVolumeSource.decode(reader, reader.uint32());
                                    break;
                                case 29:
                                    message.ephemeral = $root.k8s.io.api.core.v1.EphemeralVolumeSource.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.VolumeSource} VolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.hostPath != null && message.hasOwnProperty("hostPath")) {
                                var error = $root.k8s.io.api.core.v1.HostPathVolumeSource.verify(message.hostPath);
                                if (error)
                                    return "hostPath." + error;
                            }
                            if (message.emptyDir != null && message.hasOwnProperty("emptyDir")) {
                                var error = $root.k8s.io.api.core.v1.EmptyDirVolumeSource.verify(message.emptyDir);
                                if (error)
                                    return "emptyDir." + error;
                            }
                            if (message.gcePersistentDisk != null && message.hasOwnProperty("gcePersistentDisk")) {
                                var error = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.verify(message.gcePersistentDisk);
                                if (error)
                                    return "gcePersistentDisk." + error;
                            }
                            if (message.awsElasticBlockStore != null && message.hasOwnProperty("awsElasticBlockStore")) {
                                var error = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.verify(message.awsElasticBlockStore);
                                if (error)
                                    return "awsElasticBlockStore." + error;
                            }
                            if (message.gitRepo != null && message.hasOwnProperty("gitRepo")) {
                                var error = $root.k8s.io.api.core.v1.GitRepoVolumeSource.verify(message.gitRepo);
                                if (error)
                                    return "gitRepo." + error;
                            }
                            if (message.secret != null && message.hasOwnProperty("secret")) {
                                var error = $root.k8s.io.api.core.v1.SecretVolumeSource.verify(message.secret);
                                if (error)
                                    return "secret." + error;
                            }
                            if (message.nfs != null && message.hasOwnProperty("nfs")) {
                                var error = $root.k8s.io.api.core.v1.NFSVolumeSource.verify(message.nfs);
                                if (error)
                                    return "nfs." + error;
                            }
                            if (message.iscsi != null && message.hasOwnProperty("iscsi")) {
                                var error = $root.k8s.io.api.core.v1.ISCSIVolumeSource.verify(message.iscsi);
                                if (error)
                                    return "iscsi." + error;
                            }
                            if (message.glusterfs != null && message.hasOwnProperty("glusterfs")) {
                                var error = $root.k8s.io.api.core.v1.GlusterfsVolumeSource.verify(message.glusterfs);
                                if (error)
                                    return "glusterfs." + error;
                            }
                            if (message.persistentVolumeClaim != null && message.hasOwnProperty("persistentVolumeClaim")) {
                                var error = $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.verify(message.persistentVolumeClaim);
                                if (error)
                                    return "persistentVolumeClaim." + error;
                            }
                            if (message.rbd != null && message.hasOwnProperty("rbd")) {
                                var error = $root.k8s.io.api.core.v1.RBDVolumeSource.verify(message.rbd);
                                if (error)
                                    return "rbd." + error;
                            }
                            if (message.flexVolume != null && message.hasOwnProperty("flexVolume")) {
                                var error = $root.k8s.io.api.core.v1.FlexVolumeSource.verify(message.flexVolume);
                                if (error)
                                    return "flexVolume." + error;
                            }
                            if (message.cinder != null && message.hasOwnProperty("cinder")) {
                                var error = $root.k8s.io.api.core.v1.CinderVolumeSource.verify(message.cinder);
                                if (error)
                                    return "cinder." + error;
                            }
                            if (message.cephfs != null && message.hasOwnProperty("cephfs")) {
                                var error = $root.k8s.io.api.core.v1.CephFSVolumeSource.verify(message.cephfs);
                                if (error)
                                    return "cephfs." + error;
                            }
                            if (message.flocker != null && message.hasOwnProperty("flocker")) {
                                var error = $root.k8s.io.api.core.v1.FlockerVolumeSource.verify(message.flocker);
                                if (error)
                                    return "flocker." + error;
                            }
                            if (message.downwardAPI != null && message.hasOwnProperty("downwardAPI")) {
                                var error = $root.k8s.io.api.core.v1.DownwardAPIVolumeSource.verify(message.downwardAPI);
                                if (error)
                                    return "downwardAPI." + error;
                            }
                            if (message.fc != null && message.hasOwnProperty("fc")) {
                                var error = $root.k8s.io.api.core.v1.FCVolumeSource.verify(message.fc);
                                if (error)
                                    return "fc." + error;
                            }
                            if (message.azureFile != null && message.hasOwnProperty("azureFile")) {
                                var error = $root.k8s.io.api.core.v1.AzureFileVolumeSource.verify(message.azureFile);
                                if (error)
                                    return "azureFile." + error;
                            }
                            if (message.configMap != null && message.hasOwnProperty("configMap")) {
                                var error = $root.k8s.io.api.core.v1.ConfigMapVolumeSource.verify(message.configMap);
                                if (error)
                                    return "configMap." + error;
                            }
                            if (message.vsphereVolume != null && message.hasOwnProperty("vsphereVolume")) {
                                var error = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.verify(message.vsphereVolume);
                                if (error)
                                    return "vsphereVolume." + error;
                            }
                            if (message.quobyte != null && message.hasOwnProperty("quobyte")) {
                                var error = $root.k8s.io.api.core.v1.QuobyteVolumeSource.verify(message.quobyte);
                                if (error)
                                    return "quobyte." + error;
                            }
                            if (message.azureDisk != null && message.hasOwnProperty("azureDisk")) {
                                var error = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.verify(message.azureDisk);
                                if (error)
                                    return "azureDisk." + error;
                            }
                            if (message.photonPersistentDisk != null && message.hasOwnProperty("photonPersistentDisk")) {
                                var error = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.verify(message.photonPersistentDisk);
                                if (error)
                                    return "photonPersistentDisk." + error;
                            }
                            if (message.projected != null && message.hasOwnProperty("projected")) {
                                var error = $root.k8s.io.api.core.v1.ProjectedVolumeSource.verify(message.projected);
                                if (error)
                                    return "projected." + error;
                            }
                            if (message.portworxVolume != null && message.hasOwnProperty("portworxVolume")) {
                                var error = $root.k8s.io.api.core.v1.PortworxVolumeSource.verify(message.portworxVolume);
                                if (error)
                                    return "portworxVolume." + error;
                            }
                            if (message.scaleIO != null && message.hasOwnProperty("scaleIO")) {
                                var error = $root.k8s.io.api.core.v1.ScaleIOVolumeSource.verify(message.scaleIO);
                                if (error)
                                    return "scaleIO." + error;
                            }
                            if (message.storageos != null && message.hasOwnProperty("storageos")) {
                                var error = $root.k8s.io.api.core.v1.StorageOSVolumeSource.verify(message.storageos);
                                if (error)
                                    return "storageos." + error;
                            }
                            if (message.csi != null && message.hasOwnProperty("csi")) {
                                var error = $root.k8s.io.api.core.v1.CSIVolumeSource.verify(message.csi);
                                if (error)
                                    return "csi." + error;
                            }
                            if (message.ephemeral != null && message.hasOwnProperty("ephemeral")) {
                                var error = $root.k8s.io.api.core.v1.EphemeralVolumeSource.verify(message.ephemeral);
                                if (error)
                                    return "ephemeral." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a VolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.VolumeSource} VolumeSource
                         */
                        VolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.VolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.VolumeSource();
                            if (object.hostPath != null) {
                                if (typeof object.hostPath !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.hostPath: object expected");
                                message.hostPath = $root.k8s.io.api.core.v1.HostPathVolumeSource.fromObject(object.hostPath);
                            }
                            if (object.emptyDir != null) {
                                if (typeof object.emptyDir !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.emptyDir: object expected");
                                message.emptyDir = $root.k8s.io.api.core.v1.EmptyDirVolumeSource.fromObject(object.emptyDir);
                            }
                            if (object.gcePersistentDisk != null) {
                                if (typeof object.gcePersistentDisk !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.gcePersistentDisk: object expected");
                                message.gcePersistentDisk = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.fromObject(object.gcePersistentDisk);
                            }
                            if (object.awsElasticBlockStore != null) {
                                if (typeof object.awsElasticBlockStore !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.awsElasticBlockStore: object expected");
                                message.awsElasticBlockStore = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.fromObject(object.awsElasticBlockStore);
                            }
                            if (object.gitRepo != null) {
                                if (typeof object.gitRepo !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.gitRepo: object expected");
                                message.gitRepo = $root.k8s.io.api.core.v1.GitRepoVolumeSource.fromObject(object.gitRepo);
                            }
                            if (object.secret != null) {
                                if (typeof object.secret !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.secret: object expected");
                                message.secret = $root.k8s.io.api.core.v1.SecretVolumeSource.fromObject(object.secret);
                            }
                            if (object.nfs != null) {
                                if (typeof object.nfs !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.nfs: object expected");
                                message.nfs = $root.k8s.io.api.core.v1.NFSVolumeSource.fromObject(object.nfs);
                            }
                            if (object.iscsi != null) {
                                if (typeof object.iscsi !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.iscsi: object expected");
                                message.iscsi = $root.k8s.io.api.core.v1.ISCSIVolumeSource.fromObject(object.iscsi);
                            }
                            if (object.glusterfs != null) {
                                if (typeof object.glusterfs !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.glusterfs: object expected");
                                message.glusterfs = $root.k8s.io.api.core.v1.GlusterfsVolumeSource.fromObject(object.glusterfs);
                            }
                            if (object.persistentVolumeClaim != null) {
                                if (typeof object.persistentVolumeClaim !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.persistentVolumeClaim: object expected");
                                message.persistentVolumeClaim = $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.fromObject(object.persistentVolumeClaim);
                            }
                            if (object.rbd != null) {
                                if (typeof object.rbd !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.rbd: object expected");
                                message.rbd = $root.k8s.io.api.core.v1.RBDVolumeSource.fromObject(object.rbd);
                            }
                            if (object.flexVolume != null) {
                                if (typeof object.flexVolume !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.flexVolume: object expected");
                                message.flexVolume = $root.k8s.io.api.core.v1.FlexVolumeSource.fromObject(object.flexVolume);
                            }
                            if (object.cinder != null) {
                                if (typeof object.cinder !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.cinder: object expected");
                                message.cinder = $root.k8s.io.api.core.v1.CinderVolumeSource.fromObject(object.cinder);
                            }
                            if (object.cephfs != null) {
                                if (typeof object.cephfs !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.cephfs: object expected");
                                message.cephfs = $root.k8s.io.api.core.v1.CephFSVolumeSource.fromObject(object.cephfs);
                            }
                            if (object.flocker != null) {
                                if (typeof object.flocker !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.flocker: object expected");
                                message.flocker = $root.k8s.io.api.core.v1.FlockerVolumeSource.fromObject(object.flocker);
                            }
                            if (object.downwardAPI != null) {
                                if (typeof object.downwardAPI !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.downwardAPI: object expected");
                                message.downwardAPI = $root.k8s.io.api.core.v1.DownwardAPIVolumeSource.fromObject(object.downwardAPI);
                            }
                            if (object.fc != null) {
                                if (typeof object.fc !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.fc: object expected");
                                message.fc = $root.k8s.io.api.core.v1.FCVolumeSource.fromObject(object.fc);
                            }
                            if (object.azureFile != null) {
                                if (typeof object.azureFile !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.azureFile: object expected");
                                message.azureFile = $root.k8s.io.api.core.v1.AzureFileVolumeSource.fromObject(object.azureFile);
                            }
                            if (object.configMap != null) {
                                if (typeof object.configMap !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.configMap: object expected");
                                message.configMap = $root.k8s.io.api.core.v1.ConfigMapVolumeSource.fromObject(object.configMap);
                            }
                            if (object.vsphereVolume != null) {
                                if (typeof object.vsphereVolume !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.vsphereVolume: object expected");
                                message.vsphereVolume = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.fromObject(object.vsphereVolume);
                            }
                            if (object.quobyte != null) {
                                if (typeof object.quobyte !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.quobyte: object expected");
                                message.quobyte = $root.k8s.io.api.core.v1.QuobyteVolumeSource.fromObject(object.quobyte);
                            }
                            if (object.azureDisk != null) {
                                if (typeof object.azureDisk !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.azureDisk: object expected");
                                message.azureDisk = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.fromObject(object.azureDisk);
                            }
                            if (object.photonPersistentDisk != null) {
                                if (typeof object.photonPersistentDisk !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.photonPersistentDisk: object expected");
                                message.photonPersistentDisk = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.fromObject(object.photonPersistentDisk);
                            }
                            if (object.projected != null) {
                                if (typeof object.projected !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.projected: object expected");
                                message.projected = $root.k8s.io.api.core.v1.ProjectedVolumeSource.fromObject(object.projected);
                            }
                            if (object.portworxVolume != null) {
                                if (typeof object.portworxVolume !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.portworxVolume: object expected");
                                message.portworxVolume = $root.k8s.io.api.core.v1.PortworxVolumeSource.fromObject(object.portworxVolume);
                            }
                            if (object.scaleIO != null) {
                                if (typeof object.scaleIO !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.scaleIO: object expected");
                                message.scaleIO = $root.k8s.io.api.core.v1.ScaleIOVolumeSource.fromObject(object.scaleIO);
                            }
                            if (object.storageos != null) {
                                if (typeof object.storageos !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.storageos: object expected");
                                message.storageos = $root.k8s.io.api.core.v1.StorageOSVolumeSource.fromObject(object.storageos);
                            }
                            if (object.csi != null) {
                                if (typeof object.csi !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.csi: object expected");
                                message.csi = $root.k8s.io.api.core.v1.CSIVolumeSource.fromObject(object.csi);
                            }
                            if (object.ephemeral != null) {
                                if (typeof object.ephemeral !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.VolumeSource.ephemeral: object expected");
                                message.ephemeral = $root.k8s.io.api.core.v1.EphemeralVolumeSource.fromObject(object.ephemeral);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a VolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.VolumeSource} message VolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.hostPath = null;
                                object.emptyDir = null;
                                object.gcePersistentDisk = null;
                                object.awsElasticBlockStore = null;
                                object.gitRepo = null;
                                object.secret = null;
                                object.nfs = null;
                                object.iscsi = null;
                                object.glusterfs = null;
                                object.persistentVolumeClaim = null;
                                object.rbd = null;
                                object.flexVolume = null;
                                object.cinder = null;
                                object.cephfs = null;
                                object.flocker = null;
                                object.downwardAPI = null;
                                object.fc = null;
                                object.azureFile = null;
                                object.configMap = null;
                                object.vsphereVolume = null;
                                object.quobyte = null;
                                object.azureDisk = null;
                                object.photonPersistentDisk = null;
                                object.portworxVolume = null;
                                object.scaleIO = null;
                                object.projected = null;
                                object.storageos = null;
                                object.csi = null;
                                object.ephemeral = null;
                            }
                            if (message.hostPath != null && message.hasOwnProperty("hostPath"))
                                object.hostPath = $root.k8s.io.api.core.v1.HostPathVolumeSource.toObject(message.hostPath, options);
                            if (message.emptyDir != null && message.hasOwnProperty("emptyDir"))
                                object.emptyDir = $root.k8s.io.api.core.v1.EmptyDirVolumeSource.toObject(message.emptyDir, options);
                            if (message.gcePersistentDisk != null && message.hasOwnProperty("gcePersistentDisk"))
                                object.gcePersistentDisk = $root.k8s.io.api.core.v1.GCEPersistentDiskVolumeSource.toObject(message.gcePersistentDisk, options);
                            if (message.awsElasticBlockStore != null && message.hasOwnProperty("awsElasticBlockStore"))
                                object.awsElasticBlockStore = $root.k8s.io.api.core.v1.AWSElasticBlockStoreVolumeSource.toObject(message.awsElasticBlockStore, options);
                            if (message.gitRepo != null && message.hasOwnProperty("gitRepo"))
                                object.gitRepo = $root.k8s.io.api.core.v1.GitRepoVolumeSource.toObject(message.gitRepo, options);
                            if (message.secret != null && message.hasOwnProperty("secret"))
                                object.secret = $root.k8s.io.api.core.v1.SecretVolumeSource.toObject(message.secret, options);
                            if (message.nfs != null && message.hasOwnProperty("nfs"))
                                object.nfs = $root.k8s.io.api.core.v1.NFSVolumeSource.toObject(message.nfs, options);
                            if (message.iscsi != null && message.hasOwnProperty("iscsi"))
                                object.iscsi = $root.k8s.io.api.core.v1.ISCSIVolumeSource.toObject(message.iscsi, options);
                            if (message.glusterfs != null && message.hasOwnProperty("glusterfs"))
                                object.glusterfs = $root.k8s.io.api.core.v1.GlusterfsVolumeSource.toObject(message.glusterfs, options);
                            if (message.persistentVolumeClaim != null && message.hasOwnProperty("persistentVolumeClaim"))
                                object.persistentVolumeClaim = $root.k8s.io.api.core.v1.PersistentVolumeClaimVolumeSource.toObject(message.persistentVolumeClaim, options);
                            if (message.rbd != null && message.hasOwnProperty("rbd"))
                                object.rbd = $root.k8s.io.api.core.v1.RBDVolumeSource.toObject(message.rbd, options);
                            if (message.flexVolume != null && message.hasOwnProperty("flexVolume"))
                                object.flexVolume = $root.k8s.io.api.core.v1.FlexVolumeSource.toObject(message.flexVolume, options);
                            if (message.cinder != null && message.hasOwnProperty("cinder"))
                                object.cinder = $root.k8s.io.api.core.v1.CinderVolumeSource.toObject(message.cinder, options);
                            if (message.cephfs != null && message.hasOwnProperty("cephfs"))
                                object.cephfs = $root.k8s.io.api.core.v1.CephFSVolumeSource.toObject(message.cephfs, options);
                            if (message.flocker != null && message.hasOwnProperty("flocker"))
                                object.flocker = $root.k8s.io.api.core.v1.FlockerVolumeSource.toObject(message.flocker, options);
                            if (message.downwardAPI != null && message.hasOwnProperty("downwardAPI"))
                                object.downwardAPI = $root.k8s.io.api.core.v1.DownwardAPIVolumeSource.toObject(message.downwardAPI, options);
                            if (message.fc != null && message.hasOwnProperty("fc"))
                                object.fc = $root.k8s.io.api.core.v1.FCVolumeSource.toObject(message.fc, options);
                            if (message.azureFile != null && message.hasOwnProperty("azureFile"))
                                object.azureFile = $root.k8s.io.api.core.v1.AzureFileVolumeSource.toObject(message.azureFile, options);
                            if (message.configMap != null && message.hasOwnProperty("configMap"))
                                object.configMap = $root.k8s.io.api.core.v1.ConfigMapVolumeSource.toObject(message.configMap, options);
                            if (message.vsphereVolume != null && message.hasOwnProperty("vsphereVolume"))
                                object.vsphereVolume = $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.toObject(message.vsphereVolume, options);
                            if (message.quobyte != null && message.hasOwnProperty("quobyte"))
                                object.quobyte = $root.k8s.io.api.core.v1.QuobyteVolumeSource.toObject(message.quobyte, options);
                            if (message.azureDisk != null && message.hasOwnProperty("azureDisk"))
                                object.azureDisk = $root.k8s.io.api.core.v1.AzureDiskVolumeSource.toObject(message.azureDisk, options);
                            if (message.photonPersistentDisk != null && message.hasOwnProperty("photonPersistentDisk"))
                                object.photonPersistentDisk = $root.k8s.io.api.core.v1.PhotonPersistentDiskVolumeSource.toObject(message.photonPersistentDisk, options);
                            if (message.portworxVolume != null && message.hasOwnProperty("portworxVolume"))
                                object.portworxVolume = $root.k8s.io.api.core.v1.PortworxVolumeSource.toObject(message.portworxVolume, options);
                            if (message.scaleIO != null && message.hasOwnProperty("scaleIO"))
                                object.scaleIO = $root.k8s.io.api.core.v1.ScaleIOVolumeSource.toObject(message.scaleIO, options);
                            if (message.projected != null && message.hasOwnProperty("projected"))
                                object.projected = $root.k8s.io.api.core.v1.ProjectedVolumeSource.toObject(message.projected, options);
                            if (message.storageos != null && message.hasOwnProperty("storageos"))
                                object.storageos = $root.k8s.io.api.core.v1.StorageOSVolumeSource.toObject(message.storageos, options);
                            if (message.csi != null && message.hasOwnProperty("csi"))
                                object.csi = $root.k8s.io.api.core.v1.CSIVolumeSource.toObject(message.csi, options);
                            if (message.ephemeral != null && message.hasOwnProperty("ephemeral"))
                                object.ephemeral = $root.k8s.io.api.core.v1.EphemeralVolumeSource.toObject(message.ephemeral, options);
                            return object;
                        };

                        /**
                         * Converts this VolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.VolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VolumeSource;
                    })();

                    v1.VsphereVirtualDiskVolumeSource = (function() {

                        /**
                         * Properties of a VsphereVirtualDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @interface IVsphereVirtualDiskVolumeSource
                         * @property {string|null} [volumePath] VsphereVirtualDiskVolumeSource volumePath
                         * @property {string|null} [fsType] VsphereVirtualDiskVolumeSource fsType
                         * @property {string|null} [storagePolicyName] VsphereVirtualDiskVolumeSource storagePolicyName
                         * @property {string|null} [storagePolicyID] VsphereVirtualDiskVolumeSource storagePolicyID
                         */

                        /**
                         * Constructs a new VsphereVirtualDiskVolumeSource.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a VsphereVirtualDiskVolumeSource.
                         * @implements IVsphereVirtualDiskVolumeSource
                         * @constructor
                         * @param {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource=} [properties] Properties to set
                         */
                        function VsphereVirtualDiskVolumeSource(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * VsphereVirtualDiskVolumeSource volumePath.
                         * @member {string} volumePath
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @instance
                         */
                        VsphereVirtualDiskVolumeSource.prototype.volumePath = "";

                        /**
                         * VsphereVirtualDiskVolumeSource fsType.
                         * @member {string} fsType
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @instance
                         */
                        VsphereVirtualDiskVolumeSource.prototype.fsType = "";

                        /**
                         * VsphereVirtualDiskVolumeSource storagePolicyName.
                         * @member {string} storagePolicyName
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @instance
                         */
                        VsphereVirtualDiskVolumeSource.prototype.storagePolicyName = "";

                        /**
                         * VsphereVirtualDiskVolumeSource storagePolicyID.
                         * @member {string} storagePolicyID
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @instance
                         */
                        VsphereVirtualDiskVolumeSource.prototype.storagePolicyID = "";

                        /**
                         * Creates a new VsphereVirtualDiskVolumeSource instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource} VsphereVirtualDiskVolumeSource instance
                         */
                        VsphereVirtualDiskVolumeSource.create = function create(properties) {
                            return new VsphereVirtualDiskVolumeSource(properties);
                        };

                        /**
                         * Encodes the specified VsphereVirtualDiskVolumeSource message. Does not implicitly {@link k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource} message VsphereVirtualDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VsphereVirtualDiskVolumeSource.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.volumePath != null && Object.hasOwnProperty.call(message, "volumePath"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.volumePath);
                            if (message.fsType != null && Object.hasOwnProperty.call(message, "fsType"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fsType);
                            if (message.storagePolicyName != null && Object.hasOwnProperty.call(message, "storagePolicyName"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.storagePolicyName);
                            if (message.storagePolicyID != null && Object.hasOwnProperty.call(message, "storagePolicyID"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.storagePolicyID);
                            return writer;
                        };

                        /**
                         * Encodes the specified VsphereVirtualDiskVolumeSource message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.IVsphereVirtualDiskVolumeSource} message VsphereVirtualDiskVolumeSource message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        VsphereVirtualDiskVolumeSource.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a VsphereVirtualDiskVolumeSource message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource} VsphereVirtualDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VsphereVirtualDiskVolumeSource.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.volumePath = reader.string();
                                    break;
                                case 2:
                                    message.fsType = reader.string();
                                    break;
                                case 3:
                                    message.storagePolicyName = reader.string();
                                    break;
                                case 4:
                                    message.storagePolicyID = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a VsphereVirtualDiskVolumeSource message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource} VsphereVirtualDiskVolumeSource
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        VsphereVirtualDiskVolumeSource.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a VsphereVirtualDiskVolumeSource message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        VsphereVirtualDiskVolumeSource.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.volumePath != null && message.hasOwnProperty("volumePath"))
                                if (!$util.isString(message.volumePath))
                                    return "volumePath: string expected";
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                if (!$util.isString(message.fsType))
                                    return "fsType: string expected";
                            if (message.storagePolicyName != null && message.hasOwnProperty("storagePolicyName"))
                                if (!$util.isString(message.storagePolicyName))
                                    return "storagePolicyName: string expected";
                            if (message.storagePolicyID != null && message.hasOwnProperty("storagePolicyID"))
                                if (!$util.isString(message.storagePolicyID))
                                    return "storagePolicyID: string expected";
                            return null;
                        };

                        /**
                         * Creates a VsphereVirtualDiskVolumeSource message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource} VsphereVirtualDiskVolumeSource
                         */
                        VsphereVirtualDiskVolumeSource.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource();
                            if (object.volumePath != null)
                                message.volumePath = String(object.volumePath);
                            if (object.fsType != null)
                                message.fsType = String(object.fsType);
                            if (object.storagePolicyName != null)
                                message.storagePolicyName = String(object.storagePolicyName);
                            if (object.storagePolicyID != null)
                                message.storagePolicyID = String(object.storagePolicyID);
                            return message;
                        };

                        /**
                         * Creates a plain object from a VsphereVirtualDiskVolumeSource message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @static
                         * @param {k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource} message VsphereVirtualDiskVolumeSource
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        VsphereVirtualDiskVolumeSource.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.volumePath = "";
                                object.fsType = "";
                                object.storagePolicyName = "";
                                object.storagePolicyID = "";
                            }
                            if (message.volumePath != null && message.hasOwnProperty("volumePath"))
                                object.volumePath = message.volumePath;
                            if (message.fsType != null && message.hasOwnProperty("fsType"))
                                object.fsType = message.fsType;
                            if (message.storagePolicyName != null && message.hasOwnProperty("storagePolicyName"))
                                object.storagePolicyName = message.storagePolicyName;
                            if (message.storagePolicyID != null && message.hasOwnProperty("storagePolicyID"))
                                object.storagePolicyID = message.storagePolicyID;
                            return object;
                        };

                        /**
                         * Converts this VsphereVirtualDiskVolumeSource to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.VsphereVirtualDiskVolumeSource
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        VsphereVirtualDiskVolumeSource.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return VsphereVirtualDiskVolumeSource;
                    })();

                    v1.WeightedPodAffinityTerm = (function() {

                        /**
                         * Properties of a WeightedPodAffinityTerm.
                         * @memberof k8s.io.api.core.v1
                         * @interface IWeightedPodAffinityTerm
                         * @property {number|null} [weight] WeightedPodAffinityTerm weight
                         * @property {k8s.io.api.core.v1.IPodAffinityTerm|null} [podAffinityTerm] WeightedPodAffinityTerm podAffinityTerm
                         */

                        /**
                         * Constructs a new WeightedPodAffinityTerm.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a WeightedPodAffinityTerm.
                         * @implements IWeightedPodAffinityTerm
                         * @constructor
                         * @param {k8s.io.api.core.v1.IWeightedPodAffinityTerm=} [properties] Properties to set
                         */
                        function WeightedPodAffinityTerm(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WeightedPodAffinityTerm weight.
                         * @member {number} weight
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @instance
                         */
                        WeightedPodAffinityTerm.prototype.weight = 0;

                        /**
                         * WeightedPodAffinityTerm podAffinityTerm.
                         * @member {k8s.io.api.core.v1.IPodAffinityTerm|null|undefined} podAffinityTerm
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @instance
                         */
                        WeightedPodAffinityTerm.prototype.podAffinityTerm = null;

                        /**
                         * Creates a new WeightedPodAffinityTerm instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IWeightedPodAffinityTerm=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.WeightedPodAffinityTerm} WeightedPodAffinityTerm instance
                         */
                        WeightedPodAffinityTerm.create = function create(properties) {
                            return new WeightedPodAffinityTerm(properties);
                        };

                        /**
                         * Encodes the specified WeightedPodAffinityTerm message. Does not implicitly {@link k8s.io.api.core.v1.WeightedPodAffinityTerm.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IWeightedPodAffinityTerm} message WeightedPodAffinityTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WeightedPodAffinityTerm.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.weight);
                            if (message.podAffinityTerm != null && Object.hasOwnProperty.call(message, "podAffinityTerm"))
                                $root.k8s.io.api.core.v1.PodAffinityTerm.encode(message.podAffinityTerm, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified WeightedPodAffinityTerm message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.WeightedPodAffinityTerm.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.IWeightedPodAffinityTerm} message WeightedPodAffinityTerm message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WeightedPodAffinityTerm.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WeightedPodAffinityTerm message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.WeightedPodAffinityTerm} WeightedPodAffinityTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WeightedPodAffinityTerm.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.WeightedPodAffinityTerm();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.weight = reader.int32();
                                    break;
                                case 2:
                                    message.podAffinityTerm = $root.k8s.io.api.core.v1.PodAffinityTerm.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WeightedPodAffinityTerm message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.WeightedPodAffinityTerm} WeightedPodAffinityTerm
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WeightedPodAffinityTerm.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WeightedPodAffinityTerm message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WeightedPodAffinityTerm.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.weight != null && message.hasOwnProperty("weight"))
                                if (!$util.isInteger(message.weight))
                                    return "weight: integer expected";
                            if (message.podAffinityTerm != null && message.hasOwnProperty("podAffinityTerm")) {
                                var error = $root.k8s.io.api.core.v1.PodAffinityTerm.verify(message.podAffinityTerm);
                                if (error)
                                    return "podAffinityTerm." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a WeightedPodAffinityTerm message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.WeightedPodAffinityTerm} WeightedPodAffinityTerm
                         */
                        WeightedPodAffinityTerm.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.WeightedPodAffinityTerm)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.WeightedPodAffinityTerm();
                            if (object.weight != null)
                                message.weight = object.weight | 0;
                            if (object.podAffinityTerm != null) {
                                if (typeof object.podAffinityTerm !== "object")
                                    throw TypeError(".k8s.io.api.core.v1.WeightedPodAffinityTerm.podAffinityTerm: object expected");
                                message.podAffinityTerm = $root.k8s.io.api.core.v1.PodAffinityTerm.fromObject(object.podAffinityTerm);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a WeightedPodAffinityTerm message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @static
                         * @param {k8s.io.api.core.v1.WeightedPodAffinityTerm} message WeightedPodAffinityTerm
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WeightedPodAffinityTerm.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.weight = 0;
                                object.podAffinityTerm = null;
                            }
                            if (message.weight != null && message.hasOwnProperty("weight"))
                                object.weight = message.weight;
                            if (message.podAffinityTerm != null && message.hasOwnProperty("podAffinityTerm"))
                                object.podAffinityTerm = $root.k8s.io.api.core.v1.PodAffinityTerm.toObject(message.podAffinityTerm, options);
                            return object;
                        };

                        /**
                         * Converts this WeightedPodAffinityTerm to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.WeightedPodAffinityTerm
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WeightedPodAffinityTerm.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WeightedPodAffinityTerm;
                    })();

                    v1.WindowsSecurityContextOptions = (function() {

                        /**
                         * Properties of a WindowsSecurityContextOptions.
                         * @memberof k8s.io.api.core.v1
                         * @interface IWindowsSecurityContextOptions
                         * @property {string|null} [gmsaCredentialSpecName] WindowsSecurityContextOptions gmsaCredentialSpecName
                         * @property {string|null} [gmsaCredentialSpec] WindowsSecurityContextOptions gmsaCredentialSpec
                         * @property {string|null} [runAsUserName] WindowsSecurityContextOptions runAsUserName
                         */

                        /**
                         * Constructs a new WindowsSecurityContextOptions.
                         * @memberof k8s.io.api.core.v1
                         * @classdesc Represents a WindowsSecurityContextOptions.
                         * @implements IWindowsSecurityContextOptions
                         * @constructor
                         * @param {k8s.io.api.core.v1.IWindowsSecurityContextOptions=} [properties] Properties to set
                         */
                        function WindowsSecurityContextOptions(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * WindowsSecurityContextOptions gmsaCredentialSpecName.
                         * @member {string} gmsaCredentialSpecName
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @instance
                         */
                        WindowsSecurityContextOptions.prototype.gmsaCredentialSpecName = "";

                        /**
                         * WindowsSecurityContextOptions gmsaCredentialSpec.
                         * @member {string} gmsaCredentialSpec
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @instance
                         */
                        WindowsSecurityContextOptions.prototype.gmsaCredentialSpec = "";

                        /**
                         * WindowsSecurityContextOptions runAsUserName.
                         * @member {string} runAsUserName
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @instance
                         */
                        WindowsSecurityContextOptions.prototype.runAsUserName = "";

                        /**
                         * Creates a new WindowsSecurityContextOptions instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IWindowsSecurityContextOptions=} [properties] Properties to set
                         * @returns {k8s.io.api.core.v1.WindowsSecurityContextOptions} WindowsSecurityContextOptions instance
                         */
                        WindowsSecurityContextOptions.create = function create(properties) {
                            return new WindowsSecurityContextOptions(properties);
                        };

                        /**
                         * Encodes the specified WindowsSecurityContextOptions message. Does not implicitly {@link k8s.io.api.core.v1.WindowsSecurityContextOptions.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IWindowsSecurityContextOptions} message WindowsSecurityContextOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WindowsSecurityContextOptions.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.gmsaCredentialSpecName != null && Object.hasOwnProperty.call(message, "gmsaCredentialSpecName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gmsaCredentialSpecName);
                            if (message.gmsaCredentialSpec != null && Object.hasOwnProperty.call(message, "gmsaCredentialSpec"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gmsaCredentialSpec);
                            if (message.runAsUserName != null && Object.hasOwnProperty.call(message, "runAsUserName"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.runAsUserName);
                            return writer;
                        };

                        /**
                         * Encodes the specified WindowsSecurityContextOptions message, length delimited. Does not implicitly {@link k8s.io.api.core.v1.WindowsSecurityContextOptions.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {k8s.io.api.core.v1.IWindowsSecurityContextOptions} message WindowsSecurityContextOptions message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        WindowsSecurityContextOptions.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a WindowsSecurityContextOptions message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.api.core.v1.WindowsSecurityContextOptions} WindowsSecurityContextOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WindowsSecurityContextOptions.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.api.core.v1.WindowsSecurityContextOptions();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.gmsaCredentialSpecName = reader.string();
                                    break;
                                case 2:
                                    message.gmsaCredentialSpec = reader.string();
                                    break;
                                case 3:
                                    message.runAsUserName = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a WindowsSecurityContextOptions message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.api.core.v1.WindowsSecurityContextOptions} WindowsSecurityContextOptions
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        WindowsSecurityContextOptions.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a WindowsSecurityContextOptions message.
                         * @function verify
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        WindowsSecurityContextOptions.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.gmsaCredentialSpecName != null && message.hasOwnProperty("gmsaCredentialSpecName"))
                                if (!$util.isString(message.gmsaCredentialSpecName))
                                    return "gmsaCredentialSpecName: string expected";
                            if (message.gmsaCredentialSpec != null && message.hasOwnProperty("gmsaCredentialSpec"))
                                if (!$util.isString(message.gmsaCredentialSpec))
                                    return "gmsaCredentialSpec: string expected";
                            if (message.runAsUserName != null && message.hasOwnProperty("runAsUserName"))
                                if (!$util.isString(message.runAsUserName))
                                    return "runAsUserName: string expected";
                            return null;
                        };

                        /**
                         * Creates a WindowsSecurityContextOptions message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.api.core.v1.WindowsSecurityContextOptions} WindowsSecurityContextOptions
                         */
                        WindowsSecurityContextOptions.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.api.core.v1.WindowsSecurityContextOptions)
                                return object;
                            var message = new $root.k8s.io.api.core.v1.WindowsSecurityContextOptions();
                            if (object.gmsaCredentialSpecName != null)
                                message.gmsaCredentialSpecName = String(object.gmsaCredentialSpecName);
                            if (object.gmsaCredentialSpec != null)
                                message.gmsaCredentialSpec = String(object.gmsaCredentialSpec);
                            if (object.runAsUserName != null)
                                message.runAsUserName = String(object.runAsUserName);
                            return message;
                        };

                        /**
                         * Creates a plain object from a WindowsSecurityContextOptions message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @static
                         * @param {k8s.io.api.core.v1.WindowsSecurityContextOptions} message WindowsSecurityContextOptions
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        WindowsSecurityContextOptions.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.gmsaCredentialSpecName = "";
                                object.gmsaCredentialSpec = "";
                                object.runAsUserName = "";
                            }
                            if (message.gmsaCredentialSpecName != null && message.hasOwnProperty("gmsaCredentialSpecName"))
                                object.gmsaCredentialSpecName = message.gmsaCredentialSpecName;
                            if (message.gmsaCredentialSpec != null && message.hasOwnProperty("gmsaCredentialSpec"))
                                object.gmsaCredentialSpec = message.gmsaCredentialSpec;
                            if (message.runAsUserName != null && message.hasOwnProperty("runAsUserName"))
                                object.runAsUserName = message.runAsUserName;
                            return object;
                        };

                        /**
                         * Converts this WindowsSecurityContextOptions to JSON.
                         * @function toJSON
                         * @memberof k8s.io.api.core.v1.WindowsSecurityContextOptions
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        WindowsSecurityContextOptions.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return WindowsSecurityContextOptions;
                    })();

                    return v1;
                })();

                return core;
            })();

            return api;
        })();

        io.apimachinery = (function() {

            /**
             * Namespace apimachinery.
             * @memberof k8s.io
             * @namespace
             */
            var apimachinery = {};

            apimachinery.pkg = (function() {

                /**
                 * Namespace pkg.
                 * @memberof k8s.io.apimachinery
                 * @namespace
                 */
                var pkg = {};

                pkg.api = (function() {

                    /**
                     * Namespace api.
                     * @memberof k8s.io.apimachinery.pkg
                     * @namespace
                     */
                    var api = {};

                    api.resource = (function() {

                        /**
                         * Namespace resource.
                         * @memberof k8s.io.apimachinery.pkg.api
                         * @namespace
                         */
                        var resource = {};

                        resource.Quantity = (function() {

                            /**
                             * Properties of a Quantity.
                             * @memberof k8s.io.apimachinery.pkg.api.resource
                             * @interface IQuantity
                             * @property {string|null} [string] Quantity string
                             */

                            /**
                             * Constructs a new Quantity.
                             * @memberof k8s.io.apimachinery.pkg.api.resource
                             * @classdesc Represents a Quantity.
                             * @implements IQuantity
                             * @constructor
                             * @param {k8s.io.apimachinery.pkg.api.resource.IQuantity=} [properties] Properties to set
                             */
                            function Quantity(properties) {
                                if (properties)
                                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * Quantity string.
                             * @member {string} string
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @instance
                             */
                            Quantity.prototype.string = "";

                            /**
                             * Creates a new Quantity instance using the specified properties.
                             * @function create
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {k8s.io.apimachinery.pkg.api.resource.IQuantity=} [properties] Properties to set
                             * @returns {k8s.io.apimachinery.pkg.api.resource.Quantity} Quantity instance
                             */
                            Quantity.create = function create(properties) {
                                return new Quantity(properties);
                            };

                            /**
                             * Encodes the specified Quantity message. Does not implicitly {@link k8s.io.apimachinery.pkg.api.resource.Quantity.verify|verify} messages.
                             * @function encode
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {k8s.io.apimachinery.pkg.api.resource.IQuantity} message Quantity message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            Quantity.encode = function encode(message, writer) {
                                if (!writer)
                                    writer = $Writer.create();
                                if (message.string != null && Object.hasOwnProperty.call(message, "string"))
                                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.string);
                                return writer;
                            };

                            /**
                             * Encodes the specified Quantity message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.api.resource.Quantity.verify|verify} messages.
                             * @function encodeDelimited
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {k8s.io.apimachinery.pkg.api.resource.IQuantity} message Quantity message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            Quantity.encodeDelimited = function encodeDelimited(message, writer) {
                                return this.encode(message, writer).ldelim();
                            };

                            /**
                             * Decodes a Quantity message from the specified reader or buffer.
                             * @function decode
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @param {number} [length] Message length if known beforehand
                             * @returns {k8s.io.apimachinery.pkg.api.resource.Quantity} Quantity
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            Quantity.decode = function decode(reader, length) {
                                if (!(reader instanceof $Reader))
                                    reader = $Reader.create(reader);
                                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.api.resource.Quantity();
                                while (reader.pos < end) {
                                    var tag = reader.uint32();
                                    switch (tag >>> 3) {
                                    case 1:
                                        message.string = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag & 7);
                                        break;
                                    }
                                }
                                return message;
                            };

                            /**
                             * Decodes a Quantity message from the specified reader or buffer, length delimited.
                             * @function decodeDelimited
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @returns {k8s.io.apimachinery.pkg.api.resource.Quantity} Quantity
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            Quantity.decodeDelimited = function decodeDelimited(reader) {
                                if (!(reader instanceof $Reader))
                                    reader = new $Reader(reader);
                                return this.decode(reader, reader.uint32());
                            };

                            /**
                             * Verifies a Quantity message.
                             * @function verify
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {Object.<string,*>} message Plain object to verify
                             * @returns {string|null} `null` if valid, otherwise the reason why it is not
                             */
                            Quantity.verify = function verify(message) {
                                if (typeof message !== "object" || message === null)
                                    return "object expected";
                                if (message.string != null && message.hasOwnProperty("string"))
                                    if (!$util.isString(message.string))
                                        return "string: string expected";
                                return null;
                            };

                            /**
                             * Creates a Quantity message from a plain object. Also converts values to their respective internal types.
                             * @function fromObject
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {Object.<string,*>} object Plain object
                             * @returns {k8s.io.apimachinery.pkg.api.resource.Quantity} Quantity
                             */
                            Quantity.fromObject = function fromObject(object) {
                                if (object instanceof $root.k8s.io.apimachinery.pkg.api.resource.Quantity)
                                    return object;
                                var message = new $root.k8s.io.apimachinery.pkg.api.resource.Quantity();
                                if (object.string != null)
                                    message.string = String(object.string);
                                return message;
                            };

                            /**
                             * Creates a plain object from a Quantity message. Also converts values to other types if specified.
                             * @function toObject
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @static
                             * @param {k8s.io.apimachinery.pkg.api.resource.Quantity} message Quantity
                             * @param {$protobuf.IConversionOptions} [options] Conversion options
                             * @returns {Object.<string,*>} Plain object
                             */
                            Quantity.toObject = function toObject(message, options) {
                                if (!options)
                                    options = {};
                                var object = {};
                                if (options.defaults)
                                    object.string = "";
                                if (message.string != null && message.hasOwnProperty("string"))
                                    object.string = message.string;
                                return object;
                            };

                            /**
                             * Converts this Quantity to JSON.
                             * @function toJSON
                             * @memberof k8s.io.apimachinery.pkg.api.resource.Quantity
                             * @instance
                             * @returns {Object.<string,*>} JSON object
                             */
                            Quantity.prototype.toJSON = function toJSON() {
                                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                            };

                            return Quantity;
                        })();

                        return resource;
                    })();

                    return api;
                })();

                pkg.apis = (function() {

                    /**
                     * Namespace apis.
                     * @memberof k8s.io.apimachinery.pkg
                     * @namespace
                     */
                    var apis = {};

                    apis.meta = (function() {

                        /**
                         * Namespace meta.
                         * @memberof k8s.io.apimachinery.pkg.apis
                         * @namespace
                         */
                        var meta = {};

                        meta.v1 = (function() {

                            /**
                             * Namespace v1.
                             * @memberof k8s.io.apimachinery.pkg.apis.meta
                             * @namespace
                             */
                            var v1 = {};

                            v1.APIGroup = (function() {

                                /**
                                 * Properties of a APIGroup.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IAPIGroup
                                 * @property {string|null} [name] APIGroup name
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery>|null} [versions] APIGroup versions
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery|null} [preferredVersion] APIGroup preferredVersion
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR>|null} [serverAddressByClientCIDRs] APIGroup serverAddressByClientCIDRs
                                 */

                                /**
                                 * Constructs a new APIGroup.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a APIGroup.
                                 * @implements IAPIGroup
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroup=} [properties] Properties to set
                                 */
                                function APIGroup(properties) {
                                    this.versions = [];
                                    this.serverAddressByClientCIDRs = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * APIGroup name.
                                 * @member {string} name
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @instance
                                 */
                                APIGroup.prototype.name = "";

                                /**
                                 * APIGroup versions.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery>} versions
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @instance
                                 */
                                APIGroup.prototype.versions = $util.emptyArray;

                                /**
                                 * APIGroup preferredVersion.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery|null|undefined} preferredVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @instance
                                 */
                                APIGroup.prototype.preferredVersion = null;

                                /**
                                 * APIGroup serverAddressByClientCIDRs.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR>} serverAddressByClientCIDRs
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @instance
                                 */
                                APIGroup.prototype.serverAddressByClientCIDRs = $util.emptyArray;

                                /**
                                 * Creates a new APIGroup instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroup=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup} APIGroup instance
                                 */
                                APIGroup.create = function create(properties) {
                                    return new APIGroup(properties);
                                };

                                /**
                                 * Encodes the specified APIGroup message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroup} message APIGroup message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIGroup.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                                    if (message.versions != null && message.versions.length)
                                        for (var i = 0; i < message.versions.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.encode(message.versions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    if (message.preferredVersion != null && Object.hasOwnProperty.call(message, "preferredVersion"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.encode(message.preferredVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                                    if (message.serverAddressByClientCIDRs != null && message.serverAddressByClientCIDRs.length)
                                        for (var i = 0; i < message.serverAddressByClientCIDRs.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.encode(message.serverAddressByClientCIDRs[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified APIGroup message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroup} message APIGroup message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIGroup.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a APIGroup message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup} APIGroup
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIGroup.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.name = reader.string();
                                            break;
                                        case 2:
                                            if (!(message.versions && message.versions.length))
                                                message.versions = [];
                                            message.versions.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.decode(reader, reader.uint32()));
                                            break;
                                        case 3:
                                            message.preferredVersion = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.decode(reader, reader.uint32());
                                            break;
                                        case 4:
                                            if (!(message.serverAddressByClientCIDRs && message.serverAddressByClientCIDRs.length))
                                                message.serverAddressByClientCIDRs = [];
                                            message.serverAddressByClientCIDRs.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a APIGroup message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup} APIGroup
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIGroup.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a APIGroup message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                APIGroup.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        if (!$util.isString(message.name))
                                            return "name: string expected";
                                    if (message.versions != null && message.hasOwnProperty("versions")) {
                                        if (!Array.isArray(message.versions))
                                            return "versions: array expected";
                                        for (var i = 0; i < message.versions.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.verify(message.versions[i]);
                                            if (error)
                                                return "versions." + error;
                                        }
                                    }
                                    if (message.preferredVersion != null && message.hasOwnProperty("preferredVersion")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.verify(message.preferredVersion);
                                        if (error)
                                            return "preferredVersion." + error;
                                    }
                                    if (message.serverAddressByClientCIDRs != null && message.hasOwnProperty("serverAddressByClientCIDRs")) {
                                        if (!Array.isArray(message.serverAddressByClientCIDRs))
                                            return "serverAddressByClientCIDRs: array expected";
                                        for (var i = 0; i < message.serverAddressByClientCIDRs.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.verify(message.serverAddressByClientCIDRs[i]);
                                            if (error)
                                                return "serverAddressByClientCIDRs." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a APIGroup message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup} APIGroup
                                 */
                                APIGroup.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup();
                                    if (object.name != null)
                                        message.name = String(object.name);
                                    if (object.versions) {
                                        if (!Array.isArray(object.versions))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.versions: array expected");
                                        message.versions = [];
                                        for (var i = 0; i < object.versions.length; ++i) {
                                            if (typeof object.versions[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.versions: object expected");
                                            message.versions[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.fromObject(object.versions[i]);
                                        }
                                    }
                                    if (object.preferredVersion != null) {
                                        if (typeof object.preferredVersion !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.preferredVersion: object expected");
                                        message.preferredVersion = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.fromObject(object.preferredVersion);
                                    }
                                    if (object.serverAddressByClientCIDRs) {
                                        if (!Array.isArray(object.serverAddressByClientCIDRs))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.serverAddressByClientCIDRs: array expected");
                                        message.serverAddressByClientCIDRs = [];
                                        for (var i = 0; i < object.serverAddressByClientCIDRs.length; ++i) {
                                            if (typeof object.serverAddressByClientCIDRs[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.serverAddressByClientCIDRs: object expected");
                                            message.serverAddressByClientCIDRs[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.fromObject(object.serverAddressByClientCIDRs[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a APIGroup message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup} message APIGroup
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                APIGroup.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults) {
                                        object.versions = [];
                                        object.serverAddressByClientCIDRs = [];
                                    }
                                    if (options.defaults) {
                                        object.name = "";
                                        object.preferredVersion = null;
                                    }
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        object.name = message.name;
                                    if (message.versions && message.versions.length) {
                                        object.versions = [];
                                        for (var j = 0; j < message.versions.length; ++j)
                                            object.versions[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.toObject(message.versions[j], options);
                                    }
                                    if (message.preferredVersion != null && message.hasOwnProperty("preferredVersion"))
                                        object.preferredVersion = $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.toObject(message.preferredVersion, options);
                                    if (message.serverAddressByClientCIDRs && message.serverAddressByClientCIDRs.length) {
                                        object.serverAddressByClientCIDRs = [];
                                        for (var j = 0; j < message.serverAddressByClientCIDRs.length; ++j)
                                            object.serverAddressByClientCIDRs[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.toObject(message.serverAddressByClientCIDRs[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this APIGroup to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                APIGroup.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return APIGroup;
                            })();

                            v1.APIGroupList = (function() {

                                /**
                                 * Properties of a APIGroupList.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IAPIGroupList
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroup>|null} [groups] APIGroupList groups
                                 */

                                /**
                                 * Constructs a new APIGroupList.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a APIGroupList.
                                 * @implements IAPIGroupList
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroupList=} [properties] Properties to set
                                 */
                                function APIGroupList(properties) {
                                    this.groups = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * APIGroupList groups.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroup>} groups
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @instance
                                 */
                                APIGroupList.prototype.groups = $util.emptyArray;

                                /**
                                 * Creates a new APIGroupList instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroupList=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList} APIGroupList instance
                                 */
                                APIGroupList.create = function create(properties) {
                                    return new APIGroupList(properties);
                                };

                                /**
                                 * Encodes the specified APIGroupList message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroupList} message APIGroupList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIGroupList.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.groups != null && message.groups.length)
                                        for (var i = 0; i < message.groups.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified APIGroupList message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIGroupList} message APIGroupList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIGroupList.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a APIGroupList message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList} APIGroupList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIGroupList.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.groups && message.groups.length))
                                                message.groups = [];
                                            message.groups.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a APIGroupList message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList} APIGroupList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIGroupList.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a APIGroupList message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                APIGroupList.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.groups != null && message.hasOwnProperty("groups")) {
                                        if (!Array.isArray(message.groups))
                                            return "groups: array expected";
                                        for (var i = 0; i < message.groups.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.verify(message.groups[i]);
                                            if (error)
                                                return "groups." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a APIGroupList message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList} APIGroupList
                                 */
                                APIGroupList.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList();
                                    if (object.groups) {
                                        if (!Array.isArray(object.groups))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList.groups: array expected");
                                        message.groups = [];
                                        for (var i = 0; i < object.groups.length; ++i) {
                                            if (typeof object.groups[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList.groups: object expected");
                                            message.groups[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.fromObject(object.groups[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a APIGroupList message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList} message APIGroupList
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                APIGroupList.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.groups = [];
                                    if (message.groups && message.groups.length) {
                                        object.groups = [];
                                        for (var j = 0; j < message.groups.length; ++j)
                                            object.groups[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIGroup.toObject(message.groups[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this APIGroupList to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIGroupList
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                APIGroupList.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return APIGroupList;
                            })();

                            v1.APIResource = (function() {

                                /**
                                 * Properties of a APIResource.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IAPIResource
                                 * @property {string|null} [name] APIResource name
                                 * @property {string|null} [singularName] APIResource singularName
                                 * @property {boolean|null} [namespaced] APIResource namespaced
                                 * @property {string|null} [group] APIResource group
                                 * @property {string|null} [version] APIResource version
                                 * @property {string|null} [kind] APIResource kind
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IVerbs|null} [verbs] APIResource verbs
                                 * @property {Array.<string>|null} [shortNames] APIResource shortNames
                                 * @property {Array.<string>|null} [categories] APIResource categories
                                 * @property {string|null} [storageVersionHash] APIResource storageVersionHash
                                 */

                                /**
                                 * Constructs a new APIResource.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a APIResource.
                                 * @implements IAPIResource
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResource=} [properties] Properties to set
                                 */
                                function APIResource(properties) {
                                    this.shortNames = [];
                                    this.categories = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * APIResource name.
                                 * @member {string} name
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.name = "";

                                /**
                                 * APIResource singularName.
                                 * @member {string} singularName
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.singularName = "";

                                /**
                                 * APIResource namespaced.
                                 * @member {boolean} namespaced
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.namespaced = false;

                                /**
                                 * APIResource group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.group = "";

                                /**
                                 * APIResource version.
                                 * @member {string} version
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.version = "";

                                /**
                                 * APIResource kind.
                                 * @member {string} kind
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.kind = "";

                                /**
                                 * APIResource verbs.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IVerbs|null|undefined} verbs
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.verbs = null;

                                /**
                                 * APIResource shortNames.
                                 * @member {Array.<string>} shortNames
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.shortNames = $util.emptyArray;

                                /**
                                 * APIResource categories.
                                 * @member {Array.<string>} categories
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.categories = $util.emptyArray;

                                /**
                                 * APIResource storageVersionHash.
                                 * @member {string} storageVersionHash
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 */
                                APIResource.prototype.storageVersionHash = "";

                                /**
                                 * Creates a new APIResource instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResource=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResource} APIResource instance
                                 */
                                APIResource.create = function create(properties) {
                                    return new APIResource(properties);
                                };

                                /**
                                 * Encodes the specified APIResource message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResource} message APIResource message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIResource.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                                    if (message.namespaced != null && Object.hasOwnProperty.call(message, "namespaced"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.namespaced);
                                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.kind);
                                    if (message.verbs != null && Object.hasOwnProperty.call(message, "verbs"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.encode(message.verbs, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    if (message.shortNames != null && message.shortNames.length)
                                        for (var i = 0; i < message.shortNames.length; ++i)
                                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.shortNames[i]);
                                    if (message.singularName != null && Object.hasOwnProperty.call(message, "singularName"))
                                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.singularName);
                                    if (message.categories != null && message.categories.length)
                                        for (var i = 0; i < message.categories.length; ++i)
                                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.categories[i]);
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.group);
                                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.version);
                                    if (message.storageVersionHash != null && Object.hasOwnProperty.call(message, "storageVersionHash"))
                                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.storageVersionHash);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified APIResource message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResource} message APIResource message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIResource.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a APIResource message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResource} APIResource
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIResource.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.name = reader.string();
                                            break;
                                        case 6:
                                            message.singularName = reader.string();
                                            break;
                                        case 2:
                                            message.namespaced = reader.bool();
                                            break;
                                        case 8:
                                            message.group = reader.string();
                                            break;
                                        case 9:
                                            message.version = reader.string();
                                            break;
                                        case 3:
                                            message.kind = reader.string();
                                            break;
                                        case 4:
                                            message.verbs = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.decode(reader, reader.uint32());
                                            break;
                                        case 5:
                                            if (!(message.shortNames && message.shortNames.length))
                                                message.shortNames = [];
                                            message.shortNames.push(reader.string());
                                            break;
                                        case 7:
                                            if (!(message.categories && message.categories.length))
                                                message.categories = [];
                                            message.categories.push(reader.string());
                                            break;
                                        case 10:
                                            message.storageVersionHash = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a APIResource message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResource} APIResource
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIResource.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a APIResource message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                APIResource.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        if (!$util.isString(message.name))
                                            return "name: string expected";
                                    if (message.singularName != null && message.hasOwnProperty("singularName"))
                                        if (!$util.isString(message.singularName))
                                            return "singularName: string expected";
                                    if (message.namespaced != null && message.hasOwnProperty("namespaced"))
                                        if (typeof message.namespaced !== "boolean")
                                            return "namespaced: boolean expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        if (!$util.isString(message.version))
                                            return "version: string expected";
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        if (!$util.isString(message.kind))
                                            return "kind: string expected";
                                    if (message.verbs != null && message.hasOwnProperty("verbs")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.verify(message.verbs);
                                        if (error)
                                            return "verbs." + error;
                                    }
                                    if (message.shortNames != null && message.hasOwnProperty("shortNames")) {
                                        if (!Array.isArray(message.shortNames))
                                            return "shortNames: array expected";
                                        for (var i = 0; i < message.shortNames.length; ++i)
                                            if (!$util.isString(message.shortNames[i]))
                                                return "shortNames: string[] expected";
                                    }
                                    if (message.categories != null && message.hasOwnProperty("categories")) {
                                        if (!Array.isArray(message.categories))
                                            return "categories: array expected";
                                        for (var i = 0; i < message.categories.length; ++i)
                                            if (!$util.isString(message.categories[i]))
                                                return "categories: string[] expected";
                                    }
                                    if (message.storageVersionHash != null && message.hasOwnProperty("storageVersionHash"))
                                        if (!$util.isString(message.storageVersionHash))
                                            return "storageVersionHash: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a APIResource message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResource} APIResource
                                 */
                                APIResource.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource();
                                    if (object.name != null)
                                        message.name = String(object.name);
                                    if (object.singularName != null)
                                        message.singularName = String(object.singularName);
                                    if (object.namespaced != null)
                                        message.namespaced = Boolean(object.namespaced);
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.version != null)
                                        message.version = String(object.version);
                                    if (object.kind != null)
                                        message.kind = String(object.kind);
                                    if (object.verbs != null) {
                                        if (typeof object.verbs !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.verbs: object expected");
                                        message.verbs = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.fromObject(object.verbs);
                                    }
                                    if (object.shortNames) {
                                        if (!Array.isArray(object.shortNames))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.shortNames: array expected");
                                        message.shortNames = [];
                                        for (var i = 0; i < object.shortNames.length; ++i)
                                            message.shortNames[i] = String(object.shortNames[i]);
                                    }
                                    if (object.categories) {
                                        if (!Array.isArray(object.categories))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.categories: array expected");
                                        message.categories = [];
                                        for (var i = 0; i < object.categories.length; ++i)
                                            message.categories[i] = String(object.categories[i]);
                                    }
                                    if (object.storageVersionHash != null)
                                        message.storageVersionHash = String(object.storageVersionHash);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a APIResource message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.APIResource} message APIResource
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                APIResource.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults) {
                                        object.shortNames = [];
                                        object.categories = [];
                                    }
                                    if (options.defaults) {
                                        object.name = "";
                                        object.namespaced = false;
                                        object.kind = "";
                                        object.verbs = null;
                                        object.singularName = "";
                                        object.group = "";
                                        object.version = "";
                                        object.storageVersionHash = "";
                                    }
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        object.name = message.name;
                                    if (message.namespaced != null && message.hasOwnProperty("namespaced"))
                                        object.namespaced = message.namespaced;
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        object.kind = message.kind;
                                    if (message.verbs != null && message.hasOwnProperty("verbs"))
                                        object.verbs = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.toObject(message.verbs, options);
                                    if (message.shortNames && message.shortNames.length) {
                                        object.shortNames = [];
                                        for (var j = 0; j < message.shortNames.length; ++j)
                                            object.shortNames[j] = message.shortNames[j];
                                    }
                                    if (message.singularName != null && message.hasOwnProperty("singularName"))
                                        object.singularName = message.singularName;
                                    if (message.categories && message.categories.length) {
                                        object.categories = [];
                                        for (var j = 0; j < message.categories.length; ++j)
                                            object.categories[j] = message.categories[j];
                                    }
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        object.version = message.version;
                                    if (message.storageVersionHash != null && message.hasOwnProperty("storageVersionHash"))
                                        object.storageVersionHash = message.storageVersionHash;
                                    return object;
                                };

                                /**
                                 * Converts this APIResource to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResource
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                APIResource.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return APIResource;
                            })();

                            v1.APIResourceList = (function() {

                                /**
                                 * Properties of a APIResourceList.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IAPIResourceList
                                 * @property {string|null} [groupVersion] APIResourceList groupVersion
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResource>|null} [resources] APIResourceList resources
                                 */

                                /**
                                 * Constructs a new APIResourceList.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a APIResourceList.
                                 * @implements IAPIResourceList
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResourceList=} [properties] Properties to set
                                 */
                                function APIResourceList(properties) {
                                    this.resources = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * APIResourceList groupVersion.
                                 * @member {string} groupVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @instance
                                 */
                                APIResourceList.prototype.groupVersion = "";

                                /**
                                 * APIResourceList resources.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResource>} resources
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @instance
                                 */
                                APIResourceList.prototype.resources = $util.emptyArray;

                                /**
                                 * Creates a new APIResourceList instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResourceList=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList} APIResourceList instance
                                 */
                                APIResourceList.create = function create(properties) {
                                    return new APIResourceList(properties);
                                };

                                /**
                                 * Encodes the specified APIResourceList message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResourceList} message APIResourceList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIResourceList.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.groupVersion != null && Object.hasOwnProperty.call(message, "groupVersion"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupVersion);
                                    if (message.resources != null && message.resources.length)
                                        for (var i = 0; i < message.resources.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.encode(message.resources[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified APIResourceList message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIResourceList} message APIResourceList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIResourceList.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a APIResourceList message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList} APIResourceList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIResourceList.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.groupVersion = reader.string();
                                            break;
                                        case 2:
                                            if (!(message.resources && message.resources.length))
                                                message.resources = [];
                                            message.resources.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a APIResourceList message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList} APIResourceList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIResourceList.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a APIResourceList message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                APIResourceList.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.groupVersion != null && message.hasOwnProperty("groupVersion"))
                                        if (!$util.isString(message.groupVersion))
                                            return "groupVersion: string expected";
                                    if (message.resources != null && message.hasOwnProperty("resources")) {
                                        if (!Array.isArray(message.resources))
                                            return "resources: array expected";
                                        for (var i = 0; i < message.resources.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.verify(message.resources[i]);
                                            if (error)
                                                return "resources." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a APIResourceList message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList} APIResourceList
                                 */
                                APIResourceList.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList();
                                    if (object.groupVersion != null)
                                        message.groupVersion = String(object.groupVersion);
                                    if (object.resources) {
                                        if (!Array.isArray(object.resources))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList.resources: array expected");
                                        message.resources = [];
                                        for (var i = 0; i < object.resources.length; ++i) {
                                            if (typeof object.resources[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList.resources: object expected");
                                            message.resources[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.fromObject(object.resources[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a APIResourceList message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList} message APIResourceList
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                APIResourceList.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.resources = [];
                                    if (options.defaults)
                                        object.groupVersion = "";
                                    if (message.groupVersion != null && message.hasOwnProperty("groupVersion"))
                                        object.groupVersion = message.groupVersion;
                                    if (message.resources && message.resources.length) {
                                        object.resources = [];
                                        for (var j = 0; j < message.resources.length; ++j)
                                            object.resources[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIResource.toObject(message.resources[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this APIResourceList to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIResourceList
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                APIResourceList.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return APIResourceList;
                            })();

                            v1.APIVersions = (function() {

                                /**
                                 * Properties of a APIVersions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IAPIVersions
                                 * @property {Array.<string>|null} [versions] APIVersions versions
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR>|null} [serverAddressByClientCIDRs] APIVersions serverAddressByClientCIDRs
                                 */

                                /**
                                 * Constructs a new APIVersions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a APIVersions.
                                 * @implements IAPIVersions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIVersions=} [properties] Properties to set
                                 */
                                function APIVersions(properties) {
                                    this.versions = [];
                                    this.serverAddressByClientCIDRs = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * APIVersions versions.
                                 * @member {Array.<string>} versions
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @instance
                                 */
                                APIVersions.prototype.versions = $util.emptyArray;

                                /**
                                 * APIVersions serverAddressByClientCIDRs.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR>} serverAddressByClientCIDRs
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @instance
                                 */
                                APIVersions.prototype.serverAddressByClientCIDRs = $util.emptyArray;

                                /**
                                 * Creates a new APIVersions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIVersions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions} APIVersions instance
                                 */
                                APIVersions.create = function create(properties) {
                                    return new APIVersions(properties);
                                };

                                /**
                                 * Encodes the specified APIVersions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIVersions} message APIVersions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIVersions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.versions != null && message.versions.length)
                                        for (var i = 0; i < message.versions.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.versions[i]);
                                    if (message.serverAddressByClientCIDRs != null && message.serverAddressByClientCIDRs.length)
                                        for (var i = 0; i < message.serverAddressByClientCIDRs.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.encode(message.serverAddressByClientCIDRs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified APIVersions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IAPIVersions} message APIVersions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                APIVersions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a APIVersions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions} APIVersions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIVersions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.versions && message.versions.length))
                                                message.versions = [];
                                            message.versions.push(reader.string());
                                            break;
                                        case 2:
                                            if (!(message.serverAddressByClientCIDRs && message.serverAddressByClientCIDRs.length))
                                                message.serverAddressByClientCIDRs = [];
                                            message.serverAddressByClientCIDRs.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a APIVersions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions} APIVersions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                APIVersions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a APIVersions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                APIVersions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.versions != null && message.hasOwnProperty("versions")) {
                                        if (!Array.isArray(message.versions))
                                            return "versions: array expected";
                                        for (var i = 0; i < message.versions.length; ++i)
                                            if (!$util.isString(message.versions[i]))
                                                return "versions: string[] expected";
                                    }
                                    if (message.serverAddressByClientCIDRs != null && message.hasOwnProperty("serverAddressByClientCIDRs")) {
                                        if (!Array.isArray(message.serverAddressByClientCIDRs))
                                            return "serverAddressByClientCIDRs: array expected";
                                        for (var i = 0; i < message.serverAddressByClientCIDRs.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.verify(message.serverAddressByClientCIDRs[i]);
                                            if (error)
                                                return "serverAddressByClientCIDRs." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a APIVersions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions} APIVersions
                                 */
                                APIVersions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions();
                                    if (object.versions) {
                                        if (!Array.isArray(object.versions))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions.versions: array expected");
                                        message.versions = [];
                                        for (var i = 0; i < object.versions.length; ++i)
                                            message.versions[i] = String(object.versions[i]);
                                    }
                                    if (object.serverAddressByClientCIDRs) {
                                        if (!Array.isArray(object.serverAddressByClientCIDRs))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions.serverAddressByClientCIDRs: array expected");
                                        message.serverAddressByClientCIDRs = [];
                                        for (var i = 0; i < object.serverAddressByClientCIDRs.length; ++i) {
                                            if (typeof object.serverAddressByClientCIDRs[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions.serverAddressByClientCIDRs: object expected");
                                            message.serverAddressByClientCIDRs[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.fromObject(object.serverAddressByClientCIDRs[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a APIVersions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions} message APIVersions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                APIVersions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults) {
                                        object.versions = [];
                                        object.serverAddressByClientCIDRs = [];
                                    }
                                    if (message.versions && message.versions.length) {
                                        object.versions = [];
                                        for (var j = 0; j < message.versions.length; ++j)
                                            object.versions[j] = message.versions[j];
                                    }
                                    if (message.serverAddressByClientCIDRs && message.serverAddressByClientCIDRs.length) {
                                        object.serverAddressByClientCIDRs = [];
                                        for (var j = 0; j < message.serverAddressByClientCIDRs.length; ++j)
                                            object.serverAddressByClientCIDRs[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.toObject(message.serverAddressByClientCIDRs[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this APIVersions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.APIVersions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                APIVersions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return APIVersions;
                            })();

                            v1.Condition = (function() {

                                /**
                                 * Properties of a Condition.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ICondition
                                 * @property {string|null} [type] Condition type
                                 * @property {string|null} [status] Condition status
                                 * @property {number|Long|null} [observedGeneration] Condition observedGeneration
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [lastTransitionTime] Condition lastTransitionTime
                                 * @property {string|null} [reason] Condition reason
                                 * @property {string|null} [message] Condition message
                                 */

                                /**
                                 * Constructs a new Condition.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Condition.
                                 * @implements ICondition
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICondition=} [properties] Properties to set
                                 */
                                function Condition(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Condition type.
                                 * @member {string} type
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 */
                                Condition.prototype.type = "";

                                /**
                                 * Condition status.
                                 * @member {string} status
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 */
                                Condition.prototype.status = "";

                                /**
                                 * Condition observedGeneration.
                                 * @member {number|Long} observedGeneration
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 */
                                Condition.prototype.observedGeneration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * Condition lastTransitionTime.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} lastTransitionTime
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 */
                                Condition.prototype.lastTransitionTime = null;

                                /**
                                 * Condition reason.
                                 * @member {string} reason
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 */
                                Condition.prototype.reason = "";

                                /**
                                 * Condition message.
                                 * @member {string} message
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 */
                                Condition.prototype.message = "";

                                /**
                                 * Creates a new Condition instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICondition=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Condition} Condition instance
                                 */
                                Condition.create = function create(properties) {
                                    return new Condition(properties);
                                };

                                /**
                                 * Encodes the specified Condition message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Condition.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICondition} message Condition message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Condition.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                                    if (message.observedGeneration != null && Object.hasOwnProperty.call(message, "observedGeneration"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.observedGeneration);
                                    if (message.lastTransitionTime != null && Object.hasOwnProperty.call(message, "lastTransitionTime"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.lastTransitionTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.reason);
                                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.message);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Condition message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Condition.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICondition} message Condition message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Condition.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Condition message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Condition} Condition
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Condition.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.type = reader.string();
                                            break;
                                        case 2:
                                            message.status = reader.string();
                                            break;
                                        case 3:
                                            message.observedGeneration = reader.int64();
                                            break;
                                        case 4:
                                            message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                            break;
                                        case 5:
                                            message.reason = reader.string();
                                            break;
                                        case 6:
                                            message.message = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Condition message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Condition} Condition
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Condition.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Condition message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Condition.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.type != null && message.hasOwnProperty("type"))
                                        if (!$util.isString(message.type))
                                            return "type: string expected";
                                    if (message.status != null && message.hasOwnProperty("status"))
                                        if (!$util.isString(message.status))
                                            return "status: string expected";
                                    if (message.observedGeneration != null && message.hasOwnProperty("observedGeneration"))
                                        if (!$util.isInteger(message.observedGeneration) && !(message.observedGeneration && $util.isInteger(message.observedGeneration.low) && $util.isInteger(message.observedGeneration.high)))
                                            return "observedGeneration: integer|Long expected";
                                    if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.lastTransitionTime);
                                        if (error)
                                            return "lastTransitionTime." + error;
                                    }
                                    if (message.reason != null && message.hasOwnProperty("reason"))
                                        if (!$util.isString(message.reason))
                                            return "reason: string expected";
                                    if (message.message != null && message.hasOwnProperty("message"))
                                        if (!$util.isString(message.message))
                                            return "message: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a Condition message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Condition} Condition
                                 */
                                Condition.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Condition();
                                    if (object.type != null)
                                        message.type = String(object.type);
                                    if (object.status != null)
                                        message.status = String(object.status);
                                    if (object.observedGeneration != null)
                                        if ($util.Long)
                                            (message.observedGeneration = $util.Long.fromValue(object.observedGeneration)).unsigned = false;
                                        else if (typeof object.observedGeneration === "string")
                                            message.observedGeneration = parseInt(object.observedGeneration, 10);
                                        else if (typeof object.observedGeneration === "number")
                                            message.observedGeneration = object.observedGeneration;
                                        else if (typeof object.observedGeneration === "object")
                                            message.observedGeneration = new $util.LongBits(object.observedGeneration.low >>> 0, object.observedGeneration.high >>> 0).toNumber();
                                    if (object.lastTransitionTime != null) {
                                        if (typeof object.lastTransitionTime !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.Condition.lastTransitionTime: object expected");
                                        message.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.lastTransitionTime);
                                    }
                                    if (object.reason != null)
                                        message.reason = String(object.reason);
                                    if (object.message != null)
                                        message.message = String(object.message);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Condition message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Condition} message Condition
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Condition.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.type = "";
                                        object.status = "";
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.observedGeneration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.observedGeneration = options.longs === String ? "0" : 0;
                                        object.lastTransitionTime = null;
                                        object.reason = "";
                                        object.message = "";
                                    }
                                    if (message.type != null && message.hasOwnProperty("type"))
                                        object.type = message.type;
                                    if (message.status != null && message.hasOwnProperty("status"))
                                        object.status = message.status;
                                    if (message.observedGeneration != null && message.hasOwnProperty("observedGeneration"))
                                        if (typeof message.observedGeneration === "number")
                                            object.observedGeneration = options.longs === String ? String(message.observedGeneration) : message.observedGeneration;
                                        else
                                            object.observedGeneration = options.longs === String ? $util.Long.prototype.toString.call(message.observedGeneration) : options.longs === Number ? new $util.LongBits(message.observedGeneration.low >>> 0, message.observedGeneration.high >>> 0).toNumber() : message.observedGeneration;
                                    if (message.lastTransitionTime != null && message.hasOwnProperty("lastTransitionTime"))
                                        object.lastTransitionTime = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.lastTransitionTime, options);
                                    if (message.reason != null && message.hasOwnProperty("reason"))
                                        object.reason = message.reason;
                                    if (message.message != null && message.hasOwnProperty("message"))
                                        object.message = message.message;
                                    return object;
                                };

                                /**
                                 * Converts this Condition to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Condition
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Condition.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Condition;
                            })();

                            v1.CreateOptions = (function() {

                                /**
                                 * Properties of a CreateOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ICreateOptions
                                 * @property {Array.<string>|null} [dryRun] CreateOptions dryRun
                                 * @property {string|null} [fieldManager] CreateOptions fieldManager
                                 */

                                /**
                                 * Constructs a new CreateOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a CreateOptions.
                                 * @implements ICreateOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICreateOptions=} [properties] Properties to set
                                 */
                                function CreateOptions(properties) {
                                    this.dryRun = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * CreateOptions dryRun.
                                 * @member {Array.<string>} dryRun
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @instance
                                 */
                                CreateOptions.prototype.dryRun = $util.emptyArray;

                                /**
                                 * CreateOptions fieldManager.
                                 * @member {string} fieldManager
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @instance
                                 */
                                CreateOptions.prototype.fieldManager = "";

                                /**
                                 * Creates a new CreateOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICreateOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions} CreateOptions instance
                                 */
                                CreateOptions.create = function create(properties) {
                                    return new CreateOptions(properties);
                                };

                                /**
                                 * Encodes the specified CreateOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICreateOptions} message CreateOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                CreateOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.dryRun != null && message.dryRun.length)
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dryRun[i]);
                                    if (message.fieldManager != null && Object.hasOwnProperty.call(message, "fieldManager"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.fieldManager);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified CreateOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ICreateOptions} message CreateOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                CreateOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a CreateOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions} CreateOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                CreateOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.dryRun && message.dryRun.length))
                                                message.dryRun = [];
                                            message.dryRun.push(reader.string());
                                            break;
                                        case 3:
                                            message.fieldManager = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a CreateOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions} CreateOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                CreateOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a CreateOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                CreateOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.dryRun != null && message.hasOwnProperty("dryRun")) {
                                        if (!Array.isArray(message.dryRun))
                                            return "dryRun: array expected";
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            if (!$util.isString(message.dryRun[i]))
                                                return "dryRun: string[] expected";
                                    }
                                    if (message.fieldManager != null && message.hasOwnProperty("fieldManager"))
                                        if (!$util.isString(message.fieldManager))
                                            return "fieldManager: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a CreateOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions} CreateOptions
                                 */
                                CreateOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions();
                                    if (object.dryRun) {
                                        if (!Array.isArray(object.dryRun))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions.dryRun: array expected");
                                        message.dryRun = [];
                                        for (var i = 0; i < object.dryRun.length; ++i)
                                            message.dryRun[i] = String(object.dryRun[i]);
                                    }
                                    if (object.fieldManager != null)
                                        message.fieldManager = String(object.fieldManager);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a CreateOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions} message CreateOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                CreateOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.dryRun = [];
                                    if (options.defaults)
                                        object.fieldManager = "";
                                    if (message.dryRun && message.dryRun.length) {
                                        object.dryRun = [];
                                        for (var j = 0; j < message.dryRun.length; ++j)
                                            object.dryRun[j] = message.dryRun[j];
                                    }
                                    if (message.fieldManager != null && message.hasOwnProperty("fieldManager"))
                                        object.fieldManager = message.fieldManager;
                                    return object;
                                };

                                /**
                                 * Converts this CreateOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.CreateOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                CreateOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return CreateOptions;
                            })();

                            v1.DeleteOptions = (function() {

                                /**
                                 * Properties of a DeleteOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IDeleteOptions
                                 * @property {number|Long|null} [gracePeriodSeconds] DeleteOptions gracePeriodSeconds
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IPreconditions|null} [preconditions] DeleteOptions preconditions
                                 * @property {boolean|null} [orphanDependents] DeleteOptions orphanDependents
                                 * @property {string|null} [propagationPolicy] DeleteOptions propagationPolicy
                                 * @property {Array.<string>|null} [dryRun] DeleteOptions dryRun
                                 */

                                /**
                                 * Constructs a new DeleteOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a DeleteOptions.
                                 * @implements IDeleteOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDeleteOptions=} [properties] Properties to set
                                 */
                                function DeleteOptions(properties) {
                                    this.dryRun = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * DeleteOptions gracePeriodSeconds.
                                 * @member {number|Long} gracePeriodSeconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @instance
                                 */
                                DeleteOptions.prototype.gracePeriodSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * DeleteOptions preconditions.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IPreconditions|null|undefined} preconditions
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @instance
                                 */
                                DeleteOptions.prototype.preconditions = null;

                                /**
                                 * DeleteOptions orphanDependents.
                                 * @member {boolean} orphanDependents
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @instance
                                 */
                                DeleteOptions.prototype.orphanDependents = false;

                                /**
                                 * DeleteOptions propagationPolicy.
                                 * @member {string} propagationPolicy
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @instance
                                 */
                                DeleteOptions.prototype.propagationPolicy = "";

                                /**
                                 * DeleteOptions dryRun.
                                 * @member {Array.<string>} dryRun
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @instance
                                 */
                                DeleteOptions.prototype.dryRun = $util.emptyArray;

                                /**
                                 * Creates a new DeleteOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDeleteOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions} DeleteOptions instance
                                 */
                                DeleteOptions.create = function create(properties) {
                                    return new DeleteOptions(properties);
                                };

                                /**
                                 * Encodes the specified DeleteOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDeleteOptions} message DeleteOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                DeleteOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.gracePeriodSeconds != null && Object.hasOwnProperty.call(message, "gracePeriodSeconds"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.gracePeriodSeconds);
                                    if (message.preconditions != null && Object.hasOwnProperty.call(message, "preconditions"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.encode(message.preconditions, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    if (message.orphanDependents != null && Object.hasOwnProperty.call(message, "orphanDependents"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.orphanDependents);
                                    if (message.propagationPolicy != null && Object.hasOwnProperty.call(message, "propagationPolicy"))
                                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.propagationPolicy);
                                    if (message.dryRun != null && message.dryRun.length)
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.dryRun[i]);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified DeleteOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDeleteOptions} message DeleteOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                DeleteOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a DeleteOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions} DeleteOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                DeleteOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.gracePeriodSeconds = reader.int64();
                                            break;
                                        case 2:
                                            message.preconditions = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.decode(reader, reader.uint32());
                                            break;
                                        case 3:
                                            message.orphanDependents = reader.bool();
                                            break;
                                        case 4:
                                            message.propagationPolicy = reader.string();
                                            break;
                                        case 5:
                                            if (!(message.dryRun && message.dryRun.length))
                                                message.dryRun = [];
                                            message.dryRun.push(reader.string());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a DeleteOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions} DeleteOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                DeleteOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a DeleteOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                DeleteOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.gracePeriodSeconds != null && message.hasOwnProperty("gracePeriodSeconds"))
                                        if (!$util.isInteger(message.gracePeriodSeconds) && !(message.gracePeriodSeconds && $util.isInteger(message.gracePeriodSeconds.low) && $util.isInteger(message.gracePeriodSeconds.high)))
                                            return "gracePeriodSeconds: integer|Long expected";
                                    if (message.preconditions != null && message.hasOwnProperty("preconditions")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.verify(message.preconditions);
                                        if (error)
                                            return "preconditions." + error;
                                    }
                                    if (message.orphanDependents != null && message.hasOwnProperty("orphanDependents"))
                                        if (typeof message.orphanDependents !== "boolean")
                                            return "orphanDependents: boolean expected";
                                    if (message.propagationPolicy != null && message.hasOwnProperty("propagationPolicy"))
                                        if (!$util.isString(message.propagationPolicy))
                                            return "propagationPolicy: string expected";
                                    if (message.dryRun != null && message.hasOwnProperty("dryRun")) {
                                        if (!Array.isArray(message.dryRun))
                                            return "dryRun: array expected";
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            if (!$util.isString(message.dryRun[i]))
                                                return "dryRun: string[] expected";
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a DeleteOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions} DeleteOptions
                                 */
                                DeleteOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions();
                                    if (object.gracePeriodSeconds != null)
                                        if ($util.Long)
                                            (message.gracePeriodSeconds = $util.Long.fromValue(object.gracePeriodSeconds)).unsigned = false;
                                        else if (typeof object.gracePeriodSeconds === "string")
                                            message.gracePeriodSeconds = parseInt(object.gracePeriodSeconds, 10);
                                        else if (typeof object.gracePeriodSeconds === "number")
                                            message.gracePeriodSeconds = object.gracePeriodSeconds;
                                        else if (typeof object.gracePeriodSeconds === "object")
                                            message.gracePeriodSeconds = new $util.LongBits(object.gracePeriodSeconds.low >>> 0, object.gracePeriodSeconds.high >>> 0).toNumber();
                                    if (object.preconditions != null) {
                                        if (typeof object.preconditions !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions.preconditions: object expected");
                                        message.preconditions = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.fromObject(object.preconditions);
                                    }
                                    if (object.orphanDependents != null)
                                        message.orphanDependents = Boolean(object.orphanDependents);
                                    if (object.propagationPolicy != null)
                                        message.propagationPolicy = String(object.propagationPolicy);
                                    if (object.dryRun) {
                                        if (!Array.isArray(object.dryRun))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions.dryRun: array expected");
                                        message.dryRun = [];
                                        for (var i = 0; i < object.dryRun.length; ++i)
                                            message.dryRun[i] = String(object.dryRun[i]);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a DeleteOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions} message DeleteOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                DeleteOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.dryRun = [];
                                    if (options.defaults) {
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.gracePeriodSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.gracePeriodSeconds = options.longs === String ? "0" : 0;
                                        object.preconditions = null;
                                        object.orphanDependents = false;
                                        object.propagationPolicy = "";
                                    }
                                    if (message.gracePeriodSeconds != null && message.hasOwnProperty("gracePeriodSeconds"))
                                        if (typeof message.gracePeriodSeconds === "number")
                                            object.gracePeriodSeconds = options.longs === String ? String(message.gracePeriodSeconds) : message.gracePeriodSeconds;
                                        else
                                            object.gracePeriodSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.gracePeriodSeconds) : options.longs === Number ? new $util.LongBits(message.gracePeriodSeconds.low >>> 0, message.gracePeriodSeconds.high >>> 0).toNumber() : message.gracePeriodSeconds;
                                    if (message.preconditions != null && message.hasOwnProperty("preconditions"))
                                        object.preconditions = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.toObject(message.preconditions, options);
                                    if (message.orphanDependents != null && message.hasOwnProperty("orphanDependents"))
                                        object.orphanDependents = message.orphanDependents;
                                    if (message.propagationPolicy != null && message.hasOwnProperty("propagationPolicy"))
                                        object.propagationPolicy = message.propagationPolicy;
                                    if (message.dryRun && message.dryRun.length) {
                                        object.dryRun = [];
                                        for (var j = 0; j < message.dryRun.length; ++j)
                                            object.dryRun[j] = message.dryRun[j];
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this DeleteOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.DeleteOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                DeleteOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return DeleteOptions;
                            })();

                            v1.Duration = (function() {

                                /**
                                 * Properties of a Duration.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IDuration
                                 * @property {number|Long|null} [duration] Duration duration
                                 */

                                /**
                                 * Constructs a new Duration.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Duration.
                                 * @implements IDuration
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration=} [properties] Properties to set
                                 */
                                function Duration(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Duration duration.
                                 * @member {number|Long} duration
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @instance
                                 */
                                Duration.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * Creates a new Duration instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Duration} Duration instance
                                 */
                                Duration.create = function create(properties) {
                                    return new Duration(properties);
                                };

                                /**
                                 * Encodes the specified Duration message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Duration.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration} message Duration message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Duration.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.duration);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Duration message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Duration.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IDuration} message Duration message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Duration.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Duration message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Duration} Duration
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Duration.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.duration = reader.int64();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Duration message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Duration} Duration
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Duration.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Duration message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Duration.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.duration != null && message.hasOwnProperty("duration"))
                                        if (!$util.isInteger(message.duration) && !(message.duration && $util.isInteger(message.duration.low) && $util.isInteger(message.duration.high)))
                                            return "duration: integer|Long expected";
                                    return null;
                                };

                                /**
                                 * Creates a Duration message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Duration} Duration
                                 */
                                Duration.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Duration();
                                    if (object.duration != null)
                                        if ($util.Long)
                                            (message.duration = $util.Long.fromValue(object.duration)).unsigned = false;
                                        else if (typeof object.duration === "string")
                                            message.duration = parseInt(object.duration, 10);
                                        else if (typeof object.duration === "number")
                                            message.duration = object.duration;
                                        else if (typeof object.duration === "object")
                                            message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber();
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Duration message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Duration} message Duration
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Duration.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults)
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.duration = options.longs === String ? "0" : 0;
                                    if (message.duration != null && message.hasOwnProperty("duration"))
                                        if (typeof message.duration === "number")
                                            object.duration = options.longs === String ? String(message.duration) : message.duration;
                                        else
                                            object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber() : message.duration;
                                    return object;
                                };

                                /**
                                 * Converts this Duration to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Duration
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Duration.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Duration;
                            })();

                            v1.ExportOptions = (function() {

                                /**
                                 * Properties of an ExportOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IExportOptions
                                 * @property {boolean|null} ["export"] ExportOptions export
                                 * @property {boolean|null} [exact] ExportOptions exact
                                 */

                                /**
                                 * Constructs a new ExportOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents an ExportOptions.
                                 * @implements IExportOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IExportOptions=} [properties] Properties to set
                                 */
                                function ExportOptions(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ExportOptions export.
                                 * @member {boolean} export
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @instance
                                 */
                                ExportOptions.prototype["export"] = false;

                                /**
                                 * ExportOptions exact.
                                 * @member {boolean} exact
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @instance
                                 */
                                ExportOptions.prototype.exact = false;

                                /**
                                 * Creates a new ExportOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IExportOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions} ExportOptions instance
                                 */
                                ExportOptions.create = function create(properties) {
                                    return new ExportOptions(properties);
                                };

                                /**
                                 * Encodes the specified ExportOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IExportOptions} message ExportOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ExportOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message["export"] != null && Object.hasOwnProperty.call(message, "export"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message["export"]);
                                    if (message.exact != null && Object.hasOwnProperty.call(message, "exact"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.exact);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ExportOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IExportOptions} message ExportOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ExportOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes an ExportOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions} ExportOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ExportOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message["export"] = reader.bool();
                                            break;
                                        case 2:
                                            message.exact = reader.bool();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes an ExportOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions} ExportOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ExportOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies an ExportOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ExportOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message["export"] != null && message.hasOwnProperty("export"))
                                        if (typeof message["export"] !== "boolean")
                                            return "export: boolean expected";
                                    if (message.exact != null && message.hasOwnProperty("exact"))
                                        if (typeof message.exact !== "boolean")
                                            return "exact: boolean expected";
                                    return null;
                                };

                                /**
                                 * Creates an ExportOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions} ExportOptions
                                 */
                                ExportOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions();
                                    if (object["export"] != null)
                                        message["export"] = Boolean(object["export"]);
                                    if (object.exact != null)
                                        message.exact = Boolean(object.exact);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from an ExportOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions} message ExportOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ExportOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object["export"] = false;
                                        object.exact = false;
                                    }
                                    if (message["export"] != null && message.hasOwnProperty("export"))
                                        object["export"] = message["export"];
                                    if (message.exact != null && message.hasOwnProperty("exact"))
                                        object.exact = message.exact;
                                    return object;
                                };

                                /**
                                 * Converts this ExportOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ExportOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ExportOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ExportOptions;
                            })();

                            v1.FieldsV1 = (function() {

                                /**
                                 * Properties of a FieldsV1.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IFieldsV1
                                 * @property {Uint8Array|null} [Raw] FieldsV1 Raw
                                 */

                                /**
                                 * Constructs a new FieldsV1.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a FieldsV1.
                                 * @implements IFieldsV1
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IFieldsV1=} [properties] Properties to set
                                 */
                                function FieldsV1(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * FieldsV1 Raw.
                                 * @member {Uint8Array} Raw
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @instance
                                 */
                                FieldsV1.prototype.Raw = $util.newBuffer([]);

                                /**
                                 * Creates a new FieldsV1 instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IFieldsV1=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1} FieldsV1 instance
                                 */
                                FieldsV1.create = function create(properties) {
                                    return new FieldsV1(properties);
                                };

                                /**
                                 * Encodes the specified FieldsV1 message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IFieldsV1} message FieldsV1 message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                FieldsV1.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.Raw != null && Object.hasOwnProperty.call(message, "Raw"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.Raw);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified FieldsV1 message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IFieldsV1} message FieldsV1 message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                FieldsV1.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a FieldsV1 message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1} FieldsV1
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                FieldsV1.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.Raw = reader.bytes();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a FieldsV1 message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1} FieldsV1
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                FieldsV1.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a FieldsV1 message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                FieldsV1.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.Raw != null && message.hasOwnProperty("Raw"))
                                        if (!(message.Raw && typeof message.Raw.length === "number" || $util.isString(message.Raw)))
                                            return "Raw: buffer expected";
                                    return null;
                                };

                                /**
                                 * Creates a FieldsV1 message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1} FieldsV1
                                 */
                                FieldsV1.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1();
                                    if (object.Raw != null)
                                        if (typeof object.Raw === "string")
                                            $util.base64.decode(object.Raw, message.Raw = $util.newBuffer($util.base64.length(object.Raw)), 0);
                                        else if (object.Raw.length)
                                            message.Raw = object.Raw;
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a FieldsV1 message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1} message FieldsV1
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                FieldsV1.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults)
                                        if (options.bytes === String)
                                            object.Raw = "";
                                        else {
                                            object.Raw = [];
                                            if (options.bytes !== Array)
                                                object.Raw = $util.newBuffer(object.Raw);
                                        }
                                    if (message.Raw != null && message.hasOwnProperty("Raw"))
                                        object.Raw = options.bytes === String ? $util.base64.encode(message.Raw, 0, message.Raw.length) : options.bytes === Array ? Array.prototype.slice.call(message.Raw) : message.Raw;
                                    return object;
                                };

                                /**
                                 * Converts this FieldsV1 to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                FieldsV1.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return FieldsV1;
                            })();

                            v1.GetOptions = (function() {

                                /**
                                 * Properties of a GetOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGetOptions
                                 * @property {string|null} [resourceVersion] GetOptions resourceVersion
                                 */

                                /**
                                 * Constructs a new GetOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GetOptions.
                                 * @implements IGetOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGetOptions=} [properties] Properties to set
                                 */
                                function GetOptions(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GetOptions resourceVersion.
                                 * @member {string} resourceVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @instance
                                 */
                                GetOptions.prototype.resourceVersion = "";

                                /**
                                 * Creates a new GetOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGetOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions} GetOptions instance
                                 */
                                GetOptions.create = function create(properties) {
                                    return new GetOptions(properties);
                                };

                                /**
                                 * Encodes the specified GetOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGetOptions} message GetOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GetOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.resourceVersion);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GetOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGetOptions} message GetOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GetOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GetOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions} GetOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GetOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.resourceVersion = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GetOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions} GetOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GetOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GetOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GetOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        if (!$util.isString(message.resourceVersion))
                                            return "resourceVersion: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GetOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions} GetOptions
                                 */
                                GetOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions();
                                    if (object.resourceVersion != null)
                                        message.resourceVersion = String(object.resourceVersion);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GetOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions} message GetOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GetOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults)
                                        object.resourceVersion = "";
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        object.resourceVersion = message.resourceVersion;
                                    return object;
                                };

                                /**
                                 * Converts this GetOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GetOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GetOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GetOptions;
                            })();

                            v1.GroupKind = (function() {

                                /**
                                 * Properties of a GroupKind.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGroupKind
                                 * @property {string|null} [group] GroupKind group
                                 * @property {string|null} [kind] GroupKind kind
                                 */

                                /**
                                 * Constructs a new GroupKind.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GroupKind.
                                 * @implements IGroupKind
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupKind=} [properties] Properties to set
                                 */
                                function GroupKind(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GroupKind group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @instance
                                 */
                                GroupKind.prototype.group = "";

                                /**
                                 * GroupKind kind.
                                 * @member {string} kind
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @instance
                                 */
                                GroupKind.prototype.kind = "";

                                /**
                                 * Creates a new GroupKind instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupKind=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind} GroupKind instance
                                 */
                                GroupKind.create = function create(properties) {
                                    return new GroupKind(properties);
                                };

                                /**
                                 * Encodes the specified GroupKind message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupKind} message GroupKind message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupKind.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.group);
                                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.kind);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GroupKind message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupKind} message GroupKind message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupKind.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GroupKind message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind} GroupKind
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupKind.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.group = reader.string();
                                            break;
                                        case 2:
                                            message.kind = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GroupKind message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind} GroupKind
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupKind.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GroupKind message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GroupKind.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        if (!$util.isString(message.kind))
                                            return "kind: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GroupKind message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind} GroupKind
                                 */
                                GroupKind.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind();
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.kind != null)
                                        message.kind = String(object.kind);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GroupKind message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind} message GroupKind
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GroupKind.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.group = "";
                                        object.kind = "";
                                    }
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        object.kind = message.kind;
                                    return object;
                                };

                                /**
                                 * Converts this GroupKind to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupKind
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GroupKind.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GroupKind;
                            })();

                            v1.GroupResource = (function() {

                                /**
                                 * Properties of a GroupResource.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGroupResource
                                 * @property {string|null} [group] GroupResource group
                                 * @property {string|null} [resource] GroupResource resource
                                 */

                                /**
                                 * Constructs a new GroupResource.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GroupResource.
                                 * @implements IGroupResource
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupResource=} [properties] Properties to set
                                 */
                                function GroupResource(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GroupResource group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @instance
                                 */
                                GroupResource.prototype.group = "";

                                /**
                                 * GroupResource resource.
                                 * @member {string} resource
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @instance
                                 */
                                GroupResource.prototype.resource = "";

                                /**
                                 * Creates a new GroupResource instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupResource=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource} GroupResource instance
                                 */
                                GroupResource.create = function create(properties) {
                                    return new GroupResource(properties);
                                };

                                /**
                                 * Encodes the specified GroupResource message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupResource} message GroupResource message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupResource.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.group);
                                    if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.resource);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GroupResource message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupResource} message GroupResource message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupResource.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GroupResource message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource} GroupResource
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupResource.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.group = reader.string();
                                            break;
                                        case 2:
                                            message.resource = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GroupResource message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource} GroupResource
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupResource.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GroupResource message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GroupResource.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.resource != null && message.hasOwnProperty("resource"))
                                        if (!$util.isString(message.resource))
                                            return "resource: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GroupResource message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource} GroupResource
                                 */
                                GroupResource.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource();
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.resource != null)
                                        message.resource = String(object.resource);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GroupResource message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource} message GroupResource
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GroupResource.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.group = "";
                                        object.resource = "";
                                    }
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.resource != null && message.hasOwnProperty("resource"))
                                        object.resource = message.resource;
                                    return object;
                                };

                                /**
                                 * Converts this GroupResource to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupResource
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GroupResource.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GroupResource;
                            })();

                            v1.GroupVersion = (function() {

                                /**
                                 * Properties of a GroupVersion.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGroupVersion
                                 * @property {string|null} [group] GroupVersion group
                                 * @property {string|null} [version] GroupVersion version
                                 */

                                /**
                                 * Constructs a new GroupVersion.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GroupVersion.
                                 * @implements IGroupVersion
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersion=} [properties] Properties to set
                                 */
                                function GroupVersion(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GroupVersion group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @instance
                                 */
                                GroupVersion.prototype.group = "";

                                /**
                                 * GroupVersion version.
                                 * @member {string} version
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @instance
                                 */
                                GroupVersion.prototype.version = "";

                                /**
                                 * Creates a new GroupVersion instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersion=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion} GroupVersion instance
                                 */
                                GroupVersion.create = function create(properties) {
                                    return new GroupVersion(properties);
                                };

                                /**
                                 * Encodes the specified GroupVersion message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersion} message GroupVersion message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersion.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.group);
                                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GroupVersion message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersion} message GroupVersion message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersion.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GroupVersion message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion} GroupVersion
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersion.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.group = reader.string();
                                            break;
                                        case 2:
                                            message.version = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GroupVersion message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion} GroupVersion
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersion.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GroupVersion message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GroupVersion.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        if (!$util.isString(message.version))
                                            return "version: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GroupVersion message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion} GroupVersion
                                 */
                                GroupVersion.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion();
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.version != null)
                                        message.version = String(object.version);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GroupVersion message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion} message GroupVersion
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GroupVersion.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.group = "";
                                        object.version = "";
                                    }
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        object.version = message.version;
                                    return object;
                                };

                                /**
                                 * Converts this GroupVersion to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersion
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GroupVersion.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GroupVersion;
                            })();

                            v1.GroupVersionForDiscovery = (function() {

                                /**
                                 * Properties of a GroupVersionForDiscovery.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGroupVersionForDiscovery
                                 * @property {string|null} [groupVersion] GroupVersionForDiscovery groupVersion
                                 * @property {string|null} [version] GroupVersionForDiscovery version
                                 */

                                /**
                                 * Constructs a new GroupVersionForDiscovery.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GroupVersionForDiscovery.
                                 * @implements IGroupVersionForDiscovery
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery=} [properties] Properties to set
                                 */
                                function GroupVersionForDiscovery(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GroupVersionForDiscovery groupVersion.
                                 * @member {string} groupVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @instance
                                 */
                                GroupVersionForDiscovery.prototype.groupVersion = "";

                                /**
                                 * GroupVersionForDiscovery version.
                                 * @member {string} version
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @instance
                                 */
                                GroupVersionForDiscovery.prototype.version = "";

                                /**
                                 * Creates a new GroupVersionForDiscovery instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery} GroupVersionForDiscovery instance
                                 */
                                GroupVersionForDiscovery.create = function create(properties) {
                                    return new GroupVersionForDiscovery(properties);
                                };

                                /**
                                 * Encodes the specified GroupVersionForDiscovery message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery} message GroupVersionForDiscovery message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersionForDiscovery.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.groupVersion != null && Object.hasOwnProperty.call(message, "groupVersion"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupVersion);
                                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GroupVersionForDiscovery message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionForDiscovery} message GroupVersionForDiscovery message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersionForDiscovery.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GroupVersionForDiscovery message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery} GroupVersionForDiscovery
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersionForDiscovery.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.groupVersion = reader.string();
                                            break;
                                        case 2:
                                            message.version = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GroupVersionForDiscovery message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery} GroupVersionForDiscovery
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersionForDiscovery.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GroupVersionForDiscovery message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GroupVersionForDiscovery.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.groupVersion != null && message.hasOwnProperty("groupVersion"))
                                        if (!$util.isString(message.groupVersion))
                                            return "groupVersion: string expected";
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        if (!$util.isString(message.version))
                                            return "version: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GroupVersionForDiscovery message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery} GroupVersionForDiscovery
                                 */
                                GroupVersionForDiscovery.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery();
                                    if (object.groupVersion != null)
                                        message.groupVersion = String(object.groupVersion);
                                    if (object.version != null)
                                        message.version = String(object.version);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GroupVersionForDiscovery message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery} message GroupVersionForDiscovery
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GroupVersionForDiscovery.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.groupVersion = "";
                                        object.version = "";
                                    }
                                    if (message.groupVersion != null && message.hasOwnProperty("groupVersion"))
                                        object.groupVersion = message.groupVersion;
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        object.version = message.version;
                                    return object;
                                };

                                /**
                                 * Converts this GroupVersionForDiscovery to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionForDiscovery
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GroupVersionForDiscovery.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GroupVersionForDiscovery;
                            })();

                            v1.GroupVersionKind = (function() {

                                /**
                                 * Properties of a GroupVersionKind.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGroupVersionKind
                                 * @property {string|null} [group] GroupVersionKind group
                                 * @property {string|null} [version] GroupVersionKind version
                                 * @property {string|null} [kind] GroupVersionKind kind
                                 */

                                /**
                                 * Constructs a new GroupVersionKind.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GroupVersionKind.
                                 * @implements IGroupVersionKind
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionKind=} [properties] Properties to set
                                 */
                                function GroupVersionKind(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GroupVersionKind group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @instance
                                 */
                                GroupVersionKind.prototype.group = "";

                                /**
                                 * GroupVersionKind version.
                                 * @member {string} version
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @instance
                                 */
                                GroupVersionKind.prototype.version = "";

                                /**
                                 * GroupVersionKind kind.
                                 * @member {string} kind
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @instance
                                 */
                                GroupVersionKind.prototype.kind = "";

                                /**
                                 * Creates a new GroupVersionKind instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionKind=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind} GroupVersionKind instance
                                 */
                                GroupVersionKind.create = function create(properties) {
                                    return new GroupVersionKind(properties);
                                };

                                /**
                                 * Encodes the specified GroupVersionKind message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionKind} message GroupVersionKind message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersionKind.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.group);
                                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.kind);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GroupVersionKind message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionKind} message GroupVersionKind message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersionKind.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GroupVersionKind message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind} GroupVersionKind
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersionKind.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.group = reader.string();
                                            break;
                                        case 2:
                                            message.version = reader.string();
                                            break;
                                        case 3:
                                            message.kind = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GroupVersionKind message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind} GroupVersionKind
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersionKind.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GroupVersionKind message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GroupVersionKind.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        if (!$util.isString(message.version))
                                            return "version: string expected";
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        if (!$util.isString(message.kind))
                                            return "kind: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GroupVersionKind message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind} GroupVersionKind
                                 */
                                GroupVersionKind.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind();
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.version != null)
                                        message.version = String(object.version);
                                    if (object.kind != null)
                                        message.kind = String(object.kind);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GroupVersionKind message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind} message GroupVersionKind
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GroupVersionKind.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.group = "";
                                        object.version = "";
                                        object.kind = "";
                                    }
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        object.version = message.version;
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        object.kind = message.kind;
                                    return object;
                                };

                                /**
                                 * Converts this GroupVersionKind to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionKind
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GroupVersionKind.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GroupVersionKind;
                            })();

                            v1.GroupVersionResource = (function() {

                                /**
                                 * Properties of a GroupVersionResource.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IGroupVersionResource
                                 * @property {string|null} [group] GroupVersionResource group
                                 * @property {string|null} [version] GroupVersionResource version
                                 * @property {string|null} [resource] GroupVersionResource resource
                                 */

                                /**
                                 * Constructs a new GroupVersionResource.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a GroupVersionResource.
                                 * @implements IGroupVersionResource
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionResource=} [properties] Properties to set
                                 */
                                function GroupVersionResource(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * GroupVersionResource group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @instance
                                 */
                                GroupVersionResource.prototype.group = "";

                                /**
                                 * GroupVersionResource version.
                                 * @member {string} version
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @instance
                                 */
                                GroupVersionResource.prototype.version = "";

                                /**
                                 * GroupVersionResource resource.
                                 * @member {string} resource
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @instance
                                 */
                                GroupVersionResource.prototype.resource = "";

                                /**
                                 * Creates a new GroupVersionResource instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionResource=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource} GroupVersionResource instance
                                 */
                                GroupVersionResource.create = function create(properties) {
                                    return new GroupVersionResource(properties);
                                };

                                /**
                                 * Encodes the specified GroupVersionResource message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionResource} message GroupVersionResource message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersionResource.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.group);
                                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
                                    if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.resource);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified GroupVersionResource message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IGroupVersionResource} message GroupVersionResource message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                GroupVersionResource.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a GroupVersionResource message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource} GroupVersionResource
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersionResource.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.group = reader.string();
                                            break;
                                        case 2:
                                            message.version = reader.string();
                                            break;
                                        case 3:
                                            message.resource = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a GroupVersionResource message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource} GroupVersionResource
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                GroupVersionResource.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a GroupVersionResource message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                GroupVersionResource.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        if (!$util.isString(message.version))
                                            return "version: string expected";
                                    if (message.resource != null && message.hasOwnProperty("resource"))
                                        if (!$util.isString(message.resource))
                                            return "resource: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a GroupVersionResource message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource} GroupVersionResource
                                 */
                                GroupVersionResource.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource();
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.version != null)
                                        message.version = String(object.version);
                                    if (object.resource != null)
                                        message.resource = String(object.resource);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a GroupVersionResource message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource} message GroupVersionResource
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                GroupVersionResource.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.group = "";
                                        object.version = "";
                                        object.resource = "";
                                    }
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.version != null && message.hasOwnProperty("version"))
                                        object.version = message.version;
                                    if (message.resource != null && message.hasOwnProperty("resource"))
                                        object.resource = message.resource;
                                    return object;
                                };

                                /**
                                 * Converts this GroupVersionResource to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.GroupVersionResource
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                GroupVersionResource.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return GroupVersionResource;
                            })();

                            v1.LabelSelector = (function() {

                                /**
                                 * Properties of a LabelSelector.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ILabelSelector
                                 * @property {Object.<string,string>|null} [matchLabels] LabelSelector matchLabels
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelectorRequirement>|null} [matchExpressions] LabelSelector matchExpressions
                                 */

                                /**
                                 * Constructs a new LabelSelector.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a LabelSelector.
                                 * @implements ILabelSelector
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector=} [properties] Properties to set
                                 */
                                function LabelSelector(properties) {
                                    this.matchLabels = {};
                                    this.matchExpressions = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * LabelSelector matchLabels.
                                 * @member {Object.<string,string>} matchLabels
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @instance
                                 */
                                LabelSelector.prototype.matchLabels = $util.emptyObject;

                                /**
                                 * LabelSelector matchExpressions.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelectorRequirement>} matchExpressions
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @instance
                                 */
                                LabelSelector.prototype.matchExpressions = $util.emptyArray;

                                /**
                                 * Creates a new LabelSelector instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector} LabelSelector instance
                                 */
                                LabelSelector.create = function create(properties) {
                                    return new LabelSelector(properties);
                                };

                                /**
                                 * Encodes the specified LabelSelector message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector} message LabelSelector message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                LabelSelector.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.matchLabels != null && Object.hasOwnProperty.call(message, "matchLabels"))
                                        for (var keys = Object.keys(message.matchLabels), i = 0; i < keys.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.matchLabels[keys[i]]).ldelim();
                                    if (message.matchExpressions != null && message.matchExpressions.length)
                                        for (var i = 0; i < message.matchExpressions.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.encode(message.matchExpressions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified LabelSelector message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelector} message LabelSelector message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                LabelSelector.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a LabelSelector message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector} LabelSelector
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                LabelSelector.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector(), key, value;
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (message.matchLabels === $util.emptyObject)
                                                message.matchLabels = {};
                                            var end2 = reader.uint32() + reader.pos;
                                            key = "";
                                            value = "";
                                            while (reader.pos < end2) {
                                                var tag2 = reader.uint32();
                                                switch (tag2 >>> 3) {
                                                case 1:
                                                    key = reader.string();
                                                    break;
                                                case 2:
                                                    value = reader.string();
                                                    break;
                                                default:
                                                    reader.skipType(tag2 & 7);
                                                    break;
                                                }
                                            }
                                            message.matchLabels[key] = value;
                                            break;
                                        case 2:
                                            if (!(message.matchExpressions && message.matchExpressions.length))
                                                message.matchExpressions = [];
                                            message.matchExpressions.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a LabelSelector message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector} LabelSelector
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                LabelSelector.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a LabelSelector message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                LabelSelector.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.matchLabels != null && message.hasOwnProperty("matchLabels")) {
                                        if (!$util.isObject(message.matchLabels))
                                            return "matchLabels: object expected";
                                        var key = Object.keys(message.matchLabels);
                                        for (var i = 0; i < key.length; ++i)
                                            if (!$util.isString(message.matchLabels[key[i]]))
                                                return "matchLabels: string{k:string} expected";
                                    }
                                    if (message.matchExpressions != null && message.hasOwnProperty("matchExpressions")) {
                                        if (!Array.isArray(message.matchExpressions))
                                            return "matchExpressions: array expected";
                                        for (var i = 0; i < message.matchExpressions.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.verify(message.matchExpressions[i]);
                                            if (error)
                                                return "matchExpressions." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a LabelSelector message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector} LabelSelector
                                 */
                                LabelSelector.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector();
                                    if (object.matchLabels) {
                                        if (typeof object.matchLabels !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.matchLabels: object expected");
                                        message.matchLabels = {};
                                        for (var keys = Object.keys(object.matchLabels), i = 0; i < keys.length; ++i)
                                            message.matchLabels[keys[i]] = String(object.matchLabels[keys[i]]);
                                    }
                                    if (object.matchExpressions) {
                                        if (!Array.isArray(object.matchExpressions))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.matchExpressions: array expected");
                                        message.matchExpressions = [];
                                        for (var i = 0; i < object.matchExpressions.length; ++i) {
                                            if (typeof object.matchExpressions[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector.matchExpressions: object expected");
                                            message.matchExpressions[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.fromObject(object.matchExpressions[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a LabelSelector message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector} message LabelSelector
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                LabelSelector.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.matchExpressions = [];
                                    if (options.objects || options.defaults)
                                        object.matchLabels = {};
                                    var keys2;
                                    if (message.matchLabels && (keys2 = Object.keys(message.matchLabels)).length) {
                                        object.matchLabels = {};
                                        for (var j = 0; j < keys2.length; ++j)
                                            object.matchLabels[keys2[j]] = message.matchLabels[keys2[j]];
                                    }
                                    if (message.matchExpressions && message.matchExpressions.length) {
                                        object.matchExpressions = [];
                                        for (var j = 0; j < message.matchExpressions.length; ++j)
                                            object.matchExpressions[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.toObject(message.matchExpressions[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this LabelSelector to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelector
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                LabelSelector.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return LabelSelector;
                            })();

                            v1.LabelSelectorRequirement = (function() {

                                /**
                                 * Properties of a LabelSelectorRequirement.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ILabelSelectorRequirement
                                 * @property {string|null} [key] LabelSelectorRequirement key
                                 * @property {string|null} [operator] LabelSelectorRequirement operator
                                 * @property {Array.<string>|null} [values] LabelSelectorRequirement values
                                 */

                                /**
                                 * Constructs a new LabelSelectorRequirement.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a LabelSelectorRequirement.
                                 * @implements ILabelSelectorRequirement
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelectorRequirement=} [properties] Properties to set
                                 */
                                function LabelSelectorRequirement(properties) {
                                    this.values = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * LabelSelectorRequirement key.
                                 * @member {string} key
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @instance
                                 */
                                LabelSelectorRequirement.prototype.key = "";

                                /**
                                 * LabelSelectorRequirement operator.
                                 * @member {string} operator
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @instance
                                 */
                                LabelSelectorRequirement.prototype.operator = "";

                                /**
                                 * LabelSelectorRequirement values.
                                 * @member {Array.<string>} values
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @instance
                                 */
                                LabelSelectorRequirement.prototype.values = $util.emptyArray;

                                /**
                                 * Creates a new LabelSelectorRequirement instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelectorRequirement=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement} LabelSelectorRequirement instance
                                 */
                                LabelSelectorRequirement.create = function create(properties) {
                                    return new LabelSelectorRequirement(properties);
                                };

                                /**
                                 * Encodes the specified LabelSelectorRequirement message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelectorRequirement} message LabelSelectorRequirement message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                LabelSelectorRequirement.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                                    if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.operator);
                                    if (message.values != null && message.values.length)
                                        for (var i = 0; i < message.values.length; ++i)
                                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.values[i]);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified LabelSelectorRequirement message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ILabelSelectorRequirement} message LabelSelectorRequirement message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                LabelSelectorRequirement.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a LabelSelectorRequirement message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement} LabelSelectorRequirement
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                LabelSelectorRequirement.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.key = reader.string();
                                            break;
                                        case 2:
                                            message.operator = reader.string();
                                            break;
                                        case 3:
                                            if (!(message.values && message.values.length))
                                                message.values = [];
                                            message.values.push(reader.string());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a LabelSelectorRequirement message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement} LabelSelectorRequirement
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                LabelSelectorRequirement.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a LabelSelectorRequirement message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                LabelSelectorRequirement.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.key != null && message.hasOwnProperty("key"))
                                        if (!$util.isString(message.key))
                                            return "key: string expected";
                                    if (message.operator != null && message.hasOwnProperty("operator"))
                                        if (!$util.isString(message.operator))
                                            return "operator: string expected";
                                    if (message.values != null && message.hasOwnProperty("values")) {
                                        if (!Array.isArray(message.values))
                                            return "values: array expected";
                                        for (var i = 0; i < message.values.length; ++i)
                                            if (!$util.isString(message.values[i]))
                                                return "values: string[] expected";
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a LabelSelectorRequirement message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement} LabelSelectorRequirement
                                 */
                                LabelSelectorRequirement.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement();
                                    if (object.key != null)
                                        message.key = String(object.key);
                                    if (object.operator != null)
                                        message.operator = String(object.operator);
                                    if (object.values) {
                                        if (!Array.isArray(object.values))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement.values: array expected");
                                        message.values = [];
                                        for (var i = 0; i < object.values.length; ++i)
                                            message.values[i] = String(object.values[i]);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a LabelSelectorRequirement message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement} message LabelSelectorRequirement
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                LabelSelectorRequirement.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.values = [];
                                    if (options.defaults) {
                                        object.key = "";
                                        object.operator = "";
                                    }
                                    if (message.key != null && message.hasOwnProperty("key"))
                                        object.key = message.key;
                                    if (message.operator != null && message.hasOwnProperty("operator"))
                                        object.operator = message.operator;
                                    if (message.values && message.values.length) {
                                        object.values = [];
                                        for (var j = 0; j < message.values.length; ++j)
                                            object.values[j] = message.values[j];
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this LabelSelectorRequirement to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.LabelSelectorRequirement
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                LabelSelectorRequirement.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return LabelSelectorRequirement;
                            })();

                            v1.List = (function() {

                                /**
                                 * Properties of a List.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IList
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] List metadata
                                 * @property {Array.<k8s.io.apimachinery.pkg.runtime.IRawExtension>|null} [items] List items
                                 */

                                /**
                                 * Constructs a new List.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a List.
                                 * @implements IList
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IList=} [properties] Properties to set
                                 */
                                function List(properties) {
                                    this.items = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * List metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @instance
                                 */
                                List.prototype.metadata = null;

                                /**
                                 * List items.
                                 * @member {Array.<k8s.io.apimachinery.pkg.runtime.IRawExtension>} items
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @instance
                                 */
                                List.prototype.items = $util.emptyArray;

                                /**
                                 * Creates a new List instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IList=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.List} List instance
                                 */
                                List.create = function create(properties) {
                                    return new List(properties);
                                };

                                /**
                                 * Encodes the specified List message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.List.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IList} message List message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                List.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.items != null && message.items.length)
                                        for (var i = 0; i < message.items.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.runtime.RawExtension.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified List message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.List.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IList} message List message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                List.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a List message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.List} List
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                List.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.List();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            if (!(message.items && message.items.length))
                                                message.items = [];
                                            message.items.push($root.k8s.io.apimachinery.pkg.runtime.RawExtension.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a List message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.List} List
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                List.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a List message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                List.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.items != null && message.hasOwnProperty("items")) {
                                        if (!Array.isArray(message.items))
                                            return "items: array expected";
                                        for (var i = 0; i < message.items.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.verify(message.items[i]);
                                            if (error)
                                                return "items." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a List message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.List} List
                                 */
                                List.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.List)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.List();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.List.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                                    }
                                    if (object.items) {
                                        if (!Array.isArray(object.items))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.List.items: array expected");
                                        message.items = [];
                                        for (var i = 0; i < object.items.length; ++i) {
                                            if (typeof object.items[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.List.items: object expected");
                                            message.items[i] = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.fromObject(object.items[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a List message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.List} message List
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                List.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.items = [];
                                    if (options.defaults)
                                        object.metadata = null;
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                                    if (message.items && message.items.length) {
                                        object.items = [];
                                        for (var j = 0; j < message.items.length; ++j)
                                            object.items[j] = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.toObject(message.items[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this List to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.List
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                List.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return List;
                            })();

                            v1.ListMeta = (function() {

                                /**
                                 * Properties of a ListMeta.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IListMeta
                                 * @property {string|null} [selfLink] ListMeta selfLink
                                 * @property {string|null} [resourceVersion] ListMeta resourceVersion
                                 * @property {string|null} ["continue"] ListMeta continue
                                 * @property {number|Long|null} [remainingItemCount] ListMeta remainingItemCount
                                 */

                                /**
                                 * Constructs a new ListMeta.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a ListMeta.
                                 * @implements IListMeta
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta=} [properties] Properties to set
                                 */
                                function ListMeta(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ListMeta selfLink.
                                 * @member {string} selfLink
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @instance
                                 */
                                ListMeta.prototype.selfLink = "";

                                /**
                                 * ListMeta resourceVersion.
                                 * @member {string} resourceVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @instance
                                 */
                                ListMeta.prototype.resourceVersion = "";

                                /**
                                 * ListMeta continue.
                                 * @member {string} continue
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @instance
                                 */
                                ListMeta.prototype["continue"] = "";

                                /**
                                 * ListMeta remainingItemCount.
                                 * @member {number|Long} remainingItemCount
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @instance
                                 */
                                ListMeta.prototype.remainingItemCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * Creates a new ListMeta instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta} ListMeta instance
                                 */
                                ListMeta.create = function create(properties) {
                                    return new ListMeta(properties);
                                };

                                /**
                                 * Encodes the specified ListMeta message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta} message ListMeta message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ListMeta.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.selfLink != null && Object.hasOwnProperty.call(message, "selfLink"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.selfLink);
                                    if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.resourceVersion);
                                    if (message["continue"] != null && Object.hasOwnProperty.call(message, "continue"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message["continue"]);
                                    if (message.remainingItemCount != null && Object.hasOwnProperty.call(message, "remainingItemCount"))
                                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.remainingItemCount);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ListMeta message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta} message ListMeta message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ListMeta.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a ListMeta message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta} ListMeta
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ListMeta.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.selfLink = reader.string();
                                            break;
                                        case 2:
                                            message.resourceVersion = reader.string();
                                            break;
                                        case 3:
                                            message["continue"] = reader.string();
                                            break;
                                        case 4:
                                            message.remainingItemCount = reader.int64();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a ListMeta message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta} ListMeta
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ListMeta.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a ListMeta message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ListMeta.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.selfLink != null && message.hasOwnProperty("selfLink"))
                                        if (!$util.isString(message.selfLink))
                                            return "selfLink: string expected";
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        if (!$util.isString(message.resourceVersion))
                                            return "resourceVersion: string expected";
                                    if (message["continue"] != null && message.hasOwnProperty("continue"))
                                        if (!$util.isString(message["continue"]))
                                            return "continue: string expected";
                                    if (message.remainingItemCount != null && message.hasOwnProperty("remainingItemCount"))
                                        if (!$util.isInteger(message.remainingItemCount) && !(message.remainingItemCount && $util.isInteger(message.remainingItemCount.low) && $util.isInteger(message.remainingItemCount.high)))
                                            return "remainingItemCount: integer|Long expected";
                                    return null;
                                };

                                /**
                                 * Creates a ListMeta message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta} ListMeta
                                 */
                                ListMeta.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta();
                                    if (object.selfLink != null)
                                        message.selfLink = String(object.selfLink);
                                    if (object.resourceVersion != null)
                                        message.resourceVersion = String(object.resourceVersion);
                                    if (object["continue"] != null)
                                        message["continue"] = String(object["continue"]);
                                    if (object.remainingItemCount != null)
                                        if ($util.Long)
                                            (message.remainingItemCount = $util.Long.fromValue(object.remainingItemCount)).unsigned = false;
                                        else if (typeof object.remainingItemCount === "string")
                                            message.remainingItemCount = parseInt(object.remainingItemCount, 10);
                                        else if (typeof object.remainingItemCount === "number")
                                            message.remainingItemCount = object.remainingItemCount;
                                        else if (typeof object.remainingItemCount === "object")
                                            message.remainingItemCount = new $util.LongBits(object.remainingItemCount.low >>> 0, object.remainingItemCount.high >>> 0).toNumber();
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a ListMeta message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta} message ListMeta
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ListMeta.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.selfLink = "";
                                        object.resourceVersion = "";
                                        object["continue"] = "";
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.remainingItemCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.remainingItemCount = options.longs === String ? "0" : 0;
                                    }
                                    if (message.selfLink != null && message.hasOwnProperty("selfLink"))
                                        object.selfLink = message.selfLink;
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        object.resourceVersion = message.resourceVersion;
                                    if (message["continue"] != null && message.hasOwnProperty("continue"))
                                        object["continue"] = message["continue"];
                                    if (message.remainingItemCount != null && message.hasOwnProperty("remainingItemCount"))
                                        if (typeof message.remainingItemCount === "number")
                                            object.remainingItemCount = options.longs === String ? String(message.remainingItemCount) : message.remainingItemCount;
                                        else
                                            object.remainingItemCount = options.longs === String ? $util.Long.prototype.toString.call(message.remainingItemCount) : options.longs === Number ? new $util.LongBits(message.remainingItemCount.low >>> 0, message.remainingItemCount.high >>> 0).toNumber() : message.remainingItemCount;
                                    return object;
                                };

                                /**
                                 * Converts this ListMeta to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ListMeta.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ListMeta;
                            })();

                            v1.ListOptions = (function() {

                                /**
                                 * Properties of a ListOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IListOptions
                                 * @property {string|null} [labelSelector] ListOptions labelSelector
                                 * @property {string|null} [fieldSelector] ListOptions fieldSelector
                                 * @property {boolean|null} [watch] ListOptions watch
                                 * @property {boolean|null} [allowWatchBookmarks] ListOptions allowWatchBookmarks
                                 * @property {string|null} [resourceVersion] ListOptions resourceVersion
                                 * @property {string|null} [resourceVersionMatch] ListOptions resourceVersionMatch
                                 * @property {number|Long|null} [timeoutSeconds] ListOptions timeoutSeconds
                                 * @property {number|Long|null} [limit] ListOptions limit
                                 * @property {string|null} ["continue"] ListOptions continue
                                 */

                                /**
                                 * Constructs a new ListOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a ListOptions.
                                 * @implements IListOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListOptions=} [properties] Properties to set
                                 */
                                function ListOptions(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ListOptions labelSelector.
                                 * @member {string} labelSelector
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.labelSelector = "";

                                /**
                                 * ListOptions fieldSelector.
                                 * @member {string} fieldSelector
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.fieldSelector = "";

                                /**
                                 * ListOptions watch.
                                 * @member {boolean} watch
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.watch = false;

                                /**
                                 * ListOptions allowWatchBookmarks.
                                 * @member {boolean} allowWatchBookmarks
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.allowWatchBookmarks = false;

                                /**
                                 * ListOptions resourceVersion.
                                 * @member {string} resourceVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.resourceVersion = "";

                                /**
                                 * ListOptions resourceVersionMatch.
                                 * @member {string} resourceVersionMatch
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.resourceVersionMatch = "";

                                /**
                                 * ListOptions timeoutSeconds.
                                 * @member {number|Long} timeoutSeconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.timeoutSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * ListOptions limit.
                                 * @member {number|Long} limit
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * ListOptions continue.
                                 * @member {string} continue
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 */
                                ListOptions.prototype["continue"] = "";

                                /**
                                 * Creates a new ListOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions} ListOptions instance
                                 */
                                ListOptions.create = function create(properties) {
                                    return new ListOptions(properties);
                                };

                                /**
                                 * Encodes the specified ListOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListOptions} message ListOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ListOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.labelSelector != null && Object.hasOwnProperty.call(message, "labelSelector"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.labelSelector);
                                    if (message.fieldSelector != null && Object.hasOwnProperty.call(message, "fieldSelector"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fieldSelector);
                                    if (message.watch != null && Object.hasOwnProperty.call(message, "watch"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.watch);
                                    if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.resourceVersion);
                                    if (message.timeoutSeconds != null && Object.hasOwnProperty.call(message, "timeoutSeconds"))
                                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.timeoutSeconds);
                                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.limit);
                                    if (message["continue"] != null && Object.hasOwnProperty.call(message, "continue"))
                                        writer.uint32(/* id 8, wireType 2 =*/66).string(message["continue"]);
                                    if (message.allowWatchBookmarks != null && Object.hasOwnProperty.call(message, "allowWatchBookmarks"))
                                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.allowWatchBookmarks);
                                    if (message.resourceVersionMatch != null && Object.hasOwnProperty.call(message, "resourceVersionMatch"))
                                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.resourceVersionMatch);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ListOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IListOptions} message ListOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ListOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a ListOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions} ListOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ListOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.labelSelector = reader.string();
                                            break;
                                        case 2:
                                            message.fieldSelector = reader.string();
                                            break;
                                        case 3:
                                            message.watch = reader.bool();
                                            break;
                                        case 9:
                                            message.allowWatchBookmarks = reader.bool();
                                            break;
                                        case 4:
                                            message.resourceVersion = reader.string();
                                            break;
                                        case 10:
                                            message.resourceVersionMatch = reader.string();
                                            break;
                                        case 5:
                                            message.timeoutSeconds = reader.int64();
                                            break;
                                        case 7:
                                            message.limit = reader.int64();
                                            break;
                                        case 8:
                                            message["continue"] = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a ListOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions} ListOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ListOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a ListOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ListOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.labelSelector != null && message.hasOwnProperty("labelSelector"))
                                        if (!$util.isString(message.labelSelector))
                                            return "labelSelector: string expected";
                                    if (message.fieldSelector != null && message.hasOwnProperty("fieldSelector"))
                                        if (!$util.isString(message.fieldSelector))
                                            return "fieldSelector: string expected";
                                    if (message.watch != null && message.hasOwnProperty("watch"))
                                        if (typeof message.watch !== "boolean")
                                            return "watch: boolean expected";
                                    if (message.allowWatchBookmarks != null && message.hasOwnProperty("allowWatchBookmarks"))
                                        if (typeof message.allowWatchBookmarks !== "boolean")
                                            return "allowWatchBookmarks: boolean expected";
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        if (!$util.isString(message.resourceVersion))
                                            return "resourceVersion: string expected";
                                    if (message.resourceVersionMatch != null && message.hasOwnProperty("resourceVersionMatch"))
                                        if (!$util.isString(message.resourceVersionMatch))
                                            return "resourceVersionMatch: string expected";
                                    if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                                        if (!$util.isInteger(message.timeoutSeconds) && !(message.timeoutSeconds && $util.isInteger(message.timeoutSeconds.low) && $util.isInteger(message.timeoutSeconds.high)))
                                            return "timeoutSeconds: integer|Long expected";
                                    if (message.limit != null && message.hasOwnProperty("limit"))
                                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                                            return "limit: integer|Long expected";
                                    if (message["continue"] != null && message.hasOwnProperty("continue"))
                                        if (!$util.isString(message["continue"]))
                                            return "continue: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a ListOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions} ListOptions
                                 */
                                ListOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions();
                                    if (object.labelSelector != null)
                                        message.labelSelector = String(object.labelSelector);
                                    if (object.fieldSelector != null)
                                        message.fieldSelector = String(object.fieldSelector);
                                    if (object.watch != null)
                                        message.watch = Boolean(object.watch);
                                    if (object.allowWatchBookmarks != null)
                                        message.allowWatchBookmarks = Boolean(object.allowWatchBookmarks);
                                    if (object.resourceVersion != null)
                                        message.resourceVersion = String(object.resourceVersion);
                                    if (object.resourceVersionMatch != null)
                                        message.resourceVersionMatch = String(object.resourceVersionMatch);
                                    if (object.timeoutSeconds != null)
                                        if ($util.Long)
                                            (message.timeoutSeconds = $util.Long.fromValue(object.timeoutSeconds)).unsigned = false;
                                        else if (typeof object.timeoutSeconds === "string")
                                            message.timeoutSeconds = parseInt(object.timeoutSeconds, 10);
                                        else if (typeof object.timeoutSeconds === "number")
                                            message.timeoutSeconds = object.timeoutSeconds;
                                        else if (typeof object.timeoutSeconds === "object")
                                            message.timeoutSeconds = new $util.LongBits(object.timeoutSeconds.low >>> 0, object.timeoutSeconds.high >>> 0).toNumber();
                                    if (object.limit != null)
                                        if ($util.Long)
                                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                                        else if (typeof object.limit === "string")
                                            message.limit = parseInt(object.limit, 10);
                                        else if (typeof object.limit === "number")
                                            message.limit = object.limit;
                                        else if (typeof object.limit === "object")
                                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                                    if (object["continue"] != null)
                                        message["continue"] = String(object["continue"]);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a ListOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions} message ListOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ListOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.labelSelector = "";
                                        object.fieldSelector = "";
                                        object.watch = false;
                                        object.resourceVersion = "";
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.timeoutSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.timeoutSeconds = options.longs === String ? "0" : 0;
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.limit = options.longs === String ? "0" : 0;
                                        object["continue"] = "";
                                        object.allowWatchBookmarks = false;
                                        object.resourceVersionMatch = "";
                                    }
                                    if (message.labelSelector != null && message.hasOwnProperty("labelSelector"))
                                        object.labelSelector = message.labelSelector;
                                    if (message.fieldSelector != null && message.hasOwnProperty("fieldSelector"))
                                        object.fieldSelector = message.fieldSelector;
                                    if (message.watch != null && message.hasOwnProperty("watch"))
                                        object.watch = message.watch;
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        object.resourceVersion = message.resourceVersion;
                                    if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                                        if (typeof message.timeoutSeconds === "number")
                                            object.timeoutSeconds = options.longs === String ? String(message.timeoutSeconds) : message.timeoutSeconds;
                                        else
                                            object.timeoutSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.timeoutSeconds) : options.longs === Number ? new $util.LongBits(message.timeoutSeconds.low >>> 0, message.timeoutSeconds.high >>> 0).toNumber() : message.timeoutSeconds;
                                    if (message.limit != null && message.hasOwnProperty("limit"))
                                        if (typeof message.limit === "number")
                                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                                        else
                                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                                    if (message["continue"] != null && message.hasOwnProperty("continue"))
                                        object["continue"] = message["continue"];
                                    if (message.allowWatchBookmarks != null && message.hasOwnProperty("allowWatchBookmarks"))
                                        object.allowWatchBookmarks = message.allowWatchBookmarks;
                                    if (message.resourceVersionMatch != null && message.hasOwnProperty("resourceVersionMatch"))
                                        object.resourceVersionMatch = message.resourceVersionMatch;
                                    return object;
                                };

                                /**
                                 * Converts this ListOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ListOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ListOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ListOptions;
                            })();

                            v1.ManagedFieldsEntry = (function() {

                                /**
                                 * Properties of a ManagedFieldsEntry.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IManagedFieldsEntry
                                 * @property {string|null} [manager] ManagedFieldsEntry manager
                                 * @property {string|null} [operation] ManagedFieldsEntry operation
                                 * @property {string|null} [apiVersion] ManagedFieldsEntry apiVersion
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [time] ManagedFieldsEntry time
                                 * @property {string|null} [fieldsType] ManagedFieldsEntry fieldsType
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IFieldsV1|null} [fieldsV1] ManagedFieldsEntry fieldsV1
                                 */

                                /**
                                 * Constructs a new ManagedFieldsEntry.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a ManagedFieldsEntry.
                                 * @implements IManagedFieldsEntry
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IManagedFieldsEntry=} [properties] Properties to set
                                 */
                                function ManagedFieldsEntry(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ManagedFieldsEntry manager.
                                 * @member {string} manager
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 */
                                ManagedFieldsEntry.prototype.manager = "";

                                /**
                                 * ManagedFieldsEntry operation.
                                 * @member {string} operation
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 */
                                ManagedFieldsEntry.prototype.operation = "";

                                /**
                                 * ManagedFieldsEntry apiVersion.
                                 * @member {string} apiVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 */
                                ManagedFieldsEntry.prototype.apiVersion = "";

                                /**
                                 * ManagedFieldsEntry time.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} time
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 */
                                ManagedFieldsEntry.prototype.time = null;

                                /**
                                 * ManagedFieldsEntry fieldsType.
                                 * @member {string} fieldsType
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 */
                                ManagedFieldsEntry.prototype.fieldsType = "";

                                /**
                                 * ManagedFieldsEntry fieldsV1.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IFieldsV1|null|undefined} fieldsV1
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 */
                                ManagedFieldsEntry.prototype.fieldsV1 = null;

                                /**
                                 * Creates a new ManagedFieldsEntry instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IManagedFieldsEntry=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry} ManagedFieldsEntry instance
                                 */
                                ManagedFieldsEntry.create = function create(properties) {
                                    return new ManagedFieldsEntry(properties);
                                };

                                /**
                                 * Encodes the specified ManagedFieldsEntry message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IManagedFieldsEntry} message ManagedFieldsEntry message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ManagedFieldsEntry.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.manager != null && Object.hasOwnProperty.call(message, "manager"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.manager);
                                    if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.operation);
                                    if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.apiVersion);
                                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.time, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    if (message.fieldsType != null && Object.hasOwnProperty.call(message, "fieldsType"))
                                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.fieldsType);
                                    if (message.fieldsV1 != null && Object.hasOwnProperty.call(message, "fieldsV1"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.encode(message.fieldsV1, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ManagedFieldsEntry message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IManagedFieldsEntry} message ManagedFieldsEntry message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ManagedFieldsEntry.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a ManagedFieldsEntry message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry} ManagedFieldsEntry
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ManagedFieldsEntry.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.manager = reader.string();
                                            break;
                                        case 2:
                                            message.operation = reader.string();
                                            break;
                                        case 3:
                                            message.apiVersion = reader.string();
                                            break;
                                        case 4:
                                            message.time = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                            break;
                                        case 6:
                                            message.fieldsType = reader.string();
                                            break;
                                        case 7:
                                            message.fieldsV1 = $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a ManagedFieldsEntry message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry} ManagedFieldsEntry
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ManagedFieldsEntry.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a ManagedFieldsEntry message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ManagedFieldsEntry.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.manager != null && message.hasOwnProperty("manager"))
                                        if (!$util.isString(message.manager))
                                            return "manager: string expected";
                                    if (message.operation != null && message.hasOwnProperty("operation"))
                                        if (!$util.isString(message.operation))
                                            return "operation: string expected";
                                    if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                        if (!$util.isString(message.apiVersion))
                                            return "apiVersion: string expected";
                                    if (message.time != null && message.hasOwnProperty("time")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.time);
                                        if (error)
                                            return "time." + error;
                                    }
                                    if (message.fieldsType != null && message.hasOwnProperty("fieldsType"))
                                        if (!$util.isString(message.fieldsType))
                                            return "fieldsType: string expected";
                                    if (message.fieldsV1 != null && message.hasOwnProperty("fieldsV1")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.verify(message.fieldsV1);
                                        if (error)
                                            return "fieldsV1." + error;
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a ManagedFieldsEntry message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry} ManagedFieldsEntry
                                 */
                                ManagedFieldsEntry.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry();
                                    if (object.manager != null)
                                        message.manager = String(object.manager);
                                    if (object.operation != null)
                                        message.operation = String(object.operation);
                                    if (object.apiVersion != null)
                                        message.apiVersion = String(object.apiVersion);
                                    if (object.time != null) {
                                        if (typeof object.time !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.time: object expected");
                                        message.time = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.time);
                                    }
                                    if (object.fieldsType != null)
                                        message.fieldsType = String(object.fieldsType);
                                    if (object.fieldsV1 != null) {
                                        if (typeof object.fieldsV1 !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.fieldsV1: object expected");
                                        message.fieldsV1 = $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.fromObject(object.fieldsV1);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a ManagedFieldsEntry message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry} message ManagedFieldsEntry
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ManagedFieldsEntry.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.manager = "";
                                        object.operation = "";
                                        object.apiVersion = "";
                                        object.time = null;
                                        object.fieldsType = "";
                                        object.fieldsV1 = null;
                                    }
                                    if (message.manager != null && message.hasOwnProperty("manager"))
                                        object.manager = message.manager;
                                    if (message.operation != null && message.hasOwnProperty("operation"))
                                        object.operation = message.operation;
                                    if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                        object.apiVersion = message.apiVersion;
                                    if (message.time != null && message.hasOwnProperty("time"))
                                        object.time = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.time, options);
                                    if (message.fieldsType != null && message.hasOwnProperty("fieldsType"))
                                        object.fieldsType = message.fieldsType;
                                    if (message.fieldsV1 != null && message.hasOwnProperty("fieldsV1"))
                                        object.fieldsV1 = $root.k8s.io.apimachinery.pkg.apis.meta.v1.FieldsV1.toObject(message.fieldsV1, options);
                                    return object;
                                };

                                /**
                                 * Converts this ManagedFieldsEntry to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ManagedFieldsEntry.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ManagedFieldsEntry;
                            })();

                            v1.MicroTime = (function() {

                                /**
                                 * Properties of a MicroTime.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IMicroTime
                                 * @property {number|Long|null} [seconds] MicroTime seconds
                                 * @property {number|null} [nanos] MicroTime nanos
                                 */

                                /**
                                 * Constructs a new MicroTime.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a MicroTime.
                                 * @implements IMicroTime
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime=} [properties] Properties to set
                                 */
                                function MicroTime(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * MicroTime seconds.
                                 * @member {number|Long} seconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @instance
                                 */
                                MicroTime.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * MicroTime nanos.
                                 * @member {number} nanos
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @instance
                                 */
                                MicroTime.prototype.nanos = 0;

                                /**
                                 * Creates a new MicroTime instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime} MicroTime instance
                                 */
                                MicroTime.create = function create(properties) {
                                    return new MicroTime(properties);
                                };

                                /**
                                 * Encodes the specified MicroTime message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime} message MicroTime message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                MicroTime.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified MicroTime message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IMicroTime} message MicroTime message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                MicroTime.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a MicroTime message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime} MicroTime
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                MicroTime.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.seconds = reader.int64();
                                            break;
                                        case 2:
                                            message.nanos = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a MicroTime message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime} MicroTime
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                MicroTime.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a MicroTime message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                MicroTime.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                                            return "seconds: integer|Long expected";
                                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                                        if (!$util.isInteger(message.nanos))
                                            return "nanos: integer expected";
                                    return null;
                                };

                                /**
                                 * Creates a MicroTime message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime} MicroTime
                                 */
                                MicroTime.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime();
                                    if (object.seconds != null)
                                        if ($util.Long)
                                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                                        else if (typeof object.seconds === "string")
                                            message.seconds = parseInt(object.seconds, 10);
                                        else if (typeof object.seconds === "number")
                                            message.seconds = object.seconds;
                                        else if (typeof object.seconds === "object")
                                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                                    if (object.nanos != null)
                                        message.nanos = object.nanos | 0;
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a MicroTime message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime} message MicroTime
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                MicroTime.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.seconds = options.longs === String ? "0" : 0;
                                        object.nanos = 0;
                                    }
                                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                                        if (typeof message.seconds === "number")
                                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                                        else
                                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                                        object.nanos = message.nanos;
                                    return object;
                                };

                                /**
                                 * Converts this MicroTime to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.MicroTime
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                MicroTime.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return MicroTime;
                            })();

                            v1.ObjectMeta = (function() {

                                /**
                                 * Properties of an ObjectMeta.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IObjectMeta
                                 * @property {string|null} [name] ObjectMeta name
                                 * @property {string|null} [generateName] ObjectMeta generateName
                                 * @property {string|null} [namespace] ObjectMeta namespace
                                 * @property {string|null} [selfLink] ObjectMeta selfLink
                                 * @property {string|null} [uid] ObjectMeta uid
                                 * @property {string|null} [resourceVersion] ObjectMeta resourceVersion
                                 * @property {number|Long|null} [generation] ObjectMeta generation
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [creationTimestamp] ObjectMeta creationTimestamp
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null} [deletionTimestamp] ObjectMeta deletionTimestamp
                                 * @property {number|Long|null} [deletionGracePeriodSeconds] ObjectMeta deletionGracePeriodSeconds
                                 * @property {Object.<string,string>|null} [labels] ObjectMeta labels
                                 * @property {Object.<string,string>|null} [annotations] ObjectMeta annotations
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference>|null} [ownerReferences] ObjectMeta ownerReferences
                                 * @property {Array.<string>|null} [finalizers] ObjectMeta finalizers
                                 * @property {string|null} [clusterName] ObjectMeta clusterName
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IManagedFieldsEntry>|null} [managedFields] ObjectMeta managedFields
                                 */

                                /**
                                 * Constructs a new ObjectMeta.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents an ObjectMeta.
                                 * @implements IObjectMeta
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta=} [properties] Properties to set
                                 */
                                function ObjectMeta(properties) {
                                    this.labels = {};
                                    this.annotations = {};
                                    this.ownerReferences = [];
                                    this.finalizers = [];
                                    this.managedFields = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ObjectMeta name.
                                 * @member {string} name
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.name = "";

                                /**
                                 * ObjectMeta generateName.
                                 * @member {string} generateName
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.generateName = "";

                                /**
                                 * ObjectMeta namespace.
                                 * @member {string} namespace
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.namespace = "";

                                /**
                                 * ObjectMeta selfLink.
                                 * @member {string} selfLink
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.selfLink = "";

                                /**
                                 * ObjectMeta uid.
                                 * @member {string} uid
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.uid = "";

                                /**
                                 * ObjectMeta resourceVersion.
                                 * @member {string} resourceVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.resourceVersion = "";

                                /**
                                 * ObjectMeta generation.
                                 * @member {number|Long} generation
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.generation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * ObjectMeta creationTimestamp.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} creationTimestamp
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.creationTimestamp = null;

                                /**
                                 * ObjectMeta deletionTimestamp.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.ITime|null|undefined} deletionTimestamp
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.deletionTimestamp = null;

                                /**
                                 * ObjectMeta deletionGracePeriodSeconds.
                                 * @member {number|Long} deletionGracePeriodSeconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.deletionGracePeriodSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * ObjectMeta labels.
                                 * @member {Object.<string,string>} labels
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.labels = $util.emptyObject;

                                /**
                                 * ObjectMeta annotations.
                                 * @member {Object.<string,string>} annotations
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.annotations = $util.emptyObject;

                                /**
                                 * ObjectMeta ownerReferences.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference>} ownerReferences
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.ownerReferences = $util.emptyArray;

                                /**
                                 * ObjectMeta finalizers.
                                 * @member {Array.<string>} finalizers
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.finalizers = $util.emptyArray;

                                /**
                                 * ObjectMeta clusterName.
                                 * @member {string} clusterName
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.clusterName = "";

                                /**
                                 * ObjectMeta managedFields.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IManagedFieldsEntry>} managedFields
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 */
                                ObjectMeta.prototype.managedFields = $util.emptyArray;

                                /**
                                 * Creates a new ObjectMeta instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta} ObjectMeta instance
                                 */
                                ObjectMeta.create = function create(properties) {
                                    return new ObjectMeta(properties);
                                };

                                /**
                                 * Encodes the specified ObjectMeta message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta} message ObjectMeta message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ObjectMeta.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                                    if (message.generateName != null && Object.hasOwnProperty.call(message, "generateName"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.generateName);
                                    if (message.namespace != null && Object.hasOwnProperty.call(message, "namespace"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.namespace);
                                    if (message.selfLink != null && Object.hasOwnProperty.call(message, "selfLink"))
                                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.selfLink);
                                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.uid);
                                    if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.resourceVersion);
                                    if (message.generation != null && Object.hasOwnProperty.call(message, "generation"))
                                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.generation);
                                    if (message.creationTimestamp != null && Object.hasOwnProperty.call(message, "creationTimestamp"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.creationTimestamp, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                                    if (message.deletionTimestamp != null && Object.hasOwnProperty.call(message, "deletionTimestamp"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.encode(message.deletionTimestamp, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                                    if (message.deletionGracePeriodSeconds != null && Object.hasOwnProperty.call(message, "deletionGracePeriodSeconds"))
                                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.deletionGracePeriodSeconds);
                                    if (message.labels != null && Object.hasOwnProperty.call(message, "labels"))
                                        for (var keys = Object.keys(message.labels), i = 0; i < keys.length; ++i)
                                            writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.labels[keys[i]]).ldelim();
                                    if (message.annotations != null && Object.hasOwnProperty.call(message, "annotations"))
                                        for (var keys = Object.keys(message.annotations), i = 0; i < keys.length; ++i)
                                            writer.uint32(/* id 12, wireType 2 =*/98).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.annotations[keys[i]]).ldelim();
                                    if (message.ownerReferences != null && message.ownerReferences.length)
                                        for (var i = 0; i < message.ownerReferences.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.encode(message.ownerReferences[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                                    if (message.finalizers != null && message.finalizers.length)
                                        for (var i = 0; i < message.finalizers.length; ++i)
                                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.finalizers[i]);
                                    if (message.clusterName != null && Object.hasOwnProperty.call(message, "clusterName"))
                                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.clusterName);
                                    if (message.managedFields != null && message.managedFields.length)
                                        for (var i = 0; i < message.managedFields.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.encode(message.managedFields[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ObjectMeta message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta} message ObjectMeta message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ObjectMeta.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes an ObjectMeta message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta} ObjectMeta
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ObjectMeta.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta(), key, value;
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.name = reader.string();
                                            break;
                                        case 2:
                                            message.generateName = reader.string();
                                            break;
                                        case 3:
                                            message.namespace = reader.string();
                                            break;
                                        case 4:
                                            message.selfLink = reader.string();
                                            break;
                                        case 5:
                                            message.uid = reader.string();
                                            break;
                                        case 6:
                                            message.resourceVersion = reader.string();
                                            break;
                                        case 7:
                                            message.generation = reader.int64();
                                            break;
                                        case 8:
                                            message.creationTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                            break;
                                        case 9:
                                            message.deletionTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.decode(reader, reader.uint32());
                                            break;
                                        case 10:
                                            message.deletionGracePeriodSeconds = reader.int64();
                                            break;
                                        case 11:
                                            if (message.labels === $util.emptyObject)
                                                message.labels = {};
                                            var end2 = reader.uint32() + reader.pos;
                                            key = "";
                                            value = "";
                                            while (reader.pos < end2) {
                                                var tag2 = reader.uint32();
                                                switch (tag2 >>> 3) {
                                                case 1:
                                                    key = reader.string();
                                                    break;
                                                case 2:
                                                    value = reader.string();
                                                    break;
                                                default:
                                                    reader.skipType(tag2 & 7);
                                                    break;
                                                }
                                            }
                                            message.labels[key] = value;
                                            break;
                                        case 12:
                                            if (message.annotations === $util.emptyObject)
                                                message.annotations = {};
                                            var end2 = reader.uint32() + reader.pos;
                                            key = "";
                                            value = "";
                                            while (reader.pos < end2) {
                                                var tag2 = reader.uint32();
                                                switch (tag2 >>> 3) {
                                                case 1:
                                                    key = reader.string();
                                                    break;
                                                case 2:
                                                    value = reader.string();
                                                    break;
                                                default:
                                                    reader.skipType(tag2 & 7);
                                                    break;
                                                }
                                            }
                                            message.annotations[key] = value;
                                            break;
                                        case 13:
                                            if (!(message.ownerReferences && message.ownerReferences.length))
                                                message.ownerReferences = [];
                                            message.ownerReferences.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.decode(reader, reader.uint32()));
                                            break;
                                        case 14:
                                            if (!(message.finalizers && message.finalizers.length))
                                                message.finalizers = [];
                                            message.finalizers.push(reader.string());
                                            break;
                                        case 15:
                                            message.clusterName = reader.string();
                                            break;
                                        case 17:
                                            if (!(message.managedFields && message.managedFields.length))
                                                message.managedFields = [];
                                            message.managedFields.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes an ObjectMeta message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta} ObjectMeta
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ObjectMeta.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies an ObjectMeta message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ObjectMeta.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        if (!$util.isString(message.name))
                                            return "name: string expected";
                                    if (message.generateName != null && message.hasOwnProperty("generateName"))
                                        if (!$util.isString(message.generateName))
                                            return "generateName: string expected";
                                    if (message.namespace != null && message.hasOwnProperty("namespace"))
                                        if (!$util.isString(message.namespace))
                                            return "namespace: string expected";
                                    if (message.selfLink != null && message.hasOwnProperty("selfLink"))
                                        if (!$util.isString(message.selfLink))
                                            return "selfLink: string expected";
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        if (!$util.isString(message.uid))
                                            return "uid: string expected";
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        if (!$util.isString(message.resourceVersion))
                                            return "resourceVersion: string expected";
                                    if (message.generation != null && message.hasOwnProperty("generation"))
                                        if (!$util.isInteger(message.generation) && !(message.generation && $util.isInteger(message.generation.low) && $util.isInteger(message.generation.high)))
                                            return "generation: integer|Long expected";
                                    if (message.creationTimestamp != null && message.hasOwnProperty("creationTimestamp")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.creationTimestamp);
                                        if (error)
                                            return "creationTimestamp." + error;
                                    }
                                    if (message.deletionTimestamp != null && message.hasOwnProperty("deletionTimestamp")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify(message.deletionTimestamp);
                                        if (error)
                                            return "deletionTimestamp." + error;
                                    }
                                    if (message.deletionGracePeriodSeconds != null && message.hasOwnProperty("deletionGracePeriodSeconds"))
                                        if (!$util.isInteger(message.deletionGracePeriodSeconds) && !(message.deletionGracePeriodSeconds && $util.isInteger(message.deletionGracePeriodSeconds.low) && $util.isInteger(message.deletionGracePeriodSeconds.high)))
                                            return "deletionGracePeriodSeconds: integer|Long expected";
                                    if (message.labels != null && message.hasOwnProperty("labels")) {
                                        if (!$util.isObject(message.labels))
                                            return "labels: object expected";
                                        var key = Object.keys(message.labels);
                                        for (var i = 0; i < key.length; ++i)
                                            if (!$util.isString(message.labels[key[i]]))
                                                return "labels: string{k:string} expected";
                                    }
                                    if (message.annotations != null && message.hasOwnProperty("annotations")) {
                                        if (!$util.isObject(message.annotations))
                                            return "annotations: object expected";
                                        var key = Object.keys(message.annotations);
                                        for (var i = 0; i < key.length; ++i)
                                            if (!$util.isString(message.annotations[key[i]]))
                                                return "annotations: string{k:string} expected";
                                    }
                                    if (message.ownerReferences != null && message.hasOwnProperty("ownerReferences")) {
                                        if (!Array.isArray(message.ownerReferences))
                                            return "ownerReferences: array expected";
                                        for (var i = 0; i < message.ownerReferences.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.verify(message.ownerReferences[i]);
                                            if (error)
                                                return "ownerReferences." + error;
                                        }
                                    }
                                    if (message.finalizers != null && message.hasOwnProperty("finalizers")) {
                                        if (!Array.isArray(message.finalizers))
                                            return "finalizers: array expected";
                                        for (var i = 0; i < message.finalizers.length; ++i)
                                            if (!$util.isString(message.finalizers[i]))
                                                return "finalizers: string[] expected";
                                    }
                                    if (message.clusterName != null && message.hasOwnProperty("clusterName"))
                                        if (!$util.isString(message.clusterName))
                                            return "clusterName: string expected";
                                    if (message.managedFields != null && message.hasOwnProperty("managedFields")) {
                                        if (!Array.isArray(message.managedFields))
                                            return "managedFields: array expected";
                                        for (var i = 0; i < message.managedFields.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.verify(message.managedFields[i]);
                                            if (error)
                                                return "managedFields." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates an ObjectMeta message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta} ObjectMeta
                                 */
                                ObjectMeta.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta();
                                    if (object.name != null)
                                        message.name = String(object.name);
                                    if (object.generateName != null)
                                        message.generateName = String(object.generateName);
                                    if (object.namespace != null)
                                        message.namespace = String(object.namespace);
                                    if (object.selfLink != null)
                                        message.selfLink = String(object.selfLink);
                                    if (object.uid != null)
                                        message.uid = String(object.uid);
                                    if (object.resourceVersion != null)
                                        message.resourceVersion = String(object.resourceVersion);
                                    if (object.generation != null)
                                        if ($util.Long)
                                            (message.generation = $util.Long.fromValue(object.generation)).unsigned = false;
                                        else if (typeof object.generation === "string")
                                            message.generation = parseInt(object.generation, 10);
                                        else if (typeof object.generation === "number")
                                            message.generation = object.generation;
                                        else if (typeof object.generation === "object")
                                            message.generation = new $util.LongBits(object.generation.low >>> 0, object.generation.high >>> 0).toNumber();
                                    if (object.creationTimestamp != null) {
                                        if (typeof object.creationTimestamp !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.creationTimestamp: object expected");
                                        message.creationTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.creationTimestamp);
                                    }
                                    if (object.deletionTimestamp != null) {
                                        if (typeof object.deletionTimestamp !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.deletionTimestamp: object expected");
                                        message.deletionTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.fromObject(object.deletionTimestamp);
                                    }
                                    if (object.deletionGracePeriodSeconds != null)
                                        if ($util.Long)
                                            (message.deletionGracePeriodSeconds = $util.Long.fromValue(object.deletionGracePeriodSeconds)).unsigned = false;
                                        else if (typeof object.deletionGracePeriodSeconds === "string")
                                            message.deletionGracePeriodSeconds = parseInt(object.deletionGracePeriodSeconds, 10);
                                        else if (typeof object.deletionGracePeriodSeconds === "number")
                                            message.deletionGracePeriodSeconds = object.deletionGracePeriodSeconds;
                                        else if (typeof object.deletionGracePeriodSeconds === "object")
                                            message.deletionGracePeriodSeconds = new $util.LongBits(object.deletionGracePeriodSeconds.low >>> 0, object.deletionGracePeriodSeconds.high >>> 0).toNumber();
                                    if (object.labels) {
                                        if (typeof object.labels !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.labels: object expected");
                                        message.labels = {};
                                        for (var keys = Object.keys(object.labels), i = 0; i < keys.length; ++i)
                                            message.labels[keys[i]] = String(object.labels[keys[i]]);
                                    }
                                    if (object.annotations) {
                                        if (typeof object.annotations !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.annotations: object expected");
                                        message.annotations = {};
                                        for (var keys = Object.keys(object.annotations), i = 0; i < keys.length; ++i)
                                            message.annotations[keys[i]] = String(object.annotations[keys[i]]);
                                    }
                                    if (object.ownerReferences) {
                                        if (!Array.isArray(object.ownerReferences))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.ownerReferences: array expected");
                                        message.ownerReferences = [];
                                        for (var i = 0; i < object.ownerReferences.length; ++i) {
                                            if (typeof object.ownerReferences[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.ownerReferences: object expected");
                                            message.ownerReferences[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.fromObject(object.ownerReferences[i]);
                                        }
                                    }
                                    if (object.finalizers) {
                                        if (!Array.isArray(object.finalizers))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.finalizers: array expected");
                                        message.finalizers = [];
                                        for (var i = 0; i < object.finalizers.length; ++i)
                                            message.finalizers[i] = String(object.finalizers[i]);
                                    }
                                    if (object.clusterName != null)
                                        message.clusterName = String(object.clusterName);
                                    if (object.managedFields) {
                                        if (!Array.isArray(object.managedFields))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.managedFields: array expected");
                                        message.managedFields = [];
                                        for (var i = 0; i < object.managedFields.length; ++i) {
                                            if (typeof object.managedFields[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.managedFields: object expected");
                                            message.managedFields[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.fromObject(object.managedFields[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from an ObjectMeta message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta} message ObjectMeta
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ObjectMeta.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults) {
                                        object.ownerReferences = [];
                                        object.finalizers = [];
                                        object.managedFields = [];
                                    }
                                    if (options.objects || options.defaults) {
                                        object.labels = {};
                                        object.annotations = {};
                                    }
                                    if (options.defaults) {
                                        object.name = "";
                                        object.generateName = "";
                                        object.namespace = "";
                                        object.selfLink = "";
                                        object.uid = "";
                                        object.resourceVersion = "";
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.generation = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.generation = options.longs === String ? "0" : 0;
                                        object.creationTimestamp = null;
                                        object.deletionTimestamp = null;
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.deletionGracePeriodSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.deletionGracePeriodSeconds = options.longs === String ? "0" : 0;
                                        object.clusterName = "";
                                    }
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        object.name = message.name;
                                    if (message.generateName != null && message.hasOwnProperty("generateName"))
                                        object.generateName = message.generateName;
                                    if (message.namespace != null && message.hasOwnProperty("namespace"))
                                        object.namespace = message.namespace;
                                    if (message.selfLink != null && message.hasOwnProperty("selfLink"))
                                        object.selfLink = message.selfLink;
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        object.uid = message.uid;
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        object.resourceVersion = message.resourceVersion;
                                    if (message.generation != null && message.hasOwnProperty("generation"))
                                        if (typeof message.generation === "number")
                                            object.generation = options.longs === String ? String(message.generation) : message.generation;
                                        else
                                            object.generation = options.longs === String ? $util.Long.prototype.toString.call(message.generation) : options.longs === Number ? new $util.LongBits(message.generation.low >>> 0, message.generation.high >>> 0).toNumber() : message.generation;
                                    if (message.creationTimestamp != null && message.hasOwnProperty("creationTimestamp"))
                                        object.creationTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.creationTimestamp, options);
                                    if (message.deletionTimestamp != null && message.hasOwnProperty("deletionTimestamp"))
                                        object.deletionTimestamp = $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time.toObject(message.deletionTimestamp, options);
                                    if (message.deletionGracePeriodSeconds != null && message.hasOwnProperty("deletionGracePeriodSeconds"))
                                        if (typeof message.deletionGracePeriodSeconds === "number")
                                            object.deletionGracePeriodSeconds = options.longs === String ? String(message.deletionGracePeriodSeconds) : message.deletionGracePeriodSeconds;
                                        else
                                            object.deletionGracePeriodSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.deletionGracePeriodSeconds) : options.longs === Number ? new $util.LongBits(message.deletionGracePeriodSeconds.low >>> 0, message.deletionGracePeriodSeconds.high >>> 0).toNumber() : message.deletionGracePeriodSeconds;
                                    var keys2;
                                    if (message.labels && (keys2 = Object.keys(message.labels)).length) {
                                        object.labels = {};
                                        for (var j = 0; j < keys2.length; ++j)
                                            object.labels[keys2[j]] = message.labels[keys2[j]];
                                    }
                                    if (message.annotations && (keys2 = Object.keys(message.annotations)).length) {
                                        object.annotations = {};
                                        for (var j = 0; j < keys2.length; ++j)
                                            object.annotations[keys2[j]] = message.annotations[keys2[j]];
                                    }
                                    if (message.ownerReferences && message.ownerReferences.length) {
                                        object.ownerReferences = [];
                                        for (var j = 0; j < message.ownerReferences.length; ++j)
                                            object.ownerReferences[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.toObject(message.ownerReferences[j], options);
                                    }
                                    if (message.finalizers && message.finalizers.length) {
                                        object.finalizers = [];
                                        for (var j = 0; j < message.finalizers.length; ++j)
                                            object.finalizers[j] = message.finalizers[j];
                                    }
                                    if (message.clusterName != null && message.hasOwnProperty("clusterName"))
                                        object.clusterName = message.clusterName;
                                    if (message.managedFields && message.managedFields.length) {
                                        object.managedFields = [];
                                        for (var j = 0; j < message.managedFields.length; ++j)
                                            object.managedFields[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ManagedFieldsEntry.toObject(message.managedFields[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this ObjectMeta to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ObjectMeta.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ObjectMeta;
                            })();

                            v1.OwnerReference = (function() {

                                /**
                                 * Properties of an OwnerReference.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IOwnerReference
                                 * @property {string|null} [apiVersion] OwnerReference apiVersion
                                 * @property {string|null} [kind] OwnerReference kind
                                 * @property {string|null} [name] OwnerReference name
                                 * @property {string|null} [uid] OwnerReference uid
                                 * @property {boolean|null} [controller] OwnerReference controller
                                 * @property {boolean|null} [blockOwnerDeletion] OwnerReference blockOwnerDeletion
                                 */

                                /**
                                 * Constructs a new OwnerReference.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents an OwnerReference.
                                 * @implements IOwnerReference
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference=} [properties] Properties to set
                                 */
                                function OwnerReference(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * OwnerReference apiVersion.
                                 * @member {string} apiVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 */
                                OwnerReference.prototype.apiVersion = "";

                                /**
                                 * OwnerReference kind.
                                 * @member {string} kind
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 */
                                OwnerReference.prototype.kind = "";

                                /**
                                 * OwnerReference name.
                                 * @member {string} name
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 */
                                OwnerReference.prototype.name = "";

                                /**
                                 * OwnerReference uid.
                                 * @member {string} uid
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 */
                                OwnerReference.prototype.uid = "";

                                /**
                                 * OwnerReference controller.
                                 * @member {boolean} controller
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 */
                                OwnerReference.prototype.controller = false;

                                /**
                                 * OwnerReference blockOwnerDeletion.
                                 * @member {boolean} blockOwnerDeletion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 */
                                OwnerReference.prototype.blockOwnerDeletion = false;

                                /**
                                 * Creates a new OwnerReference instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference} OwnerReference instance
                                 */
                                OwnerReference.create = function create(properties) {
                                    return new OwnerReference(properties);
                                };

                                /**
                                 * Encodes the specified OwnerReference message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference} message OwnerReference message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                OwnerReference.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.kind);
                                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.uid);
                                    if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.apiVersion);
                                    if (message.controller != null && Object.hasOwnProperty.call(message, "controller"))
                                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.controller);
                                    if (message.blockOwnerDeletion != null && Object.hasOwnProperty.call(message, "blockOwnerDeletion"))
                                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.blockOwnerDeletion);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified OwnerReference message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IOwnerReference} message OwnerReference message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                OwnerReference.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes an OwnerReference message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference} OwnerReference
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                OwnerReference.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 5:
                                            message.apiVersion = reader.string();
                                            break;
                                        case 1:
                                            message.kind = reader.string();
                                            break;
                                        case 3:
                                            message.name = reader.string();
                                            break;
                                        case 4:
                                            message.uid = reader.string();
                                            break;
                                        case 6:
                                            message.controller = reader.bool();
                                            break;
                                        case 7:
                                            message.blockOwnerDeletion = reader.bool();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes an OwnerReference message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference} OwnerReference
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                OwnerReference.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies an OwnerReference message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                OwnerReference.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                        if (!$util.isString(message.apiVersion))
                                            return "apiVersion: string expected";
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        if (!$util.isString(message.kind))
                                            return "kind: string expected";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        if (!$util.isString(message.name))
                                            return "name: string expected";
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        if (!$util.isString(message.uid))
                                            return "uid: string expected";
                                    if (message.controller != null && message.hasOwnProperty("controller"))
                                        if (typeof message.controller !== "boolean")
                                            return "controller: boolean expected";
                                    if (message.blockOwnerDeletion != null && message.hasOwnProperty("blockOwnerDeletion"))
                                        if (typeof message.blockOwnerDeletion !== "boolean")
                                            return "blockOwnerDeletion: boolean expected";
                                    return null;
                                };

                                /**
                                 * Creates an OwnerReference message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference} OwnerReference
                                 */
                                OwnerReference.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference();
                                    if (object.apiVersion != null)
                                        message.apiVersion = String(object.apiVersion);
                                    if (object.kind != null)
                                        message.kind = String(object.kind);
                                    if (object.name != null)
                                        message.name = String(object.name);
                                    if (object.uid != null)
                                        message.uid = String(object.uid);
                                    if (object.controller != null)
                                        message.controller = Boolean(object.controller);
                                    if (object.blockOwnerDeletion != null)
                                        message.blockOwnerDeletion = Boolean(object.blockOwnerDeletion);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from an OwnerReference message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference} message OwnerReference
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                OwnerReference.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.kind = "";
                                        object.name = "";
                                        object.uid = "";
                                        object.apiVersion = "";
                                        object.controller = false;
                                        object.blockOwnerDeletion = false;
                                    }
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        object.kind = message.kind;
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        object.name = message.name;
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        object.uid = message.uid;
                                    if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                        object.apiVersion = message.apiVersion;
                                    if (message.controller != null && message.hasOwnProperty("controller"))
                                        object.controller = message.controller;
                                    if (message.blockOwnerDeletion != null && message.hasOwnProperty("blockOwnerDeletion"))
                                        object.blockOwnerDeletion = message.blockOwnerDeletion;
                                    return object;
                                };

                                /**
                                 * Converts this OwnerReference to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.OwnerReference
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                OwnerReference.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return OwnerReference;
                            })();

                            v1.PartialObjectMetadata = (function() {

                                /**
                                 * Properties of a PartialObjectMetadata.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IPartialObjectMetadata
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null} [metadata] PartialObjectMetadata metadata
                                 */

                                /**
                                 * Constructs a new PartialObjectMetadata.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a PartialObjectMetadata.
                                 * @implements IPartialObjectMetadata
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadata=} [properties] Properties to set
                                 */
                                function PartialObjectMetadata(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * PartialObjectMetadata metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IObjectMeta|null|undefined} metadata
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @instance
                                 */
                                PartialObjectMetadata.prototype.metadata = null;

                                /**
                                 * Creates a new PartialObjectMetadata instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadata=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata} PartialObjectMetadata instance
                                 */
                                PartialObjectMetadata.create = function create(properties) {
                                    return new PartialObjectMetadata(properties);
                                };

                                /**
                                 * Encodes the specified PartialObjectMetadata message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadata} message PartialObjectMetadata message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PartialObjectMetadata.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified PartialObjectMetadata message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadata} message PartialObjectMetadata message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PartialObjectMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a PartialObjectMetadata message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata} PartialObjectMetadata
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PartialObjectMetadata.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a PartialObjectMetadata message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata} PartialObjectMetadata
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PartialObjectMetadata.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a PartialObjectMetadata message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                PartialObjectMetadata.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a PartialObjectMetadata message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata} PartialObjectMetadata
                                 */
                                PartialObjectMetadata.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.fromObject(object.metadata);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a PartialObjectMetadata message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata} message PartialObjectMetadata
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                PartialObjectMetadata.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults)
                                        object.metadata = null;
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ObjectMeta.toObject(message.metadata, options);
                                    return object;
                                };

                                /**
                                 * Converts this PartialObjectMetadata to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                PartialObjectMetadata.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return PartialObjectMetadata;
                            })();

                            v1.PartialObjectMetadataList = (function() {

                                /**
                                 * Properties of a PartialObjectMetadataList.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IPartialObjectMetadataList
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] PartialObjectMetadataList metadata
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadata>|null} [items] PartialObjectMetadataList items
                                 */

                                /**
                                 * Constructs a new PartialObjectMetadataList.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a PartialObjectMetadataList.
                                 * @implements IPartialObjectMetadataList
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadataList=} [properties] Properties to set
                                 */
                                function PartialObjectMetadataList(properties) {
                                    this.items = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * PartialObjectMetadataList metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @instance
                                 */
                                PartialObjectMetadataList.prototype.metadata = null;

                                /**
                                 * PartialObjectMetadataList items.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadata>} items
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @instance
                                 */
                                PartialObjectMetadataList.prototype.items = $util.emptyArray;

                                /**
                                 * Creates a new PartialObjectMetadataList instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadataList=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList} PartialObjectMetadataList instance
                                 */
                                PartialObjectMetadataList.create = function create(properties) {
                                    return new PartialObjectMetadataList(properties);
                                };

                                /**
                                 * Encodes the specified PartialObjectMetadataList message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadataList} message PartialObjectMetadataList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PartialObjectMetadataList.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.items != null && message.items.length)
                                        for (var i = 0; i < message.items.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified PartialObjectMetadataList message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPartialObjectMetadataList} message PartialObjectMetadataList message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PartialObjectMetadataList.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a PartialObjectMetadataList message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList} PartialObjectMetadataList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PartialObjectMetadataList.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            if (!(message.items && message.items.length))
                                                message.items = [];
                                            message.items.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a PartialObjectMetadataList message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList} PartialObjectMetadataList
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PartialObjectMetadataList.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a PartialObjectMetadataList message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                PartialObjectMetadataList.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.items != null && message.hasOwnProperty("items")) {
                                        if (!Array.isArray(message.items))
                                            return "items: array expected";
                                        for (var i = 0; i < message.items.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.verify(message.items[i]);
                                            if (error)
                                                return "items." + error;
                                        }
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a PartialObjectMetadataList message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList} PartialObjectMetadataList
                                 */
                                PartialObjectMetadataList.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                                    }
                                    if (object.items) {
                                        if (!Array.isArray(object.items))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList.items: array expected");
                                        message.items = [];
                                        for (var i = 0; i < object.items.length; ++i) {
                                            if (typeof object.items[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList.items: object expected");
                                            message.items[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.fromObject(object.items[i]);
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a PartialObjectMetadataList message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList} message PartialObjectMetadataList
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                PartialObjectMetadataList.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.items = [];
                                    if (options.defaults)
                                        object.metadata = null;
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                                    if (message.items && message.items.length) {
                                        object.items = [];
                                        for (var j = 0; j < message.items.length; ++j)
                                            object.items[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadata.toObject(message.items[j], options);
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this PartialObjectMetadataList to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PartialObjectMetadataList
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                PartialObjectMetadataList.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return PartialObjectMetadataList;
                            })();

                            v1.Patch = (function() {

                                /**
                                 * Properties of a Patch.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IPatch
                                 */

                                /**
                                 * Constructs a new Patch.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Patch.
                                 * @implements IPatch
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatch=} [properties] Properties to set
                                 */
                                function Patch(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Creates a new Patch instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatch=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Patch} Patch instance
                                 */
                                Patch.create = function create(properties) {
                                    return new Patch(properties);
                                };

                                /**
                                 * Encodes the specified Patch message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Patch.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatch} message Patch message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Patch.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Patch message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Patch.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatch} message Patch message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Patch.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Patch message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Patch} Patch
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Patch.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Patch();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Patch message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Patch} Patch
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Patch.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Patch message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Patch.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    return null;
                                };

                                /**
                                 * Creates a Patch message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Patch} Patch
                                 */
                                Patch.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Patch)
                                        return object;
                                    return new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Patch();
                                };

                                /**
                                 * Creates a plain object from a Patch message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Patch} message Patch
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Patch.toObject = function toObject() {
                                    return {};
                                };

                                /**
                                 * Converts this Patch to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Patch
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Patch.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Patch;
                            })();

                            v1.PatchOptions = (function() {

                                /**
                                 * Properties of a PatchOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IPatchOptions
                                 * @property {Array.<string>|null} [dryRun] PatchOptions dryRun
                                 * @property {boolean|null} [force] PatchOptions force
                                 * @property {string|null} [fieldManager] PatchOptions fieldManager
                                 */

                                /**
                                 * Constructs a new PatchOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a PatchOptions.
                                 * @implements IPatchOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatchOptions=} [properties] Properties to set
                                 */
                                function PatchOptions(properties) {
                                    this.dryRun = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * PatchOptions dryRun.
                                 * @member {Array.<string>} dryRun
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @instance
                                 */
                                PatchOptions.prototype.dryRun = $util.emptyArray;

                                /**
                                 * PatchOptions force.
                                 * @member {boolean} force
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @instance
                                 */
                                PatchOptions.prototype.force = false;

                                /**
                                 * PatchOptions fieldManager.
                                 * @member {string} fieldManager
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @instance
                                 */
                                PatchOptions.prototype.fieldManager = "";

                                /**
                                 * Creates a new PatchOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatchOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions} PatchOptions instance
                                 */
                                PatchOptions.create = function create(properties) {
                                    return new PatchOptions(properties);
                                };

                                /**
                                 * Encodes the specified PatchOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatchOptions} message PatchOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PatchOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.dryRun != null && message.dryRun.length)
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dryRun[i]);
                                    if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
                                    if (message.fieldManager != null && Object.hasOwnProperty.call(message, "fieldManager"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.fieldManager);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified PatchOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPatchOptions} message PatchOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PatchOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a PatchOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions} PatchOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PatchOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.dryRun && message.dryRun.length))
                                                message.dryRun = [];
                                            message.dryRun.push(reader.string());
                                            break;
                                        case 2:
                                            message.force = reader.bool();
                                            break;
                                        case 3:
                                            message.fieldManager = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a PatchOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions} PatchOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PatchOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a PatchOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                PatchOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.dryRun != null && message.hasOwnProperty("dryRun")) {
                                        if (!Array.isArray(message.dryRun))
                                            return "dryRun: array expected";
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            if (!$util.isString(message.dryRun[i]))
                                                return "dryRun: string[] expected";
                                    }
                                    if (message.force != null && message.hasOwnProperty("force"))
                                        if (typeof message.force !== "boolean")
                                            return "force: boolean expected";
                                    if (message.fieldManager != null && message.hasOwnProperty("fieldManager"))
                                        if (!$util.isString(message.fieldManager))
                                            return "fieldManager: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a PatchOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions} PatchOptions
                                 */
                                PatchOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions();
                                    if (object.dryRun) {
                                        if (!Array.isArray(object.dryRun))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions.dryRun: array expected");
                                        message.dryRun = [];
                                        for (var i = 0; i < object.dryRun.length; ++i)
                                            message.dryRun[i] = String(object.dryRun[i]);
                                    }
                                    if (object.force != null)
                                        message.force = Boolean(object.force);
                                    if (object.fieldManager != null)
                                        message.fieldManager = String(object.fieldManager);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a PatchOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions} message PatchOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                PatchOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.dryRun = [];
                                    if (options.defaults) {
                                        object.force = false;
                                        object.fieldManager = "";
                                    }
                                    if (message.dryRun && message.dryRun.length) {
                                        object.dryRun = [];
                                        for (var j = 0; j < message.dryRun.length; ++j)
                                            object.dryRun[j] = message.dryRun[j];
                                    }
                                    if (message.force != null && message.hasOwnProperty("force"))
                                        object.force = message.force;
                                    if (message.fieldManager != null && message.hasOwnProperty("fieldManager"))
                                        object.fieldManager = message.fieldManager;
                                    return object;
                                };

                                /**
                                 * Converts this PatchOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.PatchOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                PatchOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return PatchOptions;
                            })();

                            v1.Preconditions = (function() {

                                /**
                                 * Properties of a Preconditions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IPreconditions
                                 * @property {string|null} [uid] Preconditions uid
                                 * @property {string|null} [resourceVersion] Preconditions resourceVersion
                                 */

                                /**
                                 * Constructs a new Preconditions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Preconditions.
                                 * @implements IPreconditions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPreconditions=} [properties] Properties to set
                                 */
                                function Preconditions(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Preconditions uid.
                                 * @member {string} uid
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @instance
                                 */
                                Preconditions.prototype.uid = "";

                                /**
                                 * Preconditions resourceVersion.
                                 * @member {string} resourceVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @instance
                                 */
                                Preconditions.prototype.resourceVersion = "";

                                /**
                                 * Creates a new Preconditions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPreconditions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions} Preconditions instance
                                 */
                                Preconditions.create = function create(properties) {
                                    return new Preconditions(properties);
                                };

                                /**
                                 * Encodes the specified Preconditions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPreconditions} message Preconditions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Preconditions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
                                    if (message.resourceVersion != null && Object.hasOwnProperty.call(message, "resourceVersion"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.resourceVersion);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Preconditions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IPreconditions} message Preconditions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Preconditions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Preconditions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions} Preconditions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Preconditions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.uid = reader.string();
                                            break;
                                        case 2:
                                            message.resourceVersion = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Preconditions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions} Preconditions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Preconditions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Preconditions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Preconditions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        if (!$util.isString(message.uid))
                                            return "uid: string expected";
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        if (!$util.isString(message.resourceVersion))
                                            return "resourceVersion: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a Preconditions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions} Preconditions
                                 */
                                Preconditions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions();
                                    if (object.uid != null)
                                        message.uid = String(object.uid);
                                    if (object.resourceVersion != null)
                                        message.resourceVersion = String(object.resourceVersion);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Preconditions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions} message Preconditions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Preconditions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.uid = "";
                                        object.resourceVersion = "";
                                    }
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        object.uid = message.uid;
                                    if (message.resourceVersion != null && message.hasOwnProperty("resourceVersion"))
                                        object.resourceVersion = message.resourceVersion;
                                    return object;
                                };

                                /**
                                 * Converts this Preconditions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Preconditions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Preconditions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Preconditions;
                            })();

                            v1.RootPaths = (function() {

                                /**
                                 * Properties of a RootPaths.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IRootPaths
                                 * @property {Array.<string>|null} [paths] RootPaths paths
                                 */

                                /**
                                 * Constructs a new RootPaths.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a RootPaths.
                                 * @implements IRootPaths
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IRootPaths=} [properties] Properties to set
                                 */
                                function RootPaths(properties) {
                                    this.paths = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * RootPaths paths.
                                 * @member {Array.<string>} paths
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @instance
                                 */
                                RootPaths.prototype.paths = $util.emptyArray;

                                /**
                                 * Creates a new RootPaths instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IRootPaths=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths} RootPaths instance
                                 */
                                RootPaths.create = function create(properties) {
                                    return new RootPaths(properties);
                                };

                                /**
                                 * Encodes the specified RootPaths message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IRootPaths} message RootPaths message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                RootPaths.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.paths != null && message.paths.length)
                                        for (var i = 0; i < message.paths.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.paths[i]);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified RootPaths message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IRootPaths} message RootPaths message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                RootPaths.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a RootPaths message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths} RootPaths
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                RootPaths.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.paths && message.paths.length))
                                                message.paths = [];
                                            message.paths.push(reader.string());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a RootPaths message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths} RootPaths
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                RootPaths.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a RootPaths message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                RootPaths.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.paths != null && message.hasOwnProperty("paths")) {
                                        if (!Array.isArray(message.paths))
                                            return "paths: array expected";
                                        for (var i = 0; i < message.paths.length; ++i)
                                            if (!$util.isString(message.paths[i]))
                                                return "paths: string[] expected";
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a RootPaths message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths} RootPaths
                                 */
                                RootPaths.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths();
                                    if (object.paths) {
                                        if (!Array.isArray(object.paths))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths.paths: array expected");
                                        message.paths = [];
                                        for (var i = 0; i < object.paths.length; ++i)
                                            message.paths[i] = String(object.paths[i]);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a RootPaths message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths} message RootPaths
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                RootPaths.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.paths = [];
                                    if (message.paths && message.paths.length) {
                                        object.paths = [];
                                        for (var j = 0; j < message.paths.length; ++j)
                                            object.paths[j] = message.paths[j];
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this RootPaths to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.RootPaths
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                RootPaths.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return RootPaths;
                            })();

                            v1.ServerAddressByClientCIDR = (function() {

                                /**
                                 * Properties of a ServerAddressByClientCIDR.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IServerAddressByClientCIDR
                                 * @property {string|null} [clientCIDR] ServerAddressByClientCIDR clientCIDR
                                 * @property {string|null} [serverAddress] ServerAddressByClientCIDR serverAddress
                                 */

                                /**
                                 * Constructs a new ServerAddressByClientCIDR.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a ServerAddressByClientCIDR.
                                 * @implements IServerAddressByClientCIDR
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR=} [properties] Properties to set
                                 */
                                function ServerAddressByClientCIDR(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ServerAddressByClientCIDR clientCIDR.
                                 * @member {string} clientCIDR
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @instance
                                 */
                                ServerAddressByClientCIDR.prototype.clientCIDR = "";

                                /**
                                 * ServerAddressByClientCIDR serverAddress.
                                 * @member {string} serverAddress
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @instance
                                 */
                                ServerAddressByClientCIDR.prototype.serverAddress = "";

                                /**
                                 * Creates a new ServerAddressByClientCIDR instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR} ServerAddressByClientCIDR instance
                                 */
                                ServerAddressByClientCIDR.create = function create(properties) {
                                    return new ServerAddressByClientCIDR(properties);
                                };

                                /**
                                 * Encodes the specified ServerAddressByClientCIDR message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR} message ServerAddressByClientCIDR message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ServerAddressByClientCIDR.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.clientCIDR != null && Object.hasOwnProperty.call(message, "clientCIDR"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientCIDR);
                                    if (message.serverAddress != null && Object.hasOwnProperty.call(message, "serverAddress"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.serverAddress);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ServerAddressByClientCIDR message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IServerAddressByClientCIDR} message ServerAddressByClientCIDR message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ServerAddressByClientCIDR.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a ServerAddressByClientCIDR message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR} ServerAddressByClientCIDR
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ServerAddressByClientCIDR.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.clientCIDR = reader.string();
                                            break;
                                        case 2:
                                            message.serverAddress = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a ServerAddressByClientCIDR message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR} ServerAddressByClientCIDR
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ServerAddressByClientCIDR.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a ServerAddressByClientCIDR message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ServerAddressByClientCIDR.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.clientCIDR != null && message.hasOwnProperty("clientCIDR"))
                                        if (!$util.isString(message.clientCIDR))
                                            return "clientCIDR: string expected";
                                    if (message.serverAddress != null && message.hasOwnProperty("serverAddress"))
                                        if (!$util.isString(message.serverAddress))
                                            return "serverAddress: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a ServerAddressByClientCIDR message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR} ServerAddressByClientCIDR
                                 */
                                ServerAddressByClientCIDR.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR();
                                    if (object.clientCIDR != null)
                                        message.clientCIDR = String(object.clientCIDR);
                                    if (object.serverAddress != null)
                                        message.serverAddress = String(object.serverAddress);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a ServerAddressByClientCIDR message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR} message ServerAddressByClientCIDR
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ServerAddressByClientCIDR.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.clientCIDR = "";
                                        object.serverAddress = "";
                                    }
                                    if (message.clientCIDR != null && message.hasOwnProperty("clientCIDR"))
                                        object.clientCIDR = message.clientCIDR;
                                    if (message.serverAddress != null && message.hasOwnProperty("serverAddress"))
                                        object.serverAddress = message.serverAddress;
                                    return object;
                                };

                                /**
                                 * Converts this ServerAddressByClientCIDR to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.ServerAddressByClientCIDR
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ServerAddressByClientCIDR.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return ServerAddressByClientCIDR;
                            })();

                            v1.Status = (function() {

                                /**
                                 * Properties of a Status.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IStatus
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null} [metadata] Status metadata
                                 * @property {string|null} [status] Status status
                                 * @property {string|null} [message] Status message
                                 * @property {string|null} [reason] Status reason
                                 * @property {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusDetails|null} [details] Status details
                                 * @property {number|null} [code] Status code
                                 */

                                /**
                                 * Constructs a new Status.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Status.
                                 * @implements IStatus
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatus=} [properties] Properties to set
                                 */
                                function Status(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Status metadata.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IListMeta|null|undefined} metadata
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 */
                                Status.prototype.metadata = null;

                                /**
                                 * Status status.
                                 * @member {string} status
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 */
                                Status.prototype.status = "";

                                /**
                                 * Status message.
                                 * @member {string} message
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 */
                                Status.prototype.message = "";

                                /**
                                 * Status reason.
                                 * @member {string} reason
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 */
                                Status.prototype.reason = "";

                                /**
                                 * Status details.
                                 * @member {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusDetails|null|undefined} details
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 */
                                Status.prototype.details = null;

                                /**
                                 * Status code.
                                 * @member {number} code
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 */
                                Status.prototype.code = 0;

                                /**
                                 * Creates a new Status instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatus=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Status} Status instance
                                 */
                                Status.create = function create(properties) {
                                    return new Status(properties);
                                };

                                /**
                                 * Encodes the specified Status message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Status.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatus} message Status message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Status.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
                                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
                                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.reason);
                                    if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                                        $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.encode(message.details, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.code);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Status message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Status.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatus} message Status message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Status.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Status message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Status} Status
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Status.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Status();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.decode(reader, reader.uint32());
                                            break;
                                        case 2:
                                            message.status = reader.string();
                                            break;
                                        case 3:
                                            message.message = reader.string();
                                            break;
                                        case 4:
                                            message.reason = reader.string();
                                            break;
                                        case 5:
                                            message.details = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.decode(reader, reader.uint32());
                                            break;
                                        case 6:
                                            message.code = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Status message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Status} Status
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Status.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Status message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Status.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.verify(message.metadata);
                                        if (error)
                                            return "metadata." + error;
                                    }
                                    if (message.status != null && message.hasOwnProperty("status"))
                                        if (!$util.isString(message.status))
                                            return "status: string expected";
                                    if (message.message != null && message.hasOwnProperty("message"))
                                        if (!$util.isString(message.message))
                                            return "message: string expected";
                                    if (message.reason != null && message.hasOwnProperty("reason"))
                                        if (!$util.isString(message.reason))
                                            return "reason: string expected";
                                    if (message.details != null && message.hasOwnProperty("details")) {
                                        var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.verify(message.details);
                                        if (error)
                                            return "details." + error;
                                    }
                                    if (message.code != null && message.hasOwnProperty("code"))
                                        if (!$util.isInteger(message.code))
                                            return "code: integer expected";
                                    return null;
                                };

                                /**
                                 * Creates a Status message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Status} Status
                                 */
                                Status.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Status)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Status();
                                    if (object.metadata != null) {
                                        if (typeof object.metadata !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.Status.metadata: object expected");
                                        message.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.fromObject(object.metadata);
                                    }
                                    if (object.status != null)
                                        message.status = String(object.status);
                                    if (object.message != null)
                                        message.message = String(object.message);
                                    if (object.reason != null)
                                        message.reason = String(object.reason);
                                    if (object.details != null) {
                                        if (typeof object.details !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.Status.details: object expected");
                                        message.details = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.fromObject(object.details);
                                    }
                                    if (object.code != null)
                                        message.code = object.code | 0;
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Status message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Status} message Status
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Status.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.metadata = null;
                                        object.status = "";
                                        object.message = "";
                                        object.reason = "";
                                        object.details = null;
                                        object.code = 0;
                                    }
                                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                                        object.metadata = $root.k8s.io.apimachinery.pkg.apis.meta.v1.ListMeta.toObject(message.metadata, options);
                                    if (message.status != null && message.hasOwnProperty("status"))
                                        object.status = message.status;
                                    if (message.message != null && message.hasOwnProperty("message"))
                                        object.message = message.message;
                                    if (message.reason != null && message.hasOwnProperty("reason"))
                                        object.reason = message.reason;
                                    if (message.details != null && message.hasOwnProperty("details"))
                                        object.details = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.toObject(message.details, options);
                                    if (message.code != null && message.hasOwnProperty("code"))
                                        object.code = message.code;
                                    return object;
                                };

                                /**
                                 * Converts this Status to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Status
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Status.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Status;
                            })();

                            v1.StatusCause = (function() {

                                /**
                                 * Properties of a StatusCause.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IStatusCause
                                 * @property {string|null} [reason] StatusCause reason
                                 * @property {string|null} [message] StatusCause message
                                 * @property {string|null} [field] StatusCause field
                                 */

                                /**
                                 * Constructs a new StatusCause.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a StatusCause.
                                 * @implements IStatusCause
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusCause=} [properties] Properties to set
                                 */
                                function StatusCause(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * StatusCause reason.
                                 * @member {string} reason
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @instance
                                 */
                                StatusCause.prototype.reason = "";

                                /**
                                 * StatusCause message.
                                 * @member {string} message
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @instance
                                 */
                                StatusCause.prototype.message = "";

                                /**
                                 * StatusCause field.
                                 * @member {string} field
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @instance
                                 */
                                StatusCause.prototype.field = "";

                                /**
                                 * Creates a new StatusCause instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusCause=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause} StatusCause instance
                                 */
                                StatusCause.create = function create(properties) {
                                    return new StatusCause(properties);
                                };

                                /**
                                 * Encodes the specified StatusCause message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusCause} message StatusCause message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                StatusCause.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.reason);
                                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                                    if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.field);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified StatusCause message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusCause} message StatusCause message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                StatusCause.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a StatusCause message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause} StatusCause
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                StatusCause.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.reason = reader.string();
                                            break;
                                        case 2:
                                            message.message = reader.string();
                                            break;
                                        case 3:
                                            message.field = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a StatusCause message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause} StatusCause
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                StatusCause.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a StatusCause message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                StatusCause.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.reason != null && message.hasOwnProperty("reason"))
                                        if (!$util.isString(message.reason))
                                            return "reason: string expected";
                                    if (message.message != null && message.hasOwnProperty("message"))
                                        if (!$util.isString(message.message))
                                            return "message: string expected";
                                    if (message.field != null && message.hasOwnProperty("field"))
                                        if (!$util.isString(message.field))
                                            return "field: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a StatusCause message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause} StatusCause
                                 */
                                StatusCause.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause();
                                    if (object.reason != null)
                                        message.reason = String(object.reason);
                                    if (object.message != null)
                                        message.message = String(object.message);
                                    if (object.field != null)
                                        message.field = String(object.field);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a StatusCause message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause} message StatusCause
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                StatusCause.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.reason = "";
                                        object.message = "";
                                        object.field = "";
                                    }
                                    if (message.reason != null && message.hasOwnProperty("reason"))
                                        object.reason = message.reason;
                                    if (message.message != null && message.hasOwnProperty("message"))
                                        object.message = message.message;
                                    if (message.field != null && message.hasOwnProperty("field"))
                                        object.field = message.field;
                                    return object;
                                };

                                /**
                                 * Converts this StatusCause to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                StatusCause.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return StatusCause;
                            })();

                            v1.StatusDetails = (function() {

                                /**
                                 * Properties of a StatusDetails.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IStatusDetails
                                 * @property {string|null} [name] StatusDetails name
                                 * @property {string|null} [group] StatusDetails group
                                 * @property {string|null} [kind] StatusDetails kind
                                 * @property {string|null} [uid] StatusDetails uid
                                 * @property {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IStatusCause>|null} [causes] StatusDetails causes
                                 * @property {number|null} [retryAfterSeconds] StatusDetails retryAfterSeconds
                                 */

                                /**
                                 * Constructs a new StatusDetails.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a StatusDetails.
                                 * @implements IStatusDetails
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusDetails=} [properties] Properties to set
                                 */
                                function StatusDetails(properties) {
                                    this.causes = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * StatusDetails name.
                                 * @member {string} name
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 */
                                StatusDetails.prototype.name = "";

                                /**
                                 * StatusDetails group.
                                 * @member {string} group
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 */
                                StatusDetails.prototype.group = "";

                                /**
                                 * StatusDetails kind.
                                 * @member {string} kind
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 */
                                StatusDetails.prototype.kind = "";

                                /**
                                 * StatusDetails uid.
                                 * @member {string} uid
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 */
                                StatusDetails.prototype.uid = "";

                                /**
                                 * StatusDetails causes.
                                 * @member {Array.<k8s.io.apimachinery.pkg.apis.meta.v1.IStatusCause>} causes
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 */
                                StatusDetails.prototype.causes = $util.emptyArray;

                                /**
                                 * StatusDetails retryAfterSeconds.
                                 * @member {number} retryAfterSeconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 */
                                StatusDetails.prototype.retryAfterSeconds = 0;

                                /**
                                 * Creates a new StatusDetails instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusDetails=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails} StatusDetails instance
                                 */
                                StatusDetails.create = function create(properties) {
                                    return new StatusDetails(properties);
                                };

                                /**
                                 * Encodes the specified StatusDetails message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusDetails} message StatusDetails message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                StatusDetails.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                                    if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.group);
                                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.kind);
                                    if (message.causes != null && message.causes.length)
                                        for (var i = 0; i < message.causes.length; ++i)
                                            $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.encode(message.causes[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    if (message.retryAfterSeconds != null && Object.hasOwnProperty.call(message, "retryAfterSeconds"))
                                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.retryAfterSeconds);
                                    if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.uid);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified StatusDetails message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IStatusDetails} message StatusDetails message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                StatusDetails.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a StatusDetails message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails} StatusDetails
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                StatusDetails.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.name = reader.string();
                                            break;
                                        case 2:
                                            message.group = reader.string();
                                            break;
                                        case 3:
                                            message.kind = reader.string();
                                            break;
                                        case 6:
                                            message.uid = reader.string();
                                            break;
                                        case 4:
                                            if (!(message.causes && message.causes.length))
                                                message.causes = [];
                                            message.causes.push($root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.decode(reader, reader.uint32()));
                                            break;
                                        case 5:
                                            message.retryAfterSeconds = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a StatusDetails message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails} StatusDetails
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                StatusDetails.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a StatusDetails message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                StatusDetails.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        if (!$util.isString(message.name))
                                            return "name: string expected";
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        if (!$util.isString(message.group))
                                            return "group: string expected";
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        if (!$util.isString(message.kind))
                                            return "kind: string expected";
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        if (!$util.isString(message.uid))
                                            return "uid: string expected";
                                    if (message.causes != null && message.hasOwnProperty("causes")) {
                                        if (!Array.isArray(message.causes))
                                            return "causes: array expected";
                                        for (var i = 0; i < message.causes.length; ++i) {
                                            var error = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.verify(message.causes[i]);
                                            if (error)
                                                return "causes." + error;
                                        }
                                    }
                                    if (message.retryAfterSeconds != null && message.hasOwnProperty("retryAfterSeconds"))
                                        if (!$util.isInteger(message.retryAfterSeconds))
                                            return "retryAfterSeconds: integer expected";
                                    return null;
                                };

                                /**
                                 * Creates a StatusDetails message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails} StatusDetails
                                 */
                                StatusDetails.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails();
                                    if (object.name != null)
                                        message.name = String(object.name);
                                    if (object.group != null)
                                        message.group = String(object.group);
                                    if (object.kind != null)
                                        message.kind = String(object.kind);
                                    if (object.uid != null)
                                        message.uid = String(object.uid);
                                    if (object.causes) {
                                        if (!Array.isArray(object.causes))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.causes: array expected");
                                        message.causes = [];
                                        for (var i = 0; i < object.causes.length; ++i) {
                                            if (typeof object.causes[i] !== "object")
                                                throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails.causes: object expected");
                                            message.causes[i] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.fromObject(object.causes[i]);
                                        }
                                    }
                                    if (object.retryAfterSeconds != null)
                                        message.retryAfterSeconds = object.retryAfterSeconds | 0;
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a StatusDetails message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails} message StatusDetails
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                StatusDetails.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.causes = [];
                                    if (options.defaults) {
                                        object.name = "";
                                        object.group = "";
                                        object.kind = "";
                                        object.retryAfterSeconds = 0;
                                        object.uid = "";
                                    }
                                    if (message.name != null && message.hasOwnProperty("name"))
                                        object.name = message.name;
                                    if (message.group != null && message.hasOwnProperty("group"))
                                        object.group = message.group;
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        object.kind = message.kind;
                                    if (message.causes && message.causes.length) {
                                        object.causes = [];
                                        for (var j = 0; j < message.causes.length; ++j)
                                            object.causes[j] = $root.k8s.io.apimachinery.pkg.apis.meta.v1.StatusCause.toObject(message.causes[j], options);
                                    }
                                    if (message.retryAfterSeconds != null && message.hasOwnProperty("retryAfterSeconds"))
                                        object.retryAfterSeconds = message.retryAfterSeconds;
                                    if (message.uid != null && message.hasOwnProperty("uid"))
                                        object.uid = message.uid;
                                    return object;
                                };

                                /**
                                 * Converts this StatusDetails to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.StatusDetails
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                StatusDetails.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return StatusDetails;
                            })();

                            v1.TableOptions = (function() {

                                /**
                                 * Properties of a TableOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ITableOptions
                                 * @property {string|null} [includeObject] TableOptions includeObject
                                 */

                                /**
                                 * Constructs a new TableOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a TableOptions.
                                 * @implements ITableOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITableOptions=} [properties] Properties to set
                                 */
                                function TableOptions(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * TableOptions includeObject.
                                 * @member {string} includeObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @instance
                                 */
                                TableOptions.prototype.includeObject = "";

                                /**
                                 * Creates a new TableOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITableOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions} TableOptions instance
                                 */
                                TableOptions.create = function create(properties) {
                                    return new TableOptions(properties);
                                };

                                /**
                                 * Encodes the specified TableOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITableOptions} message TableOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                TableOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.includeObject != null && Object.hasOwnProperty.call(message, "includeObject"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.includeObject);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified TableOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITableOptions} message TableOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                TableOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a TableOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions} TableOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                TableOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.includeObject = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a TableOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions} TableOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                TableOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a TableOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                TableOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.includeObject != null && message.hasOwnProperty("includeObject"))
                                        if (!$util.isString(message.includeObject))
                                            return "includeObject: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a TableOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions} TableOptions
                                 */
                                TableOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions();
                                    if (object.includeObject != null)
                                        message.includeObject = String(object.includeObject);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a TableOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions} message TableOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                TableOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults)
                                        object.includeObject = "";
                                    if (message.includeObject != null && message.hasOwnProperty("includeObject"))
                                        object.includeObject = message.includeObject;
                                    return object;
                                };

                                /**
                                 * Converts this TableOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TableOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                TableOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return TableOptions;
                            })();

                            v1.Time = (function() {

                                /**
                                 * Properties of a Time.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ITime
                                 * @property {number|Long|null} [seconds] Time seconds
                                 * @property {number|null} [nanos] Time nanos
                                 */

                                /**
                                 * Constructs a new Time.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Time.
                                 * @implements ITime
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITime=} [properties] Properties to set
                                 */
                                function Time(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Time seconds.
                                 * @member {number|Long} seconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @instance
                                 */
                                Time.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * Time nanos.
                                 * @member {number} nanos
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @instance
                                 */
                                Time.prototype.nanos = 0;

                                /**
                                 * Creates a new Time instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITime=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Time} Time instance
                                 */
                                Time.create = function create(properties) {
                                    return new Time(properties);
                                };

                                /**
                                 * Encodes the specified Time message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITime} message Time message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Time.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Time message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Time.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITime} message Time message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Time.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Time message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Time} Time
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Time.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.seconds = reader.int64();
                                            break;
                                        case 2:
                                            message.nanos = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Time message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Time} Time
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Time.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Time message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Time.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                                            return "seconds: integer|Long expected";
                                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                                        if (!$util.isInteger(message.nanos))
                                            return "nanos: integer expected";
                                    return null;
                                };

                                /**
                                 * Creates a Time message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Time} Time
                                 */
                                Time.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Time();
                                    if (object.seconds != null)
                                        if ($util.Long)
                                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                                        else if (typeof object.seconds === "string")
                                            message.seconds = parseInt(object.seconds, 10);
                                        else if (typeof object.seconds === "number")
                                            message.seconds = object.seconds;
                                        else if (typeof object.seconds === "object")
                                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                                    if (object.nanos != null)
                                        message.nanos = object.nanos | 0;
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Time message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Time} message Time
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Time.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.seconds = options.longs === String ? "0" : 0;
                                        object.nanos = 0;
                                    }
                                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                                        if (typeof message.seconds === "number")
                                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                                        else
                                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                                        object.nanos = message.nanos;
                                    return object;
                                };

                                /**
                                 * Converts this Time to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Time
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Time.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Time;
                            })();

                            v1.Timestamp = (function() {

                                /**
                                 * Properties of a Timestamp.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ITimestamp
                                 * @property {number|Long|null} [seconds] Timestamp seconds
                                 * @property {number|null} [nanos] Timestamp nanos
                                 */

                                /**
                                 * Constructs a new Timestamp.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Timestamp.
                                 * @implements ITimestamp
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITimestamp=} [properties] Properties to set
                                 */
                                function Timestamp(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Timestamp seconds.
                                 * @member {number|Long} seconds
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @instance
                                 */
                                Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                                /**
                                 * Timestamp nanos.
                                 * @member {number} nanos
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @instance
                                 */
                                Timestamp.prototype.nanos = 0;

                                /**
                                 * Creates a new Timestamp instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITimestamp=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp} Timestamp instance
                                 */
                                Timestamp.create = function create(properties) {
                                    return new Timestamp(properties);
                                };

                                /**
                                 * Encodes the specified Timestamp message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITimestamp} message Timestamp message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Timestamp.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                                    if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITimestamp} message Timestamp message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Timestamp message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp} Timestamp
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Timestamp.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.seconds = reader.int64();
                                            break;
                                        case 2:
                                            message.nanos = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Timestamp message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp} Timestamp
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Timestamp.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Timestamp message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Timestamp.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                                            return "seconds: integer|Long expected";
                                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                                        if (!$util.isInteger(message.nanos))
                                            return "nanos: integer expected";
                                    return null;
                                };

                                /**
                                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp} Timestamp
                                 */
                                Timestamp.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp();
                                    if (object.seconds != null)
                                        if ($util.Long)
                                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                                        else if (typeof object.seconds === "string")
                                            message.seconds = parseInt(object.seconds, 10);
                                        else if (typeof object.seconds === "number")
                                            message.seconds = object.seconds;
                                        else if (typeof object.seconds === "object")
                                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                                    if (object.nanos != null)
                                        message.nanos = object.nanos | 0;
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp} message Timestamp
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Timestamp.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        if ($util.Long) {
                                            var long = new $util.Long(0, 0, false);
                                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                        } else
                                            object.seconds = options.longs === String ? "0" : 0;
                                        object.nanos = 0;
                                    }
                                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                                        if (typeof message.seconds === "number")
                                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                                        else
                                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                                        object.nanos = message.nanos;
                                    return object;
                                };

                                /**
                                 * Converts this Timestamp to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Timestamp
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Timestamp.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Timestamp;
                            })();

                            v1.TypeMeta = (function() {

                                /**
                                 * Properties of a TypeMeta.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface ITypeMeta
                                 * @property {string|null} [kind] TypeMeta kind
                                 * @property {string|null} [apiVersion] TypeMeta apiVersion
                                 */

                                /**
                                 * Constructs a new TypeMeta.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a TypeMeta.
                                 * @implements ITypeMeta
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITypeMeta=} [properties] Properties to set
                                 */
                                function TypeMeta(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * TypeMeta kind.
                                 * @member {string} kind
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @instance
                                 */
                                TypeMeta.prototype.kind = "";

                                /**
                                 * TypeMeta apiVersion.
                                 * @member {string} apiVersion
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @instance
                                 */
                                TypeMeta.prototype.apiVersion = "";

                                /**
                                 * Creates a new TypeMeta instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITypeMeta=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta} TypeMeta instance
                                 */
                                TypeMeta.create = function create(properties) {
                                    return new TypeMeta(properties);
                                };

                                /**
                                 * Encodes the specified TypeMeta message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITypeMeta} message TypeMeta message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                TypeMeta.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.kind);
                                    if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.apiVersion);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified TypeMeta message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.ITypeMeta} message TypeMeta message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                TypeMeta.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a TypeMeta message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta} TypeMeta
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                TypeMeta.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.kind = reader.string();
                                            break;
                                        case 2:
                                            message.apiVersion = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a TypeMeta message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta} TypeMeta
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                TypeMeta.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a TypeMeta message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                TypeMeta.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        if (!$util.isString(message.kind))
                                            return "kind: string expected";
                                    if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                        if (!$util.isString(message.apiVersion))
                                            return "apiVersion: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a TypeMeta message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta} TypeMeta
                                 */
                                TypeMeta.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta();
                                    if (object.kind != null)
                                        message.kind = String(object.kind);
                                    if (object.apiVersion != null)
                                        message.apiVersion = String(object.apiVersion);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a TypeMeta message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta} message TypeMeta
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                TypeMeta.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.kind = "";
                                        object.apiVersion = "";
                                    }
                                    if (message.kind != null && message.hasOwnProperty("kind"))
                                        object.kind = message.kind;
                                    if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                        object.apiVersion = message.apiVersion;
                                    return object;
                                };

                                /**
                                 * Converts this TypeMeta to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.TypeMeta
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                TypeMeta.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return TypeMeta;
                            })();

                            v1.UpdateOptions = (function() {

                                /**
                                 * Properties of an UpdateOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IUpdateOptions
                                 * @property {Array.<string>|null} [dryRun] UpdateOptions dryRun
                                 * @property {string|null} [fieldManager] UpdateOptions fieldManager
                                 */

                                /**
                                 * Constructs a new UpdateOptions.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents an UpdateOptions.
                                 * @implements IUpdateOptions
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IUpdateOptions=} [properties] Properties to set
                                 */
                                function UpdateOptions(properties) {
                                    this.dryRun = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * UpdateOptions dryRun.
                                 * @member {Array.<string>} dryRun
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @instance
                                 */
                                UpdateOptions.prototype.dryRun = $util.emptyArray;

                                /**
                                 * UpdateOptions fieldManager.
                                 * @member {string} fieldManager
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @instance
                                 */
                                UpdateOptions.prototype.fieldManager = "";

                                /**
                                 * Creates a new UpdateOptions instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IUpdateOptions=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions} UpdateOptions instance
                                 */
                                UpdateOptions.create = function create(properties) {
                                    return new UpdateOptions(properties);
                                };

                                /**
                                 * Encodes the specified UpdateOptions message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IUpdateOptions} message UpdateOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                UpdateOptions.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.dryRun != null && message.dryRun.length)
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dryRun[i]);
                                    if (message.fieldManager != null && Object.hasOwnProperty.call(message, "fieldManager"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fieldManager);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified UpdateOptions message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IUpdateOptions} message UpdateOptions message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                UpdateOptions.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes an UpdateOptions message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions} UpdateOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                UpdateOptions.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.dryRun && message.dryRun.length))
                                                message.dryRun = [];
                                            message.dryRun.push(reader.string());
                                            break;
                                        case 2:
                                            message.fieldManager = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes an UpdateOptions message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions} UpdateOptions
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                UpdateOptions.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies an UpdateOptions message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                UpdateOptions.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.dryRun != null && message.hasOwnProperty("dryRun")) {
                                        if (!Array.isArray(message.dryRun))
                                            return "dryRun: array expected";
                                        for (var i = 0; i < message.dryRun.length; ++i)
                                            if (!$util.isString(message.dryRun[i]))
                                                return "dryRun: string[] expected";
                                    }
                                    if (message.fieldManager != null && message.hasOwnProperty("fieldManager"))
                                        if (!$util.isString(message.fieldManager))
                                            return "fieldManager: string expected";
                                    return null;
                                };

                                /**
                                 * Creates an UpdateOptions message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions} UpdateOptions
                                 */
                                UpdateOptions.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions();
                                    if (object.dryRun) {
                                        if (!Array.isArray(object.dryRun))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions.dryRun: array expected");
                                        message.dryRun = [];
                                        for (var i = 0; i < object.dryRun.length; ++i)
                                            message.dryRun[i] = String(object.dryRun[i]);
                                    }
                                    if (object.fieldManager != null)
                                        message.fieldManager = String(object.fieldManager);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from an UpdateOptions message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions} message UpdateOptions
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                UpdateOptions.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.dryRun = [];
                                    if (options.defaults)
                                        object.fieldManager = "";
                                    if (message.dryRun && message.dryRun.length) {
                                        object.dryRun = [];
                                        for (var j = 0; j < message.dryRun.length; ++j)
                                            object.dryRun[j] = message.dryRun[j];
                                    }
                                    if (message.fieldManager != null && message.hasOwnProperty("fieldManager"))
                                        object.fieldManager = message.fieldManager;
                                    return object;
                                };

                                /**
                                 * Converts this UpdateOptions to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.UpdateOptions
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                UpdateOptions.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return UpdateOptions;
                            })();

                            v1.Verbs = (function() {

                                /**
                                 * Properties of a Verbs.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IVerbs
                                 * @property {Array.<string>|null} [items] Verbs items
                                 */

                                /**
                                 * Constructs a new Verbs.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a Verbs.
                                 * @implements IVerbs
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IVerbs=} [properties] Properties to set
                                 */
                                function Verbs(properties) {
                                    this.items = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Verbs items.
                                 * @member {Array.<string>} items
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @instance
                                 */
                                Verbs.prototype.items = $util.emptyArray;

                                /**
                                 * Creates a new Verbs instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IVerbs=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Verbs} Verbs instance
                                 */
                                Verbs.create = function create(properties) {
                                    return new Verbs(properties);
                                };

                                /**
                                 * Encodes the specified Verbs message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IVerbs} message Verbs message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Verbs.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.items != null && message.items.length)
                                        for (var i = 0; i < message.items.length; ++i)
                                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.items[i]);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified Verbs message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IVerbs} message Verbs message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                Verbs.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a Verbs message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Verbs} Verbs
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Verbs.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.items && message.items.length))
                                                message.items = [];
                                            message.items.push(reader.string());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a Verbs message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Verbs} Verbs
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                Verbs.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a Verbs message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                Verbs.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.items != null && message.hasOwnProperty("items")) {
                                        if (!Array.isArray(message.items))
                                            return "items: array expected";
                                        for (var i = 0; i < message.items.length; ++i)
                                            if (!$util.isString(message.items[i]))
                                                return "items: string[] expected";
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a Verbs message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.Verbs} Verbs
                                 */
                                Verbs.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.Verbs();
                                    if (object.items) {
                                        if (!Array.isArray(object.items))
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.Verbs.items: array expected");
                                        message.items = [];
                                        for (var i = 0; i < object.items.length; ++i)
                                            message.items[i] = String(object.items[i]);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a Verbs message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.Verbs} message Verbs
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                Verbs.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.arrays || options.defaults)
                                        object.items = [];
                                    if (message.items && message.items.length) {
                                        object.items = [];
                                        for (var j = 0; j < message.items.length; ++j)
                                            object.items[j] = message.items[j];
                                    }
                                    return object;
                                };

                                /**
                                 * Converts this Verbs to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.Verbs
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                Verbs.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return Verbs;
                            })();

                            v1.WatchEvent = (function() {

                                /**
                                 * Properties of a WatchEvent.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @interface IWatchEvent
                                 * @property {string|null} [type] WatchEvent type
                                 * @property {k8s.io.apimachinery.pkg.runtime.IRawExtension|null} [object] WatchEvent object
                                 */

                                /**
                                 * Constructs a new WatchEvent.
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1
                                 * @classdesc Represents a WatchEvent.
                                 * @implements IWatchEvent
                                 * @constructor
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IWatchEvent=} [properties] Properties to set
                                 */
                                function WatchEvent(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * WatchEvent type.
                                 * @member {string} type
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @instance
                                 */
                                WatchEvent.prototype.type = "";

                                /**
                                 * WatchEvent object.
                                 * @member {k8s.io.apimachinery.pkg.runtime.IRawExtension|null|undefined} object
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @instance
                                 */
                                WatchEvent.prototype.object = null;

                                /**
                                 * Creates a new WatchEvent instance using the specified properties.
                                 * @function create
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IWatchEvent=} [properties] Properties to set
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent} WatchEvent instance
                                 */
                                WatchEvent.create = function create(properties) {
                                    return new WatchEvent(properties);
                                };

                                /**
                                 * Encodes the specified WatchEvent message. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent.verify|verify} messages.
                                 * @function encode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IWatchEvent} message WatchEvent message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                WatchEvent.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                                    if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                                        $root.k8s.io.apimachinery.pkg.runtime.RawExtension.encode(message.object, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Encodes the specified WatchEvent message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.IWatchEvent} message WatchEvent message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                WatchEvent.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a WatchEvent message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent} WatchEvent
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                WatchEvent.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.type = reader.string();
                                            break;
                                        case 2:
                                            message.object = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a WatchEvent message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent} WatchEvent
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                WatchEvent.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a WatchEvent message.
                                 * @function verify
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                WatchEvent.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.type != null && message.hasOwnProperty("type"))
                                        if (!$util.isString(message.type))
                                            return "type: string expected";
                                    if (message.object != null && message.hasOwnProperty("object")) {
                                        var error = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.verify(message.object);
                                        if (error)
                                            return "object." + error;
                                    }
                                    return null;
                                };

                                /**
                                 * Creates a WatchEvent message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent} WatchEvent
                                 */
                                WatchEvent.fromObject = function fromObject(object) {
                                    if (object instanceof $root.k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent)
                                        return object;
                                    var message = new $root.k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent();
                                    if (object.type != null)
                                        message.type = String(object.type);
                                    if (object.object != null) {
                                        if (typeof object.object !== "object")
                                            throw TypeError(".k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent.object: object expected");
                                        message.object = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.fromObject(object.object);
                                    }
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a WatchEvent message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @static
                                 * @param {k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent} message WatchEvent
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                WatchEvent.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    var object = {};
                                    if (options.defaults) {
                                        object.type = "";
                                        object.object = null;
                                    }
                                    if (message.type != null && message.hasOwnProperty("type"))
                                        object.type = message.type;
                                    if (message.object != null && message.hasOwnProperty("object"))
                                        object.object = $root.k8s.io.apimachinery.pkg.runtime.RawExtension.toObject(message.object, options);
                                    return object;
                                };

                                /**
                                 * Converts this WatchEvent to JSON.
                                 * @function toJSON
                                 * @memberof k8s.io.apimachinery.pkg.apis.meta.v1.WatchEvent
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                WatchEvent.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                return WatchEvent;
                            })();

                            return v1;
                        })();

                        return meta;
                    })();

                    return apis;
                })();

                pkg.runtime = (function() {

                    /**
                     * Namespace runtime.
                     * @memberof k8s.io.apimachinery.pkg
                     * @namespace
                     */
                    var runtime = {};

                    runtime.RawExtension = (function() {

                        /**
                         * Properties of a RawExtension.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @interface IRawExtension
                         * @property {Uint8Array|null} [raw] RawExtension raw
                         */

                        /**
                         * Constructs a new RawExtension.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @classdesc Represents a RawExtension.
                         * @implements IRawExtension
                         * @constructor
                         * @param {k8s.io.apimachinery.pkg.runtime.IRawExtension=} [properties] Properties to set
                         */
                        function RawExtension(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RawExtension raw.
                         * @member {Uint8Array} raw
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @instance
                         */
                        RawExtension.prototype.raw = $util.newBuffer([]);

                        /**
                         * Creates a new RawExtension instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.IRawExtension=} [properties] Properties to set
                         * @returns {k8s.io.apimachinery.pkg.runtime.RawExtension} RawExtension instance
                         */
                        RawExtension.create = function create(properties) {
                            return new RawExtension(properties);
                        };

                        /**
                         * Encodes the specified RawExtension message. Does not implicitly {@link k8s.io.apimachinery.pkg.runtime.RawExtension.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.IRawExtension} message RawExtension message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RawExtension.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.raw);
                            return writer;
                        };

                        /**
                         * Encodes the specified RawExtension message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.runtime.RawExtension.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.IRawExtension} message RawExtension message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        RawExtension.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a RawExtension message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.apimachinery.pkg.runtime.RawExtension} RawExtension
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RawExtension.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.runtime.RawExtension();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.raw = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a RawExtension message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.apimachinery.pkg.runtime.RawExtension} RawExtension
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        RawExtension.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a RawExtension message.
                         * @function verify
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RawExtension.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.raw != null && message.hasOwnProperty("raw"))
                                if (!(message.raw && typeof message.raw.length === "number" || $util.isString(message.raw)))
                                    return "raw: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a RawExtension message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.apimachinery.pkg.runtime.RawExtension} RawExtension
                         */
                        RawExtension.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.apimachinery.pkg.runtime.RawExtension)
                                return object;
                            var message = new $root.k8s.io.apimachinery.pkg.runtime.RawExtension();
                            if (object.raw != null)
                                if (typeof object.raw === "string")
                                    $util.base64.decode(object.raw, message.raw = $util.newBuffer($util.base64.length(object.raw)), 0);
                                else if (object.raw.length)
                                    message.raw = object.raw;
                            return message;
                        };

                        /**
                         * Creates a plain object from a RawExtension message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.RawExtension} message RawExtension
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RawExtension.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults)
                                if (options.bytes === String)
                                    object.raw = "";
                                else {
                                    object.raw = [];
                                    if (options.bytes !== Array)
                                        object.raw = $util.newBuffer(object.raw);
                                }
                            if (message.raw != null && message.hasOwnProperty("raw"))
                                object.raw = options.bytes === String ? $util.base64.encode(message.raw, 0, message.raw.length) : options.bytes === Array ? Array.prototype.slice.call(message.raw) : message.raw;
                            return object;
                        };

                        /**
                         * Converts this RawExtension to JSON.
                         * @function toJSON
                         * @memberof k8s.io.apimachinery.pkg.runtime.RawExtension
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RawExtension.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return RawExtension;
                    })();

                    runtime.TypeMeta = (function() {

                        /**
                         * Properties of a TypeMeta.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @interface ITypeMeta
                         * @property {string|null} [apiVersion] TypeMeta apiVersion
                         * @property {string|null} [kind] TypeMeta kind
                         */

                        /**
                         * Constructs a new TypeMeta.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @classdesc Represents a TypeMeta.
                         * @implements ITypeMeta
                         * @constructor
                         * @param {k8s.io.apimachinery.pkg.runtime.ITypeMeta=} [properties] Properties to set
                         */
                        function TypeMeta(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TypeMeta apiVersion.
                         * @member {string} apiVersion
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @instance
                         */
                        TypeMeta.prototype.apiVersion = "";

                        /**
                         * TypeMeta kind.
                         * @member {string} kind
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @instance
                         */
                        TypeMeta.prototype.kind = "";

                        /**
                         * Creates a new TypeMeta instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.ITypeMeta=} [properties] Properties to set
                         * @returns {k8s.io.apimachinery.pkg.runtime.TypeMeta} TypeMeta instance
                         */
                        TypeMeta.create = function create(properties) {
                            return new TypeMeta(properties);
                        };

                        /**
                         * Encodes the specified TypeMeta message. Does not implicitly {@link k8s.io.apimachinery.pkg.runtime.TypeMeta.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.ITypeMeta} message TypeMeta message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TypeMeta.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.apiVersion != null && Object.hasOwnProperty.call(message, "apiVersion"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.apiVersion);
                            if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.kind);
                            return writer;
                        };

                        /**
                         * Encodes the specified TypeMeta message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.runtime.TypeMeta.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.ITypeMeta} message TypeMeta message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        TypeMeta.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a TypeMeta message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.apimachinery.pkg.runtime.TypeMeta} TypeMeta
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TypeMeta.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.runtime.TypeMeta();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.apiVersion = reader.string();
                                    break;
                                case 2:
                                    message.kind = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a TypeMeta message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.apimachinery.pkg.runtime.TypeMeta} TypeMeta
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        TypeMeta.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a TypeMeta message.
                         * @function verify
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        TypeMeta.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                if (!$util.isString(message.apiVersion))
                                    return "apiVersion: string expected";
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                if (!$util.isString(message.kind))
                                    return "kind: string expected";
                            return null;
                        };

                        /**
                         * Creates a TypeMeta message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.apimachinery.pkg.runtime.TypeMeta} TypeMeta
                         */
                        TypeMeta.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.apimachinery.pkg.runtime.TypeMeta)
                                return object;
                            var message = new $root.k8s.io.apimachinery.pkg.runtime.TypeMeta();
                            if (object.apiVersion != null)
                                message.apiVersion = String(object.apiVersion);
                            if (object.kind != null)
                                message.kind = String(object.kind);
                            return message;
                        };

                        /**
                         * Creates a plain object from a TypeMeta message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.TypeMeta} message TypeMeta
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        TypeMeta.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.apiVersion = "";
                                object.kind = "";
                            }
                            if (message.apiVersion != null && message.hasOwnProperty("apiVersion"))
                                object.apiVersion = message.apiVersion;
                            if (message.kind != null && message.hasOwnProperty("kind"))
                                object.kind = message.kind;
                            return object;
                        };

                        /**
                         * Converts this TypeMeta to JSON.
                         * @function toJSON
                         * @memberof k8s.io.apimachinery.pkg.runtime.TypeMeta
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        TypeMeta.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return TypeMeta;
                    })();

                    runtime.Unknown = (function() {

                        /**
                         * Properties of an Unknown.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @interface IUnknown
                         * @property {k8s.io.apimachinery.pkg.runtime.ITypeMeta|null} [typeMeta] Unknown typeMeta
                         * @property {Uint8Array|null} [raw] Unknown raw
                         * @property {string|null} [contentEncoding] Unknown contentEncoding
                         * @property {string|null} [contentType] Unknown contentType
                         */

                        /**
                         * Constructs a new Unknown.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @classdesc Represents an Unknown.
                         * @implements IUnknown
                         * @constructor
                         * @param {k8s.io.apimachinery.pkg.runtime.IUnknown=} [properties] Properties to set
                         */
                        function Unknown(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Unknown typeMeta.
                         * @member {k8s.io.apimachinery.pkg.runtime.ITypeMeta|null|undefined} typeMeta
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @instance
                         */
                        Unknown.prototype.typeMeta = null;

                        /**
                         * Unknown raw.
                         * @member {Uint8Array} raw
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @instance
                         */
                        Unknown.prototype.raw = $util.newBuffer([]);

                        /**
                         * Unknown contentEncoding.
                         * @member {string} contentEncoding
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @instance
                         */
                        Unknown.prototype.contentEncoding = "";

                        /**
                         * Unknown contentType.
                         * @member {string} contentType
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @instance
                         */
                        Unknown.prototype.contentType = "";

                        /**
                         * Creates a new Unknown instance using the specified properties.
                         * @function create
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.IUnknown=} [properties] Properties to set
                         * @returns {k8s.io.apimachinery.pkg.runtime.Unknown} Unknown instance
                         */
                        Unknown.create = function create(properties) {
                            return new Unknown(properties);
                        };

                        /**
                         * Encodes the specified Unknown message. Does not implicitly {@link k8s.io.apimachinery.pkg.runtime.Unknown.verify|verify} messages.
                         * @function encode
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.IUnknown} message Unknown message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Unknown.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.typeMeta != null && Object.hasOwnProperty.call(message, "typeMeta"))
                                $root.k8s.io.apimachinery.pkg.runtime.TypeMeta.encode(message.typeMeta, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.raw);
                            if (message.contentEncoding != null && Object.hasOwnProperty.call(message, "contentEncoding"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.contentEncoding);
                            if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.contentType);
                            return writer;
                        };

                        /**
                         * Encodes the specified Unknown message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.runtime.Unknown.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.IUnknown} message Unknown message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Unknown.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Unknown message from the specified reader or buffer.
                         * @function decode
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {k8s.io.apimachinery.pkg.runtime.Unknown} Unknown
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Unknown.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.runtime.Unknown();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.typeMeta = $root.k8s.io.apimachinery.pkg.runtime.TypeMeta.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.raw = reader.bytes();
                                    break;
                                case 3:
                                    message.contentEncoding = reader.string();
                                    break;
                                case 4:
                                    message.contentType = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Unknown message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {k8s.io.apimachinery.pkg.runtime.Unknown} Unknown
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Unknown.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Unknown message.
                         * @function verify
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Unknown.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.typeMeta != null && message.hasOwnProperty("typeMeta")) {
                                var error = $root.k8s.io.apimachinery.pkg.runtime.TypeMeta.verify(message.typeMeta);
                                if (error)
                                    return "typeMeta." + error;
                            }
                            if (message.raw != null && message.hasOwnProperty("raw"))
                                if (!(message.raw && typeof message.raw.length === "number" || $util.isString(message.raw)))
                                    return "raw: buffer expected";
                            if (message.contentEncoding != null && message.hasOwnProperty("contentEncoding"))
                                if (!$util.isString(message.contentEncoding))
                                    return "contentEncoding: string expected";
                            if (message.contentType != null && message.hasOwnProperty("contentType"))
                                if (!$util.isString(message.contentType))
                                    return "contentType: string expected";
                            return null;
                        };

                        /**
                         * Creates an Unknown message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {k8s.io.apimachinery.pkg.runtime.Unknown} Unknown
                         */
                        Unknown.fromObject = function fromObject(object) {
                            if (object instanceof $root.k8s.io.apimachinery.pkg.runtime.Unknown)
                                return object;
                            var message = new $root.k8s.io.apimachinery.pkg.runtime.Unknown();
                            if (object.typeMeta != null) {
                                if (typeof object.typeMeta !== "object")
                                    throw TypeError(".k8s.io.apimachinery.pkg.runtime.Unknown.typeMeta: object expected");
                                message.typeMeta = $root.k8s.io.apimachinery.pkg.runtime.TypeMeta.fromObject(object.typeMeta);
                            }
                            if (object.raw != null)
                                if (typeof object.raw === "string")
                                    $util.base64.decode(object.raw, message.raw = $util.newBuffer($util.base64.length(object.raw)), 0);
                                else if (object.raw.length)
                                    message.raw = object.raw;
                            if (object.contentEncoding != null)
                                message.contentEncoding = String(object.contentEncoding);
                            if (object.contentType != null)
                                message.contentType = String(object.contentType);
                            return message;
                        };

                        /**
                         * Creates a plain object from an Unknown message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @static
                         * @param {k8s.io.apimachinery.pkg.runtime.Unknown} message Unknown
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Unknown.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.typeMeta = null;
                                if (options.bytes === String)
                                    object.raw = "";
                                else {
                                    object.raw = [];
                                    if (options.bytes !== Array)
                                        object.raw = $util.newBuffer(object.raw);
                                }
                                object.contentEncoding = "";
                                object.contentType = "";
                            }
                            if (message.typeMeta != null && message.hasOwnProperty("typeMeta"))
                                object.typeMeta = $root.k8s.io.apimachinery.pkg.runtime.TypeMeta.toObject(message.typeMeta, options);
                            if (message.raw != null && message.hasOwnProperty("raw"))
                                object.raw = options.bytes === String ? $util.base64.encode(message.raw, 0, message.raw.length) : options.bytes === Array ? Array.prototype.slice.call(message.raw) : message.raw;
                            if (message.contentEncoding != null && message.hasOwnProperty("contentEncoding"))
                                object.contentEncoding = message.contentEncoding;
                            if (message.contentType != null && message.hasOwnProperty("contentType"))
                                object.contentType = message.contentType;
                            return object;
                        };

                        /**
                         * Converts this Unknown to JSON.
                         * @function toJSON
                         * @memberof k8s.io.apimachinery.pkg.runtime.Unknown
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Unknown.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Unknown;
                    })();

                    runtime.schema = (function() {

                        /**
                         * Namespace schema.
                         * @memberof k8s.io.apimachinery.pkg.runtime
                         * @namespace
                         */
                        var schema = {};

                        return schema;
                    })();

                    return runtime;
                })();

                pkg.util = (function() {

                    /**
                     * Namespace util.
                     * @memberof k8s.io.apimachinery.pkg
                     * @namespace
                     */
                    var util = {};

                    util.intstr = (function() {

                        /**
                         * Namespace intstr.
                         * @memberof k8s.io.apimachinery.pkg.util
                         * @namespace
                         */
                        var intstr = {};

                        intstr.IntOrString = (function() {

                            /**
                             * Properties of an IntOrString.
                             * @memberof k8s.io.apimachinery.pkg.util.intstr
                             * @interface IIntOrString
                             * @property {number|Long|null} [type] IntOrString type
                             * @property {number|null} [intVal] IntOrString intVal
                             * @property {string|null} [strVal] IntOrString strVal
                             */

                            /**
                             * Constructs a new IntOrString.
                             * @memberof k8s.io.apimachinery.pkg.util.intstr
                             * @classdesc Represents an IntOrString.
                             * @implements IIntOrString
                             * @constructor
                             * @param {k8s.io.apimachinery.pkg.util.intstr.IIntOrString=} [properties] Properties to set
                             */
                            function IntOrString(properties) {
                                if (properties)
                                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * IntOrString type.
                             * @member {number|Long} type
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @instance
                             */
                            IntOrString.prototype.type = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                            /**
                             * IntOrString intVal.
                             * @member {number} intVal
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @instance
                             */
                            IntOrString.prototype.intVal = 0;

                            /**
                             * IntOrString strVal.
                             * @member {string} strVal
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @instance
                             */
                            IntOrString.prototype.strVal = "";

                            /**
                             * Creates a new IntOrString instance using the specified properties.
                             * @function create
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {k8s.io.apimachinery.pkg.util.intstr.IIntOrString=} [properties] Properties to set
                             * @returns {k8s.io.apimachinery.pkg.util.intstr.IntOrString} IntOrString instance
                             */
                            IntOrString.create = function create(properties) {
                                return new IntOrString(properties);
                            };

                            /**
                             * Encodes the specified IntOrString message. Does not implicitly {@link k8s.io.apimachinery.pkg.util.intstr.IntOrString.verify|verify} messages.
                             * @function encode
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {k8s.io.apimachinery.pkg.util.intstr.IIntOrString} message IntOrString message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            IntOrString.encode = function encode(message, writer) {
                                if (!writer)
                                    writer = $Writer.create();
                                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.type);
                                if (message.intVal != null && Object.hasOwnProperty.call(message, "intVal"))
                                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.intVal);
                                if (message.strVal != null && Object.hasOwnProperty.call(message, "strVal"))
                                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.strVal);
                                return writer;
                            };

                            /**
                             * Encodes the specified IntOrString message, length delimited. Does not implicitly {@link k8s.io.apimachinery.pkg.util.intstr.IntOrString.verify|verify} messages.
                             * @function encodeDelimited
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {k8s.io.apimachinery.pkg.util.intstr.IIntOrString} message IntOrString message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            IntOrString.encodeDelimited = function encodeDelimited(message, writer) {
                                return this.encode(message, writer).ldelim();
                            };

                            /**
                             * Decodes an IntOrString message from the specified reader or buffer.
                             * @function decode
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @param {number} [length] Message length if known beforehand
                             * @returns {k8s.io.apimachinery.pkg.util.intstr.IntOrString} IntOrString
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            IntOrString.decode = function decode(reader, length) {
                                if (!(reader instanceof $Reader))
                                    reader = $Reader.create(reader);
                                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString();
                                while (reader.pos < end) {
                                    var tag = reader.uint32();
                                    switch (tag >>> 3) {
                                    case 1:
                                        message.type = reader.int64();
                                        break;
                                    case 2:
                                        message.intVal = reader.int32();
                                        break;
                                    case 3:
                                        message.strVal = reader.string();
                                        break;
                                    default:
                                        reader.skipType(tag & 7);
                                        break;
                                    }
                                }
                                return message;
                            };

                            /**
                             * Decodes an IntOrString message from the specified reader or buffer, length delimited.
                             * @function decodeDelimited
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @returns {k8s.io.apimachinery.pkg.util.intstr.IntOrString} IntOrString
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            IntOrString.decodeDelimited = function decodeDelimited(reader) {
                                if (!(reader instanceof $Reader))
                                    reader = new $Reader(reader);
                                return this.decode(reader, reader.uint32());
                            };

                            /**
                             * Verifies an IntOrString message.
                             * @function verify
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {Object.<string,*>} message Plain object to verify
                             * @returns {string|null} `null` if valid, otherwise the reason why it is not
                             */
                            IntOrString.verify = function verify(message) {
                                if (typeof message !== "object" || message === null)
                                    return "object expected";
                                if (message.type != null && message.hasOwnProperty("type"))
                                    if (!$util.isInteger(message.type) && !(message.type && $util.isInteger(message.type.low) && $util.isInteger(message.type.high)))
                                        return "type: integer|Long expected";
                                if (message.intVal != null && message.hasOwnProperty("intVal"))
                                    if (!$util.isInteger(message.intVal))
                                        return "intVal: integer expected";
                                if (message.strVal != null && message.hasOwnProperty("strVal"))
                                    if (!$util.isString(message.strVal))
                                        return "strVal: string expected";
                                return null;
                            };

                            /**
                             * Creates an IntOrString message from a plain object. Also converts values to their respective internal types.
                             * @function fromObject
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {Object.<string,*>} object Plain object
                             * @returns {k8s.io.apimachinery.pkg.util.intstr.IntOrString} IntOrString
                             */
                            IntOrString.fromObject = function fromObject(object) {
                                if (object instanceof $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString)
                                    return object;
                                var message = new $root.k8s.io.apimachinery.pkg.util.intstr.IntOrString();
                                if (object.type != null)
                                    if ($util.Long)
                                        (message.type = $util.Long.fromValue(object.type)).unsigned = false;
                                    else if (typeof object.type === "string")
                                        message.type = parseInt(object.type, 10);
                                    else if (typeof object.type === "number")
                                        message.type = object.type;
                                    else if (typeof object.type === "object")
                                        message.type = new $util.LongBits(object.type.low >>> 0, object.type.high >>> 0).toNumber();
                                if (object.intVal != null)
                                    message.intVal = object.intVal | 0;
                                if (object.strVal != null)
                                    message.strVal = String(object.strVal);
                                return message;
                            };

                            /**
                             * Creates a plain object from an IntOrString message. Also converts values to other types if specified.
                             * @function toObject
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @static
                             * @param {k8s.io.apimachinery.pkg.util.intstr.IntOrString} message IntOrString
                             * @param {$protobuf.IConversionOptions} [options] Conversion options
                             * @returns {Object.<string,*>} Plain object
                             */
                            IntOrString.toObject = function toObject(message, options) {
                                if (!options)
                                    options = {};
                                var object = {};
                                if (options.defaults) {
                                    if ($util.Long) {
                                        var long = new $util.Long(0, 0, false);
                                        object.type = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                    } else
                                        object.type = options.longs === String ? "0" : 0;
                                    object.intVal = 0;
                                    object.strVal = "";
                                }
                                if (message.type != null && message.hasOwnProperty("type"))
                                    if (typeof message.type === "number")
                                        object.type = options.longs === String ? String(message.type) : message.type;
                                    else
                                        object.type = options.longs === String ? $util.Long.prototype.toString.call(message.type) : options.longs === Number ? new $util.LongBits(message.type.low >>> 0, message.type.high >>> 0).toNumber() : message.type;
                                if (message.intVal != null && message.hasOwnProperty("intVal"))
                                    object.intVal = message.intVal;
                                if (message.strVal != null && message.hasOwnProperty("strVal"))
                                    object.strVal = message.strVal;
                                return object;
                            };

                            /**
                             * Converts this IntOrString to JSON.
                             * @function toJSON
                             * @memberof k8s.io.apimachinery.pkg.util.intstr.IntOrString
                             * @instance
                             * @returns {Object.<string,*>} JSON object
                             */
                            IntOrString.prototype.toJSON = function toJSON() {
                                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                            };

                            return IntOrString;
                        })();

                        return intstr;
                    })();

                    return util;
                })();

                return pkg;
            })();

            return apimachinery;
        })();

        return io;
    })();

    return k8s;
})();

module.exports = $root;
