import React from 'react';

const ExecSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
        <path d="M78.841 216.367c0 4.186 3.408 7.594 7.597 7.594H213.56c4.189 0 7.597-3.405 7.597-7.594v-85.456H78.841v85.456zM221.154 98.03c.002-4.186-3.406-7.594-7.594-7.594H86.438c-4.189 0-7.597 3.405-7.597 7.594v17.32h142.313V98.03zm-119.137 11.251h-9.736V98.906h9.736v10.375zm17.67 0h-9.736V98.906h9.736v10.375zm17.668 0h-9.736V98.906h9.736v10.375z"/>
        <path d="M149.996 0C67.157 0 .001 67.161.001 149.997S67.157 300 149.996 300s150.003-67.163 150.003-150.003S232.835 0 149.996 0zm86.722 216.367c0 12.768-10.39 23.156-23.158 23.156H86.438c-12.768 0-23.158-10.387-23.158-23.156V98.03c0-12.768 10.387-23.156 23.158-23.156H213.56c12.768 0 23.158 10.387 23.158 23.156v118.337z"/>
    </svg>
);

export default ExecSvg;
