import React from 'react';

const LockSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.995 299.995" {...props}>
        <path d="M149.997 161.485c-8.613 0-15.598 6.982-15.598 15.598 0 5.776 3.149 10.807 7.817 13.505v17.341h15.562v-17.341c4.668-2.697 7.817-7.729 7.817-13.505 0-8.616-6.984-15.598-15.598-15.598zM150.003 85.849c-13.111 0-23.775 10.665-23.775 23.775v25.319h47.548v-25.319c-.001-13.108-10.665-23.775-23.773-23.775z"/>
        <path d="M149.995.001C67.156.001 0 67.159 0 149.998c0 82.837 67.156 149.997 149.995 149.997s150-67.161 150-149.997c0-82.839-67.161-149.997-150-149.997zm46.09 227.117h-92.173c-9.734 0-17.626-7.892-17.626-17.629V152.57c0-8.491 6.007-15.582 14.003-17.25v-25.697c0-27.409 22.3-49.711 49.711-49.711 27.409 0 49.709 22.3 49.709 49.711v25.697c7.993 1.673 14 8.759 14 17.25v56.919h.002c0 9.736-7.892 17.629-17.626 17.629z"/>
    </svg>
);

export default LockSvg;
