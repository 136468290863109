import React from 'react';

const ClusterRoleSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.0353 17.5004" {...props}>
        <path d="M8.9583.4632a1.1358 1.1265 0 0 0-.4352.1101l-5.9396 2.838a1.1358 1.1265 0 0 0-.6145.7642L.5037 10.5503a1.1358 1.1265 0 0 0 .1542.8637 1.1358 1.1265 0 0 0 .0645.0896l4.1112 5.1116a1.1358 1.1265 0 0 0 .888.4238l6.5928-.0015a1.1358 1.1265 0 0 0 .888-.4231l4.1096-5.1123a1.1358 1.1265 0 0 0 .2195-.9533L16.064 4.174a1.1358 1.1265 0 0 0-.6145-.7642L9.509.5733a1.1358 1.1265 0 0 0-.5508-.1101z"/>
        <path d="M8.9552.0016a1.199 1.1892 0 0 0-.4589.117L2.2256 3.1138a1.199 1.1892 0 0 0-.6487.8067L.0303 10.65a1.199 1.1892 0 0 0 .1625.9124 1.199 1.1892 0 0 0 .0684.0942L4.601 17.053a1.199 1.1892 0 0 0 .9373.4474l6.9598-.0015a1.199 1.1892 0 0 0 .9373-.4467l4.3383-5.3972a1.199 1.1892 0 0 0 .2317-1.0057l-1.549-6.7303a1.199 1.1892 0 0 0-.6486-.806L9.537.1178a1.199 1.1892 0 0 0-.5818-.1162zm.003.4618a1.1358 1.1265 0 0 1 .5507.1102L15.4492 3.41a1.1358 1.1265 0 0 1 .6146.7642l1.4676 6.3748a1.1358 1.1265 0 0 1-.2196.9534l-4.1096 5.1123a1.1358 1.1265 0 0 1-.888.423l-6.5928.0016a1.1358 1.1265 0 0 1-.888-.4239L.7223 11.504a1.1358 1.1265 0 0 1-.0646-.0897 1.1358 1.1265 0 0 1-.1542-.8637l1.4653-6.3748a1.1358 1.1265 0 0 1 .6146-.7641L8.5229.5736a1.1358 1.1265 0 0 1 .4353-.1102z" color="#000" fontWeight="400" fontFamily="Sans" overflow="visible" fill="#fff"/>
        <text y="16.8118" x="10.0144" fontWeight="400" fontSize="10.5833" fontFamily="Sans" letterSpacing="0" wordSpacing="0" fill="#fff" strokeWidth=".2646" transform="translate(-.9926 -1.1742)">
            <tspan y="16.8118" x="10.0144" fontSize="2.8222" fontFamily="Arial" textAnchor="middle">c.role</tspan>
        </text>
        <g fill="#fff" transform="translate(-.9926 -1.1742)">
            <ellipse ry=".5023" rx=".5063" cy="9.9493" cx="9.9283"/>
            <path d="M10.011 5.8762L6.9028 7.2504v2.0505c0 1.901 1.3248 3.678 3.108 4.1098 1.7808-.4319 3.1068-2.2088 3.1068-4.1098V7.2504zm1.6451 5.5182H8.3644V8.631h.4725v-.3015c0-.651.5319-1.1791 1.1892-1.1791.6574 0 1.1893.5281 1.1893 1.1791v.3015h.4407z"/>
            <path d="M9.9878 7.589c-.3939.0014-.713.318-.713.708v.2964h1.4273V8.292c-.0038-.3889-.3217-.7017-.7143-.703z"/>
        </g>
    </svg>
);

export default ClusterRoleSvg;
