import React from 'react';

const LogoutSvg = (props: {[key: string]: any}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
        <path d="M127.591 119.253l-13.258 13.258 22.406 22.406-22.406 22.406 13.258 13.259 22.406-22.406 22.406 22.406 13.259-13.259-22.406-22.406 22.406-22.406-13.259-13.258-22.406 22.406z"/>
        <path d="M149.997 0C67.158 0 .003 67.161.003 149.997S67.158 300 149.997 300s150-67.163 150-150.003S232.837 0 149.997 0zM150 232.886c-77.185 0-82.683-130.592-82.683-130.592l82.681-35.187H150l82.681 35.187c0 .002-5.498 130.592-82.681 130.592z"/>
    </svg>
);

export default LogoutSvg;
